export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string such as 2019-12-03T09:54:33Z representing a valid ISO-8601 Date string. */
  DateTime: { input: string; output: string; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: Blob; output: Blob; }
  /** Represents NULL values */
  Void: { input: void; output: void; }
};

export type AbstractBookingProviderExtensionModel = {
  bookingType: ServiceBookingType;
};

export type AbstractChatModel = {
  featuredItem?: Maybe<ChatItemModel>;
  featuredRequest?: Maybe<Request>;
  firstUnansweredPatientChatItem?: Maybe<ChatItemModel>;
  hasDicomAttachments: Scalars['Boolean']['output'];
  hasNonDicomAttachments: Scalars['Boolean']['output'];
  /** NB: for a given chat context, across multiple calls, it may be populated with different aliases (which are equivalent when used as input for other queries)For this reason, do not expect/validate any particular format/length. */
  id: Scalars['String']['output'];
  isPinnedToHome: Scalars['Boolean']['output'];
  items: Array<ChatItemModel>;
  lastItemTimestamp?: Maybe<Scalars['DateTime']['output']>;
  numberOfChatItems: Scalars['Float']['output'];
  /** The policy that will be applied in case the Patient participant should try to post a Direct Message to the Chat. */
  patientWritePolicy: AllowDenyPolicyType;
  /** (Based on the invoker user) the most recent *read* item in the timeline. */
  readTillItemIncluded?: Maybe<ChatItemModel>;
  unreadItemsCount: Scalars['Int']['output'];
};

export type AbstractServiceProviderModel = {
  serviceProviderType: ServiceProviderType;
};

export type AccessTokenAndOtpChallengeModel = {
  __typename?: 'AccessTokenAndOtpChallengeModel';
  /** target user has this amount of minutes to fulfill the challenge and verify/execute the request */
  expiresInMinutes: Scalars['Int']['output'];
};

export type AccessTokenChallengeModel = {
  __typename?: 'AccessTokenChallengeModel';
  /** target user has this amount of minutes to fulfill the challenge and verify/execute the request */
  expiresInMinutes: Scalars['Int']['output'];
  patientInviteId?: Maybe<Scalars['String']['output']>;
};

export type AccountingEntryDataModelV2 = {
  __typename?: 'AccountingEntryDataModelV2';
  items: Array<AccountingEntryItemUnionV2>;
  version: AccountingEntryVersion;
};

export enum AccountingEntryItemType {
  BANK_TAX = 'BANK_TAX',
  BASE_TAX = 'BASE_TAX',
  SERVICE = 'SERVICE'
}

export type AccountingEntryItemUnionV2 = AccountingEntryServiceItemModelV2 | AccountingEntryTaxItemModel;

export type AccountingEntryModel = {
  __typename?: 'AccountingEntryModel';
  file?: Maybe<File>;
  id: Scalars['String']['output'];
  memorandumDate: Scalars['DateTime']['output'];
  status: AccountingEntryStatus;
  totalInvoice: Scalars['Float']['output'];
  totalPaid: Scalars['Float']['output'];
};

export type AccountingEntryServiceItemModelV2 = {
  __typename?: 'AccountingEntryServiceItemModelV2';
  appliedTaxes: Array<AccountingEntryServiceItemTaxModel>;
  currency: CurrencyCode;
  date: Scalars['DateTime']['output'];
  doctorProfit: Scalars['Float']['output'];
  paidByPatientForDoctor: Scalars['Float']['output'];
  patientName: Scalars['String']['output'];
  refund: Scalars['Boolean']['output'];
  requestCode: Scalars['String']['output'];
  service: Scalars['String']['output'];
  type: AccountingEntryItemType;
};

export type AccountingEntryServiceItemTaxModel = {
  __typename?: 'AccountingEntryServiceItemTaxModel';
  taxValue: Scalars['Float']['output'];
  translationCode: Scalars['String']['output'];
};

export enum AccountingEntryStatus {
  GENERATED = 'GENERATED',
  PENDING_FILE_GENERATION = 'PENDING_FILE_GENERATION'
}

export type AccountingEntryTaxItemModel = {
  __typename?: 'AccountingEntryTaxItemModel';
  amount: Scalars['Float']['output'];
  currency: CurrencyCode;
  title: Scalars['String']['output'];
  translationCode?: Maybe<Scalars['String']['output']>;
  type: AccountingEntryItemType;
};

export enum AccountingEntryVersion {
  V1 = 'V1',
  V2 = 'V2'
}

export type AccountingMetadata = {
  __typename?: 'AccountingMetadata';
  paid: Scalars['Float']['output'];
  toBeInvoiced: Scalars['Float']['output'];
};

export type AddDoctorSpecializationArgs = {
  pathologies?: Array<Pathology>;
  symptoms?: Array<Symptom>;
  type: Specialization;
  university?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Float']['input']>;
};

export enum AdminProfileType {
  PORTAL_ADMIN = 'PORTAL_ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN'
}

export type AdminUserListFilterByArgs = {
  searchValue: Scalars['String']['input'];
};

export type AdminUserListOrderByArgs = {
  field: AdminUserListOrderByField;
  type: OrderByType;
};

export enum AdminUserListOrderByField {
  CREATED_AT = 'CREATED_AT',
  EMAIL = 'EMAIL',
  FIRST_NAME_AND_LAST_NAME = 'FIRST_NAME_AND_LAST_NAME'
}

export type AdminUserModel = {
  __typename?: 'AdminUserModel';
  allowedPortals: Array<PortalModel>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  profileType: AdminProfileType;
};

export type AffiliationBookingProviderExtensionModel = AbstractBookingProviderExtensionModel & {
  __typename?: 'AffiliationBookingProviderExtensionModel';
  affiliationId: Scalars['String']['output'];
  affiliationName: Scalars['String']['output'];
  bookingType: ServiceBookingType;
};

export type AffiliationBookingTypeFilterArgs = {
  affiliationIds: Array<Scalars['String']['input']>;
};

export enum AffiliationDoctorJoinStatus {
  JOINED = 'JOINED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

/** Describes an Affiliation in the context of a specific Doctor instance */
export type AffiliationForDoctorModel = {
  __typename?: 'AffiliationForDoctorModel';
  affiliationId: Scalars['String']['output'];
  doctorPortalUserId: Scalars['String']['output'];
  endDate: Scalars['DateTime']['output'];
  isExpired: Scalars['Boolean']['output'];
  joinStatus: AffiliationDoctorJoinStatus;
  name: Scalars['String']['output'];
  services: Array<ServiceModel>;
  startDate: Scalars['DateTime']['output'];
};

export type AffiliationForPatientModel = {
  __typename?: 'AffiliationForPatientModel';
  canBeBooked: Scalars['Boolean']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isExpired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  services: Array<AffiliationServiceForPatientModel>;
  startDate: Scalars['DateTime']['output'];
};

export enum AffiliationPublicationStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export type AffiliationRoleServiceModel = {
  __typename?: 'AffiliationRoleServiceModel';
  addons: Array<ServiceAddonModel>;
  durationMinutes?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  providedWithinDays?: Maybe<Scalars['Int']['output']>;
  role: MedicalRoleModel;
};

export type AffiliationServiceForPatientModel = {
  __typename?: 'AffiliationServiceForPatientModel';
  baseServiceType: ServiceType;
  description: Scalars['String']['output'];
  maxPrice: Scalars['Float']['output'];
  minPrice: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

export type AffiliationsGlobalSearchDetailsModel = {
  __typename?: 'AffiliationsGlobalSearchDetailsModel';
  items: Array<ServicesByContextModel>;
  totalCount: Scalars['Int']['output'];
};

export type AllergiesAnaphylacticShockData = {
  __typename?: 'AllergiesAnaphylacticShockData';
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  recurrence?: Maybe<Scalars['String']['output']>;
  toWhat?: Maybe<Scalars['String']['output']>;
};

export type AllergiesAnaphylacticShockDataArgs = {
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  recurrence?: InputMaybe<Scalars['String']['input']>;
  toWhat?: InputMaybe<Scalars['String']['input']>;
};

export type AllergiesAnaphylacticShockHistory = {
  __typename?: 'AllergiesAnaphylacticShockHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AllergiesAnaphylacticShockData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type AllergiesData = {
  __typename?: 'AllergiesData';
  anaphylacticShock?: Maybe<AllergiesAnaphylacticShockData>;
  hymenoptera?: Maybe<AllergiesHymenopteraData>;
  other?: Maybe<AllergiesOtherData>;
};

export type AllergiesHymenopteraData = {
  __typename?: 'AllergiesHymenopteraData';
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type AllergiesHymenopteraDataArgs = {
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type AllergiesHymenopteraHistory = {
  __typename?: 'AllergiesHymenopteraHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AllergiesHymenopteraData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type AllergiesOtherData = {
  __typename?: 'AllergiesOtherData';
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  recurrence?: Maybe<Scalars['String']['output']>;
  toWhat?: Maybe<Scalars['String']['output']>;
};

export type AllergiesOtherDataArgs = {
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  recurrence?: InputMaybe<Scalars['String']['input']>;
  toWhat?: InputMaybe<Scalars['String']['input']>;
};

export type AllergiesOtherHistory = {
  __typename?: 'AllergiesOtherHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AllergiesOtherData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export enum AllogenicTransplantationType {
  ALLOGENEIC = 'ALLOGENEIC',
  AUTOLOGOUS = 'AUTOLOGOUS'
}

export enum AllowDenyPolicyType {
  ALLOW = 'ALLOW',
  DENY = 'DENY'
}

export type AnamnesisAllogeneicTransplantationData = {
  __typename?: 'AnamnesisAllogeneicTransplantationData';
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AllogenicTransplantationType>;
};

export type AnamnesisAllogeneicTransplantationDataArgs = {
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AllogenicTransplantationType>;
};

export type AnamnesisAllogeneicTransplantationHistory = {
  __typename?: 'AnamnesisAllogeneicTransplantationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisAllogeneicTransplantationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type AnamnesisAutoimmuneDiseasesData = {
  __typename?: 'AnamnesisAutoimmuneDiseasesData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type AnamnesisAutoimmuneDiseasesDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type AnamnesisAutoimmuneDiseasesHistory = {
  __typename?: 'AnamnesisAutoimmuneDiseasesHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisAutoimmuneDiseasesData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type AnamnesisBreastFamilyHistoryData = {
  __typename?: 'AnamnesisBreastFamilyHistoryData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type AnamnesisBreastFamilyHistoryDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type AnamnesisBreastFamilyHistoryHistory = {
  __typename?: 'AnamnesisBreastFamilyHistoryHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisBreastFamilyHistoryData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type AnamnesisDonorData = {
  __typename?: 'AnamnesisDonorData';
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type AnamnesisDonorDataArgs = {
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type AnamnesisDonorHistory = {
  __typename?: 'AnamnesisDonorHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisDonorData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type AnamnesisGlomerulonephritesData = {
  __typename?: 'AnamnesisGlomerulonephritesData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type AnamnesisGlomerulonephritesDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type AnamnesisGlomerulonephritesHistory = {
  __typename?: 'AnamnesisGlomerulonephritesHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisGlomerulonephritesData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type AnamnesisInfectionsData = {
  __typename?: 'AnamnesisInfectionsData';
  CMV: Scalars['Boolean']['output'];
  EBV: Scalars['Boolean']['output'];
  HBV: Scalars['Boolean']['output'];
  HIV: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type AnamnesisInfectionsDataArgs = {
  CMV: Scalars['Boolean']['input'];
  EBV: Scalars['Boolean']['input'];
  HBV: Scalars['Boolean']['input'];
  HIV: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type AnamnesisInfectionsHistory = {
  __typename?: 'AnamnesisInfectionsHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisInfectionsData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type AnamnesisIsInTransplantListData = {
  __typename?: 'AnamnesisIsInTransplantListData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type AnamnesisIsInTransplantListDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type AnamnesisIsInTransplantListHistory = {
  __typename?: 'AnamnesisIsInTransplantListHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisIsInTransplantListData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type AnamnesisMelanomaFamilyHistoryData = {
  __typename?: 'AnamnesisMelanomaFamilyHistoryData';
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type AnamnesisMelanomaFamilyHistoryDataArgs = {
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type AnamnesisMelanomaFamilyHistoryHistory = {
  __typename?: 'AnamnesisMelanomaFamilyHistoryHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisMelanomaFamilyHistoryData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type AnamnesisNeoplasiaData = {
  __typename?: 'AnamnesisNeoplasiaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type AnamnesisNeoplasiaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type AnamnesisNeoplasiaHistory = {
  __typename?: 'AnamnesisNeoplasiaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisNeoplasiaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

/** This is a model that represents NET_SURGERY history, for the flag see AnamnesisNetSurgeryFlagHistory */
export type AnamnesisNetSurgeryData = {
  __typename?: 'AnamnesisNetSurgeryData';
  complications: OptionalYesNo;
  complicationsType?: Maybe<Scalars['String']['output']>;
  executionDate: Scalars['DateTime']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  objectId: Scalars['String']['output'];
  surgeryType: Scalars['String']['output'];
};

export type AnamnesisNetSurgeryDataArgs = {
  complications: OptionalYesNo;
  complicationsType?: InputMaybe<Scalars['String']['input']>;
  executionDate: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['String']['input'];
  surgeryType: Scalars['String']['input'];
};

export type AnamnesisNetSurgeryFlagData = {
  __typename?: 'AnamnesisNetSurgeryFlagData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type AnamnesisNetSurgeryFlagDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type AnamnesisNetSurgeryFlagHistory = {
  __typename?: 'AnamnesisNetSurgeryFlagHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisNetSurgeryFlagData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

/** This is a model that represents NET_SURGERY history, for the flag see AnamnesisNetSurgeryFlagHistory */
export type AnamnesisNetSurgeryHistory = {
  __typename?: 'AnamnesisNetSurgeryHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisNetSurgeryData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

/** This is a model that represents NET_SURGERY history, for the flag see AnamnesisNetSurgeryFlagHistory */
export type AnamnesisNetSurgeryHistoryModel = {
  __typename?: 'AnamnesisNetSurgeryHistoryModel';
  rows: Array<AnamnesisNetSurgeryRow>;
};

export type AnamnesisNetSurgeryRow = {
  __typename?: 'AnamnesisNetSurgeryRow';
  history: Array<AnamnesisNetSurgeryHistory>;
  objectId: Scalars['String']['output'];
};

export type AnamnesisSevereHypertensionData = {
  __typename?: 'AnamnesisSevereHypertensionData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type AnamnesisSevereHypertensionDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type AnamnesisSevereHypertensionHistory = {
  __typename?: 'AnamnesisSevereHypertensionHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisSevereHypertensionData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type AnamnesisSurgeryForBreastCancerData = {
  __typename?: 'AnamnesisSurgeryForBreastCancerData';
  isExecuted: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type AnamnesisSurgeryForBreastCancerDataArgs = {
  isExecuted: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type AnamnesisSurgeryForBreastCancerHistory = {
  __typename?: 'AnamnesisSurgeryForBreastCancerHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisSurgeryForBreastCancerData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type AnamnesisTraumaData = {
  __typename?: 'AnamnesisTraumaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type AnamnesisTraumaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type AnamnesisTraumaHistory = {
  __typename?: 'AnamnesisTraumaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: AnamnesisTraumaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type AnnouncementModel = {
  __typename?: 'AnnouncementModel';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum AnnouncementsOrder {
  MOST_RECENT = 'MOST_RECENT',
  TO_READ = 'TO_READ'
}

export type AppUserClaimModel = {
  __typename?: 'AppUserClaimModel';
  email: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  residenceAddress: ResidenceAddressModel;
};

export type AssistedDoctorSearchFormModel = {
  __typename?: 'AssistedDoctorSearchFormModel';
  appliedSearchCriteria: SearchCriteriaModel;
  attachments: Array<FileModel>;
  desiredSearchCriteria: SearchCriteriaModel;
  patientPortalUser: PortalUserModel;
  /** the contents are filtered/sanitized */
  text?: Maybe<Scalars['String']['output']>;
};

export type AssistedSearchDoctorsResultModel = {
  __typename?: 'AssistedSearchDoctorsResultModel';
  /** If true, indicates the system did filter results based on the specific invoker PortalUser. Intended to provide feedback about the user matching status when calling the endpoint anonymously alongside the 'forPatientUserEmail' arg. */
  appliedPortalUserFilter: Scalars['Boolean']['output'];
  doctors: Array<DoctorModel>;
};

export type AssistedSearchSpecializationsResultModel = {
  __typename?: 'AssistedSearchSpecializationsResultModel';
  /** If true, indicates the system did filter results based on the specific invoker PortalUser. Intended to provide feedback about the user matching status when calling the endpoint anonymously alongside the 'forPatientUserEmail' arg. */
  appliedPortalUserFilter: Scalars['Boolean']['output'];
  specializations: Array<Specialization>;
};

export type AssociatedUserModel = {
  __typename?: 'AssociatedUserModel';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type AuthenticatedUserResourceReferenceModel = {
  __typename?: 'AuthenticatedUserResourceReferenceModel';
  cognitoAuthResult: CognitoAuthModel;
};

export enum Availability {
  ABSENT = 'ABSENT',
  PRESENT = 'PRESENT'
}

export type BasicChatReferenceModel = {
  __typename?: 'BasicChatReferenceModel';
  chatId: Scalars['String']['output'];
  chatType: ChatType;
};

export type BasicPatientFileReferenceModel = {
  __typename?: 'BasicPatientFileReferenceModel';
  patientFileId: Scalars['String']['output'];
};

export type BasicResourceReferenceModelUnion = AuthenticatedUserResourceReferenceModel | BasicChatReferenceModel | BasicPatientFileReferenceModel;

export type BillingData = {
  __typename?: 'BillingData';
  iban: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type BirthAddressModel = {
  __typename?: 'BirthAddressModel';
  fullAddress: Scalars['String']['output'];
  googlePlaceId: Scalars['String']['output'];
};

export type BookingRequestArgs = {
  primaryDate?: InputMaybe<Scalars['DateTime']['input']>;
  secondaryDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum BookingRequestDateType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}

export type BookingTypeFilterArgs = {
  affiliationBookingTypeFilter?: InputMaybe<AffiliationBookingTypeFilterArgs>;
  /** Any non-null value would enable this filter */
  doctorBookingTypeFilter?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum BrafMutation {
  NOT_V600 = 'NOT_V600',
  V600 = 'V600',
  V600E = 'V600E',
  V600K = 'V600K',
  WT = 'WT'
}

export type BulkImportDoctorsResultModel = {
  __typename?: 'BulkImportDoctorsResultModel';
  /** null implies `servicesParsingStepResult` contains at least one error, hence processing stopped there */
  doctorsImportResult?: Maybe<BulkProcessingResultModel>;
  /** null implies the step was skipped, because the related csv was not provided */
  servicesParsingResult?: Maybe<BulkProcessingResultModel>;
};

/** Holds error information about a specific line or item in the input sequence of a bulk processing operation */
export type BulkProcessingLineErrorModel = {
  __typename?: 'BulkProcessingLineErrorModel';
  /** The line or item position in the input sequence (1-index based). */
  lineNumber: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  trace: Scalars['String']['output'];
};

/** Holds warning information about a specific line or item in the input sequence of a bulk processing operation */
export type BulkProcessingLineWarningModel = {
  __typename?: 'BulkProcessingLineWarningModel';
  /** The line or item position in the input sequence (1-index based). */
  lineNumber: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type BulkProcessingResultModel = {
  __typename?: 'BulkProcessingResultModel';
  dryRun: Scalars['Boolean']['output'];
  errors: Array<BulkProcessingLineErrorModel>;
  lineCount: Scalars['Int']['output'];
  warnings: Array<BulkProcessingLineWarningModel>;
};

export enum CSVExportType {
  GSD_CSV_ACCESSES = 'GSD_CSV_ACCESSES',
  GSD_CSV_DOCTORS = 'GSD_CSV_DOCTORS',
  GSD_CSV_INVOICES = 'GSD_CSV_INVOICES',
  GSD_CSV_PATIENTS = 'GSD_CSV_PATIENTS',
  GSD_CSV_REQUESTS = 'GSD_CSV_REQUESTS',
  GSD_CSV_VOICE_CALLS = 'GSD_CSV_VOICE_CALLS',
  PHARMA_CSV_INSTITUTIONS = 'PHARMA_CSV_INSTITUTIONS',
  PHARMA_CSV_INVITES = 'PHARMA_CSV_INVITES',
  PHARMA_CSV_STATISTICS = 'PHARMA_CSV_STATISTICS',
  PHARMA_CSV_USERS = 'PHARMA_CSV_USERS'
}

export type CalendarModel = {
  __typename?: 'CalendarModel';
  dateEnd?: Maybe<Scalars['DateTime']['output']>;
  dateStart: Scalars['DateTime']['output'];
  dates: Array<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  recurrenceType: CalendarRecurrenceType;
  timeSlots: Array<CalendarTimeSlotModel>;
  type: CalendarType;
};

export enum CalendarRecurrenceType {
  EVERY_DAY = 'EVERY_DAY',
  EVERY_MONTH = 'EVERY_MONTH',
  EVERY_TWO_WEEK = 'EVERY_TWO_WEEK',
  EVERY_WEEK = 'EVERY_WEEK',
  EVERY_YEAR = 'EVERY_YEAR',
  NEVER = 'NEVER'
}

export type CalendarTimeSlotArgs = {
  timeEnd: Scalars['String']['input'];
  timeStart: Scalars['String']['input'];
};

export type CalendarTimeSlotModel = {
  __typename?: 'CalendarTimeSlotModel';
  timeEnd: Scalars['String']['output'];
  timeStart: Scalars['String']['output'];
};

export enum CalendarType {
  UNAVAILABILITY = 'UNAVAILABILITY',
  VIDEO_VISIT = 'VIDEO_VISIT'
}

export enum CarTInfusionStatus {
  EXECUTED = 'EXECUTED',
  PENDING = 'PENDING'
}

export type CareTeamMember = {
  __typename?: 'CareTeamMember';
  careType?: Maybe<CareTeamMemberType>;
  fromCurrentPortal: Scalars['Boolean']['output'];
  institutionUser: InstitutionUser;
};

export enum CareTeamMemberType {
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER'
}

export enum CciMedicalIndexAge {
  FROM_50_TO_59 = 'FROM_50_TO_59',
  FROM_60_TO_69 = 'FROM_60_TO_69',
  FROM_70_TO_79 = 'FROM_70_TO_79',
  LESS_THAN_50 = 'LESS_THAN_50',
  MORE_THAN_80 = 'MORE_THAN_80'
}

export enum CciMedicalIndexDiabetesMellitus {
  END_ORGAN_DAMAGE = 'END_ORGAN_DAMAGE',
  NONE_OR_DIET_CONTROLLED = 'NONE_OR_DIET_CONTROLLED',
  UNCOMPLICATED = 'UNCOMPLICATED'
}

export enum CciMedicalIndexLiverDisease {
  MILD = 'MILD',
  MODERATE_TO_SEVERE = 'MODERATE_TO_SEVERE',
  NONE = 'NONE'
}

export type CciMedicalIndexParametersModel = {
  age: CciMedicalIndexAge;
  aids: Scalars['Boolean']['input'];
  cerebrovascularAccidentOrTransientIschemicAttack: Scalars['Boolean']['input'];
  chronicObstructivePulmonaryDisease: Scalars['Boolean']['input'];
  congestiveHeartFailure: Scalars['Boolean']['input'];
  connectiveTissueDisease: Scalars['Boolean']['input'];
  dementia: Scalars['Boolean']['input'];
  diabetesMellitus: CciMedicalIndexDiabetesMellitus;
  hemiplegia: Scalars['Boolean']['input'];
  leukemia: Scalars['Boolean']['input'];
  liverDisease: CciMedicalIndexLiverDisease;
  lymphoma: Scalars['Boolean']['input'];
  moderateToSevereChronicKidneyDisease: Scalars['Boolean']['input'];
  myocardialInfarction: Scalars['Boolean']['input'];
  pepticUlcerDisease: Scalars['Boolean']['input'];
  peripheralVascularDisease: Scalars['Boolean']['input'];
  solidTumor: CciMedicalIndexSolidTumor;
};

export enum CciMedicalIndexSolidTumor {
  LOCALIZED = 'LOCALIZED',
  METASTATIC = 'METASTATIC',
  NONE = 'NONE'
}

export type ChallengeModelUnion = AccessTokenAndOtpChallengeModel | AccessTokenChallengeModel | DeviceOtpChallengeModel | NoneAdminPreauthorizedChallengeModel;

export type ChatFeaturedRequestFilterByArgs = {
  assignedDoctorPortalUserId?: InputMaybe<Scalars['String']['input']>;
  dueStatuses?: InputMaybe<Array<RequestDueStatus>>;
  isAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  portalExtensionFilter?: InputMaybe<RequestPortalExtensionFilterArgs>;
  serviceBookingTypes?: InputMaybe<Array<ServiceBookingType>>;
  serviceTypes?: InputMaybe<Array<ServiceType>>;
  statuses?: InputMaybe<Array<RequestStatus>>;
};

export type ChatFilesModel = {
  __typename?: 'ChatFilesModel';
  invoices: Array<FileModel>;
  patientFiles: ChatPatientFilesModel;
};

export type ChatFilterByArgs = {
  /** Provided Chat IDs will be excluded from results */
  excludeChatIds?: InputMaybe<Array<Scalars['String']['input']>>;
  featuredRequestFilter?: InputMaybe<ChatFeaturedRequestFilterByArgs>;
  freeText?: InputMaybe<Scalars['String']['input']>;
  hasFeaturedRequest?: InputMaybe<Scalars['Boolean']['input']>;
  includeWithoutFeaturedRequest?: InputMaybe<Scalars['Boolean']['input']>;
  isPinnedToHome?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only applicable when invoker is *not* a Patient (gets ignored otherwise) */
  patientPortalUserId?: InputMaybe<Scalars['String']['input']>;
};

export type ChatItemDetailsUnion = AssistedDoctorSearchFormModel | DirectMessageModel | MailMessageModel | ReminderModel | RequestHistoryEventModel | RequestProposalModel | VoiceCallDetailsModel;

export enum ChatItemDoctorOriginatorClaim {
  /** The Doctor claims that the Chat's Patient has solicited them via chat, but the subject of the solicitation should require the Patient to open a Request */
  PATIENT_SHOULD_OPEN_A_REQUEST = 'PATIENT_SHOULD_OPEN_A_REQUEST'
}

export type ChatItemMetadataModel = {
  __typename?: 'ChatItemMetadataModel';
  /** When present, implies the ChatItem originator is a DoctorPortalUser; represents a claim (reason for sending/expectation) such doctor is making on the ChatItem itself or the Chat context. */
  doctorOriginatorClaim?: Maybe<ChatItemDoctorOriginatorClaim>;
  /** When present, implies the ChatItem originator is a PatientPortalUser; represents a claim (reason for sending/expectation) such patient is making on the ChatItem itself or the Chat context. */
  patientOriginatorClaim?: Maybe<ChatItemPatientOriginatorClaim>;
  /** Is patientOriginatorClaim tied to some real-world, end user-provided value, or was it synthesized/inferred/forced by the system? */
  patientOriginatorClaimIsSynthetic?: Maybe<Scalars['Boolean']['output']>;
};

export type ChatItemModel = {
  __typename?: 'ChatItemModel';
  details: ChatItemDetailsUnion;
  id: Scalars['String']['output'];
  metadata: ChatItemMetadataModel;
  timestamp: Scalars['DateTime']['output'];
};

export enum ChatItemPatientOriginatorClaim {
  /** The Patient claims that the solicitation is a follow-up to a recent Request */
  RECENT_REQUEST = 'RECENT_REQUEST',
  /** The Patient is requesting the Doctor to send them some relevant medical documentation */
  REQUESTING_MEDICAL_DOCUMENT = 'REQUESTING_MEDICAL_DOCUMENT'
}

export type ChatMedicalFilePdfDataArgs = {
  fileType: PDFDataStorageTypeAllowedFromChat;
  subjects: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type ChatModelUnion = ClinicChatModel | OneToOneChatModel;

export type ChatOrderByArgs = {
  field: ChatOrderByField;
  type: OrderByType;
};

/** The fallback criterion for results where the specified one does not apply will be LAST_ITEM_TIMESTAMP */
export enum ChatOrderByField {
  FEATURED_REQUEST_DUE_DATE = 'FEATURED_REQUEST_DUE_DATE',
  FIRST_UNANSWERED_PATIENT_ITEM_TIMESTAMP = 'FIRST_UNANSWERED_PATIENT_ITEM_TIMESTAMP',
  FIRST_UNREAD_ITEM_TIMESTAMP = 'FIRST_UNREAD_ITEM_TIMESTAMP',
  LAST_ITEM_TIMESTAMP = 'LAST_ITEM_TIMESTAMP'
}

export type ChatPatientFilesModel = {
  __typename?: 'ChatPatientFilesModel';
  prescriptions: Array<FileModel>;
  reports: Array<FileModel>;
  rest: Array<FileModel>;
};

export type ChatPinnedToHomeChangedModel = {
  __typename?: 'ChatPinnedToHomeChangedModel';
  pinnedToHome: Scalars['Boolean']['output'];
};

export enum ChatType {
  CLINIC = 'CLINIC',
  ONE_TO_ONE = 'ONE_TO_ONE',
  REQUEST = 'REQUEST'
}

export type CheckPatientExistenceResponse = {
  __typename?: 'CheckPatientExistenceResponse';
  status: PatientExistenceCheckStatusDecision;
  user?: Maybe<User>;
};

export type CheckUserRegistrationStatusModel = {
  __typename?: 'CheckUserRegistrationStatusModel';
  status: UserRegistrationStatusCheckDecision;
  user?: Maybe<UserModel>;
};

export type CheckValidationBirthResidenceAddressForGsdSsoModel = {
  __typename?: 'CheckValidationBirthResidenceAddressForGsdSsoModel';
  birthAddressIsValid: Scalars['Boolean']['output'];
  residenceAddressIsValid: Scalars['Boolean']['output'];
};

export type ClinicAvailabilityModel = {
  __typename?: 'ClinicAvailabilityModel';
  isCurrentlyAvailable: Scalars['Boolean']['output'];
  /** in case isCurrentlyAvailable is true, will just echo current time */
  nextAvailableOn: Scalars['DateTime']['output'];
};

export type ClinicBookingProviderExtensionModel = AbstractBookingProviderExtensionModel & {
  __typename?: 'ClinicBookingProviderExtensionModel';
  bookingType: ServiceBookingType;
  clinicCanBeBookedByPatient: Scalars['Boolean']['output'];
  clinicId: Scalars['String']['output'];
  clinicMembers: Array<PortalUserModel>;
  clinicName: Scalars['String']['output'];
  clinicSpecializations: Array<Specialization>;
};

/** A Chat which "topic" is a pair of Patient and Clinic */
export type ClinicChatModel = AbstractChatModel & {
  __typename?: 'ClinicChatModel';
  /** The Clinic this Chat is related to */
  clinic: ClinicModel;
  featuredItem?: Maybe<ChatItemModel>;
  featuredRequest?: Maybe<Request>;
  firstUnansweredPatientChatItem?: Maybe<ChatItemModel>;
  hasDicomAttachments: Scalars['Boolean']['output'];
  hasNonDicomAttachments: Scalars['Boolean']['output'];
  /** NB: for a given chat context, across multiple calls, it may be populated with different aliases (which are equivalent when used as input for other queries)For this reason, do not expect/validate any particular format/length. */
  id: Scalars['String']['output'];
  isPinnedToHome: Scalars['Boolean']['output'];
  items: Array<ChatItemModel>;
  lastItemTimestamp?: Maybe<Scalars['DateTime']['output']>;
  numberOfChatItems: Scalars['Float']['output'];
  /** The Patient this Chat is related to */
  patientPortalUser: PortalUserModel;
  /** The policy that will be applied in case the Patient participant should try to post a Direct Message to the Chat. */
  patientWritePolicy: AllowDenyPolicyType;
  /** (Based on the invoker user) the most recent *read* item in the timeline. */
  readTillItemIncluded?: Maybe<ChatItemModel>;
  unreadItemsCount: Scalars['Int']['output'];
};

export type ClinicDataArgs = {
  bookingCapacity: Scalars['Int']['input'];
  defaultPatientWritePolicy: AllowDenyPolicyType;
  description: Scalars['String']['input'];
  isEnabled: Scalars['Boolean']['input'];
  memberPortalUserIds: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  patientWriteButtonVisibilityPolicy: AllowDenyPolicyType;
  slug?: InputMaybe<Scalars['String']['input']>;
  specializations: Array<Specialization>;
};

export type ClinicDetailsUrlsModel = {
  __typename?: 'ClinicDetailsUrlsModel';
  external?: Maybe<Scalars['String']['output']>;
  internal: Scalars['String']['output'];
};

/** Patient-specific model of a clinic */
export type ClinicModel = {
  __typename?: 'ClinicModel';
  availability: ClinicAvailabilityModel;
  canPatientBook: Scalars['Boolean']['output'];
  capacity: Scalars['Int']['output'];
  defaultPatientWritePolicy: AllowDenyPolicyType;
  description?: Maybe<Scalars['String']['output']>;
  detailsUrls?: Maybe<ClinicDetailsUrlsModel>;
  extendedServices: Array<ContextAwareServiceModel>;
  id: Scalars['String']['output'];
  isEnabled: Scalars['Boolean']['output'];
  members: Array<PortalUserModel>;
  name: Scalars['String']['output'];
  patientWriteButtonVisibilityPolicy: AllowDenyPolicyType;
  portalEmail?: Maybe<Scalars['String']['output']>;
  portalExtension: ClinicPortalExtensionModel;
  /** @deprecated use extendedServices */
  services: Array<ServiceModel>;
  specializations: Array<Specialization>;
};

export type ClinicPortalExtensionFilterArgs = {
  gsdHsrClinicSubType?: InputMaybe<Array<GsdHsrClinicSubType>>;
};

export type ClinicPortalExtensionGsdHsrClinicModel = {
  __typename?: 'ClinicPortalExtensionGsdHsrClinicModel';
  subType: GsdHsrClinicSubType;
};

export type ClinicPortalExtensionModel = {
  __typename?: 'ClinicPortalExtensionModel';
  gsdHsrClinic?: Maybe<ClinicPortalExtensionGsdHsrClinicModel>;
};

export type ClosedRequestListFilters = {
  closedAt?: InputMaybe<TimeIntervalInput>;
  serviceBookingTypes?: InputMaybe<Array<ServiceBookingType>>;
  serviceTypes?: InputMaybe<Array<ServiceType>>;
};

export type ClosedRequestListOrderByArgs = {
  field: ClosedRequestListOrderByField;
  type?: InputMaybe<OrderByType>;
};

export enum ClosedRequestListOrderByField {
  CLOSED_AT = 'CLOSED_AT'
}

export type ClosedRequestModel = {
  __typename?: 'ClosedRequestModel';
  bookingType: ServiceBookingType;
  closedAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  patient: PatientModel;
  price: Scalars['Int']['output'];
  serviceTitle: Scalars['String']['output'];
};

export type CodeDeliveryDetailsModel = {
  __typename?: 'CodeDeliveryDetailsModel';
  attributeName: CognitoVerifyAttribute;
  deliveryMedium: Scalars['String']['output'];
  destination: Scalars['String']['output'];
};

export type CognitoAdminAuthModel = {
  __typename?: 'CognitoAdminAuthModel';
  cognitoAuthModel?: Maybe<CognitoAuthModel>;
  session?: Maybe<Scalars['String']['output']>;
};

export type CognitoAuthModel = {
  __typename?: 'CognitoAuthModel';
  accessToken: Scalars['String']['output'];
  expiresIn: Scalars['Int']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export enum CognitoVerifyAttribute {
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER'
}

export type CompetitiveSportCertificatePrefillableFormDataModel = {
  __typename?: 'CompetitiveSportCertificatePrefillableFormDataModel';
  correctiveLenses: Scalars['Boolean']['output'];
  sport: Scalars['String']['output'];
};

export type ConfirmDraftDirectMessageModel = {
  __typename?: 'ConfirmDraftDirectMessageModel';
  chatId: Scalars['String']['output'];
};

export type ConfirmedPdfParameterArgs = {
  parameter: SubSectionLabParameter;
  unitOfMeasure: LabResultParameterUoM;
  value: Scalars['String']['input'];
};

export type ConsultationModel = {
  __typename?: 'ConsultationModel';
  withPriority: Scalars['Int']['output'];
  withoutPriority: Scalars['Int']['output'];
};

export type ContextAwareServiceModel = {
  __typename?: 'ContextAwareServiceModel';
  addons: Array<ServiceAddonModel>;
  allowsOnDemandSchedule?: Maybe<Scalars['Boolean']['output']>;
  baseServiceType: ServiceType;
  bookingProviderExtension: AbstractBookingProviderExtensionModel;
  description: Scalars['String']['output'];
  doctorCanCreateRequestProposal: Scalars['Boolean']['output'];
  durationMinutes?: Maybe<Scalars['Float']['output']>;
  earliestAvailabilityDate?: Maybe<Scalars['DateTime']['output']>;
  giftType: ServiceGiftType;
  id: Scalars['String']['output'];
  integrationServiceType?: Maybe<IntegrationServiceType>;
  isEnabled: Scalars['Boolean']['output'];
  isGiftService?: Maybe<Scalars['Boolean']['output']>;
  patientBookingRequiresRequestProposal: Scalars['Boolean']['output'];
  portalExtension: ServicePortalExtensionModel;
  price: Scalars['Float']['output'];
  providedWithinDays?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type CountryModel = {
  __typename?: 'CountryModel';
  isoCountryCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CreateAddonArgs = {
  price: Scalars['Float']['input'];
  providedWithinDays?: InputMaybe<Scalars['Float']['input']>;
  type: ServiceAddonType;
};

export type CreateClinicServiceArgs = {
  addons: Array<CreateAddonArgs>;
  baseServiceType: ServiceType;
  description: Scalars['String']['input'];
  doctorCanCreateRequestProposal?: Scalars['Boolean']['input'];
  durationMinutes?: InputMaybe<Scalars['Float']['input']>;
  isEnabled: Scalars['Boolean']['input'];
  patientBookingRequiresRequestProposal?: Scalars['Boolean']['input'];
  price: Scalars['Float']['input'];
  providedWithinDays?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
};

export type CreateInviteArgs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  institutionId: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  profileType: ProfileType;
};

export enum CsvDelimiter {
  COMMA = 'COMMA',
  SEMICOLON = 'SEMICOLON'
}

export type CsvExportTypeModel = {
  __typename?: 'CsvExportTypeModel';
  path: Scalars['String']['output'];
  type: CSVExportType;
};

export enum CtnmMedicalIndexMParameter {
  M0 = 'M0',
  M1 = 'M1'
}

export enum CtnmMedicalIndexNParameter {
  MORE_THAN_OR_EQUAL_TO_N1 = 'MORE_THAN_OR_EQUAL_TO_N1',
  N0 = 'N0'
}

export type CtnmMedicalIndexParametersModel = {
  mParameter: CtnmMedicalIndexMParameter;
  nParameter: CtnmMedicalIndexNParameter;
  tParameter: CtnmMedicalIndexTParameter;
};

export enum CtnmMedicalIndexTParameter {
  T1A = 'T1A',
  T1B = 'T1B',
  T2A = 'T2A',
  T2B = 'T2B',
  T3A = 'T3A',
  T3B = 'T3B',
  T4A = 'T4A',
  T4B = 'T4B',
  TIS = 'TIS'
}

export enum CurrencyCode {
  EUR = 'EUR',
  USD = 'USD'
}

export type CurrentClinicalEvaluationGenericNotesData = {
  __typename?: 'CurrentClinicalEvaluationGenericNotesData';
  notes: Scalars['String']['output'];
};

export type CurrentClinicalEvaluationGenericNotesDataArgs = {
  notes: Scalars['String']['input'];
};

export type CurrentClinicalEvaluationGenericNotesHistory = {
  __typename?: 'CurrentClinicalEvaluationGenericNotesHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: CurrentClinicalEvaluationGenericNotesData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type DashboardNotification = {
  __typename?: 'DashboardNotification';
  answerDelayDays?: Maybe<Scalars['Int']['output']>;
  answerStatus?: Maybe<Scalars['String']['output']>;
  attachment: Array<NotificationAttachment>;
  attachmentsPresent: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  dicomPresent: Scalars['Boolean']['output'];
  flag: Array<NotificationFlag>;
  id: Scalars['String']['output'];
  isUnread: Scalars['Boolean']['output'];
  isVisible: Scalars['Boolean']['output'];
  object: DashboardNotificationObject;
  requestShouldBeAnsweredBy?: Maybe<Scalars['DateTime']['output']>;
  sender: NotificationSender;
  subject: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DashboardNotificationObject = {
  __typename?: 'DashboardNotificationObject';
  createdAt: Scalars['DateTime']['output'];
  entity?: Maybe<DashboardNotificationObjectUnion>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  patient?: Maybe<Patient>;
  patientInstitutionUser?: Maybe<InstitutionUser>;
  recipients?: Maybe<Array<DashboardThreadRecipientUnion>>;
  type: Scalars['String']['output'];
};

export type DashboardNotificationObjectUnion = InfoThread | IntramedThread;

export type DashboardThreadRecipientUnion = InfoThreadRecipient | IntramedThreadRecipient | RequestRecipient;

export type DefaultQuestionValueModel = {
  __typename?: 'DefaultQuestionValueModel';
  otherAnswerValue?: Maybe<Array<Scalars['String']['output']>>;
  questionCode: Scalars['String']['output'];
  value?: Maybe<Array<Scalars['String']['output']>>;
};

export type DefaultServiceConfigurationsModel = {
  __typename?: 'DefaultServiceConfigurationsModel';
  consultation: ConsultationModel;
  videovisit: VideovisitModel;
};

export enum Degree {
  BIOLOGY_AND_SCIENCES = 'BIOLOGY_AND_SCIENCES',
  DENTISTRY_AND_DENTAL_PROSTHETICS = 'DENTISTRY_AND_DENTAL_PROSTHETICS',
  DIETETICS = 'DIETETICS',
  FOOD_SCIENCE_AND_NUTRITION = 'FOOD_SCIENCE_AND_NUTRITION',
  HEALTH_NURSING = 'HEALTH_NURSING',
  MEDICINE_AND_SURGERY = 'MEDICINE_AND_SURGERY',
  OBSTETRIC_HEALTHCARE = 'OBSTETRIC_HEALTHCARE',
  OCCUPATIONAL_THERAPY = 'OCCUPATIONAL_THERAPY',
  PHARMACEUTICAL_TECHNOLOGY_AND_CHEMISTRY = 'PHARMACEUTICAL_TECHNOLOGY_AND_CHEMISTRY',
  PHARMACY = 'PHARMACY',
  PHYSIOTHERAPY = 'PHYSIOTHERAPY',
  PSYCHOLOGY = 'PSYCHOLOGY',
  SOCIAL_SERVICE = 'SOCIAL_SERVICE',
  SPEECH_THERAPY = 'SPEECH_THERAPY',
  VETERINARY_MEDICINE = 'VETERINARY_MEDICINE'
}

export type DeviceOtpChallengeModel = {
  __typename?: 'DeviceOtpChallengeModel';
  /** target user has this amount of minutes to fulfill the challenge and verify/execute the request */
  expiresInMinutes: Scalars['Int']['output'];
};

export type DiagnosesAhusDiagnosisDateData = {
  __typename?: 'DiagnosesAhusDiagnosisDateData';
  value: Scalars['DateTime']['output'];
};

export type DiagnosesAhusDiagnosisDateDataArgs = {
  value: Scalars['DateTime']['input'];
};

export type DiagnosesAhusDiagnosisDateHistory = {
  __typename?: 'DiagnosesAhusDiagnosisDateHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: DiagnosesAhusDiagnosisDateData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type DiagnosesDiseaseGenericNotesDataArgs = {
  icd9Diagnoses?: InputMaybe<Array<ICD9DiagnosisCodeModel>>;
  notes: Scalars['String']['input'];
};

export type DiagnosesDiseaseStageData = {
  __typename?: 'DiagnosesDiseaseStageData';
  stage: DiagnosesDiseaseStageEnum;
};

export type DiagnosesDiseaseStageDataArgs = {
  stage: DiagnosesDiseaseStageEnum;
};

export enum DiagnosesDiseaseStageEnum {
  ACCELERATED = 'ACCELERATED',
  BLAST = 'BLAST',
  CHRONIC = 'CHRONIC'
}

export type DiagnosesDiseaseStageHistory = {
  __typename?: 'DiagnosesDiseaseStageHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: DiagnosesDiseaseStageData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type DiagnosesGenericNotesData = {
  __typename?: 'DiagnosesGenericNotesData';
  icd9Diagnoses: Array<ICD9DiagnosisModel>;
  notes: Scalars['String']['output'];
};

export type DiagnosesGenericNotesHistory = {
  __typename?: 'DiagnosesGenericNotesHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: DiagnosesGenericNotesData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type DiagnosesNetGepGradingData = {
  __typename?: 'DiagnosesNetGepGradingData';
  gepGrading: GepGrading;
};

export type DiagnosesNetGepGradingDataArgs = {
  gepGrading: GepGrading;
};

export type DiagnosesNetGepGradingHistory = {
  __typename?: 'DiagnosesNetGepGradingHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: DiagnosesNetGepGradingData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type DiagnosesNetThoraxGradingData = {
  __typename?: 'DiagnosesNetThoraxGradingData';
  thoraxGrading: ThoraxGrading;
};

export type DiagnosesNetThoraxGradingDataArgs = {
  thoraxGrading: ThoraxGrading;
};

export type DiagnosesNetThoraxGradingHistory = {
  __typename?: 'DiagnosesNetThoraxGradingHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: DiagnosesNetThoraxGradingData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type DiagnosesNetThoraxStagingTnmAsco2021Data = {
  __typename?: 'DiagnosesNetThoraxStagingTnmAsco2021Data';
  thoraxStagingTnmAsco2021: ThoraxStagingTnmAsco2021;
};

export type DiagnosesNetThoraxStagingTnmAsco2021DataArgs = {
  thoraxStagingTnmAsco2021: ThoraxStagingTnmAsco2021;
};

export type DiagnosesNetThoraxStagingTnmAsco2021History = {
  __typename?: 'DiagnosesNetThoraxStagingTnmAsco2021History';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: DiagnosesNetThoraxStagingTnmAsco2021Data;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type DicomDiskModel = {
  __typename?: 'DicomDiskModel';
  id: Scalars['String']['output'];
};

export enum Differentiation {
  MANECS = 'MANECS',
  PD_NETS = 'PD_NETS',
  WD_NETS = 'WD_NETS'
}

export type DipssMedicalIndexParametersModel = {
  ageIsMoreThan65: Scalars['Boolean']['input'];
  constitutionalSymptoms: Scalars['Boolean']['input'];
  hemoglobinIsLessThan10: Scalars['Boolean']['input'];
  peripheralBloodBlastsIsMoreThanOrEqualTo1: Scalars['Boolean']['input'];
  whiteBloodCellCountIsMoreThan25: Scalars['Boolean']['input'];
};

export type DipssPlusMedicalIndexParametersModel = {
  ageIsMoreThan65: Scalars['Boolean']['input'];
  constitutionalSymptoms: Scalars['Boolean']['input'];
  hemoglobinIsLessThan10: Scalars['Boolean']['input'];
  peripheralBloodBlastsIsMoreThanOrEqualTo1: Scalars['Boolean']['input'];
  plateletsIsLessThan100: Scalars['Boolean']['input'];
  redCellTransfusionDependent: Scalars['Boolean']['input'];
  unfavorableKaryotype: Scalars['Boolean']['input'];
  whiteBloodCellCountIsMoreThan25: Scalars['Boolean']['input'];
};

export type DirectMessageModel = {
  __typename?: 'DirectMessageModel';
  attachments: Array<FileModel>;
  sender: PortalUserModel;
  /** the contents are filtered/sanitized */
  text?: Maybe<Scalars['String']['output']>;
};

export type Doctor = {
  __typename?: 'Doctor';
  availability: DoctorAvailabilityModel;
  createdAt: Scalars['DateTime']['output'];
  defaultPatientWritePolicy: AllowDenyPolicyType;
  detailsUrls: DoctorDetailsUrlsModel;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  identityVerificationMethod?: Maybe<IdentityVerificationMethod>;
  insurance?: Maybe<DoctorInsurance>;
  isInfoManagementHasBeenDelegatedToSecretaries: Scalars['Boolean']['output'];
  medicalRole?: Maybe<MedicalRoleModel>;
  patientWriteButtonVisibilityPolicy: AllowDenyPolicyType;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  portalEmail?: Maybe<Scalars['String']['output']>;
  portalUser: PortalUserModel;
  professionalData?: Maybe<DoctorProfessionalData>;
  /** @deprecated Use getFileUrl or mngGetFileUrl queries instead */
  profileImageFile?: Maybe<File>;
  regionalCode?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  specializations: Array<DoctorSpecializationModel>;
  sphereOfInterest?: Maybe<Array<Scalars['String']['output']>>;
  sphereOfInterestUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use getFileUrl or mngGetFileUrl queries instead */
  thumbnailImageFile?: Maybe<File>;
  titleCode: UserTitle;
  updatedAt: Scalars['DateTime']['output'];
};

export type DoctorAdditionalProfileDataArgs = {
  insurance?: InputMaybe<DoctorInsuranceDataArgs>;
  professionalData?: InputMaybe<DoctorProfessionalDataArgs>;
  specializations?: Array<AddDoctorSpecializationArgs>;
};

export type DoctorAffiliationListFilterByArgs = {
  affiliationIdsFilter?: InputMaybe<Array<Scalars['String']['input']>>;
  isExpiredFilter?: InputMaybe<Scalars['Boolean']['input']>;
  joinStatusFilter?: InputMaybe<AffiliationDoctorJoinStatus>;
  nameFilter?: InputMaybe<Scalars['String']['input']>;
};

export type DoctorAvailabilityModel = {
  __typename?: 'DoctorAvailabilityModel';
  isCurrentlyAvailable: Scalars['Boolean']['output'];
  /** in case isCurrentlyAvailable is true, will just echo current time */
  nextAvailableOn: Scalars['DateTime']['output'];
};

/** NB: HSR-specific */
export type DoctorAvailableServiceAddonFromIntegrationModel = {
  __typename?: 'DoctorAvailableServiceAddonFromIntegrationModel';
  price: Scalars['Float']['output'];
  type: ServiceAddonType;
};

/** NB: HSR-specific */
export type DoctorAvailableServiceFromIntegrationModel = {
  __typename?: 'DoctorAvailableServiceFromIntegrationModel';
  addons: Array<DoctorAvailableServiceAddonFromIntegrationModel>;
  integrationServiceType: IntegrationServiceType;
  price: Scalars['Float']['output'];
  serviceType: ServiceType;
};

export type DoctorBookingProviderExtensionModel = AbstractBookingProviderExtensionModel & {
  __typename?: 'DoctorBookingProviderExtensionModel';
  bookingType: ServiceBookingType;
};

/** Status information the Doctor self-assigning the Request should be informed about */
export enum DoctorCanSelfAssignNotice {
  /** The provided Request requires scheduling and overlaps with one or more existing appointments in the Doctor's Calendar. */
  OVERLAPS_IN_CALENDAR = 'OVERLAPS_IN_CALENDAR'
}

export type DoctorCanSelfAssignRequestResponseModel = {
  __typename?: 'DoctorCanSelfAssignRequestResponseModel';
  canSelfAssign: Scalars['Boolean']['output'];
  /** Non-null when canSelfAssign is false (use for troubleshooting) */
  cannotAssignReason?: Maybe<Scalars['String']['output']>;
  /** Only gets populated when canSelfAssign is true */
  notices: Array<DoctorCanSelfAssignNotice>;
};

export type DoctorClinicListFilterByArgs = {
  amMemberFilter?: InputMaybe<Scalars['Boolean']['input']>;
  canBookForPatientFilter?: InputMaybe<Scalars['Boolean']['input']>;
  portalExtensionFilter?: InputMaybe<ClinicPortalExtensionFilterArgs>;
  searchByFilter?: InputMaybe<Scalars['String']['input']>;
  specializationsFilter: Array<Specialization>;
};

export type DoctorClinicModel = {
  __typename?: 'DoctorClinicModel';
  amMember: Scalars['Boolean']['output'];
  availability: ClinicAvailabilityModel;
  canBookForPatient: Scalars['Boolean']['output'];
  canPatientBook: Scalars['Boolean']['output'];
  capacity: Scalars['Int']['output'];
  defaultPatientWritePolicy: AllowDenyPolicyType;
  description?: Maybe<Scalars['String']['output']>;
  detailsUrls?: Maybe<ClinicDetailsUrlsModel>;
  extendedServices: Array<ContextAwareServiceModel>;
  id: Scalars['String']['output'];
  isEnabled: Scalars['Boolean']['output'];
  members: Array<PortalUserModel>;
  name: Scalars['String']['output'];
  patientWriteButtonVisibilityPolicy: AllowDenyPolicyType;
  portalEmail?: Maybe<Scalars['String']['output']>;
  portalExtension: ClinicPortalExtensionModel;
  /** @deprecated use extendedServices */
  services: Array<ServiceModel>;
  specializations: Array<Specialization>;
};

export type DoctorDetailsUrlsModel = {
  __typename?: 'DoctorDetailsUrlsModel';
  external?: Maybe<Scalars['String']['output']>;
  internal: Scalars['String']['output'];
};

export type DoctorInfoRequestAcceptanceModel = {
  __typename?: 'DoctorInfoRequestAcceptanceModel';
  acceptsInfoRequest: Scalars['Boolean']['output'];
};

export type DoctorInsurance = {
  __typename?: 'DoctorInsurance';
  company?: Maybe<Scalars['String']['output']>;
  expiringDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  number?: Maybe<Scalars['String']['output']>;
};

export type DoctorInsuranceDataArgs = {
  company?: InputMaybe<Scalars['String']['input']>;
  expiringDate: Scalars['DateTime']['input'];
  number?: InputMaybe<Scalars['String']['input']>;
};

/** NB: HSR-specific */
export type DoctorIntegrationServicesModel = {
  __typename?: 'DoctorIntegrationServicesModel';
  allServices: Array<DoctorAvailableServiceFromIntegrationModel>;
  availableServices: Array<DoctorMatchedServiceFromIntegrationModel>;
};

/** NB: HSR-specific */
export type DoctorMatchedServiceAddonFromIntegrationModel = {
  __typename?: 'DoctorMatchedServiceAddonFromIntegrationModel';
  id: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  providedWithinDays?: Maybe<Scalars['Float']['output']>;
  type: ServiceAddonType;
};

/** NB: HSR-specific */
export type DoctorMatchedServiceFromIntegrationModel = {
  __typename?: 'DoctorMatchedServiceFromIntegrationModel';
  addons: Array<DoctorMatchedServiceAddonFromIntegrationModel>;
  allowsOnDemandSchedule?: Maybe<Scalars['Boolean']['output']>;
  description: Scalars['String']['output'];
  doctorCanCreateRequestProposal: Scalars['Boolean']['output'];
  durationMinutes?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  integrationServiceType: IntegrationServiceType;
  isEnabled: Scalars['Boolean']['output'];
  patientBookingRequiresRequestProposal: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
  providedWithinDays?: Maybe<Scalars['Float']['output']>;
  serviceType: ServiceType;
  title: Scalars['String']['output'];
};

export type DoctorModel = {
  __typename?: 'DoctorModel';
  affiliations: Array<AffiliationForDoctorModel>;
  availability: DoctorAvailabilityModel;
  createdAt: Scalars['DateTime']['output'];
  cvFile?: Maybe<FileModel>;
  defaultPatientWritePolicy: AllowDenyPolicyType;
  detailsUrls: DoctorDetailsUrlsModel;
  doctorsSecretaryAssociations: Array<AssociatedUserModel>;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  identityVerificationMethod?: Maybe<IdentityVerificationMethod>;
  institutionUser: InstitutionUserModel;
  insuranceExpiringDate?: Maybe<Scalars['DateTime']['output']>;
  isInfoManagementHasBeenDelegatedToSecretaries: Scalars['Boolean']['output'];
  medicalRole?: Maybe<MedicalRoleModel>;
  patientWriteButtonVisibilityPolicy: AllowDenyPolicyType;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  portalEmail?: Maybe<Scalars['String']['output']>;
  portalUser: PortalUserModel;
  privacies: Array<UserPrivacyModel>;
  professionalData?: Maybe<DoctorProfessionalDataModel>;
  regionalCode?: Maybe<Scalars['String']['output']>;
  services: Array<ServiceModel>;
  slug: Scalars['String']['output'];
  specializations: Array<DoctorSpecializationModel>;
  sphereOfInterest: Array<Scalars['String']['output']>;
  sphereOfInterestUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  titleCode: UserTitle;
  updatedAt: Scalars['DateTime']['output'];
};

export type DoctorNonDoctorPortalUserModelUnion = DoctorPortalUserModel | NonDoctorPortalUserModel;

export type DoctorPortalUserModel = {
  __typename?: 'DoctorPortalUserModel';
  doctor: DoctorModel;
  id: Scalars['String']['output'];
  institution: InstitutionModel;
  portal: PortalModel;
  user: UserModel;
};

export type DoctorProfessionalData = {
  __typename?: 'DoctorProfessionalData';
  bio?: Maybe<Scalars['String']['output']>;
  cvFile?: Maybe<File>;
  cvFileId?: Maybe<Scalars['String']['output']>;
  degree?: Maybe<Degree>;
  id: Scalars['String']['output'];
  licenseUniversity?: Maybe<Scalars['String']['output']>;
  licenseYear?: Maybe<Scalars['Float']['output']>;
  registrationCity?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  registrationProfessional?: Maybe<ProfessionalRegister>;
  registrationYear?: Maybe<Scalars['Float']['output']>;
  universityOfDegree?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  yearOfDegree?: Maybe<Scalars['Float']['output']>;
};

export type DoctorProfessionalDataArgs = {
  bio?: InputMaybe<Scalars['String']['input']>;
  cvFileId?: InputMaybe<Scalars['String']['input']>;
  degree?: InputMaybe<Degree>;
  licenseUniversity?: InputMaybe<Scalars['String']['input']>;
  licenseYear?: InputMaybe<Scalars['Float']['input']>;
  registrationCity?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  registrationProfessional?: InputMaybe<ProfessionalRegister>;
  registrationYear?: InputMaybe<Scalars['Float']['input']>;
  universityOfDegree?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  yearOfDegree?: InputMaybe<Scalars['Float']['input']>;
};

export type DoctorProfessionalDataModel = {
  __typename?: 'DoctorProfessionalDataModel';
  bio?: Maybe<Scalars['String']['output']>;
  cvFile?: Maybe<FileModel>;
  degree?: Maybe<Degree>;
  licenseUniversity?: Maybe<Scalars['String']['output']>;
  licenseYear?: Maybe<Scalars['Float']['output']>;
  registrationCity?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  registrationProfessional?: Maybe<ProfessionalRegister>;
  registrationYear?: Maybe<Scalars['Float']['output']>;
  universityOfDegree?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  yearOfDegree?: Maybe<Scalars['Float']['output']>;
};

export type DoctorServiceProviderModel = AbstractServiceProviderModel & {
  __typename?: 'DoctorServiceProviderModel';
  doctorPortalUser: PortalUserModel;
  serviceProviderType: ServiceProviderType;
};

export type DoctorSpecializationModel = {
  __typename?: 'DoctorSpecializationModel';
  category: SpecializationCategory;
  id: Scalars['String']['output'];
  pathologies: Array<Pathology>;
  symptoms: Array<Symptom>;
  type: Specialization;
  university?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

export type DoctorSurveyListEntryModel = {
  __typename?: 'DoctorSurveyListEntryModel';
  latestAnswerDate?: Maybe<Scalars['DateTime']['output']>;
  recipientPortalUser: PortalUserModel;
  survey: SurveyModel;
};

export type DoctorsGlobalSearchDetailsModel = {
  __typename?: 'DoctorsGlobalSearchDetailsModel';
  items: Array<ServicesByContextModel>;
  totalCount: Scalars['Int']['output'];
};

export type DoctorsSecretaryAssociation = {
  __typename?: 'DoctorsSecretaryAssociation';
  associatedInstitutionUser: InstitutionUser;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isManagingDoctorsInfoRequests: Scalars['Boolean']['output'];
  isManagingPatientsMedicalData: Scalars['Boolean']['output'];
  onBehalfOfInstitutionUser: InstitutionUser;
};

export type DraftAccountingEntryModel = {
  __typename?: 'DraftAccountingEntryModel';
  data: AccountingEntryDataModelV2;
  totalInvoice: Scalars['Float']['output'];
  totalPaid: Scalars['Float']['output'];
};

export type DraftDirectMessageConfirmedModel = {
  __typename?: 'DraftDirectMessageConfirmedModel';
  chatId: Scalars['String']['output'];
};

export type DraftDirectMessageModel = {
  __typename?: 'DraftDirectMessageModel';
  id: Scalars['String']['output'];
  userStatus: DraftDirectMessageUserStatus;
};

export enum DraftDirectMessageUserStatus {
  UNKNOWN_USER = 'UNKNOWN_USER',
  USER_EXISTS_WITHOUT_PATIENT = 'USER_EXISTS_WITHOUT_PATIENT',
  USER_EXISTS_WITH_INCOMPLETE_PATIENT_REGISTRATION = 'USER_EXISTS_WITH_INCOMPLETE_PATIENT_REGISTRATION',
  USER_EXISTS_WITH_PATIENT = 'USER_EXISTS_WITH_PATIENT'
}

export enum ECGResult {
  NORMAL = 'NORMAL',
  PATHOLOGICAL = 'PATHOLOGICAL'
}

export type EditAddonArgs = {
  price: Scalars['Float']['input'];
  providedWithinDays?: InputMaybe<Scalars['Float']['input']>;
  type: ServiceAddonType;
};

export type EltsMedicalIndexParametersModel = {
  ageInYears: Scalars['Float']['input'];
  myeloblastsPercentage: Scalars['Float']['input'];
  plateletCount: Scalars['Float']['input'];
  spleenSizeCm: Scalars['Float']['input'];
};

export enum EmailNotificationPeriod {
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
  MORNING = 'MORNING'
}

export type EntityDeleteResultModel = {
  __typename?: 'EntityDeleteResultModel';
  deletedCount: Scalars['Int']['output'];
  entityName: Scalars['String']['output'];
};

export type Equipe = {
  __typename?: 'Equipe';
  id: Scalars['String']['output'];
  institution: Institution;
  institutionUser: Array<InstitutionUser>;
  membersCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  portal: Portal;
};

export type EquipeModel = {
  __typename?: 'EquipeModel';
  id: Scalars['String']['output'];
  institution: InstitutionModel;
  members: Array<DoctorNonDoctorPortalUserModelUnion>;
  name: Scalars['String']['output'];
  portal: PortalModel;
};

export enum ErrorCodes {
  ACCESS_DENIED = 'ACCESS_DENIED',
  CHAT_WRITE_POLICY_ACCESS_DENIED = 'CHAT_WRITE_POLICY_ACCESS_DENIED',
  CLINIC_SLUG_NOT_AVAILABLE_ERROR = 'CLINIC_SLUG_NOT_AVAILABLE_ERROR',
  COULD_NOT_MATCH_ON_HEALTH_PATIENT_FISCAL_CODE = 'COULD_NOT_MATCH_ON_HEALTH_PATIENT_FISCAL_CODE',
  COULD_NOT_MATCH_ON_HEALTH_PATIENT_PHONE_NUMBER = 'COULD_NOT_MATCH_ON_HEALTH_PATIENT_PHONE_NUMBER',
  FILE_ERROR = 'FILE_ERROR',
  FILE_MIMETYPE_IS_NOT_SUPPORTED = 'FILE_MIMETYPE_IS_NOT_SUPPORTED',
  FILE_SIZE_ERROR = 'FILE_SIZE_ERROR',
  FISCAL_CODE_DOES_NOT_CORRELATE_TO_BIRTH_DATE = 'FISCAL_CODE_DOES_NOT_CORRELATE_TO_BIRTH_DATE',
  GSD_INVALID_BOOKING_DATA = 'GSD_INVALID_BOOKING_DATA',
  INCORRECT_OTP = 'INCORRECT_OTP',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  INVITE_ALREADY_ACCEPTED = 'INVITE_ALREADY_ACCEPTED',
  INVITE_NOT_FOUND = 'INVITE_NOT_FOUND',
  NOT_ACTIVATED_OTP = 'NOT_ACTIVATED_OTP',
  OPEN_REQUEST_EXISTS = 'OPEN_REQUEST_EXISTS',
  SEARCH_PIT_EXPIRED = 'SEARCH_PIT_EXPIRED'
}

export enum ExternalIntegrationStatus {
  ERROR = 'ERROR',
  NO_INFO = 'NO_INFO',
  OK = 'OK'
}

export type FamilyAnamnesisCardiovascularDiseaseData = {
  __typename?: 'FamilyAnamnesisCardiovascularDiseaseData';
  knownReason?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type FamilyAnamnesisCardiovascularDiseaseDataArgs = {
  knownReason?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type FamilyAnamnesisCardiovascularDiseaseHistory = {
  __typename?: 'FamilyAnamnesisCardiovascularDiseaseHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: FamilyAnamnesisCardiovascularDiseaseData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type FamilyAnamnesisGenericNotesData = {
  __typename?: 'FamilyAnamnesisGenericNotesData';
  notes: Scalars['String']['output'];
};

export type FamilyAnamnesisGenericNotesDataArgs = {
  notes: Scalars['String']['input'];
};

export type FamilyAnamnesisGenericNotesHistory = {
  __typename?: 'FamilyAnamnesisGenericNotesHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: FamilyAnamnesisGenericNotesData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type FamilyAnamnesisKidneyFailureData = {
  __typename?: 'FamilyAnamnesisKidneyFailureData';
  knownReason?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type FamilyAnamnesisKidneyFailureDataArgs = {
  knownReason?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type FamilyAnamnesisKidneyFailureHistory = {
  __typename?: 'FamilyAnamnesisKidneyFailureHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: FamilyAnamnesisKidneyFailureData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type FamilyAnamnesisNetGastricUlcersData = {
  __typename?: 'FamilyAnamnesisNetGastricUlcersData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetGastricUlcersDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetGastricUlcersHistory = {
  __typename?: 'FamilyAnamnesisNetGastricUlcersHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: FamilyAnamnesisNetGastricUlcersData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type FamilyAnamnesisNetGlucidicMetabolismAlterationData = {
  __typename?: 'FamilyAnamnesisNetGlucidicMetabolismAlterationData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetGlucidicMetabolismAlterationDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetGlucidicMetabolismAlterationHistory = {
  __typename?: 'FamilyAnamnesisNetGlucidicMetabolismAlterationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: FamilyAnamnesisNetGlucidicMetabolismAlterationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type FamilyAnamnesisNetHypercalcemiaData = {
  __typename?: 'FamilyAnamnesisNetHypercalcemiaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetHypercalcemiaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetHypercalcemiaHistory = {
  __typename?: 'FamilyAnamnesisNetHypercalcemiaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: FamilyAnamnesisNetHypercalcemiaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type FamilyAnamnesisNetHypophysisAdenomaData = {
  __typename?: 'FamilyAnamnesisNetHypophysisAdenomaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetHypophysisAdenomaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetHypophysisAdenomaHistory = {
  __typename?: 'FamilyAnamnesisNetHypophysisAdenomaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: FamilyAnamnesisNetHypophysisAdenomaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type FamilyAnamnesisNetMultipleEndocrineNeoplasiaData = {
  __typename?: 'FamilyAnamnesisNetMultipleEndocrineNeoplasiaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetMultipleEndocrineNeoplasiaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetMultipleEndocrineNeoplasiaHistory = {
  __typename?: 'FamilyAnamnesisNetMultipleEndocrineNeoplasiaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: FamilyAnamnesisNetMultipleEndocrineNeoplasiaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type FamilyAnamnesisNetNephrolithiasisData = {
  __typename?: 'FamilyAnamnesisNetNephrolithiasisData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetNephrolithiasisDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetNephrolithiasisHistory = {
  __typename?: 'FamilyAnamnesisNetNephrolithiasisHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: FamilyAnamnesisNetNephrolithiasisData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type FamilyAnamnesisNetNeuroendocrineTumorsData = {
  __typename?: 'FamilyAnamnesisNetNeuroendocrineTumorsData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetNeuroendocrineTumorsDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetNeuroendocrineTumorsHistory = {
  __typename?: 'FamilyAnamnesisNetNeuroendocrineTumorsHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: FamilyAnamnesisNetNeuroendocrineTumorsData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type FamilyAnamnesisNetOtherNeoplasiaData = {
  __typename?: 'FamilyAnamnesisNetOtherNeoplasiaData';
  diagnoses?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetOtherNeoplasiaDataArgs = {
  diagnoses?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type FamilyAnamnesisNetOtherNeoplasiaHistory = {
  __typename?: 'FamilyAnamnesisNetOtherNeoplasiaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: FamilyAnamnesisNetOtherNeoplasiaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type File = {
  __typename?: 'File';
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  dateOfExecution: Scalars['DateTime']['output'];
  hasAnyPdfParametersRevision: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  invoice?: Maybe<Invoice>;
  isCancellable: Scalars['Boolean']['output'];
  isModifiable: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  pdfData?: Maybe<PDFDataStorage>;
  pdfHeaderExtracted: Scalars['Boolean']['output'];
  pdfParametersRevision?: Maybe<PdfParametersRevisionModel>;
  receipt?: Maybe<Invoice>;
  tag?: Maybe<FileTag>;
  type: FileType;
  uploadedAt: Scalars['DateTime']['output'];
  uploaderInstitutionUser?: Maybe<InstitutionUser>;
};

export type FileListFilterByArgs = {
  isCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type FileModel = {
  __typename?: 'FileModel';
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  dateOfExecution: Scalars['DateTime']['output'];
  hasAnyPdfParametersRevision: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isCancellable: Scalars['Boolean']['output'];
  isModifiable: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  pdfData?: Maybe<PDFDataStorage>;
  pdfHeaderExtracted: Scalars['Boolean']['output'];
  pdfParametersRevision?: Maybe<PdfParametersRevisionModel>;
  tag?: Maybe<FileTag>;
  type: FileType;
  uploadedAt: Scalars['DateTime']['output'];
  uploaderInstitutionUser?: Maybe<InstitutionUserModel>;
};

export enum FileTag {
  ACCOUNTING_ENTRY = 'ACCOUNTING_ENTRY',
  ALLERGIES = 'ALLERGIES',
  BODY_PART_PHOTO = 'BODY_PART_PHOTO',
  CERTIFICATE_EXEMPTION_TREATMENT_PLAN = 'CERTIFICATE_EXEMPTION_TREATMENT_PLAN',
  CURRENT_MEDICATION = 'CURRENT_MEDICATION',
  DIAGNOSE = 'DIAGNOSE',
  DICOM = 'DICOM',
  DICOM_IMAGE = 'DICOM_IMAGE',
  DICOM_REPORT = 'DICOM_REPORT',
  EXPORT_INVOICE = 'EXPORT_INVOICE',
  FAMILY_ANAMNESIS = 'FAMILY_ANAMNESIS',
  FAMILY_HISTORY = 'FAMILY_HISTORY',
  HOSPITAL_DISCHARGE_LETTERS = 'HOSPITAL_DISCHARGE_LETTERS',
  IMAGE = 'IMAGE',
  INFORMED_CONSENT = 'INFORMED_CONSENT',
  INVOICE = 'INVOICE',
  LAB_RESULT = 'LAB_RESULT',
  PARAMETER = 'PARAMETER',
  PATHOLOGY_RESULT = 'PATHOLOGY_RESULT',
  PATIENT_UNRELATED_DOCUMENT = 'PATIENT_UNRELATED_DOCUMENT',
  PRESCRIPTION = 'PRESCRIPTION',
  PSYCHOLOGICAL_HISTORY = 'PSYCHOLOGICAL_HISTORY',
  RECEIPT = 'RECEIPT',
  REFUND = 'REFUND',
  REFUND_RECEIPT = 'REFUND_RECEIPT',
  REPORT = 'REPORT',
  SIGNS_AND_SYMPTOMS = 'SIGNS_AND_SYMPTOMS',
  SURVEY = 'SURVEY',
  SURVEY_ANSWERS = 'SURVEY_ANSWERS',
  UPLOADED_INVOICE = 'UPLOADED_INVOICE',
  UPLOADED_REFUND = 'UPLOADED_REFUND',
  VACCINATION = 'VACCINATION'
}

export enum FileType {
  ACCOUNTING_ENTRY = 'ACCOUNTING_ENTRY',
  CV = 'CV',
  DICOM = 'DICOM',
  DICOM_REPORT = 'DICOM_REPORT',
  EXPORT_INVOICE = 'EXPORT_INVOICE',
  INFORMED_CONSENT = 'INFORMED_CONSENT',
  INVOICE = 'INVOICE',
  MEDICAL_PARAMETER = 'MEDICAL_PARAMETER',
  PATIENT_UNCLASSIFIED = 'PATIENT_UNCLASSIFIED',
  PORTAL_DOCUMENT = 'PORTAL_DOCUMENT',
  PRESCRIPTION = 'PRESCRIPTION',
  PRIVACY = 'PRIVACY',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
  RECEIPT = 'RECEIPT',
  REFUND = 'REFUND',
  REFUND_RECEIPT = 'REFUND_RECEIPT',
  REPORT = 'REPORT',
  SPORT_CERTIFICATE_COMPETITIVE = 'SPORT_CERTIFICATE_COMPETITIVE',
  SPORT_CERTIFICATE_NON_COMPETITIVE = 'SPORT_CERTIFICATE_NON_COMPETITIVE',
  SURVEY_ANSWERS = 'SURVEY_ANSWERS',
  UPLOADED_INVOICE = 'UPLOADED_INVOICE',
  UPLOADED_REFUND = 'UPLOADED_REFUND',
  USER_CLAIM = 'USER_CLAIM'
}

export type FileUploadResultModel = {
  __typename?: 'FileUploadResultModel';
  dateOfExecution: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isPdfDataExtractable: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  suggestions: FileUploadResultSuggestionModel;
  tag?: Maybe<FileTag>;
  type: FileType;
  uploadedAt: Scalars['DateTime']['output'];
};

export type FileUploadResultSuggestionModel = {
  __typename?: 'FileUploadResultSuggestionModel';
  dateOfExecution?: Maybe<Scalars['DateTime']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<FileTag>;
};

export type FilterByArgs = {
  answerStatus?: InputMaybe<RequestAnswerStatus>;
  type?: InputMaybe<NotificationType>;
};

export enum FitzpatrickScale {
  I = 'I',
  II = 'II',
  III = 'III',
  IV = 'IV',
  V = 'V',
  VI = 'VI'
}

export type ForwardedIntramedMessagePreviewModel = {
  __typename?: 'ForwardedIntramedMessagePreviewModel';
  attachments: Array<FileModel>;
  patient?: Maybe<PatientModel>;
  text: Scalars['String']['output'];
};

export type FreeClinicsGlobalSearchDetailsModel = {
  __typename?: 'FreeClinicsGlobalSearchDetailsModel';
  items: Array<ClinicModel>;
  totalCount: Scalars['Int']['output'];
};

export enum FreeNotFree {
  FREE = 'FREE',
  NOT_FREE = 'NOT_FREE'
}

/** Gender code, corresponds with OpenID Connect specification values */
export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  UNKNOWN = 'UNKNOWN'
}

export enum GenerateAndStorePdfPatientFileFileType {
  DICOM_REPORT = 'DICOM_REPORT',
  PRESCRIPTION = 'PRESCRIPTION',
  REPORT = 'REPORT'
}

export type GenericAhusTookChargeDateData = {
  __typename?: 'GenericAhusTookChargeDateData';
  value: Scalars['DateTime']['output'];
};

export type GenericAhusTookChargeDateDataArgs = {
  value: Scalars['DateTime']['input'];
};

export type GenericAhusTookChargeDateHistory = {
  __typename?: 'GenericAhusTookChargeDateHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: GenericAhusTookChargeDateData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type GenericClinicsGlobalSearchDetailsModel = {
  __typename?: 'GenericClinicsGlobalSearchDetailsModel';
  items: Array<ClinicModel>;
  totalCount: Scalars['Int']['output'];
};

export enum GepGrading {
  G1 = 'G1',
  G2 = 'G2',
  G3 = 'G3',
  GX = 'GX'
}

export type GetIdentityVerificationAccessTokenModel = {
  __typename?: 'GetIdentityVerificationAccessTokenModel';
  accessToken: Scalars['String']['output'];
  verifyServiceSid: Scalars['String']['output'];
};

export type GetItalianCityByGsdHsrModel = {
  __typename?: 'GetItalianCityByGsdHsrModel';
  name: Scalars['String']['output'];
  postcodes: Array<Scalars['String']['output']>;
  provinceCode: Scalars['String']['output'];
};

export type GetNotificationOrderByArgs = {
  field?: InputMaybe<GetNotificationOrderByField>;
  type?: InputMaybe<OrderByKeyword>;
};

export enum GetNotificationOrderByField {
  CREATED_AT = 'CREATED_AT',
  DUE_DATE = 'DUE_DATE',
  PATIENT = 'PATIENT',
  REQUEST_TYPE = 'REQUEST_TYPE',
  SENDER = 'SENDER'
}

export type GlobalSearchDetailsFiltersArgs = {
  affiliationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  globalSearch?: InputMaybe<Scalars['String']['input']>;
  hitType?: InputMaybe<GlobalSearchHitType>;
  patientCanWriteOneToOneChatMessage?: InputMaybe<Scalars['Boolean']['input']>;
  serviceTypes?: InputMaybe<Array<ServiceType>>;
  specializations?: InputMaybe<Array<Specialization>>;
  specificSearch?: InputMaybe<Array<Scalars['String']['input']>>;
  videoVisitAvailability?: InputMaybe<VideoVisitAvailability>;
};

export type GlobalSearchDetailsModel = {
  __typename?: 'GlobalSearchDetailsModel';
  affiliations?: Maybe<AffiliationsGlobalSearchDetailsModel>;
  doctors: DoctorsGlobalSearchDetailsModel;
  freeClinics?: Maybe<FreeClinicsGlobalSearchDetailsModel>;
  genericClinics?: Maybe<GenericClinicsGlobalSearchDetailsModel>;
};

export enum GlobalSearchDetailsOrder {
  EARLIEST_AVAILABLE = 'EARLIEST_AVAILABLE',
  MOST_RELEVANT = 'MOST_RELEVANT',
  MOST_REQUESTED = 'MOST_REQUESTED'
}

export enum GlobalSearchHitType {
  CLINIC = 'CLINIC',
  DOCTOR = 'DOCTOR',
  SPECIALIZATION = 'SPECIALIZATION',
  SPHERE_OF_INTEREST = 'SPHERE_OF_INTEREST'
}

export type GlobalSearchModel = {
  __typename?: 'GlobalSearchModel';
  type: GlobalSearchHitType;
  value: Scalars['String']['output'];
};

export type GooglePlaceAddressModel = {
  __typename?: 'GooglePlaceAddressModel';
  longName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  types: Array<Scalars['String']['output']>;
};

export type GooglePlaceDetailsModel = {
  __typename?: 'GooglePlaceDetailsModel';
  addressComponents: Array<GooglePlaceAddressModel>;
  formattedAddress: Scalars['String']['output'];
  geometry?: Maybe<GooglePlaceGeometryModel>;
  placeId: Scalars['String']['output'];
};

export type GooglePlaceGeometryModel = {
  __typename?: 'GooglePlaceGeometryModel';
  location: GooglePlaceLocationModel;
  viewport: GooglePlaceViewportModel;
};

export type GooglePlaceLocationModel = {
  __typename?: 'GooglePlaceLocationModel';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type GooglePlacePredictionModel = {
  __typename?: 'GooglePlacePredictionModel';
  description: Scalars['String']['output'];
  placeId: Scalars['String']['output'];
};

export enum GooglePlaceType {
  ADDRESS = 'ADDRESS',
  CITY = 'CITY',
  REGION = 'REGION'
}

export type GooglePlaceViewportModel = {
  __typename?: 'GooglePlaceViewportModel';
  northeast: GooglePlaceLocationModel;
  southwest: GooglePlaceLocationModel;
};

export type GroupIntramedCreatedDataModel = {
  __typename?: 'GroupIntramedCreatedDataModel';
  title: Scalars['String']['output'];
  type: IntramedThreadLogEntryType;
};

export type GsdHsrBirthAddressArgs = {
  cityName?: InputMaybe<Scalars['String']['input']>;
  isoCountryCode: Scalars['String']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
};

export type GsdHsrClinicDataArgs = {
  medicalCode: Scalars['String']['input'];
};

export type GsdHsrClinicIntegrationDataModel = {
  __typename?: 'GsdHsrClinicIntegrationDataModel';
  bookingCode?: Maybe<Scalars['String']['output']>;
  medicalCode: Scalars['String']['output'];
  operatingUnit: Scalars['String']['output'];
};

export enum GsdHsrClinicSubType {
  GENERIC = 'GENERIC',
  NON_INTEGRATED = 'NON_INTEGRATED',
  SSN = 'SSN'
}

export type GsdHsrResidenceAddressArgs = {
  addressLine: Scalars['String']['input'];
  cityName?: InputMaybe<Scalars['String']['input']>;
  isoCountryCode: Scalars['String']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
};

export type GsdHsrServiceAddonConfigurationArgs = {
  providedWithinDays?: InputMaybe<Scalars['Int']['input']>;
  type: ServiceAddonType;
};

export type GsdHsrServiceConfigurationArgs = {
  addons?: InputMaybe<Array<GsdHsrServiceAddonConfigurationArgs>>;
  description: Scalars['String']['input'];
  doctorCanCreateRequestProposal?: Scalars['Boolean']['input'];
  durationMinutes?: InputMaybe<Scalars['Int']['input']>;
  integrationServiceType: IntegrationServiceType;
  isEnabled: Scalars['Boolean']['input'];
  patientBookingRequiresRequestProposal?: Scalars['Boolean']['input'];
  providedWithinDays?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type GsdHsrSsnClinicDataArgs = {
  bookingCode: Scalars['String']['input'];
  medicalCode: Scalars['String']['input'];
  operatingUnit: Scalars['String']['input'];
};

export enum GsdHsrSsnCouldNotFulfillReason {
  MEDICAL = 'MEDICAL',
  TECHNICAL = 'TECHNICAL'
}

export enum GsdHsrSsnFeedbackStatus {
  COULD_FULFILL = 'COULD_FULFILL',
  COULD_NOT_FULFILL = 'COULD_NOT_FULFILL',
  NOT_YET_PROVIDED = 'NOT_YET_PROVIDED'
}

export type GsdHsrSsnServiceArgs = {
  description: Scalars['String']['input'];
  doctorCanCreateRequestProposal?: Scalars['Boolean']['input'];
  durationMinutes: Scalars['Int']['input'];
  isRadiologicalImageAddonEnabled: Scalars['Boolean']['input'];
  patientBookingRequiresRequestProposal?: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

export type GsdSsoPatientModel = {
  __typename?: 'GsdSsoPatientModel';
  birthAddress: Scalars['String']['output'];
  birthDate: Scalars['DateTime']['output'];
  firstName: Scalars['String']['output'];
  gender: Gender;
  identityId: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  residenceAddress: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type GuestInviteModel = {
  __typename?: 'GuestInviteModel';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  institution: InstitutionModel;
  lastName: Scalars['String']['output'];
  portal: PortalModel;
  profileType: ProfileType;
};

export enum HeartRateType {
  AR = 'AR',
  R = 'R'
}

export type ICD9DiagnosesFilterBy = {
  searchValue?: InputMaybe<Scalars['String']['input']>;
};

export type ICD9DiagnosisCodeModel = {
  code: Scalars['String']['input'];
};

export type ICD9DiagnosisModel = {
  __typename?: 'ICD9DiagnosisModel';
  code: Scalars['String']['output'];
  displayCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum IdentityDocumentType {
  DRIVING_LICENCE = 'DRIVING_LICENCE',
  IDENTITY_CARD = 'IDENTITY_CARD',
  PASSPORT = 'PASSPORT'
}

export type IdentityVerificationInitializedModel = {
  __typename?: 'IdentityVerificationInitializedModel';
  isInitialized: Scalars['Boolean']['output'];
};

export enum IdentityVerificationMethod {
  OTP_LEGACY = 'OTP_LEGACY',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'
}

export type IdentityVerificationRequestModel = {
  __typename?: 'IdentityVerificationRequestModel';
  id: Scalars['String']['output'];
};

export type IdentityVerificationRequestProcessingResultModel = {
  __typename?: 'IdentityVerificationRequestProcessingResultModel';
  accessToken?: Maybe<Scalars['String']['output']>;
  isAccepted: Scalars['Boolean']['output'];
};

export type IndividualIntramedCreatedDataModel = {
  __typename?: 'IndividualIntramedCreatedDataModel';
  type: IntramedThreadLogEntryType;
};

export type InfoForwardedMessageModel = {
  __typename?: 'InfoForwardedMessageModel';
  message: InfoMessageModel;
  patient: PatientModel;
};

export type InfoMessage = {
  __typename?: 'InfoMessage';
  attachments: Array<InfoMessageAttachment>;
  createdAt: Scalars['DateTime']['output'];
  flags?: Maybe<Array<InfoMessageFlag>>;
  id: Scalars['String']['output'];
  infoThread: InfoThread;
  sender: InstitutionUser;
  sentAfterClose: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type InfoMessageAttachment = {
  __typename?: 'InfoMessageAttachment';
  attachedAt: Scalars['DateTime']['output'];
  file: FileModel;
  fileId: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type InfoMessageAttachmentModel = {
  __typename?: 'InfoMessageAttachmentModel';
  attachedAt: Scalars['DateTime']['output'];
  file: FileModel;
  fileId: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type InfoMessageFlag = {
  __typename?: 'InfoMessageFlag';
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InfoMessageModel = {
  __typename?: 'InfoMessageModel';
  attachments: Array<InfoMessageAttachmentModel>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  sender: PortalUserModelUnion;
  text?: Maybe<Scalars['String']['output']>;
};

export type InfoPatientCommunication = {
  __typename?: 'InfoPatientCommunication';
  createdAt: Scalars['DateTime']['output'];
  infoThread: InfoThread;
  subject?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type InfoThread = {
  __typename?: 'InfoThread';
  createdAt: Scalars['DateTime']['output'];
  firstThreadId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** is thread notification pinned on dashboard? */
  isPending: Scalars['Boolean']['output'];
  messages: Array<InfoMessage>;
  metadata: InfoThreadMetadata;
  patientInstitutionUser: InstitutionUser;
  portal: Portal;
  recipients: Array<InfoThreadRecipient>;
  status: InfoThreadStatus;
  subject: Scalars['String']['output'];
  /** NB: this counter refers to the number of unread Messages across all the InfoThreads in the same group as this (i.e. with the same firstThreadId) */
  unreadMessagesCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InfoThreadMetadata = {
  __typename?: 'InfoThreadMetadata';
  firstThreadId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  infoThread: InfoThread;
  isFileAttached: Scalars['Boolean']['output'];
  lastMessage?: Maybe<InfoMessage>;
};

export type InfoThreadRecipient = {
  __typename?: 'InfoThreadRecipient';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  infoThread: InfoThread;
  institutionUser?: Maybe<InstitutionUser>;
};

export enum InfoThreadStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}

export type InfoThreadsFilterByArgs = {
  isPinnedToHome?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Institution = {
  __typename?: 'Institution';
  id: Scalars['String']['output'];
  region: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: InstitutionType;
};

export type InstitutionListFilterByArgs = {
  regions?: InputMaybe<Array<Scalars['String']['input']>>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InstitutionType>;
};

export type InstitutionListOrderByArgs = {
  field: InstitutionListOrderByField;
  type: OrderByType;
};

export enum InstitutionListOrderByField {
  REGION = 'REGION',
  TITLE = 'TITLE',
  TYPE = 'TYPE'
}

export type InstitutionModel = {
  __typename?: 'InstitutionModel';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  region: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: InstitutionType;
};

export enum InstitutionType {
  HUB = 'HUB',
  SPOKE = 'SPOKE'
}

export type InstitutionUser = {
  __typename?: 'InstitutionUser';
  careType?: Maybe<CareTeamMemberType>;
  doctor?: Maybe<Doctor>;
  id: Scalars['String']['output'];
  institution: Institution;
  isBlocked: Scalars['Boolean']['output'];
  isVisibleInSearch: Scalars['Boolean']['output'];
  nonDoctor?: Maybe<NonDoctor>;
  patient?: Maybe<Patient>;
  portal: Portal;
  portalUser: PortalUserModel;
  profileType: ProfileType;
  user: User;
};

export type InstitutionUserModel = {
  __typename?: 'InstitutionUserModel';
  doctor?: Maybe<DoctorModel>;
  id: Scalars['String']['output'];
  isBlocked: Scalars['Boolean']['output'];
  isVisibleInSearch: Scalars['Boolean']['output'];
  nonDoctor?: Maybe<NonDoctorModel>;
  patient?: Maybe<PatientModel>;
  portalUser: PortalUserModel;
  /** @deprecated Use getFileUrl or mngGetFileUrl queries instead */
  profileImageFile?: Maybe<FileModel>;
  profileType: ProfileType;
  /** @deprecated Use getFileUrl or mngGetFileUrl queries instead */
  thumbnailImageFile?: Maybe<FileModel>;
  user: UserModel;
};

export type InstitutionUserModelAndEquipeModelUnion = EquipeModel | InstitutionUserModel;

/** NB: HSR-specific */
export enum IntegrationServiceType {
  CLINICAL_RESULTS_CONSULTATION = 'CLINICAL_RESULTS_CONSULTATION',
  CONTROL_CONSULTATION = 'CONTROL_CONSULTATION',
  CONTROL_VIDEO_VISIT = 'CONTROL_VIDEO_VISIT',
  PRIMARY_CONSULTATION = 'PRIMARY_CONSULTATION',
  PRIMARY_VIDEO_VISIT = 'PRIMARY_VIDEO_VISIT',
  SECOND_OPINION = 'SECOND_OPINION'
}

export type IntramedForwardedMessageArgs = {
  entityId: Scalars['String']['input'];
  entityType: MessageForwardingSource;
};

export type IntramedForwarderMessageModel = {
  __typename?: 'IntramedForwarderMessageModel';
  message: IntramedMessageModel;
  patient?: Maybe<PatientModel>;
};

export type IntramedMedicalFilePdfDataArgs = {
  fileType: PDFDataStorageTypeAllowedFromIntramed;
  subjects: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type IntramedMessage = {
  __typename?: 'IntramedMessage';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  intramedThreadId: Scalars['String']['output'];
  repliedMessage?: Maybe<IntramedMessage>;
  sender: InstitutionUser;
  senderId: Scalars['String']['output'];
  text: Scalars['String']['output'];
  type: IntramedMessageType;
};

export type IntramedMessageFlagsModel = {
  __typename?: 'IntramedMessageFlagsModel';
  isImportant: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
};

export type IntramedMessageForwardSourceModel = {
  __typename?: 'IntramedMessageForwardSourceModel';
  author: PortalUserModelUnion;
  createdAt: Scalars['DateTime']['output'];
};

export type IntramedMessageModel = {
  __typename?: 'IntramedMessageModel';
  attachments: Array<FileModel>;
  createdAt: Scalars['DateTime']['output'];
  flags: IntramedMessageFlagsModel;
  forwardSource?: Maybe<IntramedMessageForwardSourceModel>;
  id: Scalars['String']['output'];
  modelType: IntramedMessageModelType;
  repliedMessage?: Maybe<IntramedMessageModel>;
  sender: DoctorNonDoctorPortalUserModelUnion;
  text: Scalars['String']['output'];
  type: IntramedMessageType;
};

export enum IntramedMessageModelType {
  LOG_ENTRY = 'LOG_ENTRY',
  MESSAGE = 'MESSAGE'
}

export enum IntramedMessageType {
  FORWARDED = 'FORWARDED',
  SIMPLE = 'SIMPLE'
}

export type IntramedPatientCommunication = {
  __typename?: 'IntramedPatientCommunication';
  createdAt: Scalars['DateTime']['output'];
  intramedThread: IntramedThreadModel;
  subject?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type IntramedThread = {
  __typename?: 'IntramedThread';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isPending: Scalars['Boolean']['output'];
  messages: Array<IntramedMessage>;
  metadata: IntramedThreadMetadata;
  patient?: Maybe<Patient>;
  portal: Portal;
  recipients: Array<IntramedThreadRecipient>;
};

export type IntramedThreadAttachmentsModel = {
  __typename?: 'IntramedThreadAttachmentsModel';
  documents: Array<FileModel>;
  reports: Array<FileModel>;
};

export type IntramedThreadListFiltersArgs = {
  excludeIntramedThreadIds?: InputMaybe<Array<Scalars['String']['input']>>;
  onlyPinned?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum IntramedThreadListOrder {
  MOST_RECENT = 'MOST_RECENT',
  TO_READ = 'TO_READ'
}

export type IntramedThreadLogEntryDataModelUnion = GroupIntramedCreatedDataModel | IndividualIntramedCreatedDataModel | IntramedTitleChangedDataModel;

export type IntramedThreadLogEntryModel = {
  __typename?: 'IntramedThreadLogEntryModel';
  data: IntramedThreadLogEntryDataModelUnion;
  id: Scalars['String']['output'];
  modelType: IntramedMessageModelType;
  triggeredAt: Scalars['DateTime']['output'];
  triggeredBy: DoctorNonDoctorPortalUserModelUnion;
};

export enum IntramedThreadLogEntryType {
  GROUP_INTRAMED_CREATED = 'GROUP_INTRAMED_CREATED',
  INDIVIDUAL_INTRAMED_CREATED = 'INDIVIDUAL_INTRAMED_CREATED',
  INTRAMED_TITLE_CHANGED = 'INTRAMED_TITLE_CHANGED'
}

export type IntramedThreadMessageUnion = IntramedMessageModel | IntramedThreadLogEntryModel;

export type IntramedThreadMetadata = {
  __typename?: 'IntramedThreadMetadata';
  id: Scalars['String']['output'];
  isDicomAttached: Scalars['Boolean']['output'];
  isFileAttached: Scalars['Boolean']['output'];
  lastMessage?: Maybe<IntramedMessage>;
};

export type IntramedThreadModel = {
  __typename?: 'IntramedThreadModel';
  createdAt: Scalars['DateTime']['output'];
  firstUnreadMessageDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  isPinned: Scalars['Boolean']['output'];
  messages: Array<IntramedThreadMessageUnion>;
  numberOfImportantMessages: Scalars['Int']['output'];
  numberOfUnreadMessages: Scalars['Int']['output'];
  patient?: Maybe<PatientModel>;
  recipients: Array<IntramedThreadRecipientUnion>;
  title?: Maybe<Scalars['String']['output']>;
  type: IntramedThreadType;
  updatedAt: Scalars['DateTime']['output'];
};

export type IntramedThreadRecipient = {
  __typename?: 'IntramedThreadRecipient';
  createdAt: Scalars['DateTime']['output'];
  equipe?: Maybe<Equipe>;
  id: Scalars['String']['output'];
  user?: Maybe<InstitutionUser>;
};

export type IntramedThreadRecipientUnion = DoctorPortalUserModel | EquipeModel | NonDoctorPortalUserModel;

export enum IntramedThreadType {
  GROUP = 'GROUP',
  INDIVIDUAL = 'INDIVIDUAL'
}

export type IntramedTitleChangedDataModel = {
  __typename?: 'IntramedTitleChangedDataModel';
  oldTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: IntramedThreadLogEntryType;
};

export type InviteListFilterByArgs = {
  institutionIdsFilter?: InputMaybe<Array<Scalars['String']['input']>>;
  profileTypeFilter?: InputMaybe<ProfileType>;
  searchByFilter?: InputMaybe<Scalars['String']['input']>;
  statusFilter?: InputMaybe<InviteStatus>;
};

export type InviteModel = {
  __typename?: 'InviteModel';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  institution: InstitutionModel;
  lastName: Scalars['String']['output'];
  profileType: ProfileType;
  sender: PortalUserModel;
  status: InviteStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export enum InviteStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING'
}

export type InvitedDataModel = {
  __typename?: 'InvitedDataModel';
  invitedHub: Scalars['Float']['output'];
  invitedSpoke: Scalars['Float']['output'];
  region: Scalars['String']['output'];
  registeredHub: Scalars['Float']['output'];
  registeredSpoke: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  createdAt: Scalars['DateTime']['output'];
  file?: Maybe<File>;
  id: Scalars['String']['output'];
  payment?: Maybe<Payment>;
  receiptFile?: Maybe<File>;
  refund?: Maybe<Refund>;
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceExportModel = {
  __typename?: 'InvoiceExportModel';
  file?: Maybe<FileModel>;
  generatedTo: Scalars['DateTime']['output'];
  invoiceCode: Scalars['String']['output'];
  status: InvoiceExportModelStatus;
};

export enum InvoiceExportModelStatus {
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCEEDED = 'SUCCEEDED'
}

export enum IpssrMedicalIndexCytogeneticGroup {
  GOOD = 'GOOD',
  INTERMEDIATE = 'INTERMEDIATE',
  POOR = 'POOR',
  VERY_GOOD = 'VERY_GOOD',
  VERY_POOR = 'VERY_POOR'
}

export enum IpssrMedicalIndexHemoglobin {
  LESS_THAN_8 = 'LESS_THAN_8',
  MORE_THAN_OR_EQUAL_TO_8_AND_LESS_THAN_10 = 'MORE_THAN_OR_EQUAL_TO_8_AND_LESS_THAN_10',
  MORE_THAN_OR_EQUAL_TO_10 = 'MORE_THAN_OR_EQUAL_TO_10'
}

export enum IpssrMedicalIndexMedullaryBlasts {
  LESS_THAN_OR_EQUAL_TO_2 = 'LESS_THAN_OR_EQUAL_TO_2',
  MORE_THAN_2_AND_LESS_THAN_5 = 'MORE_THAN_2_AND_LESS_THAN_5',
  MORE_THAN_10 = 'MORE_THAN_10',
  MORE_THAN_OR_EQUAL_TO_5_AND_LESS_THAN_OR_EQUAL_TO_10 = 'MORE_THAN_OR_EQUAL_TO_5_AND_LESS_THAN_OR_EQUAL_TO_10'
}

export type IpssrMedicalIndexParametersModel = {
  absoluteNeutrophilCountIsLessThan0Point8: Scalars['Boolean']['input'];
  cytogeneticGroup: IpssrMedicalIndexCytogeneticGroup;
  hemoglobin: IpssrMedicalIndexHemoglobin;
  medullaryBlasts: IpssrMedicalIndexMedullaryBlasts;
  platelets: IpssrMedicalIndexPlatelets;
};

export enum IpssrMedicalIndexPlatelets {
  LESS_THAN_50 = 'LESS_THAN_50',
  MORE_THAN_OR_EQUAL_TO_50_AND_LESS_THAN_100 = 'MORE_THAN_OR_EQUAL_TO_50_AND_LESS_THAN_100',
  MORE_THAN_OR_EQUAL_TO_100 = 'MORE_THAN_OR_EQUAL_TO_100'
}

export type IterateReturnValueArgs = {
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
};

export type LabResultAdamts13DeliveryDateData = {
  __typename?: 'LabResultAdamts13DeliveryDateData';
  value: Scalars['DateTime']['output'];
};

export type LabResultAdamts13DeliveryDateDataArgs = {
  value: Scalars['DateTime']['input'];
};

export type LabResultAdamts13DeliveryDateHistory = {
  __typename?: 'LabResultAdamts13DeliveryDateHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultAdamts13DeliveryDateData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type LabResultAdamts13RequestDateData = {
  __typename?: 'LabResultAdamts13RequestDateData';
  value: Scalars['DateTime']['output'];
};

export type LabResultAdamts13RequestDateDataArgs = {
  value: Scalars['DateTime']['input'];
};

export type LabResultAdamts13RequestDateHistory = {
  __typename?: 'LabResultAdamts13RequestDateHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultAdamts13RequestDateData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type LabResultGeneticsData = {
  __typename?: 'LabResultGeneticsData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['DateTime']['output'];
};

export type LabResultGeneticsDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['DateTime']['input'];
};

export type LabResultGeneticsHistory = {
  __typename?: 'LabResultGeneticsHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultGeneticsData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type LabResultParameterAllowedUoMModel = {
  __typename?: 'LabResultParameterAllowedUoMModel';
  UoM: LabResultParameterUoM;
  /** When true, this is the default/standard/preferred measurement unit to be used for the related parameter. */
  isStandard: Scalars['Boolean']['output'];
};

export type LabResultParameterData = {
  __typename?: 'LabResultParameterData';
  executionDate: Scalars['DateTime']['output'];
  type: SubSectionLabParameter;
  unitOfMeasurement: LabResultParameterUoM;
  value: Scalars['String']['output'];
};

export type LabResultParameterDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  type: SubSectionLabParameter;
  unitOfMeasurement: LabResultParameterUoM;
  value: Scalars['String']['input'];
};

export type LabResultParameterHistory = {
  __typename?: 'LabResultParameterHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultParameterData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSectionLabParameter;
};

export type LabResultParameterToUoMConfigModel = {
  __typename?: 'LabResultParameterToUoMConfigModel';
  allowedUoMs: Array<LabResultParameterAllowedUoMModel>;
  labResultParameter: SubSectionLabParameter;
};

export enum LabResultParameterUoM {
  BILLIONS_PER_LITRE = 'BILLIONS_PER_LITRE',
  FEMTOLITRE = 'FEMTOLITRE',
  GRAMS_PER_DECILITRE = 'GRAMS_PER_DECILITRE',
  GRAMS_PER_LITRE = 'GRAMS_PER_LITRE',
  LITRES_PER_LITRE = 'LITRES_PER_LITRE',
  MICROGRAMS_PER_24_HOURS = 'MICROGRAMS_PER_24_HOURS',
  MICROGRAMS_PER_DECILITRE = 'MICROGRAMS_PER_DECILITRE',
  MICROGRAMS_PER_HUNDRED_MILLILITRE = 'MICROGRAMS_PER_HUNDRED_MILLILITRE',
  MICROGRAMS_PER_LITRE = 'MICROGRAMS_PER_LITRE',
  MICROUNITS_PER_MILLILITRE = 'MICROUNITS_PER_MILLILITRE',
  MILLIEQUIVALENTS_PER_LITRE = 'MILLIEQUIVALENTS_PER_LITRE',
  MILLIGRAMS_PER_24_HOURS = 'MILLIGRAMS_PER_24_HOURS',
  MILLIGRAMS_PER_DECILITRE = 'MILLIGRAMS_PER_DECILITRE',
  MILLIGRAMS_PER_HUNDRED_MILLILITRE = 'MILLIGRAMS_PER_HUNDRED_MILLILITRE',
  MILLIGRAMS_PER_LITRE = 'MILLIGRAMS_PER_LITRE',
  MILLILITRES_PER_MINUTE_PER_1_73_SQUARE_METERS = 'MILLILITRES_PER_MINUTE_PER_1_73_SQUARE_METERS',
  MILLIMETRES_PER_HOUR = 'MILLIMETRES_PER_HOUR',
  MILLIMOLES_PER_LITRE = 'MILLIMOLES_PER_LITRE',
  MILLIMOLES_PER_MOLE = 'MILLIMOLES_PER_MOLE',
  MILLIONS_PER_CUBIC_MILLIMETRE = 'MILLIONS_PER_CUBIC_MILLIMETRE',
  MILLIONS_PER_LITRE = 'MILLIONS_PER_LITRE',
  MILLIONS_PER_MICROLITRE = 'MILLIONS_PER_MICROLITRE',
  MILLIUNITS_PER_LITRE = 'MILLIUNITS_PER_LITRE',
  MILLIUNITS_PER_MILLILITRE = 'MILLIUNITS_PER_MILLILITRE',
  NANOGRAMS_PER_DECILITRE = 'NANOGRAMS_PER_DECILITRE',
  NANOGRAMS_PER_LITRE = 'NANOGRAMS_PER_LITRE',
  NANOGRAMS_PER_MILLILITRE = 'NANOGRAMS_PER_MILLILITRE',
  NANOMOLES_PER_LITRE = 'NANOMOLES_PER_LITRE',
  NORMAL_OR_PATHOLOGIC = 'NORMAL_OR_PATHOLOGIC',
  PERCENT = 'PERCENT',
  PER_CUBIC_MILLIMETRE = 'PER_CUBIC_MILLIMETRE',
  PER_MICROLITRE = 'PER_MICROLITRE',
  PICOGRAMS_PER_CELL = 'PICOGRAMS_PER_CELL',
  PICOGRAMS_PER_MILLILITRE = 'PICOGRAMS_PER_MILLILITRE',
  PICOMOLES_PER_LITRE = 'PICOMOLES_PER_LITRE',
  POSITIVE_OR_NEGATIVE = 'POSITIVE_OR_NEGATIVE',
  RATIO = 'RATIO',
  SECONDS = 'SECONDS',
  THOUSANDS_PER_CUBIC_MILLIMETRE = 'THOUSANDS_PER_CUBIC_MILLIMETRE',
  THOUSANDS_PER_LITRE = 'THOUSANDS_PER_LITRE',
  THOUSANDS_PER_MICROLITRE = 'THOUSANDS_PER_MICROLITRE',
  TRILLIONS_PER_LITRE = 'TRILLIONS_PER_LITRE',
  UNITS_PER_LITRE = 'UNITS_PER_LITRE',
  UNITS_PER_MICROLITRE = 'UNITS_PER_MICROLITRE',
  UNITS_PER_MILLILITRE = 'UNITS_PER_MILLILITRE'
}

export type LabResultUrineExamData = {
  __typename?: 'LabResultUrineExamData';
  aspect?: Maybe<Scalars['String']['output']>;
  bilirubin?: Maybe<Availability>;
  bilirubinAmount?: Maybe<Scalars['Float']['output']>;
  chetonicBodies?: Maybe<Availability>;
  chetonicBodiesAmount?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  executionDate: Scalars['DateTime']['output'];
  glucose?: Maybe<Availability>;
  glucoseAmount?: Maybe<Scalars['Float']['output']>;
  hemoglobin?: Maybe<Availability>;
  hemoglobinAmount?: Maybe<Scalars['Float']['output']>;
  microscopicExaminationOfTheSediment?: Maybe<Scalars['String']['output']>;
  nitrites?: Maybe<Availability>;
  pH?: Maybe<Scalars['Float']['output']>;
  proteins?: Maybe<Availability>;
  proteinsAmount?: Maybe<Scalars['Float']['output']>;
  specificWeight?: Maybe<Scalars['Float']['output']>;
  urobilinogen?: Maybe<Availability>;
  urobilinogenAmount?: Maybe<Scalars['Float']['output']>;
};

export type LabResultUrineExamDataArgs = {
  aspect?: InputMaybe<Scalars['String']['input']>;
  bilirubin?: InputMaybe<Availability>;
  bilirubinAmount?: InputMaybe<Scalars['Float']['input']>;
  chetonicBodies?: InputMaybe<Availability>;
  chetonicBodiesAmount?: InputMaybe<Scalars['Float']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  executionDate: Scalars['DateTime']['input'];
  glucose?: InputMaybe<Availability>;
  glucoseAmount?: InputMaybe<Scalars['Float']['input']>;
  hemoglobin?: InputMaybe<Availability>;
  hemoglobinAmount?: InputMaybe<Scalars['Float']['input']>;
  microscopicExaminationOfTheSediment?: InputMaybe<Scalars['String']['input']>;
  nitrites?: InputMaybe<Availability>;
  pH?: InputMaybe<Scalars['Float']['input']>;
  proteins?: InputMaybe<Availability>;
  proteinsAmount?: InputMaybe<Scalars['Float']['input']>;
  specificWeight?: InputMaybe<Scalars['Float']['input']>;
  urobilinogen?: InputMaybe<Availability>;
  urobilinogenAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type LabResultUrineExamHistory = {
  __typename?: 'LabResultUrineExamHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultUrineExamData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type LabResultsBcrAbl1MutationData = {
  __typename?: 'LabResultsBcrAbl1MutationData';
  executionDate: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type LabResultsBcrAbl1MutationDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  value: Scalars['String']['input'];
};

export type LabResultsBcrAbl1MutationHistory = {
  __typename?: 'LabResultsBcrAbl1MutationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultsBcrAbl1MutationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type LabResultsBcrAbl1PcrQData = {
  __typename?: 'LabResultsBcrAbl1PcrQData';
  executionDate: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type LabResultsBcrAbl1PcrQDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  value: Scalars['Float']['input'];
};

export type LabResultsBcrAbl1PcrQHistory = {
  __typename?: 'LabResultsBcrAbl1PcrQHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultsBcrAbl1PcrQData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type LabResultsBcrAbl1TranscriptData = {
  __typename?: 'LabResultsBcrAbl1TranscriptData';
  executionDate: Scalars['DateTime']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type LabResultsBcrAbl1TranscriptDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type LabResultsBcrAbl1TranscriptHistory = {
  __typename?: 'LabResultsBcrAbl1TranscriptHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultsBcrAbl1TranscriptData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type LabResultsCALRData = {
  __typename?: 'LabResultsCALRData';
  executionDate: Scalars['DateTime']['output'];
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type LabResultsCALRDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type LabResultsCALRHistory = {
  __typename?: 'LabResultsCALRHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultsCALRData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type LabResultsJak2Exon12Data = {
  __typename?: 'LabResultsJak2Exon12Data';
  alleleBurden?: Maybe<Scalars['String']['output']>;
  executionDate: Scalars['DateTime']['output'];
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type LabResultsJak2Exon12DataArgs = {
  alleleBurden?: InputMaybe<Scalars['String']['input']>;
  executionDate: Scalars['DateTime']['input'];
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type LabResultsJak2Exon12History = {
  __typename?: 'LabResultsJak2Exon12History';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultsJak2Exon12Data;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type LabResultsJak2V617FData = {
  __typename?: 'LabResultsJak2V617FData';
  alleleBurden?: Maybe<Scalars['String']['output']>;
  executionDate: Scalars['DateTime']['output'];
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type LabResultsJak2V617FDataArgs = {
  alleleBurden?: InputMaybe<Scalars['String']['input']>;
  executionDate: Scalars['DateTime']['input'];
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type LabResultsJak2V617FHistory = {
  __typename?: 'LabResultsJak2V617FHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultsJak2V617FData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type LabResultsMPLData = {
  __typename?: 'LabResultsMPLData';
  executionDate: Scalars['DateTime']['output'];
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type LabResultsMPLDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type LabResultsMPLHistory = {
  __typename?: 'LabResultsMPLHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultsMPLData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type LabResultsNGSData = {
  __typename?: 'LabResultsNGSData';
  executionDate: Scalars['DateTime']['output'];
  isExecuted: Scalars['Boolean']['output'];
  isPresentMutationHMR?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
};

export type LabResultsNGSDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  isExecuted: Scalars['Boolean']['input'];
  isPresentMutationHMR?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type LabResultsNGSHistory = {
  __typename?: 'LabResultsNGSHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultsNGSData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type LabResultsSubclonalData = {
  __typename?: 'LabResultsSubclonalData';
  executionDate: Scalars['DateTime']['output'];
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type LabResultsSubclonalDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type LabResultsSubclonalHistory = {
  __typename?: 'LabResultsSubclonalHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: LabResultsSubclonalData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export enum Language {
  ENGLISH = 'ENGLISH',
  ITALIAN = 'ITALIAN'
}

export type LookupGsdHsrSsnAppointmentResultModel = {
  __typename?: 'LookupGsdHsrSsnAppointmentResultModel';
  appointmentCode: Scalars['String']['output'];
  appointmentDateAndTime: Scalars['DateTime']['output'];
  clinic: ClinicModel;
  service: ServiceModel;
};

export type MailMessageModel = {
  __typename?: 'MailMessageModel';
  attachments: Array<FileModel>;
  receivedAt: Scalars['DateTime']['output'];
  sender: PortalUserModel;
  /** the contents are filtered/sanitized */
  subject?: Maybe<Scalars['String']['output']>;
  /** the contents are filtered/sanitized */
  text: Scalars['String']['output'];
};

export type MastocytosisData = {
  __typename?: 'MastocytosisData';
  allergies?: Maybe<AllergiesData>;
  pathologyResults?: Maybe<PathologyResultsData>;
  radiology?: Maybe<RadiologyData>;
  signsAndSymptoms?: Maybe<SignsAndSymptoms>;
  tumours?: Maybe<Tumours>;
};

export type MedicalData = {
  __typename?: 'MedicalData';
  createdAt: Scalars['DateTime']['output'];
  currentMedication?: Maybe<Array<StringContainer>>;
  familyHistory?: Maybe<Array<StringContainer>>;
  id: Scalars['String']['output'];
  patient: Patient;
  psychologicalHistory: PsychologicalHistory;
  updatedAt: Scalars['DateTime']['output'];
  vitalParameter: Array<MedicalDataVitalParameter>;
};

export type MedicalDataVitalParameter = {
  __typename?: 'MedicalDataVitalParameter';
  basalMetabolism?: Maybe<Scalars['Float']['output']>;
  bmi?: Maybe<Scalars['Float']['output']>;
  bodySurfaceArea?: Maybe<Scalars['Float']['output']>;
  bp?: Maybe<Scalars['String']['output']>;
  bpDiastolic?: Maybe<Scalars['Int']['output']>;
  bpSystolic?: Maybe<Scalars['Int']['output']>;
  breathing?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: InstitutionUser;
  evaluatedAt: Scalars['DateTime']['output'];
  heartRate?: Maybe<Scalars['Int']['output']>;
  heartRateType?: Maybe<HeartRateType>;
  heartRateWithType?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  temperature?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updaterName: Scalars['String']['output'];
  weight?: Maybe<Scalars['Float']['output']>;
};

export enum MedicalIndex {
  CCI = 'CCI',
  CTNM = 'CTNM',
  DIPSS = 'DIPSS',
  DIPSS_PLUS = 'DIPSS_PLUS',
  ELTS = 'ELTS',
  IPSSR = 'IPSSR',
  MIPSS70 = 'MIPSS70',
  MPN10 = 'MPN10',
  MYSEC_PM = 'MYSEC_PM',
  PTNM = 'PTNM',
  SOKAL = 'SOKAL',
  TNM = 'TNM',
  TNM_ASCO_2021_APPENDIX = 'TNM_ASCO_2021_APPENDIX',
  TNM_ASCO_2021_COLON_AND_RECTUM = 'TNM_ASCO_2021_COLON_AND_RECTUM',
  TNM_ASCO_2021_DUODENUM_AND_VATER_AMPULLA = 'TNM_ASCO_2021_DUODENUM_AND_VATER_AMPULLA',
  TNM_ASCO_2021_JEJUNUM_AND_ILEUM = 'TNM_ASCO_2021_JEJUNUM_AND_ILEUM',
  TNM_ASCO_2021_PANCREAS = 'TNM_ASCO_2021_PANCREAS',
  TNM_ASCO_2021_STOMACH = 'TNM_ASCO_2021_STOMACH'
}

export type MedicalReportTemplateModel = {
  __typename?: 'MedicalReportTemplateModel';
  createdAt: Scalars['DateTime']['output'];
  doctorPortalUser: PortalUserModel;
  id: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MedicalRoleModel = {
  __typename?: 'MedicalRoleModel';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  sortOrder: Scalars['Float']['output'];
};

export enum MemorandumListOrderByField {
  MEMORANDUM_DATE = 'MEMORANDUM_DATE'
}

export type MenopauseArgs = {
  additionalData?: InputMaybe<Scalars['String']['input']>;
  age?: InputMaybe<Scalars['Float']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MenopauseType>;
  value: Scalars['Boolean']['input'];
};

export enum MenopauseType {
  INDUCED = 'INDUCED',
  SPONTANEOUS = 'SPONTANEOUS'
}

export enum MenstrualCycle {
  IRREGULAR = 'IRREGULAR',
  REGULAR = 'REGULAR'
}

export type MessageArgs = {
  attachmentFileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  replyToId?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

export enum MessageForwardingSource {
  CHAT_ITEM = 'CHAT_ITEM',
  INFO = 'INFO',
  INTRAMED = 'INTRAMED'
}

export type Mipss70MedicalIndexParametersModel = {
  boneMarrowFibrosisGradeIsMoreThanOrEqualTo2: Scalars['Boolean']['input'];
  carlType1LikeMutationAbsence: Scalars['Boolean']['input'];
  constitutionalSymptoms: Scalars['Boolean']['input'];
  hemoglobinIsLessThan100: Scalars['Boolean']['input'];
  hmrMutationPresence: Scalars['Boolean']['input'];
  hmrMutationsIsMoreThanOrEqualTo2: Scalars['Boolean']['input'];
  peripheralBloodBlastsIsMoreThanOrEqualTo2: Scalars['Boolean']['input'];
  plateletsIsLessThan100: Scalars['Boolean']['input'];
  whiteBloodCellCountIsMoreThan25: Scalars['Boolean']['input'];
};

export type MngAccountingEntryModel = {
  __typename?: 'MngAccountingEntryModel';
  currencyCode?: Maybe<CurrencyCode>;
  file?: Maybe<MngFileModel>;
  id: Scalars['String']['output'];
  memorandumDate: Scalars['DateTime']['output'];
  totalInvoice: Scalars['Float']['output'];
  totalPaid: Scalars['Float']['output'];
  user: MngAccountingEntryUserModel;
};

export type MngAccountingEntryUserModel = {
  __typename?: 'MngAccountingEntryUserModel';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type MngAffiliationListFilterByArgs = {
  nameFilter?: InputMaybe<Scalars['String']['input']>;
  statusFilter?: InputMaybe<AffiliationPublicationStatus>;
};

export type MngAffiliationModel = {
  __typename?: 'MngAffiliationModel';
  endDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isDeletable: Scalars['Boolean']['output'];
  isPublishable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  publicationDetails?: Maybe<MngAffiliationPublicationDetailsModel>;
  services: Array<MngAffiliationServiceModel>;
  startDate: Scalars['DateTime']['output'];
};

export type MngAffiliationPublicationDetailsModel = {
  __typename?: 'MngAffiliationPublicationDetailsModel';
  publicLink: Scalars['String']['output'];
  publishedAt: Scalars['DateTime']['output'];
  wasSilentPublication: Scalars['Boolean']['output'];
};

export type MngAffiliationRoleServiceArgs = {
  addons: Array<CreateAddonArgs>;
  durationMinutes?: InputMaybe<Scalars['Int']['input']>;
  price: Scalars['Float']['input'];
  providedWithinDays?: InputMaybe<Scalars['Int']['input']>;
  roleId: Scalars['String']['input'];
};

export type MngAffiliationServiceArgs = {
  baseServiceType: ServiceType;
  description: Scalars['String']['input'];
  perRoleServices: Array<MngAffiliationRoleServiceArgs>;
  title: Scalars['String']['input'];
};

export type MngAffiliationServiceModel = {
  __typename?: 'MngAffiliationServiceModel';
  baseServiceType: ServiceType;
  description: Scalars['String']['output'];
  perRoleServices: Array<AffiliationRoleServiceModel>;
  title: Scalars['String']['output'];
};

export type MngAnnouncementModel = {
  __typename?: 'MngAnnouncementModel';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  portals: Array<PortalModel>;
  profileTypes: Array<ProfileType>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type MngAvailableRegionsModel = {
  __typename?: 'MngAvailableRegionsModel';
  regions: Array<Scalars['String']['output']>;
};

export type MngClinicListFilterByArgs = {
  /** NB: when specifying isVisibleForPatientsFilter := true, this filter will be ignored */
  isEnabledFilter?: InputMaybe<Scalars['Boolean']['input']>;
  isVisibleForPatientsFilter?: InputMaybe<Scalars['Boolean']['input']>;
  portalExtensionFilter?: InputMaybe<ClinicPortalExtensionFilterArgs>;
  searchByFilter?: InputMaybe<Scalars['String']['input']>;
  specializationsFilter?: InputMaybe<Array<Specialization>>;
};

export type MngClinicModel = {
  __typename?: 'MngClinicModel';
  bookableByProfileTypes: Array<ProfileType>;
  capacity: Scalars['Int']['output'];
  defaultPatientWritePolicy: AllowDenyPolicyType;
  description?: Maybe<Scalars['String']['output']>;
  detailsUrls?: Maybe<ClinicDetailsUrlsModel>;
  extendedServices: Array<ContextAwareServiceModel>;
  id: Scalars['String']['output'];
  isEnabled: Scalars['Boolean']['output'];
  isVisibleForPatients: Scalars['Boolean']['output'];
  members: Array<PortalUserModel>;
  name: Scalars['String']['output'];
  patientWriteButtonVisibilityPolicy: AllowDenyPolicyType;
  portalEmail?: Maybe<Scalars['String']['output']>;
  portalExtension: MngClinicPortalExtensionModel;
  services: Array<MngServiceModel>;
  specializations: Array<Specialization>;
  statusNotices: Array<MngClinicStatusNotice>;
};

export type MngClinicPortalExtensionGsdHsrClinicModel = {
  __typename?: 'MngClinicPortalExtensionGsdHsrClinicModel';
  integrationData?: Maybe<GsdHsrClinicIntegrationDataModel>;
  subType: GsdHsrClinicSubType;
};

export type MngClinicPortalExtensionModel = {
  __typename?: 'MngClinicPortalExtensionModel';
  gsdHsrClinic?: Maybe<MngClinicPortalExtensionGsdHsrClinicModel>;
};

export enum MngClinicStatusNotice {
  NO_DOCTOR_MEMBERS = 'NO_DOCTOR_MEMBERS',
  NO_ENABLED_SERVICES = 'NO_ENABLED_SERVICES'
}

export type MngDeleteUserResultModel = {
  __typename?: 'MngDeleteUserResultModel';
  /** entity-specific deletion stats; the order matches the order of execution */
  deleteResults?: Maybe<Array<EntityDeleteResultModel>>;
  /** echoes back the input argument with the same name */
  dryRun: Scalars['Boolean']['output'];
  /** indicates preliminary checks were green, but the operation still failed for some unexpected reason */
  executionError?: Maybe<Scalars['String']['output']>;
  /** when false, see either validationErrors or executionError for the failure reason */
  success: Scalars['Boolean']['output'];
  /** indicate the operation was not executed because some preliminary checks failed */
  validationErrors?: Maybe<Array<Scalars['String']['output']>>;
};

export type MngDoctorUserModel = {
  __typename?: 'MngDoctorUserModel';
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type MngFileModel = {
  __typename?: 'MngFileModel';
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  dateOfExecution: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isCancellable: Scalars['Boolean']['output'];
  isModifiable: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  pdfData?: Maybe<PDFDataStorage>;
  tag?: Maybe<FileTag>;
  type: FileType;
  uploadedAt: Scalars['DateTime']['output'];
  uploaderInstitutionUser?: Maybe<InstitutionUserModel>;
};

export type MngGsdHsrUserIntegrationModel = {
  __typename?: 'MngGsdHsrUserIntegrationModel';
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type MngInstitutionModel = {
  __typename?: 'MngInstitutionModel';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  numberOfPendingInvites: Scalars['Float']['output'];
  numberOfRegisteredDoctorsAndNonDoctors: Scalars['Float']['output'];
  region: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: InstitutionType;
};

export type MngInviteModel = {
  __typename?: 'MngInviteModel';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  institution: InstitutionModel;
  lastName: Scalars['String']['output'];
  link: Scalars['String']['output'];
  profileType: ProfileType;
  registeredUser?: Maybe<UserModel>;
  sender: PortalUserModelAndAdminUserModelUnion;
  status: InviteStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type MngLookupGsdHsrGenericClinicResultModel = {
  __typename?: 'MngLookupGsdHsrGenericClinicResultModel';
  medicalCode: Scalars['String']['output'];
  services: Array<DoctorAvailableServiceFromIntegrationModel>;
};

export type MngLookupGsdHsrSsnClinicResultModel = {
  __typename?: 'MngLookupGsdHsrSsnClinicResultModel';
  bookingCode: Scalars['String']['output'];
  medicalCode: Scalars['String']['output'];
  operatingUnit: Scalars['String']['output'];
  service?: Maybe<DoctorAvailableServiceFromIntegrationModel>;
};

export type MngMemorandumListFilters = {
  searchValue?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Float']['input']>;
};

export type MngMemorandumListOrderByArgs = {
  field: MemorandumListOrderByField;
  type?: InputMaybe<OrderByType>;
};

export type MngPatientModel = {
  __typename?: 'MngPatientModel';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  portalUser: PortalUserModel;
  privacies: Array<UserPrivacyModel>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MngPatientUserModel = {
  __typename?: 'MngPatientUserModel';
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  portalUser: PortalUserModel;
  userId: Scalars['String']['output'];
};

export type MngRequestListFilters = {
  closedAt?: InputMaybe<TimeIntervalInput>;
  createdAt?: InputMaybe<TimeIntervalInput>;
  filesFilter?: InputMaybe<RequestFilesFilterArgs>;
  isAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  portalExtensionFilter?: InputMaybe<RequestPortalExtensionFilterArgs>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  serviceBookingTypes?: InputMaybe<Array<ServiceBookingType>>;
  serviceTypes?: InputMaybe<Array<ServiceType>>;
  status?: InputMaybe<RequestStatusCompound>;
};

export type MngRequestModel = {
  __typename?: 'MngRequestModel';
  assignmentStatus: RequestAssignmentStatus;
  bookingProviderExtension: AbstractBookingProviderExtensionModel;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  doctor?: Maybe<MngDoctorUserModel>;
  files?: Maybe<RequestInvoiceFilesModel>;
  id: Scalars['String']['output'];
  integrationStatus: OrderIntegrationStatusModel;
  isInternal: Scalars['Boolean']['output'];
  order?: Maybe<OrderModel>;
  orderCode?: Maybe<Scalars['String']['output']>;
  patient: MngPatientUserModel;
  paymentInvoiceCanBeGenerated: Scalars['Boolean']['output'];
  refundInvoiceCanBeGenerated: Scalars['Boolean']['output'];
  serviceBookingType: ServiceBookingType;
  serviceType: ServiceType;
  shouldBeAnsweredBy?: Maybe<Scalars['DateTime']['output']>;
  status: Array<RequestStatusCompound>;
  transactionId?: Maybe<Scalars['String']['output']>;
};

export type MngServiceModel = {
  __typename?: 'MngServiceModel';
  addons: Array<ServiceAddonModel>;
  allowsOnDemandSchedule?: Maybe<Scalars['Boolean']['output']>;
  baseServiceType: ServiceType;
  description: Scalars['String']['output'];
  doctorCanCreateRequestProposal: Scalars['Boolean']['output'];
  durationMinutes?: Maybe<Scalars['Int']['output']>;
  giftType: ServiceGiftType;
  id: Scalars['String']['output'];
  isEnabled: Scalars['Boolean']['output'];
  isGiftService?: Maybe<Scalars['Boolean']['output']>;
  patientBookingRequiresRequestProposal: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
  providedWithinDays?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type MngUpdateNoticeModel = {
  __typename?: 'MngUpdateNoticeModel';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  portals: Array<PortalModel>;
  profileTypes: Array<ProfileType>;
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['DateTime']['output'];
  validTo: Scalars['DateTime']['output'];
};

export type MngUserClaimListFilterByArgs = {
  searchValue?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserClaimStatus>;
};

export type MngUserClaimListOrderByArgs = {
  field: UserClaimListOrderByField;
  type: OrderByType;
};

export type MngUserClaimModel = {
  __typename?: 'MngUserClaimModel';
  attachments: Array<MngFileModel>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invalidatedAt?: Maybe<Scalars['DateTime']['output']>;
  phoneNumber: Scalars['String']['output'];
  portal: PortalModel;
  rejectedReason?: Maybe<Scalars['String']['output']>;
  residenceAddress: ResidenceAddressModel;
  status: UserClaimStatus;
  user: UserModel;
  userClaimHistory: Array<MngUserClaimModel>;
};

export type Mpn10MedicalIndexParametersModel = {
  abdominalDiscomfort: Scalars['Int']['input'];
  bonePain: Scalars['Int']['input'];
  concentrationProblems: Scalars['Int']['input'];
  earlySatiety: Scalars['Int']['input'];
  fatigue: Scalars['Int']['input'];
  fever: Scalars['Int']['input'];
  inactivity: Scalars['Int']['input'];
  itching: Scalars['Int']['input'];
  nightSweats: Scalars['Int']['input'];
  unintentionalWeightLossLast6Months: Scalars['Int']['input'];
};

export type MultiValueSurveyAnswerArgs = {
  code: Scalars['String']['input'];
  otherAnswerValue?: InputMaybe<Array<Scalars['String']['input']>>;
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MultiValueSurveyAnswerModel = {
  __typename?: 'MultiValueSurveyAnswerModel';
  code: Scalars['String']['output'];
  otherAnswerValue?: Maybe<Array<Scalars['String']['output']>>;
  type: SurveyAnswerType;
  /** In case when OtherAnswerOptions.isExclusive === true, this shall be null */
  value?: Maybe<Array<Scalars['String']['output']>>;
};

export type MultidisciplinaryReportPdfDataArgs = {
  multidisciplinaryTeamPortalUserIds: Array<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MultidisciplinaryTeamMember = {
  __typename?: 'MultidisciplinaryTeamMember';
  firstName: Scalars['String']['output'];
  gender: Gender;
  lastName: Scalars['String']['output'];
  specializations: Array<Scalars['String']['output']>;
  titleCode: UserTitle;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptEhrConsensusPrivacy: UserPrivacy;
  acceptEhrConsensusPrivacyByDoctor: Scalars['Boolean']['output'];
  addAllergiesAnaphylacticShockPatientMedicalData: AllergiesAnaphylacticShockHistory;
  addAllergiesHymenopteraPatientMedicalData: AllergiesHymenopteraHistory;
  addAllergiesOtherPatientMedicalData: AllergiesOtherHistory;
  addAnamnesisAllogeneicTransplantationPatientMedicalData: AnamnesisAllogeneicTransplantationHistory;
  addAnamnesisAutoimmuneDiseasesPatientMedicalData: AnamnesisAutoimmuneDiseasesHistory;
  addAnamnesisBreastFamilyHistoryPatientMedicalData: AnamnesisBreastFamilyHistoryHistory;
  addAnamnesisDonorPatientMedicalData: AnamnesisDonorHistory;
  addAnamnesisGlomerulonephritesPatientMedicalData: AnamnesisGlomerulonephritesHistory;
  addAnamnesisInfectionsPatientMedicalData: AnamnesisInfectionsHistory;
  addAnamnesisIsInTransplantListPatientMedicalData: AnamnesisIsInTransplantListHistory;
  addAnamnesisMelanomaFamilyHistoryPatientMedicalData: AnamnesisMelanomaFamilyHistoryHistory;
  addAnamnesisNeoplasiaPatientMedicalData: AnamnesisNeoplasiaHistory;
  addAnamnesisNetSurgeryFlagPatientMedicalData: AnamnesisNetSurgeryFlagHistory;
  /** This mutation is actually used to persist NET_SURGERY history, see addAnamnesisNetSurgeryFlagPatientMedicalData for storing the flag */
  addAnamnesisNetSurgeryPatientMedicalData: AnamnesisNetSurgeryHistory;
  addAnamnesisSevereHypertensionPatientMedicalData: AnamnesisSevereHypertensionHistory;
  addAnamnesisSurgeryForBreastCancerPatientMedicalData: AnamnesisSurgeryForBreastCancerHistory;
  addAnamnesisTraumaPatientMedicalData: AnamnesisTraumaHistory;
  addCalendar: CalendarModel;
  addCurrentClinicalEvaluationGenericNotesPatientMedicalData: CurrentClinicalEvaluationGenericNotesHistory;
  addDiagnosesAhusDiagnosisDatePatientMedicalData: DiagnosesAhusDiagnosisDateHistory;
  addDiagnosesDiseaseStagePatientMedicalData: DiagnosesDiseaseStageHistory;
  addDiagnosesGenericNotesPatientMedicalData: DiagnosesGenericNotesHistory;
  addDiagnosesNetGepGradingPatientMedicalData: DiagnosesNetGepGradingHistory;
  addDiagnosesNetThoraxGradingPatientMedicalData: DiagnosesNetThoraxGradingHistory;
  addDiagnosesNetThoraxStagingTnmAsco2021PatientMedicalData: DiagnosesNetThoraxStagingTnmAsco2021History;
  addDoctorSpecializations: Array<DoctorSpecializationModel>;
  addFamilyAnamnesisCardiovascularDiseasePatientMedicalData: FamilyAnamnesisCardiovascularDiseaseHistory;
  addFamilyAnamnesisGenericNotesPatientMedicalData: FamilyAnamnesisGenericNotesHistory;
  addFamilyAnamnesisKidneyFailurePatientMedicalData: FamilyAnamnesisKidneyFailureHistory;
  addFamilyAnamnesisNetGastricUlcersPatientMedicalData: FamilyAnamnesisNetGastricUlcersHistory;
  addFamilyAnamnesisNetGlucidicMetabolismAlterationPatientMedicalData: FamilyAnamnesisNetGlucidicMetabolismAlterationHistory;
  addFamilyAnamnesisNetHypercalcemiaPatientMedicalData: FamilyAnamnesisNetHypercalcemiaHistory;
  addFamilyAnamnesisNetHypophysisAdenomaPatientMedicalData: FamilyAnamnesisNetHypophysisAdenomaHistory;
  addFamilyAnamnesisNetMultipleEndocrineNeoplasiaPatientMedicalData: FamilyAnamnesisNetMultipleEndocrineNeoplasiaHistory;
  addFamilyAnamnesisNetNephrolithiasisPatientMedicalData: FamilyAnamnesisNetNephrolithiasisHistory;
  addFamilyAnamnesisNetNeuroendocrineTumorsPatientMedicalData: FamilyAnamnesisNetNeuroendocrineTumorsHistory;
  addFamilyAnamnesisNetOtherNeoplasiaPatientMedicalData: FamilyAnamnesisNetOtherNeoplasiaHistory;
  addGenericAhusTookChargeDatePatientMedicalData: GenericAhusTookChargeDateHistory;
  addInfoThreadMessage: InfoMessage;
  addIntramedMessage: IntramedMessageModel;
  addLabResultAdamts13DeliveryDatePatientMedicalData: LabResultAdamts13DeliveryDateHistory;
  addLabResultAdamts13RequestDatePatientMedicalData: LabResultAdamts13RequestDateHistory;
  addLabResultGeneticsPatientMedicalData: LabResultGeneticsHistory;
  addLabResultUrineExamPatientMedicalData: LabResultUrineExamHistory;
  addLabResultsBcrAbl1MutationPatientMedicalData: LabResultsBcrAbl1MutationHistory;
  addLabResultsBcrAbl1PcrQPatientMedicalData: LabResultsBcrAbl1PcrQHistory;
  addLabResultsBcrAbl1TranscriptValuePatientMedicalData: LabResultsBcrAbl1TranscriptHistory;
  addLabResultsCALRPatientMedicalData: LabResultsCALRHistory;
  addLabResultsJak2Exon12PatientMedicalData: LabResultsJak2Exon12History;
  addLabResultsJak2V617FPatientMedicalData: LabResultsJak2V617FHistory;
  addLabResultsMPLPatientMedicalData: LabResultsMPLHistory;
  addLabResultsNGSPatientMedicalData: LabResultsNGSHistory;
  addLabResultsSubclonalPatientMedicalData: LabResultsSubclonalHistory;
  addMembersToEquipe: EquipeModel;
  addNonDoctorProfessionalRole: Array<NonDoctorProfessionalRole>;
  addPathologicalAnamnesisNetGastricUlcersPatientMedicalData: PathologicalAnamnesisNetGastricUlcersHistory;
  addPathologicalAnamnesisNetGlucidicMetabolismAlterationPatientMedicalData: PathologicalAnamnesisNetGlucidicMetabolismAlterationHistory;
  addPathologicalAnamnesisNetHypercalcemiaPatientMedicalData: PathologicalAnamnesisNetHypercalcemiaHistory;
  addPathologicalAnamnesisNetHypophysisAdenomaPatientMedicalData: PathologicalAnamnesisNetHypophysisAdenomaHistory;
  addPathologicalAnamnesisNetMultipleEndocrineNeoplasiaPatientMedicalData: PathologicalAnamnesisNetMultipleEndocrineNeoplasiaHistory;
  addPathologicalAnamnesisNetNephrolithiasisPatientMedicalData: PathologicalAnamnesisNetNephrolithiasisHistory;
  addPathologicalAnamnesisNetNeuroendocrineTumorsPatientMedicalData: PathologicalAnamnesisNetNeuroendocrineTumorsHistory;
  addPathologicalAnamnesisNetOtherNeoplasiaPatientMedicalData: PathologicalAnamnesisNetOtherNeoplasiaHistory;
  addPathologyResultsACAPatientMedicalData: PathologyResultsACAHistory;
  addPathologyResultsAhusCytologicalExaminationPatientMedicalData: PathologyResultsAhusCytologicalExaminationHistory;
  addPathologyResultsAhusHystologicalExaminationPatientMedicalData: PathologyResultsAhusHystologicalExaminationHistory;
  addPathologyResultsBMAPatientMedicalData: PathologyResultsBMAHistory;
  addPathologyResultsBMBPatientMedicalData: PathologyResultsBMBHistory;
  addPathologyResultsBrafMutationPatientMedicalData: PathologyResultsBrafMutationHistory;
  addPathologyResultsBreastHistologicalExaminationPatientMedicalData: PathologyResultsBreastHistologicalExaminationHistory;
  addPathologyResultsBreastHistotypePatientMedicalData: PathologyResultsBreastHistotypeHistory;
  addPathologyResultsBreastMetastasisPatientMedicalData: PathologyResultsBreastMetastasisHistory;
  addPathologyResultsBreastNodesDissectionPatientMedicalData: PathologyResultsBreastNodesDissectionHistory;
  addPathologyResultsBreastResectionMarginsPatientMedicalData: PathologyResultsBreastResectionMarginsHistory;
  addPathologyResultsBreastSentinelNodePatientMedicalData: PathologyResultsBreastSentinelNodeHistory;
  addPathologyResultsCKitMutationPatientMedicalData: PathologyResultsCKitMutationHistory;
  addPathologyResultsGenericNotesPatientMedicalData: PathologyResultsGenericNotesHistory;
  addPathologyResultsHistologyPatientMedicalData: PathologyResultsHistologyHistory;
  addPathologyResultsImmunophenotypingPatientMedicalData: PathologyResultsImmunophenotypingHistory;
  addPathologyResultsKaryotypePatientMedicalData: PathologyResultsKaryotypeHistory;
  addPathologyResultsMelanomaHistotypePatientMedicalData: PathologyResultsMelanomaHistotypeHistory;
  addPathologyResultsMelanomaLymphNodesHistologicalExaminationPatientMedicalData: PathologyResultsMelanomaLymphNodesHistologicalExaminationHistory;
  addPathologyResultsMelanomaMetastasisPatientMedicalData: PathologyResultsMelanomaMetastasisHistory;
  addPathologyResultsMelanomaNodesDissectionPatientMedicalData: PathologyResultsMelanomaNodesDissectionHistory;
  addPathologyResultsMelanomaResectionMarginsPatientMedicalData: PathologyResultsMelanomaResectionMarginsHistory;
  addPathologyResultsMelanomaSentinelNodePatientMedicalData: PathologyResultsMelanomaSentinelNodeHistory;
  addPathologyResultsMelanomaTumorHistologicalExaminationPatientMedicalData: PathologyResultsMelanomaTumorHistologicalExaminationHistory;
  addPathologyResultsNetCytologicalExaminationPatientMedicalData: PathologyResultsNetCytologicalExaminationHistory;
  addPathologyResultsNetHistologicalExaminationPatientMedicalData: PathologyResultsNetHistologicalExaminationHistory;
  addPathologyResultsNetLymphNodesHistologicalExaminationPatientMedicalData: PathologyResultsNetLymphNodesHistologicalExaminationHistory;
  addPathologyResultsNetLymphNodesPresencePatientMedicalData: PathologyResultsNetLymphNodesPresenceHistory;
  addPathologyResultsNetMetastasisPatientMedicalData: PathologyResultsNetMetastasisHistory;
  addPathologyResultsNonBrafMutationPatientMedicalData: PathologyResultsNonBrafMutationHistory;
  addPatientLabResultsParameters: Array<LabResultParameterHistory>;
  addPatientProfile: PatientPortalUserModel;
  addPhysiologicalHistoryDeathDatePatientMedicalData: PhysiologicalHistoryDeathDateHistory;
  addPhysiologicalHistoryFitzpatrickScalePatientMedicalData: PhysiologicalHistoryFitzpatrickScaleHistory;
  addPhysiologicalHistoryGenericNotesPatientMedicalData: PhysiologicalHistoryGenericNotesHistory;
  addPhysiologicalHistoryMenopausePatientMedicalData: PhysiologicalHistoryMenopauseHistory;
  addPhysiologicalHistoryMenstrualCyclePatientMedicalData: PhysiologicalHistoryMenstrualCycleHistory;
  addPhysiologicalHistorySmokePatientMedicalData: PhysiologicalHistorySmokeHistory;
  addProfileCvToDoctor: File;
  addProfileImages: ProfileImageList;
  addRadiologyAbdomenUltrasoundPatientMedicalData: RadiologyAbdomenUltrasoundHistory;
  addRadiologyBoneDensitometryPatientMedicalData: RadiologyBoneDensitometryHistory;
  addRadiologyBoneImagingPatientMedicalData: RadiologyBoneImagingHistory;
  addRadiologyECGPatientMedicalData: RadiologyECGHistory;
  addRadiologyEchoPatientMedicalData: RadiologyEchoHistory;
  addRadiologyEyeExaminationPatientMedicalData: RadiologyEyeExaminationHistory;
  addRadiologySncLocalizationsPatientMedicalData: RadiologySncLocalizationsHistory;
  addRiskScoreGenericNotesPatientMedicalData: RiskScoreGenericNotesHistory;
  addSignsAndSymptomsAbdominalPainPatientMedicalData: SignsAndSymptomsAbdominalPainHistory;
  addSignsAndSymptomsAstheniaPatientMedicalData: SignsAndSymptomsAstheniaHistory;
  addSignsAndSymptomsBlurredEyesightPatientMedicalData: SignsAndSymptomsBlurredEyesightHistory;
  addSignsAndSymptomsComaPatientMedicalData: SignsAndSymptomsComaHistory;
  addSignsAndSymptomsConfusionPatientMedicalData: SignsAndSymptomsConfusionHistory;
  addSignsAndSymptomsConvulsionsPatientMedicalData: SignsAndSymptomsConvulsionsHistory;
  addSignsAndSymptomsDispneaPatientMedicalData: SignsAndSymptomsDispneaHistory;
  addSignsAndSymptomsEcogPatientMedicalData: SignsAndSymptomsEcogHistory;
  addSignsAndSymptomsEdemasPatientMedicalData: SignsAndSymptomsEdemasHistory;
  addSignsAndSymptomsEyeBleedingPatientMedicalData: SignsAndSymptomsEyeBleedingHistory;
  addSignsAndSymptomsEyePainPatientMedicalData: SignsAndSymptomsEyePainHistory;
  addSignsAndSymptomsFeverPatientMedicalData: SignsAndSymptomsFeverHistory;
  addSignsAndSymptomsGastrointestinalDisordersPatientMedicalData: SignsAndSymptomsGastrointestinalDisordersHistory;
  addSignsAndSymptomsGenericNotesPatientMedicalData: SignsAndSymptomsGenericNotesHistory;
  addSignsAndSymptomsHeartAttackPatientMedicalData: SignsAndSymptomsHeartAttackHistory;
  addSignsAndSymptomsHematuriaPatientMedicalData: SignsAndSymptomsHematuriaHistory;
  addSignsAndSymptomsHemophthousPatientMedicalData: SignsAndSymptomsHemophthousHistory;
  addSignsAndSymptomsHemoptysisPatientMedicalData: SignsAndSymptomsHemoptysisHistory;
  addSignsAndSymptomsHypertensionPatientMedicalData: SignsAndSymptomsHypertensionHistory;
  addSignsAndSymptomsNauseaPatientMedicalData: SignsAndSymptomsNauseaHistory;
  addSignsAndSymptomsNetAchePatientMedicalData: SignsAndSymptomsNetAcheHistory;
  addSignsAndSymptomsNetDiarrheaPatientMedicalData: SignsAndSymptomsNetDiarrheaHistory;
  addSignsAndSymptomsNetFlushingPatientMedicalData: SignsAndSymptomsNetFlushingHistory;
  addSignsAndSymptomsNetJaundicePatientMedicalData: SignsAndSymptomsNetJaundiceHistory;
  addSignsAndSymptomsNetRetchingPatientMedicalData: SignsAndSymptomsNetRetchingHistory;
  addSignsAndSymptomsNetTachycardiaPatientMedicalData: SignsAndSymptomsNetTachycardiaHistory;
  addSignsAndSymptomsNetWeightLossPatientMedicalData: SignsAndSymptomsNetWeightLossHistory;
  addSignsAndSymptomsOligoanuriaPatientMedicalData: SignsAndSymptomsOligoanuriaHistory;
  addSignsAndSymptomsPancreatitisPatientMedicalData: SignsAndSymptomsPancreatitisHistory;
  addSignsAndSymptomsPulmonaryBleedingPatientMedicalData: SignsAndSymptomsPulmonaryBleedingHistory;
  addSignsAndSymptomsPulmonaryEdemaPatientMedicalData: SignsAndSymptomsPulmonaryEdemaHistory;
  addSignsAndSymptomsRetinalVesselsObstructionPatientMedicalData: SignsAndSymptomsRetinalVesselsObstructionHistory;
  addSignsAndSymptomsSkinInvolvementPatientMedicalData: SignsAndSymptomsSkinInvolvementHistory;
  addSignsAndSymptomsSplenomegalyPalpablePatientMedicalData: SignsAndSymptomsSplenomegalyPalpableHistory;
  addSignsAndSymptomsStenosisPatientMedicalData: SignsAndSymptomsStenosisHistory;
  addSignsAndSymptomsStrokePatientMedicalData: SignsAndSymptomsStrokeHistory;
  addSignsAndSymptomsSyncopesPatientMedicalData: SignsAndSymptomsSyncopesHistory;
  addSignsAndSymptomsTachypneaPatientMedicalData: SignsAndSymptomsTachypneaHistory;
  addSignsAndSymptomsVisualImpairmentPatientMedicalData: SignsAndSymptomsVisualImpairmentHistory;
  addSupportRequest: SupportRequest;
  addTherapyAhusMedicationsStartDatePatientMedicalData: TherapyAhusMedicationsStartDateHistory;
  addTherapyApheresisPatientMedicalData: TherapyApheresisHistory;
  addTherapyBreastAdjuvantTherapyPatientMedicalData: TherapyBreastAdjuvantTherapyHistory;
  addTherapyBreastHormoneTherapyPatientMedicalData: TherapyBreastHormoneTherapyHistory;
  addTherapyBreastNeoadjuvantTherapyPatientMedicalData: TherapyBreastNeoadjuvantTherapyHistory;
  addTherapyBreastRadiotherapyPatientMedicalData: TherapyBreastRadiotherapyHistory;
  addTherapyCarTEligibilityPatientMedicalData: TherapyCarTEligibilityHistory;
  addTherapyCarTInfusionPatientMedicalData: TherapyCarTInfusionHistory;
  addTherapyCarTTreatmentArrivePatientMedicalData: TherapyCarTTreatmentArriveHistory;
  addTherapyCarTTreatmentOrderPatientMedicalData: TherapyCarTTreatmentOrderHistory;
  addTherapyHematologyPatientMedicalData: TherapyHematologyHistory;
  addTherapyNetAdjuvantTherapyPatientMedicalData: TherapyNetAdjuvantTherapyHistory;
  addTherapyNetNeoadjuvantTherapyPatientMedicalData: TherapyNetNeoadjuvantTherapyHistory;
  addTherapyNetRadioReceptorTherapyPatientMedicalData: TherapyNetRadioReceptorTherapyHistory;
  addTherapyNetSomatostatinAnalogueTherapyPatientMedicalData: TherapyNetSomatostatinAnalogueTherapyHistory;
  addTherapyTherapyPatientMedicalData: TherapyTherapyHistory;
  addTumoursGenericInformationPatientMedicalData: TumoursGenericInformationHistory;
  /** @deprecated Will be removed. Use addMembersToEquipe mutation instead */
  addUsersToEquipe: Scalars['Boolean']['output'];
  addVaccinationsAntimeningococcalVaccinationPatientMedicalData: VaccinationsAntimeningococcalVaccinationHistory;
  addVaccinationsGenericNotesPatientMedicalData: VaccinationsGenericNotesHistory;
  addVitalsParametersGenericNotesPatientMedicalData: VitalsParametersGenericNotesHistory;
  answerSurvey: SurveyAnswerModel;
  answerSurveyOnBehalfOfAPatient: SurveyAnswerModel;
  attachBillingDataToDoctor: BillingData;
  attachPatientFile: PatientFile;
  attachPushNotificationsDevice: Scalars['Boolean']['output'];
  attachResidenceAddress: ResidenceAddressModel;
  attachResidenceAddressToPatient: ResidenceAddressModel;
  callEmailVerificationFlow: CodeDeliveryDetailsModel;
  callPhoneNumberVerificationFlow: CodeDeliveryDetailsModel;
  cancelDicomDiskUpload: Scalars['Boolean']['output'];
  cancelFile: PatientFile;
  capturePatientCallSid: VoiceCallDetailsModel;
  capturePaypalOrder: PaypalCaptureOrderResponse;
  changeIntramedThreadTitle: IntramedThreadModel;
  /**
   * @deprecated
   *       Will be split into separate mutations for each action.
   *       Use instead:
   *         CLOSE - closeRequest
   *
   */
  changeRequestStatus: Request;
  /** @deprecated Stop using it. Will be deleted */
  cleanupOutdatedIdentityVerificationFactors: Scalars['Boolean']['output'];
  closeRequest: Request;
  completeDicomDiskUpload: FileModel;
  completeRegistrationByUserClaim: CognitoAuthModel;
  confirmAndExecutePendingApplicationRequest: BasicResourceReferenceModelUnion;
  confirmDraftDirectMessage: ConfirmDraftDirectMessageModel;
  confirmEmailChange: UserEmailChangeRequestModel;
  confirmEventBookingRequest: Request;
  confirmForgotPassword: Scalars['Boolean']['output'];
  confirmOtpChange: OTPChangeResult;
  confirmPassword: CognitoAuthModel;
  confirmPasswordViaRegistration: CognitoAuthModel;
  confirmPhoneNumberChange: UserPhoneNumberChangeRequestModel;
  createCompetitiveSportCertificate: PatientFileModel;
  createDicomDisk: DicomDiskModel;
  createDirectMessageFromDraft: ChatModelUnion;
  createDoctorsSecretaryAssociation: DoctorsSecretaryAssociation;
  /** @deprecated Will be removed. Use createNewEquipe mutation instead */
  createEquipe: Equipe;
  createIdentityVerificationRequest: IdentityVerificationRequestModel;
  createInfoThread: InfoThread;
  createIntramedThread: IntramedThreadModel;
  createNewEquipe: EquipeModel;
  createNonCompetitiveSportCertificate: PatientFileModel;
  createPatient: Patient;
  createPatientProfileForUser: Patient;
  createPaypalOrderFromRequest: PaypalCreateOrderResponse;
  createReminders: Array<ReminderModel>;
  createRequest: Request;
  createRequestFromProposal: Request;
  createRequestProposal: RequestProposalModel;
  createService: ServiceModel;
  createSurveyIssue: Array<SurveyIssueModel>;
  createUserClaim: AppUserClaimModel;
  deleteAllPushNotifications: Scalars['Boolean']['output'];
  deleteCalendar: Scalars['Boolean']['output'];
  deleteCalendarEvent: Scalars['Boolean']['output'];
  deleteCalendarEventSequence: Scalars['Boolean']['output'];
  deleteDoctorSpecializations: Array<DoctorSpecializationModel>;
  deleteDoctorsSecretaryAssociation: Scalars['Boolean']['output'];
  deleteIdentityVerificationFactor: Scalars['Boolean']['output'];
  deleteNonDoctorProfessionalRole: Array<NonDoctorProfessionalRole>;
  deleteReminder: Scalars['Boolean']['output'];
  deleteService: ServiceModel;
  deleteSurveyIssue?: Maybe<SurveyIssueModel>;
  detachPushNotificationsDevice: Scalars['Boolean']['output'];
  doctorCreateRequestOnBehalfOfPatient: MngRequestModel;
  doctorJoinAffiliation: AffiliationForDoctorModel;
  doctorRejectAffiliation: AffiliationForDoctorModel;
  doctorSelfAssignRequest: Request;
  doctorSendPatientInvite: Scalars['Boolean']['output'];
  doctorSetPatientUserEmail: UserModel;
  doctorSubmitGsdHsrSsnFeedbackStatus: MngRequestModel;
  doctorSubmitPdfParametersRevision: FileModel;
  exportInvoices: InvoiceExportModel;
  generateAndSavePdfPatientFile: PatientFileModel;
  generatePatientFileFromPdfData: PatientFile;
  getIdentityVerificationAccessToken: GetIdentityVerificationAccessTokenModel;
  guestSubmitAssistedDoctorSearchForm: PendingApplicationRequestModel;
  issuePatientCallToken: VoiceCallTokenIssueResultModel;
  joinPatientCareTeam: Scalars['Boolean']['output'];
  logoutUser: Scalars['Boolean']['output'];
  markAnnouncementAsRead: AnnouncementModel;
  markUpdateNoticeAsRead: Scalars['Boolean']['output'];
  mngAcceptUserClaim: MngUserClaimModel;
  mngAddClinicCalendarAvailability: CalendarModel;
  mngAddClinicCalendarUnavailability: CalendarModel;
  mngBulkImportBravomedPatients: BulkProcessingResultModel;
  mngBulkImportDoctors: BulkImportDoctorsResultModel;
  mngCancelRequest: MngRequestModel;
  mngChangeAdminUserOtpPassword: CognitoAuthModel;
  mngChangeAdminUserPassword: Scalars['Boolean']['output'];
  mngCloseAndRefundRequest: MngRequestModel;
  mngCreateAdminUser: AdminUserModel;
  mngCreateAffiliation: MngAffiliationModel;
  mngCreateAnnouncement: MngAnnouncementModel;
  mngCreateClinic: MngClinicModel;
  mngCreateClinicService: MngClinicModel;
  mngCreateInstitution: MngInstitutionModel;
  mngCreateUpdateNotice: MngUpdateNoticeModel;
  mngDeleteAffiliation: Scalars['Boolean']['output'];
  mngDeleteClinicCalendar: Scalars['Boolean']['output'];
  mngDeleteClinicCalendarEvent: Scalars['Boolean']['output'];
  mngDeleteClinicCalendarEventSequence: Scalars['Boolean']['output'];
  mngDeleteClinicService: Scalars['Boolean']['output'];
  mngDeleteUser: MngDeleteUserResultModel;
  mngDoctorSetContactPhone: Scalars['Boolean']['output'];
  mngForceUserAuthentication: PendingApplicationRequestModel;
  mngGenerateGsdHsrInvoice: MngFileModel;
  mngGenerateGsdHsrRefund: MngFileModel;
  mngGenerateOnHealthPaymentInvoice: MngFileModel;
  mngGenerateOnHealthRefundInvoice: MngFileModel;
  mngImportGsdHsrGenericClinic: MngClinicModel;
  mngImportGsdHsrSsnClinic: MngClinicModel;
  mngLoginAdminUser: CognitoAdminAuthModel;
  mngLogoutAdminUser: Scalars['Boolean']['output'];
  mngNonDoctorSetContactPhone: Scalars['Boolean']['output'];
  mngPatientSetContactPhone: Scalars['Boolean']['output'];
  mngPublishAffiliation: MngAffiliationModel;
  mngRefreshSession: CognitoAuthModel;
  mngRejectUserClaim: MngUserClaimModel;
  mngRemoveInvoiceFile: Scalars['Boolean']['output'];
  mngRemoveRefundFile: Scalars['Boolean']['output'];
  mngResendInvite: MngInviteModel;
  mngResetUserOtp: UserModel;
  mngSendInvite: MngInviteModel;
  mngSendPatientInvite: Scalars['Boolean']['output'];
  mngSetAffiliationName: MngAffiliationModel;
  mngSetClinicIsEnabled: MngClinicModel;
  mngSetClinicPatientWriteButtonVisibilityPolicy: MngClinicModel;
  mngSetDefaultClinicPatientWritePolicy: MngClinicModel;
  mngSetDoctorDefaultPatientWritePolicy: DoctorModel;
  /** Sets (initializes) the given doctor's OnHealth/external ID, while also importing Services from the remote catalog. */
  mngSetOnHealthDoctorId: Scalars['Void']['output'];
  mngSetPatientWriteButtonVisibilityPolicy: DoctorModel;
  mngSetServiceIsEnabled: MngServiceModel;
  mngSetUserBlocked: Scalars['Boolean']['output'];
  mngUpdateAdminUser: AdminUserModel;
  mngUpdateAnnouncement: MngAnnouncementModel;
  mngUpdateClinicData: MngClinicModel;
  mngUpdateClinicService: ServiceModel;
  mngUpdateGsdHsrClinicData: MngClinicModel;
  mngUpdateGsdHsrGenericClinicService: MngServiceModel;
  mngUpdateGsdHsrSsnClinicService: MngServiceModel;
  mngUpdateInstitution: MngInstitutionModel;
  /**
   * Updates the provided PatientPortalUser(User)'s phoneNumber both locally and on the remote OnHealth API.
   * Intended usage is the resolution of 'COULD_NOT_MATCH_ON_HEALTH_PATIENT_PHONE_NUMBER' error during OnHealth invoice generation.
   * NB: the new local phoneNumber is immediately set as verified.
   */
  mngUpdateLocalAndOnHealthPatientPhoneNumber: Scalars['Void']['output'];
  mngUpdateResidenceAddress: PlaceDetailsModel;
  mngUpdateUserEmail: UserModel;
  mngUpdateUserPersonalData: UserModel;
  mngUpdateUserPhoneNumber: UserModel;
  mngUploadInstitutionLogo: MngInstitutionModel;
  mngUploadInvoice: Scalars['Boolean']['output'];
  mngUploadRefund: Scalars['Boolean']['output'];
  patientCreateGsdHsrSsnRequest: MngRequestModel;
  patientJoinAffiliation: AffiliationForPatientModel;
  patientLookupGsdHsrSsnAppointment: LookupGsdHsrSsnAppointmentResultModel;
  proposeEventBookingRequest: Request;
  refreshSession: CognitoAuthModel;
  refundPaypalOrder: PaypalRefundOrderResponse;
  registerDoctorByIntention: InstitutionUser;
  registerUser: UserRegistrationModel;
  requestEmailChange: UserEmailChangeRequestModel;
  requestOtpChange: PendingOTP;
  requestPhoneNumberChange: UserPhoneNumberChangeRequestModel;
  /** @deprecated Will be removed (bad name); use requestUserDeletion instead */
  requestUserCancellation: Scalars['Void']['output'];
  requestUserDeletion: Scalars['Void']['output'];
  resendInvite: InviteModel;
  resendPendingApplicationRequestOTP: Scalars['Void']['output'];
  restoreFile: PatientFile;
  saveAccountingEntry: AccountingEntryModel;
  saveBirthResidenceAddressToCacheForGsdSsoUser: SaveBirthResidenceAddressForGsdSsoModel;
  saveCciMedicalIndexCalculation: PatientCalculationModel;
  saveCtnmMedicalIndexCalculation: PatientCalculationModel;
  saveDipssMedicalIndexCalculation: PatientCalculationModel;
  saveDipssPlusMedicalIndexCalculation: PatientCalculationModel;
  saveEltsMedicalIndexCalculation: PatientCalculationModel;
  saveGsdHsrBirthAddress: Scalars['Boolean']['output'];
  saveGsdHsrResidenceAddress: Scalars['Boolean']['output'];
  saveIpssrMedicalIndexCalculation: PatientCalculationModel;
  saveMedicalReportTemplate: MedicalReportTemplateModel;
  saveMipss70MedicalIndexCalculation: PatientCalculationModel;
  saveMpn10MedicalIndexCalculation: PatientCalculationModel;
  saveMysecPmMedicalIndexCalculation: PatientCalculationModel;
  savePtnmMedicalIndexCalculation: PatientCalculationModel;
  saveSokalMedicalIndexCalculation: PatientCalculationModel;
  saveTnmAsco2021AppendixMedicalIndexCalculation: PatientCalculationModel;
  saveTnmAsco2021ColonAndRectumMedicalIndexCalculation: PatientCalculationModel;
  saveTnmAsco2021DuodenumAndVaterAmpullaMedicalIndexCalculation: PatientCalculationModel;
  saveTnmAsco2021JejunumAndIleumMedicalIndexCalculation: PatientCalculationModel;
  saveTnmAsco2021PancreasMedicalIndexCalculation: PatientCalculationModel;
  saveTnmAsco2021StomachMedicalIndexCalculation: PatientCalculationModel;
  saveTnmMedicalIndexCalculation: PatientCalculationModel;
  sendDirectMessage: ChatItemModel;
  sendDraftDirectMessage: SendDraftDirectMessageModel;
  sendDraftDirectMessageConfirmationEmail: Scalars['Boolean']['output'];
  sendGsdSsoUserVerificationEmail: Scalars['Boolean']['output'];
  sendInvite: InviteModel;
  sendPatientPrivacyConsentRequest: Scalars['Boolean']['output'];
  sendUserVerificationEmail: UserVerificationModel;
  setChatIsPinnedToHome: Scalars['Void']['output'];
  /** Sets the most recent *read* item in the timeline for the invoker user */
  setChatReadTillItemIncluded: Scalars['Void']['output'];
  /** Sets the last *read* item in the timeline for the invoker user to the ancestor of the provided item */
  setChatUnreadFromItemIncluded: Scalars['Void']['output'];
  setDoctorMedicalRole: Doctor;
  setDoctorRegionalCode: Doctor;
  setInfoThreadPendingStatus: InfoThread;
  setMyInfoRequestAcceptance: DoctorInfoRequestAcceptanceModel;
  setSpecificPatientWritePolicyForClinic: Scalars['Boolean']['output'];
  setSpecificPatientWritePolicyForDoctor: Scalars['Boolean']['output'];
  setSurveyVisibilityOnDoctorDashboard: Scalars['Boolean']['output'];
  setSurveyVisibilityOnPatientDashboard: Scalars['Boolean']['output'];
  setUserTimezone: Scalars['Boolean']['output'];
  signIn: CognitoAuthModel;
  signInForPatientCreatedByDoctor: CognitoAuthModel;
  signInViaRegistration: CognitoAuthModel;
  signInWithGsdSso: SignInWithGsdSsoModel;
  signInWithGsdSsoByCustomEmail: Scalars['Boolean']['output'];
  signPatientFile: PendingApplicationRequestModel;
  submitAssistedDoctorSearchForm: BasicChatReferenceModel;
  submitUserVerification: Scalars['Boolean']['output'];
  submitVerifyEmail: Scalars['Boolean']['output'];
  submitVerifyPhoneNumber: Scalars['Boolean']['output'];
  toggleReminderVisibility: ReminderModel;
  updateDoctorBio: DoctorProfessionalData;
  updateDoctorCanCreateRequestProposalFlag: ServiceModel;
  updateDoctorProfile: Doctor;
  updateDoctorSphereOfInterest: DoctorModel;
  updateDoctorsSecretaryAssociation: DoctorsSecretaryAssociation;
  updateEquipe: Equipe;
  updateFile: PatientFile;
  /**
   *
   *       This mutation is used for updating label, tag and dateOfExecution of a File.
   *       Allowed to be accessed only by initial uploader.
   *       Allowed to be performed only for draft/orphan medical folder File.
   *
   */
  updateFileAttributes: FileModel;
  updateInfoMessageReadStatus: InfoMessageFlag;
  updateInfoThreadStatus: InfoThread;
  updateIntramedMessageImportantFlag: IntramedMessageModel;
  updateIntramedMessageReadFlag: IntramedMessageModel;
  updateIntramedThreadPinnedStatus: IntramedThreadModel;
  updateMyPrivacyAcceptance: UserPrivacy;
  updateNonDoctorProfile: NonDoctor;
  updatePatientBookingRequiresRequestProposalFlag: ServiceModel;
  updatePatientFileReportPrescription: PatientFile;
  updatePatientIdentityDocument: PatientModel;
  updatePatientMedicalData: MedicalData;
  updatePatientPersonalInfo: Patient;
  updatePatientProfile: Patient;
  updatePrivacyAcceptance: Array<UserPrivacy>;
  updateReminder: ReminderModel;
  updateService: ServiceModel;
  updateServiceAvailability: ServiceUpdateAvailabilityModel;
  updateUserFiscalCode: UserModel;
  updateUserInfoData: User;
  updateUserPrivacyConsent: Array<UserPrivacy>;
  uploadDicomFile: Scalars['Boolean']['output'];
  uploadFile: FileUploadResultModel;
  uploadPatientUnclassifiedFile: FileUploadResultModel;
  verifyEmailToSignInWithGsdSso: CognitoAuthModel;
};


export type MutationacceptEhrConsensusPrivacyArgs = {
  userPrivacyId: Scalars['String']['input'];
};


export type MutationacceptEhrConsensusPrivacyByDoctorArgs = {
  userId: Scalars['String']['input'];
};


export type MutationaddAllergiesAnaphylacticShockPatientMedicalDataArgs = {
  data: AllergiesAnaphylacticShockDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAllergiesHymenopteraPatientMedicalDataArgs = {
  data: AllergiesOtherDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAllergiesOtherPatientMedicalDataArgs = {
  data: AllergiesOtherDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisAllogeneicTransplantationPatientMedicalDataArgs = {
  data: AnamnesisAllogeneicTransplantationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisAutoimmuneDiseasesPatientMedicalDataArgs = {
  data: AnamnesisAutoimmuneDiseasesDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisBreastFamilyHistoryPatientMedicalDataArgs = {
  data: AnamnesisBreastFamilyHistoryDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisDonorPatientMedicalDataArgs = {
  data: AnamnesisDonorDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisGlomerulonephritesPatientMedicalDataArgs = {
  data: AnamnesisGlomerulonephritesDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisInfectionsPatientMedicalDataArgs = {
  data: AnamnesisInfectionsDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisIsInTransplantListPatientMedicalDataArgs = {
  data: AnamnesisIsInTransplantListDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisMelanomaFamilyHistoryPatientMedicalDataArgs = {
  data: AnamnesisMelanomaFamilyHistoryDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisNeoplasiaPatientMedicalDataArgs = {
  data: AnamnesisNeoplasiaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisNetSurgeryFlagPatientMedicalDataArgs = {
  data: AnamnesisNetSurgeryFlagDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisNetSurgeryPatientMedicalDataArgs = {
  data: AnamnesisNetSurgeryDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisSevereHypertensionPatientMedicalDataArgs = {
  data: AnamnesisSevereHypertensionDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisSurgeryForBreastCancerPatientMedicalDataArgs = {
  data: AnamnesisSurgeryForBreastCancerDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddAnamnesisTraumaPatientMedicalDataArgs = {
  data: AnamnesisTraumaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddCalendarArgs = {
  dateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateStart: Scalars['DateTime']['input'];
  iterateReturnValue: IterateReturnValueArgs;
  recurrenceType: CalendarRecurrenceType;
  timeSlots?: InputMaybe<Array<CalendarTimeSlotArgs>>;
  type: CalendarType;
};


export type MutationaddCurrentClinicalEvaluationGenericNotesPatientMedicalDataArgs = {
  data: CurrentClinicalEvaluationGenericNotesDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddDiagnosesAhusDiagnosisDatePatientMedicalDataArgs = {
  data: DiagnosesAhusDiagnosisDateDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddDiagnosesDiseaseStagePatientMedicalDataArgs = {
  data: DiagnosesDiseaseStageDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddDiagnosesGenericNotesPatientMedicalDataArgs = {
  data: DiagnosesDiseaseGenericNotesDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddDiagnosesNetGepGradingPatientMedicalDataArgs = {
  data: DiagnosesNetGepGradingDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddDiagnosesNetThoraxGradingPatientMedicalDataArgs = {
  data: DiagnosesNetThoraxGradingDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddDiagnosesNetThoraxStagingTnmAsco2021PatientMedicalDataArgs = {
  data: DiagnosesNetThoraxStagingTnmAsco2021DataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddDoctorSpecializationsArgs = {
  specializations: Array<AddDoctorSpecializationArgs>;
};


export type MutationaddFamilyAnamnesisCardiovascularDiseasePatientMedicalDataArgs = {
  data: FamilyAnamnesisCardiovascularDiseaseDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddFamilyAnamnesisGenericNotesPatientMedicalDataArgs = {
  data: FamilyAnamnesisGenericNotesDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddFamilyAnamnesisKidneyFailurePatientMedicalDataArgs = {
  data: FamilyAnamnesisKidneyFailureDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddFamilyAnamnesisNetGastricUlcersPatientMedicalDataArgs = {
  data: FamilyAnamnesisNetGastricUlcersDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddFamilyAnamnesisNetGlucidicMetabolismAlterationPatientMedicalDataArgs = {
  data: FamilyAnamnesisNetGlucidicMetabolismAlterationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddFamilyAnamnesisNetHypercalcemiaPatientMedicalDataArgs = {
  data: FamilyAnamnesisNetHypercalcemiaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddFamilyAnamnesisNetHypophysisAdenomaPatientMedicalDataArgs = {
  data: FamilyAnamnesisNetHypophysisAdenomaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddFamilyAnamnesisNetMultipleEndocrineNeoplasiaPatientMedicalDataArgs = {
  data: FamilyAnamnesisNetMultipleEndocrineNeoplasiaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddFamilyAnamnesisNetNephrolithiasisPatientMedicalDataArgs = {
  data: FamilyAnamnesisNetNephrolithiasisDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddFamilyAnamnesisNetNeuroendocrineTumorsPatientMedicalDataArgs = {
  data: FamilyAnamnesisNetNeuroendocrineTumorsDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddFamilyAnamnesisNetOtherNeoplasiaPatientMedicalDataArgs = {
  data: FamilyAnamnesisNetOtherNeoplasiaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddGenericAhusTookChargeDatePatientMedicalDataArgs = {
  data: GenericAhusTookChargeDateDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddInfoThreadMessageArgs = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  infoThread?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationaddIntramedMessageArgs = {
  intramedThreadId: Scalars['String']['input'];
  medicalFilesPdfData?: InputMaybe<Array<IntramedMedicalFilePdfDataArgs>>;
  message: MessageArgs;
  multidisciplinaryReportsPdfData?: InputMaybe<Array<MultidisciplinaryReportPdfDataArgs>>;
  otpCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationaddLabResultAdamts13DeliveryDatePatientMedicalDataArgs = {
  data: LabResultAdamts13DeliveryDateDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddLabResultAdamts13RequestDatePatientMedicalDataArgs = {
  data: LabResultAdamts13RequestDateDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddLabResultGeneticsPatientMedicalDataArgs = {
  data: LabResultGeneticsDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddLabResultUrineExamPatientMedicalDataArgs = {
  data: LabResultUrineExamDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddLabResultsBcrAbl1MutationPatientMedicalDataArgs = {
  data: LabResultsBcrAbl1MutationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddLabResultsBcrAbl1PcrQPatientMedicalDataArgs = {
  data: LabResultsBcrAbl1PcrQDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddLabResultsBcrAbl1TranscriptValuePatientMedicalDataArgs = {
  data: LabResultsBcrAbl1TranscriptDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddLabResultsCALRPatientMedicalDataArgs = {
  data: LabResultsCALRDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddLabResultsJak2Exon12PatientMedicalDataArgs = {
  data: LabResultsJak2Exon12DataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddLabResultsJak2V617FPatientMedicalDataArgs = {
  data: LabResultsJak2V617FDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddLabResultsMPLPatientMedicalDataArgs = {
  data: LabResultsMPLDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddLabResultsNGSPatientMedicalDataArgs = {
  data: LabResultsNGSDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddLabResultsSubclonalPatientMedicalDataArgs = {
  data: LabResultsSubclonalDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddMembersToEquipeArgs = {
  equipeId: Scalars['String']['input'];
  portalUserIds: Array<Scalars['String']['input']>;
};


export type MutationaddNonDoctorProfessionalRoleArgs = {
  professionalRole: Scalars['String']['input'];
};


export type MutationaddPathologicalAnamnesisNetGastricUlcersPatientMedicalDataArgs = {
  data: PathologicalAnamnesisNetGastricUlcersDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologicalAnamnesisNetGlucidicMetabolismAlterationPatientMedicalDataArgs = {
  data: PathologicalAnamnesisNetGlucidicMetabolismAlterationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologicalAnamnesisNetHypercalcemiaPatientMedicalDataArgs = {
  data: PathologicalAnamnesisNetHypercalcemiaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologicalAnamnesisNetHypophysisAdenomaPatientMedicalDataArgs = {
  data: PathologicalAnamnesisNetHypophysisAdenomaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologicalAnamnesisNetMultipleEndocrineNeoplasiaPatientMedicalDataArgs = {
  data: PathologicalAnamnesisNetMultipleEndocrineNeoplasiaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologicalAnamnesisNetNephrolithiasisPatientMedicalDataArgs = {
  data: PathologicalAnamnesisNetNephrolithiasisDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologicalAnamnesisNetNeuroendocrineTumorsPatientMedicalDataArgs = {
  data: PathologicalAnamnesisNetNeuroendocrineTumorsDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologicalAnamnesisNetOtherNeoplasiaPatientMedicalDataArgs = {
  data: PathologicalAnamnesisNetOtherNeoplasiaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsACAPatientMedicalDataArgs = {
  data: PathologyResultsACADataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsAhusCytologicalExaminationPatientMedicalDataArgs = {
  data: PathologyResultsAhusCytologicalExaminationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsAhusHystologicalExaminationPatientMedicalDataArgs = {
  data: PathologyResultsAhusHystologicalExaminationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsBMAPatientMedicalDataArgs = {
  data: PathologyResultsBMADataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsBMBPatientMedicalDataArgs = {
  data: PathologyResultsBMBDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsBrafMutationPatientMedicalDataArgs = {
  data: PathologyResultsBrafMutationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsBreastHistologicalExaminationPatientMedicalDataArgs = {
  data: PathologyResultsBreastHistologicalExaminationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsBreastHistotypePatientMedicalDataArgs = {
  data: PathologyResultsBreastHistotypeDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsBreastMetastasisPatientMedicalDataArgs = {
  data: PathologyResultsBreastMetastasisArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsBreastNodesDissectionPatientMedicalDataArgs = {
  data: PathologyResultsBreastNodesDissectionDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsBreastResectionMarginsPatientMedicalDataArgs = {
  data: PathologyResultsBreastResectionMarginsDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsBreastSentinelNodePatientMedicalDataArgs = {
  data: PathologyResultsBreastSentinelNodeDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsCKitMutationPatientMedicalDataArgs = {
  data: PathologyResultsCKitMutationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsGenericNotesPatientMedicalDataArgs = {
  data: PathologyResultsGenericNotesDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsHistologyPatientMedicalDataArgs = {
  data: PathologyResultsHistologyDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsImmunophenotypingPatientMedicalDataArgs = {
  data: PathologyResultsImmunophenotypingDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsKaryotypePatientMedicalDataArgs = {
  data: PathologyResultsKaryotypeDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsMelanomaHistotypePatientMedicalDataArgs = {
  data: PathologyResultsMelanomaHistotypeDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsMelanomaLymphNodesHistologicalExaminationPatientMedicalDataArgs = {
  data: PathologyResultsMelanomaLymphNodesHistologicalExaminationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsMelanomaMetastasisPatientMedicalDataArgs = {
  data: PathologyResultsMelanomaMetastasisDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsMelanomaNodesDissectionPatientMedicalDataArgs = {
  data: PathologyResultsMelanomaNodesDissectionDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsMelanomaResectionMarginsPatientMedicalDataArgs = {
  data: PathologyResultsMelanomaResectionMarginsDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsMelanomaSentinelNodePatientMedicalDataArgs = {
  data: PathologyResultsMelanomaSentinelNodeDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsMelanomaTumorHistologicalExaminationPatientMedicalDataArgs = {
  data: PathologyResultsMelanomaTumorHistologicalExaminationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsNetCytologicalExaminationPatientMedicalDataArgs = {
  data: PathologyResultsNetCytologicalExaminationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsNetHistologicalExaminationPatientMedicalDataArgs = {
  data: PathologyResultsNetHistologicalExaminationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsNetLymphNodesHistologicalExaminationPatientMedicalDataArgs = {
  data: PathologyResultsNetLymphNodesHistologicalExaminationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsNetLymphNodesPresencePatientMedicalDataArgs = {
  data: PathologyResultsNetLymphNodesPresenceDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsNetMetastasisPatientMedicalDataArgs = {
  data: PathologyResultsNetMetastasisDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPathologyResultsNonBrafMutationPatientMedicalDataArgs = {
  data: PathologyResultsNonBrafMutationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPatientLabResultsParametersArgs = {
  parameters: Array<LabResultParameterDataArgs>;
  patient: Scalars['String']['input'];
};


export type MutationaddPhysiologicalHistoryDeathDatePatientMedicalDataArgs = {
  data: PhysiologicalHistoryDeathDateDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPhysiologicalHistoryFitzpatrickScalePatientMedicalDataArgs = {
  data: PhysiologicalHistoryFitzpatrickScaleDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPhysiologicalHistoryGenericNotesPatientMedicalDataArgs = {
  data: PhysiologicalHistoryGenericNotesDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPhysiologicalHistoryMenopausePatientMedicalDataArgs = {
  data: PhysiologicalHistoryMenopauseDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPhysiologicalHistoryMenstrualCyclePatientMedicalDataArgs = {
  data: PhysiologicalHistoryMenstrualCycleDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddPhysiologicalHistorySmokePatientMedicalDataArgs = {
  data: PhysiologicalHistorySmokeDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddProfileCvToDoctorArgs = {
  cv: Scalars['Upload']['input'];
};


export type MutationaddProfileImagesArgs = {
  profileImage: Scalars['Upload']['input'];
  thumbnailImage: Scalars['Upload']['input'];
};


export type MutationaddRadiologyAbdomenUltrasoundPatientMedicalDataArgs = {
  data: RadiologyAbdomenUltrasoundDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddRadiologyBoneDensitometryPatientMedicalDataArgs = {
  data: RadiologyBoneDensitometryDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddRadiologyBoneImagingPatientMedicalDataArgs = {
  data: RadiologyBoneImagingDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddRadiologyECGPatientMedicalDataArgs = {
  data: RadiologyECGDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddRadiologyEchoPatientMedicalDataArgs = {
  data: RadiologyEchoDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddRadiologyEyeExaminationPatientMedicalDataArgs = {
  data: RadiologyEyeExaminationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddRadiologySncLocalizationsPatientMedicalDataArgs = {
  data: RadiologySncLocalizationsDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddRiskScoreGenericNotesPatientMedicalDataArgs = {
  data: RiskScoreGenericNotesDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsAbdominalPainPatientMedicalDataArgs = {
  data: SignsAndSymptomsAbdominalPainDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsAstheniaPatientMedicalDataArgs = {
  data: SignsAndSymptomsAstheniaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsBlurredEyesightPatientMedicalDataArgs = {
  data: SignsAndSymptomsBlurredEyesightDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsComaPatientMedicalDataArgs = {
  data: SignsAndSymptomsComaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsConfusionPatientMedicalDataArgs = {
  data: SignsAndSymptomsConfusionDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsConvulsionsPatientMedicalDataArgs = {
  data: SignsAndSymptomsConvulsionsDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsDispneaPatientMedicalDataArgs = {
  data: SignsAndSymptomsDispneaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsEcogPatientMedicalDataArgs = {
  data: SignsAndSymptomsEcogDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsEdemasPatientMedicalDataArgs = {
  data: SignsAndSymptomsEdemasDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsEyeBleedingPatientMedicalDataArgs = {
  data: SignsAndSymptomsEyeBleedingDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsEyePainPatientMedicalDataArgs = {
  data: SignsAndSymptomsEyePainDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsFeverPatientMedicalDataArgs = {
  data: SignsAndSymptomsFeverDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsGastrointestinalDisordersPatientMedicalDataArgs = {
  data: SignsAndSymptomsGastrointestinalDisordersDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsGenericNotesPatientMedicalDataArgs = {
  data: SignsAndSymptomsGenericNotesDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsHeartAttackPatientMedicalDataArgs = {
  data: SignsAndSymptomsHeartAttackDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsHematuriaPatientMedicalDataArgs = {
  data: SignsAndSymptomsHematuriaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsHemophthousPatientMedicalDataArgs = {
  data: SignsAndSymptomsHemophthousDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsHemoptysisPatientMedicalDataArgs = {
  data: SignsAndSymptomsHemoptysisDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsHypertensionPatientMedicalDataArgs = {
  data: SignsAndSymptomsHypertensionDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsNauseaPatientMedicalDataArgs = {
  data: SignsAndSymptomsNauseaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsNetAchePatientMedicalDataArgs = {
  data: SignsAndSymptomsNetAcheDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsNetDiarrheaPatientMedicalDataArgs = {
  data: SignsAndSymptomsNetDiarrheaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsNetFlushingPatientMedicalDataArgs = {
  data: SignsAndSymptomsNetFlushingDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsNetJaundicePatientMedicalDataArgs = {
  data: SignsAndSymptomsNetJaundiceDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsNetRetchingPatientMedicalDataArgs = {
  data: SignsAndSymptomsNetRetchingDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsNetTachycardiaPatientMedicalDataArgs = {
  data: SignsAndSymptomsNetTachycardiaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsNetWeightLossPatientMedicalDataArgs = {
  data: SignsAndSymptomsNetWeightLossDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsOligoanuriaPatientMedicalDataArgs = {
  data: SignsAndSymptomsOligoanuriaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsPancreatitisPatientMedicalDataArgs = {
  data: SignsAndSymptomsPancreatitisDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsPulmonaryBleedingPatientMedicalDataArgs = {
  data: SignsAndSymptomsPulmonaryBleedingDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsPulmonaryEdemaPatientMedicalDataArgs = {
  data: SignsAndSymptomsPulmonaryEdemaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsRetinalVesselsObstructionPatientMedicalDataArgs = {
  data: SignsAndSymptomsRetinalVesselsObstructionDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsSkinInvolvementPatientMedicalDataArgs = {
  data: SignsAndSymptomsSkinInvolvementDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsSplenomegalyPalpablePatientMedicalDataArgs = {
  data: SignsAndSymptomsSplenomegalyPalpableDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsStenosisPatientMedicalDataArgs = {
  data: SignsAndSymptomsStenosisDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsStrokePatientMedicalDataArgs = {
  data: SignsAndSymptomsStrokeDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsSyncopesPatientMedicalDataArgs = {
  data: SignsAndSymptomsSyncopesDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsTachypneaPatientMedicalDataArgs = {
  data: SignsAndSymptomsTachypneaDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSignsAndSymptomsVisualImpairmentPatientMedicalDataArgs = {
  data: SignsAndSymptomsVisualImpairmentDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddSupportRequestArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationaddTherapyAhusMedicationsStartDatePatientMedicalDataArgs = {
  data: TherapyAhusMedicationsStartDateDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyApheresisPatientMedicalDataArgs = {
  data: TherapyApheresisDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyBreastAdjuvantTherapyPatientMedicalDataArgs = {
  data: TherapyBreastAdjuvantTherapyDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyBreastHormoneTherapyPatientMedicalDataArgs = {
  data: TherapyBreastHormoneTherapyDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyBreastNeoadjuvantTherapyPatientMedicalDataArgs = {
  data: TherapyBreastNeoadjuvantTherapyDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyBreastRadiotherapyPatientMedicalDataArgs = {
  data: TherapyBreastRadiotherapyDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyCarTEligibilityPatientMedicalDataArgs = {
  data: TherapyCarTEligibilityDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyCarTInfusionPatientMedicalDataArgs = {
  data: TherapyCarTInfusionDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyCarTTreatmentArrivePatientMedicalDataArgs = {
  data: TherapyCarTTreatmentArriveDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyCarTTreatmentOrderPatientMedicalDataArgs = {
  data: TherapyCarTTreatmentOrderDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyHematologyPatientMedicalDataArgs = {
  data: TherapyHematologyDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyNetAdjuvantTherapyPatientMedicalDataArgs = {
  data: TherapyNetAdjuvantTherapyDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyNetNeoadjuvantTherapyPatientMedicalDataArgs = {
  data: TherapyNetNeoadjuvantTherapyDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyNetRadioReceptorTherapyPatientMedicalDataArgs = {
  data: TherapyNetRadioReceptorTherapyDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyNetSomatostatinAnalogueTherapyPatientMedicalDataArgs = {
  data: TherapyNetSomatostatinAnalogueTherapyDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTherapyTherapyPatientMedicalDataArgs = {
  data: TherapyTherapyDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddTumoursGenericInformationPatientMedicalDataArgs = {
  data: TumoursGenericInformationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddUsersToEquipeArgs = {
  equipeId: Scalars['String']['input'];
  profileIdList: Array<Scalars['String']['input']>;
};


export type MutationaddVaccinationsAntimeningococcalVaccinationPatientMedicalDataArgs = {
  data: VaccinationsAntimeningococcalVaccinationDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddVaccinationsGenericNotesPatientMedicalDataArgs = {
  data: VaccinationsGenericNotesDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationaddVitalsParametersGenericNotesPatientMedicalDataArgs = {
  data: VitalsParametersGenericNotesDataArgs;
  patient: Scalars['String']['input'];
};


export type MutationanswerSurveyArgs = {
  answerData: SurveyAnswerDataArgs;
  surveyIssueRecordId: Scalars['String']['input'];
};


export type MutationanswerSurveyOnBehalfOfAPatientArgs = {
  answerData: SurveyAnswerDataArgs;
  patientInstitutionUserId: Scalars['String']['input'];
  surveyId: Scalars['String']['input'];
};


export type MutationattachBillingDataToDoctorArgs = {
  iban: Scalars['String']['input'];
};


export type MutationattachPatientFileArgs = {
  fileId: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
};


export type MutationattachPushNotificationsDeviceArgs = {
  applicationVersion?: InputMaybe<Scalars['String']['input']>;
  bundleId: PushNotificationBundleId;
  deviceId: Scalars['String']['input'];
  deviceOS?: InputMaybe<PushNotificationDeviceOS>;
  deviceToken: Scalars['String']['input'];
};


export type MutationattachResidenceAddressArgs = {
  externalGooglePlaceId: Scalars['String']['input'];
  gsdHsrResidenceAddress?: InputMaybe<GsdHsrResidenceAddressArgs>;
};


export type MutationattachResidenceAddressToPatientArgs = {
  externalGooglePlaceId: Scalars['String']['input'];
  gsdHsrResidenceAddress?: InputMaybe<GsdHsrResidenceAddressArgs>;
  patient: Scalars['String']['input'];
};


export type MutationcallEmailVerificationFlowArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationcallPhoneNumberVerificationFlowArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationcancelDicomDiskUploadArgs = {
  dicomDiskId: Scalars['String']['input'];
};


export type MutationcancelFileArgs = {
  file: Scalars['String']['input'];
  patient: Scalars['String']['input'];
};


export type MutationcapturePatientCallSidArgs = {
  callSid: Scalars['String']['input'];
  clinicId?: InputMaybe<Scalars['String']['input']>;
  patientPortalUserId: Scalars['String']['input'];
};


export type MutationcapturePaypalOrderArgs = {
  order: Scalars['String']['input'];
};


export type MutationchangeIntramedThreadTitleArgs = {
  intramedThreadId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationchangeRequestStatusArgs = {
  action: RequestStatusAction;
  request: Scalars['String']['input'];
};


export type MutationcleanupOutdatedIdentityVerificationFactorsArgs = {
  deviceId: Scalars['String']['input'];
  factorSid: Scalars['String']['input'];
};


export type MutationcloseRequestArgs = {
  requestId: Scalars['String']['input'];
};


export type MutationcompleteDicomDiskUploadArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  dicomDiskId: Scalars['String']['input'];
  label: Scalars['String']['input'];
};


export type MutationcompleteRegistrationByUserClaimArgs = {
  password: Scalars['String']['input'];
  userClaimToken: Scalars['String']['input'];
};


export type MutationconfirmAndExecutePendingApplicationRequestArgs = {
  otp?: InputMaybe<Scalars['String']['input']>;
  pendingApplicationRequestId: Scalars['String']['input'];
};


export type MutationconfirmDraftDirectMessageArgs = {
  token: Scalars['String']['input'];
};


export type MutationconfirmEmailChangeArgs = {
  token: Scalars['String']['input'];
};


export type MutationconfirmEventBookingRequestArgs = {
  dateType: BookingRequestDateType;
  request: Scalars['String']['input'];
};


export type MutationconfirmForgotPasswordArgs = {
  confirmationCode: Scalars['String']['input'];
  emailOrPhoneNumber: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationconfirmOtpChangeArgs = {
  code: Scalars['String']['input'];
};


export type MutationconfirmPasswordArgs = {
  emailOrPhoneNumber: Scalars['String']['input'];
  institutionId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationconfirmPasswordViaRegistrationArgs = {
  acceptedPrivacies: Array<PrivacyAcceptance>;
  emailOrPhoneNumber: Scalars['String']['input'];
  institutionId: Scalars['String']['input'];
  inviteId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  profileType: ProfileType;
};


export type MutationconfirmPhoneNumberChangeArgs = {
  token: Scalars['String']['input'];
};


export type MutationcreateCompetitiveSportCertificateArgs = {
  certificateNumber: Scalars['String']['input'];
  correctiveLenses: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  otpCode: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  sport: Scalars['String']['input'];
  validForMonths: Scalars['Int']['input'];
};


export type MutationcreateDirectMessageFromDraftArgs = {
  draftDirectMessageId: Scalars['String']['input'];
};


export type MutationcreateDoctorsSecretaryAssociationArgs = {
  associateWith: Scalars['String']['input'];
};


export type MutationcreateEquipeArgs = {
  institutionId: Scalars['String']['input'];
  institutionUserIdList: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


export type MutationcreateInfoThreadArgs = {
  institutionUser?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  text: Scalars['String']['input'];
};


export type MutationcreateIntramedThreadArgs = {
  forwardedMessage?: InputMaybe<IntramedForwardedMessageArgs>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  recipientEquipeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  recipientPortalUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationcreateNewEquipeArgs = {
  name: Scalars['String']['input'];
  portalUserIds: Array<Scalars['String']['input']>;
};


export type MutationcreateNonCompetitiveSportCertificateArgs = {
  ecgDate: Scalars['DateTime']['input'];
  leftEyeVisualAcuity: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  otpCode: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  rightEyeVisualAcuity: Scalars['String']['input'];
  sport: Scalars['String']['input'];
  validForMonths: Scalars['Int']['input'];
};


export type MutationcreatePatientArgs = {
  birthAddressExternalGooglePlaceId: Scalars['String']['input'];
  birthDate: Scalars['DateTime']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  fiscalCode?: InputMaybe<Scalars['String']['input']>;
  gender: Gender;
  institutionId?: InputMaybe<Scalars['String']['input']>;
  isConsentReceived: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationcreatePatientProfileForUserArgs = {
  institutionId?: InputMaybe<Scalars['String']['input']>;
  isConsentReceived: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};


export type MutationcreatePaypalOrderFromRequestArgs = {
  request: Scalars['String']['input'];
};


export type MutationcreateRemindersArgs = {
  dateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateStart: Scalars['DateTime']['input'];
  description: Scalars['String']['input'];
  emailNotificationTime: Scalars['String']['input'];
  isFromOneToOneChat?: InputMaybe<Scalars['Boolean']['input']>;
  recipients: Array<ReminderRecipientArgs>;
  recurrenceType: ReminderRecurrenceType;
  title: Scalars['String']['input'];
};


export type MutationcreateRequestArgs = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  eventBookingDate?: InputMaybe<Scalars['DateTime']['input']>;
  patientPortalUserId?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  recipientPortalUserId?: InputMaybe<Scalars['String']['input']>;
  requestEventBookingRequest?: InputMaybe<BookingRequestArgs>;
  selectedServiceAddons?: InputMaybe<Array<Scalars['String']['input']>>;
  service: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
};


export type MutationcreateRequestFromProposalArgs = {
  requestProposalId: Scalars['String']['input'];
};


export type MutationcreateRequestProposalArgs = {
  forcedVideoVisitDate?: InputMaybe<Scalars['DateTime']['input']>;
  proposeePatientPortalUserId: Scalars['String']['input'];
  selectedServiceAddonTypes: Array<ServiceAddonType>;
  serviceId: Scalars['String']['input'];
};


export type MutationcreateServiceArgs = {
  addons?: InputMaybe<Array<CreateAddonArgs>>;
  allowsOnDemandSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  baseServiceType: ServiceType;
  description: Scalars['String']['input'];
  doctorCanCreateRequestProposal?: Scalars['Boolean']['input'];
  durationMinutes?: InputMaybe<Scalars['Float']['input']>;
  integrationServiceType?: InputMaybe<IntegrationServiceType>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  patientBookingRequiresRequestProposal?: Scalars['Boolean']['input'];
  price: Scalars['Float']['input'];
  providedWithinDays?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
};


export type MutationcreateSurveyIssueArgs = {
  dateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateStart: Scalars['DateTime']['input'];
  recipientsIds: Array<Scalars['String']['input']>;
  recurrenceType: SurveyIssueRecurrenceType;
  surveyId: Scalars['String']['input'];
};


export type MutationcreateUserClaimArgs = {
  acceptedPrivacies: Array<PrivacyAcceptance>;
  attachments: Array<Scalars['Upload']['input']>;
  email: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  residenceAddressExternalGooglePlaceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationdeleteCalendarArgs = {
  calendarId: Scalars['String']['input'];
};


export type MutationdeleteCalendarEventArgs = {
  calendarId: Scalars['String']['input'];
  eventDate: Scalars['DateTime']['input'];
};


export type MutationdeleteCalendarEventSequenceArgs = {
  calendarId: Scalars['String']['input'];
  startEventDate: Scalars['DateTime']['input'];
};


export type MutationdeleteDoctorSpecializationsArgs = {
  specializationIds: Array<Scalars['String']['input']>;
};


export type MutationdeleteDoctorsSecretaryAssociationArgs = {
  associationId: Scalars['String']['input'];
};


export type MutationdeleteIdentityVerificationFactorArgs = {
  factorSid: Scalars['String']['input'];
};


export type MutationdeleteNonDoctorProfessionalRoleArgs = {
  professionalRoleId: Scalars['String']['input'];
};


export type MutationdeleteReminderArgs = {
  reminderId: Scalars['String']['input'];
};


export type MutationdeleteServiceArgs = {
  id: Scalars['String']['input'];
};


export type MutationdeleteSurveyIssueArgs = {
  surveyIssueId: Scalars['String']['input'];
};


export type MutationdetachPushNotificationsDeviceArgs = {
  bundleId: PushNotificationBundleId;
  deviceId: Scalars['String']['input'];
};


export type MutationdoctorCreateRequestOnBehalfOfPatientArgs = {
  attachmentFileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  eventBookingDate?: InputMaybe<Scalars['DateTime']['input']>;
  patientPortalUserId: Scalars['String']['input'];
  question?: InputMaybe<Scalars['String']['input']>;
  selectedServiceAddonIds?: InputMaybe<Array<Scalars['String']['input']>>;
  serviceId: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
};


export type MutationdoctorJoinAffiliationArgs = {
  affiliationId: Scalars['String']['input'];
};


export type MutationdoctorRejectAffiliationArgs = {
  affiliationId: Scalars['String']['input'];
};


export type MutationdoctorSelfAssignRequestArgs = {
  requestId: Scalars['String']['input'];
};


export type MutationdoctorSendPatientInviteArgs = {
  patientPortalUserId: Scalars['String']['input'];
};


export type MutationdoctorSetPatientUserEmailArgs = {
  email: Scalars['String']['input'];
  patientId?: InputMaybe<Scalars['String']['input']>;
  patientPortalUserId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationdoctorSubmitGsdHsrSsnFeedbackStatusArgs = {
  couldFulfill: Scalars['Boolean']['input'];
  couldNotFulfillReason?: InputMaybe<GsdHsrSsnCouldNotFulfillReason>;
  requestId: Scalars['String']['input'];
};


export type MutationdoctorSubmitPdfParametersRevisionArgs = {
  confirmed: Array<ConfirmedPdfParameterArgs>;
  fileId: Scalars['String']['input'];
  rejected: Array<SubSectionLabParameter>;
};


export type MutationexportInvoicesArgs = {
  adminId: Scalars['String']['input'];
  generateTo: Scalars['DateTime']['input'];
  invoiceCode: Scalars['String']['input'];
};


export type MutationgenerateAndSavePdfPatientFileArgs = {
  fileType: GenerateAndStorePdfPatientFileFileType;
  otpCode: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  subjects: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};


export type MutationgeneratePatientFileFromPdfDataArgs = {
  otpCode: Scalars['String']['input'];
  patient: Scalars['String']['input'];
  pdfData: Scalars['String']['input'];
};


export type MutationgetIdentityVerificationAccessTokenArgs = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationguestSubmitAssistedDoctorSearchFormArgs = {
  data: SubmitAssistedDoctorSearchFormArgs;
  forPatientUserEmail: Scalars['String']['input'];
};


export type MutationissuePatientCallTokenArgs = {
  patientPortalUserId: Scalars['String']['input'];
  question?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};


export type MutationjoinPatientCareTeamArgs = {
  birthAddressExternalGooglePlaceId: Scalars['String']['input'];
  birthDate: Scalars['DateTime']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  lastName: Scalars['String']['input'];
};


export type MutationlogoutUserArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationmarkAnnouncementAsReadArgs = {
  announcementId: Scalars['String']['input'];
};


export type MutationmarkUpdateNoticeAsReadArgs = {
  updateNoticeId: Scalars['String']['input'];
};


export type MutationmngAcceptUserClaimArgs = {
  userClaimId: Scalars['String']['input'];
};


export type MutationmngAddClinicCalendarAvailabilityArgs = {
  clinicId: Scalars['String']['input'];
  dateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateStart: Scalars['DateTime']['input'];
  iterateReturnValue: IterateReturnValueArgs;
  recurrenceType: CalendarRecurrenceType;
  timeSlots?: InputMaybe<Array<CalendarTimeSlotArgs>>;
};


export type MutationmngAddClinicCalendarUnavailabilityArgs = {
  clinicId: Scalars['String']['input'];
  dateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateStart: Scalars['DateTime']['input'];
  iterateReturnValue: IterateReturnValueArgs;
  recurrenceType: CalendarRecurrenceType;
};


export type MutationmngBulkImportBravomedPatientsArgs = {
  bravomedCompanyId: Scalars['String']['input'];
  collectErrorTrace?: Scalars['Boolean']['input'];
  csv: Scalars['Upload']['input'];
  delimiter: CsvDelimiter;
  dryRun: Scalars['Boolean']['input'];
  includesHeaders: Scalars['Boolean']['input'];
};


export type MutationmngBulkImportDoctorsArgs = {
  collectErrorTrace?: Scalars['Boolean']['input'];
  delimiter: CsvDelimiter;
  doctorsCsv: Scalars['Upload']['input'];
  dryRun: Scalars['Boolean']['input'];
  includesHeaders: Scalars['Boolean']['input'];
  servicesCsv?: InputMaybe<Scalars['Upload']['input']>;
};


export type MutationmngCancelRequestArgs = {
  requestId: Scalars['String']['input'];
};


export type MutationmngChangeAdminUserOtpPasswordArgs = {
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  session: Scalars['String']['input'];
};


export type MutationmngChangeAdminUserPasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationmngCloseAndRefundRequestArgs = {
  requestId: Scalars['String']['input'];
};


export type MutationmngCreateAdminUserArgs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  portalIds: Array<Scalars['String']['input']>;
};


export type MutationmngCreateAffiliationArgs = {
  endDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  services: Array<MngAffiliationServiceArgs>;
  startDate: Scalars['DateTime']['input'];
};


export type MutationmngCreateAnnouncementArgs = {
  portalIds: Array<Scalars['String']['input']>;
  profileTypes: Array<ProfileType>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationmngCreateClinicArgs = {
  bookableByProfileTypes: Array<ProfileType>;
  bookingCapacity: Scalars['Int']['input'];
  defaultPatientWritePolicy: AllowDenyPolicyType;
  description: Scalars['String']['input'];
  isEnabled: Scalars['Boolean']['input'];
  memberPortalUserIds: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  patientWriteButtonVisibilityPolicy: AllowDenyPolicyType;
  services: Array<CreateClinicServiceArgs>;
  slug?: InputMaybe<Scalars['String']['input']>;
  specializations: Array<Specialization>;
};


export type MutationmngCreateClinicServiceArgs = {
  clinicId: Scalars['String']['input'];
  service: CreateClinicServiceArgs;
};


export type MutationmngCreateInstitutionArgs = {
  region: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: InstitutionType;
};


export type MutationmngCreateUpdateNoticeArgs = {
  content: Scalars['String']['input'];
  portalIds: Array<Scalars['String']['input']>;
  profileTypes: Array<ProfileType>;
  title: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  validFrom: Scalars['DateTime']['input'];
  validTo: Scalars['DateTime']['input'];
};


export type MutationmngDeleteAffiliationArgs = {
  affiliationId: Scalars['String']['input'];
};


export type MutationmngDeleteClinicCalendarArgs = {
  calendarId: Scalars['String']['input'];
};


export type MutationmngDeleteClinicCalendarEventArgs = {
  calendarId: Scalars['String']['input'];
  eventDate: Scalars['DateTime']['input'];
};


export type MutationmngDeleteClinicCalendarEventSequenceArgs = {
  calendarId: Scalars['String']['input'];
  startEventDate: Scalars['DateTime']['input'];
};


export type MutationmngDeleteClinicServiceArgs = {
  serviceId: Scalars['String']['input'];
};


export type MutationmngDeleteUserArgs = {
  dryRun: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};


export type MutationmngDoctorSetContactPhoneArgs = {
  doctorId: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationmngForceUserAuthenticationArgs = {
  userId: Scalars['String']['input'];
};


export type MutationmngGenerateGsdHsrInvoiceArgs = {
  orderId: Scalars['String']['input'];
};


export type MutationmngGenerateGsdHsrRefundArgs = {
  orderId: Scalars['String']['input'];
};


export type MutationmngGenerateOnHealthPaymentInvoiceArgs = {
  orderId: Scalars['String']['input'];
};


export type MutationmngGenerateOnHealthRefundInvoiceArgs = {
  orderId: Scalars['String']['input'];
};


export type MutationmngImportGsdHsrGenericClinicArgs = {
  clinic: ClinicDataArgs;
  gsdHsrClinicData: GsdHsrClinicDataArgs;
  services: Array<GsdHsrServiceConfigurationArgs>;
};


export type MutationmngImportGsdHsrSsnClinicArgs = {
  clinic: SsnClinicDataArgs;
  gsdHsrSsnClinicData: GsdHsrSsnClinicDataArgs;
  service: GsdHsrSsnServiceArgs;
};


export type MutationmngLoginAdminUserArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationmngLogoutAdminUserArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationmngNonDoctorSetContactPhoneArgs = {
  nonDoctorId: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationmngPatientSetContactPhoneArgs = {
  patientId: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationmngPublishAffiliationArgs = {
  affiliationId: Scalars['String']['input'];
  isSilent: Scalars['Boolean']['input'];
};


export type MutationmngRefreshSessionArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationmngRejectUserClaimArgs = {
  rejectedReason: Scalars['String']['input'];
  userClaimId: Scalars['String']['input'];
};


export type MutationmngRemoveInvoiceFileArgs = {
  fileId: Scalars['String']['input'];
};


export type MutationmngRemoveRefundFileArgs = {
  fileId: Scalars['String']['input'];
};


export type MutationmngResendInviteArgs = {
  inviteId: Scalars['String']['input'];
};


export type MutationmngResetUserOtpArgs = {
  userId: Scalars['String']['input'];
};


export type MutationmngSendInviteArgs = {
  invite: CreateInviteArgs;
};


export type MutationmngSendPatientInviteArgs = {
  patientPortalUserId: Scalars['String']['input'];
};


export type MutationmngSetAffiliationNameArgs = {
  affiliationId: Scalars['String']['input'];
  newName: Scalars['String']['input'];
};


export type MutationmngSetClinicIsEnabledArgs = {
  clinicId: Scalars['String']['input'];
  isEnabled: Scalars['Boolean']['input'];
};


export type MutationmngSetClinicPatientWriteButtonVisibilityPolicyArgs = {
  clinicId: Scalars['String']['input'];
  policy: AllowDenyPolicyType;
};


export type MutationmngSetDefaultClinicPatientWritePolicyArgs = {
  clinicId: Scalars['String']['input'];
  policy: AllowDenyPolicyType;
};


export type MutationmngSetDoctorDefaultPatientWritePolicyArgs = {
  doctorPortalUserId: Scalars['String']['input'];
  policy: AllowDenyPolicyType;
};


export type MutationmngSetOnHealthDoctorIdArgs = {
  doctorPortalUserId: Scalars['String']['input'];
  onHealthDoctorId: Scalars['String']['input'];
};


export type MutationmngSetPatientWriteButtonVisibilityPolicyArgs = {
  doctorId: Scalars['String']['input'];
  policy: AllowDenyPolicyType;
};


export type MutationmngSetServiceIsEnabledArgs = {
  isEnabled: Scalars['Boolean']['input'];
  serviceId: Scalars['String']['input'];
};


export type MutationmngSetUserBlockedArgs = {
  blocked: Scalars['Boolean']['input'];
  profileType: ProfileType;
  userId: Scalars['String']['input'];
};


export type MutationmngUpdateAdminUserArgs = {
  adminUserId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationmngUpdateAnnouncementArgs = {
  announcementId: Scalars['String']['input'];
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationmngUpdateClinicDataArgs = {
  bookableByProfileTypes: Array<ProfileType>;
  bookingCapacity: Scalars['Int']['input'];
  clinicId: Scalars['String']['input'];
  description: Scalars['String']['input'];
  isEnabled: Scalars['Boolean']['input'];
  memberPortalUserIds: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  specializations: Array<Specialization>;
};


export type MutationmngUpdateClinicServiceArgs = {
  addons: Array<EditAddonArgs>;
  description: Scalars['String']['input'];
  durationMinutes?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  isEnabled: Scalars['Boolean']['input'];
  price: Scalars['Float']['input'];
  providedWithinDays?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
};


export type MutationmngUpdateGsdHsrClinicDataArgs = {
  bookingCapacity: Scalars['Int']['input'];
  clinicId: Scalars['String']['input'];
  description: Scalars['String']['input'];
  isEnabled: Scalars['Boolean']['input'];
  memberPortalUserIds: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  specializations: Array<Specialization>;
};


export type MutationmngUpdateGsdHsrGenericClinicServiceArgs = {
  addons?: InputMaybe<Array<GsdHsrServiceAddonConfigurationArgs>>;
  description: Scalars['String']['input'];
  durationMinutes?: InputMaybe<Scalars['Int']['input']>;
  isEnabled: Scalars['Boolean']['input'];
  providedWithinDays?: InputMaybe<Scalars['Int']['input']>;
  serviceId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationmngUpdateGsdHsrSsnClinicServiceArgs = {
  description: Scalars['String']['input'];
  durationMinutes: Scalars['Int']['input'];
  isRadiologicalImageAddonEnabled: Scalars['Boolean']['input'];
  serviceId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationmngUpdateInstitutionArgs = {
  institutionId: Scalars['String']['input'];
  region: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: InstitutionType;
};


export type MutationmngUpdateLocalAndOnHealthPatientPhoneNumberArgs = {
  patientPortalUserId: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationmngUpdateResidenceAddressArgs = {
  externalGooglePlaceId: Scalars['String']['input'];
  gsdHsrResidenceAddress?: InputMaybe<GsdHsrResidenceAddressArgs>;
  language?: InputMaybe<Language>;
  userId: Scalars['String']['input'];
};


export type MutationmngUpdateUserEmailArgs = {
  email: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationmngUpdateUserPersonalDataArgs = {
  birthAddressExternalGooglePlaceId?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fiscalCode?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  gsdHsrBirthAddress?: InputMaybe<GsdHsrBirthAddressArgs>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type MutationmngUpdateUserPhoneNumberArgs = {
  newPhoneNumber: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationmngUploadInstitutionLogoArgs = {
  institutionId: Scalars['String']['input'];
  logo: Scalars['Upload']['input'];
};


export type MutationmngUploadInvoiceArgs = {
  fileUpload: Scalars['Upload']['input'];
  requestId: Scalars['String']['input'];
};


export type MutationmngUploadRefundArgs = {
  fileUpload: Scalars['Upload']['input'];
  requestId: Scalars['String']['input'];
};


export type MutationpatientCreateGsdHsrSsnRequestArgs = {
  appointmentCode: Scalars['String']['input'];
};


export type MutationpatientJoinAffiliationArgs = {
  code: Scalars['String']['input'];
};


export type MutationpatientLookupGsdHsrSsnAppointmentArgs = {
  appointmentCode: Scalars['String']['input'];
};


export type MutationproposeEventBookingRequestArgs = {
  primaryDate: Scalars['DateTime']['input'];
  request: Scalars['String']['input'];
  secondaryDate: Scalars['DateTime']['input'];
};


export type MutationrefreshSessionArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationrefundPaypalOrderArgs = {
  order: Scalars['String']['input'];
};


export type MutationregisterDoctorByIntentionArgs = {
  additionalProfileData: DoctorAdditionalProfileDataArgs;
  email: Scalars['String']['input'];
  emailNotificationPeriod: EmailNotificationPeriod;
  iban?: InputMaybe<Scalars['String']['input']>;
  institutionId: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  sphereOfInterest?: InputMaybe<Array<Scalars['String']['input']>>;
  titleCode?: InputMaybe<UserTitle>;
};


export type MutationregisterUserArgs = {
  acceptedPrivacies: Array<PrivacyAcceptance>;
  birthAddressExternalGooglePlaceId: Scalars['String']['input'];
  birthDate: Scalars['DateTime']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  fiscalCode?: InputMaybe<Scalars['String']['input']>;
  gender: Gender;
  institutionId: Scalars['String']['input'];
  inviteId?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  profileType: ProfileType;
  residenceAddressExternalGooglePlaceId: Scalars['String']['input'];
};


export type MutationrequestEmailChangeArgs = {
  newEmail: Scalars['String']['input'];
};


export type MutationrequestPhoneNumberChangeArgs = {
  newPhoneNumber: Scalars['String']['input'];
};


export type MutationresendInviteArgs = {
  inviteId: Scalars['String']['input'];
};


export type MutationresendPendingApplicationRequestOTPArgs = {
  pendingApplicationRequestId: Scalars['String']['input'];
};


export type MutationrestoreFileArgs = {
  file: Scalars['String']['input'];
  patient: Scalars['String']['input'];
};


export type MutationsaveBirthResidenceAddressToCacheForGsdSsoUserArgs = {
  birthAddressExternalGooglePlaceId?: InputMaybe<Scalars['String']['input']>;
  gsdSsoRequestId: Scalars['String']['input'];
  gsdSsoUserId: Scalars['String']['input'];
  residenceAddressExternalGooglePlaceId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsaveCciMedicalIndexCalculationArgs = {
  cciMedicalIndexParametersModel: CciMedicalIndexParametersModel;
  dateOfExecution: Scalars['DateTime']['input'];
  patientId: Scalars['String']['input'];
};


export type MutationsaveCtnmMedicalIndexCalculationArgs = {
  ctnmMedicalIndexParametersModel: CtnmMedicalIndexParametersModel;
  dateOfExecution: Scalars['DateTime']['input'];
  patientId: Scalars['String']['input'];
};


export type MutationsaveDipssMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  dipssMedicalIndexParametersModel: DipssMedicalIndexParametersModel;
  patientId: Scalars['String']['input'];
};


export type MutationsaveDipssPlusMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  dipssPlusMedicalIndexParametersModel: DipssPlusMedicalIndexParametersModel;
  patientId: Scalars['String']['input'];
};


export type MutationsaveEltsMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  eltsMedicalIndexParametersModel: EltsMedicalIndexParametersModel;
  patientId: Scalars['String']['input'];
};


export type MutationsaveGsdHsrBirthAddressArgs = {
  cityName?: InputMaybe<Scalars['String']['input']>;
  isoCountryCode: Scalars['String']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsaveGsdHsrResidenceAddressArgs = {
  addressLine: Scalars['String']['input'];
  cityName?: InputMaybe<Scalars['String']['input']>;
  isoCountryCode: Scalars['String']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsaveIpssrMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  ipssrMedicalIndexParametersModel: IpssrMedicalIndexParametersModel;
  patientId: Scalars['String']['input'];
};


export type MutationsaveMedicalReportTemplateArgs = {
  isDefault: Scalars['Boolean']['input'];
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationsaveMipss70MedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  mipss70MedicalIndexParametersModel: Mipss70MedicalIndexParametersModel;
  patientId: Scalars['String']['input'];
};


export type MutationsaveMpn10MedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  mpn10MedicalIndexParametersModel: Mpn10MedicalIndexParametersModel;
  patientId: Scalars['String']['input'];
};


export type MutationsaveMysecPmMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  mysecPmMedicalIndexParametersModel: MysecPmMedicalIndexParametersModel;
  patientId: Scalars['String']['input'];
};


export type MutationsavePtnmMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  patientId: Scalars['String']['input'];
  ptnmMedicalIndexParametersModel: PtnmMedicalIndexParametersModel;
};


export type MutationsaveSokalMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  patientId: Scalars['String']['input'];
  sokalMedicalIndexParametersModel: SokalMedicalIndexParametersModel;
};


export type MutationsaveTnmAsco2021AppendixMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  patientId: Scalars['String']['input'];
  tnmAsco2021AppendixMedicalIndexParametersModel: TnmAsco2021AppendixMedicalIndexParametersModel;
};


export type MutationsaveTnmAsco2021ColonAndRectumMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  patientId: Scalars['String']['input'];
  tnmAsco2021ColonAndRectumMedicalIndexParametersModel: TnmAsco2021ColonAndRectumMedicalIndexParametersModel;
};


export type MutationsaveTnmAsco2021DuodenumAndVaterAmpullaMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  patientId: Scalars['String']['input'];
  tnmAsco2021DuodenumAndVaterAmpullaMedicalIndexParametersModel: TnmAsco2021DuodenumAndVaterAmpullaMedicalIndexParametersModel;
};


export type MutationsaveTnmAsco2021JejunumAndIleumMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  patientId: Scalars['String']['input'];
  tnmAsco2021JejunumAndIleumMedicalIndexParametersModel: TnmAsco2021JejunumAndIleumMedicalIndexParametersModel;
};


export type MutationsaveTnmAsco2021PancreasMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  patientId: Scalars['String']['input'];
  tnmAsco2021PancreasMedicalIndexParametersModel: TnmAsco2021PancreasMedicalIndexParametersModel;
};


export type MutationsaveTnmAsco2021StomachMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  patientId: Scalars['String']['input'];
  tnmAsco2021StomachMedicalIndexParametersModel: TnmAsco2021StomachMedicalIndexParametersModel;
};


export type MutationsaveTnmMedicalIndexCalculationArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  patientId: Scalars['String']['input'];
  tnmMedicalIndexParametersModel: TnmMedicalIndexParametersModel;
};


export type MutationsendDirectMessageArgs = {
  chatId: Scalars['String']['input'];
  doctorClaim?: InputMaybe<ChatItemDoctorOriginatorClaim>;
  patientClaim?: InputMaybe<ChatItemPatientOriginatorClaim>;
  patientFileAttachmentsIds?: Array<Scalars['String']['input']>;
  patientFilesFromPdfDataCreationRequest?: InputMaybe<PatientFilesFromPdfDataCreationRequestArgs>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsendDraftDirectMessageArgs = {
  attachmentFileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  clinicId?: InputMaybe<Scalars['String']['input']>;
  doctorPortalUserId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsendDraftDirectMessageConfirmationEmailArgs = {
  draftDirectMessageId: Scalars['String']['input'];
};


export type MutationsendGsdSsoUserVerificationEmailArgs = {
  gsdSsoRequestId: Scalars['String']['input'];
  gsdSsoUserId: Scalars['String']['input'];
};


export type MutationsendInviteArgs = {
  invite: CreateInviteArgs;
};


export type MutationsendPatientPrivacyConsentRequestArgs = {
  patientId: Scalars['String']['input'];
};


export type MutationsendUserVerificationEmailArgs = {
  userVerificationId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsetChatIsPinnedToHomeArgs = {
  chatId: Scalars['String']['input'];
  isPinned: Scalars['Boolean']['input'];
};


export type MutationsetChatReadTillItemIncludedArgs = {
  chatId: Scalars['String']['input'];
  chatItemId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsetChatUnreadFromItemIncludedArgs = {
  chatId: Scalars['String']['input'];
  chatItemId: Scalars['String']['input'];
};


export type MutationsetDoctorMedicalRoleArgs = {
  medicalRoleId: Scalars['String']['input'];
};


export type MutationsetDoctorRegionalCodeArgs = {
  regionalCode: Scalars['String']['input'];
};


export type MutationsetInfoThreadPendingStatusArgs = {
  infoThread: Scalars['String']['input'];
  isPending: Scalars['Boolean']['input'];
};


export type MutationsetMyInfoRequestAcceptanceArgs = {
  acceptInfoRequest: Scalars['Boolean']['input'];
};


export type MutationsetSpecificPatientWritePolicyForClinicArgs = {
  clinicId: Scalars['String']['input'];
  patientPortalUserId: Scalars['String']['input'];
  policy: AllowDenyPolicyType;
};


export type MutationsetSpecificPatientWritePolicyForDoctorArgs = {
  patientPortalUserId: Scalars['String']['input'];
  policy: AllowDenyPolicyType;
};


export type MutationsetSurveyVisibilityOnDoctorDashboardArgs = {
  isVisible: Scalars['Boolean']['input'];
  patientPortalUserId: Scalars['String']['input'];
  surveyId: Scalars['String']['input'];
};


export type MutationsetSurveyVisibilityOnPatientDashboardArgs = {
  isVisible: Scalars['Boolean']['input'];
  surveyId: Scalars['String']['input'];
};


export type MutationsetUserTimezoneArgs = {
  timezone: Scalars['String']['input'];
};


export type MutationsignInArgs = {
  emailOrPhoneNumber: Scalars['String']['input'];
  institutionId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  signInType?: InputMaybe<SignInType>;
};


export type MutationsignInForPatientCreatedByDoctorArgs = {
  acceptedPrivacies: Array<PrivacyAcceptance>;
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  residenceAddressExternalGooglePlaceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationsignInViaRegistrationArgs = {
  acceptedPrivacies: Array<PrivacyAcceptance>;
  emailOrPhoneNumber: Scalars['String']['input'];
  institutionId: Scalars['String']['input'];
  inviteId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  profileType: ProfileType;
};


export type MutationsignInWithGsdSsoArgs = {
  gsdSsoRequestId: Scalars['String']['input'];
  gsdSsoUserId: Scalars['String']['input'];
};


export type MutationsignInWithGsdSsoByCustomEmailArgs = {
  email: Scalars['String']['input'];
  gsdSsoRequestId: Scalars['String']['input'];
  gsdSsoUserId: Scalars['String']['input'];
};


export type MutationsignPatientFileArgs = {
  patientFileId: Scalars['String']['input'];
};


export type MutationsubmitAssistedDoctorSearchFormArgs = {
  appliedSearchCriteria: SearchCriteriaArgs;
  desiredSearchCriteria: SearchCriteriaArgs;
  doctorPortalUserId: Scalars['String']['input'];
  patientFileAttachmentsIds?: Array<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsubmitUserVerificationArgs = {
  userVerificationCode: Scalars['String']['input'];
  userVerificationId: Scalars['String']['input'];
};


export type MutationsubmitVerifyEmailArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  verificationCode: Scalars['String']['input'];
};


export type MutationsubmitVerifyPhoneNumberArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  verificationCode: Scalars['String']['input'];
};


export type MutationtoggleReminderVisibilityArgs = {
  isVisible: Scalars['Boolean']['input'];
  reminderId: Scalars['String']['input'];
};


export type MutationupdateDoctorBioArgs = {
  bio?: InputMaybe<Scalars['String']['input']>;
};


export type MutationupdateDoctorCanCreateRequestProposalFlagArgs = {
  doctorCanCreateRequestProposal: Scalars['Boolean']['input'];
  serviceId: Scalars['String']['input'];
};


export type MutationupdateDoctorProfileArgs = {
  expiringDate?: InputMaybe<Scalars['DateTime']['input']>;
  titleCode?: InputMaybe<UserTitle>;
};


export type MutationupdateDoctorSphereOfInterestArgs = {
  sphereOfInterest: Array<Scalars['String']['input']>;
};


export type MutationupdateDoctorsSecretaryAssociationArgs = {
  associationId: Scalars['String']['input'];
  isManagingDoctorsInfoRequests: Scalars['Boolean']['input'];
  isManagingPatientsMedicalData: Scalars['Boolean']['input'];
};


export type MutationupdateEquipeArgs = {
  equipe: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationupdateFileArgs = {
  dateOfExecution?: InputMaybe<Scalars['DateTime']['input']>;
  file: Scalars['String']['input'];
  fileTag?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  patient: Scalars['String']['input'];
};


export type MutationupdateFileAttributesArgs = {
  dateOfExecution?: InputMaybe<Scalars['DateTime']['input']>;
  fileId: Scalars['String']['input'];
  fileTag?: InputMaybe<FileTag>;
  label?: InputMaybe<Scalars['String']['input']>;
};


export type MutationupdateInfoMessageReadStatusArgs = {
  infoMessageId: Scalars['String']['input'];
  status?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationupdateInfoThreadStatusArgs = {
  infoThread: Scalars['String']['input'];
  status: InfoThreadStatus;
};


export type MutationupdateIntramedMessageImportantFlagArgs = {
  intramedMessageId: Scalars['String']['input'];
  isImportant: Scalars['Boolean']['input'];
};


export type MutationupdateIntramedMessageReadFlagArgs = {
  intramedMessageId: Scalars['String']['input'];
  isRead: Scalars['Boolean']['input'];
};


export type MutationupdateIntramedThreadPinnedStatusArgs = {
  intramedThreadId: Scalars['String']['input'];
  isPinned: Scalars['Boolean']['input'];
};


export type MutationupdateMyPrivacyAcceptanceArgs = {
  isAccepted: Scalars['Boolean']['input'];
  privacyType: PrivacyType;
  userPrivacyId: Scalars['String']['input'];
};


export type MutationupdateNonDoctorProfileArgs = {
  titleCode?: InputMaybe<UserTitle>;
};


export type MutationupdatePatientBookingRequiresRequestProposalFlagArgs = {
  patientBookingRequiresRequestProposal: Scalars['Boolean']['input'];
  serviceId: Scalars['String']['input'];
};


export type MutationupdatePatientFileReportPrescriptionArgs = {
  addition?: InputMaybe<PDFDataAdditionArgs>;
  file: Scalars['String']['input'];
  isInvalidated?: InputMaybe<Scalars['Boolean']['input']>;
  otpCode: Scalars['String']['input'];
  patient?: InputMaybe<Scalars['String']['input']>;
  subjects?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationupdatePatientIdentityDocumentArgs = {
  documentExpiryDate: Scalars['DateTime']['input'];
  documentNumber: Scalars['String']['input'];
  documentType: IdentityDocumentType;
};


export type MutationupdatePatientMedicalDataArgs = {
  currentMedication?: InputMaybe<StringArgs>;
  familyHistory?: InputMaybe<StringArgs>;
  patientId: Scalars['String']['input'];
  psychologicalHistory?: InputMaybe<PsychologicalHistoryArgs>;
  vitalParameter?: InputMaybe<VitalParameterArgs>;
};


export type MutationupdatePatientPersonalInfoArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationupdatePatientProfileArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationupdatePrivacyAcceptanceArgs = {
  acceptedPrivacies: Array<PrivacyAcceptance>;
};


export type MutationupdateReminderArgs = {
  dateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateStart: Scalars['DateTime']['input'];
  description: Scalars['String']['input'];
  emailNotificationTime: Scalars['String']['input'];
  isFromOneToOneChat?: InputMaybe<Scalars['Boolean']['input']>;
  recurrenceType: ReminderRecurrenceType;
  reminderId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationupdateServiceArgs = {
  addons: Array<EditAddonArgs>;
  allowsOnDemandSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  description: Scalars['String']['input'];
  durationMinutes?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  isEnabled: Scalars['Boolean']['input'];
  price: Scalars['Float']['input'];
  providedWithinDays?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
};


export type MutationupdateServiceAvailabilityArgs = {
  isEnabled: Scalars['Boolean']['input'];
  serviceId: Scalars['String']['input'];
};


export type MutationupdateUserFiscalCodeArgs = {
  fiscalCode: Scalars['String']['input'];
};


export type MutationupdateUserInfoDataArgs = {
  emailNotificationPeriod?: InputMaybe<EmailNotificationPeriod>;
};


export type MutationupdateUserPrivacyConsentArgs = {
  isConsentReceived: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
};


export type MutationuploadDicomFileArgs = {
  dicomDiskId: Scalars['String']['input'];
  dicomFile: Scalars['Upload']['input'];
  dicomFilePath: Scalars['String']['input'];
};


export type MutationuploadFileArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  file: Scalars['Upload']['input'];
  fileTag?: InputMaybe<FileTag>;
  fileType?: InputMaybe<FileType>;
  institutionUserId?: InputMaybe<Scalars['String']['input']>;
  isCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  patientPortalUserId?: InputMaybe<Scalars['String']['input']>;
  profileType?: InputMaybe<ProfileType>;
  textToAnalyze?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationuploadPatientUnclassifiedFileArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  file: Scalars['Upload']['input'];
  label: Scalars['String']['input'];
  textToAnalyze?: InputMaybe<Scalars['String']['input']>;
};


export type MutationverifyEmailToSignInWithGsdSsoArgs = {
  gsdSsoRequestId: Scalars['String']['input'];
  gsdSsoUserId: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type MysecPmMedicalIndexParametersModel = {
  ageInYears: Scalars['Int']['input'];
  calrUnmutatedGenotype: Scalars['Boolean']['input'];
  circulatingBlastsIsMoreThanOrEqualTo3: Scalars['Boolean']['input'];
  constitutionalSymptoms: Scalars['Boolean']['input'];
  hemoglobinIsLessThan11: Scalars['Boolean']['input'];
  plateletsIsLessThan150: Scalars['Boolean']['input'];
};

export type NetworkInstitution = {
  __typename?: 'NetworkInstitution';
  institution: InstitutionModel;
  isMember: Scalars['Boolean']['output'];
  membersCount: Scalars['Float']['output'];
};

export type NonCompetitiveSportCertificatePrefillableFormDataModel = {
  __typename?: 'NonCompetitiveSportCertificatePrefillableFormDataModel';
  ecgDate: Scalars['DateTime']['output'];
  leftEyeVisualAcuity: Scalars['String']['output'];
  rightEyeVisualAcuity: Scalars['String']['output'];
  sport: Scalars['String']['output'];
};

export type NonDoctor = {
  __typename?: 'NonDoctor';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  professionalRole: Array<NonDoctorProfessionalRole>;
  /** @deprecated Use getFileUrl or mngGetFileUrl queries instead */
  profileImageFile?: Maybe<File>;
  /** @deprecated Use getFileUrl or mngGetFileUrl queries instead */
  thumbnailImageFile?: Maybe<File>;
  titleCode: UserTitle;
  updatedAt: Scalars['DateTime']['output'];
};

export type NonDoctorModel = {
  __typename?: 'NonDoctorModel';
  createdAt: Scalars['DateTime']['output'];
  doctorsSecretaryAssociations: Array<AssociatedUserModel>;
  id: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  portalUser: PortalUserModel;
  privacies: Array<UserPrivacyModel>;
  professionalRole: Array<NonDoctorProfessionalRole>;
  titleCode: UserTitle;
  updatedAt: Scalars['DateTime']['output'];
};

export type NonDoctorPortalUserModel = {
  __typename?: 'NonDoctorPortalUserModel';
  id: Scalars['String']['output'];
  institution: InstitutionModel;
  nonDoctor: NonDoctorModel;
  portal: PortalModel;
  user: UserModel;
};

export type NonDoctorProfessionalRole = {
  __typename?: 'NonDoctorProfessionalRole';
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
  year?: Maybe<Scalars['Float']['output']>;
};

export type NoneAdminPreauthorizedChallengeModel = {
  __typename?: 'NoneAdminPreauthorizedChallengeModel';
  /** target user has this amount of minutes to fulfill the challenge and verify/execute the request */
  expiresInMinutes: Scalars['Int']['output'];
};

export type NotificationAttachment = {
  __typename?: 'NotificationAttachment';
  createdAt: Scalars['DateTime']['output'];
  file: File;
  id: Scalars['String']['output'];
};

export type NotificationFlag = {
  __typename?: 'NotificationFlag';
  id: Scalars['String']['output'];
  institutionUser: InstitutionUser;
  isRead: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type NotificationSender = {
  __typename?: 'NotificationSender';
  createdAt: Scalars['DateTime']['output'];
  equipe?: Maybe<Equipe>;
  id: Scalars['String']['output'];
  institutionUser?: Maybe<InstitutionUser>;
};

export enum NotificationType {
  CONSULTATION = 'CONSULTATION',
  INFO = 'INFO',
  INTRAMED = 'INTRAMED',
  VIDEO_VISIT = 'VIDEO_VISIT'
}

export type OTPChangeResult = {
  __typename?: 'OTPChangeResult';
  changedAt: Scalars['DateTime']['output'];
  isActive: Scalars['Boolean']['output'];
};

/** A private Chat involving exactly *two* individuals */
export type OneToOneChatModel = AbstractChatModel & {
  __typename?: 'OneToOneChatModel';
  featuredItem?: Maybe<ChatItemModel>;
  featuredRequest?: Maybe<Request>;
  firstUnansweredPatientChatItem?: Maybe<ChatItemModel>;
  hasDicomAttachments: Scalars['Boolean']['output'];
  hasNonDicomAttachments: Scalars['Boolean']['output'];
  /** NB: for a given chat context, across multiple calls, it may be populated with different aliases (which are equivalent when used as input for other queries)For this reason, do not expect/validate any particular format/length. */
  id: Scalars['String']['output'];
  /** The person the invoker is taking to in the Chat */
  interlocutor: PortalUserModel;
  isPinnedToHome: Scalars['Boolean']['output'];
  items: Array<ChatItemModel>;
  lastItemTimestamp?: Maybe<Scalars['DateTime']['output']>;
  numberOfChatItems: Scalars['Float']['output'];
  /** The policy that will be applied in case the Patient participant should try to post a Direct Message to the Chat. */
  patientWritePolicy: AllowDenyPolicyType;
  /** (Based on the invoker user) the most recent *read* item in the timeline. */
  readTillItemIncluded?: Maybe<ChatItemModel>;
  unreadItemsCount: Scalars['Int']['output'];
};

export enum OptionalAvailability {
  ABSENT = 'ABSENT',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PRESENT = 'PRESENT'
}

export enum OptionalYesNo {
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
  YES = 'YES'
}

export type Order = {
  __typename?: 'Order';
  code?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  id: Scalars['String']['output'];
  payments: Array<Payment>;
  request: Request;
  state: Scalars['String']['output'];
  totalTaxPrice: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderByArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OrderByKeyword>;
};

export enum OrderByKeyword {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum OrderByType {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type OrderIntegrationStatusModel = {
  __typename?: 'OrderIntegrationStatusModel';
  invoiceIntegrationStatus: ExternalIntegrationStatus;
  refundIntegrationStatus: ExternalIntegrationStatus;
};

export type OrderModel = {
  __typename?: 'OrderModel';
  code?: Maybe<Scalars['String']['output']>;
  currencyCode: CurrencyCode;
  id: Scalars['String']['output'];
  totalTaxPrice: Scalars['Float']['output'];
};

export enum OrderState {
  CAPTURED = 'CAPTURED',
  CREATED = 'CREATED'
}

export type OtherAnswerOptionsModel = {
  __typename?: 'OtherAnswerOptionsModel';
  isExclusive: Scalars['Boolean']['output'];
  isMultiple: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type PDFData = {
  __typename?: 'PDFData';
  additions?: Maybe<Array<PDFDataAddition>>;
  multidisciplinaryTeam?: Maybe<Array<MultidisciplinaryTeamMember>>;
  subjects: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type PDFDataAddition = {
  __typename?: 'PDFDataAddition';
  addedAt: Scalars['String']['output'];
  subjects: Array<Scalars['String']['output']>;
  type: PDFDataAdditionType;
};

export type PDFDataAdditionArgs = {
  addedAt: Scalars['String']['input'];
  subjects: Array<Scalars['String']['input']>;
  type: PDFDataAdditionType;
};

export enum PDFDataAdditionType {
  REPORT_ADDENDUM = 'REPORT_ADDENDUM'
}

export type PDFDataStorage = {
  __typename?: 'PDFDataStorage';
  createdAt: Scalars['DateTime']['output'];
  data: PDFData;
  fileType: PDFDataStorageType;
  id: Scalars['String']['output'];
  isInvalidated: Scalars['Boolean']['output'];
  patientSignatureDate?: Maybe<Scalars['DateTime']['output']>;
  requiresPatientSignature: Scalars['Boolean']['output'];
};

export enum PDFDataStorageType {
  DICOM_REPORT = 'DICOM_REPORT',
  GSD_HSR_INFORMED_CONSENT = 'GSD_HSR_INFORMED_CONSENT',
  MULTIDISCIPLINARY_REPORT = 'MULTIDISCIPLINARY_REPORT',
  PRESCRIPTION = 'PRESCRIPTION',
  REPORT = 'REPORT',
  SPORT_CERTIFICATE_COMPETITIVE = 'SPORT_CERTIFICATE_COMPETITIVE',
  SPORT_CERTIFICATE_NON_COMPETITIVE = 'SPORT_CERTIFICATE_NON_COMPETITIVE'
}

export enum PDFDataStorageTypeAllowedFromChat {
  PRESCRIPTION = 'PRESCRIPTION',
  REPORT = 'REPORT'
}

export enum PDFDataStorageTypeAllowedFromIntramed {
  MULTIDISCIPLINARY_REPORT = 'MULTIDISCIPLINARY_REPORT',
  REPORT = 'REPORT'
}

export type PaginatedAdminUserModelResponse = {
  __typename?: 'PaginatedAdminUserModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<AdminUserModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedChatModelResponse = {
  __typename?: 'PaginatedChatModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<ChatModelUnion>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedClinicChatModelResponse = {
  __typename?: 'PaginatedClinicChatModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<ClinicChatModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedClinicModelResponse = {
  __typename?: 'PaginatedClinicModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<ClinicModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedClosedRequestListModelResponse = {
  __typename?: 'PaginatedClosedRequestListModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<ClosedRequestModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalAmount: Scalars['Float']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedDoctorAffiliationModelResponse = {
  __typename?: 'PaginatedDoctorAffiliationModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<AffiliationForDoctorModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedDoctorClinicModelResponse = {
  __typename?: 'PaginatedDoctorClinicModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<DoctorClinicModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedDoctorModelResponse = {
  __typename?: 'PaginatedDoctorModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<DoctorModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedDoctorSurveyListEntryModelResponse = {
  __typename?: 'PaginatedDoctorSurveyListEntryModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<DoctorSurveyListEntryModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedInfoThreadResponse = {
  __typename?: 'PaginatedInfoThreadResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<InfoThread>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedInviteModelResponse = {
  __typename?: 'PaginatedInviteModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<InviteModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedMedicalReportTemplateResponse = {
  __typename?: 'PaginatedMedicalReportTemplateResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<MedicalReportTemplateModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedMngAccountingEntryModelResponse = {
  __typename?: 'PaginatedMngAccountingEntryModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<MngAccountingEntryModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedMngAffiliationModelResponse = {
  __typename?: 'PaginatedMngAffiliationModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<MngAffiliationModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedMngClinicModelResponse = {
  __typename?: 'PaginatedMngClinicModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<MngClinicModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedMngInstitutionModelResponse = {
  __typename?: 'PaginatedMngInstitutionModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<MngInstitutionModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedMngInviteModelResponse = {
  __typename?: 'PaginatedMngInviteModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<MngInviteModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedMngRequestModelResponse = {
  __typename?: 'PaginatedMngRequestModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<MngRequestModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedOneToOneChatModelResponse = {
  __typename?: 'PaginatedOneToOneChatModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<OneToOneChatModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedPatientAffiliationModelResponse = {
  __typename?: 'PaginatedPatientAffiliationModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<AffiliationForPatientModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedPatientFileModelResponse = {
  __typename?: 'PaginatedPatientFileModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<PatientFileModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedPatientModelResponse = {
  __typename?: 'PaginatedPatientModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<PatientModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedPatientSurveyListEntryModelResponse = {
  __typename?: 'PaginatedPatientSurveyListEntryModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<PatientSurveyListEntryModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedPortalDocumentResponse = {
  __typename?: 'PaginatedPortalDocumentResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<PortalDocumentModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedReminderModelResponse = {
  __typename?: 'PaginatedReminderModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<ReminderModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedRequestResponse = {
  __typename?: 'PaginatedRequestResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<Request>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSpecializationsResponse = {
  __typename?: 'PaginatedSpecializationsResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<Specialization>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSphereOfInterestResponse = {
  __typename?: 'PaginatedSphereOfInterestResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<Scalars['String']['output']>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedUserClaimModelResponse = {
  __typename?: 'PaginatedUserClaimModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<MngUserClaimModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedUserModelResponse = {
  __typename?: 'PaginatedUserModelResponse';
  appliedPaginationRequest: PaginationRequestModel;
  items: Array<UserModel>;
  nextPaginationRequest?: Maybe<PaginationRequestModel>;
  totalCount: Scalars['Int']['output'];
};

export type PaginationArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
};

export type PaginationModel = {
  /** Min: 1, Max: 50, Default: 20 */
  numberOfRecords: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type PaginationRequestModel = {
  __typename?: 'PaginationRequestModel';
  /** Min: 1, Max: 50, Default: 20 */
  numberOfRecords: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
};

export type PathologicalAnamnesisNetGastricUlcersData = {
  __typename?: 'PathologicalAnamnesisNetGastricUlcersData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetGastricUlcersDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetGastricUlcersHistory = {
  __typename?: 'PathologicalAnamnesisNetGastricUlcersHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologicalAnamnesisNetGastricUlcersData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologicalAnamnesisNetGlucidicMetabolismAlterationData = {
  __typename?: 'PathologicalAnamnesisNetGlucidicMetabolismAlterationData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetGlucidicMetabolismAlterationDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetGlucidicMetabolismAlterationHistory = {
  __typename?: 'PathologicalAnamnesisNetGlucidicMetabolismAlterationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologicalAnamnesisNetGlucidicMetabolismAlterationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologicalAnamnesisNetHypercalcemiaData = {
  __typename?: 'PathologicalAnamnesisNetHypercalcemiaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetHypercalcemiaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetHypercalcemiaHistory = {
  __typename?: 'PathologicalAnamnesisNetHypercalcemiaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologicalAnamnesisNetHypercalcemiaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologicalAnamnesisNetHypophysisAdenomaData = {
  __typename?: 'PathologicalAnamnesisNetHypophysisAdenomaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetHypophysisAdenomaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetHypophysisAdenomaHistory = {
  __typename?: 'PathologicalAnamnesisNetHypophysisAdenomaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologicalAnamnesisNetHypophysisAdenomaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologicalAnamnesisNetMultipleEndocrineNeoplasiaData = {
  __typename?: 'PathologicalAnamnesisNetMultipleEndocrineNeoplasiaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetMultipleEndocrineNeoplasiaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetMultipleEndocrineNeoplasiaHistory = {
  __typename?: 'PathologicalAnamnesisNetMultipleEndocrineNeoplasiaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologicalAnamnesisNetMultipleEndocrineNeoplasiaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologicalAnamnesisNetNephrolithiasisData = {
  __typename?: 'PathologicalAnamnesisNetNephrolithiasisData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetNephrolithiasisDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetNephrolithiasisHistory = {
  __typename?: 'PathologicalAnamnesisNetNephrolithiasisHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologicalAnamnesisNetNephrolithiasisData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologicalAnamnesisNetNeuroendocrineTumorsData = {
  __typename?: 'PathologicalAnamnesisNetNeuroendocrineTumorsData';
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetNeuroendocrineTumorsDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetNeuroendocrineTumorsHistory = {
  __typename?: 'PathologicalAnamnesisNetNeuroendocrineTumorsHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologicalAnamnesisNetNeuroendocrineTumorsData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologicalAnamnesisNetOtherNeoplasiaData = {
  __typename?: 'PathologicalAnamnesisNetOtherNeoplasiaData';
  diagnoses?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetOtherNeoplasiaDataArgs = {
  diagnoses?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  value: PositiveNegative;
};

export type PathologicalAnamnesisNetOtherNeoplasiaHistory = {
  __typename?: 'PathologicalAnamnesisNetOtherNeoplasiaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologicalAnamnesisNetOtherNeoplasiaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export enum Pathology {
  ABDOMINAL_AORTIC_ANEURYSM = 'ABDOMINAL_AORTIC_ANEURYSM',
  ABDOMINAL_AORTIC_ANEURYSM_VARIANT_2 = 'ABDOMINAL_AORTIC_ANEURYSM_VARIANT_2',
  ABDOMINAL_HYSTERECTOMY = 'ABDOMINAL_HYSTERECTOMY',
  ABDUCTOR_MUSCLE_SYNDROME = 'ABDUCTOR_MUSCLE_SYNDROME',
  ABNORMALITY_OF_THE_POSITION_OF_THE_CERVIX_CERVIX = 'ABNORMALITY_OF_THE_POSITION_OF_THE_CERVIX_CERVIX',
  ABNORMAL_SPERMIOGRAM = 'ABNORMAL_SPERMIOGRAM',
  ABRASION = 'ABRASION',
  ACCESSORY_THYROID = 'ACCESSORY_THYROID',
  ACCUMULATION_DISORDER = 'ACCUMULATION_DISORDER',
  ACETIC_CELL_CARCINOMA_OF_THE_PANCREAS = 'ACETIC_CELL_CARCINOMA_OF_THE_PANCREAS',
  ACHALASIA = 'ACHALASIA',
  ACHILLES_TENDINOPATHY = 'ACHILLES_TENDINOPATHY',
  ACHILLES_TENDINOSIS = 'ACHILLES_TENDINOSIS',
  ACINAR_CELL_CARCINOMA_OF_THE_PANCREAS = 'ACINAR_CELL_CARCINOMA_OF_THE_PANCREAS',
  ACNE = 'ACNE',
  ACNE_VULGARIS = 'ACNE_VULGARIS',
  ACOUSTIC_NEURINOMA = 'ACOUSTIC_NEURINOMA',
  ACQUIRED_MELANOCYTIC_NEVUS = 'ACQUIRED_MELANOCYTIC_NEVUS',
  ACROMEGALY = 'ACROMEGALY',
  ACUTE_APPENDICITIS = 'ACUTE_APPENDICITIS',
  ACUTE_ATAXIA = 'ACUTE_ATAXIA',
  ACUTE_CEREBELLAR_HEMORRHAGE = 'ACUTE_CEREBELLAR_HEMORRHAGE',
  ACUTE_CHOLECYSTITIS = 'ACUTE_CHOLECYSTITIS',
  ACUTE_EPIDURAL_HEMATOMA = 'ACUTE_EPIDURAL_HEMATOMA',
  ACUTE_LYMPHOBLASTIC_LEUKEMIA = 'ACUTE_LYMPHOBLASTIC_LEUKEMIA',
  ACUTE_LYMPHOBLASTIC_LEUKEMIA_ALL = 'ACUTE_LYMPHOBLASTIC_LEUKEMIA_ALL',
  ACUTE_MEGAKARYOBLASTIC_LEUKEMIA = 'ACUTE_MEGAKARYOBLASTIC_LEUKEMIA',
  ACUTE_MYELOBLASTIC_LEUKEMIA_WITH_T_16_16 = 'ACUTE_MYELOBLASTIC_LEUKEMIA_WITH_T_16_16',
  ACUTE_MYELOID_LEUKEMIA = 'ACUTE_MYELOID_LEUKEMIA',
  ACUTE_MYELOID_LEUKEMIA_AML = 'ACUTE_MYELOID_LEUKEMIA_AML',
  ACUTE_MYELOID_LEUKEMIA_WITH_T_8_21 = 'ACUTE_MYELOID_LEUKEMIA_WITH_T_8_21',
  ACUTE_MYELOID_LEUKEMIA_WITH_T_15_17 = 'ACUTE_MYELOID_LEUKEMIA_WITH_T_15_17',
  ACUTE_MYELOMONOCYTIC_LEUKEMIA = 'ACUTE_MYELOMONOCYTIC_LEUKEMIA',
  ACUTE_MYELOMONOCYTIC_LEUKEMIA_WITH_INV_16 = 'ACUTE_MYELOMONOCYTIC_LEUKEMIA_WITH_INV_16',
  ACUTE_PANCREATITIS = 'ACUTE_PANCREATITIS',
  ACUTE_PROMYELOCYTIC_LEUKEMIA_APML = 'ACUTE_PROMYELOCYTIC_LEUKEMIA_APML',
  ACUTE_RENAL_FAILURE = 'ACUTE_RENAL_FAILURE',
  ACUTE_RESPIRATORY_DISTRESS_SYNDROME_ARDS = 'ACUTE_RESPIRATORY_DISTRESS_SYNDROME_ARDS',
  ACUTE_STRESS_DISORDER = 'ACUTE_STRESS_DISORDER',
  ACUTE_SUBDURAL_HEMORRHAGE = 'ACUTE_SUBDURAL_HEMORRHAGE',
  ACUTE_WOUNDS = 'ACUTE_WOUNDS',
  ADAPTATION_DISORDER = 'ADAPTATION_DISORDER',
  ADDISON_S_DISEASE = 'ADDISON_S_DISEASE',
  ADDISON_S_DISEASE_ADRENAL_INSUFFICIENCY = 'ADDISON_S_DISEASE_ADRENAL_INSUFFICIENCY',
  ADDISON_S_SYNDROME = 'ADDISON_S_SYNDROME',
  ADDUCTOR_MUSCLE_SYNDROME = 'ADDUCTOR_MUSCLE_SYNDROME',
  ADENOCARCINOMA_CARCINOMA_OF_THE_COLON = 'ADENOCARCINOMA_CARCINOMA_OF_THE_COLON',
  ADENOCARCINOMA_CARCINOMA_OF_THE_ESOPHAGUS = 'ADENOCARCINOMA_CARCINOMA_OF_THE_ESOPHAGUS',
  ADENOCARCINOMA_CARCINOMA_OF_THE_RECTUM = 'ADENOCARCINOMA_CARCINOMA_OF_THE_RECTUM',
  ADENOCARCINOMA_CARCINOMA_OF_THE_STOMACH = 'ADENOCARCINOMA_CARCINOMA_OF_THE_STOMACH',
  ADENOCARCINOMA_OF_THE_LUNG = 'ADENOCARCINOMA_OF_THE_LUNG',
  ADENOID_CELL_CARCINOMA_OF_THE_LUNG = 'ADENOID_CELL_CARCINOMA_OF_THE_LUNG',
  ADENOID_CELL_CARCINOMA_OF_THE_PANCREAS = 'ADENOID_CELL_CARCINOMA_OF_THE_PANCREAS',
  ADENOMA = 'ADENOMA',
  ADENOMATOUS_POLYP = 'ADENOMATOUS_POLYP',
  ADIPOSE_CELL_CARCINOMA_OF_THE_PANCREAS = 'ADIPOSE_CELL_CARCINOMA_OF_THE_PANCREAS',
  ADRENAL_GLANDS = 'ADRENAL_GLANDS',
  AFRICAN_TRYPANOSOMIASIS_SLEEPING_SICKNESS = 'AFRICAN_TRYPANOSOMIASIS_SLEEPING_SICKNESS',
  AFTER_EFFECTS_OF_SURGERY_SUCH_AS_ORTHOPEDIC_OR_CARDIOVASCULAR_SURGERY = 'AFTER_EFFECTS_OF_SURGERY_SUCH_AS_ORTHOPEDIC_OR_CARDIOVASCULAR_SURGERY',
  AGE_RELATED_MACULAR_DEGENERATION = 'AGE_RELATED_MACULAR_DEGENERATION',
  AGE_RELATED_MOBILITY_AND_WALKING_DISORDERS = 'AGE_RELATED_MOBILITY_AND_WALKING_DISORDERS',
  AGE_RELATED_MUSCULOSKELETAL_PROBLEMS_SUCH_AS_OSTEOARTHRITIS = 'AGE_RELATED_MUSCULOSKELETAL_PROBLEMS_SUCH_AS_OSTEOARTHRITIS',
  AGE_RELATED_POSTURE_AND_BALANCE_DISORDERS = 'AGE_RELATED_POSTURE_AND_BALANCE_DISORDERS',
  AGGRESSIVENESS_VIOLENCE = 'AGGRESSIVENESS_VIOLENCE',
  AGITATION = 'AGITATION',
  AGNOSIA = 'AGNOSIA',
  AGORAPHOBIA = 'AGORAPHOBIA',
  AICARDI_S_SYNDROME = 'AICARDI_S_SYNDROME',
  AIRWAY_STENOSIS = 'AIRWAY_STENOSIS',
  ALAGILLE_LIKE_SYNDROME = 'ALAGILLE_LIKE_SYNDROME',
  ALAGILLE_SYNDROME = 'ALAGILLE_SYNDROME',
  ALCOHOLIC_HEPATITIS = 'ALCOHOLIC_HEPATITIS',
  ALKHUMRA_HEMORRHAGIC_FEVER = 'ALKHUMRA_HEMORRHAGIC_FEVER',
  ALLERGIC_BRONCHOPULMONARY_ASPERGILLOSIS = 'ALLERGIC_BRONCHOPULMONARY_ASPERGILLOSIS',
  ALLERGIC_CONJUNCTIVITIS = 'ALLERGIC_CONJUNCTIVITIS',
  ALLERGIC_FUNGAL_RHINOSINUSITIS = 'ALLERGIC_FUNGAL_RHINOSINUSITIS',
  ALLERGIC_RHINITIS = 'ALLERGIC_RHINITIS',
  ALLERGIES = 'ALLERGIES',
  ALLERGY = 'ALLERGY',
  ALLERGY_TO_BEE_OR_WASP_VENOM = 'ALLERGY_TO_BEE_OR_WASP_VENOM',
  ALLERGY_TO_CHEMICALS_E_G_DETERGENTS = 'ALLERGY_TO_CHEMICALS_E_G_DETERGENTS',
  ALLERGY_TO_INSECT_BITES = 'ALLERGY_TO_INSECT_BITES',
  ALLERGY_TO_METALS_E_G_CHROMIUM = 'ALLERGY_TO_METALS_E_G_CHROMIUM',
  ALLERGY_TO_PETS_E_G_CATS = 'ALLERGY_TO_PETS_E_G_CATS',
  ALPORT_SYNDROME = 'ALPORT_SYNDROME',
  ALPORT_SYNDROME_WITH_CARDIAC_INVOLVEMENT = 'ALPORT_SYNDROME_WITH_CARDIAC_INVOLVEMENT',
  ALSTR_M_LIKE_SYNDROME = 'ALSTR_M_LIKE_SYNDROME',
  ALSTR_M_S_SYNDROME = 'ALSTR_M_S_SYNDROME',
  ALTERED_VOICE = 'ALTERED_VOICE',
  ALZHEIMER_S_DISEASE = 'ALZHEIMER_S_DISEASE',
  AMAUROTIC_CONGENITAL_LEBER_S_DISEASE = 'AMAUROTIC_CONGENITAL_LEBER_S_DISEASE',
  AMBLYOPIA = 'AMBLYOPIA',
  AMELOBLASTIC_FIBROMA = 'AMELOBLASTIC_FIBROMA',
  AMENORRHEA_ABSENCE_OF_MENSTRUATION = 'AMENORRHEA_ABSENCE_OF_MENSTRUATION',
  AMERICAN_TRYPANOSOMIASIS_CHAGAS_DISEASE = 'AMERICAN_TRYPANOSOMIASIS_CHAGAS_DISEASE',
  AMETROPIA = 'AMETROPIA',
  AMOEBIASIS = 'AMOEBIASIS',
  AMPUTATIONS = 'AMPUTATIONS',
  AMYLOID_ANGIOPATHY = 'AMYLOID_ANGIOPATHY',
  AMYOTROPHIC_LATERAL_SCLEROSIS_ALS = 'AMYOTROPHIC_LATERAL_SCLEROSIS_ALS',
  ANAL_FISTULAS = 'ANAL_FISTULAS',
  ANAL_RAMAD = 'ANAL_RAMAD',
  ANAPHYLACTIC_SHOCK = 'ANAPHYLACTIC_SHOCK',
  ANAPHYLAXIS = 'ANAPHYLAXIS',
  ANASTOMOTIC_STENOSIS = 'ANASTOMOTIC_STENOSIS',
  ANDROGEN_INSENSITIVITY_LIKE_SYNDROME = 'ANDROGEN_INSENSITIVITY_LIKE_SYNDROME',
  ANDROGEN_INSENSITIVITY_SYNDROME_SAI = 'ANDROGEN_INSENSITIVITY_SYNDROME_SAI',
  ANEMIA = 'ANEMIA',
  ANEURYSMAL_BONE_CYST = 'ANEURYSMAL_BONE_CYST',
  ANEURYSM_OF_THE_THORACIC_AORTA = 'ANEURYSM_OF_THE_THORACIC_AORTA',
  ANGELMAN_SYNDROME = 'ANGELMAN_SYNDROME',
  ANGELMAN_SYNDROME_15Q_DELETION = 'ANGELMAN_SYNDROME_15Q_DELETION',
  ANGINA_PECTORIS = 'ANGINA_PECTORIS',
  ANGIODYSPLASIA = 'ANGIODYSPLASIA',
  ANGIOEDEMA = 'ANGIOEDEMA',
  ANGIOMYOLIPOMA = 'ANGIOMYOLIPOMA',
  ANGIOSARCOMA_OF_THE_BREAST = 'ANGIOSARCOMA_OF_THE_BREAST',
  ANISAKIASIS = 'ANISAKIASIS',
  ANISOMETROPIA = 'ANISOMETROPIA',
  ANKLE_FRACTURE = 'ANKLE_FRACTURE',
  ANKYLOSING_SPONDYLITIS = 'ANKYLOSING_SPONDYLITIS',
  ANNULAR_GRANULOMA = 'ANNULAR_GRANULOMA',
  ANORECTAL_MALFORMATIONS = 'ANORECTAL_MALFORMATIONS',
  ANOREXIA_NERVOSA = 'ANOREXIA_NERVOSA',
  ANORGASMIA = 'ANORGASMIA',
  ANTERIOR_CRANIAL_FOSSA_TUMOR = 'ANTERIOR_CRANIAL_FOSSA_TUMOR',
  ANTERIOR_CRUCIATE_LIGAMENT_ACL_INJURY = 'ANTERIOR_CRUCIATE_LIGAMENT_ACL_INJURY',
  ANTERIOR_CUBITAL_MUSCLE_SYNDROME = 'ANTERIOR_CUBITAL_MUSCLE_SYNDROME',
  ANTERIOR_FOSSA_TUMOR = 'ANTERIOR_FOSSA_TUMOR',
  ANTERIOR_MEDIASTINAL_TUMOR = 'ANTERIOR_MEDIASTINAL_TUMOR',
  ANTERIOR_SKULL_BASE_FRACTURE = 'ANTERIOR_SKULL_BASE_FRACTURE',
  ANTERIOR_TIBIAL_MUSCLE_SYNDROME = 'ANTERIOR_TIBIAL_MUSCLE_SYNDROME',
  ANTISOCIAL_PERSONALITY_DISORDER = 'ANTISOCIAL_PERSONALITY_DISORDER',
  ANTISOCIAL_PERSONALITY_DISORDER_VARIANT_2 = 'ANTISOCIAL_PERSONALITY_DISORDER_VARIANT_2',
  ANXIETY = 'ANXIETY',
  ANXIETY_DISORDER = 'ANXIETY_DISORDER',
  ANXIETY_DISORDER_VARIANT_2 = 'ANXIETY_DISORDER_VARIANT_2',
  ANXIETY_INCLUDING_SPECIFIC_ANXIETY_DISORDERS_SUCH_AS_PHOBIAS_GENERALIZED_ANXIETY_DISORDER_PANIC_DISORDER = 'ANXIETY_INCLUDING_SPECIFIC_ANXIETY_DISORDERS_SUCH_AS_PHOBIAS_GENERALIZED_ANXIETY_DISORDER_PANIC_DISORDER',
  AORTIC_ANEURYSM = 'AORTIC_ANEURYSM',
  AORTIC_ANEURYSM_VARIANT_2 = 'AORTIC_ANEURYSM_VARIANT_2',
  AORTIC_COARCTATION = 'AORTIC_COARCTATION',
  AORTIC_COARCTATION_SYNDROME = 'AORTIC_COARCTATION_SYNDROME',
  AORTIC_DISSECTION = 'AORTIC_DISSECTION',
  AORTIC_INSUFFICIENCY = 'AORTIC_INSUFFICIENCY',
  AORTIC_STENOSIS = 'AORTIC_STENOSIS',
  AORTIC_TRAUMA = 'AORTIC_TRAUMA',
  APERT_S_SYNDROME = 'APERT_S_SYNDROME',
  APHASIA = 'APHASIA',
  APHASIA_LOSS_OR_IMPAIRMENT_OF_SPEECH_DUE_TO_BRAIN_INJURY = 'APHASIA_LOSS_OR_IMPAIRMENT_OF_SPEECH_DUE_TO_BRAIN_INJURY',
  APLASTIC_ANEMIA = 'APLASTIC_ANEMIA',
  APLA_SYNDROME_ANTIPHOSPHOLIPID_ANTIBODY_SYNDROME = 'APLA_SYNDROME_ANTIPHOSPHOLIPID_ANTIBODY_SYNDROME',
  APNEA_SLEEP_DISORDER = 'APNEA_SLEEP_DISORDER',
  APPENDICITIS = 'APPENDICITIS',
  APRAXIA = 'APRAXIA',
  APRAXIA_OF_SPEECH_DIFFICULTY_IN_MOTOR_ORGANIZATION_AND_PLANNING_FOR_SPEECH = 'APRAXIA_OF_SPEECH_DIFFICULTY_IN_MOTOR_ORGANIZATION_AND_PLANNING_FOR_SPEECH',
  ARACHNOID_CYST = 'ARACHNOID_CYST',
  ARNOLD_CHIARI_MALFORMATION = 'ARNOLD_CHIARI_MALFORMATION',
  ARNOLD_CHIARI_SYNDROME = 'ARNOLD_CHIARI_SYNDROME',
  ARRHYTHMIAS = 'ARRHYTHMIAS',
  ARROW_CELL_CARCINOMA_OF_THE_PANCREAS = 'ARROW_CELL_CARCINOMA_OF_THE_PANCREAS',
  ARTERIAL_FAILURE = 'ARTERIAL_FAILURE',
  ARTERIAL_HEMORRHAGE = 'ARTERIAL_HEMORRHAGE',
  ARTERIAL_ULCERS = 'ARTERIAL_ULCERS',
  ARTERIOSCLEROSIS = 'ARTERIOSCLEROSIS',
  ARTERIOVENOUS_FISTULA = 'ARTERIOVENOUS_FISTULA',
  ARTERIOVENOUS_MALFORMATIONS = 'ARTERIOVENOUS_MALFORMATIONS',
  ARTERIOVENOUS_MALFORMATIONS_AVMS = 'ARTERIOVENOUS_MALFORMATIONS_AVMS',
  ARTHRITIS = 'ARTHRITIS',
  ARTHRITIS_OSTEOARTHRITIS = 'ARTHRITIS_OSTEOARTHRITIS',
  ARTHROSIS = 'ARTHROSIS',
  ASCARIDIASIS = 'ASCARIDIASIS',
  ASHERMAN_LIKE_SYNDROME = 'ASHERMAN_LIKE_SYNDROME',
  ASHERMAN_S_SYNDROME_SYNONYM_FOR_SYMPHYSIOLISTHESIS = 'ASHERMAN_S_SYNDROME_SYNONYM_FOR_SYMPHYSIOLISTHESIS',
  ASHERSON_S_SYNDROME = 'ASHERSON_S_SYNDROME',
  ASPERGER_S_SYNDROME_PART_OF_THE_AUTISM_SPECTRUM = 'ASPERGER_S_SYNDROME_PART_OF_THE_AUTISM_SPECTRUM',
  ASPERGILLOSIS = 'ASPERGILLOSIS',
  ASSISTED_FERTILITY_IVF_ICSI_ETC = 'ASSISTED_FERTILITY_IVF_ICSI_ETC',
  ASTHMA = 'ASTHMA',
  ASTIGMATISM = 'ASTIGMATISM',
  ASTROCYTOMA = 'ASTROCYTOMA',
  ATAXIAS_SUCH_AS_FRIEDREICH_S_ATAXIA = 'ATAXIAS_SUCH_AS_FRIEDREICH_S_ATAXIA',
  ATAXIA_OF_SCA28 = 'ATAXIA_OF_SCA28',
  ATAXIA_TELANGECTASIA = 'ATAXIA_TELANGECTASIA',
  ATHEROSCLEROSIS = 'ATHEROSCLEROSIS',
  ATHLETE_S_FOOT = 'ATHLETE_S_FOOT',
  ATOPIC_DERMATITIS = 'ATOPIC_DERMATITIS',
  ATRIAL_FIBRILLATION = 'ATRIAL_FIBRILLATION',
  ATRIAL_FLUTTER = 'ATRIAL_FLUTTER',
  ATROPHIC_SCARS = 'ATROPHIC_SCARS',
  ATTENTION_DEFICIT_HYPERACTIVITY_DISORDER_ADHD = 'ATTENTION_DEFICIT_HYPERACTIVITY_DISORDER_ADHD',
  ATYPICAL_FABRY_SYNDROME = 'ATYPICAL_FABRY_SYNDROME',
  ATYPICAL_GLANDULAR_HYPERPLASIA_AGUS = 'ATYPICAL_GLANDULAR_HYPERPLASIA_AGUS',
  ATYPICAL_INTRADUCTAL_PAPILLOMA_ADH = 'ATYPICAL_INTRADUCTAL_PAPILLOMA_ADH',
  AUTISM = 'AUTISM',
  AUTISM_SPECTRUM_DISORDER_ASD = 'AUTISM_SPECTRUM_DISORDER_ASD',
  AUTISM_SPECTRUM_DISORDER_ASD_VARIANT_2 = 'AUTISM_SPECTRUM_DISORDER_ASD_VARIANT_2',
  AUTOIMMUNE_DISEASES = 'AUTOIMMUNE_DISEASES',
  AUTOIMMUNE_DISEASES_LUPUS_RHEUMATOID_ARTHRITIS = 'AUTOIMMUNE_DISEASES_LUPUS_RHEUMATOID_ARTHRITIS',
  AUTOIMMUNE_DISEASES_SUCH_AS_RHEUMATOID_ARTHRITIS_LUPUS_AND_MULTIPLE_SCLEROSIS = 'AUTOIMMUNE_DISEASES_SUCH_AS_RHEUMATOID_ARTHRITIS_LUPUS_AND_MULTIPLE_SCLEROSIS',
  AUTOIMMUNE_HEMOLYTIC_ANEMIA = 'AUTOIMMUNE_HEMOLYTIC_ANEMIA',
  AUTOIMMUNE_HEPATITIS = 'AUTOIMMUNE_HEPATITIS',
  AUTOIMMUNE_LYMPHOPROLIFERATIVE_SYNDROME_ALPS = 'AUTOIMMUNE_LYMPHOPROLIFERATIVE_SYNDROME_ALPS',
  AUTONOMIC_NEUROPATHY = 'AUTONOMIC_NEUROPATHY',
  AVIAN_INFLUENZA_H5N1 = 'AVIAN_INFLUENZA_H5N1',
  AVOIDANT_PERSONALITY_DISORDER = 'AVOIDANT_PERSONALITY_DISORDER',
  AVOIDANT_PERSONALITY_DISORDER_VARIANT_2 = 'AVOIDANT_PERSONALITY_DISORDER_VARIANT_2',
  AVULSION_FRACTURES = 'AVULSION_FRACTURES',
  AZOOSPERMIA_ABSENCE_OF_SPERMATOZOA_IN_THE_EJACULATE = 'AZOOSPERMIA_ABSENCE_OF_SPERMATOZOA_IN_THE_EJACULATE',
  BACK_PAIN = 'BACK_PAIN',
  BACTERIAL_ENDOCARDITIS = 'BACTERIAL_ENDOCARDITIS',
  BACTERIAL_INFECTIONS = 'BACTERIAL_INFECTIONS',
  BACTERIAL_INFECTIONS_SUCH_AS_STREPTOCOCCUS_STAPHYLOCOCCUS = 'BACTERIAL_INFECTIONS_SUCH_AS_STREPTOCOCCUS_STAPHYLOCOCCUS',
  BACTERIAL_VAGINOSIS = 'BACTERIAL_VAGINOSIS',
  BALANCE_AND_FALL_DISORDERS = 'BALANCE_AND_FALL_DISORDERS',
  BALANCE_AND_PROPRIOCEPTION_DISORDERS = 'BALANCE_AND_PROPRIOCEPTION_DISORDERS',
  BANNAYAN_RILEY_RUVALCABA_SYNDROME = 'BANNAYAN_RILEY_RUVALCABA_SYNDROME',
  BANNAYAN_ZONANA_SYNDROME = 'BANNAYAN_ZONANA_SYNDROME',
  BARDET_BIEDL_LIKE_SYNDROME = 'BARDET_BIEDL_LIKE_SYNDROME',
  BARDET_BIEDL_SYNDROME = 'BARDET_BIEDL_SYNDROME',
  BARRETT_S_ESOPHAGUS = 'BARRETT_S_ESOPHAGUS',
  BARTON_S_FRACTURE = 'BARTON_S_FRACTURE',
  BARTTER_S_SYNDROME = 'BARTTER_S_SYNDROME',
  BASAL_CELL_CARCINOMA = 'BASAL_CELL_CARCINOMA',
  BASAL_CELL_CARCINOMA_OF_THE_PANCREAS = 'BASAL_CELL_CARCINOMA_OF_THE_PANCREAS',
  BATTEN_DISEASE = 'BATTEN_DISEASE',
  BECKER_S_MUSCULAR_DYSTROPHY = 'BECKER_S_MUSCULAR_DYSTROPHY',
  BECKWITH_WIEDEMANN_SYNDROME = 'BECKWITH_WIEDEMANN_SYNDROME',
  BECKWITH_WIEDEMANN_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT = 'BECKWITH_WIEDEMANN_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT',
  BEDSORES = 'BEDSORES',
  BEDWETTING = 'BEDWETTING',
  BEHAVIOR_DISORDERS = 'BEHAVIOR_DISORDERS',
  BEHAVIOR_DISORDERS_OPPOSITIONAL_PROVOCATIVE_CONDUCT = 'BEHAVIOR_DISORDERS_OPPOSITIONAL_PROVOCATIVE_CONDUCT',
  BEH_ET_S_DISEASE = 'BEH_ET_S_DISEASE',
  BEH_ET_S_SYNDROME = 'BEH_ET_S_SYNDROME',
  BELL_S_PALSY = 'BELL_S_PALSY',
  BELL_S_PALSY_PARALYSIS_OF_THE_FACIAL_NERVE = 'BELL_S_PALSY_PARALYSIS_OF_THE_FACIAL_NERVE',
  BENIGN_BONE_TUMORS = 'BENIGN_BONE_TUMORS',
  BENIGN_BREAST_TUMORS = 'BENIGN_BREAST_TUMORS',
  BENIGN_GEOGRAPHIC_LANGUAGE = 'BENIGN_GEOGRAPHIC_LANGUAGE',
  BENIGN_INTRAMEDULLARY_TUMOR = 'BENIGN_INTRAMEDULLARY_TUMOR',
  BENIGN_MIGRATORY_GLOSSITIS = 'BENIGN_MIGRATORY_GLOSSITIS',
  BENIGN_PANCREATIC_TUMORS = 'BENIGN_PANCREATIC_TUMORS',
  BENIGN_PROSTATIC_HYPERPLASIA_BPH = 'BENIGN_PROSTATIC_HYPERPLASIA_BPH',
  BENIGN_SKIN_TUMORS = 'BENIGN_SKIN_TUMORS',
  BENIGN_TUMORS = 'BENIGN_TUMORS',
  BENIGN_TUMORS_OF_THE_LIVER = 'BENIGN_TUMORS_OF_THE_LIVER',
  BENNETT_FRACTURE = 'BENNETT_FRACTURE',
  BERGER_S_DISEASE_IGA_NEPHROPATHY = 'BERGER_S_DISEASE_IGA_NEPHROPATHY',
  BERNARD_SOULIER_SYNDROME = 'BERNARD_SOULIER_SYNDROME',
  BICEPS_SYNDROME = 'BICEPS_SYNDROME',
  BICKERSTAFF_SYNDROME = 'BICKERSTAFF_SYNDROME',
  BILE_DUCT_ATRESIA = 'BILE_DUCT_ATRESIA',
  BILE_DUCT_CARCINOMA = 'BILE_DUCT_CARCINOMA',
  BILE_DUCT_CYST = 'BILE_DUCT_CYST',
  BILE_DUCT_TUMOR = 'BILE_DUCT_TUMOR',
  BILIARY_DYSKINESIA = 'BILIARY_DYSKINESIA',
  BILIARY_REFLUX = 'BILIARY_REFLUX',
  BILIARY_REFLUX_DISEASE = 'BILIARY_REFLUX_DISEASE',
  BILIARY_STENOSIS = 'BILIARY_STENOSIS',
  BILIARY_TRACT_CANCER = 'BILIARY_TRACT_CANCER',
  BILIARY_TRACT_CANCER_VARIANT_2 = 'BILIARY_TRACT_CANCER_VARIANT_2',
  BILIARY_TRACT_DISEASES_SUCH_AS_PRIMARY_SCLEROSING_CHOLANGITIS = 'BILIARY_TRACT_DISEASES_SUCH_AS_PRIMARY_SCLEROSING_CHOLANGITIS',
  BINDER_SYNDROME = 'BINDER_SYNDROME',
  BIPOLAR_DISORDER = 'BIPOLAR_DISORDER',
  BIPOLAR_MOOD_DISORDER = 'BIPOLAR_MOOD_DISORDER',
  BIRT_HOGG_DUB_SYNDROME = 'BIRT_HOGG_DUB_SYNDROME',
  BITE_WOUNDS = 'BITE_WOUNDS',
  BLACK_VILLOUS_TONGUE = 'BLACK_VILLOUS_TONGUE',
  BLADDER = 'BLADDER',
  BLADDER_CANCER = 'BLADDER_CANCER',
  BLADDER_CANCER_VARIANT_2 = 'BLADDER_CANCER_VARIANT_2',
  BLADDER_TUMORS = 'BLADDER_TUMORS',
  BLASTOMYCOSIS = 'BLASTOMYCOSIS',
  BLAU_SYNDROME = 'BLAU_SYNDROME',
  BLEEDING = 'BLEEDING',
  BLEEDING_COAGULATION_PROBLEMS = 'BLEEDING_COAGULATION_PROBLEMS',
  BLEEDING_DISORDERS_SUCH_AS_HEMOPHILIA_AND_THROMBOCYTOPATHY = 'BLEEDING_DISORDERS_SUCH_AS_HEMOPHILIA_AND_THROMBOCYTOPATHY',
  BLEPHARITIS = 'BLEPHARITIS',
  BLEPHAROCALAZIUM = 'BLEPHAROCALAZIUM',
  BLEPHAROPTOSIS = 'BLEPHAROPTOSIS',
  BLEPHAROSPASM = 'BLEPHAROSPASM',
  BLINDNESS = 'BLINDNESS',
  BLOOD_CANCERS = 'BLOOD_CANCERS',
  BLOOD_CANCER_TUMOR = 'BLOOD_CANCER_TUMOR',
  BLOOD_CELL_TUMOR = 'BLOOD_CELL_TUMOR',
  BLOOD_COAGULATION_DISEASES_SUCH_AS_HEMOPHILIA = 'BLOOD_COAGULATION_DISEASES_SUCH_AS_HEMOPHILIA',
  BLOOD_PROBLEMS = 'BLOOD_PROBLEMS',
  BLOOD_VESSELS_ARTERIES_VEINS_AND_CAPILLARIES = 'BLOOD_VESSELS_ARTERIES_VEINS_AND_CAPILLARIES',
  BLOUNT_S_DISEASE = 'BLOUNT_S_DISEASE',
  BLOUNT_S_SYNDROME = 'BLOUNT_S_SYNDROME',
  BLUE_BASED_MELANOCYTIC_NEVUS = 'BLUE_BASED_MELANOCYTIC_NEVUS',
  BOLIVIAN_HEMORRHAGIC_FEVER = 'BOLIVIAN_HEMORRHAGIC_FEVER',
  BONES = 'BONES',
  BONE_CANCER = 'BONE_CANCER',
  BONE_CANCER_TUMOR = 'BONE_CANCER_TUMOR',
  BONE_FIBROSARCOMA = 'BONE_FIBROSARCOMA',
  BONE_LEIOMYOSARCOMA = 'BONE_LEIOMYOSARCOMA',
  BONE_MARROW_CANCER = 'BONE_MARROW_CANCER',
  BONE_METASTASIS_FROM_TUMORS_ELSEWHERE_IN_THE_BODY = 'BONE_METASTASIS_FROM_TUMORS_ELSEWHERE_IN_THE_BODY',
  BONE_SARCOMA = 'BONE_SARCOMA',
  BONE_TUMORS = 'BONE_TUMORS',
  BORDERLINE_PERSONALITY_DISORDER = 'BORDERLINE_PERSONALITY_DISORDER',
  BORDERLINE_PERSONALITY_DISORDER_BPD = 'BORDERLINE_PERSONALITY_DISORDER_BPD',
  BORDERLINE_PERSONALITY_DISORDER_VARIANT_2 = 'BORDERLINE_PERSONALITY_DISORDER_VARIANT_2',
  BOTALLO_S_DUCTUS_SYNDROME = 'BOTALLO_S_DUCTUS_SYNDROME',
  BOTULISM = 'BOTULISM',
  BOWEL_BLEEDING = 'BOWEL_BLEEDING',
  BOWEL_CANCER = 'BOWEL_CANCER',
  BOWEL_CANCER_TUMOR = 'BOWEL_CANCER_TUMOR',
  BOWEL_CANCER_VARIANT_2 = 'BOWEL_CANCER_VARIANT_2',
  BOWEL_MOTILITY_DISORDERS_SUCH_AS_IRRITABLE_BOWEL_SYNDROME = 'BOWEL_MOTILITY_DISORDERS_SUCH_AS_IRRITABLE_BOWEL_SYNDROME',
  BOWEL_PROBLEMS = 'BOWEL_PROBLEMS',
  BOWEL_TORSION = 'BOWEL_TORSION',
  BOWEL_TUMORS = 'BOWEL_TUMORS',
  BOXER_S_FRACTURE = 'BOXER_S_FRACTURE',
  BRACHIAL_PLEXUS_INJURIES = 'BRACHIAL_PLEXUS_INJURIES',
  BRACHIAL_PLEXUS_TUMORS = 'BRACHIAL_PLEXUS_TUMORS',
  BRACHIORADIALIS_MUSCLE_SYNDROME = 'BRACHIORADIALIS_MUSCLE_SYNDROME',
  BRAIN = 'BRAIN',
  BRAINSTEM_INJURY = 'BRAINSTEM_INJURY',
  BRAIN_CONTUSION = 'BRAIN_CONTUSION',
  BRAIN_METASTASIS = 'BRAIN_METASTASIS',
  BRAIN_TRAUMA = 'BRAIN_TRAUMA',
  BRAIN_TUMOR = 'BRAIN_TUMOR',
  BRAIN_TUMOR_VARIANT_2 = 'BRAIN_TUMOR_VARIANT_2',
  BREAST_ATRESIA = 'BREAST_ATRESIA',
  BREAST_BREAST_CANCER = 'BREAST_BREAST_CANCER',
  BREAST_CANCER = 'BREAST_CANCER',
  BREAST_CANCER_VARIANT_2 = 'BREAST_CANCER_VARIANT_2',
  BREAST_CANCER_VARIANT_3 = 'BREAST_CANCER_VARIANT_3',
  BREAST_CANCER_VARIANT_4 = 'BREAST_CANCER_VARIANT_4',
  BREAST_CARCINOMA = 'BREAST_CARCINOMA',
  BREAST_CARCINOMA_BREAST_CANCER = 'BREAST_CARCINOMA_BREAST_CANCER',
  BREAST_DEFECTS = 'BREAST_DEFECTS',
  BREAST_LYMPHOMA = 'BREAST_LYMPHOMA',
  BREAST_MALFORMATIONS = 'BREAST_MALFORMATIONS',
  BREAST_NODULES = 'BREAST_NODULES',
  BREAST_PROSTHESIS = 'BREAST_PROSTHESIS',
  BREAST_SKIN_CONDITIONS_OR_PROBLEMS = 'BREAST_SKIN_CONDITIONS_OR_PROBLEMS',
  BREATHING_DISORDERS = 'BREATHING_DISORDERS',
  BRIEF_PSYCHOTIC_DISORDER = 'BRIEF_PSYCHOTIC_DISORDER',
  BROKEN_HEART_SYNDROME = 'BROKEN_HEART_SYNDROME',
  BRONCHIAL_ASTHMA = 'BRONCHIAL_ASTHMA',
  BRONCHIAL_CYST_OF_THE_MIDDLE_MEDIASTINUM = 'BRONCHIAL_CYST_OF_THE_MIDDLE_MEDIASTINUM',
  BRONCHIECTASIS = 'BRONCHIECTASIS',
  BRONCHIOLITIS_OBLITERANS_WITH_ORGANIZATIONAL_PNEUMONIA_BOOP = 'BRONCHIOLITIS_OBLITERANS_WITH_ORGANIZATIONAL_PNEUMONIA_BOOP',
  BRUCELLOSIS = 'BRUCELLOSIS',
  BRUGADA_SYNDROME = 'BRUGADA_SYNDROME',
  BRUISING_BLEEDING = 'BRUISING_BLEEDING',
  BUBBLES = 'BUBBLES',
  BUERGER_S_DISEASE = 'BUERGER_S_DISEASE',
  BULIMIA_NERVOSA = 'BULIMIA_NERVOSA',
  BULLOUS_EPIDERMOLYSIS = 'BULLOUS_EPIDERMOLYSIS',
  BULLOUS_IMPETIGO = 'BULLOUS_IMPETIGO',
  BULLOUS_PEMPHIGOID = 'BULLOUS_PEMPHIGOID',
  BULLOUS_PENOPHIGOID = 'BULLOUS_PENOPHIGOID',
  BURNING_MOUTH_SYNDROME = 'BURNING_MOUTH_SYNDROME',
  BURNS = 'BURNS',
  BURN_WOUNDS = 'BURN_WOUNDS',
  BURSITIS = 'BURSITIS',
  BURULI_DISEASE_BURULI_ULCER = 'BURULI_DISEASE_BURULI_ULCER',
  BURULI_ULCER = 'BURULI_ULCER',
  CAESAR = 'CAESAR',
  CALCIUM_DEPOSIT_NEPHROPATHY = 'CALCIUM_DEPOSIT_NEPHROPATHY',
  CAMPYLOBACTER_INFECTIONS = 'CAMPYLOBACTER_INFECTIONS',
  CAMPYLOBACTER_JEJUNI_INFECTIONS = 'CAMPYLOBACTER_JEJUNI_INFECTIONS',
  CANCER_CANCER_OF_THE_UTERUS = 'CANCER_CANCER_OF_THE_UTERUS',
  CANCER_IN_PARTICULAR_COLON_BREAST_PROSTATE_CANCERS = 'CANCER_IN_PARTICULAR_COLON_BREAST_PROSTATE_CANCERS',
  CANCER_OF_THE_ANUS = 'CANCER_OF_THE_ANUS',
  CANCER_OF_THE_ANUS_CARCINOMA_OF_THE_ANUS = 'CANCER_OF_THE_ANUS_CARCINOMA_OF_THE_ANUS',
  CANCER_OF_THE_APPENDIX = 'CANCER_OF_THE_APPENDIX',
  CANCER_OF_THE_ASCENDING_COLON = 'CANCER_OF_THE_ASCENDING_COLON',
  CANCER_OF_THE_BILE_DUCT_CHOLANGIOCARCINOMA = 'CANCER_OF_THE_BILE_DUCT_CHOLANGIOCARCINOMA',
  CANCER_OF_THE_BLIND = 'CANCER_OF_THE_BLIND',
  CANCER_OF_THE_ESOPHAGUS_CARCINOMA_OF_THE_ESOPHAGUS = 'CANCER_OF_THE_ESOPHAGUS_CARCINOMA_OF_THE_ESOPHAGUS',
  CANCER_OF_THE_HEAD_TUMORS = 'CANCER_OF_THE_HEAD_TUMORS',
  CANCER_OF_THE_ILEUM = 'CANCER_OF_THE_ILEUM',
  CANCER_OF_THE_LARGE_INTESTINE = 'CANCER_OF_THE_LARGE_INTESTINE',
  CANCER_OF_THE_NOSE = 'CANCER_OF_THE_NOSE',
  CANCER_OF_THE_NOSE_AND_SINUSES = 'CANCER_OF_THE_NOSE_AND_SINUSES',
  CANCER_OF_THE_ORAL_CAVITY = 'CANCER_OF_THE_ORAL_CAVITY',
  CANCER_OF_THE_ORAL_CAVITY_AND_PHARYNX = 'CANCER_OF_THE_ORAL_CAVITY_AND_PHARYNX',
  CANCER_OF_THE_PANCREAS = 'CANCER_OF_THE_PANCREAS',
  CANCER_OF_THE_PANCREAS_PANCREATIC_ADENOCARCINOMA = 'CANCER_OF_THE_PANCREAS_PANCREATIC_ADENOCARCINOMA',
  CANCER_OF_THE_PENIS = 'CANCER_OF_THE_PENIS',
  CANCER_OF_THE_RECTUM = 'CANCER_OF_THE_RECTUM',
  CANCER_OF_THE_RECTUM_VARIANT_2 = 'CANCER_OF_THE_RECTUM_VARIANT_2',
  CANCER_OF_THE_SCROTUM = 'CANCER_OF_THE_SCROTUM',
  CANCER_OF_THE_SMALL_INTESTINE = 'CANCER_OF_THE_SMALL_INTESTINE',
  CANCER_OF_THE_STOMACH = 'CANCER_OF_THE_STOMACH',
  CANCER_OF_THE_STOMACH_GASTRIC_ADENOCARCINOMA = 'CANCER_OF_THE_STOMACH_GASTRIC_ADENOCARCINOMA',
  CANCER_OF_THE_TERMINAL_ILEUM = 'CANCER_OF_THE_TERMINAL_ILEUM',
  CANCER_OF_THE_UTERUS_CERVIX = 'CANCER_OF_THE_UTERUS_CERVIX',
  CANCER_OF_THE_VULVA = 'CANCER_OF_THE_VULVA',
  CANCER_OF_THE_VULVA_VARIANT_2 = 'CANCER_OF_THE_VULVA_VARIANT_2',
  CANCER_OVARIAN_CANCER = 'CANCER_OVARIAN_CANCER',
  CANCER_SUCH_AS_LUNG_CANCER_COLORECTAL_CANCER_AND_BREAST_CANCER = 'CANCER_SUCH_AS_LUNG_CANCER_COLORECTAL_CANCER_AND_BREAST_CANCER',
  CANDIDA_INFECTIONS_SUCH_AS_CANDIDIASIS = 'CANDIDA_INFECTIONS_SUCH_AS_CANDIDIASIS',
  CANDIDIASIS_CANDIDA_INFECTION = 'CANDIDIASIS_CANDIDA_INFECTION',
  CANDLE_SYNDROME = 'CANDLE_SYNDROME',
  CAPILLARY_FRAGILITY = 'CAPILLARY_FRAGILITY',
  CARCINOMA_ADENOCARCINOMA_OF_THE_ANUS = 'CARCINOMA_ADENOCARCINOMA_OF_THE_ANUS',
  CARCINOMA_OF_THE_ANAL_MARGIN = 'CARCINOMA_OF_THE_ANAL_MARGIN',
  CARCINOMA_OF_THE_ANUS = 'CARCINOMA_OF_THE_ANUS',
  CARCINOMA_OF_THE_ASCENDING_COLON = 'CARCINOMA_OF_THE_ASCENDING_COLON',
  CARCINOMA_OF_THE_BILIARY_TRACT = 'CARCINOMA_OF_THE_BILIARY_TRACT',
  CARCINOMA_OF_THE_BLADDER = 'CARCINOMA_OF_THE_BLADDER',
  CARCINOMA_OF_THE_BRAINSTEM = 'CARCINOMA_OF_THE_BRAINSTEM',
  CARCINOMA_OF_THE_BRIDGE = 'CARCINOMA_OF_THE_BRIDGE',
  CARCINOMA_OF_THE_BULB = 'CARCINOMA_OF_THE_BULB',
  CARCINOMA_OF_THE_CEREBELLUM = 'CARCINOMA_OF_THE_CEREBELLUM',
  CARCINOMA_OF_THE_DISTAL_RECTUM = 'CARCINOMA_OF_THE_DISTAL_RECTUM',
  CARCINOMA_OF_THE_DUCTUS_DEFERENS = 'CARCINOMA_OF_THE_DUCTUS_DEFERENS',
  CARCINOMA_OF_THE_EJACULATORY_DUCT = 'CARCINOMA_OF_THE_EJACULATORY_DUCT',
  CARCINOMA_OF_THE_ENCEPHALUS = 'CARCINOMA_OF_THE_ENCEPHALUS',
  CARCINOMA_OF_THE_ESOPHAGUS = 'CARCINOMA_OF_THE_ESOPHAGUS',
  CARCINOMA_OF_THE_EXOCRINE_PANCREAS = 'CARCINOMA_OF_THE_EXOCRINE_PANCREAS',
  CARCINOMA_OF_THE_EXTRAHEPATIC_BILIARY_TRACT = 'CARCINOMA_OF_THE_EXTRAHEPATIC_BILIARY_TRACT',
  CARCINOMA_OF_THE_GALLBLADDER = 'CARCINOMA_OF_THE_GALLBLADDER',
  CARCINOMA_OF_THE_GALLBLADDER_VARIANT_2 = 'CARCINOMA_OF_THE_GALLBLADDER_VARIANT_2',
  CARCINOMA_OF_THE_GASTROINTESTINAL_TRACT = 'CARCINOMA_OF_THE_GASTROINTESTINAL_TRACT',
  CARCINOMA_OF_THE_ILEOCECAL_VALVE = 'CARCINOMA_OF_THE_ILEOCECAL_VALVE',
  CARCINOMA_OF_THE_LARYNX = 'CARCINOMA_OF_THE_LARYNX',
  CARCINOMA_OF_THE_LEFT_TRANSVERSE_COLON = 'CARCINOMA_OF_THE_LEFT_TRANSVERSE_COLON',
  CARCINOMA_OF_THE_LIVER = 'CARCINOMA_OF_THE_LIVER',
  CARCINOMA_OF_THE_LOWER_RECTUM = 'CARCINOMA_OF_THE_LOWER_RECTUM',
  CARCINOMA_OF_THE_MAMMARY_DUCT = 'CARCINOMA_OF_THE_MAMMARY_DUCT',
  CARCINOMA_OF_THE_MIDBRAIN = 'CARCINOMA_OF_THE_MIDBRAIN',
  CARCINOMA_OF_THE_MIDDLE_RECTUM = 'CARCINOMA_OF_THE_MIDDLE_RECTUM',
  CARCINOMA_OF_THE_PANCREAS = 'CARCINOMA_OF_THE_PANCREAS',
  CARCINOMA_OF_THE_PLEURA = 'CARCINOMA_OF_THE_PLEURA',
  CARCINOMA_OF_THE_PROSTATE = 'CARCINOMA_OF_THE_PROSTATE',
  CARCINOMA_OF_THE_PROXIMAL_RECTUM = 'CARCINOMA_OF_THE_PROXIMAL_RECTUM',
  CARCINOMA_OF_THE_RIGHT_ASCENDANT = 'CARCINOMA_OF_THE_RIGHT_ASCENDANT',
  CARCINOMA_OF_THE_RIGHT_TRANSVERSE_COLON = 'CARCINOMA_OF_THE_RIGHT_TRANSVERSE_COLON',
  CARCINOMA_OF_THE_SIGMA = 'CARCINOMA_OF_THE_SIGMA',
  CARCINOMA_OF_THE_SIGMOID_COLON = 'CARCINOMA_OF_THE_SIGMOID_COLON',
  CARCINOMA_OF_THE_SKIN = 'CARCINOMA_OF_THE_SKIN',
  CARCINOMA_OF_THE_SKIN_NOT_MELANOMA = 'CARCINOMA_OF_THE_SKIN_NOT_MELANOMA',
  CARCINOMA_OF_THE_SPINAL_CORD = 'CARCINOMA_OF_THE_SPINAL_CORD',
  CARCINOMA_OF_THE_TESTICULAR_GLOMUS = 'CARCINOMA_OF_THE_TESTICULAR_GLOMUS',
  CARCINOMA_OF_THE_TRANSVERSE_COLON = 'CARCINOMA_OF_THE_TRANSVERSE_COLON',
  CARCINOMA_OF_THE_UPPER_RECTUM = 'CARCINOMA_OF_THE_UPPER_RECTUM',
  CARCINOMA_OF_THE_URETHRA = 'CARCINOMA_OF_THE_URETHRA',
  CARCINOMA_OF_THE_VAGINA = 'CARCINOMA_OF_THE_VAGINA',
  CARCINOMA_OF_THE_VULVA = 'CARCINOMA_OF_THE_VULVA',
  CARDIAC_ARRHYTHMIA = 'CARDIAC_ARRHYTHMIA',
  CARDIAC_ARRHYTHMIAS = 'CARDIAC_ARRHYTHMIAS',
  CARDIAC_TAMPONADE = 'CARDIAC_TAMPONADE',
  CARDIAC_TUMORS = 'CARDIAC_TUMORS',
  CARDIOMYOPATHY = 'CARDIOMYOPATHY',
  CARDIOVASCULAR_DISEASE_WITH_IMPLICATIONS_FOR_DIET_AND_NUTRITION = 'CARDIOVASCULAR_DISEASE_WITH_IMPLICATIONS_FOR_DIET_AND_NUTRITION',
  CARIES = 'CARIES',
  CARIOLOGY = 'CARIOLOGY',
  CARNEY_SYNDROME = 'CARNEY_SYNDROME',
  CAROTID_ARTERY_ANEURYSM = 'CAROTID_ARTERY_ANEURYSM',
  CAROTID_ARTERY_STENOSIS = 'CAROTID_ARTERY_STENOSIS',
  CAROTID_ARTERY_STENOSIS_VARIANT_2 = 'CAROTID_ARTERY_STENOSIS_VARIANT_2',
  CAROTID_GLOMUS_TUMOR = 'CAROTID_GLOMUS_TUMOR',
  CAROTID_STENOSIS = 'CAROTID_STENOSIS',
  CARPAL_TUNNEL_SYNDROME = 'CARPAL_TUNNEL_SYNDROME',
  CARPAL_TUNNEL_SYNDROME_FOR_CYCLISTS = 'CARPAL_TUNNEL_SYNDROME_FOR_CYCLISTS',
  CAR_T = 'CAR_T',
  CASTLEMAN_S_DISEASE = 'CASTLEMAN_S_DISEASE',
  CATARACTS = 'CATARACTS',
  CAVERNOUS_BREAST_CANCER = 'CAVERNOUS_BREAST_CANCER',
  CD4_MINUS_CD8_PLUS_T_LYMPHOCYTOSIS = 'CD4_MINUS_CD8_PLUS_T_LYMPHOCYTOSIS',
  CD4_PLUS_CD8_MINUS_T_LYMPHOCYTOSIS = 'CD4_PLUS_CD8_MINUS_T_LYMPHOCYTOSIS',
  CDKL5_SYNDROME = 'CDKL5_SYNDROME',
  CELIAC_ARTERY_ANEURYSM = 'CELIAC_ARTERY_ANEURYSM',
  CELIAC_DISEASE = 'CELIAC_DISEASE',
  CELIAC_DISEASE_GLUTEN_INTOLERANCE = 'CELIAC_DISEASE_GLUTEN_INTOLERANCE',
  CELIAC_DISEASE_VARIANT_2 = 'CELIAC_DISEASE_VARIANT_2',
  CELLULITE = 'CELLULITE',
  CEMENT_OSSEOUS_DYSPLASIA = 'CEMENT_OSSEOUS_DYSPLASIA',
  CENTRAL_NERVOUS_SYSTEM_DISORDERS = 'CENTRAL_NERVOUS_SYSTEM_DISORDERS',
  CENTRAL_NERVOUS_SYSTEM_DISORDERS_SUCH_AS_ALZHEIMER_S_DISEASE = 'CENTRAL_NERVOUS_SYSTEM_DISORDERS_SUCH_AS_ALZHEIMER_S_DISEASE',
  CENTRAL_SEROUS_CHOROIDOPATHY = 'CENTRAL_SEROUS_CHOROIDOPATHY',
  CEREBELLAR_ATAXIA = 'CEREBELLAR_ATAXIA',
  CEREBELLAR_CONTUSION = 'CEREBELLAR_CONTUSION',
  CEREBELLAR_HEMORRHAGE = 'CEREBELLAR_HEMORRHAGE',
  CEREBELLAR_TUMOR = 'CEREBELLAR_TUMOR',
  CEREBELLO_SPINAL_ATAXIA = 'CEREBELLO_SPINAL_ATAXIA',
  CEREBRAL_ARTERIOVENOUS_MALFORMATION_AVM = 'CEREBRAL_ARTERIOVENOUS_MALFORMATION_AVM',
  CEREBRAL_ARTERY_ANEURYSM = 'CEREBRAL_ARTERY_ANEURYSM',
  CEREBRAL_BLEEDING = 'CEREBRAL_BLEEDING',
  CEREBRAL_EDEMA = 'CEREBRAL_EDEMA',
  CEREBRAL_FLUID_DYSFUNCTION = 'CEREBRAL_FLUID_DYSFUNCTION',
  CEREBRAL_HEMANGIOMA = 'CEREBRAL_HEMANGIOMA',
  CEREBRAL_HEMATOMA = 'CEREBRAL_HEMATOMA',
  CEREBRAL_HEMORRHAGE = 'CEREBRAL_HEMORRHAGE',
  CEREBRAL_PALSY = 'CEREBRAL_PALSY',
  CEREBRAL_VENOUS_THROMBOSIS = 'CEREBRAL_VENOUS_THROMBOSIS',
  CERVICALEGIA = 'CERVICALEGIA',
  CERVICAL_CANCER = 'CERVICAL_CANCER',
  CERVICAL_CANCER_VARIANT_2 = 'CERVICAL_CANCER_VARIANT_2',
  CERVICAL_CANCER_VARIANT_3 = 'CERVICAL_CANCER_VARIANT_3',
  CERVICAL_CARCINOMA = 'CERVICAL_CARCINOMA',
  CERVICAL_DYSPLASIA = 'CERVICAL_DYSPLASIA',
  CERVICAL_ECTOPIA = 'CERVICAL_ECTOPIA',
  CHAGAS_DISEASE = 'CHAGAS_DISEASE',
  CHAGAS_DISEASE_VARIANT_2 = 'CHAGAS_DISEASE_VARIANT_2',
  CHARCOT_MARIE_TOOTH_DISEASE = 'CHARCOT_MARIE_TOOTH_DISEASE',
  CHARCOT_MARIE_TOOTH_DISEASE_CMT = 'CHARCOT_MARIE_TOOTH_DISEASE_CMT',
  CHARGE_SYNDROME = 'CHARGE_SYNDROME',
  CHAUFFEUR_S_FRACTURE = 'CHAUFFEUR_S_FRACTURE',
  CHEEK_CANCER = 'CHEEK_CANCER',
  CHERUBISM = 'CHERUBISM',
  CHEST_CANCER = 'CHEST_CANCER',
  CHEST_TRAUMA = 'CHEST_TRAUMA',
  CHEST_WALL_TRAUMA = 'CHEST_WALL_TRAUMA',
  CHEWING_DEFECTS = 'CHEWING_DEFECTS',
  CHIARI_MALFORMATION = 'CHIARI_MALFORMATION',
  CHICKENPOX = 'CHICKENPOX',
  CHIKUNGUNYA = 'CHIKUNGUNYA',
  CHIKUNGUNYA_DISEASE = 'CHIKUNGUNYA_DISEASE',
  CHILBLAIN_LUPUS_SYNDROME = 'CHILBLAIN_LUPUS_SYNDROME',
  CHILDHOOD_LEUKEMIA = 'CHILDHOOD_LEUKEMIA',
  CHILDHOOD_SCHIZOPHRENIA_RARE_BUT_POSSIBLE = 'CHILDHOOD_SCHIZOPHRENIA_RARE_BUT_POSSIBLE',
  CHILD_NUTRITION_DISORDERS_SUCH_AS_MILK_PROTEIN_INTOLERANCE = 'CHILD_NUTRITION_DISORDERS_SUCH_AS_MILK_PROTEIN_INTOLERANCE',
  CHIN_CANCER = 'CHIN_CANCER',
  CHIN_DEFORMITY = 'CHIN_DEFORMITY',
  CHLAMYDIA = 'CHLAMYDIA',
  CHOLANGIOCARCINOMA = 'CHOLANGIOCARCINOMA',
  CHOLECYSTITIS = 'CHOLECYSTITIS',
  CHOLECYSTITIS_INFLAMMATION_OF_THE_GALLBLADDER = 'CHOLECYSTITIS_INFLAMMATION_OF_THE_GALLBLADDER',
  CHOLEDOCHOCELE = 'CHOLEDOCHOCELE',
  CHOLELITHIASIS = 'CHOLELITHIASIS',
  CHOLELITHIASIS_GALLSTONES = 'CHOLELITHIASIS_GALLSTONES',
  CHOLERA = 'CHOLERA',
  CHOLESTEATOMA = 'CHOLESTEATOMA',
  CHONDROSARCOMA = 'CHONDROSARCOMA',
  CHORIORETINITIS = 'CHORIORETINITIS',
  CHOROIDAL_FRECKLE = 'CHOROIDAL_FRECKLE',
  CHOROID_PLEXUS_CYST = 'CHOROID_PLEXUS_CYST',
  CHOROID_PLEXUS_TUMOR = 'CHOROID_PLEXUS_TUMOR',
  CHROMOPHOBE_RENAL_CELL_CARCINOMA = 'CHROMOPHOBE_RENAL_CELL_CARCINOMA',
  CHRONIC = 'CHRONIC',
  CHRONIC_ATAXIA = 'CHRONIC_ATAXIA',
  CHRONIC_BRONCHITIS = 'CHRONIC_BRONCHITIS',
  CHRONIC_CEREBELLAR_HEMORRHAGE = 'CHRONIC_CEREBELLAR_HEMORRHAGE',
  CHRONIC_CHOLECYSTITIS = 'CHRONIC_CHOLECYSTITIS',
  CHRONIC_CONSTIPATION = 'CHRONIC_CONSTIPATION',
  CHRONIC_DIARRHEA = 'CHRONIC_DIARRHEA',
  CHRONIC_EPIDURAL_HEMATOMA = 'CHRONIC_EPIDURAL_HEMATOMA',
  CHRONIC_FATIGUE_SYNDROME = 'CHRONIC_FATIGUE_SYNDROME',
  CHRONIC_HEPATITIS = 'CHRONIC_HEPATITIS',
  CHRONIC_INFLAMMATORY_DEMYELINATING_POLYNEUROPATHY_CIDP = 'CHRONIC_INFLAMMATORY_DEMYELINATING_POLYNEUROPATHY_CIDP',
  CHRONIC_INFLAMMATORY_DEMYELINATING_POLYNEUROPATHY_CIDP_VARIANT_2 = 'CHRONIC_INFLAMMATORY_DEMYELINATING_POLYNEUROPATHY_CIDP_VARIANT_2',
  CHRONIC_KIDNEY_DISEASE = 'CHRONIC_KIDNEY_DISEASE',
  CHRONIC_LUNG_DISEASES_SUCH_AS_CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_COPD = 'CHRONIC_LUNG_DISEASES_SUCH_AS_CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_COPD',
  CHRONIC_LYMPHATIC_LEUKEMIA_CLL = 'CHRONIC_LYMPHATIC_LEUKEMIA_CLL',
  CHRONIC_LYMPHOBLASTIC_LEUKEMIA = 'CHRONIC_LYMPHOBLASTIC_LEUKEMIA',
  CHRONIC_MYELOID_LEUKEMIA = 'CHRONIC_MYELOID_LEUKEMIA',
  CHRONIC_MYELOID_LEUKEMIA_CML = 'CHRONIC_MYELOID_LEUKEMIA_CML',
  CHRONIC_MYELOMONOCYTIC_LEUKEMIA = 'CHRONIC_MYELOMONOCYTIC_LEUKEMIA',
  CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_COPD = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_COPD',
  CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_COPD_VARIANT_2 = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_COPD_VARIANT_2',
  CHRONIC_OSTEOMYELITIS = 'CHRONIC_OSTEOMYELITIS',
  CHRONIC_PAIN = 'CHRONIC_PAIN',
  CHRONIC_PANCREATITIS = 'CHRONIC_PANCREATITIS',
  CHRONIC_RENAL_FAILURE = 'CHRONIC_RENAL_FAILURE',
  CHRONIC_RESPIRATORY_DISEASES_SUCH_AS_COPD = 'CHRONIC_RESPIRATORY_DISEASES_SUCH_AS_COPD',
  CHRONIC_SINUSITIS = 'CHRONIC_SINUSITIS',
  CHRONIC_SUBDURAL_HEMORRHAGE = 'CHRONIC_SUBDURAL_HEMORRHAGE',
  CHRONIC_SYHALADENITIS = 'CHRONIC_SYHALADENITIS',
  CHRONIC_T_LYMPHOCYTOSIS_LTC = 'CHRONIC_T_LYMPHOCYTOSIS_LTC',
  CHRONIC_VENOUS_INSUFFICIENCY = 'CHRONIC_VENOUS_INSUFFICIENCY',
  CHURG_STRAUSS_SYNDROME = 'CHURG_STRAUSS_SYNDROME',
  CHURG_STRAUSS_SYNDROME_WITH_CARDIAC_INVOLVEMENT = 'CHURG_STRAUSS_SYNDROME_WITH_CARDIAC_INVOLVEMENT',
  CIGAR_CELL_CARCINOMA_OF_THE_PANCREAS = 'CIGAR_CELL_CARCINOMA_OF_THE_PANCREAS',
  CIRCADIAN_RHYTHM_DISORDER = 'CIRCADIAN_RHYTHM_DISORDER',
  CLEAR_CELL_CARCINOMA_OF_THE_PANCREAS = 'CLEAR_CELL_CARCINOMA_OF_THE_PANCREAS',
  CLEAR_CELL_CARCINOMA_OF_THE_PANCREAS_VARIANT_2 = 'CLEAR_CELL_CARCINOMA_OF_THE_PANCREAS_VARIANT_2',
  CLEAR_RENAL_CELL_CARCINOMA = 'CLEAR_RENAL_CELL_CARCINOMA',
  CLEFT_PALATE_AND_CLEFT_LIP_PALATE = 'CLEFT_PALATE_AND_CLEFT_LIP_PALATE',
  CLIVUS_CANCER = 'CLIVUS_CANCER',
  CLOSTRIDIUM_DIFFICILE_INFECTIONS = 'CLOSTRIDIUM_DIFFICILE_INFECTIONS',
  CLUB_CELL_CARCINOMA_OF_THE_PANCREAS = 'CLUB_CELL_CARCINOMA_OF_THE_PANCREAS',
  CLUSTER_HEADACHE = 'CLUSTER_HEADACHE',
  COAGULATION_PROBLEMS = 'COAGULATION_PROBLEMS',
  COARCTATION_OF_THE_AORTA = 'COARCTATION_OF_THE_AORTA',
  COCCIDIOIDOMYCOSIS = 'COCCIDIOIDOMYCOSIS',
  COCCYGEAL_MUSCLE_SYNDROME = 'COCCYGEAL_MUSCLE_SYNDROME',
  COCKAYNE_SYNDROME = 'COCKAYNE_SYNDROME',
  COCONUT_CELL_CARCINOMA_OF_THE_PANCREAS = 'COCONUT_CELL_CARCINOMA_OF_THE_PANCREAS',
  COFFIN_LOWRY_SYNDROME = 'COFFIN_LOWRY_SYNDROME',
  COLANGITIS = 'COLANGITIS',
  COLITIS = 'COLITIS',
  COLLES_FRACTURE = 'COLLES_FRACTURE',
  COLLOIDAL_CYST = 'COLLOIDAL_CYST',
  COLONIC_PATHOLOGY_DISORDERS = 'COLONIC_PATHOLOGY_DISORDERS',
  COLON_CANCER = 'COLON_CANCER',
  COLON_POLYPS = 'COLON_POLYPS',
  COLORECTAL_CANCER = 'COLORECTAL_CANCER',
  COLORECTAL_CANCER_VARIANT_2 = 'COLORECTAL_CANCER_VARIANT_2',
  COLORECTAL_CARCINOMA = 'COLORECTAL_CARCINOMA',
  COLORECTAL_CARCINOMA_VARIANT_2 = 'COLORECTAL_CARCINOMA_VARIANT_2',
  COMMON_FINGER_FLEXOR_MUSCLE_SYNDROME = 'COMMON_FINGER_FLEXOR_MUSCLE_SYNDROME',
  COMMON_VARIABLE_IMMUNODEFICIENCY_CVID = 'COMMON_VARIABLE_IMMUNODEFICIENCY_CVID',
  COMMUNICATING_HYDROCEPHALUS = 'COMMUNICATING_HYDROCEPHALUS',
  COMMUNICATION_AND_LANGUAGE_DISORDERS_ASSOCIATED_WITH_MEDICAL_CONDITIONS = 'COMMUNICATION_AND_LANGUAGE_DISORDERS_ASSOCIATED_WITH_MEDICAL_CONDITIONS',
  COMPARTMENT_SYNDROME = 'COMPARTMENT_SYNDROME',
  COMPARTMENT_SYNDROME_VARIANT_2 = 'COMPARTMENT_SYNDROME_VARIANT_2',
  COMPLICATIONS_FROM_SCARRING = 'COMPLICATIONS_FROM_SCARRING',
  COMPLICATIONS_OF_DIABETES = 'COMPLICATIONS_OF_DIABETES',
  COMPLICATIONS_OF_PREGNANCY = 'COMPLICATIONS_OF_PREGNANCY',
  COMPOUND_SKULL_FRACTURE = 'COMPOUND_SKULL_FRACTURE',
  COMPOUND_SKULL_FRACTURE_VARIANT_2 = 'COMPOUND_SKULL_FRACTURE_VARIANT_2',
  CONDUCT_DISORDER_CD = 'CONDUCT_DISORDER_CD',
  CONGENITAL_ABNORMALITIES_OF_THE_FACE_AND_JAW = 'CONGENITAL_ABNORMALITIES_OF_THE_FACE_AND_JAW',
  CONGENITAL_ABNORMALITIES_OF_THE_HAND = 'CONGENITAL_ABNORMALITIES_OF_THE_HAND',
  CONGENITAL_ADRENAL_HYPERPLASIA = 'CONGENITAL_ADRENAL_HYPERPLASIA',
  CONGENITAL_APLASTIC_ANEMIA = 'CONGENITAL_APLASTIC_ANEMIA',
  CONGENITAL_CLUBFOOT = 'CONGENITAL_CLUBFOOT',
  CONGENITAL_DIAPHRAGMATIC_HERNIA = 'CONGENITAL_DIAPHRAGMATIC_HERNIA',
  CONGENITAL_HEART_DISEASE = 'CONGENITAL_HEART_DISEASE',
  CONGENITAL_KIDNEY_PROBLEMS = 'CONGENITAL_KIDNEY_PROBLEMS',
  CONGENITAL_MALFORMATIONS_OF_THE_BREAST = 'CONGENITAL_MALFORMATIONS_OF_THE_BREAST',
  CONGENITAL_MALFORMATIONS_OF_THE_FACE_AND_SKULL = 'CONGENITAL_MALFORMATIONS_OF_THE_FACE_AND_SKULL',
  CONGENITAL_MALFORMATIONS_OF_THE_HEART = 'CONGENITAL_MALFORMATIONS_OF_THE_HEART',
  CONGENITAL_METABOLIC_DISEASES_SUCH_AS_PHENYLKETONURIA = 'CONGENITAL_METABOLIC_DISEASES_SUCH_AS_PHENYLKETONURIA',
  CONGENITAL_OR_ACQUIRED_ORTHOPEDIC_DISORDERS_IN_CHILDREN = 'CONGENITAL_OR_ACQUIRED_ORTHOPEDIC_DISORDERS_IN_CHILDREN',
  CONGENITAL_VERTEBRAL_FUSION = 'CONGENITAL_VERTEBRAL_FUSION',
  CONGESTIVE_HEART_FAILURE = 'CONGESTIVE_HEART_FAILURE',
  CONJUNCTIVITIS = 'CONJUNCTIVITIS',
  CONN_S_SYNDROME_PRIMARY_HYPERALDOSTERONISM = 'CONN_S_SYNDROME_PRIMARY_HYPERALDOSTERONISM',
  CONSERVATIVE_SURGERY_OF_THE_OVARY = 'CONSERVATIVE_SURGERY_OF_THE_OVARY',
  CONTACT_DERMATITIS = 'CONTACT_DERMATITIS',
  CONTACT_DERMATITIS_FROM_PLANTS_E_G_NETTLE = 'CONTACT_DERMATITIS_FROM_PLANTS_E_G_NETTLE',
  CONTRACTURES = 'CONTRACTURES',
  CONTUSION = 'CONTUSION',
  CONTUSIONS = 'CONTUSIONS',
  CONVERSION_DISORDER = 'CONVERSION_DISORDER',
  CORNEAL_EDEMA = 'CORNEAL_EDEMA',
  CORNEAL_LEUKOMA = 'CORNEAL_LEUKOMA',
  CORNELIA_DE_LANGE_SYNDROME = 'CORNELIA_DE_LANGE_SYNDROME',
  CORONARY_ARTERY_ABNORMALITIES = 'CORONARY_ARTERY_ABNORMALITIES',
  CORONARY_ARTERY_DISEASE = 'CORONARY_ARTERY_DISEASE',
  CORONAVIRUS_SARS_COV_2_RESPONSIBLE_FOR_COVID_19 = 'CORONAVIRUS_SARS_COV_2_RESPONSIBLE_FOR_COVID_19',
  COUGH = 'COUGH',
  COWDEN_SYNDROME = 'COWDEN_SYNDROME',
  CRAMPS = 'CRAMPS',
  CRANEOSYNOSTOSIS = 'CRANEOSYNOSTOSIS',
  CRANIAL_BONE_METASTASIS = 'CRANIAL_BONE_METASTASIS',
  CRANIAL_MALFORMATIONS = 'CRANIAL_MALFORMATIONS',
  CRANIAL_NEUROPATHY = 'CRANIAL_NEUROPATHY',
  CRANIOPHARYNGIOMA = 'CRANIOPHARYNGIOMA',
  CRANIOSYNOSTOSIS = 'CRANIOSYNOSTOSIS',
  CREUTZFELDT_JAKOB_DISEASE = 'CREUTZFELDT_JAKOB_DISEASE',
  CRIBRIFORM_CELL_CARCINOMA_OF_THE_PANCREAS = 'CRIBRIFORM_CELL_CARCINOMA_OF_THE_PANCREAS',
  CRIMEAN_CONGO_HEMORRHAGIC_FEVER = 'CRIMEAN_CONGO_HEMORRHAGIC_FEVER',
  CRIMEAN_CONGO_VIRUS = 'CRIMEAN_CONGO_VIRUS',
  CRI_DU_CHAT_SYNDROME = 'CRI_DU_CHAT_SYNDROME',
  CRI_DU_CHAT_SYNDROME_5P_DELETION = 'CRI_DU_CHAT_SYNDROME_5P_DELETION',
  CROHN_S_DISEASE = 'CROHN_S_DISEASE',
  CROHN_S_DISEASE_CROHN_S_DISEASE = 'CROHN_S_DISEASE_CROHN_S_DISEASE',
  CROHN_S_DISEASE_VARIANT_2 = 'CROHN_S_DISEASE_VARIANT_2',
  CRONKHITE_CANADA_SYNDROME = 'CRONKHITE_CANADA_SYNDROME',
  CROUZON_SYNDROME = 'CROUZON_SYNDROME',
  CRUCIATE_LIGAMENT_INJURY = 'CRUCIATE_LIGAMENT_INJURY',
  CRYOGLOBULINEMIC_VASCULITIS = 'CRYOGLOBULINEMIC_VASCULITIS',
  CRYPTOCOCCOSIS = 'CRYPTOCOCCOSIS',
  CRYPTORCHIDIA_UNDESCENDED_TESTES = 'CRYPTORCHIDIA_UNDESCENDED_TESTES',
  CRYPTOSPORIDIOSIS = 'CRYPTOSPORIDIOSIS',
  CRYPTOSPORIDIUM_INFECTIONS = 'CRYPTOSPORIDIUM_INFECTIONS',
  CUBIC_CELL_CARCINOMA_OF_THE_PANCREAS = 'CUBIC_CELL_CARCINOMA_OF_THE_PANCREAS',
  CUBITAL_TUNNEL_SYNDROME = 'CUBITAL_TUNNEL_SYNDROME',
  CUSHING_S_DISEASE = 'CUSHING_S_DISEASE',
  CUSHING_S_SYNDROME = 'CUSHING_S_SYNDROME',
  CUSHING_S_SYNDROME_HYPERALDOSTERONISM = 'CUSHING_S_SYNDROME_HYPERALDOSTERONISM',
  CUTANEOUS_LARVA_MIGRANS_ANISAKIASIS = 'CUTANEOUS_LARVA_MIGRANS_ANISAKIASIS',
  CUTANEOUS_LEISHMANIASIS = 'CUTANEOUS_LEISHMANIASIS',
  CUTANEOUS_LYMPHOMA = 'CUTANEOUS_LYMPHOMA',
  CUTANEOUS_MASTOCYTOSIS = 'CUTANEOUS_MASTOCYTOSIS',
  CUTANEOUS_PSORIASIS = 'CUTANEOUS_PSORIASIS',
  CUTANEOUS_T_CELL_LYMPHOMA = 'CUTANEOUS_T_CELL_LYMPHOMA',
  CUTE_CONSIDERED_THE_LARGEST_ORGAN = 'CUTE_CONSIDERED_THE_LARGEST_ORGAN',
  CUT_OR_LACERATION_WOUNDS = 'CUT_OR_LACERATION_WOUNDS',
  CYCLOTHYMIA = 'CYCLOTHYMIA',
  CYCLOTHYMIC_MOOD_DISORDER = 'CYCLOTHYMIC_MOOD_DISORDER',
  CYPHOSIS = 'CYPHOSIS',
  CYSTICERCOSIS = 'CYSTICERCOSIS',
  CYSTIC_FIBROSIS = 'CYSTIC_FIBROSIS',
  CYSTIC_LYMPHANGIOMA = 'CYSTIC_LYMPHANGIOMA',
  CYSTITIS = 'CYSTITIS',
  CYSTS = 'CYSTS',
  CYTOMEGALOVIRUS_CMV_INFECTIONS = 'CYTOMEGALOVIRUS_CMV_INFECTIONS',
  DANDY_WALKER_MALFORMATION = 'DANDY_WALKER_MALFORMATION',
  DEAFNESS = 'DEAFNESS',
  DEEP_FINGER_FLEXOR_MUSCLE_SYNDROME = 'DEEP_FINGER_FLEXOR_MUSCLE_SYNDROME',
  DEEP_VEIN_THROMBOSIS_DVT = 'DEEP_VEIN_THROMBOSIS_DVT',
  DEEP_VENOUS_THROMBOSIS = 'DEEP_VENOUS_THROMBOSIS',
  DEFECTS_IN_VERBAL_EXPRESSION = 'DEFECTS_IN_VERBAL_EXPRESSION',
  DEFECTS_OF_SPEECH = 'DEFECTS_OF_SPEECH',
  DEFECTS_OR_PATHOLOGIES_OF_THE_FACE = 'DEFECTS_OR_PATHOLOGIES_OF_THE_FACE',
  DEFORMATION_OF_HANDS = 'DEFORMATION_OF_HANDS',
  DEGENERATION_OF_SKIN_MOLES = 'DEGENERATION_OF_SKIN_MOLES',
  DELUSIONAL_DISORDER = 'DELUSIONAL_DISORDER',
  DEMENTIA = 'DEMENTIA',
  DENGUE = 'DENGUE',
  DENGUE_FEVER_VIRUS = 'DENGUE_FEVER_VIRUS',
  DENTAL_CANKER_SORE = 'DENTAL_CANKER_SORE',
  DENTAL_IMPLANTS = 'DENTAL_IMPLANTS',
  DENTAL_MALOCCLUSIONS = 'DENTAL_MALOCCLUSIONS',
  DEPENDENT_PERSONALITY_DISORDER = 'DEPENDENT_PERSONALITY_DISORDER',
  DEPENDENT_PERSONALITY_DISORDER_VARIANT_2 = 'DEPENDENT_PERSONALITY_DISORDER_VARIANT_2',
  DEPRESSION = 'DEPRESSION',
  DEPRESSIVE_DISORDER = 'DEPRESSIVE_DISORDER',
  DERCUM_S_SYNDROME_ADIPOSALGIA = 'DERCUM_S_SYNDROME_ADIPOSALGIA',
  DERMATITIS = 'DERMATITIS',
  DERMATITIS_HERPETIFORMIS = 'DERMATITIS_HERPETIFORMIS',
  DERMATOFIBROSARCOMA_PROTUBERANS = 'DERMATOFIBROSARCOMA_PROTUBERANS',
  DERMATOMYOSITIS = 'DERMATOMYOSITIS',
  DERMOIDS_AND_TERATOMAS = 'DERMOIDS_AND_TERATOMAS',
  DERMOID_CYST = 'DERMOID_CYST',
  DESCENDING_COLON_CANCER = 'DESCENDING_COLON_CANCER',
  DESCENDING_COLON_CARCINOMA = 'DESCENDING_COLON_CARCINOMA',
  DESMOID_TUMORS = 'DESMOID_TUMORS',
  DE_QUERVAIN_S_SYNDROME = 'DE_QUERVAIN_S_SYNDROME',
  DE_QUERVAIN_S_TENDINOPATHY = 'DE_QUERVAIN_S_TENDINOPATHY',
  DIABETES = 'DIABETES',
  DIABETES_MELLITUS = 'DIABETES_MELLITUS',
  DIABETES_MELLITUS_RELATED_TO_CONTROL_AND_MANAGEMENT_OF_CARBOHYDRATE_INTAKE = 'DIABETES_MELLITUS_RELATED_TO_CONTROL_AND_MANAGEMENT_OF_CARBOHYDRATE_INTAKE',
  DIABETES_MELLITUS_TYPE_1 = 'DIABETES_MELLITUS_TYPE_1',
  DIABETES_MELLITUS_TYPE_2 = 'DIABETES_MELLITUS_TYPE_2',
  DIABETES_OF_THE_ELDERLY = 'DIABETES_OF_THE_ELDERLY',
  DIABETIC_NEPHROPATHY = 'DIABETIC_NEPHROPATHY',
  DIABETIC_NEUROPATHY = 'DIABETIC_NEUROPATHY',
  DIABETIC_POLYNEUROPATHY = 'DIABETIC_POLYNEUROPATHY',
  DIABETIC_RETINOPATHY = 'DIABETIC_RETINOPATHY',
  DIABETIC_ULCERS = 'DIABETIC_ULCERS',
  DIALYSIS = 'DIALYSIS',
  DIAPHRAGMATIC_TRAUMA = 'DIAPHRAGMATIC_TRAUMA',
  DIARRHEA = 'DIARRHEA',
  DIARRHEA_UNFORMED_STOOLS = 'DIARRHEA_UNFORMED_STOOLS',
  DIDELPHUS_LIKE_UTERUS_SYNDROME = 'DIDELPHUS_LIKE_UTERUS_SYNDROME',
  DIDELPHUS_UTERI_SYNDROME = 'DIDELPHUS_UTERI_SYNDROME',
  DIFFICULTIES_IN_UNDERSTANDING = 'DIFFICULTIES_IN_UNDERSTANDING',
  DIFFICULTY_IN_BREATHING = 'DIFFICULTY_IN_BREATHING',
  DIFFICULTY_IN_BREATHING_VARIANT_2 = 'DIFFICULTY_IN_BREATHING_VARIANT_2',
  DIFFICULTY_IN_SPEAKING = 'DIFFICULTY_IN_SPEAKING',
  DIFFICULTY_IN_VERBAL_EXPRESSION = 'DIFFICULTY_IN_VERBAL_EXPRESSION',
  DIFFICULTY_STANDING = 'DIFFICULTY_STANDING',
  DIFFICULTY_SWALLOWING = 'DIFFICULTY_SWALLOWING',
  DIGEORGE_SYNDROME = 'DIGEORGE_SYNDROME',
  DIGEORGE_SYNDROME_22Q_DELETION = 'DIGEORGE_SYNDROME_22Q_DELETION',
  DILATED_MYOCARDIOPATHY = 'DILATED_MYOCARDIOPATHY',
  DIPHTHERIA = 'DIPHTHERIA',
  DISABILITIES_DUE_TO_CONGENITAL_OR_ACQUIRED_NEUROMUSCULAR_DISEASES = 'DISABILITIES_DUE_TO_CONGENITAL_OR_ACQUIRED_NEUROMUSCULAR_DISEASES',
  DISEASES_OF_CHILDHOOD = 'DISEASES_OF_CHILDHOOD',
  DISEASES_OF_PERIPHERAL_ARTERIES = 'DISEASES_OF_PERIPHERAL_ARTERIES',
  DISEASES_OF_THE_ADRENAL_GLANDS_E_G = 'DISEASES_OF_THE_ADRENAL_GLANDS_E_G',
  DISEASES_OF_THE_AUTONOMIC_NERVOUS_SYSTEM_SUCH_AS_CHRONIC_FATIGUE_SYNDROME = 'DISEASES_OF_THE_AUTONOMIC_NERVOUS_SYSTEM_SUCH_AS_CHRONIC_FATIGUE_SYNDROME',
  DISEASES_OF_THE_DIAPHRAGM = 'DISEASES_OF_THE_DIAPHRAGM',
  DISEASES_OF_THE_GONADS_E_G = 'DISEASES_OF_THE_GONADS_E_G',
  DISEASES_OF_THE_MALE_MAMMARY_GLAND = 'DISEASES_OF_THE_MALE_MAMMARY_GLAND',
  DISEASES_OF_THE_PITUITARY_GLAND_E_G = 'DISEASES_OF_THE_PITUITARY_GLAND_E_G',
  DISLOCATION = 'DISLOCATION',
  DISLOCATION_OF_THE_MANDIBLE = 'DISLOCATION_OF_THE_MANDIBLE',
  DISORDERS_AFFECTING_THE_SMALL_INTESTINE = 'DISORDERS_AFFECTING_THE_SMALL_INTESTINE',
  DISORDERS_OF_CARBOHYDRATE_METABOLISM_SUCH_AS_PHENYLKETONURIA = 'DISORDERS_OF_CARBOHYDRATE_METABOLISM_SUCH_AS_PHENYLKETONURIA',
  DISORDERS_OF_CEREBELLAR_FUNCTION = 'DISORDERS_OF_CEREBELLAR_FUNCTION',
  DISORDERS_OF_LIPID_METABOLISM_SUCH_AS_FAMILIAL_HYPERCHOLESTEROLEMIA = 'DISORDERS_OF_LIPID_METABOLISM_SUCH_AS_FAMILIAL_HYPERCHOLESTEROLEMIA',
  DISORDERS_OF_MOTOR_COORDINATION_SUCH_AS_ATAXIA = 'DISORDERS_OF_MOTOR_COORDINATION_SUCH_AS_ATAXIA',
  DISORDERS_OF_MUSCLE_GROWTH_AND_DEVELOPMENT = 'DISORDERS_OF_MUSCLE_GROWTH_AND_DEVELOPMENT',
  DISORDERS_OF_MUSCLE_TONE_HYPOTONIA_OR_HYPERTONIA = 'DISORDERS_OF_MUSCLE_TONE_HYPOTONIA_OR_HYPERTONIA',
  DISORDERS_OF_PAIN_PERCEPTION = 'DISORDERS_OF_PAIN_PERCEPTION',
  DISORDERS_OF_THE_AUTONOMIC_NERVOUS_SYSTEM = 'DISORDERS_OF_THE_AUTONOMIC_NERVOUS_SYSTEM',
  DISORDERS_OF_THE_VESTIBULAR_SYSTEM_SUCH_AS_VESTIBULAR_NEURITIS = 'DISORDERS_OF_THE_VESTIBULAR_SYSTEM_SUCH_AS_VESTIBULAR_NEURITIS',
  DISORDERS_OF_THINKING_AND_REASONING = 'DISORDERS_OF_THINKING_AND_REASONING',
  DISORDER_OF_MOTOR_COORDINATION_DCD = 'DISORDER_OF_MOTOR_COORDINATION_DCD',
  DISORDER_OF_SPEECH_RHYTHM_AND_PROSODY = 'DISORDER_OF_SPEECH_RHYTHM_AND_PROSODY',
  DISSEMINATED_LEISHMANIASIS = 'DISSEMINATED_LEISHMANIASIS',
  DISSEMINATED_LUPUS_ERYTHEMATOSUS = 'DISSEMINATED_LUPUS_ERYTHEMATOSUS',
  DISSOCIATION = 'DISSOCIATION',
  DISSOCIATIVE_DISORDERS = 'DISSOCIATIVE_DISORDERS',
  DISSOCIATIVE_DISORDER_OF_CHILDHOOD = 'DISSOCIATIVE_DISORDER_OF_CHILDHOOD',
  DISSOCIATIVE_IDENTITY_DISORDER_DID = 'DISSOCIATIVE_IDENTITY_DISORDER_DID',
  DISSOCIATIVE_IDENTITY_DISORDER_DID_VARIANT_2 = 'DISSOCIATIVE_IDENTITY_DISORDER_DID_VARIANT_2',
  DISTICHIASIS = 'DISTICHIASIS',
  DISTORTION = 'DISTORTION',
  DISTORTIONS = 'DISTORTIONS',
  DIVERTICULAR_DISEASE = 'DIVERTICULAR_DISEASE',
  DIVERTICULAR_DISEASE_OF_THE_COLON = 'DIVERTICULAR_DISEASE_OF_THE_COLON',
  DIVERTICULITIS = 'DIVERTICULITIS',
  DIVERTICULITIS_DISEASE = 'DIVERTICULITIS_DISEASE',
  DIVERTICULOSIS = 'DIVERTICULOSIS',
  DOMESTIC_RAT = 'DOMESTIC_RAT',
  DOUBLE_VISION = 'DOUBLE_VISION',
  DOWN_SYNDROME = 'DOWN_SYNDROME',
  DOWN_SYNDROME_TRISOMY_21 = 'DOWN_SYNDROME_TRISOMY_21',
  DOWN_SYNDROME_WITH_CARDIAC_INVOLVEMENT = 'DOWN_SYNDROME_WITH_CARDIAC_INVOLVEMENT',
  DRAVET_SYNDROME = 'DRAVET_SYNDROME',
  DRESSLER_S_SYNDROME = 'DRESSLER_S_SYNDROME',
  DRUG_ALLERGY = 'DRUG_ALLERGY',
  DRUG_HYPERSENSITIVITY_SYNDROME_E_G_ANTIBIOTICS = 'DRUG_HYPERSENSITIVITY_SYNDROME_E_G_ANTIBIOTICS',
  DRUG_RELATED_NEPHROPATHY = 'DRUG_RELATED_NEPHROPATHY',
  DRY_AND_ITCHY_SKIN_XEROSIS = 'DRY_AND_ITCHY_SKIN_XEROSIS',
  DRY_EYE = 'DRY_EYE',
  DRY_EYES = 'DRY_EYES',
  DUBOWITZ_SYNDROME = 'DUBOWITZ_SYNDROME',
  DUCHENNE_MUSCULAR_DYSTROPHY = 'DUCHENNE_MUSCULAR_DYSTROPHY',
  DUCHENNE_SYNDROME_MUSCULAR_DYSTROPHY = 'DUCHENNE_SYNDROME_MUSCULAR_DYSTROPHY',
  DUMPING_SYNDROME = 'DUMPING_SYNDROME',
  DUODENAL_CANCER = 'DUODENAL_CANCER',
  DUODENOGASTRIC_REFLUX = 'DUODENOGASTRIC_REFLUX',
  DUPUYTREN_S_CONTRACTURE = 'DUPUYTREN_S_CONTRACTURE',
  DUPUYTREN_S_DISEASE = 'DUPUYTREN_S_DISEASE',
  DUPUYTREN_S_DISEASE_HAND_CONTRACTURE = 'DUPUYTREN_S_DISEASE_HAND_CONTRACTURE',
  DUST_MITE_ALLERGY = 'DUST_MITE_ALLERGY',
  DYSARTHRIA = 'DYSARTHRIA',
  DYSARTHRIA_DIFFICULTY_IN_MUSCLE_CONTROL_FOR_SPEECH_PRODUCTION = 'DYSARTHRIA_DIFFICULTY_IN_MUSCLE_CONTROL_FOR_SPEECH_PRODUCTION',
  DYSHIDROTIC_ECZEMA = 'DYSHIDROTIC_ECZEMA',
  DYSLALIA_DIFFICULTY_IN_THE_CORRECT_PRODUCTION_OF_SPEECH_SOUNDS = 'DYSLALIA_DIFFICULTY_IN_THE_CORRECT_PRODUCTION_OF_SPEECH_SOUNDS',
  DYSLIPIDEMIA_HIGH_LEVELS_OF_FAT_IN_THE_BLOOD = 'DYSLIPIDEMIA_HIGH_LEVELS_OF_FAT_IN_THE_BLOOD',
  DYSMENORRHEA_PAINFUL_MENSTRUATION = 'DYSMENORRHEA_PAINFUL_MENSTRUATION',
  DYSPAREUNIA_PAIN_DURING_SEXUAL_INTERCOURSE = 'DYSPAREUNIA_PAIN_DURING_SEXUAL_INTERCOURSE',
  DYSPEPSIA = 'DYSPEPSIA',
  DYSPHAGIA = 'DYSPHAGIA',
  DYSPHAGIA_DIFFICULTY_SWALLOWING = 'DYSPHAGIA_DIFFICULTY_SWALLOWING',
  DYSPHONIA = 'DYSPHONIA',
  DYSPHONIA_ALTERATIONS_IN_VOICE_QUALITY_AND_PRODUCTION = 'DYSPHONIA_ALTERATIONS_IN_VOICE_QUALITY_AND_PRODUCTION',
  DYSPLASTIC_MELANOCYTIC_NEVUS = 'DYSPLASTIC_MELANOCYTIC_NEVUS',
  DYSPLASTIC_NEVUS = 'DYSPLASTIC_NEVUS',
  DYSTHYMIA_PERSISTENT_DEPRESSIVE_DISORDER = 'DYSTHYMIA_PERSISTENT_DEPRESSIVE_DISORDER',
  DYSTONIA = 'DYSTONIA',
  EAGLE_SYNDROME = 'EAGLE_SYNDROME',
  EAGLE_S_SYNDROME_CALCIFICATION_OF_THE_STYLOID = 'EAGLE_S_SYNDROME_CALCIFICATION_OF_THE_STYLOID',
  EARS = 'EARS',
  EAR_INFECTIONS_OTITIS = 'EAR_INFECTIONS_OTITIS',
  EAR_INFECTIONS_OTITIS_VARIANT_2 = 'EAR_INFECTIONS_OTITIS_VARIANT_2',
  EASTERN_EQUINE_ENCEPHALITIS = 'EASTERN_EQUINE_ENCEPHALITIS',
  EASTERN_EQUINE_ENCEPHALITIS_VIRUS = 'EASTERN_EQUINE_ENCEPHALITIS_VIRUS',
  EAST_NILE_FEVER_VIRUS = 'EAST_NILE_FEVER_VIRUS',
  EATING_BEHAVIOR_DISORDERS_NOT_OTHERWISE_SPECIFIED_EDNOS = 'EATING_BEHAVIOR_DISORDERS_NOT_OTHERWISE_SPECIFIED_EDNOS',
  EATING_DISORDERS_ANOREXIA_BULIMIA = 'EATING_DISORDERS_ANOREXIA_BULIMIA',
  EATING_DISORDERS_ANOREXIA_NERVOSA_BULIMIA_NERVOSA_BINGE_EATING_DISORDER = 'EATING_DISORDERS_ANOREXIA_NERVOSA_BULIMIA_NERVOSA_BINGE_EATING_DISORDER',
  EATING_DISORDERS_SUCH_AS_ANOREXIA = 'EATING_DISORDERS_SUCH_AS_ANOREXIA',
  EATING_DISORDER_SUCH_AS_ANOREXIA_AND_BULIMIA = 'EATING_DISORDER_SUCH_AS_ANOREXIA_AND_BULIMIA',
  EATING_DISORDER_SUCH_AS_ANOREXIA_NERVOSA_AND_BULIMIA_NERVOSA = 'EATING_DISORDER_SUCH_AS_ANOREXIA_NERVOSA_AND_BULIMIA_NERVOSA',
  EBOLA = 'EBOLA',
  EBOLA_HEMORRHAGIC_FEVER = 'EBOLA_HEMORRHAGIC_FEVER',
  EBSTEIN_S_ANOMALY = 'EBSTEIN_S_ANOMALY',
  ECHINOCOCCOSIS = 'ECHINOCOCCOSIS',
  ECTOPIC_POLYIMASTIA = 'ECTOPIC_POLYIMASTIA',
  ECTOPIC_PREGNANCY = 'ECTOPIC_PREGNANCY',
  ECZEMA = 'ECZEMA',
  ECZEMAS = 'ECZEMAS',
  EDWARDS_SYNDROME = 'EDWARDS_SYNDROME',
  EDWARDS_SYNDROME_TRISOMY_18 = 'EDWARDS_SYNDROME_TRISOMY_18',
  EHLERS_DANLOS_SYNDROME = 'EHLERS_DANLOS_SYNDROME',
  EISENMENGER_S_SYNDROME = 'EISENMENGER_S_SYNDROME',
  ELBOW_DISORDERS = 'ELBOW_DISORDERS',
  ELBOW_PAIN_SUCH_AS_LATERAL_EPICONDYLITIS_OR_TENNIS_ELBOW = 'ELBOW_PAIN_SUCH_AS_LATERAL_EPICONDYLITIS_OR_TENNIS_ELBOW',
  ELIMINATION_DISORDER_ENURESIS_AND_ENCOPRESIS = 'ELIMINATION_DISORDER_ENURESIS_AND_ENCOPRESIS',
  ELLIS_VAN_CREVELD_SYNDROME = 'ELLIS_VAN_CREVELD_SYNDROME',
  EMBRYONIC_STEM_CELL_TUMOR = 'EMBRYONIC_STEM_CELL_TUMOR',
  EMERGENCY_CONTRACEPTIVES_MORNING_AFTER_PILL = 'EMERGENCY_CONTRACEPTIVES_MORNING_AFTER_PILL',
  EMERY_DREIFUSS_MUSCULAR_DYSTROPHY = 'EMERY_DREIFUSS_MUSCULAR_DYSTROPHY',
  ENCEPHALITIS = 'ENCEPHALITIS',
  ENCONDROMA = 'ENCONDROMA',
  ENDOCARDITIS = 'ENDOCARDITIS',
  ENDOCRINE_CELL_CARCINOMA_OF_THE_PANCREAS = 'ENDOCRINE_CELL_CARCINOMA_OF_THE_PANCREAS',
  ENDOMETRIAL_CANCER = 'ENDOMETRIAL_CANCER',
  ENDOMETRIAL_CARCINOMA = 'ENDOMETRIAL_CARCINOMA',
  ENDOMETRIAL_HYPERPLASIA = 'ENDOMETRIAL_HYPERPLASIA',
  ENDOMETRIOSIS = 'ENDOMETRIOSIS',
  ENTEROBIASIS_OXYURIASIS = 'ENTEROBIASIS_OXYURIASIS',
  EPENDYMOMA = 'EPENDYMOMA',
  EPIDERMAL_ORGANOID_NEVUS = 'EPIDERMAL_ORGANOID_NEVUS',
  EPIDERMOID_CYST = 'EPIDERMOID_CYST',
  EPIDIDYMITIS = 'EPIDIDYMITIS',
  EPIDURAL_HEMATOMA = 'EPIDURAL_HEMATOMA',
  EPIESCLERITE = 'EPIESCLERITE',
  EPILEPSY = 'EPILEPSY',
  EPISIOTOMY = 'EPISIOTOMY',
  EPISODIC_ATAXIA_EA = 'EPISODIC_ATAXIA_EA',
  EPITHELIAL_CELL_CARCINOMA_OF_THE_PANCREAS = 'EPITHELIAL_CELL_CARCINOMA_OF_THE_PANCREAS',
  ERDHEIM_CHESTER_SYNDROME = 'ERDHEIM_CHESTER_SYNDROME',
  ERECTILE_DYSFUNCTION = 'ERECTILE_DYSFUNCTION',
  ERECTOR_SPINA_MUSCLE_SYNDROME = 'ERECTOR_SPINA_MUSCLE_SYNDROME',
  ERNIE = 'ERNIE',
  ERYTHEMA = 'ERYTHEMA',
  ERYTHEMA_MULTIFORME = 'ERYTHEMA_MULTIFORME',
  ERYTHEMA_NODOSUM = 'ERYTHEMA_NODOSUM',
  ERYTHRASMA = 'ERYTHRASMA',
  ERYTHROBLASTOPENIA = 'ERYTHROBLASTOPENIA',
  ESOPHAGEAL_ATRESIA_TRACHEO_ESOPHAGEAL_FISTULA = 'ESOPHAGEAL_ATRESIA_TRACHEO_ESOPHAGEAL_FISTULA',
  ESOPHAGEAL_CANCER = 'ESOPHAGEAL_CANCER',
  ESOPHAGEAL_CANCER_VARIANT_2 = 'ESOPHAGEAL_CANCER_VARIANT_2',
  ESOPHAGEAL_CARCINOMA = 'ESOPHAGEAL_CARCINOMA',
  ESOPHAGEAL_CYST = 'ESOPHAGEAL_CYST',
  ESOPHAGEAL_DIVERTICULOSIS = 'ESOPHAGEAL_DIVERTICULOSIS',
  ESOPHAGEAL_LEIOMYOMA = 'ESOPHAGEAL_LEIOMYOMA',
  ESOPHAGEAL_POLYP = 'ESOPHAGEAL_POLYP',
  ESOPHAGEAL_STENOSIS = 'ESOPHAGEAL_STENOSIS',
  ESOPHAGEAL_VARICES = 'ESOPHAGEAL_VARICES',
  ESOPHAGITIS = 'ESOPHAGITIS',
  ESOPHAGUS = 'ESOPHAGUS',
  ESSENTIAL_THROMBOCYTOSIS = 'ESSENTIAL_THROMBOCYTOSIS',
  ESSENTIAL_TREMOR = 'ESSENTIAL_TREMOR',
  EVANS_SYNDROME = 'EVANS_SYNDROME',
  EVANS_SYNDROME_WITH_THROMBOCYTOPENIA = 'EVANS_SYNDROME_WITH_THROMBOCYTOPENIA',
  EWING_S_SARCOMA = 'EWING_S_SARCOMA',
  EXANTHEM = 'EXANTHEM',
  EXCESSIVE_TEARING = 'EXCESSIVE_TEARING',
  EXERCISE_CONTRACTURE = 'EXERCISE_CONTRACTURE',
  EXPRESSIVE_LANGUAGE_DISORDERS_DIFFICULTIES_IN_LANGUAGE_PRODUCTION_AND_ORGANIZATION = 'EXPRESSIVE_LANGUAGE_DISORDERS_DIFFICULTIES_IN_LANGUAGE_PRODUCTION_AND_ORGANIZATION',
  EXPRESSIVE_LANGUAGE_DISORDER_ELD = 'EXPRESSIVE_LANGUAGE_DISORDER_ELD',
  EXTRABISM = 'EXTRABISM',
  EXTRAMEDULLARY_CYST = 'EXTRAMEDULLARY_CYST',
  EXTRAMEDULLARY_HEMORRHAGE = 'EXTRAMEDULLARY_HEMORRHAGE',
  EXTRAUTERINE_PREGNANCY = 'EXTRAUTERINE_PREGNANCY',
  EXTRINSIC_ALLERGIC_ALVEOLITIS = 'EXTRINSIC_ALLERGIC_ALVEOLITIS',
  EYELID_PROBLEMS = 'EYELID_PROBLEMS',
  EYES = 'EYES',
  EYE_INFECTIONS_CONJUNCTIVITIS = 'EYE_INFECTIONS_CONJUNCTIVITIS',
  EYE_PROBLEMS = 'EYE_PROBLEMS',
  EZIOPSI = 'EZIOPSI',
  E_COLI_INFECTIONS = 'E_COLI_INFECTIONS',
  FABRY_DISEASE = 'FABRY_DISEASE',
  FACIAL_CANCER_TUMORS = 'FACIAL_CANCER_TUMORS',
  FACIAL_FRACTURES = 'FACIAL_FRACTURES',
  FACIO_SCAPULOHUMERAL_DYSTROPHY_FSHD = 'FACIO_SCAPULOHUMERAL_DYSTROPHY_FSHD',
  FALLOPIAN_TUBE_BLOCKAGE = 'FALLOPIAN_TUBE_BLOCKAGE',
  FALLOPIAN_TUBE_CANCER = 'FALLOPIAN_TUBE_CANCER',
  FALLOPIAN_TUBE_CANCER_VARIANT_2 = 'FALLOPIAN_TUBE_CANCER_VARIANT_2',
  FALLOPIAN_TUBE_CANCER_VARIANT_3 = 'FALLOPIAN_TUBE_CANCER_VARIANT_3',
  FAMILIAL_ADENOMATOUS_POLYPOSIS_FAP = 'FAMILIAL_ADENOMATOUS_POLYPOSIS_FAP',
  FAMILIAL_MEDITERRANEAN_FEVER_FMF_SYNDROME = 'FAMILIAL_MEDITERRANEAN_FEVER_FMF_SYNDROME',
  FAMILIAL_MYELODYSPLASIA = 'FAMILIAL_MYELODYSPLASIA',
  FAMILISM = 'FAMILISM',
  FANCONI_SYNDROME = 'FANCONI_SYNDROME',
  FARBER_SYNDROME = 'FARBER_SYNDROME',
  FASCIOLIASIS = 'FASCIOLIASIS',
  FATIGUE_AND_TIREDNESS = 'FATIGUE_AND_TIREDNESS',
  FAT_EMBOLISM = 'FAT_EMBOLISM',
  FEARS = 'FEARS',
  FEAR_FEARS = 'FEAR_FEARS',
  FECAL_INCONTINENCE = 'FECAL_INCONTINENCE',
  FECAL_INCONTINENCE_DURING_PREGNANCY = 'FECAL_INCONTINENCE_DURING_PREGNANCY',
  FEEDING_DISORDERS = 'FEEDING_DISORDERS',
  FELTY_S_SYNDROME = 'FELTY_S_SYNDROME',
  FEMALE_INFERTILITY = 'FEMALE_INFERTILITY',
  FEMALE_SEXUAL_DYSFUNCTION = 'FEMALE_SEXUAL_DYSFUNCTION',
  FEMALE_URINARY_INCONTINENCE = 'FEMALE_URINARY_INCONTINENCE',
  FEMORAL_ARTERY_ANEURYSM = 'FEMORAL_ARTERY_ANEURYSM',
  FEMORAL_ARTERY_STENOSIS = 'FEMORAL_ARTERY_STENOSIS',
  FEMUR_FRACTURE = 'FEMUR_FRACTURE',
  FIBROADENOMA = 'FIBROADENOMA',
  FIBROMA = 'FIBROMA',
  FIBROMUSCOLODYSPLASIA = 'FIBROMUSCOLODYSPLASIA',
  FIBROMYALGIA = 'FIBROMYALGIA',
  FIEBRE_PORCINA_CL_SICA_CLASSIC_SWINE_FEVER = 'FIEBRE_PORCINA_CL_SICA_CLASSIC_SWINE_FEVER',
  FILARIA = 'FILARIA',
  FINGER_SNAP = 'FINGER_SNAP',
  FISTULAS_AND_ABSCESSES = 'FISTULAS_AND_ABSCESSES',
  FLUENCY_DISORDER_STUTTERING = 'FLUENCY_DISORDER_STUTTERING',
  FOCAL_AND_SEGMENTAL_GLOMERULOSCLEROSIS_FSGS = 'FOCAL_AND_SEGMENTAL_GLOMERULOSCLEROSIS_FSGS',
  FOCAL_CORTICAL_DYSPLASIA = 'FOCAL_CORTICAL_DYSPLASIA',
  FOLLICULAR_CELL_CARCINOMA_OF_THE_PANCREAS = 'FOLLICULAR_CELL_CARCINOMA_OF_THE_PANCREAS',
  FOOD_ALLERGIES_SUCH_AS_NICKEL_OR_WHEAT_ALLERGY = 'FOOD_ALLERGIES_SUCH_AS_NICKEL_OR_WHEAT_ALLERGY',
  FOOD_ALLERGY = 'FOOD_ALLERGY',
  FOOD_HYPERSENSITIVITY_SYNDROME_E_G_CELIAC_DISEASE = 'FOOD_HYPERSENSITIVITY_SYNDROME_E_G_CELIAC_DISEASE',
  FOOD_INTOLERANCES = 'FOOD_INTOLERANCES',
  FOOD_INTOLERANCES_E_G_LACTOSE_INTOLERANCE = 'FOOD_INTOLERANCES_E_G_LACTOSE_INTOLERANCE',
  FOOD_POISONING = 'FOOD_POISONING',
  FOOT_DISORDERS_SUCH_AS_PLANTAR_FASCIITIS = 'FOOT_DISORDERS_SUCH_AS_PLANTAR_FASCIITIS',
  FOOT_FRACTURE = 'FOOT_FRACTURE',
  FOREIGN_BODY_INJURIES = 'FOREIGN_BODY_INJURIES',
  FOURTH_VENTRICLE_CYST = 'FOURTH_VENTRICLE_CYST',
  FRACTURE = 'FRACTURE',
  FRACTURES = 'FRACTURES',
  FRACTURES_OF_THE_FACE_OR_HEAD = 'FRACTURES_OF_THE_FACE_OR_HEAD',
  FRACTURES_OF_THE_NOSE = 'FRACTURES_OF_THE_NOSE',
  FRACTURES_OF_THE_STERNUM = 'FRACTURES_OF_THE_STERNUM',
  FRACTURE_OF_HUMERUS = 'FRACTURE_OF_HUMERUS',
  FRACTURE_OF_THE_BASE_OF_THE_SKULL = 'FRACTURE_OF_THE_BASE_OF_THE_SKULL',
  FRACTURE_OF_THE_CLAVICLE = 'FRACTURE_OF_THE_CLAVICLE',
  FRACTURE_OF_THE_CRANIAL_VAULT = 'FRACTURE_OF_THE_CRANIAL_VAULT',
  FRACTURE_OF_THE_SKULL_BASE = 'FRACTURE_OF_THE_SKULL_BASE',
  FRACTURE_OF_THE_UPPER_JAW = 'FRACTURE_OF_THE_UPPER_JAW',
  FRAGILE_X_SYNDROME = 'FRAGILE_X_SYNDROME',
  FRAMBESIA_PIAN = 'FRAMBESIA_PIAN',
  FRASER_S_SYNDROME = 'FRASER_S_SYNDROME',
  FRECKLES = 'FRECKLES',
  FREIBERG_DISEASE = 'FREIBERG_DISEASE',
  FREIBERG_SYNDROME = 'FREIBERG_SYNDROME',
  FRICTION_DERMATITIS = 'FRICTION_DERMATITIS',
  FRIEDREICH_S_ATAXIA = 'FRIEDREICH_S_ATAXIA',
  FROEHLICH_SYNDROME = 'FROEHLICH_SYNDROME',
  FRONTAL_BONE_TUMOR = 'FRONTAL_BONE_TUMOR',
  FRONTAL_BREAST_CANCER = 'FRONTAL_BREAST_CANCER',
  FROZEN_SHOULDER_SYNDROME = 'FROZEN_SHOULDER_SYNDROME',
  FUCHS_CORNEAL_DYSTROPHY = 'FUCHS_CORNEAL_DYSTROPHY',
  FUCHS_DYSTROPHY = 'FUCHS_DYSTROPHY',
  FUNCTIONAL_GASTROINTESTINAL_DISEASES = 'FUNCTIONAL_GASTROINTESTINAL_DISEASES',
  FUNCTIONAL_GASTROINTESTINAL_DISORDERS_SUCH_AS_IRRITABLE_BOWEL_SYNDROME = 'FUNCTIONAL_GASTROINTESTINAL_DISORDERS_SUCH_AS_IRRITABLE_BOWEL_SYNDROME',
  FUSIFORM_CELL_CARCINOMA_OF_THE_PANCREAS = 'FUSIFORM_CELL_CARCINOMA_OF_THE_PANCREAS',
  GAIT_DISTURBANCE = 'GAIT_DISTURBANCE',
  GALACTOSEMIA = 'GALACTOSEMIA',
  GALEAZZI_FRACTURE = 'GALEAZZI_FRACTURE',
  GALLBLADDER_DISEASES_SUCH_AS_BILIARY_STONES_AND_CHOLECYSTITIS = 'GALLBLADDER_DISEASES_SUCH_AS_BILIARY_STONES_AND_CHOLECYSTITIS',
  GALLSTONES = 'GALLSTONES',
  GALLSTONES_VARIANT_2 = 'GALLSTONES_VARIANT_2',
  GAMMA_DELTA_T_LYMPHOCYTOSIS = 'GAMMA_DELTA_T_LYMPHOCYTOSIS',
  GANGLIONEUROMA = 'GANGLIONEUROMA',
  GARDNER_S_SYNDROME = 'GARDNER_S_SYNDROME',
  GASTRIC_CARCINOMA = 'GASTRIC_CARCINOMA',
  GASTRIC_NEUROENDOCRINE_CARCINOMA = 'GASTRIC_NEUROENDOCRINE_CARCINOMA',
  GASTRIC_POLYPS = 'GASTRIC_POLYPS',
  GASTRITIS = 'GASTRITIS',
  GASTROCNEMIUS_MUSCLE_SYNDROME = 'GASTROCNEMIUS_MUSCLE_SYNDROME',
  GASTROENTERITIS = 'GASTROENTERITIS',
  GASTROESOPHAGEAL_REFLUX_DISEASE_GERD = 'GASTROESOPHAGEAL_REFLUX_DISEASE_GERD',
  GASTROESOPHAGEAL_REFLUX_DISEASE_GERD_VARIANT_2 = 'GASTROESOPHAGEAL_REFLUX_DISEASE_GERD_VARIANT_2',
  GASTROESOPHAGEAL_REFLUX_DISEASE_GERD_VARIANT_3 = 'GASTROESOPHAGEAL_REFLUX_DISEASE_GERD_VARIANT_3',
  GASTROESOPHAGEAL_REGURGITATION_ACID_REFLUX = 'GASTROESOPHAGEAL_REGURGITATION_ACID_REFLUX',
  GASTROINTESTINAL_DISEASES = 'GASTROINTESTINAL_DISEASES',
  GASTROINTESTINAL_DISORDERS_SUCH_AS_GASTROESOPHAGEAL_REFLUX_OR_IRRITABLE_BOWEL_SYNDROME_REQUIRING_DIETARY_CHANGES = 'GASTROINTESTINAL_DISORDERS_SUCH_AS_GASTROESOPHAGEAL_REFLUX_OR_IRRITABLE_BOWEL_SYNDROME_REQUIRING_DIETARY_CHANGES',
  GASTROINTESTINAL_FISTULAS = 'GASTROINTESTINAL_FISTULAS',
  GASTROINTESTINAL_NEUROENDOCRINE_TUMORS_GEP_NET = 'GASTROINTESTINAL_NEUROENDOCRINE_TUMORS_GEP_NET',
  GASTROINTESTINAL_PERFORATIONS = 'GASTROINTESTINAL_PERFORATIONS',
  GASTROINTESTINAL_STROMAL_TUMOR_GIST_OF_THE_ESOPHAGUS = 'GASTROINTESTINAL_STROMAL_TUMOR_GIST_OF_THE_ESOPHAGUS',
  GASTROINTESTINAL_TRACT_CYSTS = 'GASTROINTESTINAL_TRACT_CYSTS',
  GASTROINTESTINAL_VASCULAR_MALFORMATIONS = 'GASTROINTESTINAL_VASCULAR_MALFORMATIONS',
  GASTROPARESIS = 'GASTROPARESIS',
  GASTROSCHISI = 'GASTROSCHISI',
  GAS_EMBOLISM = 'GAS_EMBOLISM',
  GAUCHER_DISEASE = 'GAUCHER_DISEASE',
  GENERALIZED_ANXIETY_DISORDER = 'GENERALIZED_ANXIETY_DISORDER',
  GENITAL_HERPES = 'GENITAL_HERPES',
  GEOHELMINTIASIS = 'GEOHELMINTIASIS',
  GERM_CELL_TUMOR_OF_THE_TESTIS = 'GERM_CELL_TUMOR_OF_THE_TESTIS',
  GESTATIONAL_DIABETES = 'GESTATIONAL_DIABETES',
  GESTOSIS_HYPERTENSION_IN_PREGNANCY = 'GESTOSIS_HYPERTENSION_IN_PREGNANCY',
  GIANT_CELL_ARTERITIS = 'GIANT_CELL_ARTERITIS',
  GIANT_CELL_CARCINOMA_OF_THE_PANCREAS = 'GIANT_CELL_CARCINOMA_OF_THE_PANCREAS',
  GIANT_CELL_TUMOR = 'GIANT_CELL_TUMOR',
  GIANT_CONGENITAL_MELANOCYTIC_NEVUS = 'GIANT_CONGENITAL_MELANOCYTIC_NEVUS',
  GIANT_FRECKLES = 'GIANT_FRECKLES',
  GIANT_NEVUS = 'GIANT_NEVUS',
  GIARDIASIS = 'GIARDIASIS',
  GIARDIA_INFECTIONS = 'GIARDIA_INFECTIONS',
  GILL_FISTULAS = 'GILL_FISTULAS',
  GINGIVITIS = 'GINGIVITIS',
  GIST_GASTROINTESTINAL_STROMAL_TUMOR = 'GIST_GASTROINTESTINAL_STROMAL_TUMOR',
  GITELMAN_S_SYNDROME = 'GITELMAN_S_SYNDROME',
  GLANDULAR_CELL_CARCINOMA_OF_THE_PANCREAS = 'GLANDULAR_CELL_CARCINOMA_OF_THE_PANCREAS',
  GLANZMANN_S_DISEASE = 'GLANZMANN_S_DISEASE',
  GLAUCOMA = 'GLAUCOMA',
  GLENOID_LABRUM_LESION = 'GLENOID_LABRUM_LESION',
  GLIOBLASTOMA_MULTIFORME = 'GLIOBLASTOMA_MULTIFORME',
  GLIOMA = 'GLIOMA',
  GLOMERULONEPHRITIS = 'GLOMERULONEPHRITIS',
  GLOVE_GIANT_CELL_TUMOR = 'GLOVE_GIANT_CELL_TUMOR',
  GLYCOGEN_STORAGE_DISEASE = 'GLYCOGEN_STORAGE_DISEASE',
  GNATHOSTOMIASIS = 'GNATHOSTOMIASIS',
  GOLDENHAR_SYNDROME = 'GOLDENHAR_SYNDROME',
  GONARTHROSIS_OSTEOARTHRITIS_OF_THE_KNEE = 'GONARTHROSIS_OSTEOARTHRITIS_OF_THE_KNEE',
  GONORRHEA = 'GONORRHEA',
  GOODPASTURE_SYNDROME = 'GOODPASTURE_SYNDROME',
  GORLIN_GOLTZ_SYNDROME = 'GORLIN_GOLTZ_SYNDROME',
  GORLIN_S_SYNDROME = 'GORLIN_S_SYNDROME',
  GOTTA = 'GOTTA',
  GOZZO = 'GOZZO',
  GRANULAR_CELL_CARCINOMA_OF_THE_PANCREAS = 'GRANULAR_CELL_CARCINOMA_OF_THE_PANCREAS',
  GRANULAR_DYSTROPHY = 'GRANULAR_DYSTROPHY',
  GRANULAR_RENAL_CELL_CARCINOMA = 'GRANULAR_RENAL_CELL_CARCINOMA',
  GRANULOMATOUS_MASTITIS = 'GRANULOMATOUS_MASTITIS',
  GRANULOMA_ANNULARE = 'GRANULOMA_ANNULARE',
  GRANULOSA_CELL_CARCINOMA = 'GRANULOSA_CELL_CARCINOMA',
  GRANULOSA_CELL_CARCINOMA_OF_THE_PANCREAS = 'GRANULOSA_CELL_CARCINOMA_OF_THE_PANCREAS',
  GRAVES_BASEDOW_S_THYROIDITIS = 'GRAVES_BASEDOW_S_THYROIDITIS',
  GRAVES_DISEASE_HYPERTHYROIDISM = 'GRAVES_DISEASE_HYPERTHYROIDISM',
  GREASING = 'GREASING',
  GREAT_BACK_MUSCLE_SYNDROME = 'GREAT_BACK_MUSCLE_SYNDROME',
  GREAT_GLUTEAL_MUSCLE_SYNDROME = 'GREAT_GLUTEAL_MUSCLE_SYNDROME',
  GREAT_ROUND_SYNDROME = 'GREAT_ROUND_SYNDROME',
  GROWTH_DEFECTS = 'GROWTH_DEFECTS',
  GROWTH_PROBLEMS = 'GROWTH_PROBLEMS',
  GUILLAIN_BARR_DISEASE = 'GUILLAIN_BARR_DISEASE',
  GUILLAIN_BARR_SYNDROME = 'GUILLAIN_BARR_SYNDROME',
  GUM_PROBLEMS = 'GUM_PROBLEMS',
  GURGLING_TAPEWORM = 'GURGLING_TAPEWORM',
  GUTTA_ENDOTHELIAL_DYSTROPHY = 'GUTTA_ENDOTHELIAL_DYSTROPHY',
  GUYON_S_SYNDROME = 'GUYON_S_SYNDROME',
  GYNECOMASTIA = 'GYNECOMASTIA',
  HAEMOPHILUS_INFLUENZAE_TYPE_B_HIB = 'HAEMOPHILUS_INFLUENZAE_TYPE_B_HIB',
  HAGLUND_S_DISEASE = 'HAGLUND_S_DISEASE',
  HAMARTOMATOUS_POLYPOSIS = 'HAMARTOMATOUS_POLYPOSIS',
  HAND_AND_WRIST_ARTHROPLASTY = 'HAND_AND_WRIST_ARTHROPLASTY',
  HANSENIASIS_LEPROSY = 'HANSENIASIS_LEPROSY',
  HANTAVIRUS_HEMORRHAGIC_FEVER = 'HANTAVIRUS_HEMORRHAGIC_FEVER',
  HASHIMOTO_S_DISEASE = 'HASHIMOTO_S_DISEASE',
  HASHIMOTO_S_DISEASE_AUTOIMMUNE_HYPOTHYROIDISM = 'HASHIMOTO_S_DISEASE_AUTOIMMUNE_HYPOTHYROIDISM',
  HAY_FEVER = 'HAY_FEVER',
  HEADACHE = 'HEADACHE',
  HEADACHE_HEADACHE = 'HEADACHE_HEADACHE',
  HEADACHE_VARIANT_2 = 'HEADACHE_VARIANT_2',
  HEAD_TRAUMA = 'HEAD_TRAUMA',
  HEARING_DISORDERS_PRESBYCUSIS = 'HEARING_DISORDERS_PRESBYCUSIS',
  HEARING_PROBLEMS_AND_DEAFNESS = 'HEARING_PROBLEMS_AND_DEAFNESS',
  HEART = 'HEART',
  HEART_BLOCK = 'HEART_BLOCK',
  HEART_DISEASE = 'HEART_DISEASE',
  HEART_FAILURE = 'HEART_FAILURE',
  HEART_MOVEMENT_PROBLEMS = 'HEART_MOVEMENT_PROBLEMS',
  HEART_MURMUR = 'HEART_MURMUR',
  HEART_VALVE_DISEASE = 'HEART_VALVE_DISEASE',
  HELIX_CELL_CARCINOMA_OF_THE_PANCREAS = 'HELIX_CELL_CARCINOMA_OF_THE_PANCREAS',
  HEMANGIOMAS = 'HEMANGIOMAS',
  HEMATOLOGIC_NEOPLASMS_LYMPHOMA_MULTIPLE_MYELOMA = 'HEMATOLOGIC_NEOPLASMS_LYMPHOMA_MULTIPLE_MYELOMA',
  HEMATOMA = 'HEMATOMA',
  HEMATOMAS = 'HEMATOMAS',
  HEMIFACIAL_MICROSOMIA = 'HEMIFACIAL_MICROSOMIA',
  HEMIFACIAL_SPASM = 'HEMIFACIAL_SPASM',
  HEMOGLOBIN_PATHOLOGIES = 'HEMOGLOBIN_PATHOLOGIES',
  HEMOLYTIC_CELL_CARCINOMA_OF_THE_PANCREAS = 'HEMOLYTIC_CELL_CARCINOMA_OF_THE_PANCREAS',
  HEMOLYTIC_UREMIC_SYNDROME_HUS = 'HEMOLYTIC_UREMIC_SYNDROME_HUS',
  HEMOPHAGOCYTIC_LYMPHOHISTIOCYTOSIS_HLH_SYNDROME = 'HEMOPHAGOCYTIC_LYMPHOHISTIOCYTOSIS_HLH_SYNDROME',
  HEMOPHAGOCYTIC_SYNDROME_HPS = 'HEMOPHAGOCYTIC_SYNDROME_HPS',
  HEMOPHILIA = 'HEMOPHILIA',
  HEMOPHTHALMOS = 'HEMOPHTHALMOS',
  HEMORRHAGIC_DENGUE = 'HEMORRHAGIC_DENGUE',
  HEMORRHOIDAL_DISEASE = 'HEMORRHOIDAL_DISEASE',
  HEMORRHOIDS = 'HEMORRHOIDS',
  HEMOTHORAX = 'HEMOTHORAX',
  HENOCH_SCH_NLEIN_SYNDROME = 'HENOCH_SCH_NLEIN_SYNDROME',
  HEPATIC_CYST = 'HEPATIC_CYST',
  HEPATIC_ENCEPHALOPATHY = 'HEPATIC_ENCEPHALOPATHY',
  HEPATIC_HEMANGIOMA = 'HEPATIC_HEMANGIOMA',
  HEPATIC_STEATOSIS_FATTY_LIVER = 'HEPATIC_STEATOSIS_FATTY_LIVER',
  HEPATITIS = 'HEPATITIS',
  HEPATITIS_A = 'HEPATITIS_A',
  HEPATITIS_B = 'HEPATITIS_B',
  HEPATITIS_C = 'HEPATITIS_C',
  HEPATITIS_D = 'HEPATITIS_D',
  HEPATITIS_DELTA_VIRUS = 'HEPATITIS_DELTA_VIRUS',
  HEPATITIS_E = 'HEPATITIS_E',
  HEPATITIS_VIRAL_AUTOIMMUNE_TOXIC = 'HEPATITIS_VIRAL_AUTOIMMUNE_TOXIC',
  HEPATOCELLULAR_CARCINOMA = 'HEPATOCELLULAR_CARCINOMA',
  HEPATOCELLULAR_CARCINOMA_HCC = 'HEPATOCELLULAR_CARCINOMA_HCC',
  HEREDITARY_HEMOLYTIC_ANEMIA = 'HEREDITARY_HEMOLYTIC_ANEMIA',
  HEREDITARY_METABOLIC_DISEASES_SUCH_AS_GALACTOSEMIA_OR_TYROSINEMIA = 'HEREDITARY_METABOLIC_DISEASES_SUCH_AS_GALACTOSEMIA_OR_TYROSINEMIA',
  HEREDITARY_POLYCYTHEMIA_VERA = 'HEREDITARY_POLYCYTHEMIA_VERA',
  HERMANSKY_PUDLAK_SYNDROME = 'HERMANSKY_PUDLAK_SYNDROME',
  HERPESVIRIDAE_OTHER_TYPES_OF_HERPESVIRUSES = 'HERPESVIRIDAE_OTHER_TYPES_OF_HERPESVIRUSES',
  HERPES_SIMPLEX = 'HERPES_SIMPLEX',
  HERPES_SIMPLEX_INFECTIONS = 'HERPES_SIMPLEX_INFECTIONS',
  HERPES_SIMPLEX_VIRUS_HSV_INFECTION = 'HERPES_SIMPLEX_VIRUS_HSV_INFECTION',
  HERPES_ZOSTER_SHINGLES = 'HERPES_ZOSTER_SHINGLES',
  HERPETIC_STOMATITIS_HERPES_LABIALIS = 'HERPETIC_STOMATITIS_HERPES_LABIALIS',
  HIATAL_HERNIA = 'HIATAL_HERNIA',
  HIGH_BLOOD_PRESSURE = 'HIGH_BLOOD_PRESSURE',
  HIP_DYSPLASIA = 'HIP_DYSPLASIA',
  HIP_FRACTURE = 'HIP_FRACTURE',
  HIP_FRACTURES = 'HIP_FRACTURES',
  HIP_IMPINGEMENT_SYNDROME = 'HIP_IMPINGEMENT_SYNDROME',
  HIP_PAIN = 'HIP_PAIN',
  HIRSCHSPRUNG_S_DISEASE = 'HIRSCHSPRUNG_S_DISEASE',
  HISTOPLASMOSIS = 'HISTOPLASMOSIS',
  HISTRIONIC_PERSONALITY_DISORDER = 'HISTRIONIC_PERSONALITY_DISORDER',
  HISTRIONIC_PERSONALITY_DISORDER_VARIANT_2 = 'HISTRIONIC_PERSONALITY_DISORDER_VARIANT_2',
  HIV_AIDS = 'HIV_AIDS',
  HIV_AIDS_INFECTIONS = 'HIV_AIDS_INFECTIONS',
  HOARSENESS = 'HOARSENESS',
  HOARSENESS_DOUBLE_VOICE = 'HOARSENESS_DOUBLE_VOICE',
  HOCM_OBSTRUCTIVE_CARDIOMYOPATHY_HYPERTROPHY_SYNDROME = 'HOCM_OBSTRUCTIVE_CARDIOMYOPATHY_HYPERTROPHY_SYNDROME',
  HODGKIN_S_LYMPHOMA = 'HODGKIN_S_LYMPHOMA',
  HOLT_ORAM_SYNDROME = 'HOLT_ORAM_SYNDROME',
  HORMONE_REPLACEMENT_THERAPY_HRT_DURING_MENOPAUSE = 'HORMONE_REPLACEMENT_THERAPY_HRT_DURING_MENOPAUSE',
  HORNER_S_SYNDROME = 'HORNER_S_SYNDROME',
  HORTON_S_ARTERITIS_TEMPORAL_ARTERITIS = 'HORTON_S_ARTERITIS_TEMPORAL_ARTERITIS',
  HOUSE_DUST_HYPERSENSITIVITY_SYNDROME = 'HOUSE_DUST_HYPERSENSITIVITY_SYNDROME',
  HUGHES_STOVIN_SYNDROME = 'HUGHES_STOVIN_SYNDROME',
  HUMAN_PAPILLOMAVIRUS_HPV = 'HUMAN_PAPILLOMAVIRUS_HPV',
  HUMAN_PAPILLOMAVIRUS_HPV_INFECTIONS = 'HUMAN_PAPILLOMAVIRUS_HPV_INFECTIONS',
  HUNTER_S_DISEASE = 'HUNTER_S_DISEASE',
  HUNTINGTON_S_DISEASE = 'HUNTINGTON_S_DISEASE',
  HUNTINGTON_S_KOREA = 'HUNTINGTON_S_KOREA',
  HURLER_SCHEIE_SYNDROME = 'HURLER_SCHEIE_SYNDROME',
  HURLER_S_DISEASE = 'HURLER_S_DISEASE',
  HUTCHINSON_S_FRACTURE = 'HUTCHINSON_S_FRACTURE',
  HYALINE_CELL_CARCINOMA_OF_THE_PANCREAS = 'HYALINE_CELL_CARCINOMA_OF_THE_PANCREAS',
  HYDATIDOSIS = 'HYDATIDOSIS',
  HYDATIDOSIS_EQUINOCOCCOSIS = 'HYDATIDOSIS_EQUINOCOCCOSIS',
  HYDROCELE = 'HYDROCELE',
  HYDROCEPHALUS = 'HYDROCEPHALUS',
  HYDROSEISM = 'HYDROSEISM',
  HYPERCHOLESTEROLEMIA = 'HYPERCHOLESTEROLEMIA',
  HYPERHIDROSIS = 'HYPERHIDROSIS',
  HYPERLIPIDEMIA = 'HYPERLIPIDEMIA',
  HYPERMETROPIA = 'HYPERMETROPIA',
  HYPERPARATHYROIDISM = 'HYPERPARATHYROIDISM',
  HYPERPITUITARISM_ACROMEGALY = 'HYPERPITUITARISM_ACROMEGALY',
  HYPERPROLACTINEMIA = 'HYPERPROLACTINEMIA',
  HYPERSENSITIVITY_PNEUMONITIS = 'HYPERSENSITIVITY_PNEUMONITIS',
  HYPERTENSION = 'HYPERTENSION',
  HYPERTENSION_RELATED_TO_SODIUM_INTAKE = 'HYPERTENSION_RELATED_TO_SODIUM_INTAKE',
  HYPERTENSION_VARIANT_2 = 'HYPERTENSION_VARIANT_2',
  HYPERTENSIVE_HEART_DISEASE = 'HYPERTENSIVE_HEART_DISEASE',
  HYPERTENSIVE_NEPHROPATHY = 'HYPERTENSIVE_NEPHROPATHY',
  HYPERTENSIVE_RETINOPATHY = 'HYPERTENSIVE_RETINOPATHY',
  HYPERTHYROIDISM_BASEDOW_GRAVES_DISEASE = 'HYPERTHYROIDISM_BASEDOW_GRAVES_DISEASE',
  HYPERTROPHIC_ADENOID = 'HYPERTROPHIC_ADENOID',
  HYPERTROPHIC_MYOCARDIOPATHY = 'HYPERTROPHIC_MYOCARDIOPATHY',
  HYPERTROPHIC_SCARS = 'HYPERTROPHIC_SCARS',
  HYPER_IGM_SYNDROME_HIGM = 'HYPER_IGM_SYNDROME_HIGM',
  HYPOCHONDRIA = 'HYPOCHONDRIA',
  HYPOGLYCEMIA = 'HYPOGLYCEMIA',
  HYPOPARATHYROIDISM = 'HYPOPARATHYROIDISM',
  HYPOPIGMENTATION_AND_HYPERPIGMENTATION = 'HYPOPIGMENTATION_AND_HYPERPIGMENTATION',
  HYPOPITUITARISM = 'HYPOPITUITARISM',
  HYPOPLASIA_OF_THE_MAXILLARY_SINUS = 'HYPOPLASIA_OF_THE_MAXILLARY_SINUS',
  HYPOPLASTIC_LEFT_HEART_SYNDROME = 'HYPOPLASTIC_LEFT_HEART_SYNDROME',
  HYPOTHYROIDISM = 'HYPOTHYROIDISM',
  HYPOTHYROIDISM_SUCH_AS_CONGENITAL_HYPOTHYROIDISM = 'HYPOTHYROIDISM_SUCH_AS_CONGENITAL_HYPOTHYROIDISM',
  HYSTERECTOMY = 'HYSTERECTOMY',
  HYSTERICAL_PERSONALITY_DISORDER = 'HYSTERICAL_PERSONALITY_DISORDER',
  ICTERICIA_INFECCIOSA_HEPATITIS_A = 'ICTERICIA_INFECCIOSA_HEPATITIS_A',
  IDIOPATHIC_MYELOFIBROSIS = 'IDIOPATHIC_MYELOFIBROSIS',
  IDIOPATHIC_PULMONARY_FIBROSIS = 'IDIOPATHIC_PULMONARY_FIBROSIS',
  IGA_DEPOSITION_GLOMERULONEPHRITIS = 'IGA_DEPOSITION_GLOMERULONEPHRITIS',
  IGA_NEPHROPATHY = 'IGA_NEPHROPATHY',
  IGG_IMMUNODEFICIENCY_SUBCLASS = 'IGG_IMMUNODEFICIENCY_SUBCLASS',
  ILIAC_ARTERY_ANEURYSM = 'ILIAC_ARTERY_ANEURYSM',
  ILIAC_ARTERY_STENOSIS = 'ILIAC_ARTERY_STENOSIS',
  ILIOPSOAS_MUSCLE_SYNDROME = 'ILIOPSOAS_MUSCLE_SYNDROME',
  IMMUNE_PLATELETOPENIA_ITP = 'IMMUNE_PLATELETOPENIA_ITP',
  IMMUNE_SYSTEM_DISORDERS_SUCH_AS_SYSTEMIC_LUPUS_ERYTHEMATOSUS = 'IMMUNE_SYSTEM_DISORDERS_SUCH_AS_SYSTEMIC_LUPUS_ERYTHEMATOSUS',
  IMMUNE_THROMBOCYTOPATHY = 'IMMUNE_THROMBOCYTOPATHY',
  IMPACT_TRAUMA = 'IMPACT_TRAUMA',
  IMPETIGO = 'IMPETIGO',
  IMPOTENCE = 'IMPOTENCE',
  IMPULSE_CONTROL_DISORDER = 'IMPULSE_CONTROL_DISORDER',
  IMPULSE_CONTROL_DISORDERS_PATHOLOGICAL_GAMBLING_TRICHOTILLOMANIA = 'IMPULSE_CONTROL_DISORDERS_PATHOLOGICAL_GAMBLING_TRICHOTILLOMANIA',
  INABILITY_TO_PERFORM_ACTIONS = 'INABILITY_TO_PERFORM_ACTIONS',
  INABILITY_TO_RECOGNIZE_THINGS_TO_PEOPLE = 'INABILITY_TO_RECOGNIZE_THINGS_TO_PEOPLE',
  INABILITY_TO_SPEAK = 'INABILITY_TO_SPEAK',
  INAPPETENCE = 'INAPPETENCE',
  INCOMPETENT_CERVIX = 'INCOMPETENT_CERVIX',
  INCONSOLABLE_WEEPING = 'INCONSOLABLE_WEEPING',
  INCONTINENCE_DERMATITIS = 'INCONTINENCE_DERMATITIS',
  INDETERMINATE_COLITIS = 'INDETERMINATE_COLITIS',
  INFARCTION = 'INFARCTION',
  INFECTIONS = 'INFECTIONS',
  INFECTIOUS_ARTERIOVENITIS = 'INFECTIOUS_ARTERIOVENITIS',
  INFECTIOUS_DISEASES_FLU_PNEUMONIA = 'INFECTIOUS_DISEASES_FLU_PNEUMONIA',
  INFECTIOUS_DISEASES_SUCH_AS_HIV_AIDS_HEPATITIS_AND_TUBERCULOSIS = 'INFECTIOUS_DISEASES_SUCH_AS_HIV_AIDS_HEPATITIS_AND_TUBERCULOSIS',
  INFECTIVE_ENDOCARDITIS = 'INFECTIVE_ENDOCARDITIS',
  INFERIOR_TWIN_MUSCLE_SYNDROME = 'INFERIOR_TWIN_MUSCLE_SYNDROME',
  INFERTILITY = 'INFERTILITY',
  INFLAMMATORY = 'INFLAMMATORY',
  INFLAMMATORY_BOWEL_DISEASE = 'INFLAMMATORY_BOWEL_DISEASE',
  INFLAMMATORY_BOWEL_DISEASE_CROHN_S_DISEASE_ULCERATIVE_COLITIS = 'INFLAMMATORY_BOWEL_DISEASE_CROHN_S_DISEASE_ULCERATIVE_COLITIS',
  INFLAMMATORY_BOWEL_DISEASE_IBD = 'INFLAMMATORY_BOWEL_DISEASE_IBD',
  INFLAMMATORY_BOWEL_DISEASE_IBD_VARIANT_2 = 'INFLAMMATORY_BOWEL_DISEASE_IBD_VARIANT_2',
  INFLAMMATORY_BOWEL_DISEASE_SUCH_AS_CROHN_S_DISEASE_AND_ULCERATIVE_COLITIS = 'INFLAMMATORY_BOWEL_DISEASE_SUCH_AS_CROHN_S_DISEASE_AND_ULCERATIVE_COLITIS',
  INFLUENCE = 'INFLUENCE',
  INGUINAL_GRANULOMA = 'INGUINAL_GRANULOMA',
  INGUINAL_HERNIA = 'INGUINAL_HERNIA',
  INJURY_OF_THE_POSTERIOR_CRUCIATE_LIGAMENT_PCL = 'INJURY_OF_THE_POSTERIOR_CRUCIATE_LIGAMENT_PCL',
  INNER_EAR = 'INNER_EAR',
  INNER_EAR_DISORDER_SUCH_AS_SJ_GREN_S_SYNDROME = 'INNER_EAR_DISORDER_SUCH_AS_SJ_GREN_S_SYNDROME',
  INSOMNIA = 'INSOMNIA',
  INTERATRIAL_SEPTAL_DEFECT_ASD = 'INTERATRIAL_SEPTAL_DEFECT_ASD',
  INTERMITTENT_EXPLOSIVE_DISORDER = 'INTERMITTENT_EXPLOSIVE_DISORDER',
  INTERMITTENT_EXPLOSIVE_DISORDER_IED = 'INTERMITTENT_EXPLOSIVE_DISORDER_IED',
  INTERSTITIAL_CELL_CARCINOMA_OF_THE_PANCREAS = 'INTERSTITIAL_CELL_CARCINOMA_OF_THE_PANCREAS',
  INTERSTITIAL_CYSTITIS = 'INTERSTITIAL_CYSTITIS',
  INTERSTITIAL_LUNG_DISEASE = 'INTERSTITIAL_LUNG_DISEASE',
  INTERSTITIAL_LUNG_DISEASE_PULMONARY_FIBROSIS = 'INTERSTITIAL_LUNG_DISEASE_PULMONARY_FIBROSIS',
  INTERSTITIAL_NEPHRITIS = 'INTERSTITIAL_NEPHRITIS',
  INTERVENTRICULAR_SEPTAL_DEFECT_VSD = 'INTERVENTRICULAR_SEPTAL_DEFECT_VSD',
  INTESTINAL_DISEASES = 'INTESTINAL_DISEASES',
  INTESTINAL_DIVERTICULA = 'INTESTINAL_DIVERTICULA',
  INTESTINAL_ENDOMETRIOSIS = 'INTESTINAL_ENDOMETRIOSIS',
  INTESTINAL_LEIOMYOMA = 'INTESTINAL_LEIOMYOMA',
  INTESTINAL_MALROTATION = 'INTESTINAL_MALROTATION',
  INTESTINAL_OBSTRUCTION = 'INTESTINAL_OBSTRUCTION',
  INTESTINAL_POLYPS = 'INTESTINAL_POLYPS',
  INTESTINAL_SCHISTOSOMIASIS = 'INTESTINAL_SCHISTOSOMIASIS',
  INTESTINAL_STENOSIS = 'INTESTINAL_STENOSIS',
  INTESTINAL_TUMORS = 'INTESTINAL_TUMORS',
  INTESTINAL_VOLVULUS = 'INTESTINAL_VOLVULUS',
  INTRACRANIAL_HEMORRHAGE = 'INTRACRANIAL_HEMORRHAGE',
  INTRADERMAL_NEVUS = 'INTRADERMAL_NEVUS',
  INTRADUCTAL_PAPILLOMA = 'INTRADUCTAL_PAPILLOMA',
  INTRAHEPATIC_BILIARY_TRACT_CARCINOMA = 'INTRAHEPATIC_BILIARY_TRACT_CARCINOMA',
  INTRAHEPATIC_GALLSTONES = 'INTRAHEPATIC_GALLSTONES',
  INTRAMEDULLARY_ANGIOMA = 'INTRAMEDULLARY_ANGIOMA',
  INTRAMEDULLARY_ASTROCYTOMA = 'INTRAMEDULLARY_ASTROCYTOMA',
  INTRAMEDULLARY_CYST = 'INTRAMEDULLARY_CYST',
  INTRAMEDULLARY_GLIOMA = 'INTRAMEDULLARY_GLIOMA',
  INTRAMEDULLARY_HEMORRHAGE = 'INTRAMEDULLARY_HEMORRHAGE',
  INTRAMEDULLARY_MENINGIOMA = 'INTRAMEDULLARY_MENINGIOMA',
  INTRAMEDULLARY_SARCOMA = 'INTRAMEDULLARY_SARCOMA',
  INTRAMEDULLARY_TUMOR = 'INTRAMEDULLARY_TUMOR',
  INTRAPARENCHYMAL_CYST = 'INTRAPARENCHYMAL_CYST',
  INTRAPARENCHYMAL_HEMATOMA = 'INTRAPARENCHYMAL_HEMATOMA',
  INTRAPARENCHYMAL_HEMORRHAGE = 'INTRAPARENCHYMAL_HEMORRHAGE',
  INTRAUTERINE_DEVICE_DIU = 'INTRAUTERINE_DEVICE_DIU',
  INTRAVENTRICULAR_HEMORRHAGE = 'INTRAVENTRICULAR_HEMORRHAGE',
  INVASIVE_CANDIDIASIS = 'INVASIVE_CANDIDIASIS',
  IN_THE = 'IN_THE',
  IPOPION = 'IPOPION',
  IRIDOCYCLITIS = 'IRIDOCYCLITIS',
  IRITE = 'IRITE',
  IRON_DEFICIENCY_ANEMIA = 'IRON_DEFICIENCY_ANEMIA',
  IRRITABLE_BOWEL_SYNDROME_IBS = 'IRRITABLE_BOWEL_SYNDROME_IBS',
  IRRITABLE_BOWEL_SYNDROME_IBS_VARIANT_2 = 'IRRITABLE_BOWEL_SYNDROME_IBS_VARIANT_2',
  ISCHEMIC_COLITIS = 'ISCHEMIC_COLITIS',
  ISCHEMIC_HEART_DISEASE = 'ISCHEMIC_HEART_DISEASE',
  ISCHEMIC_HEART_DISEASE_SUCH_AS_ANGINA_AND_HEART_ATTACK = 'ISCHEMIC_HEART_DISEASE_SUCH_AS_ANGINA_AND_HEART_ATTACK',
  ISCHEMIC_HEART_DISEASE_VARIANT_2 = 'ISCHEMIC_HEART_DISEASE_VARIANT_2',
  ISCHEMIC_STROKE = 'ISCHEMIC_STROKE',
  ITCHING_ANOGENITAL_ITCHING = 'ITCHING_ANOGENITAL_ITCHING',
  JACCOUD_SYNDROME = 'JACCOUD_SYNDROME',
  JACOBSEN_S_SYNDROME = 'JACOBSEN_S_SYNDROME',
  JAPANESE_ENCEPHALITIS = 'JAPANESE_ENCEPHALITIS',
  JAPANESE_ENCEPHALITIS_VIRUS = 'JAPANESE_ENCEPHALITIS_VIRUS',
  JAUNDICE_YELLOW_SKIN = 'JAUNDICE_YELLOW_SKIN',
  JAW_CANCER = 'JAW_CANCER',
  JAW_JOINT_PROBLEMS = 'JAW_JOINT_PROBLEMS',
  JAW_TRAUMA = 'JAW_TRAUMA',
  JEUNE_SYNDROME = 'JEUNE_SYNDROME',
  JOB_S_SYNDROME = 'JOB_S_SYNDROME',
  JOINT_DEFORMATION = 'JOINT_DEFORMATION',
  JOINT_DISEASES = 'JOINT_DISEASES',
  JOINT_INJURIES_SUCH_AS_MENISCUS_INJURIES = 'JOINT_INJURIES_SUCH_AS_MENISCUS_INJURIES',
  JOINT_PAIN = 'JOINT_PAIN',
  JONES_FRACTURE = 'JONES_FRACTURE',
  JOUBERT_LIKE_SYNDROME = 'JOUBERT_LIKE_SYNDROME',
  JOUBERT_S_SYNDROME = 'JOUBERT_S_SYNDROME',
  JUNCTIONAL_GASTROINTESTINAL_ESOPHAGEAL_CANCER_GEJ = 'JUNCTIONAL_GASTROINTESTINAL_ESOPHAGEAL_CANCER_GEJ',
  JUNIN_S_HEMORRHAGIC_FEVER = 'JUNIN_S_HEMORRHAGIC_FEVER',
  JUVENILE_DIABETES = 'JUVENILE_DIABETES',
  JUVENILE_IDIOPATHIC_ARTHRITIS = 'JUVENILE_IDIOPATHIC_ARTHRITIS',
  JUVENILE_IDIOPATHIC_ARTHRITIS_VARIANT_2 = 'JUVENILE_IDIOPATHIC_ARTHRITIS_VARIANT_2',
  KABUKI_SYNDROME = 'KABUKI_SYNDROME',
  KALLMANN_S_SYNDROME = 'KALLMANN_S_SYNDROME',
  KAPOSI_S_SARCOMA = 'KAPOSI_S_SARCOMA',
  KAPOSI_S_SARCOMA_TUMOR = 'KAPOSI_S_SARCOMA_TUMOR',
  KAWASAKI_DISEASE = 'KAWASAKI_DISEASE',
  KAWASAKI_SYNDROME = 'KAWASAKI_SYNDROME',
  KEARNS_SAYRE_SYNDROME_WITH_CARDIAC_INVOLVEMENT = 'KEARNS_SAYRE_SYNDROME_WITH_CARDIAC_INVOLVEMENT',
  KELOIDI = 'KELOIDI',
  KELOID_SCARS = 'KELOID_SCARS',
  KENNEDY_MUSCULAR_ATROPHY_SBMA = 'KENNEDY_MUSCULAR_ATROPHY_SBMA',
  KERATIN_CELL_CARCINOMA_OF_THE_PANCREAS = 'KERATIN_CELL_CARCINOMA_OF_THE_PANCREAS',
  KERATOACANTHOMA = 'KERATOACANTHOMA',
  KERATOCONUS = 'KERATOCONUS',
  KERATOMA = 'KERATOMA',
  KERATOSISIC_BONE_TUMOR = 'KERATOSISIC_BONE_TUMOR',
  KERATOSIS_PILARE = 'KERATOSIS_PILARE',
  KIDNEYS = 'KIDNEYS',
  KIDNEY_BLOCKAGE = 'KIDNEY_BLOCKAGE',
  KIDNEY_CANCER = 'KIDNEY_CANCER',
  KIDNEY_CANCER_VARIANT_2 = 'KIDNEY_CANCER_VARIANT_2',
  KIDNEY_DISEASE_OR_PROBLEMS = 'KIDNEY_DISEASE_OR_PROBLEMS',
  KIDNEY_DISEASE_WITH_THE_NEED_TO_MONITOR_PROTEIN_AND_SODIUM_INTAKE = 'KIDNEY_DISEASE_WITH_THE_NEED_TO_MONITOR_PROTEIN_AND_SODIUM_INTAKE',
  KIDNEY_STONES = 'KIDNEY_STONES',
  KIENB_CK_S_DISEASE = 'KIENB_CK_S_DISEASE',
  KIKUCHI_FUJIMOTO_SYNDROME = 'KIKUCHI_FUJIMOTO_SYNDROME',
  KLINEFELTER_LIKE_SYNDROME = 'KLINEFELTER_LIKE_SYNDROME',
  KLINEFELTER_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT = 'KLINEFELTER_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT',
  KLINEFELTER_S_SYNDROME = 'KLINEFELTER_S_SYNDROME',
  KLINEFELTER_S_SYNDROME_XXY = 'KLINEFELTER_S_SYNDROME_XXY',
  KNEE_PAIN_SUCH_AS_RUNNER_S_KNEE = 'KNEE_PAIN_SUCH_AS_RUNNER_S_KNEE',
  KOHLER_S_DISEASE = 'KOHLER_S_DISEASE',
  KOUNIS_SYNDROME_ALLERGIC_ANGINA = 'KOUNIS_SYNDROME_ALLERGIC_ANGINA',
  KYASANUR_FOREST_HEMORRHAGIC_FEVER = 'KYASANUR_FOREST_HEMORRHAGIC_FEVER',
  LABIOPALATOSCHISIS = 'LABIOPALATOSCHISIS',
  LABIOSCHISIS_AND_CLEFT_PALATE = 'LABIOSCHISIS_AND_CLEFT_PALATE',
  LABOR_DYSTOCIA = 'LABOR_DYSTOCIA',
  LABYRINTHITIS = 'LABYRINTHITIS',
  LACK_OF_DESIRE = 'LACK_OF_DESIRE',
  LACTOSE_INTOLERANCE = 'LACTOSE_INTOLERANCE',
  LANDAU_KLEFFNER_SYNDROME = 'LANDAU_KLEFFNER_SYNDROME',
  LANGERHANS_DISEASE_OF_GIANT_CELLS = 'LANGERHANS_DISEASE_OF_GIANT_CELLS',
  LANGUAGE = 'LANGUAGE',
  LANGUAGE_DELAY = 'LANGUAGE_DELAY',
  LANGUAGE_DISORDER = 'LANGUAGE_DISORDER',
  LANGUAGE_DISORDERS_IN_AUTISM_AND_AUTISM_SPECTRUM_DISORDERS_ASD = 'LANGUAGE_DISORDERS_IN_AUTISM_AND_AUTISM_SPECTRUM_DISORDERS_ASD',
  LANGUAGE_DISORDERS_IN_NEURODEGENERATIVE_CONDITIONS_SUCH_AS_ALZHEIMER_S_DISEASE = 'LANGUAGE_DISORDERS_IN_NEURODEGENERATIVE_CONDITIONS_SUCH_AS_ALZHEIMER_S_DISEASE',
  LAPAROSCOPIC_HYSTERECTOMY = 'LAPAROSCOPIC_HYSTERECTOMY',
  LARGE_CELL_CARCINOMA_OF_THE_LUNG = 'LARGE_CELL_CARCINOMA_OF_THE_LUNG',
  LARGE_INTESTINE = 'LARGE_INTESTINE',
  LARYNGEAL_CANCER = 'LARYNGEAL_CANCER',
  LARYNGEAL_CARCINOMA = 'LARYNGEAL_CARCINOMA',
  LARYNGITIS = 'LARYNGITIS',
  LASSA_HEMORRHAGIC_FEVER = 'LASSA_HEMORRHAGIC_FEVER',
  LATERAL_COLLATERAL_LIGAMENT_INJURY_LCL = 'LATERAL_COLLATERAL_LIGAMENT_INJURY_LCL',
  LATERAL_EPICONDYLITIS_TENNIS_ELBOW = 'LATERAL_EPICONDYLITIS_TENNIS_ELBOW',
  LATEX_ALLERGY = 'LATEX_ALLERGY',
  LATTICE_DEGENERATION = 'LATTICE_DEGENERATION',
  LAURENCE_MOON_BARDET_BIEDL_SYNDROME = 'LAURENCE_MOON_BARDET_BIEDL_SYNDROME',
  LEARNING_DISORDER = 'LEARNING_DISORDER',
  LEARNING_DISORDERS_DYSLEXIA_DYSCALCULIA = 'LEARNING_DISORDERS_DYSLEXIA_DYSCALCULIA',
  LEATHER = 'LEATHER',
  LEBER_S_HEREDITARY_OPTIC_NEUROPATHY_LHON = 'LEBER_S_HEREDITARY_OPTIC_NEUROPATHY_LHON',
  LEBER_S_OPTIC_NEUROPATHY = 'LEBER_S_OPTIC_NEUROPATHY',
  LEFT_HEART_SYNDROME = 'LEFT_HEART_SYNDROME',
  LEFT_VENTRICULAR_HYPOTROPHY = 'LEFT_VENTRICULAR_HYPOTROPHY',
  LEGG_CALV_PERTHES_DISEASE = 'LEGG_CALV_PERTHES_DISEASE',
  LEIOMYOMA = 'LEIOMYOMA',
  LEIOMYOMA_OF_THE_ESOPHAGUS = 'LEIOMYOMA_OF_THE_ESOPHAGUS',
  LEIOMYOMA_OF_THE_STOMACH = 'LEIOMYOMA_OF_THE_STOMACH',
  LEISHMANIASIS = 'LEISHMANIASIS',
  LENEGRE_LEV_SYNDROME_BPM = 'LENEGRE_LEV_SYNDROME_BPM',
  LENNOX_GASTAUT_SYNDROME = 'LENNOX_GASTAUT_SYNDROME',
  LEPISME_SILVERFISH = 'LEPISME_SILVERFISH',
  LEPTOSPIROSIS = 'LEPTOSPIROSIS',
  LERICHE_DISEASE = 'LERICHE_DISEASE',
  LERICHE_SYNDROME = 'LERICHE_SYNDROME',
  LEUKEMIAS_AND_LYMPHOMAS = 'LEUKEMIAS_AND_LYMPHOMAS',
  LEYDIG_CELL_CARCINOMA = 'LEYDIG_CELL_CARCINOMA',
  LICHEN_PLANUS = 'LICHEN_PLANUS',
  LICHEN_SIMPLEX_CHRONICUS = 'LICHEN_SIMPLEX_CHRONICUS',
  LIDDLE_S_SYNDROME = 'LIDDLE_S_SYNDROME',
  LIGAMENTOUS_INJURIES_SUCH_AS_ANTERIOR_OR_POSTERIOR_CRUCIATE_LIGAMENT = 'LIGAMENTOUS_INJURIES_SUCH_AS_ANTERIOR_OR_POSTERIOR_CRUCIATE_LIGAMENT',
  LIGAMENT_INJURY = 'LIGAMENT_INJURY',
  LIMB_GIRDLE_MUSCULAR_DYSTROPHY_LGMD = 'LIMB_GIRDLE_MUSCULAR_DYSTROPHY_LGMD',
  LINEAR_SKULL_FRACTURE = 'LINEAR_SKULL_FRACTURE',
  LIPOMA = 'LIPOMA',
  LIPS = 'LIPS',
  LISFRANC_FRACTURE = 'LISFRANC_FRACTURE',
  LIVER = 'LIVER',
  LIVER_CANCER = 'LIVER_CANCER',
  LIVER_CANCER_HEPATOCELLULAR_CARCINOMA = 'LIVER_CANCER_HEPATOCELLULAR_CARCINOMA',
  LIVER_CANCER_TUMOR = 'LIVER_CANCER_TUMOR',
  LIVER_CANCER_VARIANT_2 = 'LIVER_CANCER_VARIANT_2',
  LIVER_CIRRHOSIS = 'LIVER_CIRRHOSIS',
  LIVER_DISEASES = 'LIVER_DISEASES',
  LIVER_DISEASES_SUCH_AS_HEPATITIS_AND_CIRRHOSIS = 'LIVER_DISEASES_SUCH_AS_HEPATITIS_AND_CIRRHOSIS',
  LIVER_DISEASE_SUCH_AS_HEPATITIS_WITH_CHANGES_IN_DIET = 'LIVER_DISEASE_SUCH_AS_HEPATITIS_WITH_CHANGES_IN_DIET',
  LIVER_METASTASIS = 'LIVER_METASTASIS',
  LIVER_PROBLEMS = 'LIVER_PROBLEMS',
  LIVER_STONES = 'LIVER_STONES',
  LIVER_TUMORS = 'LIVER_TUMORS',
  LIVER_TUMORS_VARIANT_2 = 'LIVER_TUMORS_VARIANT_2',
  LOA_LOA_LOIASI = 'LOA_LOA_LOIASI',
  LOBULAR_NEOPLASMS_IN_SITU_LCIS = 'LOBULAR_NEOPLASMS_IN_SITU_LCIS',
  LOEYS_DIETZ_SYNDROME = 'LOEYS_DIETZ_SYNDROME',
  LONG_ACTING_CONTRACEPTIVES_SUCH_AS_IMPLANT = 'LONG_ACTING_CONTRACEPTIVES_SUCH_AS_IMPLANT',
  LONG_AND_SHORT_QT_SYNDROME = 'LONG_AND_SHORT_QT_SYNDROME',
  LONG_EXTENSOR_HALLUX_MUSCLE_SYNDROME = 'LONG_EXTENSOR_HALLUX_MUSCLE_SYNDROME',
  LONG_FINGER_FLEXOR_MUSCLE_SYNDROME = 'LONG_FINGER_FLEXOR_MUSCLE_SYNDROME',
  LONG_HALLUX_FLEXOR_MUSCLE_SYNDROME = 'LONG_HALLUX_FLEXOR_MUSCLE_SYNDROME',
  LONG_PERONEAL_MUSCLE_SYNDROME = 'LONG_PERONEAL_MUSCLE_SYNDROME',
  LONG_QT_SYNDROME = 'LONG_QT_SYNDROME',
  LONG_QT_SYNDROME_AND_CATECHOLAMINERGIC_POLYMORPHIC_VENTRICULAR_TACHYCARDIA = 'LONG_QT_SYNDROME_AND_CATECHOLAMINERGIC_POLYMORPHIC_VENTRICULAR_TACHYCARDIA',
  LONG_QT_SYNDROME_VARIANT_2 = 'LONG_QT_SYNDROME_VARIANT_2',
  LORDOSIS = 'LORDOSIS',
  LOU_GEHRIG_S_DISEASE_ALS = 'LOU_GEHRIG_S_DISEASE_ALS',
  LOU_GEHRIG_S_DISEASE_AMYOTROPHIC_LATERAL_SCLEROSIS_ALS = 'LOU_GEHRIG_S_DISEASE_AMYOTROPHIC_LATERAL_SCLEROSIS_ALS',
  LOWER_RESPIRATORY_TRACT_INFECTIONS_BRONCHITIS = 'LOWER_RESPIRATORY_TRACT_INFECTIONS_BRONCHITIS',
  LOWER_RESPIRATORY_TRACT_INFECTIONS_SUCH_AS_PNEUMONIA = 'LOWER_RESPIRATORY_TRACT_INFECTIONS_SUCH_AS_PNEUMONIA',
  LUJO_S_HEMORRHAGIC_FEVER = 'LUJO_S_HEMORRHAGIC_FEVER',
  LUMBAGO = 'LUMBAGO',
  LUMBAR_MUSCLE_SYNDROME = 'LUMBAR_MUSCLE_SYNDROME',
  LUNGS = 'LUNGS',
  LUNG_CANCER = 'LUNG_CANCER',
  LUNG_CANCERS = 'LUNG_CANCERS',
  LUNG_CANCER_METASTATIC_TO_THE_GASTROINTESTINAL_TRACT = 'LUNG_CANCER_METASTATIC_TO_THE_GASTROINTESTINAL_TRACT',
  LUNG_CANCER_VARIANT_2 = 'LUNG_CANCER_VARIANT_2',
  LUNG_CARCINOMA = 'LUNG_CARCINOMA',
  LUNG_LYMPHOMA = 'LUNG_LYMPHOMA',
  LUNG_METASTASIS = 'LUNG_METASTASIS',
  LUNG_TUMORS = 'LUNG_TUMORS',
  LUPUS_NEPHRITIS = 'LUPUS_NEPHRITIS',
  LUPUS_NEPHROPATHY_SYSTEMIC_LUPUS_ERYTHEMATOSUS = 'LUPUS_NEPHROPATHY_SYSTEMIC_LUPUS_ERYTHEMATOSUS',
  LUTEIN_CELL_CARCINOMA_OF_THE_PANCREAS = 'LUTEIN_CELL_CARCINOMA_OF_THE_PANCREAS',
  LUTEMBACHER_SYNDROME = 'LUTEMBACHER_SYNDROME',
  LYME_BORRELIOSIS = 'LYME_BORRELIOSIS',
  LYME_DISEASE = 'LYME_DISEASE',
  LYME_DISEASE_ERYTHEMA_MIGRANS = 'LYME_DISEASE_ERYTHEMA_MIGRANS',
  LYME_DISEASE_LYME_BORRELIOSIS = 'LYME_DISEASE_LYME_BORRELIOSIS',
  LYME_DISEASE_NEUROBORRELIOSIS = 'LYME_DISEASE_NEUROBORRELIOSIS',
  LYMPHANGIOLEIOMYOMATOSIS_LAM = 'LYMPHANGIOLEIOMYOMATOSIS_LAM',
  LYMPHANGIOMA = 'LYMPHANGIOMA',
  LYMPHANGITIS = 'LYMPHANGITIS',
  LYMPHATIC_FILARIASIS = 'LYMPHATIC_FILARIASIS',
  LYMPHATIC_MALFORMATIONS = 'LYMPHATIC_MALFORMATIONS',
  LYMPHEDEMA = 'LYMPHEDEMA',
  LYMPHOID_CELL_CARCINOMA_OF_THE_PANCREAS = 'LYMPHOID_CELL_CARCINOMA_OF_THE_PANCREAS',
  LYMPHOMA = 'LYMPHOMA',
  LYMPHOMATOID_GRANULOMATOSIS = 'LYMPHOMATOID_GRANULOMATOSIS',
  LYMPHOMA_OF_THE_ESOPHAGUS = 'LYMPHOMA_OF_THE_ESOPHAGUS',
  LYNCH_SYNDROME = 'LYNCH_SYNDROME',
  LYNCH_SYNDROME_HEREDITARY_NONPOLYPOSIS_CANCER_SYNDROME_HNPCC = 'LYNCH_SYNDROME_HEREDITARY_NONPOLYPOSIS_CANCER_SYNDROME_HNPCC',
  LYNCH_SYNDROME_HEREDITARY_NONPOLYPOSIS_COLORECTAL_SYNDROME = 'LYNCH_SYNDROME_HEREDITARY_NONPOLYPOSIS_COLORECTAL_SYNDROME',
  LYNCH_SYNDROME_II = 'LYNCH_SYNDROME_II',
  LYNCH_SYNDROME_NONPOLYPOSIS_COLORECTAL_SYNDROME_HNPCC = 'LYNCH_SYNDROME_NONPOLYPOSIS_COLORECTAL_SYNDROME_HNPCC',
  MACHADO_JOSEPH_ATAXIA_SCA3 = 'MACHADO_JOSEPH_ATAXIA_SCA3',
  MACHUPO_HEMORRHAGIC_FEVER = 'MACHUPO_HEMORRHAGIC_FEVER',
  MACROPHAGE_ACTIVATION_SYNDROME_MAS = 'MACROPHAGE_ACTIVATION_SYNDROME_MAS',
  MADELUNG_S_DISEASE = 'MADELUNG_S_DISEASE',
  MAFFUCCI_S_DISEASE = 'MAFFUCCI_S_DISEASE',
  MAINZER_SALDINO_SYNDROME = 'MAINZER_SALDINO_SYNDROME',
  MAJOR_DEPRESSION = 'MAJOR_DEPRESSION',
  MAJOR_DEPRESSIVE_DISORDER = 'MAJOR_DEPRESSIVE_DISORDER',
  MALABSORPTION = 'MALABSORPTION',
  MALADIE_D_EBSTEIN_SYNDROME = 'MALADIE_D_EBSTEIN_SYNDROME',
  MALARIA = 'MALARIA',
  MALE_INFERTILITY = 'MALE_INFERTILITY',
  MALE_ORGASM_DISORDERS = 'MALE_ORGASM_DISORDERS',
  MALE_URINARY_INCONTINENCE = 'MALE_URINARY_INCONTINENCE',
  MALFORMATIONS_OF_THE_HAND_AND_WRIST = 'MALFORMATIONS_OF_THE_HAND_AND_WRIST',
  MALFORMATIONS_OF_THE_PULMONARY_ARTERIES = 'MALFORMATIONS_OF_THE_PULMONARY_ARTERIES',
  MALIGNANT_BONE_TUMORS = 'MALIGNANT_BONE_TUMORS',
  MALIGNANT_CONNECTIVE_TISSUE_TUMOR = 'MALIGNANT_CONNECTIVE_TISSUE_TUMOR',
  MALIGNANT_INTRAMEDULLARY_TUMOR = 'MALIGNANT_INTRAMEDULLARY_TUMOR',
  MALIGNANT_MELANOMA = 'MALIGNANT_MELANOMA',
  MALIGNANT_SKIN_TUMORS = 'MALIGNANT_SKIN_TUMORS',
  MALIGNANT_SOFT_TISSUE_TUMOR = 'MALIGNANT_SOFT_TISSUE_TUMOR',
  MALIGNANT_TUMORS_OF_THE_LIVER = 'MALIGNANT_TUMORS_OF_THE_LIVER',
  MALIGNANT_TUMORS_RARE = 'MALIGNANT_TUMORS_RARE',
  MALIGNANT_TUMOR_OF_ADIPOSE_TISSUE = 'MALIGNANT_TUMOR_OF_ADIPOSE_TISSUE',
  MALIGNANT_TUMOR_OF_BLOOD_VESSELS = 'MALIGNANT_TUMOR_OF_BLOOD_VESSELS',
  MALIGNANT_TUMOR_OF_BONE_TISSUE = 'MALIGNANT_TUMOR_OF_BONE_TISSUE',
  MALIGNANT_TUMOR_OF_CARTILAGE_TISSUE = 'MALIGNANT_TUMOR_OF_CARTILAGE_TISSUE',
  MALIGNANT_TUMOR_OF_EPITHELIAL_TISSUE = 'MALIGNANT_TUMOR_OF_EPITHELIAL_TISSUE',
  MALIGNANT_TUMOR_OF_FIBROUS_TISSUE = 'MALIGNANT_TUMOR_OF_FIBROUS_TISSUE',
  MALIGNANT_TUMOR_OF_LYMPHATIC_TISSUE = 'MALIGNANT_TUMOR_OF_LYMPHATIC_TISSUE',
  MALIGNANT_TUMOR_OF_MUSCLE_TISSUE = 'MALIGNANT_TUMOR_OF_MUSCLE_TISSUE',
  MALIGNANT_TUMOR_OF_MYOEPITHELIAL_TISSUE = 'MALIGNANT_TUMOR_OF_MYOEPITHELIAL_TISSUE',
  MALIGNANT_TUMOR_OF_NEURAL_TISSUE = 'MALIGNANT_TUMOR_OF_NEURAL_TISSUE',
  MALIGNANT_TUMOR_OF_SMOOTH_MUSCLE_TISSUE = 'MALIGNANT_TUMOR_OF_SMOOTH_MUSCLE_TISSUE',
  MALIGNANT_TUMOR_OF_STRIATED_MUSCLE_TISSUE = 'MALIGNANT_TUMOR_OF_STRIATED_MUSCLE_TISSUE',
  MALIGNANT_TUMOR_OF_THE_BLOOD_TISSUE = 'MALIGNANT_TUMOR_OF_THE_BLOOD_TISSUE',
  MALIGNANT_TUMOR_OF_THE_NERVOUS_TISSUE = 'MALIGNANT_TUMOR_OF_THE_NERVOUS_TISSUE',
  MALIGNANT_TUMOR_OF_THE_SKIN = 'MALIGNANT_TUMOR_OF_THE_SKIN',
  MALNUTRITION = 'MALNUTRITION',
  MALOCCLUSIONS = 'MALOCCLUSIONS',
  MANDIBLE_TRAUMA = 'MANDIBLE_TRAUMA',
  MANDIBULAR_HYPOPLASIA = 'MANDIBULAR_HYPOPLASIA',
  MANDIBULAR_TORUS = 'MANDIBULAR_TORUS',
  MANDOLIN_CELL_CARCINOMA_OF_THE_PANCREAS = 'MANDOLIN_CELL_CARCINOMA_OF_THE_PANCREAS',
  MANSONELLIASIS_MANSONELLOSIS = 'MANSONELLIASIS_MANSONELLOSIS',
  MANTLE_CELL_LYMPHOMA = 'MANTLE_CELL_LYMPHOMA',
  MAP_CELL_CARCINOMA_OF_THE_PANCREAS = 'MAP_CELL_CARCINOMA_OF_THE_PANCREAS',
  MARBURG = 'MARBURG',
  MARBURG_HEMORRHAGIC_FEVER = 'MARBURG_HEMORRHAGIC_FEVER',
  MARFAN_SYNDROME = 'MARFAN_SYNDROME',
  MARGINAL_LINEAR_KERATITIS_CLM = 'MARGINAL_LINEAR_KERATITIS_CLM',
  MAROTEAUX_LAMY_SYNDROME = 'MAROTEAUX_LAMY_SYNDROME',
  MAR_CELL_CARCINOMA = 'MAR_CELL_CARCINOMA',
  MASTITIS = 'MASTITIS',
  MAXILLARY_AND_MANDIBULAR_CYSTS = 'MAXILLARY_AND_MANDIBULAR_CYSTS',
  MAYER_ROKITANSKY_K_STER_HAUSER_LIKE_SYNDROME = 'MAYER_ROKITANSKY_K_STER_HAUSER_LIKE_SYNDROME',
  MAYER_ROKITANSKY_K_STER_HAUSER_SYNDROME_MRKH = 'MAYER_ROKITANSKY_K_STER_HAUSER_SYNDROME_MRKH',
  MAY_THURNER_SYNDROME = 'MAY_THURNER_SYNDROME',
  MCCUNE_ALBRIGHT_SYNDROME = 'MCCUNE_ALBRIGHT_SYNDROME',
  MCKUSICK_KAUFMAN_LIKE_SYNDROME = 'MCKUSICK_KAUFMAN_LIKE_SYNDROME',
  MCKUSICK_KAUFMAN_SYNDROME = 'MCKUSICK_KAUFMAN_SYNDROME',
  MCLEOD_SYNDROME = 'MCLEOD_SYNDROME',
  MEASLES = 'MEASLES',
  MECKEL_GRUBER_LIKE_SYNDROME = 'MECKEL_GRUBER_LIKE_SYNDROME',
  MECKEL_GRUBER_SYNDROME = 'MECKEL_GRUBER_SYNDROME',
  MECKEL_S_DIVERTICULUM = 'MECKEL_S_DIVERTICULUM',
  MEDIAL_COLLATERAL_LIGAMENT_MCL_INJURY = 'MEDIAL_COLLATERAL_LIGAMENT_MCL_INJURY',
  MEDIAL_EPICONDYLITIS_GOLFER_S_ELBOW = 'MEDIAL_EPICONDYLITIS_GOLFER_S_ELBOW',
  MEDIASTINAL_TUMORS = 'MEDIASTINAL_TUMORS',
  MEDULLARY_ARTERIOVENOUS_MALFORMATION = 'MEDULLARY_ARTERIOVENOUS_MALFORMATION',
  MEDULLARY_CARCINOMA_OF_THE_THYROID = 'MEDULLARY_CARCINOMA_OF_THE_THYROID',
  MEDULLARY_CYST = 'MEDULLARY_CYST',
  MEDULLOBLASTOMA = 'MEDULLOBLASTOMA',
  MELANOCYTIC_NEVUS_ON_A_CLEAR_BASE = 'MELANOCYTIC_NEVUS_ON_A_CLEAR_BASE',
  MELANOMA = 'MELANOMA',
  MELASMA = 'MELASMA',
  MEMBRANOPROLIFERATIVE_GLOMERULONEPHRITIS = 'MEMBRANOPROLIFERATIVE_GLOMERULONEPHRITIS',
  MEMBRANOUS_NEPHROPATHY = 'MEMBRANOUS_NEPHROPATHY',
  MENIERE_S_DISEASE = 'MENIERE_S_DISEASE',
  MENINGIOMA = 'MENINGIOMA',
  MENINGITIS = 'MENINGITIS',
  MENISCOPATHY = 'MENISCOPATHY',
  MENISCUS_INJURY = 'MENISCUS_INJURY',
  MENOPAUSE = 'MENOPAUSE',
  MENORRHAGIA_HEAVY_MENSTRUATION = 'MENORRHAGIA_HEAVY_MENSTRUATION',
  MENSTRUAL_DISORDERS = 'MENSTRUAL_DISORDERS',
  METABOLIC_PROBLEMS = 'METABOLIC_PROBLEMS',
  METABOLIC_SYNDROME = 'METABOLIC_SYNDROME',
  METASTATIC_INTRAMEDULLARY_TUMOR = 'METASTATIC_INTRAMEDULLARY_TUMOR',
  METRORRHAGIA_ABNORMAL_UTERINE_BLEEDING = 'METRORRHAGIA_ABNORMAL_UTERINE_BLEEDING',
  MICROPAPILLARY_CELL_CARCINOMA_OF_THE_PANCREAS = 'MICROPAPILLARY_CELL_CARCINOMA_OF_THE_PANCREAS',
  MICROPOLICYSTIC_OVARY_SYNDROME = 'MICROPOLICYSTIC_OVARY_SYNDROME',
  MICROSCOPIC_COLITIS = 'MICROSCOPIC_COLITIS',
  MICROSCOPIC_POLYANGIITIS = 'MICROSCOPIC_POLYANGIITIS',
  MICROSCOPIC_POLYANGIITIS_PAM = 'MICROSCOPIC_POLYANGIITIS_PAM',
  MICROTIA_MALFORMED_EAR = 'MICROTIA_MALFORMED_EAR',
  MIDDLE_EAR = 'MIDDLE_EAR',
  MIDDLE_SKULL_BASE_FRACTURE = 'MIDDLE_SKULL_BASE_FRACTURE',
  MID_GLUTEAL_MUSCLE_SYNDROME = 'MID_GLUTEAL_MUSCLE_SYNDROME',
  MIGRAINE = 'MIGRAINE',
  MIGRAINE_WITHOUT_AURA = 'MIGRAINE_WITHOUT_AURA',
  MIGRAINE_WITH_AURA = 'MIGRAINE_WITH_AURA',
  MIGRAINE_WITH_AURA_TRIGEMINAL_AUTONOMOUS_CEPHALALGIC = 'MIGRAINE_WITH_AURA_TRIGEMINAL_AUTONOMOUS_CEPHALALGIC',
  MILD_COGNITIVE_DISORDER = 'MILD_COGNITIVE_DISORDER',
  MILD_COGNITIVE_IMPAIRMENT_MCI = 'MILD_COGNITIVE_IMPAIRMENT_MCI',
  MINERAL_DEFICIENCIES = 'MINERAL_DEFICIENCIES',
  MINIMAL_LESION_NEPHROPATHY = 'MINIMAL_LESION_NEPHROPATHY',
  MISCARRIAGE = 'MISCARRIAGE',
  MITRAL_INSUFFICIENCY = 'MITRAL_INSUFFICIENCY',
  MITRAL_STENOSIS = 'MITRAL_STENOSIS',
  MITRAL_VALVE_PROLAPSE = 'MITRAL_VALVE_PROLAPSE',
  MIXED_CELL_CARCINOMA_OF_THE_PANCREAS = 'MIXED_CELL_CARCINOMA_OF_THE_PANCREAS',
  MIXED_CONNECTIVE_TISSUE_DISEASE_MCTD_SYNDROME = 'MIXED_CONNECTIVE_TISSUE_DISEASE_MCTD_SYNDROME',
  MIXED_ULCERS = 'MIXED_ULCERS',
  MOBILITY_AND_WALKING_DISORDERS = 'MOBILITY_AND_WALKING_DISORDERS',
  MOEBIUS_SYNDROME = 'MOEBIUS_SYNDROME',
  MOEBURG_SYNDROME = 'MOEBURG_SYNDROME',
  MOLD_HYPERSENSITIVITY_SYNDROME = 'MOLD_HYPERSENSITIVITY_SYNDROME',
  MOLUSCO_CONTAGIOUS = 'MOLUSCO_CONTAGIOUS',
  MONDOR_S_DISEASE = 'MONDOR_S_DISEASE',
  MONDOR_S_DISEASE_SUPERFICIAL_THROMBOPHLEBITIS = 'MONDOR_S_DISEASE_SUPERFICIAL_THROMBOPHLEBITIS',
  MONOCLONAL_IGM_NEPHROPATHY = 'MONOCLONAL_IGM_NEPHROPATHY',
  MONOVISION = 'MONOVISION',
  MONTEGGIA_FRACTURE = 'MONTEGGIA_FRACTURE',
  MOOD_DISORDERS = 'MOOD_DISORDERS',
  MOOD_DISORDERS_BIPOLAR_CYCLOTHYMIA = 'MOOD_DISORDERS_BIPOLAR_CYCLOTHYMIA',
  MOOD_DISORDERS_OR_DEMENTIA = 'MOOD_DISORDERS_OR_DEMENTIA',
  MOOD_REGULATION_DISORDER = 'MOOD_REGULATION_DISORDER',
  MORQUIO_SYNDROME = 'MORQUIO_SYNDROME',
  MOTOR_COORDINATION_DISORDERS = 'MOTOR_COORDINATION_DISORDERS',
  MOVEMENT_DISORDER = 'MOVEMENT_DISORDER',
  MOVEMENT_DISORDERS = 'MOVEMENT_DISORDERS',
  MOVEMENT_DISORDERS_SUCH_AS_PARKINSON_S_DISEASE = 'MOVEMENT_DISORDERS_SUCH_AS_PARKINSON_S_DISEASE',
  MOVEMENT_PROBLEMS = 'MOVEMENT_PROBLEMS',
  MOWAT_WILSON_LIKE_SYNDROME = 'MOWAT_WILSON_LIKE_SYNDROME',
  MOWAT_WILSON_SYNDROME = 'MOWAT_WILSON_SYNDROME',
  MUCINOUS_CELL_CARCINOMA_OF_THE_PANCREAS = 'MUCINOUS_CELL_CARCINOMA_OF_THE_PANCREAS',
  MUCINOUS_RENAL_CELL_CARCINOMA = 'MUCINOUS_RENAL_CELL_CARCINOMA',
  MUCKLE_WELLS_SYNDROME = 'MUCKLE_WELLS_SYNDROME',
  MUCORMYCOSIS = 'MUCORMYCOSIS',
  MULTIFOCAL_MOTOR_NEUROPATHY_MMN = 'MULTIFOCAL_MOTOR_NEUROPATHY_MMN',
  MULTIFOCAL_SENSORY_MOTOR_NEUROPATHY_WITH_CONDUCTION_BLOCK_MFS = 'MULTIFOCAL_SENSORY_MOTOR_NEUROPATHY_WITH_CONDUCTION_BLOCK_MFS',
  MULTILOCULAR_RENAL_CELL_CARCINOMA = 'MULTILOCULAR_RENAL_CELL_CARCINOMA',
  MULTIPLE_MYELOMA = 'MULTIPLE_MYELOMA',
  MULTIPLE_MYELOMA_CANCER = 'MULTIPLE_MYELOMA_CANCER',
  MULTIPLE_SCLEROSIS = 'MULTIPLE_SCLEROSIS',
  MUSCLE_BURDEN_DISEASE = 'MUSCLE_BURDEN_DISEASE',
  MUSCLE_HYPOTONIA = 'MUSCLE_HYPOTONIA',
  MUSCLE_OR_TENDON_TEAR = 'MUSCLE_OR_TENDON_TEAR',
  MUSCLE_STRAINS = 'MUSCLE_STRAINS',
  MUSCLE_STRENGTH_DISORDERS = 'MUSCLE_STRENGTH_DISORDERS',
  MUSCULOSKELETAL_DISORDERS_SUCH_AS_OSTEOARTHRITIS = 'MUSCULOSKELETAL_DISORDERS_SUCH_AS_OSTEOARTHRITIS',
  MUSHROOM = 'MUSHROOM',
  MUSHROOM_CELL_CARCINOMA_OF_THE_PANCREAS = 'MUSHROOM_CELL_CARCINOMA_OF_THE_PANCREAS',
  MYASTHENIA_GRAVIS = 'MYASTHENIA_GRAVIS',
  MYCOSIS = 'MYCOSIS',
  MYELODYSPLASTIC_SYNDROME = 'MYELODYSPLASTIC_SYNDROME',
  MYELODYSPLASTIC_SYNDROME_MDS = 'MYELODYSPLASTIC_SYNDROME_MDS',
  MYELODYSPLASTIC_SYNDROME_WITH_ANEUPLOIDY = 'MYELODYSPLASTIC_SYNDROME_WITH_ANEUPLOIDY',
  MYELOFIBROSIS = 'MYELOFIBROSIS',
  MYELOMENINGOCELE = 'MYELOMENINGOCELE',
  MYOCARDIAL_INFARCTION = 'MYOCARDIAL_INFARCTION',
  MYOCARDITIS = 'MYOCARDITIS',
  MYODESOPSIA = 'MYODESOPSIA',
  MYOFASCIAL_PAIN_SYNDROME = 'MYOFASCIAL_PAIN_SYNDROME',
  MYOMECTOMY = 'MYOMECTOMY',
  MYOPIA = 'MYOPIA',
  MYOSITIS_AND_EPIDEMIC_MYALGIA_BORNHOLM_DISEASE = 'MYOSITIS_AND_EPIDEMIC_MYALGIA_BORNHOLM_DISEASE',
  NAGASHIMA_SYNDROME = 'NAGASHIMA_SYNDROME',
  NAIL_CELL_CARCINOMA_OF_THE_PANCREAS = 'NAIL_CELL_CARCINOMA_OF_THE_PANCREAS',
  NARCISSISTIC_PERSONALITY_DISORDER = 'NARCISSISTIC_PERSONALITY_DISORDER',
  NARCISSISTIC_PERSONALITY_DISORDER_VARIANT_2 = 'NARCISSISTIC_PERSONALITY_DISORDER_VARIANT_2',
  NARCOLEPSY = 'NARCOLEPSY',
  NARROW_VESSEL_RENAL_DISEASE_STENOTIC_RENAL_ARTERIES = 'NARROW_VESSEL_RENAL_DISEASE_STENOTIC_RENAL_ARTERIES',
  NASAL_OBSTRUCTION = 'NASAL_OBSTRUCTION',
  NASAL_POLYPOSIS = 'NASAL_POLYPOSIS',
  NAXOS_SYNDROME = 'NAXOS_SYNDROME',
  NECK_AND_SHOULDER_PAIN = 'NECK_AND_SHOULDER_PAIN',
  NECK_CYST = 'NECK_CYST',
  NECROTIZING_ENTEROCOLITIS = 'NECROTIZING_ENTEROCOLITIS',
  NECROTIZING_FASCIITIS = 'NECROTIZING_FASCIITIS',
  NELSON_S_SYNDROME = 'NELSON_S_SYNDROME',
  NEONATAL_NUTRITION_DISORDERS_SUCH_AS_PREMATURITY_AND_LOW_BIRTH_RATE = 'NEONATAL_NUTRITION_DISORDERS_SUCH_AS_PREMATURITY_AND_LOW_BIRTH_RATE',
  NEONATAL_POLYCYTHEMIA = 'NEONATAL_POLYCYTHEMIA',
  NEPHRITIC_SYNDROME = 'NEPHRITIC_SYNDROME',
  NEPHRITIS = 'NEPHRITIS',
  NEPHROANGIOSCLEROSIS_NEPHROPATHY = 'NEPHROANGIOSCLEROSIS_NEPHROPATHY',
  NEPHROBLASTOMA_NEPHROPATHY = 'NEPHROBLASTOMA_NEPHROPATHY',
  NEPHROPATHY_FROM_IMMUNE_COMPLEX_DEPOSITS = 'NEPHROPATHY_FROM_IMMUNE_COMPLEX_DEPOSITS',
  NEPHROPATHY_FROM_VARIOUS_CAUSES = 'NEPHROPATHY_FROM_VARIOUS_CAUSES',
  NEPHROSIS = 'NEPHROSIS',
  NEPHROTIC_SYNDROME = 'NEPHROTIC_SYNDROME',
  NERVE_COMPRESSION = 'NERVE_COMPRESSION',
  NERVE_CONDUCTION_DISORDERS = 'NERVE_CONDUCTION_DISORDERS',
  NESTING_OSTEOID_OSTEOMA = 'NESTING_OSTEOID_OSTEOMA',
  NEURAL_TUBE_ABNORMALITIES = 'NEURAL_TUBE_ABNORMALITIES',
  NEUROBLASTOMA = 'NEUROBLASTOMA',
  NEURODEGENERATIVE_DISEASES_SUCH_AS_ALZHEIMER_S_AND_PARKINSON_S_DISEASE = 'NEURODEGENERATIVE_DISEASES_SUCH_AS_ALZHEIMER_S_AND_PARKINSON_S_DISEASE',
  NEURODEGENERATIVE_DISEASES_SUCH_AS_PARKINSON_S_DISEASE = 'NEURODEGENERATIVE_DISEASES_SUCH_AS_PARKINSON_S_DISEASE',
  NEURODEVELOPMENTAL_DISORDER = 'NEURODEVELOPMENTAL_DISORDER',
  NEURODEVELOPMENTAL_DISORDERS = 'NEURODEVELOPMENTAL_DISORDERS',
  NEURODEVELOPMENTAL_DISORDERS_AUTISM_ASPERGER_S_SYNDROME = 'NEURODEVELOPMENTAL_DISORDERS_AUTISM_ASPERGER_S_SYNDROME',
  NEURODEVELOPMENTAL_DISORDER_SUCH_AS_MOTOR_COORDINATION_DISORDER = 'NEURODEVELOPMENTAL_DISORDER_SUCH_AS_MOTOR_COORDINATION_DISORDER',
  NEUROENDOCRINE_TUMOR = 'NEUROENDOCRINE_TUMOR',
  NEUROENTERIC_CYST_OF_THE_POSTERIOR_MEDIASTINUM = 'NEUROENTERIC_CYST_OF_THE_POSTERIOR_MEDIASTINUM',
  NEUROFIBROMA = 'NEUROFIBROMA',
  NEUROFIBROMATOSIS = 'NEUROFIBROMATOSIS',
  NEUROFIBROMATOSIS_SYNDROME = 'NEUROFIBROMATOSIS_SYNDROME',
  NEUROGENIC_TUMOR_OF_THE_ANTERIOR_MEDIASTINUM = 'NEUROGENIC_TUMOR_OF_THE_ANTERIOR_MEDIASTINUM',
  NEUROGENIC_TUMOR_OF_THE_MIDDLE_MEDIASTINUM = 'NEUROGENIC_TUMOR_OF_THE_MIDDLE_MEDIASTINUM',
  NEUROGENIC_TUMOR_OF_THE_POSTERIOR_MEDIASTINUM = 'NEUROGENIC_TUMOR_OF_THE_POSTERIOR_MEDIASTINUM',
  NEUROLOGICAL_CONDITIONS_SUCH_AS_STROKE_SPINAL_CORD_INJURY = 'NEUROLOGICAL_CONDITIONS_SUCH_AS_STROKE_SPINAL_CORD_INJURY',
  NEUROMUSCULAR_TRANSMISSION_DISORDERS = 'NEUROMUSCULAR_TRANSMISSION_DISORDERS',
  NEUROPATHIC_ULCERS = 'NEUROPATHIC_ULCERS',
  NEUROPATHY_ASSOCIATED_WITH_MONOCLONAL_IGM = 'NEUROPATHY_ASSOCIATED_WITH_MONOCLONAL_IGM',
  NEURO_MUSCULAR_DISORDERS_SUCH_AS_MYASTHENIA_GRAVIS = 'NEURO_MUSCULAR_DISORDERS_SUCH_AS_MYASTHENIA_GRAVIS',
  NEVUS_COMEDONICUS = 'NEVUS_COMEDONICUS',
  NEVUS_MELANOCITICI_CONG_NITI = 'NEVUS_MELANOCITICI_CONG_NITI',
  NEVUS_MELANOCYTIC_PIGMENTOSIS = 'NEVUS_MELANOCYTIC_PIGMENTOSIS',
  NEVUS_PIGMENTOSIS = 'NEVUS_PIGMENTOSIS',
  NEVUS_PILOSO = 'NEVUS_PILOSO',
  NEVUS_SPILOMELANUS = 'NEVUS_SPILOMELANUS',
  NEVUS_VERRUCOSUM = 'NEVUS_VERRUCOSUM',
  NICKEL_ALLERGY_CONTACT_DERMATITIS = 'NICKEL_ALLERGY_CONTACT_DERMATITIS',
  NICOLAIDES_BARAITSER_SYNDROME = 'NICOLAIDES_BARAITSER_SYNDROME',
  NICU_SYNDROME = 'NICU_SYNDROME',
  NIEMANN_PICK_DISEASE = 'NIEMANN_PICK_DISEASE',
  NIEMANN_PICK_DISEASE_TYPE_C = 'NIEMANN_PICK_DISEASE_TYPE_C',
  NILGIRI_VIRUS = 'NILGIRI_VIRUS',
  NONALCOHOLIC_FATTY_LIVER_NAFLD = 'NONALCOHOLIC_FATTY_LIVER_NAFLD',
  NONALCOHOLIC_STEATOHEPATITIS_NASH = 'NONALCOHOLIC_STEATOHEPATITIS_NASH',
  NONCOMMUNICATING_HYDROCEPHALUS = 'NONCOMMUNICATING_HYDROCEPHALUS',
  NON_HODGKIN_S_LYMPHOMA = 'NON_HODGKIN_S_LYMPHOMA',
  NON_INFECTIOUS = 'NON_INFECTIOUS',
  NON_OXIDIZING_FIBROMA = 'NON_OXIDIZING_FIBROMA',
  NON_PROGRESSIVE_ATAXIA = 'NON_PROGRESSIVE_ATAXIA',
  NON_SMALL_CELL_CARCINOMA_OF_THE_LUNG = 'NON_SMALL_CELL_CARCINOMA_OF_THE_LUNG',
  NON_SMALL_CELL_LUNG_CANCER = 'NON_SMALL_CELL_LUNG_CANCER',
  NON_SQUAMOUS_CELL_LUNG_CARCINOMA = 'NON_SQUAMOUS_CELL_LUNG_CARCINOMA',
  NON_VENEREAL_SPIROCHETOSIS_LYME_DISEASE = 'NON_VENEREAL_SPIROCHETOSIS_LYME_DISEASE',
  NOONAN_SYNDROME = 'NOONAN_SYNDROME',
  NOONAN_SYNDROME_WITH_CARDIAC_INVOLVEMENT = 'NOONAN_SYNDROME_WITH_CARDIAC_INVOLVEMENT',
  NOONAN_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT = 'NOONAN_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT',
  NORMAL_PRESSURE_HYDROCEPHALUS = 'NORMAL_PRESSURE_HYDROCEPHALUS',
  NOROVIRUS_INFECTIONS = 'NOROVIRUS_INFECTIONS',
  NOSE_DEFECTS = 'NOSE_DEFECTS',
  NUTCRACKER_SYNDROME_LEFT_ADRENAL_VEIN_SYNDROME = 'NUTCRACKER_SYNDROME_LEFT_ADRENAL_VEIN_SYNDROME',
  NUTRITION_DISORDERS_IN_PREGNANCY_SUCH_AS_IRON_OR_FOLIC_ACID_DEFICIENCY = 'NUTRITION_DISORDERS_IN_PREGNANCY_SUCH_AS_IRON_OR_FOLIC_ACID_DEFICIENCY',
  NYSTAGMUS = 'NYSTAGMUS',
  OBESITY = 'OBESITY',
  OBSERVATION_OF_AGE_RELATED_MACULAR_DEGENERATION_AMD = 'OBSERVATION_OF_AGE_RELATED_MACULAR_DEGENERATION_AMD',
  OBSESSIONS = 'OBSESSIONS',
  OBSESSIVENESS = 'OBSESSIVENESS',
  OBSESSIVE_COMPULSIVE_DISORDER_OCD = 'OBSESSIVE_COMPULSIVE_DISORDER_OCD',
  OBSESSIVE_COMPULSIVE_DISORDER_OCD_VARIANT_2 = 'OBSESSIVE_COMPULSIVE_DISORDER_OCD_VARIANT_2',
  OBSESSIVE_COMPULSIVE_PERSONALITY_DISORDER = 'OBSESSIVE_COMPULSIVE_PERSONALITY_DISORDER',
  OBSESSIVE_COMPULSIVE_PERSONALITY_DISORDER_OCPD = 'OBSESSIVE_COMPULSIVE_PERSONALITY_DISORDER_OCPD',
  OBSESSIVE_HUNGER = 'OBSESSIVE_HUNGER',
  OBSTRUCTED_CORONARY_ARTERIES = 'OBSTRUCTED_CORONARY_ARTERIES',
  OBSTRUCTIVE_HYDROCEPHALUS = 'OBSTRUCTIVE_HYDROCEPHALUS',
  OBSTRUCTIVE_SLEEP_APNEA = 'OBSTRUCTIVE_SLEEP_APNEA',
  OCCIPITAL_BONE_TUMOR = 'OCCIPITAL_BONE_TUMOR',
  OCCLUSION_DEFECTS = 'OCCLUSION_DEFECTS',
  OCCUPATIONAL_INHALANT_HYPERSENSITIVITY_SYNDROME = 'OCCUPATIONAL_INHALANT_HYPERSENSITIVITY_SYNDROME',
  OCCUPATIONAL_VOCAL_DISEASES = 'OCCUPATIONAL_VOCAL_DISEASES',
  OCULOPHARYNGEAL_MUSCULAR_DYSTROPHY_OPMD = 'OCULOPHARYNGEAL_MUSCULAR_DYSTROPHY_OPMD',
  ODONTOGENIC_ABSCESSES = 'ODONTOGENIC_ABSCESSES',
  ODONTOGENIC_CYSTS = 'ODONTOGENIC_CYSTS',
  ODONTOGENIC_CYSTS_AND_TUMORS = 'ODONTOGENIC_CYSTS_AND_TUMORS',
  ODONTOGENIC_INFECTIONS_OF_THE_NECK = 'ODONTOGENIC_INFECTIONS_OF_THE_NECK',
  ODONTOGENIC_KERATOCYSTS = 'ODONTOGENIC_KERATOCYSTS',
  ODONTOGENIC_TUMORS = 'ODONTOGENIC_TUMORS',
  OGILVIE_SYNDROME = 'OGILVIE_SYNDROME',
  OILY_SKIN_SEBORRHEA = 'OILY_SKIN_SEBORRHEA',
  OLIGODENDROGLIOMA = 'OLIGODENDROGLIOMA',
  OLIGOMENORRHEA_MENSTRUAL_CYCLES_THAT_ARE_TOO_LONG = 'OLIGOMENORRHEA_MENSTRUAL_CYCLES_THAT_ARE_TOO_LONG',
  OLIGOSPERMIA_LOW_CONCENTRATION_OF_SPERMATOZOA = 'OLIGOSPERMIA_LOW_CONCENTRATION_OF_SPERMATOZOA',
  OLIVE_PONTO_CEREBELLAR_ATAXIA_OPCA = 'OLIVE_PONTO_CEREBELLAR_ATAXIA_OPCA',
  OLLIER_S_DISEASE = 'OLLIER_S_DISEASE',
  OMPHALOCELE = 'OMPHALOCELE',
  OMSK_HEMORRHAGIC_FEVER = 'OMSK_HEMORRHAGIC_FEVER',
  ONCHOCERCIASIS_RIVER_BLINDNESS = 'ONCHOCERCIASIS_RIVER_BLINDNESS',
  ONCOCYTOMA = 'ONCOCYTOMA',
  ONDINE_SYNDROME = 'ONDINE_SYNDROME',
  OOPHORECTOMY_REMOVAL_OF_THE_OVARY = 'OOPHORECTOMY_REMOVAL_OF_THE_OVARY',
  OPACIFIED_INTRAOCULAR_LENS = 'OPACIFIED_INTRAOCULAR_LENS',
  OPHTHALMIC_HERPES_ZOSTER = 'OPHTHALMIC_HERPES_ZOSTER',
  OPITZ_G_BBB_SYNDROME = 'OPITZ_G_BBB_SYNDROME',
  OPITZ_KAVEGGIA_SYNDROME = 'OPITZ_KAVEGGIA_SYNDROME',
  OPPOSITIONAL_DEFIANT_DISORDER_ODD = 'OPPOSITIONAL_DEFIANT_DISORDER_ODD',
  OPPOSITIONAL_DEFIANT_DISORDER_ODD_VARIANT_2 = 'OPPOSITIONAL_DEFIANT_DISORDER_ODD_VARIANT_2',
  OPTIC_NERVE_CARCINOMA = 'OPTIC_NERVE_CARCINOMA',
  OPTIC_NEUROPATHY_SUCH_AS_OPTIC_NEURITIS = 'OPTIC_NEUROPATHY_SUCH_AS_OPTIC_NEURITIS',
  ORAL_ACINOSE_CELL_CARCINOMA = 'ORAL_ACINOSE_CELL_CARCINOMA',
  ORAL_BASAL_CELL_CARCINOMA = 'ORAL_BASAL_CELL_CARCINOMA',
  ORAL_CANCER = 'ORAL_CANCER',
  ORAL_CLEAR_CELL_CARCINOMA = 'ORAL_CLEAR_CELL_CARCINOMA',
  ORAL_CONTRACEPTION = 'ORAL_CONTRACEPTION',
  ORAL_EROSIONS_FROM_SLE = 'ORAL_EROSIONS_FROM_SLE',
  ORAL_ERYTHROPLACHIA = 'ORAL_ERYTHROPLACHIA',
  ORAL_FUSIFORM_CELL_CARCINOMA = 'ORAL_FUSIFORM_CELL_CARCINOMA',
  ORAL_GRANULAR_CELL_CARCINOMA = 'ORAL_GRANULAR_CELL_CARCINOMA',
  ORAL_LEUKOPLAKIA = 'ORAL_LEUKOPLAKIA',
  ORAL_LICHEN_PLANUS = 'ORAL_LICHEN_PLANUS',
  ORAL_MUCINOUS_CELL_CARCINOMA = 'ORAL_MUCINOUS_CELL_CARCINOMA',
  ORAL_MUCOCELE = 'ORAL_MUCOCELE',
  ORAL_NEUROENDOCRINE_CELL_CARCINOMA = 'ORAL_NEUROENDOCRINE_CELL_CARCINOMA',
  ORAL_SPINDLE_CELL_CARCINOMA = 'ORAL_SPINDLE_CELL_CARCINOMA',
  ORAL_SQUAMOUS_CELL_CARCINOMA = 'ORAL_SQUAMOUS_CELL_CARCINOMA',
  ORAL_VERRUCOUS_CARCINOMA = 'ORAL_VERRUCOUS_CARCINOMA',
  OROANTRAL_FISTULAS = 'OROANTRAL_FISTULAS',
  OROFACIODIGITALE_LIKE_SYNDROME = 'OROFACIODIGITALE_LIKE_SYNDROME',
  OROFACIODIGITAL_SYNDROME = 'OROFACIODIGITAL_SYNDROME',
  OSGOOD_SCHLATTER_DISEASE = 'OSGOOD_SCHLATTER_DISEASE',
  OSGOOD_SCHLATTER_S_DISEASE = 'OSGOOD_SCHLATTER_S_DISEASE',
  OSTEOARTHRITIS = 'OSTEOARTHRITIS',
  OSTEOARTHROSIS = 'OSTEOARTHROSIS',
  OSTEOBLASTOMA = 'OSTEOBLASTOMA',
  OSTEOCHONDRITIS_DISSECANS = 'OSTEOCHONDRITIS_DISSECANS',
  OSTEOCHONDROMA = 'OSTEOCHONDROMA',
  OSTEOGENESIS_IMPERFECTA = 'OSTEOGENESIS_IMPERFECTA',
  OSTEOGENIC_SARCOMA = 'OSTEOGENIC_SARCOMA',
  OSTEOID_OSTEOMA = 'OSTEOID_OSTEOMA',
  OSTEOMYELITIS = 'OSTEOMYELITIS',
  OSTEOMYELITIS_OF_THE_JAWS = 'OSTEOMYELITIS_OF_THE_JAWS',
  OSTEONECROSIS_OF_THE_JAW_ONJ = 'OSTEONECROSIS_OF_THE_JAW_ONJ',
  OSTEOPOROSIS = 'OSTEOPOROSIS',
  OSTEOPOROSIS_WITH_A_FOCUS_ON_CALCIUM_AND_VITAMIN_D = 'OSTEOPOROSIS_WITH_A_FOCUS_ON_CALCIUM_AND_VITAMIN_D',
  OSTEOSARCOMA = 'OSTEOSARCOMA',
  OTITIS = 'OTITIS',
  OTITIS_MEDIA = 'OTITIS_MEDIA',
  OTOSCLEROSIS = 'OTOSCLEROSIS',
  OUTER_EAR = 'OUTER_EAR',
  OVARIAN_CANCER = 'OVARIAN_CANCER',
  OVARIAN_CANCER_TUMOR = 'OVARIAN_CANCER_TUMOR',
  OVARIAN_CANCER_VARIANT_2 = 'OVARIAN_CANCER_VARIANT_2',
  OVARIAN_CANCER_VARIANT_3 = 'OVARIAN_CANCER_VARIANT_3',
  OVARIAN_CARCINOMA = 'OVARIAN_CARCINOMA',
  OVARIAN_CYSTS = 'OVARIAN_CYSTS',
  OVARIAN_DYSFUNCTION = 'OVARIAN_DYSFUNCTION',
  OVARIAN_TUMORS = 'OVARIAN_TUMORS',
  OVARIES_IN_WOMEN = 'OVARIES_IN_WOMEN',
  OVERLOAD_SYNDROME = 'OVERLOAD_SYNDROME',
  OVERPRONATION_AND_FLAT_FOOT = 'OVERPRONATION_AND_FLAT_FOOT',
  OVERUSE_SYNDROME_OVERUSE_SYNDROME = 'OVERUSE_SYNDROME_OVERUSE_SYNDROME',
  OVERWEIGHT = 'OVERWEIGHT',
  PAGET_SCHROETTER_SYNDROME = 'PAGET_SCHROETTER_SYNDROME',
  PAGET_S_DISEASE_OF_THE_NIPPLE = 'PAGET_S_DISEASE_OF_THE_NIPPLE',
  PAGET_S_DISEASE_OSTEITIS_DEFORMANS = 'PAGET_S_DISEASE_OSTEITIS_DEFORMANS',
  PAINFUL_BLADDER_SYNDROME_INTERSTITIAL = 'PAINFUL_BLADDER_SYNDROME_INTERSTITIAL',
  PAIN_DURING_INTERCOURSE = 'PAIN_DURING_INTERCOURSE',
  PALATINE_TORUS = 'PALATINE_TORUS',
  PALLISTER_HALL_LIKE_SYNDROME = 'PALLISTER_HALL_LIKE_SYNDROME',
  PALLISTER_HALL_SYNDROME = 'PALLISTER_HALL_SYNDROME',
  PALLISTER_KILLIAN_LIKE_SYNDROME = 'PALLISTER_KILLIAN_LIKE_SYNDROME',
  PALLISTER_KILLIAN_SYNDROME = 'PALLISTER_KILLIAN_SYNDROME',
  PANCREAS = 'PANCREAS',
  PANCREAS_DIVISUM = 'PANCREAS_DIVISUM',
  PANCREATIC_ALVEOLAR_CELL_CARCINOMA_OF_THE_PANCREAS = 'PANCREATIC_ALVEOLAR_CELL_CARCINOMA_OF_THE_PANCREAS',
  PANCREATIC_BASIN_CELL_CARCINOMA_OF_THE_PANCREAS = 'PANCREATIC_BASIN_CELL_CARCINOMA_OF_THE_PANCREAS',
  PANCREATIC_CANCER = 'PANCREATIC_CANCER',
  PANCREATIC_CANCER_VARIANT_2 = 'PANCREATIC_CANCER_VARIANT_2',
  PANCREATIC_CARCINOMA = 'PANCREATIC_CARCINOMA',
  PANCREATIC_CYSTS = 'PANCREATIC_CYSTS',
  PANCREATIC_DUCTAL_CELL_CARCINOMA_OF_THE_PANCREAS = 'PANCREATIC_DUCTAL_CELL_CARCINOMA_OF_THE_PANCREAS',
  PANCREATIC_DUCT_TUMOR = 'PANCREATIC_DUCT_TUMOR',
  PANCREATIC_PROBLEMS = 'PANCREATIC_PROBLEMS',
  PANCREATIC_RING_CELL_CARCINOMA_OF_THE_PANCREAS = 'PANCREATIC_RING_CELL_CARCINOMA_OF_THE_PANCREAS',
  PANCREATITIS = 'PANCREATITIS',
  PANIC_ATTACKS = 'PANIC_ATTACKS',
  PANIC_ATTACK_DISORDER = 'PANIC_ATTACK_DISORDER',
  PANIC_DISORDER = 'PANIC_DISORDER',
  PANNER_S_DISEASE = 'PANNER_S_DISEASE',
  PANNICULITIS_ERYTHEMATOSUS = 'PANNICULITIS_ERYTHEMATOSUS',
  PANTOTHENATE_KINASE_ASSOCIATED_NEURODEGENERATION_PKAN_SYNDROME = 'PANTOTHENATE_KINASE_ASSOCIATED_NEURODEGENERATION_PKAN_SYNDROME',
  PAPA_SYNDROME = 'PAPA_SYNDROME',
  PAPILLARY_CELL_CARCINOMA_OF_THE_PANCREAS = 'PAPILLARY_CELL_CARCINOMA_OF_THE_PANCREAS',
  PAPILLARY_RENAL_CELL_CARCINOMA = 'PAPILLARY_RENAL_CELL_CARCINOMA',
  PAPILLOMA_OF_THE_CONJUNCTIVA = 'PAPILLOMA_OF_THE_CONJUNCTIVA',
  PARAGONIMIASIS_PULMONARY_DISTOMATOSIS = 'PARAGONIMIASIS_PULMONARY_DISTOMATOSIS',
  PARALYSIS_OF_THE_DIAPHRAGM = 'PARALYSIS_OF_THE_DIAPHRAGM',
  PARALYTIC_ILEO = 'PARALYTIC_ILEO',
  PARANEOPLASTIC_ATAXIA = 'PARANEOPLASTIC_ATAXIA',
  PARANOID_PERSONALITY_DISORDER = 'PARANOID_PERSONALITY_DISORDER',
  PARANOID_PERSONALITY_DISORDER_VARIANT_2 = 'PARANOID_PERSONALITY_DISORDER_VARIANT_2',
  PARAPROTEINEMIAS = 'PARAPROTEINEMIAS',
  PARAPSORIASIS = 'PARAPSORIASIS',
  PARASITIC_INFECTIONS_SUCH_AS_PINWORMS = 'PARASITIC_INFECTIONS_SUCH_AS_PINWORMS',
  PARATHYROIDS = 'PARATHYROIDS',
  PARATHYROID_CANCER = 'PARATHYROID_CANCER',
  PARATHYROID_PATHOLOGY_PARATHYROID_PROBLEM = 'PARATHYROID_PATHOLOGY_PARATHYROID_PROBLEM',
  PARATIFO = 'PARATIFO',
  PARIETAL_BONE_TUMOR = 'PARIETAL_BONE_TUMOR',
  PARINAUD_S_SYNDROME = 'PARINAUD_S_SYNDROME',
  PARKINSON_S_DISEASE = 'PARKINSON_S_DISEASE',
  PARKINSON_S_DISEASE_VARIANT_2 = 'PARKINSON_S_DISEASE_VARIANT_2',
  PAROTID = 'PAROTID',
  PAROTITIS_MUMPS = 'PAROTITIS_MUMPS',
  PAROXYSMAL_MOVEMENT_DISORDERS_PLMD = 'PAROXYSMAL_MOVEMENT_DISORDERS_PLMD',
  PAROXYSMAL_MOVEMENT_DISORDER_PLMD = 'PAROXYSMAL_MOVEMENT_DISORDER_PLMD',
  PARRY_ROMBERG_SYNDROME = 'PARRY_ROMBERG_SYNDROME',
  PATAU_SYNDROME = 'PATAU_SYNDROME',
  PATAU_SYNDROME_TRISOMY_13 = 'PATAU_SYNDROME_TRISOMY_13',
  PATELLAR_CHONDROMALACIA = 'PATELLAR_CHONDROMALACIA',
  PATELLAR_OVERLOAD_SYNDROMES = 'PATELLAR_OVERLOAD_SYNDROMES',
  PATELLAR_TENDINOPATHY = 'PATELLAR_TENDINOPATHY',
  PATELLOFEMORAL_PAIN_SYNDROME = 'PATELLOFEMORAL_PAIN_SYNDROME',
  PATHOLOGIES_AFFECTING_THE_ANKLE = 'PATHOLOGIES_AFFECTING_THE_ANKLE',
  PATHOLOGIES_AFFECTING_THE_HIP = 'PATHOLOGIES_AFFECTING_THE_HIP',
  PATHOLOGIES_AFFECTING_THE_KNEE = 'PATHOLOGIES_AFFECTING_THE_KNEE',
  PATHOLOGIES_AFFECTING_THE_NASAL_PASSAGES = 'PATHOLOGIES_AFFECTING_THE_NASAL_PASSAGES',
  PATHOLOGIES_AFFECTING_THE_WRIST = 'PATHOLOGIES_AFFECTING_THE_WRIST',
  PATHOLOGIES_AND_DISORDERS_OF_THE_PENIS = 'PATHOLOGIES_AND_DISORDERS_OF_THE_PENIS',
  PATHOLOGIES_AND_PROBLEMS_OF_THE_OVARIES = 'PATHOLOGIES_AND_PROBLEMS_OF_THE_OVARIES',
  PATHOLOGIES_DISORDERS_OF_THE_TESTICLES = 'PATHOLOGIES_DISORDERS_OF_THE_TESTICLES',
  PATHOLOGIES_INTESTINAL_PROBLEMS = 'PATHOLOGIES_INTESTINAL_PROBLEMS',
  PATHOLOGIES_LIVER_PROBLEMS = 'PATHOLOGIES_LIVER_PROBLEMS',
  PATHOLOGIES_STOMACH_PROBLEMS = 'PATHOLOGIES_STOMACH_PROBLEMS',
  PATHOLOGIES_TOOTH_PROBLEMS = 'PATHOLOGIES_TOOTH_PROBLEMS',
  PATHOLOGY_BOWEL_PROBLEMS = 'PATHOLOGY_BOWEL_PROBLEMS',
  PATHOLOGY_DISORDERS_OF_THE_PROSTATE = 'PATHOLOGY_DISORDERS_OF_THE_PROSTATE',
  PATHOLOGY_LIVER_PROBLEMS = 'PATHOLOGY_LIVER_PROBLEMS',
  PATHOLOGY_PROBLEMS_OF_THE_JAW_JOINT = 'PATHOLOGY_PROBLEMS_OF_THE_JAW_JOINT',
  PATHOLOGY_PROBLEMS_OF_THE_PANCREAS = 'PATHOLOGY_PROBLEMS_OF_THE_PANCREAS',
  PATHOLOGY_PROBLEMS_OF_THE_SPINE = 'PATHOLOGY_PROBLEMS_OF_THE_SPINE',
  PATHOLOGY_PROBLEMS_OF_THE_UTERUS = 'PATHOLOGY_PROBLEMS_OF_THE_UTERUS',
  PATHOLOGY_STOMACH_PROBLEMS = 'PATHOLOGY_STOMACH_PROBLEMS',
  PEARSON_S_SYNDROME = 'PEARSON_S_SYNDROME',
  PECTORALIS_MUSCLE_SYNDROME = 'PECTORALIS_MUSCLE_SYNDROME',
  PECTUS_CARINATUM = 'PECTUS_CARINATUM',
  PECTUS_EXCAVATUM = 'PECTUS_EXCAVATUM',
  PEDIATRIC_DISORDERS_SUCH_AS_INFANT_CEREBRAL_PALSY = 'PEDIATRIC_DISORDERS_SUCH_AS_INFANT_CEREBRAL_PALSY',
  PEDIATRIC_NEUROLOGICAL_DISEASES_SUCH_AS_CEREBRAL_PALSY = 'PEDIATRIC_NEUROLOGICAL_DISEASES_SUCH_AS_CEREBRAL_PALSY',
  PEDIATRIC_NEUROLOGICAL_DISORDERS = 'PEDIATRIC_NEUROLOGICAL_DISORDERS',
  PEDIATRIC_NEUROLOGICAL_DISORDERS_SUCH_AS_CHILDHOOD_EPILEPSY = 'PEDIATRIC_NEUROLOGICAL_DISORDERS_SUCH_AS_CHILDHOOD_EPILEPSY',
  PEDICULOSIS_OF_THE_BODY_HEAD_LICE = 'PEDICULOSIS_OF_THE_BODY_HEAD_LICE',
  PEDICULOSIS_OF_THE_HEAD_HEAD_LICE = 'PEDICULOSIS_OF_THE_HEAD_HEAD_LICE',
  PEDICULOSIS_PUBIS_HEAD_LICE = 'PEDICULOSIS_PUBIS_HEAD_LICE',
  PELLAGRA = 'PELLAGRA',
  PELVIC_ENDOMETRIOSIS = 'PELVIC_ENDOMETRIOSIS',
  PELVIC_FLOOR_DISORDERS_SUCH_AS_URINARY_INCONTINENCE = 'PELVIC_FLOOR_DISORDERS_SUCH_AS_URINARY_INCONTINENCE',
  PELVIC_FRACTURE = 'PELVIC_FRACTURE',
  PELVIC_INFLAMMATORY_DISEASE_PID = 'PELVIC_INFLAMMATORY_DISEASE_PID',
  PEMPHIGUS = 'PEMPHIGUS',
  PENETRATING_WOUND = 'PENETRATING_WOUND',
  PENETRATING_WOUNDS = 'PENETRATING_WOUNDS',
  PENILE_CANCER = 'PENILE_CANCER',
  PEPTIC_ULCER = 'PEPTIC_ULCER',
  PERFORATION_OF_THE_EARDRUM = 'PERFORATION_OF_THE_EARDRUM',
  PERIANAL_ABSCESS = 'PERIANAL_ABSCESS',
  PERICARDITIS = 'PERICARDITIS',
  PERICARDITIS_CONSTRICTIVA = 'PERICARDITIS_CONSTRICTIVA',
  PERICORONARITES = 'PERICORONARITES',
  PERIODONTITIS = 'PERIODONTITIS',
  PERIPHERAL_ARTERIAL_EMBOLISM = 'PERIPHERAL_ARTERIAL_EMBOLISM',
  PERIPHERAL_ARTERIAL_THROMBOSIS = 'PERIPHERAL_ARTERIAL_THROMBOSIS',
  PERIPHERAL_NERVE_INJURIES_SUCH_AS_CARPAL_TUNNEL_SYNDROME = 'PERIPHERAL_NERVE_INJURIES_SUCH_AS_CARPAL_TUNNEL_SYNDROME',
  PERIPHERAL_NERVE_INJURY = 'PERIPHERAL_NERVE_INJURY',
  PERIPHERAL_NERVOUS_SYSTEM_DISORDERS = 'PERIPHERAL_NERVOUS_SYSTEM_DISORDERS',
  PERIPHERAL_NEUROPATHIES_SUCH_AS_CARPAL_TUNNEL_SYNDROME = 'PERIPHERAL_NEUROPATHIES_SUCH_AS_CARPAL_TUNNEL_SYNDROME',
  PERIPHERAL_NEUROPATHY = 'PERIPHERAL_NEUROPATHY',
  PERIPHERAL_NEUROPATHY_BY_ANTI_MAG_ANTIBODIES = 'PERIPHERAL_NEUROPATHY_BY_ANTI_MAG_ANTIBODIES',
  PERIPHERAL_OBSTRUCTIVE_ARTERIOPATHY_PAOD = 'PERIPHERAL_OBSTRUCTIVE_ARTERIOPATHY_PAOD',
  PERIPHERAL_VASCULAR_DISEASE = 'PERIPHERAL_VASCULAR_DISEASE',
  PERISTOMAL_DERMATITIS = 'PERISTOMAL_DERMATITIS',
  PERRAULT_SYNDROME = 'PERRAULT_SYNDROME',
  PERSECUTION = 'PERSECUTION',
  PERSISTENCE_OF_DUCTUS_ARTERIOSUS = 'PERSISTENCE_OF_DUCTUS_ARTERIOSUS',
  PERSISTENT_ARTERIAL_CANAL = 'PERSISTENT_ARTERIAL_CANAL',
  PERSISTENT_BOTALLO_S_DUCTUS_SYNDROME = 'PERSISTENT_BOTALLO_S_DUCTUS_SYNDROME',
  PERSONALITY_DISORDERS_BORDERLINE_ANTISOCIAL_AVOIDANT_ETC = 'PERSONALITY_DISORDERS_BORDERLINE_ANTISOCIAL_AVOIDANT_ETC',
  PERTUSSIS = 'PERTUSSIS',
  PESTS = 'PESTS',
  PEUTZ_JEGHERS_SYNDROME = 'PEUTZ_JEGHERS_SYNDROME',
  PEYRONIE_S_DISEASE = 'PEYRONIE_S_DISEASE',
  PFEIFFER_SYNDROME = 'PFEIFFER_SYNDROME',
  PHARYNX = 'PHARYNX',
  PHELAN_MCDERMID_SYNDROME = 'PHELAN_MCDERMID_SYNDROME',
  PHENYLKETONURIA = 'PHENYLKETONURIA',
  PHEOCHROMOCYTOMA = 'PHEOCHROMOCYTOMA',
  PHIMOSIS_AND_PARAPHIMOSIS = 'PHIMOSIS_AND_PARAPHIMOSIS',
  PHONOLOGICAL_DISORDERS_DIFFICULTIES_IN_ORGANIZING_AND_STRUCTURING_SOUNDS_IN_SPEECH = 'PHONOLOGICAL_DISORDERS_DIFFICULTIES_IN_ORGANIZING_AND_STRUCTURING_SOUNDS_IN_SPEECH',
  PIERRE_ROBIN_SYNDROME = 'PIERRE_ROBIN_SYNDROME',
  PIGMENT_CELL_CARCINOMA_OF_THE_PANCREAS = 'PIGMENT_CELL_CARCINOMA_OF_THE_PANCREAS',
  PILON_S_FRACTURE = 'PILON_S_FRACTURE',
  PINEALOMA = 'PINEALOMA',
  PINEAL_GLAND_TUMOR = 'PINEAL_GLAND_TUMOR',
  PINGUICULA = 'PINGUICULA',
  PINT = 'PINT',
  PIRIFORMIS_MUSCLE_SYNDROME = 'PIRIFORMIS_MUSCLE_SYNDROME',
  PIRIFORMIS_SYNDROME = 'PIRIFORMIS_SYNDROME',
  PITT_HOPKINS_SYNDROME = 'PITT_HOPKINS_SYNDROME',
  PITUITARY_TUMOR = 'PITUITARY_TUMOR',
  PITYRIASIS_ALBA = 'PITYRIASIS_ALBA',
  PITYRIASIS_ROSEA = 'PITYRIASIS_ROSEA',
  PITYRIASIS_RUBRA_PILARIS = 'PITYRIASIS_RUBRA_PILARIS',
  PLACENTAL_CANCER = 'PLACENTAL_CANCER',
  PLACENTAL_RETENTION = 'PLACENTAL_RETENTION',
  PLACENTA_ACCRETA_INCRETA_PERCRETA = 'PLACENTA_ACCRETA_INCRETA_PERCRETA',
  PLACENTA_PREVIA = 'PLACENTA_PREVIA',
  PLANTAR_FASCIITIS = 'PLANTAR_FASCIITIS',
  PLAQUES_IN_THE_MOUTH = 'PLAQUES_IN_THE_MOUTH',
  PLASTIC_NOSE_JOB = 'PLASTIC_NOSE_JOB',
  PLATELETOPATHY = 'PLATELETOPATHY',
  PLATELETOPENIA = 'PLATELETOPENIA',
  PLEURAL_MESOTHELIOMA = 'PLEURAL_MESOTHELIOMA',
  PLUMMER_VINSON_SYNDROME = 'PLUMMER_VINSON_SYNDROME',
  PNEUMOCYSTOSIS = 'PNEUMOCYSTOSIS',
  PNEUMONIA = 'PNEUMONIA',
  PNEUMONIA_VARIANT_2 = 'PNEUMONIA_VARIANT_2',
  PNEUMOTHORAX = 'PNEUMOTHORAX',
  POEMS_SYNDROME = 'POEMS_SYNDROME',
  POLAND_SYNDROME = 'POLAND_SYNDROME',
  POLIOMYELITIS = 'POLIOMYELITIS',
  POLIO_POLIOMYELITIS = 'POLIO_POLIOMYELITIS',
  POLLEN_ALLERGY = 'POLLEN_ALLERGY',
  POLYCYSTIC_KIDNEY_DISEASE = 'POLYCYSTIC_KIDNEY_DISEASE',
  POLYCYSTIC_KIDNEY_DISEASE_VARIANT_2 = 'POLYCYSTIC_KIDNEY_DISEASE_VARIANT_2',
  POLYCYSTIC_OVARY_SYNDROME_PCOS = 'POLYCYSTIC_OVARY_SYNDROME_PCOS',
  POLYCYSTIC_OVARY_SYNDROME_PCOS_VARIANT_2 = 'POLYCYSTIC_OVARY_SYNDROME_PCOS_VARIANT_2',
  POLYCYSTIC_OVARY_SYNDROME_SOP = 'POLYCYSTIC_OVARY_SYNDROME_SOP',
  POLYCYTHEMIA_VERA = 'POLYCYTHEMIA_VERA',
  POLYCYTHEMIA_VERA_SYNDROME_WITH_CARDIAC_INVOLVEMENT = 'POLYCYTHEMIA_VERA_SYNDROME_WITH_CARDIAC_INVOLVEMENT',
  POLYMENORRHEA_TOO_FREQUENT_MENSTRUAL_CYCLES = 'POLYMENORRHEA_TOO_FREQUENT_MENSTRUAL_CYCLES',
  POLYMYALGIA_RHEUMATICA = 'POLYMYALGIA_RHEUMATICA',
  POLYMYASTIA = 'POLYMYASTIA',
  POLYMYOSITIS = 'POLYMYOSITIS',
  POLYPHARMACY_USE_OF_MANY_DRUGS_SIMULTANEOUSLY = 'POLYPHARMACY_USE_OF_MANY_DRUGS_SIMULTANEOUSLY',
  POLYPOSIS_SERRATA = 'POLYPOSIS_SERRATA',
  POPLITEAL_ARTERY_ANEURYSM = 'POPLITEAL_ARTERY_ANEURYSM',
  POPLITEAL_ARTERY_STENOSIS = 'POPLITEAL_ARTERY_STENOSIS',
  POPLITEAL_MUSCLE_SYNDROME = 'POPLITEAL_MUSCLE_SYNDROME',
  PORPHYRIA_CUTANEA_TARDA = 'PORPHYRIA_CUTANEA_TARDA',
  PORTAL_HYPERTENSION = 'PORTAL_HYPERTENSION',
  POSTERIOR_MEDIASTINAL_LYMPHOMA = 'POSTERIOR_MEDIASTINAL_LYMPHOMA',
  POSTERIOR_SKULL_BASE_FRACTURE = 'POSTERIOR_SKULL_BASE_FRACTURE',
  POSTERIOR_TIBIAL_MUSCLE_SYNDROME = 'POSTERIOR_TIBIAL_MUSCLE_SYNDROME',
  POSTERIOR_URETHRAL_VALVE = 'POSTERIOR_URETHRAL_VALVE',
  POSTPARTUM_ENDOMETRITIS = 'POSTPARTUM_ENDOMETRITIS',
  POSTPARTUM_HEMORRHAGE = 'POSTPARTUM_HEMORRHAGE',
  POSTURE_AND_BALANCE_DISORDERS = 'POSTURE_AND_BALANCE_DISORDERS',
  POSTURE_PROBLEMS = 'POSTURE_PROBLEMS',
  POST_INFECTIOUS_IRRITABLE_BOWEL_SYNDROME_PI_IBS = 'POST_INFECTIOUS_IRRITABLE_BOWEL_SYNDROME_PI_IBS',
  POST_TRAUMATIC_STRESS_DISORDER_PTSD = 'POST_TRAUMATIC_STRESS_DISORDER_PTSD',
  POUTEAU_COLLES_FRACTURE = 'POUTEAU_COLLES_FRACTURE',
  PRADER_WILLI_SYNDROME = 'PRADER_WILLI_SYNDROME',
  PRADER_WILLI_SYNDROME_15Q_DELETION = 'PRADER_WILLI_SYNDROME_15Q_DELETION',
  PRADER_WILLI_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT = 'PRADER_WILLI_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT',
  PREECLAMPSIA = 'PREECLAMPSIA',
  PREMATURE_BIRTH = 'PREMATURE_BIRTH',
  PREMATURE_EJACULATION = 'PREMATURE_EJACULATION',
  PREMATURE_RUPTURE_OF_MEMBRANES = 'PREMATURE_RUPTURE_OF_MEMBRANES',
  PREMENSTRUAL_SYNDROME_PMS = 'PREMENSTRUAL_SYNDROME_PMS',
  PREMENSTRUAL_SYNDROME_PMS_VARIANT_2 = 'PREMENSTRUAL_SYNDROME_PMS_VARIANT_2',
  PRESBYCUSIS = 'PRESBYCUSIS',
  PRESBYOPIA = 'PRESBYOPIA',
  PRESSURE_ULCERS_PRESSURE_SORES = 'PRESSURE_ULCERS_PRESSURE_SORES',
  PRIMARY_BILIARY_CIRRHOSIS = 'PRIMARY_BILIARY_CIRRHOSIS',
  PRIMARY_BONE_LYMPHOMA = 'PRIMARY_BONE_LYMPHOMA',
  PRIMARY_INTRAMEDULLARY_TUMOR = 'PRIMARY_INTRAMEDULLARY_TUMOR',
  PRIMARY_SCLEROSING_CHOLANGITIS = 'PRIMARY_SCLEROSING_CHOLANGITIS',
  PRIMARY_SCLEROSING_CHOLANGITIS_PSC = 'PRIMARY_SCLEROSING_CHOLANGITIS_PSC',
  PRINZMETAL_SYNDROME_PRINZMETAL_S_ANGINA = 'PRINZMETAL_SYNDROME_PRINZMETAL_S_ANGINA',
  PRION_DISEASES_SUCH_AS_CREUTZFELDT_JAKOB_DISEASE = 'PRION_DISEASES_SUCH_AS_CREUTZFELDT_JAKOB_DISEASE',
  PROBLEMS_AND_PATHOLOGIES_OF_THE_ADRENAL_GLAND = 'PROBLEMS_AND_PATHOLOGIES_OF_THE_ADRENAL_GLAND',
  PROBLEMS_OF_STATURE = 'PROBLEMS_OF_STATURE',
  PROBLEMS_OR_DISEASES_AFFECTING_THE_TONGUE = 'PROBLEMS_OR_DISEASES_AFFECTING_THE_TONGUE',
  PROBLEMS_WITH_BREAST_IMPLANTS = 'PROBLEMS_WITH_BREAST_IMPLANTS',
  PROBLEMS_WITH_PATHOLOGY_OF_THE_CONJUNCTIVA = 'PROBLEMS_WITH_PATHOLOGY_OF_THE_CONJUNCTIVA',
  PROCTITE = 'PROCTITE',
  PROGRESSIVE_ATAXIA = 'PROGRESSIVE_ATAXIA',
  PROGRESSIVE_NEUROMUSCULAR_DISEASES_SUCH_AS_MUSCULAR_DYSTROPHY = 'PROGRESSIVE_NEUROMUSCULAR_DISEASES_SUCH_AS_MUSCULAR_DYSTROPHY',
  PROLAPSE_OF_THE_PELVIC_ORGANS = 'PROLAPSE_OF_THE_PELVIC_ORGANS',
  PROSTATE_CANCER = 'PROSTATE_CANCER',
  PROSTATE_CANCER_TUMOR = 'PROSTATE_CANCER_TUMOR',
  PROSTATE_CANCER_VARIANT_2 = 'PROSTATE_CANCER_VARIANT_2',
  PROSTATE_CARCINOMA = 'PROSTATE_CARCINOMA',
  PROSTATITIS = 'PROSTATITIS',
  PROTEUS_SYNDROME = 'PROTEUS_SYNDROME',
  PRUNE_BELLY_SYNDROME = 'PRUNE_BELLY_SYNDROME',
  PRURIGO_NODULARIS = 'PRURIGO_NODULARIS',
  PSORIASIS = 'PSORIASIS',
  PSORIATIC_ARTHRITIS = 'PSORIATIC_ARTHRITIS',
  PSYCHIATRIC_DISORDERS = 'PSYCHIATRIC_DISORDERS',
  PSYCHOSIS = 'PSYCHOSIS',
  PSYCHOTIC_DISORDERS_SCHIZOPHRENIA_SCHIZOAFFECTIVE_DISORDER = 'PSYCHOTIC_DISORDERS_SCHIZOPHRENIA_SCHIZOAFFECTIVE_DISORDER',
  PTERYGIUM = 'PTERYGIUM',
  PTOSIS = 'PTOSIS',
  PUBALGIA = 'PUBALGIA',
  PULMONARY_ATRESIA = 'PULMONARY_ATRESIA',
  PULMONARY_EDEMA = 'PULMONARY_EDEMA',
  PULMONARY_EMBOLISM = 'PULMONARY_EMBOLISM',
  PULMONARY_EMBOLISM_PE = 'PULMONARY_EMBOLISM_PE',
  PULMONARY_EMPHYSEMA = 'PULMONARY_EMPHYSEMA',
  PULMONARY_EOSINOPHILIA = 'PULMONARY_EOSINOPHILIA',
  PULMONARY_HYPERTENSION = 'PULMONARY_HYPERTENSION',
  PULMONARY_STENOSIS = 'PULMONARY_STENOSIS',
  PULMONARY_THROMBOEMBOLISM = 'PULMONARY_THROMBOEMBOLISM',
  PULMONARY_TRAUMA = 'PULMONARY_TRAUMA',
  PULMONARY_TUBERCULOSIS = 'PULMONARY_TUBERCULOSIS',
  PULPITS = 'PULPITS',
  PURULENT = 'PURULENT',
  PYLORIC_STENOSIS = 'PYLORIC_STENOSIS',
  PYODERMA = 'PYODERMA',
  PYODERMA_GANGRENOSUM = 'PYODERMA_GANGRENOSUM',
  PYRUVATE_KINASE_DEFICIENCY_ANEMIA = 'PYRUVATE_KINASE_DEFICIENCY_ANEMIA',
  QUADRICEPS_FEMORIS_MUSCLE_SYNDROME = 'QUADRICEPS_FEMORIS_MUSCLE_SYNDROME',
  Q_FEVER = 'Q_FEVER',
  RADIAL_EXTENSOR_ULNA_MUSCLE_SYNDROME = 'RADIAL_EXTENSOR_ULNA_MUSCLE_SYNDROME',
  RADIAL_FLEXOR_CARPAL_MUSCLE_SYNDROME = 'RADIAL_FLEXOR_CARPAL_MUSCLE_SYNDROME',
  RADIAL_FLEXOR_ULNA_MUSCLE_SYNDROME = 'RADIAL_FLEXOR_ULNA_MUSCLE_SYNDROME',
  RADIATION_DERMATITIS = 'RADIATION_DERMATITIS',
  RADIATION_KIDNEY_DISEASE = 'RADIATION_KIDNEY_DISEASE',
  RAMSAY_HUNT_SYNDROME = 'RAMSAY_HUNT_SYNDROME',
  RAPID_EYE_MOVEMENT_REM_SLEEP_DISORDERS = 'RAPID_EYE_MOVEMENT_REM_SLEEP_DISORDERS',
  RASH_RASH = 'RASH_RASH',
  RAYNAUD_S_DISEASE = 'RAYNAUD_S_DISEASE',
  RAYNAUD_S_PHENOMENON = 'RAYNAUD_S_PHENOMENON',
  RAYNAUD_S_SYNDROME = 'RAYNAUD_S_SYNDROME',
  REACTIVE_ARTHRITIS = 'REACTIVE_ARTHRITIS',
  REACTIVE_ARTHRITIS_OR_REITER_S_SYNDROME = 'REACTIVE_ARTHRITIS_OR_REITER_S_SYNDROME',
  REACTIVE_ATTACHMENT_DISORDER = 'REACTIVE_ATTACHMENT_DISORDER',
  RECEPTIVE_LANGUAGE_DISORDERS_DIFFICULTY_IN_INTERPRETING_AND_UNDERSTANDING_LANGUAGE = 'RECEPTIVE_LANGUAGE_DISORDERS_DIFFICULTY_IN_INTERPRETING_AND_UNDERSTANDING_LANGUAGE',
  RECEPTIVE_LANGUAGE_DISORDER_DLR = 'RECEPTIVE_LANGUAGE_DISORDER_DLR',
  RECOVERY_AFTER_ACCIDENT_OR_SURGERY = 'RECOVERY_AFTER_ACCIDENT_OR_SURGERY',
  RECTAL_CANCERS = 'RECTAL_CANCERS',
  RECURRENT_ABORTION = 'RECURRENT_ABORTION',
  RECURRENT_DISEASES = 'RECURRENT_DISEASES',
  RECURRENT_MISCARRIAGE = 'RECURRENT_MISCARRIAGE',
  RECURRENT_POLYCONDRITE = 'RECURRENT_POLYCONDRITE',
  RECURRENT_RESPIRATORY_INFECTIONS = 'RECURRENT_RESPIRATORY_INFECTIONS',
  REDUCED_OPTICAL_FORAMEN = 'REDUCED_OPTICAL_FORAMEN',
  RED_BASED_MELANOCYTIC_NEVUS = 'RED_BASED_MELANOCYTIC_NEVUS',
  RED_SEA_VIRUS = 'RED_SEA_VIRUS',
  REFLEX_SYMPATHETIC_DYSTROPHY = 'REFLEX_SYMPATHETIC_DYSTROPHY',
  REFLUX = 'REFLUX',
  REFLUX_ESOPHAGITIS = 'REFLUX_ESOPHAGITIS',
  REFRACTORY_MYELODYSPLASIA = 'REFRACTORY_MYELODYSPLASIA',
  RELAPSING_POLYCHONDRITIS = 'RELAPSING_POLYCHONDRITIS',
  REM_SLEEP_BEHAVIORAL_SLEEP_DISORDER_RBD = 'REM_SLEEP_BEHAVIORAL_SLEEP_DISORDER_RBD',
  RENAL_AMYLOIDOSIS = 'RENAL_AMYLOIDOSIS',
  RENAL_ARTERY_ANEURYSM = 'RENAL_ARTERY_ANEURYSM',
  RENAL_ARTERY_HYPERPLASIA = 'RENAL_ARTERY_HYPERPLASIA',
  RENAL_ARTERY_STENOSIS = 'RENAL_ARTERY_STENOSIS',
  RENAL_CALCULOSIS = 'RENAL_CALCULOSIS',
  RENAL_CANCER = 'RENAL_CANCER',
  RENAL_CARCINOMA = 'RENAL_CARCINOMA',
  RENAL_CELL_CARCINOMA_OF_THE_PANCREAS = 'RENAL_CELL_CARCINOMA_OF_THE_PANCREAS',
  RENAL_INFECTIONS = 'RENAL_INFECTIONS',
  RENAL_MALFORMATIONS = 'RENAL_MALFORMATIONS',
  RENAL_REFLUX_DISEASE = 'RENAL_REFLUX_DISEASE',
  RENDU_OSLER_WEBER_DISEASE_OSLER_WEBER_RENDU_SYNDROME = 'RENDU_OSLER_WEBER_DISEASE_OSLER_WEBER_RENDU_SYNDROME',
  REPETITIVE_OVERLOAD_INJURIES_SUCH_AS_CARPAL_TUNNEL_SYNDROME = 'REPETITIVE_OVERLOAD_INJURIES_SUCH_AS_CARPAL_TUNNEL_SYNDROME',
  RESIDUAL_OVARY_SYNDROME = 'RESIDUAL_OVARY_SYNDROME',
  RESISTANT_OVARIAN_SYNDROME_LIKE = 'RESISTANT_OVARIAN_SYNDROME_LIKE',
  RESISTANT_OVARY_SYNDROME = 'RESISTANT_OVARY_SYNDROME',
  RESPIRATORY_ALLERGIES = 'RESPIRATORY_ALLERGIES',
  RESPIRATORY_DISORDERS = 'RESPIRATORY_DISORDERS',
  RESPIRATORY_DISORDERS_SUCH_AS_CHRONIC_BRONCHITIS = 'RESPIRATORY_DISORDERS_SUCH_AS_CHRONIC_BRONCHITIS',
  RESPIRATORY_FAILURE = 'RESPIRATORY_FAILURE',
  RESPIRATORY_INFECTIONS = 'RESPIRATORY_INFECTIONS',
  RESPIRATORY_OBSTRUCTIONS = 'RESPIRATORY_OBSTRUCTIONS',
  RESPIRATORY_PROBLEMS = 'RESPIRATORY_PROBLEMS',
  RESTLESS_LEGS_SYNDROME_RLS = 'RESTLESS_LEGS_SYNDROME_RLS',
  RESTLESS_LEG_SYNDROME = 'RESTLESS_LEG_SYNDROME',
  RESTLESS_LEG_SYNDROME_VARIANT_2 = 'RESTLESS_LEG_SYNDROME_VARIANT_2',
  RETINAL_DETACHMENT = 'RETINAL_DETACHMENT',
  RETINAL_PROBLEMS_OR_PATHOLOGY = 'RETINAL_PROBLEMS_OR_PATHOLOGY',
  RETINITIS_PIGMENTOSA = 'RETINITIS_PIGMENTOSA',
  RETINOBLASTOMA = 'RETINOBLASTOMA',
  RETINOPATHY_PIGMENTOSA = 'RETINOPATHY_PIGMENTOSA',
  RETROPHARYNGEAL_ABSCESS = 'RETROPHARYNGEAL_ABSCESS',
  RETT_SYNDROME = 'RETT_SYNDROME',
  RETT_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT = 'RETT_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT',
  REYE_S_SYNDROME = 'REYE_S_SYNDROME',
  REYNAUD_S_DISEASE = 'REYNAUD_S_DISEASE',
  RHABDOMYOSARCOMA = 'RHABDOMYOSARCOMA',
  RHEUMATOID_ARTHRITIS = 'RHEUMATOID_ARTHRITIS',
  RHINITIS = 'RHINITIS',
  RHINOPHYMA = 'RHINOPHYMA',
  RHINOPLASTY = 'RHINOPLASTY',
  RHOMBOID_MUSCLE_SYNDROME = 'RHOMBOID_MUSCLE_SYNDROME',
  RIB_FRACTURES = 'RIB_FRACTURES',
  RICKETTSIA_LIKE_ROCKY_MOUNTAIN_FEVER = 'RICKETTSIA_LIKE_ROCKY_MOUNTAIN_FEVER',
  RICKETTSIOSIS = 'RICKETTSIOSIS',
  RIFT_VALLEY_HEMORRHAGIC_FEVER = 'RIFT_VALLEY_HEMORRHAGIC_FEVER',
  RIFT_VALLEY_VIRUS = 'RIFT_VALLEY_VIRUS',
  RIGIDITY = 'RIGIDITY',
  RINGWORM_TINEA = 'RINGWORM_TINEA',
  ROKITANSKY_MAYER_K_STER_HAUSER_SYNDROME = 'ROKITANSKY_MAYER_K_STER_HAUSER_SYNDROME',
  ROLANDO_FRACTURE = 'ROLANDO_FRACTURE',
  ROMANO_WARD_SYNDROME_LQTS = 'ROMANO_WARD_SYNDROME_LQTS',
  RONCOPATHY = 'RONCOPATHY',
  ROOT_CYST = 'ROOT_CYST',
  ROSACEA = 'ROSACEA',
  ROSE_CELL_CARCINOMA_OF_THE_PANCREAS = 'ROSE_CELL_CARCINOMA_OF_THE_PANCREAS',
  ROSS_RIVER_FEVER = 'ROSS_RIVER_FEVER',
  ROUND_PRONATOR_MUSCLE_SYNDROME = 'ROUND_PRONATOR_MUSCLE_SYNDROME',
  RUBELLA = 'RUBELLA',
  RUBEOLA = 'RUBEOLA',
  RUBINSTEIN_TAYBI_SYNDROME = 'RUBINSTEIN_TAYBI_SYNDROME',
  RUNNER_S_KNEE_ILIOTIBIAL_BAND_SYNDROME = 'RUNNER_S_KNEE_ILIOTIBIAL_BAND_SYNDROME',
  RUPTURE_OF_LIGAMENTS = 'RUPTURE_OF_LIGAMENTS',
  RUPTURE_OF_THE_CRUCIATE_LIGAMENT = 'RUPTURE_OF_THE_CRUCIATE_LIGAMENT',
  RUPTURE_OF_VITREOUS_BODY = 'RUPTURE_OF_VITREOUS_BODY',
  SACRAL_MUSCLE_SYNDROME = 'SACRAL_MUSCLE_SYNDROME',
  SALIVARY_GLAND_CARCINOMA = 'SALIVARY_GLAND_CARCINOMA',
  SALIVARY_GLAND_SURGERY = 'SALIVARY_GLAND_SURGERY',
  SALMONELLA_INFECTIONS = 'SALMONELLA_INFECTIONS',
  SALMONELLOSIS = 'SALMONELLOSIS',
  SALPINGITIS_INFLAMMATION_OF_THE_FALLOPIAN_TUBES = 'SALPINGITIS_INFLAMMATION_OF_THE_FALLOPIAN_TUBES',
  SANDHOFF_S_DISEASE = 'SANDHOFF_S_DISEASE',
  SANFILIPPO_SYNDROME = 'SANFILIPPO_SYNDROME',
  SAPHO_SYNDROME = 'SAPHO_SYNDROME',
  SARCOIDOSIS = 'SARCOIDOSIS',
  SARCOPENIA_LOSS_OF_MUSCLE_MASS = 'SARCOPENIA_LOSS_OF_MUSCLE_MASS',
  SCA6_ATAXIA = 'SCA6_ATAXIA',
  SCA7_ATAXIA = 'SCA7_ATAXIA',
  SCA17_ATAXIA = 'SCA17_ATAXIA',
  SCABIES = 'SCABIES',
  SCALENE_MUSCLE_SYNDROME = 'SCALENE_MUSCLE_SYNDROME',
  SCAPULA_ELEVATOR_MUSCLE_SYNDROME = 'SCAPULA_ELEVATOR_MUSCLE_SYNDROME',
  SCARLET_FEVER = 'SCARLET_FEVER',
  SCARS = 'SCARS',
  SCARS_CONTRACTURES = 'SCARS_CONTRACTURES',
  SCAR_DEHISCENCES = 'SCAR_DEHISCENCES',
  SCHEIE_S_SYNDROME = 'SCHEIE_S_SYNDROME',
  SCHELTRO = 'SCHELTRO',
  SCHEUERMANN_S_DISEASE = 'SCHEUERMANN_S_DISEASE',
  SCHISTOSOMIASIS_BILHARZIA = 'SCHISTOSOMIASIS_BILHARZIA',
  SCHIZOAFFECTIVE_DISORDER = 'SCHIZOAFFECTIVE_DISORDER',
  SCHIZOID_PERSONALITY_DISORDER = 'SCHIZOID_PERSONALITY_DISORDER',
  SCHIZOID_PERSONALITY_DISORDER_VARIANT_2 = 'SCHIZOID_PERSONALITY_DISORDER_VARIANT_2',
  SCHIZOPHRENIA = 'SCHIZOPHRENIA',
  SCHIZOPHRENIC_DISORDER = 'SCHIZOPHRENIC_DISORDER',
  SCHIZOTYPAL_PERSONALITY_DISORDER = 'SCHIZOTYPAL_PERSONALITY_DISORDER',
  SCHIZOTYPAL_PERSONALITY_DISORDER_VARIANT_2 = 'SCHIZOTYPAL_PERSONALITY_DISORDER_VARIANT_2',
  SCHNITZLER_SYNDROME = 'SCHNITZLER_SYNDROME',
  SCHNYDER_S_DYSTROPHY = 'SCHNYDER_S_DYSTROPHY',
  SCIATICA = 'SCIATICA',
  SCLERODERMA = 'SCLERODERMA',
  SCOLIOSIS = 'SCOLIOSIS',
  SEBORRHEIC_DERMATITIS = 'SEBORRHEIC_DERMATITIS',
  SECONDARY_HYPERPARATHYROIDISM = 'SECONDARY_HYPERPARATHYROIDISM',
  SECONDARY_POLYCYTHEMIA = 'SECONDARY_POLYCYTHEMIA',
  SECONDARY_POLYCYTHEMIA_VERA = 'SECONDARY_POLYCYTHEMIA_VERA',
  SELECTIVE_EATING_DISORDER = 'SELECTIVE_EATING_DISORDER',
  SELECTIVE_EATING_DISORDER_ARFID = 'SELECTIVE_EATING_DISORDER_ARFID',
  SELECTIVE_FEEDING_DISORDERS_SUCH_AS_FOOD_SELECTIVITY = 'SELECTIVE_FEEDING_DISORDERS_SUCH_AS_FOOD_SELECTIVITY',
  SELECTIVE_IGA_DEFICIENCY = 'SELECTIVE_IGA_DEFICIENCY',
  SELECTIVE_MUTISM = 'SELECTIVE_MUTISM',
  SELLA_TURCICA_TUMOR = 'SELLA_TURCICA_TUMOR',
  SENSITIVITY_DISORDERS = 'SENSITIVITY_DISORDERS',
  SENSITIVITY_DISORDERS_SUCH_AS_SENSORY_NEUROPATHY = 'SENSITIVITY_DISORDERS_SUCH_AS_SENSORY_NEUROPATHY',
  SENSORIMOTOR_ATAXIA_SMA = 'SENSORIMOTOR_ATAXIA_SMA',
  SENSORY_PROCESSING_DISORDER_SPD = 'SENSORY_PROCESSING_DISORDER_SPD',
  SEROUS_CELL_CARCINOMA_OF_THE_PANCREAS = 'SEROUS_CELL_CARCINOMA_OF_THE_PANCREAS',
  SERTOLI_CELL_CARCINOMA = 'SERTOLI_CELL_CARCINOMA',
  SERTOLI_LEYDIG_CELL_CARCINOMA = 'SERTOLI_LEYDIG_CELL_CARCINOMA',
  SEVERE_ACUTE_RESPIRATORY_SYNDROME_SARS = 'SEVERE_ACUTE_RESPIRATORY_SYNDROME_SARS',
  SEVERE_COMBINED_IMMUNODEFICIENCY_SYNDROME_SCID = 'SEVERE_COMBINED_IMMUNODEFICIENCY_SYNDROME_SCID',
  SEVERE_HEMORRHAGIC_FEVER_WITH_THROMBOCYTOPENIA_SYNDROME_SFTS = 'SEVERE_HEMORRHAGIC_FEVER_WITH_THROMBOCYTOPENIA_SYNDROME_SFTS',
  SEVER_S_DISEASE = 'SEVER_S_DISEASE',
  SEVER_S_DISEASE_CALCANEAL_APOPHYSITIS = 'SEVER_S_DISEASE_CALCANEAL_APOPHYSITIS',
  SEXUALLY_TRANSMITTED_DISEASES = 'SEXUALLY_TRANSMITTED_DISEASES',
  SEXUALLY_TRANSMITTED_DISEASES_SUCH_AS_CHLAMYDIA_GONORRHEA_AND_SYPHILIS = 'SEXUALLY_TRANSMITTED_DISEASES_SUCH_AS_CHLAMYDIA_GONORRHEA_AND_SYPHILIS',
  SEXUAL_DISORDERS_ERECTILE_DYSFUNCTION_ORGASM_DISORDER_ETC = 'SEXUAL_DISORDERS_ERECTILE_DYSFUNCTION_ORGASM_DISORDER_ETC',
  SEXUAL_DYSFUNCTION = 'SEXUAL_DYSFUNCTION',
  SHARED_PSYCHOTIC_DISORDER = 'SHARED_PSYCHOTIC_DISORDER',
  SHEEHAN_LIKE_SYNDROME = 'SHEEHAN_LIKE_SYNDROME',
  SHEEHAN_S_SYNDROME = 'SHEEHAN_S_SYNDROME',
  SHEEHAN_S_SYNDROME_POSTPARTUM_HYPOPITUITARISM = 'SHEEHAN_S_SYNDROME_POSTPARTUM_HYPOPITUITARISM',
  SHIGELLOSIS = 'SHIGELLOSIS',
  SHINGLES_VIRUS_INFECTIONS_VARICELLA_AND_HERPES_ZOSTER = 'SHINGLES_VIRUS_INFECTIONS_VARICELLA_AND_HERPES_ZOSTER',
  SHORT_BOWEL_SYNDROME = 'SHORT_BOWEL_SYNDROME',
  SHORT_PERONEAL_MUSCLE_SYNDROME = 'SHORT_PERONEAL_MUSCLE_SYNDROME',
  SHORT_QT_SYNDROME = 'SHORT_QT_SYNDROME',
  SHOULDER_ARTHROSIS = 'SHOULDER_ARTHROSIS',
  SHOULDER_DYSTOCIA = 'SHOULDER_DYSTOCIA',
  SHWACHMAN_DIAMOND_SYNDROME = 'SHWACHMAN_DIAMOND_SYNDROME',
  SICCA_SYNDROME = 'SICCA_SYNDROME',
  SICK_NODE_SYNDROME = 'SICK_NODE_SYNDROME',
  SICK_SINUS_SYNDROME = 'SICK_SINUS_SYNDROME',
  SIDEROBLASTIC_ANEMIA = 'SIDEROBLASTIC_ANEMIA',
  SIGMOID_COLON_CANCER = 'SIGMOID_COLON_CANCER',
  SIGNET_RING_CELL_CARCINOMA_OF_THE_STOMACH = 'SIGNET_RING_CELL_CARCINOMA_OF_THE_STOMACH',
  SILVER_RUSSELL_SYNDROME = 'SILVER_RUSSELL_SYNDROME',
  SIMPSON_GOLABI_BEHMEL_LIKE_SYNDROME = 'SIMPSON_GOLABI_BEHMEL_LIKE_SYNDROME',
  SIMPSON_GOLABI_BEHMEL_SYNDROME = 'SIMPSON_GOLABI_BEHMEL_SYNDROME',
  SINDING_LARSEN_JOHANSSON_DISEASE = 'SINDING_LARSEN_JOHANSSON_DISEASE',
  SINUSITIS = 'SINUSITIS',
  SINUS_SURGERY = 'SINUS_SURGERY',
  SINUS_TUMOR = 'SINUS_TUMOR',
  SJOGREN_S_SYNDROME_WITH_CARDIAC_INVOLVEMENT = 'SJOGREN_S_SYNDROME_WITH_CARDIAC_INVOLVEMENT',
  SJ_GREN_S_SYNDROME = 'SJ_GREN_S_SYNDROME',
  SKELETAL_MALOCCLUSION = 'SKELETAL_MALOCCLUSION',
  SKIN_AND_SOFT_TISSUE_INFECTIONS = 'SKIN_AND_SOFT_TISSUE_INFECTIONS',
  SKIN_AND_SUBCUTANEOUS_TISSUE_DISORDERS_OF_THE_BREAST = 'SKIN_AND_SUBCUTANEOUS_TISSUE_DISORDERS_OF_THE_BREAST',
  SKIN_CANCER = 'SKIN_CANCER',
  SKIN_CANCERS = 'SKIN_CANCERS',
  SKIN_CANCER_VARIANT_2 = 'SKIN_CANCER_VARIANT_2',
  SKIN_DISCOLORATION = 'SKIN_DISCOLORATION',
  SKIN_DISEASES_SUCH_AS_ECZEMA_PSORIASIS_AND_ACNE = 'SKIN_DISEASES_SUCH_AS_ECZEMA_PSORIASIS_AND_ACNE',
  SKIN_IRRITATION = 'SKIN_IRRITATION',
  SKIN_IRRITATIONS = 'SKIN_IRRITATIONS',
  SKIN_RASH = 'SKIN_RASH',
  SKIN_RASH_VARIANT_2 = 'SKIN_RASH_VARIANT_2',
  SKIN_SPOTS = 'SKIN_SPOTS',
  SKIN_TUMORS = 'SKIN_TUMORS',
  SKULL_FRACTURE = 'SKULL_FRACTURE',
  SKULL_FRACTURES = 'SKULL_FRACTURES',
  SKULL_FRACTURE_VARIANT_2 = 'SKULL_FRACTURE_VARIANT_2',
  SLEEPING_SICKNESS_DISEASE_AFRICAN_TRYPANOSOMIASIS = 'SLEEPING_SICKNESS_DISEASE_AFRICAN_TRYPANOSOMIASIS',
  SLEEP_DISORDERS = 'SLEEP_DISORDERS',
  SLEEP_DISORDERS_ASSOCIATED_WITH_MEDICAL_CONDITIONS = 'SLEEP_DISORDERS_ASSOCIATED_WITH_MEDICAL_CONDITIONS',
  SLEEP_DISORDERS_INSOMNIA_SLEEP_APNEA = 'SLEEP_DISORDERS_INSOMNIA_SLEEP_APNEA',
  SLEEP_DISORDERS_INSOMNIA_SLEEP_APNEA_NARCOLEPSY = 'SLEEP_DISORDERS_INSOMNIA_SLEEP_APNEA_NARCOLEPSY',
  SLEEP_DISORDERS_OF_CIRCADIAN_RHYTHM = 'SLEEP_DISORDERS_OF_CIRCADIAN_RHYTHM',
  SLEEP_DISORDERS_SUCH_AS_SLEEP_APNEA_NARCOLEPSY = 'SLEEP_DISORDERS_SUCH_AS_SLEEP_APNEA_NARCOLEPSY',
  SLEEP_DISORDER_ASSOCIATED_WITH_NARCOLEPSY = 'SLEEP_DISORDER_ASSOCIATED_WITH_NARCOLEPSY',
  SLEEP_DISORDER_INCLUDING_INSOMNIA_AND_NARCOLEPSY = 'SLEEP_DISORDER_INCLUDING_INSOMNIA_AND_NARCOLEPSY',
  SLEEP_DISORDER_RELATED_TO_BREATHING_SDB = 'SLEEP_DISORDER_RELATED_TO_BREATHING_SDB',
  SLEEP_DISORDER_SUCH_AS_INSOMNIA_AND_SLEEP_APNEA = 'SLEEP_DISORDER_SUCH_AS_INSOMNIA_AND_SLEEP_APNEA',
  SLIMMING = 'SLIMMING',
  SLY_SYNDROME = 'SLY_SYNDROME',
  SMALL_CELL_CARCINOMA_OF_THE_LUNG = 'SMALL_CELL_CARCINOMA_OF_THE_LUNG',
  SMALL_CELL_LUNG_CANCER = 'SMALL_CELL_LUNG_CANCER',
  SMALL_CELL_LUNG_CARCINOMA = 'SMALL_CELL_LUNG_CARCINOMA',
  SMALL_GLUTEAL_MUSCLE_SYNDROME = 'SMALL_GLUTEAL_MUSCLE_SYNDROME',
  SMALL_INTESTINE = 'SMALL_INTESTINE',
  SMALL_ROUND_SYNDROME = 'SMALL_ROUND_SYNDROME',
  SMITH_LEMLI_OPITZ_SYNDROME = 'SMITH_LEMLI_OPITZ_SYNDROME',
  SMITH_MAGENIS_SYNDROME = 'SMITH_MAGENIS_SYNDROME',
  SMITH_MAGENIS_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT = 'SMITH_MAGENIS_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT',
  SMITH_S_FRACTURE = 'SMITH_S_FRACTURE',
  SNORING_SNORING = 'SNORING_SNORING',
  SOCIAL_COMMUNICATION_DISORDERS = 'SOCIAL_COMMUNICATION_DISORDERS',
  SOCIAL_PHOBIA = 'SOCIAL_PHOBIA',
  SOFT_TISSUE_CANCER = 'SOFT_TISSUE_CANCER',
  SOFT_TISSUE_INJURIES_SUCH_AS_MUSCLE_STRAINS_AND_TEARS = 'SOFT_TISSUE_INJURIES_SUCH_AS_MUSCLE_STRAINS_AND_TEARS',
  SOFT_TISSUE_SARCOMA = 'SOFT_TISSUE_SARCOMA',
  SOFT_TISSUE_SARCOMA_TUMOR = 'SOFT_TISSUE_SARCOMA_TUMOR',
  SOFT_TISSUE_TUMORS = 'SOFT_TISSUE_TUMORS',
  SOLAR_FRECKLES = 'SOLAR_FRECKLES',
  SOLEUS_MUSCLE_SYNDROME = 'SOLEUS_MUSCLE_SYNDROME',
  SOLITARY_BONE_CYST = 'SOLITARY_BONE_CYST',
  SOMATIC_DISORDER = 'SOMATIC_DISORDER',
  SOMATIZATION_DISORDER = 'SOMATIZATION_DISORDER',
  SORSBY_S_SYNDROME = 'SORSBY_S_SYNDROME',
  SOTOS_SYNDROME = 'SOTOS_SYNDROME',
  SOTO_SYNDROME = 'SOTO_SYNDROME',
  SPASTICITY_DISORDERS = 'SPASTICITY_DISORDERS',
  SPECIFIC_IMMUNOGLOBULIN_DEFICIENCY_SIGAD = 'SPECIFIC_IMMUNOGLOBULIN_DEFICIENCY_SIGAD',
  SPECIFIC_LANGUAGE_DISORDER_SLD = 'SPECIFIC_LANGUAGE_DISORDER_SLD',
  SPECIFIC_LEARNING_DISORDER_SLD = 'SPECIFIC_LEARNING_DISORDER_SLD',
  SPECIFIC_PHOBIA = 'SPECIFIC_PHOBIA',
  SPEECH_DISORDERS = 'SPEECH_DISORDERS',
  SPEECH_DISORDERS_SUCH_AS_DYSARTHRIA = 'SPEECH_DISORDERS_SUCH_AS_DYSARTHRIA',
  SPHENOID_SINUS_TUMOR = 'SPHENOID_SINUS_TUMOR',
  SPILOMELANIC_MELANOCYTIC_NEVUS = 'SPILOMELANIC_MELANOCYTIC_NEVUS',
  SPINAL_CORD_CYST = 'SPINAL_CORD_CYST',
  SPINAL_CORD_HEMORRHAGE = 'SPINAL_CORD_HEMORRHAGE',
  SPINAL_CORD_INJURIES = 'SPINAL_CORD_INJURIES',
  SPINAL_CORD_TUMOR = 'SPINAL_CORD_TUMOR',
  SPINAL_MUSCULAR_ATROPHY_SMA = 'SPINAL_MUSCULAR_ATROPHY_SMA',
  SPINAL_STENOSIS = 'SPINAL_STENOSIS',
  SPINAL_TRAUMA = 'SPINAL_TRAUMA',
  SPINA_BIFIDA = 'SPINA_BIFIDA',
  SPINDEL_CELL_CARCINOMA_OF_THE_PANCREAS = 'SPINDEL_CELL_CARCINOMA_OF_THE_PANCREAS',
  SPINDLE_CELL_CARCINOMA_OF_THE_PANCREAS = 'SPINDLE_CELL_CARCINOMA_OF_THE_PANCREAS',
  SPINOCELLULAR_CARCINOMA = 'SPINOCELLULAR_CARCINOMA',
  SPIRAL_CELL_CARCINOMA_OF_THE_PANCREAS = 'SPIRAL_CELL_CARCINOMA_OF_THE_PANCREAS',
  SPITZOID_MELANOCYTIC_NEVUS = 'SPITZOID_MELANOCYTIC_NEVUS',
  SPLEEN = 'SPLEEN',
  SPLEEN_CANCER = 'SPLEEN_CANCER',
  SPLENIC_ARTERY_ANEURYSM = 'SPLENIC_ARTERY_ANEURYSM',
  SPONDYLOLISTHESIS = 'SPONDYLOLISTHESIS',
  SPONDYLOLYSIS_AND_SPONDYLOLISTHESIS = 'SPONDYLOLYSIS_AND_SPONDYLOLISTHESIS',
  SPONTANEOUS_CEREBELLAR_HEMORRHAGE = 'SPONTANEOUS_CEREBELLAR_HEMORRHAGE',
  SPONTANEOUS_INTRACEREBRAL_HEMORRHAGE = 'SPONTANEOUS_INTRACEREBRAL_HEMORRHAGE',
  SPONTANEOUS_INTRACRANIAL_HEMORRHAGE = 'SPONTANEOUS_INTRACRANIAL_HEMORRHAGE',
  SPONTANEOUS_INTRAVENTRICULAR_HEMORRHAGE = 'SPONTANEOUS_INTRAVENTRICULAR_HEMORRHAGE',
  SPONTANEOUS_SUBARACHNOID_HEMORRHAGE = 'SPONTANEOUS_SUBARACHNOID_HEMORRHAGE',
  SPOROTRICHOSIS = 'SPOROTRICHOSIS',
  SPORTSMAN_S_SHOULDER_ROTATOR_CUFF_INJURY = 'SPORTSMAN_S_SHOULDER_ROTATOR_CUFF_INJURY',
  SPORTS_INJURIES = 'SPORTS_INJURIES',
  SPORTS_INJURIES_AND_RELATED_CONDITIONS = 'SPORTS_INJURIES_AND_RELATED_CONDITIONS',
  SPORTS_RELATED_INJURIES = 'SPORTS_RELATED_INJURIES',
  SPRAINS_AND_DISLOCATIONS = 'SPRAINS_AND_DISLOCATIONS',
  SQUAMOUS_CELL_CARCINOMA = 'SQUAMOUS_CELL_CARCINOMA',
  SQUAMOUS_CELL_CARCINOMA_OF_THE_ANUS = 'SQUAMOUS_CELL_CARCINOMA_OF_THE_ANUS',
  SQUAMOUS_CELL_CARCINOMA_OF_THE_COLON = 'SQUAMOUS_CELL_CARCINOMA_OF_THE_COLON',
  SQUAMOUS_CELL_CARCINOMA_OF_THE_ESOPHAGUS = 'SQUAMOUS_CELL_CARCINOMA_OF_THE_ESOPHAGUS',
  SQUAMOUS_CELL_CARCINOMA_OF_THE_LUNG = 'SQUAMOUS_CELL_CARCINOMA_OF_THE_LUNG',
  SQUAMOUS_CELL_CARCINOMA_OF_THE_PANCREAS = 'SQUAMOUS_CELL_CARCINOMA_OF_THE_PANCREAS',
  SQUAMOUS_CELL_CARCINOMA_OF_THE_RECTUM = 'SQUAMOUS_CELL_CARCINOMA_OF_THE_RECTUM',
  SQUAMOUS_CELL_LUNG_CARCINOMA = 'SQUAMOUS_CELL_LUNG_CARCINOMA',
  SQUARE_PRONATOR_MUSCLE_SYNDROME = 'SQUARE_PRONATOR_MUSCLE_SYNDROME',
  STAPHYLOCOCCUS_AUREUS_INFECTIONS_INCLUDING_MRSA = 'STAPHYLOCOCCUS_AUREUS_INFECTIONS_INCLUDING_MRSA',
  STARGARDT_SYNDROME = 'STARGARDT_SYNDROME',
  STARGARDT_S_DYSTROPHY = 'STARGARDT_S_DYSTROPHY',
  STAR_CELL_CARCINOMA_OF_THE_PANCREAS = 'STAR_CELL_CARCINOMA_OF_THE_PANCREAS',
  STASIS_DERMATITIS = 'STASIS_DERMATITIS',
  STEINERT_S_MYOTONIC_DYSTROPHY = 'STEINERT_S_MYOTONIC_DYSTROPHY',
  STEIN_LEVENTHAL_LIKE_SYNDROME = 'STEIN_LEVENTHAL_LIKE_SYNDROME',
  STEIN_LEVENTHAL_SYNDROME = 'STEIN_LEVENTHAL_SYNDROME',
  STENOSIS_OF_THE_INFERIOR_MESENTERIC_ARTERY = 'STENOSIS_OF_THE_INFERIOR_MESENTERIC_ARTERY',
  STENOSIS_OF_THE_SUPERIOR_MESENTERIC_ARTERY = 'STENOSIS_OF_THE_SUPERIOR_MESENTERIC_ARTERY',
  STENOSIS_OF_THE_URETHRA = 'STENOSIS_OF_THE_URETHRA',
  STEREOTYPED_MOVEMENT_DISORDER = 'STEREOTYPED_MOVEMENT_DISORDER',
  STERILITY_INFERTILITY = 'STERILITY_INFERTILITY',
  STERILIZATION_BY_TUBAL_OCCLUSION_ESSURE_METHOD = 'STERILIZATION_BY_TUBAL_OCCLUSION_ESSURE_METHOD',
  STERNOCLEIDOMASTOID_MUSCLE_SYNDROME = 'STERNOCLEIDOMASTOID_MUSCLE_SYNDROME',
  STEVENS_JOHNSON_SYNDROME = 'STEVENS_JOHNSON_SYNDROME',
  STICKLER_S_SYNDROME = 'STICKLER_S_SYNDROME',
  STICK_CELL_CARCINOMA_OF_THE_PANCREAS = 'STICK_CELL_CARCINOMA_OF_THE_PANCREAS',
  STIFF_PERSON_SYNDROME = 'STIFF_PERSON_SYNDROME',
  STILL_S_SYNDROME_OF_THE_ADULT = 'STILL_S_SYNDROME_OF_THE_ADULT',
  STOMACH = 'STOMACH',
  STOMACH_CANCER = 'STOMACH_CANCER',
  STOMACH_CANCER_TUMOR = 'STOMACH_CANCER_TUMOR',
  STOMACH_CANCER_VARIANT_2 = 'STOMACH_CANCER_VARIANT_2',
  STORAGE_POOL_THROMBOCYTOPATHY = 'STORAGE_POOL_THROMBOCYTOPATHY',
  STRABISMUS = 'STRABISMUS',
  STREPTOCOCCAL_INFECTIONS_SUCH_AS_TONSILLITIS = 'STREPTOCOCCAL_INFECTIONS_SUCH_AS_TONSILLITIS',
  STRESS_FRACTURE = 'STRESS_FRACTURE',
  STRESS_FRACTURES = 'STRESS_FRACTURES',
  STRESS_URINARY_INCONTINENCE = 'STRESS_URINARY_INCONTINENCE',
  STROKE = 'STROKE',
  STROKE_BRAIN_STROKE = 'STROKE_BRAIN_STROKE',
  STROMAL_CELL_CARCINOMA_OF_THE_PANCREAS = 'STROMAL_CELL_CARCINOMA_OF_THE_PANCREAS',
  STRONGYLOIDIASIS_ESTRONGYLOIDIASIS = 'STRONGYLOIDIASIS_ESTRONGYLOIDIASIS',
  STURGE_WEBER_SYNDROME = 'STURGE_WEBER_SYNDROME',
  STURGE_WEBER_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT = 'STURGE_WEBER_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT',
  STYE = 'STYE',
  ST_LOUIS_FEVER_VIRUS = 'ST_LOUIS_FEVER_VIRUS',
  SUBARACHNOID_HEMORRHAGE = 'SUBARACHNOID_HEMORRHAGE',
  SUBCLAVIAN_ARTERY_ANEURYSM = 'SUBCLAVIAN_ARTERY_ANEURYSM',
  SUBCLAVIAN_VEIN_COMPRESSION_SYNDROME = 'SUBCLAVIAN_VEIN_COMPRESSION_SYNDROME',
  SUBDURAL_HEMATOMA = 'SUBDURAL_HEMATOMA',
  SUBSCAPULARIS_SYNDROME = 'SUBSCAPULARIS_SYNDROME',
  SUBSTANCE_USE_DISORDER_ALCOHOL_DRUGS = 'SUBSTANCE_USE_DISORDER_ALCOHOL_DRUGS',
  SUDDEN_DEAFNESS = 'SUDDEN_DEAFNESS',
  SUDECK_SYNDROME = 'SUDECK_SYNDROME',
  SUPERFICIAL_FINGER_FLEXOR_MUSCLE_SYNDROME = 'SUPERFICIAL_FINGER_FLEXOR_MUSCLE_SYNDROME',
  SUPERFICIAL_THROMBOPHLEBITIS = 'SUPERFICIAL_THROMBOPHLEBITIS',
  SUPERIOR_MESENTERIC_ARTERY_ANEURYSM = 'SUPERIOR_MESENTERIC_ARTERY_ANEURYSM',
  SUPERIOR_MESENTERIC_ARTERY_ANEURYSM_SMA = 'SUPERIOR_MESENTERIC_ARTERY_ANEURYSM_SMA',
  SUPRASPINATUS_SYNDROME = 'SUPRASPINATUS_SYNDROME',
  SURGERY_FOR_CORRECTION_OF_UTERINE_PROLAPSE = 'SURGERY_FOR_CORRECTION_OF_UTERINE_PROLAPSE',
  SURGERY_FOR_OVARIAN_CYST_REMOVAL = 'SURGERY_FOR_OVARIAN_CYST_REMOVAL',
  SURGERY_FOR_THE_CORRECTION_OF_ENDOMETRIOSIS = 'SURGERY_FOR_THE_CORRECTION_OF_ENDOMETRIOSIS',
  SURGERY_FOR_THE_REMOVAL_OF_FIBROIDS = 'SURGERY_FOR_THE_REMOVAL_OF_FIBROIDS',
  SURGICAL_TREATMENT_OF_OBSTRUCTIVE_SLEEP_APNEA = 'SURGICAL_TREATMENT_OF_OBSTRUCTIVE_SLEEP_APNEA',
  SURGICAL_WOUNDS = 'SURGICAL_WOUNDS',
  SUSAC_S_SYNDROME = 'SUSAC_S_SYNDROME',
  SWALLOWING_DISORDERS = 'SWALLOWING_DISORDERS',
  SWALLOWING_DISORDERS_DYSPHAGIA = 'SWALLOWING_DISORDERS_DYSPHAGIA',
  SWALLOWING_PROBLEMS = 'SWALLOWING_PROBLEMS',
  SWEET_ASLEEP_SYNDROME_TRIGEMINO_AUTONOMIC_HEADACHE = 'SWEET_ASLEEP_SYNDROME_TRIGEMINO_AUTONOMIC_HEADACHE',
  SWEET_S_SYNDROME = 'SWEET_S_SYNDROME',
  SWOLLEN_LEGS = 'SWOLLEN_LEGS',
  SWYER_S_SYNDROME = 'SWYER_S_SYNDROME',
  SYMPATHETIC_OPHTHALMIA = 'SYMPATHETIC_OPHTHALMIA',
  SYNDROME_OF_THE_LONG_RADIAL_EXTENSOR_MUSCLE_OF_THE_CARPUS = 'SYNDROME_OF_THE_LONG_RADIAL_EXTENSOR_MUSCLE_OF_THE_CARPUS',
  SYNDROME_OF_THE_SYNOVIAL_VESSEL_THORACIC_PAIN_SYNDROME = 'SYNDROME_OF_THE_SYNOVIAL_VESSEL_THORACIC_PAIN_SYNDROME',
  SYNOVIAL_CYST = 'SYNOVIAL_CYST',
  SYRINGOBULBIA = 'SYRINGOBULBIA',
  SYRINGOMYELIA = 'SYRINGOMYELIA',
  SYSTEMIC_LUPUS_ERYTHEMATOSUS = 'SYSTEMIC_LUPUS_ERYTHEMATOSUS',
  SYSTEMIC_LUPUS_ERYTHEMATOSUS_SLE = 'SYSTEMIC_LUPUS_ERYTHEMATOSUS_SLE',
  SYSTEMIC_MASTOCYTOSIS_SYNDROMES = 'SYSTEMIC_MASTOCYTOSIS_SYNDROMES',
  SYSTEMIC_SCLERODERMA = 'SYSTEMIC_SCLERODERMA',
  TAKAYASU_SYNDROME = 'TAKAYASU_SYNDROME',
  TAKAYASU_S_ARTERITIS = 'TAKAYASU_S_ARTERITIS',
  TAKOTSUBO_SYNDROME_STRESS_CARDIOMYOPATHY = 'TAKOTSUBO_SYNDROME_STRESS_CARDIOMYOPATHY',
  TAKO_TSUBO_SYNDROME = 'TAKO_TSUBO_SYNDROME',
  TARSAL_TUNNEL_SYNDROME = 'TARSAL_TUNNEL_SYNDROME',
  TAUSSIG_BING_SYNDROME_WITH_CARDIAC_INVOLVEMENT = 'TAUSSIG_BING_SYNDROME_WITH_CARDIAC_INVOLVEMENT',
  TAY_SACHS_DISEASE = 'TAY_SACHS_DISEASE',
  TELEANGIECTASIAS = 'TELEANGIECTASIAS',
  TELEANGIECTATIC_OSTEOSARCOMA = 'TELEANGIECTATIC_OSTEOSARCOMA',
  TEMPORAL_BONE_TUMOR = 'TEMPORAL_BONE_TUMOR',
  TEMPOROMANDIBULAR_DISORDERS_SUCH_AS_TEMPOROMANDIBULAR_JOINT_DYSFUNCTION_OR_JAW_TENSOR_HEADACHES = 'TEMPOROMANDIBULAR_DISORDERS_SUCH_AS_TEMPOROMANDIBULAR_JOINT_DYSFUNCTION_OR_JAW_TENSOR_HEADACHES',
  TEMPOROMANDIBULAR_DISORDERS_TMD = 'TEMPOROMANDIBULAR_DISORDERS_TMD',
  TEMPOROMANDIBULAR_DYSFUNCTION_TMD = 'TEMPOROMANDIBULAR_DYSFUNCTION_TMD',
  TEMPOROMANDIBULAR_JOINT_TMJ_SURGERY = 'TEMPOROMANDIBULAR_JOINT_TMJ_SURGERY',
  TENDINITIS = 'TENDINITIS',
  TENSION_HEADACHES = 'TENSION_HEADACHES',
  TESTES_IN_MEN = 'TESTES_IN_MEN',
  TESTICULAR_CANCER = 'TESTICULAR_CANCER',
  TESTICULAR_CANCER_VARIANT_2 = 'TESTICULAR_CANCER_VARIANT_2',
  TESTICULAR_PROBLEMS = 'TESTICULAR_PROBLEMS',
  TESTICULAR_TORSION = 'TESTICULAR_TORSION',
  TESTICULAR_TUMORS = 'TESTICULAR_TUMORS',
  TETANUS = 'TETANUS',
  TETRALOGY_OF_FALLOT = 'TETRALOGY_OF_FALLOT',
  THALASSEMIA = 'THALASSEMIA',
  THIRD_VENTRICLE_CYST = 'THIRD_VENTRICLE_CYST',
  THROAT_CANCER = 'THROAT_CANCER',
  THROMBOANGEITIS_OBLITERANS_BUERGER_S_DISEASE = 'THROMBOANGEITIS_OBLITERANS_BUERGER_S_DISEASE',
  THROMBOANGIITIS_OBLITERANS_BUERGER_S_DISEASE = 'THROMBOANGIITIS_OBLITERANS_BUERGER_S_DISEASE',
  THROMBOCYTOPENIA = 'THROMBOCYTOPENIA',
  THROMBOPHILIA = 'THROMBOPHILIA',
  THYME = 'THYME',
  THYMUS_CANCER = 'THYMUS_CANCER',
  THYROGLOSSAL_CYST_OF_THE_ANTERIOR_MEDIASTINUM = 'THYROGLOSSAL_CYST_OF_THE_ANTERIOR_MEDIASTINUM',
  THYROID = 'THYROID',
  THYROIDITIS = 'THYROIDITIS',
  THYROIDITIS_SUCH_AS_HASHIMOTO_S_THYROIDITIS = 'THYROIDITIS_SUCH_AS_HASHIMOTO_S_THYROIDITIS',
  THYROID_CANCER = 'THYROID_CANCER',
  THYROID_CANCER_VARIANT_2 = 'THYROID_CANCER_VARIANT_2',
  THYROID_CARCINOMA = 'THYROID_CARCINOMA',
  THYROID_CARCINOMA_VARIANT_2 = 'THYROID_CARCINOMA_VARIANT_2',
  THYROID_DISEASES_HYPO_OR_HYPERTHYROIDISM = 'THYROID_DISEASES_HYPO_OR_HYPERTHYROIDISM',
  THYROID_DISEASE_SUCH_AS_HYPOTHYROIDISM_AND_HYPERTHYROIDISM = 'THYROID_DISEASE_SUCH_AS_HYPOTHYROIDISM_AND_HYPERTHYROIDISM',
  THYROID_DISORDERS_SUCH_AS_MULTINODULAR_GOITER = 'THYROID_DISORDERS_SUCH_AS_MULTINODULAR_GOITER',
  THYROID_DYSFUNCTION = 'THYROID_DYSFUNCTION',
  THYROID_NODULES = 'THYROID_NODULES',
  THYROID_PATHOLOGY_THYROID_PROBLEM = 'THYROID_PATHOLOGY_THYROID_PROBLEM',
  TIBIAL_PERIOSTITIS = 'TIBIAL_PERIOSTITIS',
  TICS_DISORDER_SUCH_AS_TOURETTE_S_SYNDROME = 'TICS_DISORDER_SUCH_AS_TOURETTE_S_SYNDROME',
  TIC_DISORDER = 'TIC_DISORDER',
  TIE_CELL_CARCINOMA_OF_THE_PANCREAS = 'TIE_CELL_CARCINOMA_OF_THE_PANCREAS',
  TILLAUX_FRACTURE = 'TILLAUX_FRACTURE',
  TIMOMA = 'TIMOMA',
  TINNITUS = 'TINNITUS',
  TONGUE_CANCER = 'TONGUE_CANCER',
  TONSILLITIS = 'TONSILLITIS',
  TONSILLITIS_VARIANT_2 = 'TONSILLITIS_VARIANT_2',
  TOOTH_PROBLEMS = 'TOOTH_PROBLEMS',
  TORTICOLLIS = 'TORTICOLLIS',
  TOURETTE_SYNDROME = 'TOURETTE_SYNDROME',
  TOWNES_BROCKS_SYNDROME = 'TOWNES_BROCKS_SYNDROME',
  TOXIC_HEPATITIS = 'TOXIC_HEPATITIS',
  TOXIC_MEGACOLON = 'TOXIC_MEGACOLON',
  TOXOCARIASIS = 'TOXOCARIASIS',
  TOXOPLASMOSIS = 'TOXOPLASMOSIS',
  TRABECULAR_CELL_CARCINOMA_OF_THE_PANCREAS = 'TRABECULAR_CELL_CARCINOMA_OF_THE_PANCREAS',
  TRACHOMA = 'TRACHOMA',
  TRANSITIONAL_CELL_CARCINOMA_OF_THE_PANCREAS = 'TRANSITIONAL_CELL_CARCINOMA_OF_THE_PANCREAS',
  TRANSITIONAL_RENAL_CELL_CARCINOMA = 'TRANSITIONAL_RENAL_CELL_CARCINOMA',
  TRANSPOSITION_OF_THE_GREAT_ARTERIES = 'TRANSPOSITION_OF_THE_GREAT_ARTERIES',
  TRANSPOSITION_OF_THE_GREAT_ARTERIES_VARIANT_2 = 'TRANSPOSITION_OF_THE_GREAT_ARTERIES_VARIANT_2',
  TRANSVERSE_COLON_CANCER = 'TRANSVERSE_COLON_CANCER',
  TRAPEZIUS_MUSCLE_SYNDROME = 'TRAPEZIUS_MUSCLE_SYNDROME',
  TRAUMATIC_BRAIN_INJURY_TBI = 'TRAUMATIC_BRAIN_INJURY_TBI',
  TRAUMATIC_BREAST_INJURIES = 'TRAUMATIC_BREAST_INJURIES',
  TRAUMATIC_SUBARACHNOID_HEMORRHAGE = 'TRAUMATIC_SUBARACHNOID_HEMORRHAGE',
  TRAUMATIC_WOUNDS = 'TRAUMATIC_WOUNDS',
  TRAUMA_ACCIDENT = 'TRAUMA_ACCIDENT',
  TRAUMA_AND_FRACTURES_OF_THE_CHEST = 'TRAUMA_AND_FRACTURES_OF_THE_CHEST',
  TRAUMA_OF_THE_ESOPHAGUS = 'TRAUMA_OF_THE_ESOPHAGUS',
  TRAVELER_S_DIARRHEA = 'TRAVELER_S_DIARRHEA',
  TREACHER_COLLINS_SYNDROME = 'TREACHER_COLLINS_SYNDROME',
  TREMOR = 'TREMOR',
  TRICHOSPORE_TINEA_VERSICOLOR = 'TRICHOSPORE_TINEA_VERSICOLOR',
  TRICUSPID_INSUFFICIENCY = 'TRICUSPID_INSUFFICIENCY',
  TRIGEMINAL_NEURALGIA = 'TRIGEMINAL_NEURALGIA',
  TRIPLE_X_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT = 'TRIPLE_X_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT',
  TROCHANTERIC_BURSITIS = 'TROCHANTERIC_BURSITIS',
  TROMBO_THROMBI = 'TROMBO_THROMBI',
  TROPICAL_DISEASES = 'TROPICAL_DISEASES',
  TROPICAL_PYOMYOSITIS = 'TROPICAL_PYOMYOSITIS',
  TROPICAL_VISCEROMEGALY_KALA_AZAR_SYNDROME = 'TROPICAL_VISCEROMEGALY_KALA_AZAR_SYNDROME',
  TUBAL_STERILIZATION_TUBAL_LIGATION = 'TUBAL_STERILIZATION_TUBAL_LIGATION',
  TUBERCULOSIS_TB = 'TUBERCULOSIS_TB',
  TUBEROUS_SCLEROSIS = 'TUBEROUS_SCLEROSIS',
  TUBULE_CELL_CARCINOMA_OF_THE_PANCREAS = 'TUBULE_CELL_CARCINOMA_OF_THE_PANCREAS',
  TULAREMIA = 'TULAREMIA',
  TUMORS_OF_THE_ADRENAL_GLAND = 'TUMORS_OF_THE_ADRENAL_GLAND',
  TUMORS_OF_THE_ADRENAL_GLAND_VARIANT_2 = 'TUMORS_OF_THE_ADRENAL_GLAND_VARIANT_2',
  TUMORS_OF_THE_ANTERIOR_MEDIASTINUM = 'TUMORS_OF_THE_ANTERIOR_MEDIASTINUM',
  TUMORS_OF_THE_BILIARY_TRACT = 'TUMORS_OF_THE_BILIARY_TRACT',
  TUMORS_OF_THE_CENTRAL_NERVOUS_SYSTEM = 'TUMORS_OF_THE_CENTRAL_NERVOUS_SYSTEM',
  TUMORS_OF_THE_ESOPHAGUS = 'TUMORS_OF_THE_ESOPHAGUS',
  TUMORS_OF_THE_HEAD_AND_NECK = 'TUMORS_OF_THE_HEAD_AND_NECK',
  TUMORS_OF_THE_KIDNEY = 'TUMORS_OF_THE_KIDNEY',
  TUMORS_OF_THE_LARYNX = 'TUMORS_OF_THE_LARYNX',
  TUMORS_OF_THE_MIDDLE_AND_INNER_EAR = 'TUMORS_OF_THE_MIDDLE_AND_INNER_EAR',
  TUMORS_OF_THE_MIDDLE_MEDIASTINUM = 'TUMORS_OF_THE_MIDDLE_MEDIASTINUM',
  TUMORS_OF_THE_MOUTH = 'TUMORS_OF_THE_MOUTH',
  TUMORS_OF_THE_ORAL_CAVITY = 'TUMORS_OF_THE_ORAL_CAVITY',
  TUMORS_OF_THE_ORAL_CAVITY_OR_NASAL_PASSAGES = 'TUMORS_OF_THE_ORAL_CAVITY_OR_NASAL_PASSAGES',
  TUMORS_OF_THE_POSTERIOR_MEDIASTINUM = 'TUMORS_OF_THE_POSTERIOR_MEDIASTINUM',
  TUMORS_OF_THE_SYMPATHETIC_PLEXUS = 'TUMORS_OF_THE_SYMPATHETIC_PLEXUS',
  TUMORS_OF_THE_TENDON_SHEATHS = 'TUMORS_OF_THE_TENDON_SHEATHS',
  TUMORS_OF_THE_TRACHEA = 'TUMORS_OF_THE_TRACHEA',
  TUMORS_OF_THE_URINARY_TRACT = 'TUMORS_OF_THE_URINARY_TRACT',
  TUMORS_OF_THE_VOCAL_CORDS = 'TUMORS_OF_THE_VOCAL_CORDS',
  TUMORS_OR_SKIN_CANCER = 'TUMORS_OR_SKIN_CANCER',
  TUMOR_OF_HEMATOPOIETIC_TISSUE = 'TUMOR_OF_HEMATOPOIETIC_TISSUE',
  TUMOR_OF_THE_ADRENALS = 'TUMOR_OF_THE_ADRENALS',
  TUMOR_OF_THE_ANAL_CANAL = 'TUMOR_OF_THE_ANAL_CANAL',
  TUMOR_OF_THE_ANAL_MARGIN = 'TUMOR_OF_THE_ANAL_MARGIN',
  TUMOR_OF_THE_APPENDIX = 'TUMOR_OF_THE_APPENDIX',
  TUMOR_OF_THE_BILIO_PANCREATIC_TRACT = 'TUMOR_OF_THE_BILIO_PANCREATIC_TRACT',
  TUMOR_OF_THE_BLIND = 'TUMOR_OF_THE_BLIND',
  TUMOR_OF_THE_BODY_OF_THE_UTERUS = 'TUMOR_OF_THE_BODY_OF_THE_UTERUS',
  TUMOR_OF_THE_BONES_OF_THE_SKULL = 'TUMOR_OF_THE_BONES_OF_THE_SKULL',
  TUMOR_OF_THE_CENTRAL_NERVOUS_SYSTEM = 'TUMOR_OF_THE_CENTRAL_NERVOUS_SYSTEM',
  TUMOR_OF_THE_CENTRAL_NERVOUS_SYSTEM_CNS = 'TUMOR_OF_THE_CENTRAL_NERVOUS_SYSTEM_CNS',
  TUMOR_OF_THE_ETHMOID_BONE = 'TUMOR_OF_THE_ETHMOID_BONE',
  TUMOR_OF_THE_EYE = 'TUMOR_OF_THE_EYE',
  TUMOR_OF_THE_FLOOR_OF_THE_MOUTH = 'TUMOR_OF_THE_FLOOR_OF_THE_MOUTH',
  TUMOR_OF_THE_FOURTH_VENTRICLE = 'TUMOR_OF_THE_FOURTH_VENTRICLE',
  TUMOR_OF_THE_HEART = 'TUMOR_OF_THE_HEART',
  TUMOR_OF_THE_HYPOPHARYNX = 'TUMOR_OF_THE_HYPOPHARYNX',
  TUMOR_OF_THE_HYPOTHALAMUS = 'TUMOR_OF_THE_HYPOTHALAMUS',
  TUMOR_OF_THE_IMMUNE_SYSTEM = 'TUMOR_OF_THE_IMMUNE_SYSTEM',
  TUMOR_OF_THE_LIP = 'TUMOR_OF_THE_LIP',
  TUMOR_OF_THE_LIPS = 'TUMOR_OF_THE_LIPS',
  TUMOR_OF_THE_LYMPHATIC_SYSTEM = 'TUMOR_OF_THE_LYMPHATIC_SYSTEM',
  TUMOR_OF_THE_MEDIASTINUM = 'TUMOR_OF_THE_MEDIASTINUM',
  TUMOR_OF_THE_MENINGES = 'TUMOR_OF_THE_MENINGES',
  TUMOR_OF_THE_MIDDLE_CRANIAL_FOSSA = 'TUMOR_OF_THE_MIDDLE_CRANIAL_FOSSA',
  TUMOR_OF_THE_MIDDLE_FOSSA = 'TUMOR_OF_THE_MIDDLE_FOSSA',
  TUMOR_OF_THE_MIDDLE_MEDIASTINUM = 'TUMOR_OF_THE_MIDDLE_MEDIASTINUM',
  TUMOR_OF_THE_MOUTH = 'TUMOR_OF_THE_MOUTH',
  TUMOR_OF_THE_MOUTH_AND_THROAT = 'TUMOR_OF_THE_MOUTH_AND_THROAT',
  TUMOR_OF_THE_ORBIT = 'TUMOR_OF_THE_ORBIT',
  TUMOR_OF_THE_PALATE = 'TUMOR_OF_THE_PALATE',
  TUMOR_OF_THE_PERICARDIUM = 'TUMOR_OF_THE_PERICARDIUM',
  TUMOR_OF_THE_PERITONEUM = 'TUMOR_OF_THE_PERITONEUM',
  TUMOR_OF_THE_PONTO_CEREBELLAR_ANGLE = 'TUMOR_OF_THE_PONTO_CEREBELLAR_ANGLE',
  TUMOR_OF_THE_POSTERIOR_CRANIAL_FOSSA = 'TUMOR_OF_THE_POSTERIOR_CRANIAL_FOSSA',
  TUMOR_OF_THE_POSTERIOR_FOSSA = 'TUMOR_OF_THE_POSTERIOR_FOSSA',
  TUMOR_OF_THE_POSTERIOR_MEDIASTINUM = 'TUMOR_OF_THE_POSTERIOR_MEDIASTINUM',
  TUMOR_OF_THE_SKULL = 'TUMOR_OF_THE_SKULL',
  TUMOR_OF_THE_SKULL_BASE = 'TUMOR_OF_THE_SKULL_BASE',
  TUMOR_OF_THE_SMALL_INTESTINE = 'TUMOR_OF_THE_SMALL_INTESTINE',
  TUMOR_OF_THE_SPHENOID_BONE = 'TUMOR_OF_THE_SPHENOID_BONE',
  TUMOR_OF_THE_THIRD_VENTRICLE = 'TUMOR_OF_THE_THIRD_VENTRICLE',
  TURCOT_S_SYNDROME = 'TURCOT_S_SYNDROME',
  TURNER_LIKE_SYNDROME = 'TURNER_LIKE_SYNDROME',
  TURNER_SYNDROME_MONOSOMY_X = 'TURNER_SYNDROME_MONOSOMY_X',
  TURNER_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT = 'TURNER_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT',
  TURNER_S_SYNDROME = 'TURNER_S_SYNDROME',
  TURNER_S_SYNDROME_WITH_CARDIAC_INVOLVEMENT = 'TURNER_S_SYNDROME_WITH_CARDIAC_INVOLVEMENT',
  TYMPANIC_PERFORATION = 'TYMPANIC_PERFORATION',
  TYPE_1_DIABETES = 'TYPE_1_DIABETES',
  TYPE_1_DIABETIC_HYPERGLYCEMIA = 'TYPE_1_DIABETIC_HYPERGLYCEMIA',
  TYPE_2_DIABETES = 'TYPE_2_DIABETES',
  TYPE_2_DIABETIC_HYPERGLYCEMIA = 'TYPE_2_DIABETIC_HYPERGLYCEMIA',
  TYPHOID_FEVER = 'TYPHOID_FEVER',
  TYROSINEMIA = 'TYROSINEMIA',
  T_ALPHA_BETA_LYMPHOCYTOSIS = 'T_ALPHA_BETA_LYMPHOCYTOSIS',
  T_CELL_ACUTE_LYMPHOBLASTIC_LEUKEMIA = 'T_CELL_ACUTE_LYMPHOBLASTIC_LEUKEMIA',
  T_CELL_LYMPHOMA_OF_THE_ADULT = 'T_CELL_LYMPHOMA_OF_THE_ADULT',
  UHL_S_SYNDROME = 'UHL_S_SYNDROME',
  ULCERATIVE_COLITIS = 'ULCERATIVE_COLITIS',
  ULLRICH_TURNER_LIKE_SYNDROME = 'ULLRICH_TURNER_LIKE_SYNDROME',
  ULLRICH_TURNER_SYNDROME = 'ULLRICH_TURNER_SYNDROME',
  UMBILICAL_HERNIA = 'UMBILICAL_HERNIA',
  UNCONTROLLED_EATING_DISORDER_BED = 'UNCONTROLLED_EATING_DISORDER_BED',
  UNDIFFERENTIATED_CELL_CARCINOMA_OF_THE_PANCREAS = 'UNDIFFERENTIATED_CELL_CARCINOMA_OF_THE_PANCREAS',
  UNDIFFERENTIATED_SOMATOFORM_DISORDER = 'UNDIFFERENTIATED_SOMATOFORM_DISORDER',
  UPPER_AND_LOWER_GASTROINTESTINAL_BLEEDING = 'UPPER_AND_LOWER_GASTROINTESTINAL_BLEEDING',
  UPPER_RESPIRATORY_TRACT_INFECTIONS = 'UPPER_RESPIRATORY_TRACT_INFECTIONS',
  UPPER_RESPIRATORY_TRACT_INFECTIONS_SUCH_AS_THE_COMMON_COLD = 'UPPER_RESPIRATORY_TRACT_INFECTIONS_SUCH_AS_THE_COMMON_COLD',
  UPPER_TWIN_MUSCLE_SYNDROME = 'UPPER_TWIN_MUSCLE_SYNDROME',
  URETEROCELE = 'URETEROCELE',
  URETHRAL_CANCER = 'URETHRAL_CANCER',
  URINARY_INCONTINENCE = 'URINARY_INCONTINENCE',
  URINARY_INCONTINENCE_DURING_PREGNANCY = 'URINARY_INCONTINENCE_DURING_PREGNANCY',
  URINARY_TRACT_INFECTIONS = 'URINARY_TRACT_INFECTIONS',
  URINARY_TRACT_INFECTIONS_CYSTITIS_PYELONEPHRITIS = 'URINARY_TRACT_INFECTIONS_CYSTITIS_PYELONEPHRITIS',
  URINARY_TRACT_INFECTIONS_UTIS = 'URINARY_TRACT_INFECTIONS_UTIS',
  URINARY_TRACT_INFECTIONS_VARIANT_2 = 'URINARY_TRACT_INFECTIONS_VARIANT_2',
  URINARY_TRACT_INFECTION_ITUT = 'URINARY_TRACT_INFECTION_ITUT',
  URINARY_TRACT_INFECTION_UTI = 'URINARY_TRACT_INFECTION_UTI',
  UROGENITAL_SCHISTOSOMIASIS = 'UROGENITAL_SCHISTOSOMIASIS',
  URTICARIA = 'URTICARIA',
  USHER_SYNDROME = 'USHER_SYNDROME',
  UTERINE_CANCER_ENDOMETRIAL = 'UTERINE_CANCER_ENDOMETRIAL',
  UTERINE_CANCER_TUMOR = 'UTERINE_CANCER_TUMOR',
  UTERINE_CARCINOMA = 'UTERINE_CARCINOMA',
  UTERINE_FIBROIDS = 'UTERINE_FIBROIDS',
  UTERINE_FIBROIDS_MYOMAS = 'UTERINE_FIBROIDS_MYOMAS',
  UTERINE_PROLAPSE = 'UTERINE_PROLAPSE',
  VACCINE_COMPLICATIONS = 'VACCINE_COMPLICATIONS',
  VACCINIA_POXVIRUS = 'VACCINIA_POXVIRUS',
  VACTERL_SYNDROME = 'VACTERL_SYNDROME',
  VAGINAL_CANCER = 'VAGINAL_CANCER',
  VAGINAL_CANCER_VARIANT_2 = 'VAGINAL_CANCER_VARIANT_2',
  VAGINAL_HYSTERECTOMY = 'VAGINAL_HYSTERECTOMY',
  VAGINAL_RING = 'VAGINAL_RING',
  VAGINISMUS = 'VAGINISMUS',
  VAGUS_NERVE_TUMORS = 'VAGUS_NERVE_TUMORS',
  VALVE_FAILURE = 'VALVE_FAILURE',
  VALVE_PROBLEM = 'VALVE_PROBLEM',
  VALVE_PROBLEMS = 'VALVE_PROBLEMS',
  VALVULAR_STENOSIS = 'VALVULAR_STENOSIS',
  VALVULOPATHIES = 'VALVULOPATHIES',
  VAN_DER_WOUDE_SYNDROME = 'VAN_DER_WOUDE_SYNDROME',
  VARICES = 'VARICES',
  VARICOCELE = 'VARICOCELE',
  VARICOSE_VEINS = 'VARICOSE_VEINS',
  VASCULAR_MALFORMATIONS = 'VASCULAR_MALFORMATIONS',
  VASCULITIS = 'VASCULITIS',
  VATER_LIKE_SYNDROME = 'VATER_LIKE_SYNDROME',
  VATER_SYNDROME = 'VATER_SYNDROME',
  VEIN_OF_GALEN_MALFORMATION = 'VEIN_OF_GALEN_MALFORMATION',
  VENOUS_HEMORRHAGE = 'VENOUS_HEMORRHAGE',
  VENOUS_INSUFFICIENCY = 'VENOUS_INSUFFICIENCY',
  VENOUS_MALFORMATIONS = 'VENOUS_MALFORMATIONS',
  VENOUS_REFLUX = 'VENOUS_REFLUX',
  VENOUS_ULCERATION = 'VENOUS_ULCERATION',
  VENOUS_ULCERS = 'VENOUS_ULCERS',
  VENTRAL_HERNIA = 'VENTRAL_HERNIA',
  VENTRICULAR_ANEURYSM = 'VENTRICULAR_ANEURYSM',
  VENTRICULAR_ARRHYTHMIA = 'VENTRICULAR_ARRHYTHMIA',
  VENTRICULAR_PRE_EXCITATION_SYNDROME = 'VENTRICULAR_PRE_EXCITATION_SYNDROME',
  VERTEBRAL_STRESS_FRACTURES_VERTEBRAL_COMPRESSIONS = 'VERTEBRAL_STRESS_FRACTURES_VERTEBRAL_COMPRESSIONS',
  VERTIGO = 'VERTIGO',
  VESICOURETERAL_REFLUX = 'VESICOURETERAL_REFLUX',
  VESTIBULAR_AND_BALANCE_DISORDERS = 'VESTIBULAR_AND_BALANCE_DISORDERS',
  VESTIBULAR_NEURITIS = 'VESTIBULAR_NEURITIS',
  VESTIBULAR_SCHWANNOMA = 'VESTIBULAR_SCHWANNOMA',
  VINCENT_S_ANGINA = 'VINCENT_S_ANGINA',
  VIRAL_HEPATITIS = 'VIRAL_HEPATITIS',
  VIRAL_INFECTIONS = 'VIRAL_INFECTIONS',
  VIRAL_INFECTIONS_SUCH_AS_CHICKENPOX_RUBELLA_MEASLES = 'VIRAL_INFECTIONS_SUCH_AS_CHICKENPOX_RUBELLA_MEASLES',
  VISCERAL_LEISHMANIASIS = 'VISCERAL_LEISHMANIASIS',
  VISION_PROBLEMS = 'VISION_PROBLEMS',
  VITAMIN_B12_DEFICIENCY_ANEMIA = 'VITAMIN_B12_DEFICIENCY_ANEMIA',
  VITAMIN_B12_DEFICIENCY_PELLAGRA = 'VITAMIN_B12_DEFICIENCY_PELLAGRA',
  VITILIGO = 'VITILIGO',
  VOCAL_CORD_NODULES = 'VOCAL_CORD_NODULES',
  VOCAL_CORD_PARALYSIS = 'VOCAL_CORD_PARALYSIS',
  VOCAL_CORD_POLYP = 'VOCAL_CORD_POLYP',
  VOCAL_NODULES = 'VOCAL_NODULES',
  VOCAL_POLYPS = 'VOCAL_POLYPS',
  VOGT_KOYANAGI_HARADA_SYNDROME = 'VOGT_KOYANAGI_HARADA_SYNDROME',
  VOICES = 'VOICES',
  VOICE_ALTERATIONS = 'VOICE_ALTERATIONS',
  VOICE_DISORDERS = 'VOICE_DISORDERS',
  VOICE_DISORDERS_SUCH_AS_DYSPHONIA = 'VOICE_DISORDERS_SUCH_AS_DYSPHONIA',
  VOLKMANN_S_CONTRACTURE = 'VOLKMANN_S_CONTRACTURE',
  VON_HIPPEL_LINDAU_DISEASE_VHL = 'VON_HIPPEL_LINDAU_DISEASE_VHL',
  VON_HIPPEL_LINDAU_SYNDROME = 'VON_HIPPEL_LINDAU_SYNDROME',
  VON_WILLEBRAND_DISEASE = 'VON_WILLEBRAND_DISEASE',
  VULVAR_CANCER = 'VULVAR_CANCER',
  VULVODYNIA = 'VULVODYNIA',
  WAARDENBURG_SYNDROME = 'WAARDENBURG_SYNDROME',
  WAGSTAFFE_LE_FORT_FRACTURE = 'WAGSTAFFE_LE_FORT_FRACTURE',
  WALDENSTR_M_DISEASE = 'WALDENSTR_M_DISEASE',
  WARBURG_MICRO_SYNDROME = 'WARBURG_MICRO_SYNDROME',
  WART = 'WART',
  WARTS = 'WARTS',
  WARTS_CONDYLOMATA_ACUMINATA = 'WARTS_CONDYLOMATA_ACUMINATA',
  WATER_IN_THE_LUNGS = 'WATER_IN_THE_LUNGS',
  WATER_IN_THE_PLEURA = 'WATER_IN_THE_PLEURA',
  WEAVER_S_SYNDROME = 'WEAVER_S_SYNDROME',
  WEGENER_S_GRANULOMATOSIS = 'WEGENER_S_GRANULOMATOSIS',
  WEILL_MARCHESANI_LIKE_SYNDROME = 'WEILL_MARCHESANI_LIKE_SYNDROME',
  WEILL_MARCHESANI_SYNDROME = 'WEILL_MARCHESANI_SYNDROME',
  WEIL_S_SYNDROME = 'WEIL_S_SYNDROME',
  WERNER_S_SYNDROME = 'WERNER_S_SYNDROME',
  WEST_NILE_FEVER_VIRUS = 'WEST_NILE_FEVER_VIRUS',
  WEST_NILE_VIRUS = 'WEST_NILE_VIRUS',
  WEST_SYNDROME = 'WEST_SYNDROME',
  WHIPPLE_S_DISEASE = 'WHIPPLE_S_DISEASE',
  WHISTLING_OR_BUZZING = 'WHISTLING_OR_BUZZING',
  WHOOPING_COUGH_CONVULSIVE_COUGH = 'WHOOPING_COUGH_CONVULSIVE_COUGH',
  WILLIAMS_BEUREN_SYNDROME = 'WILLIAMS_BEUREN_SYNDROME',
  WILLIAMS_SYNDROME = 'WILLIAMS_SYNDROME',
  WILLIAMS_SYNDROME_7Q_DELETION = 'WILLIAMS_SYNDROME_7Q_DELETION',
  WILMS_SYNDROME = 'WILMS_SYNDROME',
  WILMS_TUMOR = 'WILMS_TUMOR',
  WILSON_S_DISEASE = 'WILSON_S_DISEASE',
  WILSON_TURNER_SYNDROME = 'WILSON_TURNER_SYNDROME',
  WISDOM_TEETH_EXTRACTION = 'WISDOM_TEETH_EXTRACTION',
  WISKOTT_ALDRICH_SYNDROME_WAS = 'WISKOTT_ALDRICH_SYNDROME_WAS',
  WITCH_S_STRIKE = 'WITCH_S_STRIKE',
  WOLFF_PARKINSON_WHITE_SYNDROME = 'WOLFF_PARKINSON_WHITE_SYNDROME',
  WOLFF_PARKINSON_WHITE_SYNDROME_WPW = 'WOLFF_PARKINSON_WHITE_SYNDROME_WPW',
  WOLFRAM_SYNDROME = 'WOLFRAM_SYNDROME',
  WOLF_HIRSCHHORN_SYNDROME = 'WOLF_HIRSCHHORN_SYNDROME',
  WOLF_HIRSCHHORN_SYNDROME_4P_DELETION = 'WOLF_HIRSCHHORN_SYNDROME_4P_DELETION',
  WOUNDS = 'WOUNDS',
  WOUNDS_THAT_DON_T_HEAL = 'WOUNDS_THAT_DON_T_HEAL',
  WRIST_AND_HAND_PAIN_SUCH_AS_CARPAL_TUNNEL_SYNDROME = 'WRIST_AND_HAND_PAIN_SUCH_AS_CARPAL_TUNNEL_SYNDROME',
  WRIST_FRACTURE = 'WRIST_FRACTURE',
  XANTELASMAS = 'XANTELASMAS',
  XXXX_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT = 'XXXX_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT',
  XYY_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT = 'XYY_SYNDROME_WITH_ENDOCRINOLOGICAL_INVOLVEMENT',
  X_CHROMOSOME_LINKED_AGAMMAGLOBULINEMIA_XLA = 'X_CHROMOSOME_LINKED_AGAMMAGLOBULINEMIA_XLA',
  YAWS_FLAT_SYPHILIS = 'YAWS_FLAT_SYPHILIS',
  YELLOW_FEVER = 'YELLOW_FEVER',
  YELLOW_FEVER_VIRUS = 'YELLOW_FEVER_VIRUS',
  ZIKA = 'ZIKA',
  ZIKA_VIRUS = 'ZIKA_VIRUS',
  ZOLLINGER_ELLISON_SYNDROME = 'ZOLLINGER_ELLISON_SYNDROME',
  ZOLLINGER_ELLISON_SYNDROME_WITH_CARDIAC_INVOLVEMENT = 'ZOLLINGER_ELLISON_SYNDROME_WITH_CARDIAC_INVOLVEMENT',
  ZYGOMATIC_IMPLANTS = 'ZYGOMATIC_IMPLANTS'
}

export type PathologyResultsACAData = {
  __typename?: 'PathologyResultsACAData';
  executionDate: Scalars['DateTime']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type PathologyResultsACADataArgs = {
  executionDate: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type PathologyResultsACAHistory = {
  __typename?: 'PathologyResultsACAHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsACAData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsAhusCytologicalExaminationData = {
  __typename?: 'PathologyResultsAhusCytologicalExaminationData';
  executionDate: Scalars['DateTime']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type PathologyResultsAhusCytologicalExaminationDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type PathologyResultsAhusCytologicalExaminationHistory = {
  __typename?: 'PathologyResultsAhusCytologicalExaminationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsAhusCytologicalExaminationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsAhusHystologicalExaminationData = {
  __typename?: 'PathologyResultsAhusHystologicalExaminationData';
  executionDate: Scalars['DateTime']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type PathologyResultsAhusHystologicalExaminationDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type PathologyResultsAhusHystologicalExaminationHistory = {
  __typename?: 'PathologyResultsAhusHystologicalExaminationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsAhusHystologicalExaminationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsBMAData = {
  __typename?: 'PathologyResultsBMAData';
  executionDate: Scalars['DateTime']['output'];
  infiltrateMC?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
};

export type PathologyResultsBMADataArgs = {
  executionDate: Scalars['DateTime']['input'];
  infiltrateMC?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<Scalars['String']['input']>;
};

export type PathologyResultsBMAHistory = {
  __typename?: 'PathologyResultsBMAHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsBMAData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsBMBData = {
  __typename?: 'PathologyResultsBMBData';
  executionDate: Scalars['DateTime']['output'];
  fibrosisGrading?: Maybe<Scalars['String']['output']>;
  fibrosisType?: Maybe<Scalars['String']['output']>;
  infiltrateMC?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
};

export type PathologyResultsBMBDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  fibrosisGrading?: InputMaybe<Scalars['String']['input']>;
  fibrosisType?: InputMaybe<Scalars['String']['input']>;
  infiltrateMC?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<Scalars['String']['input']>;
};

export type PathologyResultsBMBHistory = {
  __typename?: 'PathologyResultsBMBHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsBMBData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsBrafMutationData = {
  __typename?: 'PathologyResultsBrafMutationData';
  executionDate: Scalars['DateTime']['output'];
  isPresent: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  type?: Maybe<BrafMutation>;
};

export type PathologyResultsBrafMutationDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  isPresent: Scalars['Boolean']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<BrafMutation>;
};

export type PathologyResultsBrafMutationHistory = {
  __typename?: 'PathologyResultsBrafMutationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsBrafMutationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsBreastHistologicalExaminationData = {
  __typename?: 'PathologyResultsBreastHistologicalExaminationData';
  dateOfExecution: Scalars['DateTime']['output'];
  dimension?: Maybe<Scalars['String']['output']>;
  er?: Maybe<Scalars['String']['output']>;
  her2?: Maybe<Scalars['String']['output']>;
  ki67?: Maybe<Scalars['String']['output']>;
  nuclearGrade?: Maybe<Scalars['String']['output']>;
  pgr?: Maybe<Scalars['String']['output']>;
  tils?: Maybe<Scalars['String']['output']>;
  vascularInvasion?: Maybe<Scalars['String']['output']>;
};

export type PathologyResultsBreastHistologicalExaminationDataArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  dimension?: InputMaybe<Scalars['String']['input']>;
  er?: InputMaybe<Scalars['String']['input']>;
  her2?: InputMaybe<Scalars['String']['input']>;
  ki67?: InputMaybe<Scalars['String']['input']>;
  nuclearGrade?: InputMaybe<Scalars['String']['input']>;
  pgr?: InputMaybe<Scalars['String']['input']>;
  tils?: InputMaybe<Scalars['String']['input']>;
  vascularInvasion?: InputMaybe<Scalars['String']['input']>;
};

export type PathologyResultsBreastHistologicalExaminationHistory = {
  __typename?: 'PathologyResultsBreastHistologicalExaminationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsBreastHistologicalExaminationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsBreastHistotypeData = {
  __typename?: 'PathologyResultsBreastHistotypeData';
  executionDate: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type PathologyResultsBreastHistotypeDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  value: Scalars['String']['input'];
};

export type PathologyResultsBreastHistotypeHistory = {
  __typename?: 'PathologyResultsBreastHistotypeHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsBreastHistotypeData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsBreastMetastasisArgs = {
  isPresent: Scalars['Boolean']['input'];
  localisation?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type PathologyResultsBreastMetastasisData = {
  __typename?: 'PathologyResultsBreastMetastasisData';
  isPresent: Scalars['Boolean']['output'];
  localisation?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
};

export type PathologyResultsBreastMetastasisHistory = {
  __typename?: 'PathologyResultsBreastMetastasisHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsBreastMetastasisData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsBreastNodesDissectionData = {
  __typename?: 'PathologyResultsBreastNodesDissectionData';
  executionDate: Scalars['DateTime']['output'];
  note?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type PathologyResultsBreastNodesDissectionDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type PathologyResultsBreastNodesDissectionHistory = {
  __typename?: 'PathologyResultsBreastNodesDissectionHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsBreastNodesDissectionData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsBreastResectionMarginsData = {
  __typename?: 'PathologyResultsBreastResectionMarginsData';
  executionDate: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type PathologyResultsBreastResectionMarginsDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  value: Scalars['String']['input'];
};

export type PathologyResultsBreastResectionMarginsHistory = {
  __typename?: 'PathologyResultsBreastResectionMarginsHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsBreastResectionMarginsData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsBreastSentinelNodeData = {
  __typename?: 'PathologyResultsBreastSentinelNodeData';
  executionDate: Scalars['DateTime']['output'];
  note?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type PathologyResultsBreastSentinelNodeDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type PathologyResultsBreastSentinelNodeHistory = {
  __typename?: 'PathologyResultsBreastSentinelNodeHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsBreastSentinelNodeData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsCKitMutationData = {
  __typename?: 'PathologyResultsCKitMutationData';
  isBoneMarrow: Scalars['Boolean']['output'];
  isPeripheralBlood: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type PathologyResultsCKitMutationDataArgs = {
  isBoneMarrow: Scalars['Boolean']['input'];
  isPeripheralBlood: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type PathologyResultsCKitMutationHistory = {
  __typename?: 'PathologyResultsCKitMutationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsCKitMutationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsData = {
  __typename?: 'PathologyResultsData';
  cKitMutation?: Maybe<PathologyResultsCKitMutationData>;
  immunophenotyping?: Maybe<PathologyResultsImmunophenotypingData>;
};

export type PathologyResultsGenericNotesData = {
  __typename?: 'PathologyResultsGenericNotesData';
  notes: Scalars['String']['output'];
};

export type PathologyResultsGenericNotesDataArgs = {
  notes: Scalars['String']['input'];
};

export type PathologyResultsGenericNotesHistory = {
  __typename?: 'PathologyResultsGenericNotesHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsGenericNotesData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsHistologyData = {
  __typename?: 'PathologyResultsHistologyData';
  notes?: Maybe<Scalars['String']['output']>;
  values: Scalars['String']['output'];
};

export type PathologyResultsHistologyDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  values: Scalars['String']['input'];
};

export type PathologyResultsHistologyHistory = {
  __typename?: 'PathologyResultsHistologyHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsHistologyData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsImmunophenotypingData = {
  __typename?: 'PathologyResultsImmunophenotypingData';
  notes: Scalars['String']['output'];
};

export type PathologyResultsImmunophenotypingDataArgs = {
  notes: Scalars['String']['input'];
};

export type PathologyResultsImmunophenotypingHistory = {
  __typename?: 'PathologyResultsImmunophenotypingHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsImmunophenotypingData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsKaryotypeData = {
  __typename?: 'PathologyResultsKaryotypeData';
  executionDate: Scalars['DateTime']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
};

export type PathologyResultsKaryotypeDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<Scalars['String']['input']>;
};

export type PathologyResultsKaryotypeHistory = {
  __typename?: 'PathologyResultsKaryotypeHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsKaryotypeData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsMelanomaHistotypeData = {
  __typename?: 'PathologyResultsMelanomaHistotypeData';
  executionDate: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type PathologyResultsMelanomaHistotypeDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  value: Scalars['String']['input'];
};

export type PathologyResultsMelanomaHistotypeHistory = {
  __typename?: 'PathologyResultsMelanomaHistotypeHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsMelanomaHistotypeData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsMelanomaLymphNodesHistologicalExaminationData = {
  __typename?: 'PathologyResultsMelanomaLymphNodesHistologicalExaminationData';
  dateOfExecution: Scalars['DateTime']['output'];
  extracapsularExtension?: Maybe<Scalars['String']['output']>;
  focusDiseaseDiameter?: Maybe<Scalars['String']['output']>;
  infiltrationCapsular?: Maybe<Scalars['String']['output']>;
  infiltrationPerineuralPerivascular?: Maybe<Scalars['String']['output']>;
  neoplasticDiseaseNodes?: Maybe<Scalars['String']['output']>;
};

export type PathologyResultsMelanomaLymphNodesHistologicalExaminationDataArgs = {
  dateOfExecution: Scalars['DateTime']['input'];
  extracapsularExtension?: InputMaybe<Scalars['String']['input']>;
  focusDiseaseDiameter?: InputMaybe<Scalars['String']['input']>;
  infiltrationCapsular?: InputMaybe<Scalars['String']['input']>;
  infiltrationPerineuralPerivascular?: InputMaybe<Scalars['String']['input']>;
  neoplasticDiseaseNodes?: InputMaybe<Scalars['String']['input']>;
};

export type PathologyResultsMelanomaLymphNodesHistologicalExaminationHistory = {
  __typename?: 'PathologyResultsMelanomaLymphNodesHistologicalExaminationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsMelanomaLymphNodesHistologicalExaminationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsMelanomaMetastasisData = {
  __typename?: 'PathologyResultsMelanomaMetastasisData';
  isPresent: Scalars['Boolean']['output'];
  localisation?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
};

export type PathologyResultsMelanomaMetastasisDataArgs = {
  isPresent: Scalars['Boolean']['input'];
  localisation?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type PathologyResultsMelanomaMetastasisHistory = {
  __typename?: 'PathologyResultsMelanomaMetastasisHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsMelanomaMetastasisData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsMelanomaNodesDissectionData = {
  __typename?: 'PathologyResultsMelanomaNodesDissectionData';
  executionDate: Scalars['DateTime']['output'];
  note?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type PathologyResultsMelanomaNodesDissectionDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type PathologyResultsMelanomaNodesDissectionHistory = {
  __typename?: 'PathologyResultsMelanomaNodesDissectionHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsMelanomaNodesDissectionData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsMelanomaResectionMarginsData = {
  __typename?: 'PathologyResultsMelanomaResectionMarginsData';
  executionDate: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type PathologyResultsMelanomaResectionMarginsDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  value: Scalars['String']['input'];
};

export type PathologyResultsMelanomaResectionMarginsHistory = {
  __typename?: 'PathologyResultsMelanomaResectionMarginsHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsMelanomaResectionMarginsData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsMelanomaSentinelNodeData = {
  __typename?: 'PathologyResultsMelanomaSentinelNodeData';
  executionDate: Scalars['DateTime']['output'];
  note?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type PathologyResultsMelanomaSentinelNodeDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type PathologyResultsMelanomaSentinelNodeHistory = {
  __typename?: 'PathologyResultsMelanomaSentinelNodeHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsMelanomaSentinelNodeData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsMelanomaTumorHistologicalExaminationData = {
  __typename?: 'PathologyResultsMelanomaTumorHistologicalExaminationData';
  breslowThickness?: Maybe<Scalars['String']['output']>;
  clarkLevel?: Maybe<Scalars['String']['output']>;
  dateOfExecution: Scalars['DateTime']['output'];
  metastasisInTransit?: Maybe<Scalars['String']['output']>;
  mitoticIndex?: Maybe<Scalars['String']['output']>;
  regression?: Maybe<Scalars['String']['output']>;
  tils?: Maybe<Scalars['String']['output']>;
  ulceration?: Maybe<Scalars['String']['output']>;
};

export type PathologyResultsMelanomaTumorHistologicalExaminationDataArgs = {
  breslowThickness?: InputMaybe<Scalars['String']['input']>;
  clarkLevel?: InputMaybe<Scalars['String']['input']>;
  dateOfExecution: Scalars['DateTime']['input'];
  metastasisInTransit?: InputMaybe<Scalars['String']['input']>;
  mitoticIndex?: InputMaybe<Scalars['String']['input']>;
  regression?: InputMaybe<Scalars['String']['input']>;
  tils?: InputMaybe<Scalars['String']['input']>;
  ulceration?: InputMaybe<Scalars['String']['input']>;
};

export type PathologyResultsMelanomaTumorHistologicalExaminationHistory = {
  __typename?: 'PathologyResultsMelanomaTumorHistologicalExaminationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsMelanomaTumorHistologicalExaminationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsNetCytologicalExaminationData = {
  __typename?: 'PathologyResultsNetCytologicalExaminationData';
  cdx2?: Maybe<OptionalAvailability>;
  chromograninA?: Maybe<OptionalAvailability>;
  differentiation?: Maybe<Differentiation>;
  examSite?: Maybe<PrimarySecondary>;
  executionDate: Scalars['DateTime']['output'];
  ki67?: Maybe<Scalars['Float']['output']>;
  mitosis?: Maybe<Scalars['Float']['output']>;
  multifocality?: Maybe<YesNo>;
  necrosis?: Maybe<Availability>;
  nesp55?: Maybe<OptionalAvailability>;
  neuralInvasion?: Maybe<OptionalAvailability>;
  pdx1?: Maybe<OptionalAvailability>;
  site?: Maybe<Scalars['String']['output']>;
  synaptophysin?: Maybe<OptionalAvailability>;
  ttf1?: Maybe<OptionalAvailability>;
  vascularInvasion?: Maybe<OptionalAvailability>;
};

export type PathologyResultsNetCytologicalExaminationDataArgs = {
  cdx2?: InputMaybe<OptionalAvailability>;
  chromograninA?: InputMaybe<OptionalAvailability>;
  differentiation?: InputMaybe<Differentiation>;
  examSite?: InputMaybe<PrimarySecondary>;
  executionDate: Scalars['DateTime']['input'];
  ki67?: InputMaybe<Scalars['Float']['input']>;
  mitosis?: InputMaybe<Scalars['Float']['input']>;
  multifocality?: InputMaybe<YesNo>;
  necrosis?: InputMaybe<Availability>;
  nesp55?: InputMaybe<OptionalAvailability>;
  neuralInvasion?: InputMaybe<OptionalAvailability>;
  pdx1?: InputMaybe<OptionalAvailability>;
  site?: InputMaybe<Scalars['String']['input']>;
  synaptophysin?: InputMaybe<OptionalAvailability>;
  ttf1?: InputMaybe<OptionalAvailability>;
  vascularInvasion?: InputMaybe<OptionalAvailability>;
};

export type PathologyResultsNetCytologicalExaminationHistory = {
  __typename?: 'PathologyResultsNetCytologicalExaminationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsNetCytologicalExaminationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsNetHistologicalExaminationData = {
  __typename?: 'PathologyResultsNetHistologicalExaminationData';
  cdx2?: Maybe<OptionalAvailability>;
  chromograninA?: Maybe<OptionalAvailability>;
  differentiation?: Maybe<Differentiation>;
  dimension?: Maybe<Scalars['Float']['output']>;
  examSite?: Maybe<PrimarySecondary>;
  executionDate: Scalars['DateTime']['output'];
  histotype?: Maybe<Scalars['String']['output']>;
  infiltrationNeighboringOrgans?: Maybe<YesNo>;
  ki67?: Maybe<Scalars['Float']['output']>;
  mitosis?: Maybe<Scalars['Float']['output']>;
  multifocality?: Maybe<YesNo>;
  necrosis?: Maybe<Availability>;
  nesp55?: Maybe<OptionalAvailability>;
  neuralInvasion?: Maybe<OptionalAvailability>;
  pdx1?: Maybe<OptionalAvailability>;
  resectionMargins?: Maybe<FreeNotFree>;
  site?: Maybe<Scalars['String']['output']>;
  synaptophysin?: Maybe<OptionalAvailability>;
  ttf1?: Maybe<OptionalAvailability>;
  vascularInvasion?: Maybe<OptionalAvailability>;
};

export type PathologyResultsNetHistologicalExaminationDataArgs = {
  cdx2?: InputMaybe<OptionalAvailability>;
  chromograninA?: InputMaybe<OptionalAvailability>;
  differentiation?: InputMaybe<Differentiation>;
  dimension?: InputMaybe<Scalars['Float']['input']>;
  examSite?: InputMaybe<PrimarySecondary>;
  executionDate: Scalars['DateTime']['input'];
  histotype?: InputMaybe<Scalars['String']['input']>;
  infiltrationNeighboringOrgans?: InputMaybe<YesNo>;
  ki67?: InputMaybe<Scalars['Float']['input']>;
  mitosis?: InputMaybe<Scalars['Float']['input']>;
  multifocality?: InputMaybe<YesNo>;
  necrosis?: InputMaybe<Availability>;
  nesp55?: InputMaybe<OptionalAvailability>;
  neuralInvasion?: InputMaybe<OptionalAvailability>;
  pdx1?: InputMaybe<OptionalAvailability>;
  resectionMargins?: InputMaybe<FreeNotFree>;
  site?: InputMaybe<Scalars['String']['input']>;
  synaptophysin?: InputMaybe<OptionalAvailability>;
  ttf1?: InputMaybe<OptionalAvailability>;
  vascularInvasion?: InputMaybe<OptionalAvailability>;
};

export type PathologyResultsNetHistologicalExaminationHistory = {
  __typename?: 'PathologyResultsNetHistologicalExaminationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsNetHistologicalExaminationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsNetLymphNodesHistologicalExaminationData = {
  __typename?: 'PathologyResultsNetLymphNodesHistologicalExaminationData';
  cdx2?: Maybe<OptionalAvailability>;
  chromograninA?: Maybe<OptionalAvailability>;
  differentiation?: Maybe<Differentiation>;
  dimension?: Maybe<Scalars['Float']['output']>;
  executionDate: Scalars['DateTime']['output'];
  histotype?: Maybe<Scalars['String']['output']>;
  infiltrationNeighboringOrgans?: Maybe<YesNo>;
  ki67?: Maybe<Scalars['Float']['output']>;
  mitosis?: Maybe<Scalars['Float']['output']>;
  necrosis?: Maybe<Availability>;
  nesp55?: Maybe<OptionalAvailability>;
  neuralInvasion?: Maybe<OptionalAvailability>;
  pdx1?: Maybe<OptionalAvailability>;
  resectionMargins?: Maybe<FreeNotFree>;
  site?: Maybe<Scalars['String']['output']>;
  synaptophysin?: Maybe<OptionalAvailability>;
  ttf1?: Maybe<OptionalAvailability>;
  vascularInvasion?: Maybe<OptionalAvailability>;
};

export type PathologyResultsNetLymphNodesHistologicalExaminationDataArgs = {
  cdx2?: InputMaybe<OptionalAvailability>;
  chromograninA?: InputMaybe<OptionalAvailability>;
  differentiation?: InputMaybe<Differentiation>;
  dimension?: InputMaybe<Scalars['Float']['input']>;
  executionDate: Scalars['DateTime']['input'];
  histotype?: InputMaybe<Scalars['String']['input']>;
  infiltrationNeighboringOrgans?: InputMaybe<YesNo>;
  ki67?: InputMaybe<Scalars['Float']['input']>;
  mitosis?: InputMaybe<Scalars['Float']['input']>;
  necrosis?: InputMaybe<Availability>;
  nesp55?: InputMaybe<OptionalAvailability>;
  neuralInvasion?: InputMaybe<OptionalAvailability>;
  pdx1?: InputMaybe<OptionalAvailability>;
  resectionMargins?: InputMaybe<FreeNotFree>;
  site?: InputMaybe<Scalars['String']['input']>;
  synaptophysin?: InputMaybe<OptionalAvailability>;
  ttf1?: InputMaybe<OptionalAvailability>;
  vascularInvasion?: InputMaybe<OptionalAvailability>;
};

export type PathologyResultsNetLymphNodesHistologicalExaminationHistory = {
  __typename?: 'PathologyResultsNetLymphNodesHistologicalExaminationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsNetLymphNodesHistologicalExaminationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsNetLymphNodesPresenceData = {
  __typename?: 'PathologyResultsNetLymphNodesPresenceData';
  executionDate: Scalars['DateTime']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  numberOfPositiveLymphNodes?: Maybe<Scalars['Int']['output']>;
  numberOfRemovedLymphNodes?: Maybe<Scalars['Int']['output']>;
  value: YesNo;
};

export type PathologyResultsNetLymphNodesPresenceDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  numberOfPositiveLymphNodes?: InputMaybe<Scalars['Int']['input']>;
  numberOfRemovedLymphNodes?: InputMaybe<Scalars['Int']['input']>;
  value: YesNo;
};

export type PathologyResultsNetLymphNodesPresenceHistory = {
  __typename?: 'PathologyResultsNetLymphNodesPresenceHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsNetLymphNodesPresenceData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsNetMetastasisData = {
  __typename?: 'PathologyResultsNetMetastasisData';
  localisation?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  value: YesNo;
};

export type PathologyResultsNetMetastasisDataArgs = {
  localisation?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  value: YesNo;
};

export type PathologyResultsNetMetastasisHistory = {
  __typename?: 'PathologyResultsNetMetastasisHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsNetMetastasisData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PathologyResultsNonBrafMutationData = {
  __typename?: 'PathologyResultsNonBrafMutationData';
  executionDate: Scalars['DateTime']['output'];
  isPresent: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type PathologyResultsNonBrafMutationDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  isPresent: Scalars['Boolean']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type PathologyResultsNonBrafMutationHistory = {
  __typename?: 'PathologyResultsNonBrafMutationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PathologyResultsNonBrafMutationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type Patient = {
  __typename?: 'Patient';
  createdAt: Scalars['DateTime']['output'];
  creatorInstitutionUser: InstitutionUser;
  documentExpiryDate?: Maybe<Scalars['DateTime']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fileList: Array<PatientFile>;
  id: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated Patient instance is cross-portal & shouldn't have portalUser at all */
  portalUser: PortalUserModel;
  privacyAcceptance: PatientPrivacyAcceptance;
  /** @deprecated Use getFileUrl or mngGetFileUrl queries instead */
  profileImageFile?: Maybe<File>;
  thread: Array<IntramedThread>;
  /** @deprecated Use getFileUrl or mngGetFileUrl queries instead */
  thumbnailImageFile?: Maybe<File>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type PatientCalculationModel = {
  __typename?: 'PatientCalculationModel';
  createdAt: Scalars['DateTime']['output'];
  dateOfExecution: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  medicalIndex: MedicalIndex;
  parameters: Array<PatientCalculationParameterModel>;
  results: Array<PatientCalculationResultRecord>;
};

export type PatientCalculationParameterModel = {
  __typename?: 'PatientCalculationParameterModel';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PatientCalculationResultRecord = {
  __typename?: 'PatientCalculationResultRecord';
  dimension: PatientCalculationResultRecordDimension;
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum PatientCalculationResultRecordDimension {
  MONTH = 'MONTH',
  PERCENTAGE = 'PERCENTAGE',
  POINT = 'POINT',
  RISK_GROUP = 'RISK_GROUP',
  STAGE = 'STAGE',
  YEAR = 'YEAR'
}

export type PatientCommunicationUnion = InfoPatientCommunication | IntramedPatientCommunication | RequestPatientCommunication | VoiceCallsPatientCommunication;

export enum PatientExistenceCheckStatusDecision {
  DOCTOR_EXISTS = 'DOCTOR_EXISTS',
  DOCTOR_ON_THE_SAME_PORTAL_EXISTS = 'DOCTOR_ON_THE_SAME_PORTAL_EXISTS',
  NON_DOCTOR_EXISTS = 'NON_DOCTOR_EXISTS',
  NON_DOCTOR_ON_THE_SAME_PORTAL_EXISTS = 'NON_DOCTOR_ON_THE_SAME_PORTAL_EXISTS',
  PATIENT_EXISTS = 'PATIENT_EXISTS',
  PATIENT_ON_THE_SAME_PORTAL_EXISTS = 'PATIENT_ON_THE_SAME_PORTAL_EXISTS',
  PROCEED_TO_CREATION = 'PROCEED_TO_CREATION'
}

export type PatientFile = {
  __typename?: 'PatientFile';
  file: File;
  id: Scalars['String']['output'];
  patient: Patient;
};

/** A replacement for PatientFile; AKA ClinicalFolderFile, that is a File which is bound to a Patient/their Clinical Folder */
export type PatientFileModel = {
  __typename?: 'PatientFileModel';
  file: FileModel;
  /** NB: this is the ID of the Patient-File DB relation, not of the File. */
  id: Scalars['String']['output'];
  patient: PatientModel;
};

export type PatientFilesFilterByArgs = {
  /**
   *
   *  true: with pdfData
   *  false: without pdfData
   *  undefined/null: both, with and not without pdfData (don't apply this filter)
   *
   */
  hasPDFData?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   *
   *  true: only cancelled
   *  false: only not cancelled
   *  undefined/null: both, cancelled and not cancelled (don't apply this filter)
   *
   */
  isCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   *
   *  Only applicable when invoker is *not* a Patient (gets error otherwise)
   *
   */
  patientId?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<FileTag>>;
  types?: InputMaybe<Array<FileType>>;
  /** AKA "createdBy" */
  uploadedByPortalUserId?: InputMaybe<Scalars['String']['input']>;
};

export type PatientFilesFromPdfDataCreationRequestArgs = {
  medicalFilesPdfData: Array<ChatMedicalFilePdfDataArgs>;
  otpCode: Scalars['String']['input'];
};

export type PatientInvoicesListOrderByArgs = {
  field?: InputMaybe<PatientInvoicesListOrderByField>;
  type?: InputMaybe<OrderByKeyword>;
};

export enum PatientInvoicesListOrderByField {
  DATE = 'DATE',
  DOCTOR_NAME = 'DOCTOR_NAME',
  SERVICE = 'SERVICE'
}

export type PatientLastChange = {
  __typename?: 'PatientLastChange';
  createdAt: Scalars['DateTime']['output'];
};

export type PatientListFilterByArgs = {
  isFullyRegisteredFilter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PatientListOrderByArgs = {
  field: PatientListOrderByField;
  type?: InputMaybe<OrderByType>;
};

export enum PatientListOrderByField {
  FULL_NAME_OR_EMAIL = 'FULL_NAME_OR_EMAIL',
  UPDATED_AT = 'UPDATED_AT'
}

export type PatientMedicalDataSurveyAnswerModel = {
  __typename?: 'PatientMedicalDataSurveyAnswerModel';
  surveyAnswerId: Scalars['String']['output'];
  surveyIssue: SurveyIssueModel;
  surveyIssueRecord: SurveyIssueRecordModel;
};

export type PatientModel = {
  __typename?: 'PatientModel';
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** when null implies that the field does not apply to the invoker */
  isCaredForByInvoker?: Maybe<Scalars['Boolean']['output']>;
  lastChange: PatientLastChange;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The PatientPortalUser related to the Patient in the same Portal as the invokers, if any */
  portalUser?: Maybe<PortalUserModel>;
  privacyAcceptance: PatientPrivacyAcceptance;
  profileImageFile?: Maybe<FileModel>;
  thumbnailImageFile?: Maybe<FileModel>;
  user: UserModel;
};

export type PatientPortalUserModel = {
  __typename?: 'PatientPortalUserModel';
  id: Scalars['String']['output'];
  institution: InstitutionModel;
  patient: PatientModel;
  portal: PortalModel;
  user: UserModel;
};

export type PatientPrivacyAcceptance = {
  __typename?: 'PatientPrivacyAcceptance';
  ehrConsensus: Scalars['Boolean']['output'];
};

export type PatientSurveyListEntryModel = {
  __typename?: 'PatientSurveyListEntryModel';
  issuerPortalUser: PortalUserModel;
  latestIssueDate: Scalars['DateTime']['output'];
  recipientPortalUser: PortalUserModel;
  survey: SurveyModel;
  unansweredCount: Scalars['Int']['output'];
};

export type PatientVoiceCallsGroupModel = {
  __typename?: 'PatientVoiceCallsGroupModel';
  callerPortalUser: PortalUserModel;
  lastVoiceCallDate: Scalars['DateTime']['output'];
  voiceCalls: Array<VoiceCallDetailsModel>;
};

export type PatientsAvailableForGsdSsoModel = {
  __typename?: 'PatientsAvailableForGsdSsoModel';
  gsdSsoPatients: Array<GsdSsoPatientModel>;
  gsdSsoRequestId: Scalars['String']['output'];
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoice?: Maybe<Invoice>;
  method: Scalars['String']['output'];
  order: Order;
  refund?: Maybe<Refund>;
  status: Scalars['String']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum PaymentStatus {
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  PAID = 'PAID',
  PAYMENT_FAILED = 'PAYMENT_FAILED'
}

export type PaypalCaptureOrderResponse = {
  __typename?: 'PaypalCaptureOrderResponse';
  orderId: Scalars['String']['output'];
  status: PaymentStatus;
};

export type PaypalCreateOrderResponse = {
  __typename?: 'PaypalCreateOrderResponse';
  orderId: Scalars['String']['output'];
  paypalOrderId: Scalars['String']['output'];
  status: OrderState;
};

export type PaypalRefundOrderResponse = {
  __typename?: 'PaypalRefundOrderResponse';
  orderId: Scalars['String']['output'];
  status: RefundStatus;
};

export type PaypalSettings = {
  __typename?: 'PaypalSettings';
  clientId: Scalars['String']['output'];
};

export type PdfHeaderExtractionResultModel = {
  __typename?: 'PdfHeaderExtractionResultModel';
  executionDate?: Maybe<Scalars['DateTime']['output']>;
  fileId: Scalars['String']['output'];
  fileTag: FileTag;
  matchesActualPatientName: Scalars['Boolean']['output'];
  patientName?: Maybe<Scalars['String']['output']>;
};

export type PdfParametersRevisionItemModel = {
  __typename?: 'PdfParametersRevisionItemModel';
  doctorConfirmation?: Maybe<RevisionDoctorConfirmationModel>;
  parameter: SubSectionLabParameter;
  unitOfMeasure: LabResultParameterUoM;
  value: Scalars['String']['output'];
};

export type PdfParametersRevisionModel = {
  __typename?: 'PdfParametersRevisionModel';
  completionPercent: Scalars['Float']['output'];
  items: Array<PdfParametersRevisionItemModel>;
  lastUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PendingApplicationRequestModel = {
  __typename?: 'PendingApplicationRequestModel';
  challenge: ChallengeModelUnion;
  id: Scalars['String']['output'];
};

export type PendingOTP = {
  __typename?: 'PendingOTP';
  gAuthUri: Scalars['String']['output'];
  pendingSecret: Scalars['String']['output'];
  qrCodeUrl: Scalars['String']['output'];
};

export type PhysiologicalHistoryDeathDateData = {
  __typename?: 'PhysiologicalHistoryDeathDateData';
  value: Scalars['DateTime']['output'];
};

export type PhysiologicalHistoryDeathDateDataArgs = {
  value: Scalars['DateTime']['input'];
};

export type PhysiologicalHistoryDeathDateHistory = {
  __typename?: 'PhysiologicalHistoryDeathDateHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PhysiologicalHistoryDeathDateData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PhysiologicalHistoryFitzpatrickScaleData = {
  __typename?: 'PhysiologicalHistoryFitzpatrickScaleData';
  value: FitzpatrickScale;
};

export type PhysiologicalHistoryFitzpatrickScaleDataArgs = {
  value: FitzpatrickScale;
};

export type PhysiologicalHistoryFitzpatrickScaleHistory = {
  __typename?: 'PhysiologicalHistoryFitzpatrickScaleHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PhysiologicalHistoryFitzpatrickScaleData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PhysiologicalHistoryGenericNotesData = {
  __typename?: 'PhysiologicalHistoryGenericNotesData';
  notes: Scalars['String']['output'];
};

export type PhysiologicalHistoryGenericNotesDataArgs = {
  notes: Scalars['String']['input'];
};

export type PhysiologicalHistoryGenericNotesHistory = {
  __typename?: 'PhysiologicalHistoryGenericNotesHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PhysiologicalHistoryGenericNotesData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PhysiologicalHistoryMenopauseData = {
  __typename?: 'PhysiologicalHistoryMenopauseData';
  age?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  type?: Maybe<MenopauseType>;
  value: Scalars['Boolean']['output'];
};

export type PhysiologicalHistoryMenopauseDataArgs = {
  age?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MenopauseType>;
  value: Scalars['Boolean']['input'];
};

export type PhysiologicalHistoryMenopauseHistory = {
  __typename?: 'PhysiologicalHistoryMenopauseHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PhysiologicalHistoryMenopauseData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PhysiologicalHistoryMenstrualCycleData = {
  __typename?: 'PhysiologicalHistoryMenstrualCycleData';
  notes?: Maybe<Scalars['String']['output']>;
  value: MenstrualCycle;
};

export type PhysiologicalHistoryMenstrualCycleDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: MenstrualCycle;
};

export type PhysiologicalHistoryMenstrualCycleHistory = {
  __typename?: 'PhysiologicalHistoryMenstrualCycleHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PhysiologicalHistoryMenstrualCycleData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PhysiologicalHistorySmokeData = {
  __typename?: 'PhysiologicalHistorySmokeData';
  notes?: Maybe<Scalars['String']['output']>;
  numberOfCigarettesPerWeek?: Maybe<Scalars['Int']['output']>;
  smoke: Scalars['Boolean']['output'];
};

export type PhysiologicalHistorySmokeDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  numberOfCigarettesPerWeek?: InputMaybe<Scalars['Int']['input']>;
  smoke: Scalars['Boolean']['input'];
};

export type PhysiologicalHistorySmokeHistory = {
  __typename?: 'PhysiologicalHistorySmokeHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: PhysiologicalHistorySmokeData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type PlaceDetailsModel = {
  __typename?: 'PlaceDetailsModel';
  fullAddress?: Maybe<Scalars['String']['output']>;
  googlePlaceId: Scalars['String']['output'];
};

export type Portal = {
  __typename?: 'Portal';
  configuration: PortalConfigurationModel;
  defaultLanguage: Scalars['String']['output'];
  domain?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  institutions: Array<PortalInstitution>;
  isDefault: Scalars['Boolean']['output'];
  isPatientAutoInviteEnabled: Scalars['Boolean']['output'];
  logoUrl: PortalLogoUrlModel;
  name: Scalars['String']['output'];
  palette: PortalPaletteModel;
  paletteV2: PortalPaletteV2Model;
  sidebarBottomLogoName?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
};

/** Mutually-exclusive configuration "templates" pertaining to third party integration aspects (invoicing/accounting, catalogue, users). Note: *not* all values deal with/imply the same aspects. When possible, rely on specific feature flags in PortalConfigurationModel instead of values from this enum.` */
export enum PortalConfigurationIntegrationType {
  /** Integration with campusbiomedico's accounting system; NB: Invoicing is *not* offloaded. */
  CAMPUS_BIOMEDICO = 'CAMPUS_BIOMEDICO',
  /** No third party integration (for any aspect) is in place. */
  DEFAULT = 'DEFAULT',
  /** Integration with ospedalesanraffaele's 'SAP middleware/wrapper' is in place. Offloads Invoicing, syncs doctors, catalogue, and patients. */
  GSD_HSR = 'GSD_HSR',
  /** Integration with OnHealth API (GVM/scn). Offloads Invoicing, syncs patients. */
  ON_HEALTH = 'ON_HEALTH'
}

export type PortalConfigurationModel = {
  __typename?: 'PortalConfigurationModel';
  canSignInWithGsdSso: Scalars['Boolean']['output'];
  defaultServiceConfigurations: DefaultServiceConfigurationsModel;
  hasAvailableSsnClinic: Scalars['Boolean']['output'];
  hasAvailableSurveys: Scalars['Boolean']['output'];
  integrationType: PortalConfigurationIntegrationType;
  isChatEnabled: Scalars['Boolean']['output'];
  isClinicsEnabled: Scalars['Boolean']['output'];
  isHospitalRequestReport: Scalars['Boolean']['output'];
  isOnHealthIntegrationEnabled: Scalars['Boolean']['output'];
  isPatientDataVisibleToManagers: Scalars['Boolean']['output'];
  /** If true -> the patient can register himself; info/request/chat flow are enabled; global search is enabled. */
  isPatientSelfRegistrationEnabled: Scalars['Boolean']['output'];
};

export type PortalDocumentListFilterByArgs = {
  /** If provided only documents with the specified groups will be matched (exactly. Virtual group '__none__' can be used to include results without a group set. */
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PortalDocumentModel = {
  __typename?: 'PortalDocumentModel';
  file: FileModel;
  group?: Maybe<Scalars['String']['output']>;
};

export type PortalInstitution = {
  __typename?: 'PortalInstitution';
  id: Scalars['String']['output'];
  institution: Institution;
  isDefault: Scalars['Boolean']['output'];
  portal: Portal;
};

export type PortalLogoUrlModel = {
  __typename?: 'PortalLogoUrlModel';
  big: Scalars['String']['output'];
  normal: Scalars['String']['output'];
  small: Scalars['String']['output'];
};

export type PortalModel = {
  __typename?: 'PortalModel';
  id: Scalars['String']['output'];
  logoUrl: PortalLogoUrlModel;
  name: Scalars['String']['output'];
  palette: PortalPaletteModel;
  paletteV2: PortalPaletteV2Model;
  slug: Scalars['String']['output'];
};

export type PortalPaletteModel = {
  __typename?: 'PortalPaletteModel';
  disabled: Scalars['String']['output'];
  hover: Scalars['String']['output'];
  main: Scalars['String']['output'];
  mainBackground: Scalars['String']['output'];
  selected: Scalars['String']['output'];
  softBackground: Scalars['String']['output'];
};

export type PortalPaletteV2AppModel = {
  __typename?: 'PortalPaletteV2AppModel';
  actionActive: Scalars['String']['output'];
  actionDisabled: Scalars['String']['output'];
  actionHover: Scalars['String']['output'];
  actionSelected: Scalars['String']['output'];
  genericDark: Scalars['String']['output'];
  genericDarker: Scalars['String']['output'];
  genericLight: Scalars['String']['output'];
  genericLighter: Scalars['String']['output'];
  genericMain: Scalars['String']['output'];
  notificationsMain: Scalars['String']['output'];
  primaryMain: Scalars['String']['output'];
  secondaryMain: Scalars['String']['output'];
  statusError: Scalars['String']['output'];
  statusSuccess: Scalars['String']['output'];
  statusWarning: Scalars['String']['output'];
  surfaceBackground: Scalars['String']['output'];
  surfaceDefault: Scalars['String']['output'];
  surfaceGeneric: Scalars['String']['output'];
  surfaceLight: Scalars['String']['output'];
};

export type PortalPaletteV2AppsModel = {
  __typename?: 'PortalPaletteV2AppsModel';
  doctor: PortalPaletteV2AppModel;
  patient: PortalPaletteV2AppModel;
};

export type PortalPaletteV2Model = {
  __typename?: 'PortalPaletteV2Model';
  apps: PortalPaletteV2AppsModel;
  web: PortalPaletteV2WebModel;
};

export type PortalPaletteV2WebModel = {
  __typename?: 'PortalPaletteV2WebModel';
  actionActive: Scalars['String']['output'];
  actionDisabled: Scalars['String']['output'];
  actionHover: Scalars['String']['output'];
  actionSelected: Scalars['String']['output'];
  auxDisabled: Scalars['String']['output'];
  auxScroll: Scalars['String']['output'];
  backgroundDefault: Scalars['String']['output'];
  blackMain: Scalars['String']['output'];
  blueLight: Scalars['String']['output'];
  blueMain: Scalars['String']['output'];
  errorDark: Scalars['String']['output'];
  errorLight: Scalars['String']['output'];
  errorMain: Scalars['String']['output'];
  genericDark: Scalars['String']['output'];
  genericLight: Scalars['String']['output'];
  genericMain: Scalars['String']['output'];
  greenDark: Scalars['String']['output'];
  greenMain: Scalars['String']['output'];
  infoDark: Scalars['String']['output'];
  infoLight: Scalars['String']['output'];
  infoMain: Scalars['String']['output'];
  linesBorders: Scalars['String']['output'];
  linesMain: Scalars['String']['output'];
  linesTable: Scalars['String']['output'];
  pastelGreenLight: Scalars['String']['output'];
  pastelGreenMain: Scalars['String']['output'];
  pastelRedLight: Scalars['String']['output'];
  pastelRedMain: Scalars['String']['output'];
  pastelYellowDark: Scalars['String']['output'];
  pastelYellowLight: Scalars['String']['output'];
  pastelYellowMain: Scalars['String']['output'];
  primaryMain: Scalars['String']['output'];
  redMain: Scalars['String']['output'];
  secondaryMain: Scalars['String']['output'];
  shadowDark: Scalars['String']['output'];
  shadowLight: Scalars['String']['output'];
  shadowMain: Scalars['String']['output'];
  surfaceDefault: Scalars['String']['output'];
  surfaceGeneric: Scalars['String']['output'];
  surfaceLight: Scalars['String']['output'];
  warningDark: Scalars['String']['output'];
  warningLight: Scalars['String']['output'];
  warningMain: Scalars['String']['output'];
  whiteMain: Scalars['String']['output'];
  yellowMain: Scalars['String']['output'];
};

export type PortalUserChatStatsModel = {
  __typename?: 'PortalUserChatStatsModel';
  /** How many ChatItems, across all Chats of of clinic type, are still unread by the PortalUser. */
  clinicOverallUnreadItemsCount: Scalars['Int']['output'];
  /** How many Chats of clinic type are currently pinned to the PortalUser's Home. */
  clinicPinnedToHomeCount: Scalars['Int']['output'];
  /** How many ChatItems, across all Chats of of one_to_one type, are still unread by the PortalUser. */
  oneToOneOverallUnreadItemsCount: Scalars['Int']['output'];
  /** How many Chats of one_to_one type are currently pinned to the PortalUser's Home. */
  oneToOnePinnedToHomeCount: Scalars['Int']['output'];
};

export type PortalUserInfoThreadStatsModel = {
  __typename?: 'PortalUserInfoThreadStatsModel';
  overallUnreadMessagesCount: Scalars['Int']['output'];
  pinnedToHomeCount: Scalars['Int']['output'];
};

export type PortalUserIntramedStatsModel = {
  __typename?: 'PortalUserIntramedStatsModel';
  pinnedToHomeCount: Scalars['Int']['output'];
};

export type PortalUserModel = {
  __typename?: 'PortalUserModel';
  id: Scalars['String']['output'];
  institution: InstitutionModel;
  institutionUser: InstitutionUserModel;
  isBlocked: Scalars['Boolean']['output'];
  isVisibleInSearch: Scalars['Boolean']['output'];
  portal: PortalModel;
  portalExtension: PortalUserPortalExtensionModel;
  profileType: ProfileType;
  user: UserModel;
};

export type PortalUserModelAndAdminUserModelUnion = AdminUserModel | PortalUserModel;

export type PortalUserModelUnion = DoctorPortalUserModel | NonDoctorPortalUserModel | PatientPortalUserModel;

/** Holds portal/integration-specific extensions to the base/shared schema */
export type PortalUserPortalExtensionModel = {
  __typename?: 'PortalUserPortalExtensionModel';
  /** When null, it means that the attribute "does not apply" (not on a GSD portal) */
  gsdHsrIsIntegrated?: Maybe<Scalars['Boolean']['output']>;
  /** AKA "mnemonico unità erogante" */
  onHealthDoctorId?: Maybe<Scalars['String']['output']>;
  /** Prefer using profile-type specific `onHealthDoctorId` and `onHealthPatientId` fields instead.  */
  onHealthIsIntegrated?: Maybe<Scalars['Boolean']['output']>;
  onHealthPatientId?: Maybe<Scalars['String']['output']>;
};

export type PortalUserReminderStatsModel = {
  __typename?: 'PortalUserReminderStatsModel';
  pinnedToHomeCount: Scalars['Int']['output'];
};

/** Container for user-specific stats about various application features/aspects */
export type PortalUserStatsModel = {
  __typename?: 'PortalUserStatsModel';
  chatStats: PortalUserChatStatsModel;
  infoThreadStats: PortalUserInfoThreadStatsModel;
  intramedStats: PortalUserIntramedStatsModel;
  portalUserModel: PortalUserModel;
  reminderStats: PortalUserReminderStatsModel;
  surveyStats: PortalUserSurveyStatsModel;
};

export type PortalUserSurveyStatsModel = {
  __typename?: 'PortalUserSurveyStatsModel';
  pinnedToHomeCount: Scalars['Int']['output'];
};

export enum PositiveNegative {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE'
}

export type PregnancyArgs = {
  additionalData?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  numberOfPregnancy?: InputMaybe<Scalars['Float']['input']>;
  value: Scalars['Boolean']['input'];
  yearOfDelivery?: InputMaybe<Scalars['Float']['input']>;
};

export enum PrimarySecondary {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}

export type Privacy = {
  __typename?: 'Privacy';
  file?: Maybe<File>;
  forProfileType: ProfileType;
  id: Scalars['String']['output'];
  isMandatory: Scalars['Boolean']['output'];
  type: PrivacyType;
};

export type PrivacyAcceptance = {
  id: Scalars['String']['input'];
  isAccepted: Scalars['Boolean']['input'];
};

export type PrivacyModel = {
  __typename?: 'PrivacyModel';
  file?: Maybe<FileModel>;
  forProfileType: ProfileType;
  isMandatory: Scalars['Boolean']['output'];
  type: PrivacyType;
};

export enum PrivacyType {
  CODE_OF_MEDICAL_ETHICS = 'CODE_OF_MEDICAL_ETHICS',
  DATA_PROCESSING = 'DATA_PROCESSING',
  DOCTOR_PATIENT_CONTRACT = 'DOCTOR_PATIENT_CONTRACT',
  EHR_CONSENSUS = 'EHR_CONSENSUS',
  MARKETING_CLIENTS_1 = 'MARKETING_CLIENTS_1',
  MARKETING_CLIENTS_2 = 'MARKETING_CLIENTS_2',
  NEWSLETTER_CONSENSUS = 'NEWSLETTER_CONSENSUS',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERMS_OF_USE = 'TERMS_OF_USE'
}

export enum ProfessionalRegister {
  BIOLOGY = 'BIOLOGY',
  DIETOLOGY = 'DIETOLOGY',
  DIETOLOGY_AND_NUTRITIONAL_SCIENCE = 'DIETOLOGY_AND_NUTRITIONAL_SCIENCE',
  NURSE = 'NURSE',
  OCCUPATIONAL_THERAPY = 'OCCUPATIONAL_THERAPY',
  PHARMACY = 'PHARMACY',
  PHYSIOTHERAPY = 'PHYSIOTHERAPY',
  PSYCHOLOGY_PSYCHOTHERAPY = 'PSYCHOLOGY_PSYCHOTHERAPY',
  SOCIAL_SERVICE = 'SOCIAL_SERVICE',
  SPEECH_THERAPY = 'SPEECH_THERAPY',
  SURGERY_AND_DENTISTRY = 'SURGERY_AND_DENTISTRY',
  VETERINARY_MEDICINE = 'VETERINARY_MEDICINE'
}

export type ProfileImageList = {
  __typename?: 'ProfileImageList';
  profileImage: File;
  thumbnailImage: File;
};

export type ProfileImageModel = {
  __typename?: 'ProfileImageModel';
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
};

export enum ProfileType {
  DOCTOR = 'DOCTOR',
  NON_DOCTOR = 'NON_DOCTOR',
  PATIENT = 'PATIENT'
}

export type PsychologicalHistory = {
  __typename?: 'PsychologicalHistory';
  alcohol?: Maybe<Array<QuestionnaireData>>;
  alvo?: Maybe<Array<QuestionnaireData>>;
  diuresis?: Maybe<Array<QuestionnaireData>>;
  menopause?: Maybe<Array<QuestionnaireData>>;
  physicalActivity?: Maybe<Array<QuestionnaireData>>;
  pregnancy?: Maybe<Array<QuestionnaireData>>;
  sleep?: Maybe<Array<QuestionnaireData>>;
  smoke?: Maybe<Array<QuestionnaireData>>;
  workingActivity?: Maybe<Array<StringContainer>>;
};

export type PsychologicalHistoryArgs = {
  alcohol?: InputMaybe<QuestionnaireDataArgs>;
  alvo?: InputMaybe<QuestionnaireDataArgs>;
  diuresis?: InputMaybe<QuestionnaireDataArgs>;
  menopause?: InputMaybe<MenopauseArgs>;
  physicalActivity?: InputMaybe<QuestionnaireDataArgs>;
  pregnancy?: InputMaybe<PregnancyArgs>;
  sleep?: InputMaybe<QuestionnaireDataArgs>;
  smoke?: InputMaybe<QuestionnaireDataArgs>;
  workingActivity?: InputMaybe<StringArgs>;
};

export enum PtnmMedicalIndexMParameter {
  M0 = 'M0',
  M1 = 'M1'
}

export enum PtnmMedicalIndexNParameter {
  N0 = 'N0',
  N1A = 'N1A',
  N1B = 'N1B',
  N1C = 'N1C',
  N2A = 'N2A',
  N2B = 'N2B',
  N2C = 'N2C',
  N3A = 'N3A',
  N3B = 'N3B',
  N3C = 'N3C'
}

export type PtnmMedicalIndexParametersModel = {
  mParameter: PtnmMedicalIndexMParameter;
  nParameter: PtnmMedicalIndexNParameter;
  tParameter: PtnmMedicalIndexTParameter;
};

export enum PtnmMedicalIndexTParameter {
  T0 = 'T0',
  T1A = 'T1A',
  T1B = 'T1B',
  T2A = 'T2A',
  T2B = 'T2B',
  T3A = 'T3A',
  T3B = 'T3B',
  T4A = 'T4A',
  T4B = 'T4B',
  TIS = 'TIS'
}

export enum PushNotificationBundleId {
  IT_WELMED_BRAVOMED = 'IT_WELMED_BRAVOMED',
  IT_WELMED_MARIACECILIAHOSPITAL = 'IT_WELMED_MARIACECILIAHOSPITAL',
  IT_WELMED_OSPEDALESANRAFFAELE = 'IT_WELMED_OSPEDALESANRAFFAELE',
  IT_WELMED_POLIMEDICO = 'IT_WELMED_POLIMEDICO',
  IT_WELMED_TIBERIAHOSPITAL = 'IT_WELMED_TIBERIAHOSPITAL',
  IT_WELMED_WELCOMEDICINE = 'IT_WELMED_WELCOMEDICINE',
  IT_WELMED_WELMEDPRO = 'IT_WELMED_WELMEDPRO'
}

export enum PushNotificationDeviceOS {
  ANDROID = 'ANDROID',
  IOS = 'IOS'
}

export type Query = {
  __typename?: 'Query';
  accountingEntries: Array<AccountingEntryModel>;
  accountingMetadata: AccountingMetadata;
  allowedPathologies: Array<Pathology>;
  allowedSymptoms: Array<Symptom>;
  /** @deprecated Use announcements instead of this query */
  announcementList: Array<AnnouncementModel>;
  announcements: Array<AnnouncementModel>;
  assistedSearchDoctors: AssistedSearchDoctorsResultModel;
  assistedSearchSpecializations: AssistedSearchSpecializationsResultModel;
  /** Returns current authenticated user data */
  authenticatedUser: User;
  availableOnDemandServiceDates: Array<ServiceDateModel>;
  availableOnDemandServiceTimeSlots: Array<TimeSlotModel>;
  availableServiceDates: Array<ServiceDateModel>;
  availableServiceTimeSlots: Array<TimeSlotModel>;
  billingData?: Maybe<BillingData>;
  calculateRequestPrice: RequestPriceResponse;
  calculateShouldBeAnsweredBy: Scalars['DateTime']['output'];
  calendars: Array<CalendarModel>;
  canSendPatientInvite: Scalars['Boolean']['output'];
  cciMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  chat: ChatModelUnion;
  chatFiles: ChatFilesModel;
  chatVoiceCalls: Array<VoiceCallDetailsModel>;
  chats: PaginatedChatModelResponse;
  chatsCount: Scalars['Int']['output'];
  /** Validates that the provided fiscal code is intrinsically valid. */
  checkFiscalCode: Scalars['Boolean']['output'];
  checkPatientExistenceStatus: CheckPatientExistenceResponse;
  checkUserRegistrationStatus: CheckUserRegistrationStatusModel;
  checkValidationBirthResidenceAddressFromGsdSso: CheckValidationBirthResidenceAddressForGsdSsoModel;
  clinicCalendars: Array<CalendarModel>;
  clinicChat: ClinicChatModel;
  clinicChats: PaginatedClinicChatModelResponse;
  clinicRequestEvents: Array<RequestEventModel>;
  closedRequestList: PaginatedClosedRequestListModelResponse;
  competitiveSportCertificatePrefillableFormData: CompetitiveSportCertificatePrefillableFormDataModel;
  computeFiscalCode?: Maybe<Scalars['String']['output']>;
  ctnmMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  dashboardReminders: Array<ReminderModel>;
  dipssMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  dipssPlusMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  doctorAffiliation: AffiliationForDoctorModel;
  doctorAffiliations: PaginatedDoctorAffiliationModelResponse;
  doctorAffiliationsToPrompt: Array<AffiliationForDoctorModel>;
  doctorCanSelfAssignRequest: DoctorCanSelfAssignRequestResponseModel;
  doctorClinic: DoctorClinicModel;
  doctorClinicList: PaginatedDoctorClinicModelResponse;
  doctorForNewRequest: Array<InstitutionUserModel>;
  /** NB: HSR-specific */
  doctorIntegrationServices: DoctorIntegrationServicesModel;
  doctorRequestEvents: Array<RequestEventModel>;
  doctorSurveys: PaginatedDoctorSurveyListEntryModelResponse;
  doctorsSecretaryAssociations: Array<DoctorsSecretaryAssociation>;
  eltsMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  equipe: Equipe;
  equipes: Array<Equipe>;
  errorCodes: Array<ErrorCodes>;
  /** Intended to be used by PortalUser(s), without any ProfileType restriction. */
  file: FileModel;
  fileTagsForPatientUpload: Array<FileTag>;
  /** @deprecated Use patientFiles query instead */
  files: Array<PatientFile>;
  forgotPassword: CodeDeliveryDetailsModel;
  forwardedIntramedMessagePreview: ForwardedIntramedMessagePreviewModel;
  generateAccountingEntry: DraftAccountingEntryModel;
  getAllergiesAnaphylacticShockPatientMedicalDataHistory: Array<AllergiesAnaphylacticShockHistory>;
  getAllergiesHymenopteraPatientMedicalDataHistory: Array<AllergiesHymenopteraHistory>;
  getAllergiesOtherPatientMedicalDataHistory: Array<AllergiesOtherHistory>;
  getAnamnesisAllogeneicTransplantationPatientMedicalDataHistory: Array<AnamnesisAllogeneicTransplantationHistory>;
  getAnamnesisAutoimmuneDiseasesPatientMedicalDataHistory: Array<AnamnesisAutoimmuneDiseasesHistory>;
  getAnamnesisBreastFamilyHistoryPatientMedicalDataHistory: Array<AnamnesisBreastFamilyHistoryHistory>;
  getAnamnesisDonorPatientMedicalDataHistory: Array<AnamnesisDonorHistory>;
  getAnamnesisGlomerulonephritesPatientMedicalDataHistory: Array<AnamnesisGlomerulonephritesHistory>;
  getAnamnesisInfectionsPatientMedicalDataHistory: Array<AnamnesisInfectionsHistory>;
  getAnamnesisIsInTransplantListPatientMedicalDataHistory: Array<AnamnesisIsInTransplantListHistory>;
  getAnamnesisMelanomaFamilyHistoryPatientMedicalDataHistory: Array<AnamnesisMelanomaFamilyHistoryHistory>;
  getAnamnesisNeoplasiaPatientMedicalDataHistory: Array<AnamnesisNeoplasiaHistory>;
  getAnamnesisNetSurgeryFlagPatientMedicalDataHistory: Array<AnamnesisNetSurgeryFlagHistory>;
  /** This query is actually used to retrieve NET_SURGERY history, see getAnamnesisNetSurgeryFlagPatientMedicalDataHistory and getLastAnamnesisNetSurgeryFlagPatientMedicalDataHistory for storing the flag */
  getAnamnesisNetSurgeryPatientMedicalDataHistory: AnamnesisNetSurgeryHistoryModel;
  getAnamnesisSevereHypertensionPatientMedicalDataHistory: Array<AnamnesisSevereHypertensionHistory>;
  getAnamnesisSurgeryForBreastCancerPatientMedicalDataHistory: Array<AnamnesisSurgeryForBreastCancerHistory>;
  getAnamnesisTraumaPatientMedicalDataHistory: Array<AnamnesisTraumaHistory>;
  /** @deprecated use getPatientLabResultsParametersHistory instead */
  getBreastPatientLabResultsParametersHistory: Array<LabResultParameterHistory>;
  /** @deprecated use getPatientLabResultsParametersHistory instead */
  getCMLPatientLabResultsParametersHistory: Array<LabResultParameterHistory>;
  /** @deprecated use getPatientLabResultsParametersHistory instead */
  getCarTPatientLabResultsParametersHistory: Array<LabResultParameterHistory>;
  getCountries: Array<CountryModel>;
  getCurrentClinicalEvaluationGenericNotesPatientMedicalDataHistory: Array<CurrentClinicalEvaluationGenericNotesHistory>;
  getDiagnosesAhusDiagnosisDatePatientMedicalDataHistory: Array<DiagnosesAhusDiagnosisDateHistory>;
  getDiagnosesDiseaseStagePatientMedicalDataHistory: Array<DiagnosesDiseaseStageHistory>;
  getDiagnosesGenericNotesPatientMedicalDataHistory: Array<DiagnosesGenericNotesHistory>;
  getDiagnosesNetGepGradingPatientMedicalDataHistory: Array<DiagnosesNetGepGradingHistory>;
  getDiagnosesNetThoraxGradingPatientMedicalDataHistory: Array<DiagnosesNetThoraxGradingHistory>;
  getDiagnosesNetThoraxStagingTnmAsco2021PatientMedicalDataHistory: Array<DiagnosesNetThoraxStagingTnmAsco2021History>;
  getDoctorDetailsBySlug: DoctorPortalUserModel;
  getFamilyAnamnesisCardiovascularDiseasePatientMedicalDataHistory: Array<FamilyAnamnesisCardiovascularDiseaseHistory>;
  getFamilyAnamnesisGenericNotesPatientMedicalDataHistory: Array<FamilyAnamnesisGenericNotesHistory>;
  getFamilyAnamnesisKidneyFailurePatientMedicalDataHistory: Array<FamilyAnamnesisKidneyFailureHistory>;
  getFamilyAnamnesisNetGastricUlcersPatientMedicalDataHistory: Array<FamilyAnamnesisNetGastricUlcersHistory>;
  getFamilyAnamnesisNetGlucidicMetabolismAlterationPatientMedicalDataHistory: Array<FamilyAnamnesisNetGlucidicMetabolismAlterationHistory>;
  getFamilyAnamnesisNetHypercalcemiaPatientMedicalDataHistory: Array<FamilyAnamnesisNetHypercalcemiaHistory>;
  getFamilyAnamnesisNetHypophysisAdenomaPatientMedicalDataHistory: Array<FamilyAnamnesisNetHypophysisAdenomaHistory>;
  getFamilyAnamnesisNetMultipleEndocrineNeoplasiaPatientMedicalDataHistory: Array<FamilyAnamnesisNetMultipleEndocrineNeoplasiaHistory>;
  getFamilyAnamnesisNetNephrolithiasisPatientMedicalDataHistory: Array<FamilyAnamnesisNetNephrolithiasisHistory>;
  getFamilyAnamnesisNetNeuroendocrineTumorsPatientMedicalDataHistory: Array<FamilyAnamnesisNetNeuroendocrineTumorsHistory>;
  getFamilyAnamnesisNetOtherNeoplasiaPatientMedicalDataHistory: Array<FamilyAnamnesisNetOtherNeoplasiaHistory>;
  getFileUrl: Scalars['String']['output'];
  getGenericAhusTookChargeDatePatientMedicalDataHistory: Array<GenericAhusTookChargeDateHistory>;
  getGooglePlaceDetails: GooglePlaceDetailsModel;
  getItalianCitiesByGsdHsr: Array<GetItalianCityByGsdHsrModel>;
  getLabResultAdamts13DeliveryDatePatientMedicalDataHistory: Array<LabResultAdamts13DeliveryDateHistory>;
  getLabResultAdamts13RequestDatePatientMedicalDataHistory: Array<LabResultAdamts13RequestDateHistory>;
  getLabResultGeneticsPatientMedicalDataHistory: Array<LabResultGeneticsHistory>;
  getLabResultParameterToUoMsMap: Array<LabResultParameterToUoMConfigModel>;
  getLabResultUrineExamPatientMedicalDataHistory: Array<LabResultUrineExamHistory>;
  getLabResultsBcrAbl1MutationPatientMedicalDataHistory: Array<LabResultsBcrAbl1MutationHistory>;
  getLabResultsBcrAbl1PcrQPatientMedicalDataHistory: Array<LabResultsBcrAbl1PcrQHistory>;
  getLabResultsBcrAbl1TranscriptPatientMedicalDataHistory: Array<LabResultsBcrAbl1TranscriptHistory>;
  getLabResultsCALRPatientMedicalDataHistory: Array<LabResultsCALRHistory>;
  getLabResultsJak2Exon12PatientMedicalDataHistory: Array<LabResultsJak2Exon12History>;
  getLabResultsJak2V617FPatientMedicalDataHistory: Array<LabResultsJak2V617FHistory>;
  getLabResultsMPLPatientMedicalDataHistory: Array<LabResultsMPLHistory>;
  getLabResultsNGSPatientMedicalDataHistory: Array<LabResultsNGSHistory>;
  getLabResultsSubclonalPatientMedicalDataHistory: Array<LabResultsSubclonalHistory>;
  getLastAnamnesisAllogeneicTransplantationPatientMedicalDataHistory?: Maybe<AnamnesisAllogeneicTransplantationHistory>;
  getLastAnamnesisAutoimmuneDiseasesPatientMedicalDataHistory?: Maybe<AnamnesisAutoimmuneDiseasesHistory>;
  getLastAnamnesisBreastFamilyHistoryPatientMedicalDataHistory?: Maybe<AnamnesisBreastFamilyHistoryHistory>;
  getLastAnamnesisDonorPatientMedicalDataHistory?: Maybe<AnamnesisDonorHistory>;
  getLastAnamnesisGlomerulonephritesPatientMedicalDataHistory?: Maybe<AnamnesisGlomerulonephritesHistory>;
  getLastAnamnesisInfectionsPatientMedicalDataHistory?: Maybe<AnamnesisInfectionsHistory>;
  getLastAnamnesisIsInTransplantListPatientMedicalDataHistory?: Maybe<AnamnesisIsInTransplantListHistory>;
  getLastAnamnesisMelanomaFamilyHistoryPatientMedicalDataHistory?: Maybe<AnamnesisMelanomaFamilyHistoryHistory>;
  getLastAnamnesisNeoplasiaPatientMedicalDataHistory?: Maybe<AnamnesisNeoplasiaHistory>;
  getLastAnamnesisNetSurgeryFlagPatientMedicalDataHistory?: Maybe<AnamnesisNetSurgeryFlagHistory>;
  getLastAnamnesisSevereHypertensionPatientMedicalDataHistory?: Maybe<AnamnesisSevereHypertensionHistory>;
  getLastAnamnesisSurgeryForBreastCancerPatientMedicalDataHistory?: Maybe<AnamnesisSurgeryForBreastCancerHistory>;
  getLastAnamnesisTraumaPatientMedicalDataHistory?: Maybe<AnamnesisTraumaHistory>;
  getLastCurrentClinicalEvaluationGenericNotesPatientMedicalDataHistory?: Maybe<CurrentClinicalEvaluationGenericNotesHistory>;
  getLastDiagnosesAhusDiagnosisDatePatientMedicalDataHistory?: Maybe<DiagnosesAhusDiagnosisDateHistory>;
  getLastDiagnosesDiseaseStagePatientMedicalDataHistory?: Maybe<DiagnosesDiseaseStageHistory>;
  getLastDiagnosesGenericNotesPatientMedicalDataHistory?: Maybe<DiagnosesGenericNotesHistory>;
  getLastDiagnosesNetGepGradingPatientMedicalDataHistory?: Maybe<DiagnosesNetGepGradingHistory>;
  getLastDiagnosesNetThoraxGradingPatientMedicalDataHistory?: Maybe<DiagnosesNetThoraxGradingHistory>;
  getLastDiagnosesNetThoraxStagingTnmAsco2021PatientMedicalDataHistory?: Maybe<DiagnosesNetThoraxStagingTnmAsco2021History>;
  getLastFamilyAnamnesisCardiovascularDiseasePatientMedicalDataHistory?: Maybe<FamilyAnamnesisCardiovascularDiseaseHistory>;
  getLastFamilyAnamnesisGenericNotesPatientMedicalDataHistory?: Maybe<FamilyAnamnesisGenericNotesHistory>;
  getLastFamilyAnamnesisKidneyFailurePatientMedicalDataHistory?: Maybe<FamilyAnamnesisKidneyFailureHistory>;
  getLastFamilyAnamnesisNetGastricUlcersPatientMedicalDataHistory?: Maybe<FamilyAnamnesisNetGastricUlcersHistory>;
  getLastFamilyAnamnesisNetGlucidicMetabolismAlterationPatientMedicalDataHistory?: Maybe<FamilyAnamnesisNetGlucidicMetabolismAlterationHistory>;
  getLastFamilyAnamnesisNetHypercalcemiaPatientMedicalDataHistory?: Maybe<FamilyAnamnesisNetHypercalcemiaHistory>;
  getLastFamilyAnamnesisNetHypophysisAdenomaPatientMedicalDataHistory?: Maybe<FamilyAnamnesisNetHypophysisAdenomaHistory>;
  getLastFamilyAnamnesisNetMultipleEndocrineNeoplasiaPatientMedicalDataHistory?: Maybe<FamilyAnamnesisNetMultipleEndocrineNeoplasiaHistory>;
  getLastFamilyAnamnesisNetNephrolithiasisPatientMedicalDataHistory?: Maybe<FamilyAnamnesisNetNephrolithiasisHistory>;
  getLastFamilyAnamnesisNetNeuroendocrineTumorsPatientMedicalDataHistory?: Maybe<FamilyAnamnesisNetNeuroendocrineTumorsHistory>;
  getLastFamilyAnamnesisNetOtherNeoplasiaPatientMedicalDataHistory?: Maybe<FamilyAnamnesisNetOtherNeoplasiaHistory>;
  getLastGenericAhusTookChargeDatePatientMedicalDataHistory?: Maybe<GenericAhusTookChargeDateHistory>;
  getLastLabResultAdamts13DeliveryDatePatientMedicalDataHistory?: Maybe<LabResultAdamts13DeliveryDateHistory>;
  getLastLabResultAdamts13RequestDatePatientMedicalDataHistory?: Maybe<LabResultAdamts13RequestDateHistory>;
  getLastLabResultGeneticsPatientMedicalDataHistory?: Maybe<LabResultGeneticsHistory>;
  getLastLabResultUrineExamPatientMedicalDataHistory?: Maybe<LabResultUrineExamHistory>;
  getLastLabResultsBcrAbl1MutationPatientMedicalDataHistory?: Maybe<LabResultsBcrAbl1MutationHistory>;
  getLastLabResultsBcrAbl1PcrQPatientMedicalDataHistory?: Maybe<LabResultsBcrAbl1PcrQHistory>;
  getLastLabResultsBcrAbl1TranscriptPatientMedicalDataHistory?: Maybe<LabResultsBcrAbl1TranscriptHistory>;
  getLastLabResultsCALRPatientMedicalDataHistory?: Maybe<LabResultsCALRHistory>;
  getLastLabResultsJak2Exon12PatientMedicalDataHistory?: Maybe<LabResultsJak2Exon12History>;
  getLastLabResultsJak2V617FPatientMedicalDataHistory?: Maybe<LabResultsJak2V617FHistory>;
  getLastLabResultsMPLPatientMedicalDataHistory?: Maybe<LabResultsMPLHistory>;
  getLastLabResultsNGSPatientMedicalDataHistory?: Maybe<LabResultsNGSHistory>;
  getLastLabResultsSubclonalPatientMedicalDataHistory?: Maybe<LabResultsSubclonalHistory>;
  getLastPathologicalAnamnesisNetGastricUlcersPatientMedicalDataHistory?: Maybe<PathologicalAnamnesisNetGastricUlcersHistory>;
  getLastPathologicalAnamnesisNetGlucidicMetabolismAlterationPatientMedicalDataHistory?: Maybe<PathologicalAnamnesisNetGlucidicMetabolismAlterationHistory>;
  getLastPathologicalAnamnesisNetHypercalcemiaPatientMedicalDataHistory?: Maybe<PathologicalAnamnesisNetHypercalcemiaHistory>;
  getLastPathologicalAnamnesisNetHypophysisAdenomaPatientMedicalDataHistory?: Maybe<PathologicalAnamnesisNetHypophysisAdenomaHistory>;
  getLastPathologicalAnamnesisNetMultipleEndocrineNeoplasiaPatientMedicalDataHistory?: Maybe<PathologicalAnamnesisNetMultipleEndocrineNeoplasiaHistory>;
  getLastPathologicalAnamnesisNetNephrolithiasisPatientMedicalDataHistory?: Maybe<PathologicalAnamnesisNetNephrolithiasisHistory>;
  getLastPathologicalAnamnesisNetNeuroendocrineTumorsPatientMedicalDataHistory?: Maybe<PathologicalAnamnesisNetNeuroendocrineTumorsHistory>;
  getLastPathologicalAnamnesisNetOtherNeoplasiaPatientMedicalDataHistory?: Maybe<PathologicalAnamnesisNetOtherNeoplasiaHistory>;
  getLastPathologyResultsACAPatientMedicalDataHistory?: Maybe<PathologyResultsACAHistory>;
  getLastPathologyResultsAhusCytologicalExaminationPatientMedicalDataHistory?: Maybe<PathologyResultsAhusCytologicalExaminationHistory>;
  getLastPathologyResultsAhusHystologicalExaminationPatientMedicalDataHistory?: Maybe<PathologyResultsAhusHystologicalExaminationHistory>;
  getLastPathologyResultsBMAPatientMedicalDataHistory?: Maybe<PathologyResultsBMAHistory>;
  getLastPathologyResultsBMBPatientMedicalDataHistory?: Maybe<PathologyResultsBMBHistory>;
  getLastPathologyResultsBrafMutationPatientMedicalDataHistory?: Maybe<PathologyResultsBrafMutationHistory>;
  getLastPathologyResultsBreastHistologicalExaminationPatientMedicalDataHistory?: Maybe<PathologyResultsBreastHistologicalExaminationHistory>;
  getLastPathologyResultsBreastHistotypePatientMedicalDataHistory?: Maybe<PathologyResultsBreastHistotypeHistory>;
  getLastPathologyResultsBreastMetastasisPatientMedicalDataHistory?: Maybe<PathologyResultsBreastMetastasisHistory>;
  getLastPathologyResultsBreastNodesDissectionPatientMedicalDataHistory?: Maybe<PathologyResultsBreastNodesDissectionHistory>;
  getLastPathologyResultsBreastResectionMarginsPatientMedicalDataHistory?: Maybe<PathologyResultsBreastResectionMarginsHistory>;
  getLastPathologyResultsBreastSentinelNodePatientMedicalDataHistory?: Maybe<PathologyResultsBreastSentinelNodeHistory>;
  getLastPathologyResultsGenericNotesPatientMedicalDataHistory?: Maybe<PathologyResultsGenericNotesHistory>;
  getLastPathologyResultsHistologyPatientMedicalDataHistory?: Maybe<PathologyResultsHistologyHistory>;
  getLastPathologyResultsKaryotypePatientMedicalDataHistory?: Maybe<PathologyResultsKaryotypeHistory>;
  getLastPathologyResultsMelanomaHistotypePatientMedicalDataHistory?: Maybe<PathologyResultsMelanomaHistotypeHistory>;
  getLastPathologyResultsMelanomaLymphNodesHistologicalExaminationPatientMedicalDataHistory?: Maybe<PathologyResultsMelanomaLymphNodesHistologicalExaminationHistory>;
  getLastPathologyResultsMelanomaMetastasisPatientMedicalDataHistory?: Maybe<PathologyResultsMelanomaMetastasisHistory>;
  getLastPathologyResultsMelanomaNodesDissectionPatientMedicalDataHistory?: Maybe<PathologyResultsMelanomaNodesDissectionHistory>;
  getLastPathologyResultsMelanomaResectionMarginsPatientMedicalDataHistory?: Maybe<PathologyResultsMelanomaResectionMarginsHistory>;
  getLastPathologyResultsMelanomaSentinelNodePatientMedicalDataHistory?: Maybe<PathologyResultsMelanomaSentinelNodeHistory>;
  getLastPathologyResultsMelanomaTumorHistologicalExaminationPatientMedicalDataHistory?: Maybe<PathologyResultsMelanomaTumorHistologicalExaminationHistory>;
  getLastPathologyResultsNetCytologicalExaminationPatientMedicalDataHistory?: Maybe<PathologyResultsNetCytologicalExaminationHistory>;
  getLastPathologyResultsNetHistologicalExaminationPatientMedicalDataHistory?: Maybe<PathologyResultsNetHistologicalExaminationHistory>;
  getLastPathologyResultsNetLymphNodesHistologicalExaminationPatientMedicalDataHistory?: Maybe<PathologyResultsNetLymphNodesHistologicalExaminationHistory>;
  getLastPathologyResultsNetLymphNodesPresencePatientMedicalDataHistory?: Maybe<PathologyResultsNetLymphNodesPresenceHistory>;
  getLastPathologyResultsNetMetastasisPatientMedicalDataHistory?: Maybe<PathologyResultsNetMetastasisHistory>;
  getLastPathologyResultsNonBrafMutationPatientMedicalDataHistory?: Maybe<PathologyResultsNonBrafMutationHistory>;
  getLastPhysiologicalHistoryDeathDatePatientMedicalDataHistory?: Maybe<PhysiologicalHistoryDeathDateHistory>;
  getLastPhysiologicalHistoryFitzpatrickScalePatientMedicalDataHistory?: Maybe<PhysiologicalHistoryFitzpatrickScaleHistory>;
  getLastPhysiologicalHistoryGenericNotesPatientMedicalDataHistory?: Maybe<PhysiologicalHistoryGenericNotesHistory>;
  getLastPhysiologicalHistoryMenopausePatientMedicalDataHistory?: Maybe<PhysiologicalHistoryMenopauseHistory>;
  getLastPhysiologicalHistoryMenstrualCyclePatientMedicalDataHistory?: Maybe<PhysiologicalHistoryMenstrualCycleHistory>;
  getLastPhysiologicalHistorySmokePatientMedicalDataHistory?: Maybe<PhysiologicalHistorySmokeHistory>;
  getLastRadiologyECGPatientMedicalDataHistory?: Maybe<RadiologyECGHistory>;
  getLastRadiologyEchoPatientMedicalDataHistory?: Maybe<RadiologyEchoHistory>;
  getLastRadiologyEyeExaminationPatientMedicalDataHistory?: Maybe<RadiologyEyeExaminationHistory>;
  getLastRadiologySncLocalizationsPatientMedicalDataHistory?: Maybe<RadiologySncLocalizationsHistory>;
  getLastRiskScoreGenericNotesPatientMedicalDataHistory?: Maybe<RiskScoreGenericNotesHistory>;
  getLastSignsAndSymptomsAbdominalPainPatientMedicalDataHistory?: Maybe<SignsAndSymptomsAbdominalPainHistory>;
  getLastSignsAndSymptomsAstheniaPatientMedicalDataHistory?: Maybe<SignsAndSymptomsAstheniaHistory>;
  getLastSignsAndSymptomsBlurredEyesightPatientMedicalDataHistory?: Maybe<SignsAndSymptomsBlurredEyesightHistory>;
  getLastSignsAndSymptomsComaPatientMedicalDataHistory?: Maybe<SignsAndSymptomsComaHistory>;
  getLastSignsAndSymptomsConfusionPatientMedicalDataHistory?: Maybe<SignsAndSymptomsConfusionHistory>;
  getLastSignsAndSymptomsConvulsionsPatientMedicalDataHistory?: Maybe<SignsAndSymptomsConvulsionsHistory>;
  getLastSignsAndSymptomsDispneaPatientMedicalDataHistory?: Maybe<SignsAndSymptomsDispneaHistory>;
  getLastSignsAndSymptomsEcogPatientMedicalDataHistory?: Maybe<SignsAndSymptomsEcogHistory>;
  getLastSignsAndSymptomsEdemasPatientMedicalDataHistory?: Maybe<SignsAndSymptomsEdemasHistory>;
  getLastSignsAndSymptomsEyeBleedingPatientMedicalDataHistory?: Maybe<SignsAndSymptomsEyeBleedingHistory>;
  getLastSignsAndSymptomsEyePainPatientMedicalDataHistory?: Maybe<SignsAndSymptomsEyePainHistory>;
  getLastSignsAndSymptomsFeverPatientMedicalDataHistory?: Maybe<SignsAndSymptomsFeverHistory>;
  getLastSignsAndSymptomsGenericNotesPatientMedicalDataHistory?: Maybe<SignsAndSymptomsGenericNotesHistory>;
  getLastSignsAndSymptomsHeartAttackPatientMedicalDataHistory?: Maybe<SignsAndSymptomsHeartAttackHistory>;
  getLastSignsAndSymptomsHematuriaPatientMedicalDataHistory?: Maybe<SignsAndSymptomsHematuriaHistory>;
  getLastSignsAndSymptomsHemophthousPatientMedicalDataHistory?: Maybe<SignsAndSymptomsHemophthousHistory>;
  getLastSignsAndSymptomsHemoptysisPatientMedicalDataHistory?: Maybe<SignsAndSymptomsHemoptysisHistory>;
  getLastSignsAndSymptomsHypertensionPatientMedicalDataHistory?: Maybe<SignsAndSymptomsHypertensionHistory>;
  getLastSignsAndSymptomsNauseaPatientMedicalDataHistory?: Maybe<SignsAndSymptomsNauseaHistory>;
  getLastSignsAndSymptomsNetAchePatientMedicalDataHistory?: Maybe<SignsAndSymptomsNetAcheHistory>;
  getLastSignsAndSymptomsNetDiarrheaPatientMedicalDataHistory?: Maybe<SignsAndSymptomsNetDiarrheaHistory>;
  getLastSignsAndSymptomsNetFlushingPatientMedicalDataHistory?: Maybe<SignsAndSymptomsNetFlushingHistory>;
  getLastSignsAndSymptomsNetJaundicePatientMedicalDataHistory?: Maybe<SignsAndSymptomsNetJaundiceHistory>;
  getLastSignsAndSymptomsNetRetchingPatientMedicalDataHistory?: Maybe<SignsAndSymptomsNetRetchingHistory>;
  getLastSignsAndSymptomsNetTachycardiaPatientMedicalDataHistory?: Maybe<SignsAndSymptomsNetTachycardiaHistory>;
  getLastSignsAndSymptomsNetWeightLossPatientMedicalDataHistory?: Maybe<SignsAndSymptomsNetWeightLossHistory>;
  getLastSignsAndSymptomsOligoanuriaPatientMedicalDataHistory?: Maybe<SignsAndSymptomsOligoanuriaHistory>;
  getLastSignsAndSymptomsPancreatitisPatientMedicalDataHistory?: Maybe<SignsAndSymptomsPancreatitisHistory>;
  getLastSignsAndSymptomsPulmonaryBleedingPatientMedicalDataHistory?: Maybe<SignsAndSymptomsPulmonaryBleedingHistory>;
  getLastSignsAndSymptomsPulmonaryEdemaPatientMedicalDataHistory?: Maybe<SignsAndSymptomsPulmonaryEdemaHistory>;
  getLastSignsAndSymptomsRetinalVesselsObstructionPatientMedicalDataHistory?: Maybe<SignsAndSymptomsRetinalVesselsObstructionHistory>;
  getLastSignsAndSymptomsSplenomegalyPalpablePatientMedicalDataHistory?: Maybe<SignsAndSymptomsSplenomegalyPalpableHistory>;
  getLastSignsAndSymptomsStenosisPatientMedicalDataHistory?: Maybe<SignsAndSymptomsStenosisHistory>;
  getLastSignsAndSymptomsStrokePatientMedicalDataHistory?: Maybe<SignsAndSymptomsStrokeHistory>;
  getLastSignsAndSymptomsTachypneaPatientMedicalDataHistory?: Maybe<SignsAndSymptomsTachypneaHistory>;
  getLastSignsAndSymptomsVisualImpairmentPatientMedicalDataHistory?: Maybe<SignsAndSymptomsVisualImpairmentHistory>;
  getLastTherapyAhusMedicationsStartDatePatientMedicalDataHistory?: Maybe<TherapyAhusMedicationsStartDateHistory>;
  getLastTherapyApheresisPatientMedicalDataHistory?: Maybe<TherapyApheresisHistory>;
  getLastTherapyBreastAdjuvantTherapyPatientMedicalDataHistory?: Maybe<TherapyBreastAdjuvantTherapyHistory>;
  getLastTherapyBreastHormoneTherapyPatientMedicalDataHistory?: Maybe<TherapyBreastHormoneTherapyHistory>;
  getLastTherapyBreastNeoadjuvantTherapyPatientMedicalDataHistory?: Maybe<TherapyBreastNeoadjuvantTherapyHistory>;
  getLastTherapyBreastRadiotherapyPatientMedicalDataHistory?: Maybe<TherapyBreastRadiotherapyHistory>;
  getLastTherapyCarTEligibilityPatientMedicalDataHistory?: Maybe<TherapyCarTEligibilityHistory>;
  getLastTherapyCarTInfusionPatientMedicalDataHistory?: Maybe<TherapyCarTInfusionHistory>;
  getLastTherapyCarTTreatmentArrivePatientMedicalDataHistory?: Maybe<TherapyCarTTreatmentArriveHistory>;
  getLastTherapyCarTTreatmentOrderPatientMedicalDataHistory?: Maybe<TherapyCarTTreatmentOrderHistory>;
  getLastTherapyNetAdjuvantTherapyPatientMedicalDataHistory?: Maybe<TherapyNetAdjuvantTherapyHistory>;
  getLastTherapyNetNeoadjuvantTherapyPatientMedicalDataHistory?: Maybe<TherapyNetNeoadjuvantTherapyHistory>;
  getLastTherapyNetRadioReceptorTherapyPatientMedicalDataHistory?: Maybe<TherapyNetRadioReceptorTherapyHistory>;
  getLastTherapyNetSomatostatinAnalogueTherapyPatientMedicalDataHistory?: Maybe<TherapyNetSomatostatinAnalogueTherapyHistory>;
  getLastTherapyTherapyPatientMedicalDataHistory?: Maybe<TherapyTherapyHistory>;
  getLastVaccinationsAntimeningococcalVaccinationPatientMedicalDataHistory?: Maybe<VaccinationsAntimeningococcalVaccinationHistory>;
  getLastVaccinationsGenericNotesPatientMedicalDataHistory?: Maybe<VaccinationsGenericNotesHistory>;
  getLastVitalsParametersGenericNotesPatientMedicalDataHistory?: Maybe<VitalsParametersGenericNotesHistory>;
  /** @deprecated use getPatientLabResultsParametersHistory instead */
  getMFPatientLabResultsParametersHistory: Array<LabResultParameterHistory>;
  /** @deprecated use getPatientLabResultsParametersHistory instead */
  getMastocytosisPatientLabResultsParametersHistory: Array<LabResultParameterHistory>;
  getMastocytosisPatientMedicalData: MastocytosisData;
  /** @deprecated use getPatientLabResultsParametersHistory instead */
  getMedicalDataPatientLabResultsParametersHistory: Array<LabResultParameterHistory>;
  /** @deprecated use getPatientLabResultsParametersHistory instead */
  getMelanomaPatientLabResultsParametersHistory: Array<LabResultParameterHistory>;
  /** @deprecated use getPatientLabResultsParametersHistory instead */
  getNetPatientLabResultsParametersHistory: Array<LabResultParameterHistory>;
  getNumberOfUnreadAnnouncements: Scalars['Float']['output'];
  /** @deprecated use getPatientLabResultsParametersHistory instead */
  getPVPatientLabResultsParametersHistory: Array<LabResultParameterHistory>;
  getParticularServiceInformation: ServiceInformationModel;
  getPathologicalAnamnesisNetGastricUlcersPatientMedicalDataHistory: Array<PathologicalAnamnesisNetGastricUlcersHistory>;
  getPathologicalAnamnesisNetGlucidicMetabolismAlterationPatientMedicalDataHistory: Array<PathologicalAnamnesisNetGlucidicMetabolismAlterationHistory>;
  getPathologicalAnamnesisNetHypercalcemiaPatientMedicalDataHistory: Array<PathologicalAnamnesisNetHypercalcemiaHistory>;
  getPathologicalAnamnesisNetHypophysisAdenomaPatientMedicalDataHistory: Array<PathologicalAnamnesisNetHypophysisAdenomaHistory>;
  getPathologicalAnamnesisNetMultipleEndocrineNeoplasiaPatientMedicalDataHistory: Array<PathologicalAnamnesisNetMultipleEndocrineNeoplasiaHistory>;
  getPathologicalAnamnesisNetNephrolithiasisPatientMedicalDataHistory: Array<PathologicalAnamnesisNetNephrolithiasisHistory>;
  getPathologicalAnamnesisNetNeuroendocrineTumorsPatientMedicalDataHistory: Array<PathologicalAnamnesisNetNeuroendocrineTumorsHistory>;
  getPathologicalAnamnesisNetOtherNeoplasiaPatientMedicalDataHistory: Array<PathologicalAnamnesisNetOtherNeoplasiaHistory>;
  getPathologyResultsACAPatientMedicalDataHistory: Array<PathologyResultsACAHistory>;
  getPathologyResultsAhusCytologicalExaminationPatientMedicalDataHistory: Array<PathologyResultsAhusCytologicalExaminationHistory>;
  getPathologyResultsAhusHystologicalExaminationPatientMedicalDataHistory: Array<PathologyResultsAhusHystologicalExaminationHistory>;
  getPathologyResultsBMAPatientMedicalDataHistory: Array<PathologyResultsBMAHistory>;
  getPathologyResultsBMBPatientMedicalDataHistory: Array<PathologyResultsBMBHistory>;
  getPathologyResultsBrafMutationPatientMedicalDataHistory: Array<PathologyResultsBrafMutationHistory>;
  getPathologyResultsBreastHistologicalExaminationPatientMedicalDataHistory: Array<PathologyResultsBreastHistologicalExaminationHistory>;
  getPathologyResultsBreastHistotypePatientMedicalDataHistory: Array<PathologyResultsBreastHistotypeHistory>;
  getPathologyResultsBreastMetastasisPatientMedicalDataHistory: Array<PathologyResultsBreastMetastasisHistory>;
  getPathologyResultsBreastNodesDissectionPatientMedicalDataHistory: Array<PathologyResultsBreastNodesDissectionHistory>;
  getPathologyResultsBreastResectionMarginsPatientMedicalDataHistory: Array<PathologyResultsBreastResectionMarginsHistory>;
  getPathologyResultsBreastSentinelNodePatientMedicalDataHistory: Array<PathologyResultsBreastSentinelNodeHistory>;
  getPathologyResultsCKitMutationPatientMedicalDataHistory: Array<PathologyResultsCKitMutationHistory>;
  getPathologyResultsGenericNotesPatientMedicalDataHistory: Array<PathologyResultsGenericNotesHistory>;
  getPathologyResultsHistologyPatientMedicalDataHistory: Array<PathologyResultsHistologyHistory>;
  getPathologyResultsImmunophenotypingPatientMedicalDataHistory: Array<PathologyResultsImmunophenotypingHistory>;
  getPathologyResultsKaryotypePatientMedicalDataHistory: Array<PathologyResultsKaryotypeHistory>;
  getPathologyResultsMelanomaHistotypePatientMedicalDataHistory: Array<PathologyResultsMelanomaHistotypeHistory>;
  getPathologyResultsMelanomaLymphNodesHistologicalExaminationPatientMedicalDataHistory: Array<PathologyResultsMelanomaLymphNodesHistologicalExaminationHistory>;
  getPathologyResultsMelanomaMetastasisPatientMedicalDataHistory: Array<PathologyResultsMelanomaMetastasisHistory>;
  getPathologyResultsMelanomaNodesDissectionPatientMedicalDataHistory: Array<PathologyResultsMelanomaNodesDissectionHistory>;
  getPathologyResultsMelanomaResectionMarginsPatientMedicalDataHistory: Array<PathologyResultsMelanomaResectionMarginsHistory>;
  getPathologyResultsMelanomaSentinelNodePatientMedicalDataHistory: Array<PathologyResultsMelanomaSentinelNodeHistory>;
  getPathologyResultsMelanomaTumorHistologicalExaminationPatientMedicalDataHistory: Array<PathologyResultsMelanomaTumorHistologicalExaminationHistory>;
  getPathologyResultsNetCytologicalExaminationPatientMedicalDataHistory: Array<PathologyResultsNetCytologicalExaminationHistory>;
  getPathologyResultsNetHistologicalExaminationPatientMedicalDataHistory: Array<PathologyResultsNetHistologicalExaminationHistory>;
  getPathologyResultsNetLymphNodesHistologicalExaminationPatientMedicalDataHistory: Array<PathologyResultsNetLymphNodesHistologicalExaminationHistory>;
  getPathologyResultsNetLymphNodesPresencePatientMedicalDataHistory: Array<PathologyResultsNetLymphNodesPresenceHistory>;
  getPathologyResultsNetMetastasisPatientMedicalDataHistory: Array<PathologyResultsNetMetastasisHistory>;
  getPathologyResultsNonBrafMutationPatientMedicalDataHistory: Array<PathologyResultsNonBrafMutationHistory>;
  /** This generic query replaces/unifies all the different previous portal/tab specific ones (e.g. getMastocytosisPatientLabResultsParametersHistory), by requiring the **client** to specify the list (filter) of labResultParameters to fetch */
  getPatientLabResultsParametersHistory: Array<LabResultParameterHistory>;
  getPatientsAvailableForGsdSsoByAccessToken: PatientsAvailableForGsdSsoModel;
  getPatientsAvailableForGsdSsoByAuthorizationCode: PatientsAvailableForGsdSsoModel;
  getPhysiologicalHistoryDeathDatePatientMedicalDataHistory: Array<PhysiologicalHistoryDeathDateHistory>;
  getPhysiologicalHistoryFitzpatrickScalePatientMedicalDataHistory: Array<PhysiologicalHistoryFitzpatrickScaleHistory>;
  getPhysiologicalHistoryGenericNotesPatientMedicalDataHistory: Array<PhysiologicalHistoryGenericNotesHistory>;
  getPhysiologicalHistoryMenopausePatientMedicalDataHistory: Array<PhysiologicalHistoryMenopauseHistory>;
  getPhysiologicalHistoryMenstrualCyclePatientMedicalDataHistory: Array<PhysiologicalHistoryMenstrualCycleHistory>;
  getPhysiologicalHistorySmokePatientMedicalDataHistory: Array<PhysiologicalHistorySmokeHistory>;
  getPlacePredictions: Array<GooglePlacePredictionModel>;
  getRadiologyAbdomenUltrasoundPatientMedicalDataHistory: Array<RadiologyAbdomenUltrasoundHistory>;
  getRadiologyBoneDensitometryPatientMedicalDataHistory: Array<RadiologyBoneDensitometryHistory>;
  getRadiologyBoneImagingPatientMedicalDataHistory: Array<RadiologyBoneImagingHistory>;
  getRadiologyECGPatientMedicalDataHistory: Array<RadiologyECGHistory>;
  getRadiologyEchoPatientMedicalDataHistory: Array<RadiologyEchoHistory>;
  getRadiologyEyeExaminationPatientMedicalDataHistory: Array<RadiologyEyeExaminationHistory>;
  getRadiologySncLocalizationsPatientMedicalDataHistory: Array<RadiologySncLocalizationsHistory>;
  getReminder: ReminderModel;
  getRiskScoreGenericNotesPatientMedicalDataHistory: Array<RiskScoreGenericNotesHistory>;
  getSignsAndSymptomsAbdominalPainPatientMedicalDataHistory: Array<SignsAndSymptomsAbdominalPainHistory>;
  getSignsAndSymptomsAstheniaPatientMedicalDataHistory: Array<SignsAndSymptomsAstheniaHistory>;
  getSignsAndSymptomsBlurredEyesightPatientMedicalDataHistory: Array<SignsAndSymptomsBlurredEyesightHistory>;
  getSignsAndSymptomsComaPatientMedicalDataHistory: Array<SignsAndSymptomsComaHistory>;
  getSignsAndSymptomsConfusionPatientMedicalDataHistory: Array<SignsAndSymptomsConfusionHistory>;
  getSignsAndSymptomsConvulsionsPatientMedicalDataHistory: Array<SignsAndSymptomsConvulsionsHistory>;
  getSignsAndSymptomsDispneaPatientMedicalDataHistory: Array<SignsAndSymptomsDispneaHistory>;
  getSignsAndSymptomsEcogPatientMedicalDataHistory: Array<SignsAndSymptomsEcogHistory>;
  getSignsAndSymptomsEdemasPatientMedicalDataHistory: Array<SignsAndSymptomsEdemasHistory>;
  getSignsAndSymptomsEyeBleedingPatientMedicalDataHistory: Array<SignsAndSymptomsEyeBleedingHistory>;
  getSignsAndSymptomsEyePainPatientMedicalDataHistory: Array<SignsAndSymptomsEyePainHistory>;
  getSignsAndSymptomsFeverPatientMedicalDataHistory: Array<SignsAndSymptomsFeverHistory>;
  getSignsAndSymptomsGastrointestinalDisordersPatientMedicalDataHistory: Array<SignsAndSymptomsGastrointestinalDisordersHistory>;
  getSignsAndSymptomsGenericNotesPatientMedicalDataHistory: Array<SignsAndSymptomsGenericNotesHistory>;
  getSignsAndSymptomsHeartAttackPatientMedicalDataHistory: Array<SignsAndSymptomsHeartAttackHistory>;
  getSignsAndSymptomsHematuriaPatientMedicalDataHistory: Array<SignsAndSymptomsHematuriaHistory>;
  getSignsAndSymptomsHemophthousPatientMedicalDataHistory: Array<SignsAndSymptomsHemophthousHistory>;
  getSignsAndSymptomsHemoptysisPatientMedicalDataHistory: Array<SignsAndSymptomsHemoptysisHistory>;
  getSignsAndSymptomsHypertensionPatientMedicalDataHistory: Array<SignsAndSymptomsHypertensionHistory>;
  getSignsAndSymptomsNauseaPatientMedicalDataHistory: Array<SignsAndSymptomsNauseaHistory>;
  getSignsAndSymptomsNetAchePatientMedicalDataHistory: Array<SignsAndSymptomsNetAcheHistory>;
  getSignsAndSymptomsNetDiarrheaPatientMedicalDataHistory: Array<SignsAndSymptomsNetDiarrheaHistory>;
  getSignsAndSymptomsNetFlushingPatientMedicalDataHistory: Array<SignsAndSymptomsNetFlushingHistory>;
  getSignsAndSymptomsNetJaundicePatientMedicalDataHistory: Array<SignsAndSymptomsNetJaundiceHistory>;
  getSignsAndSymptomsNetRetchingPatientMedicalDataHistory: Array<SignsAndSymptomsNetRetchingHistory>;
  getSignsAndSymptomsNetTachycardiaPatientMedicalDataHistory: Array<SignsAndSymptomsNetTachycardiaHistory>;
  getSignsAndSymptomsNetWeightLossPatientMedicalDataHistory: Array<SignsAndSymptomsNetWeightLossHistory>;
  getSignsAndSymptomsOligoanuriaPatientMedicalDataHistory: Array<SignsAndSymptomsOligoanuriaHistory>;
  getSignsAndSymptomsPancreatitisPatientMedicalDataHistory: Array<SignsAndSymptomsPancreatitisHistory>;
  getSignsAndSymptomsPulmonaryBleedingPatientMedicalDataHistory: Array<SignsAndSymptomsPulmonaryBleedingHistory>;
  getSignsAndSymptomsPulmonaryEdemaPatientMedicalDataHistory: Array<SignsAndSymptomsPulmonaryEdemaHistory>;
  getSignsAndSymptomsRetinalVesselsObstructionPatientMedicalDataHistory: Array<SignsAndSymptomsRetinalVesselsObstructionHistory>;
  getSignsAndSymptomsSkinInvolvementPatientMedicalDataHistory: Array<SignsAndSymptomsSkinInvolvementHistory>;
  getSignsAndSymptomsSplenomegalyPalpablePatientMedicalDataHistory: Array<SignsAndSymptomsSplenomegalyPalpableHistory>;
  getSignsAndSymptomsStenosisPatientMedicalDataHistory: Array<SignsAndSymptomsStenosisHistory>;
  getSignsAndSymptomsStrokePatientMedicalDataHistory: Array<SignsAndSymptomsStrokeHistory>;
  getSignsAndSymptomsSyncopesPatientMedicalDataHistory: Array<SignsAndSymptomsSyncopesHistory>;
  getSignsAndSymptomsTachypneaPatientMedicalDataHistory: Array<SignsAndSymptomsTachypneaHistory>;
  getSignsAndSymptomsVisualImpairmentPatientMedicalDataHistory: Array<SignsAndSymptomsVisualImpairmentHistory>;
  getSurveyAnswersPDF: FileModel;
  getTherapyAhusMedicationsStartDatePatientMedicalDataHistory: Array<TherapyAhusMedicationsStartDateHistory>;
  getTherapyApheresisPatientMedicalDataHistory: Array<TherapyApheresisHistory>;
  getTherapyBreastAdjuvantTherapyPatientMedicalDataHistory: Array<TherapyBreastAdjuvantTherapyHistory>;
  getTherapyBreastHormoneTherapyPatientMedicalDataHistory: Array<TherapyBreastHormoneTherapyHistory>;
  getTherapyBreastNeoadjuvantTherapyPatientMedicalDataHistory: Array<TherapyBreastNeoadjuvantTherapyHistory>;
  getTherapyBreastRadiotherapyPatientMedicalDataHistory: Array<TherapyBreastRadiotherapyHistory>;
  getTherapyCarTEligibilityPatientMedicalDataHistory: Array<TherapyCarTEligibilityHistory>;
  getTherapyCarTInfusionPatientMedicalDataHistory: Array<TherapyCarTInfusionHistory>;
  getTherapyCarTTreatmentArrivePatientMedicalDataHistory: Array<TherapyCarTTreatmentArriveHistory>;
  getTherapyCarTTreatmentOrderPatientMedicalDataHistory: Array<TherapyCarTTreatmentOrderHistory>;
  getTherapyHematologyPatientMedicalDataHistory: TherapyHematologyHistoryModel;
  getTherapyNetAdjuvantTherapyPatientMedicalDataHistory: Array<TherapyNetAdjuvantTherapyHistory>;
  getTherapyNetNeoadjuvantTherapyPatientMedicalDataHistory: Array<TherapyNetNeoadjuvantTherapyHistory>;
  getTherapyNetRadioReceptorTherapyPatientMedicalDataHistory: Array<TherapyNetRadioReceptorTherapyHistory>;
  getTherapyNetSomatostatinAnalogueTherapyPatientMedicalDataHistory: Array<TherapyNetSomatostatinAnalogueTherapyHistory>;
  getTherapyTherapyPatientMedicalDataHistory: Array<TherapyTherapyHistory>;
  getTumoursGenericInformationPatientMedicalDataHistory: Array<TumoursGenericInformationHistory>;
  getUserByPatientInvite: UserModel;
  getUserDataByUserClaimToken: UserDataByUserClaimTokenResponseModel;
  getVaccinationsAntimeningococcalVaccinationPatientMedicalDataHistory: Array<VaccinationsAntimeningococcalVaccinationHistory>;
  getVaccinationsGenericNotesPatientMedicalDataHistory: Array<VaccinationsGenericNotesHistory>;
  getVitalsParametersGenericNotesPatientMedicalDataHistory: Array<VitalsParametersGenericNotesHistory>;
  globalSearch: Array<GlobalSearchModel>;
  globalSearchDetails: GlobalSearchDetailsModel;
  /**
   * Lists PortalUserStatsModel for all the PortalUser(s) related to the invoker across all Portals.
   * NB: only PortalUser(s) with the same ProfileType as the invoker's are selected.
   */
  globalUserStats: Array<PortalUserStatsModel>;
  guestInvite: GuestInviteModel;
  icd9Diagnoses: Array<ICD9DiagnosisModel>;
  /** @deprecated use forwardedIntramedMessagePreview query with MessageForwardingSource.INFO */
  infoForwardedMessage: InfoForwardedMessageModel;
  infoThreads: PaginatedInfoThreadResponse;
  infoThreadsWithMessages: Array<InfoThread>;
  institutionUserProfileImage: ProfileImageModel;
  institutions: Array<InstitutionModel>;
  /** @deprecated use forwardedIntramedMessagePreview query with MessageForwardingSource.INTRAMED */
  intramedForwardedMessage: IntramedForwarderMessageModel;
  intramedThreadAttachments: IntramedThreadAttachmentsModel;
  intramedThreadDetails: IntramedThreadModel;
  intramedThreadList: Array<IntramedThreadModel>;
  intramedVideoMeetingURL: Scalars['String']['output'];
  invite: InviteModel;
  invites: PaginatedInviteModelResponse;
  ipssrMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  isUserEmailUnique: Scalars['Boolean']['output'];
  isUserPhoneNumberUnique: Scalars['Boolean']['output'];
  medicalData: MedicalData;
  medicalReportTemplates: PaginatedMedicalReportTemplateResponse;
  medicalRoles: Array<MedicalRoleModel>;
  mipss70MedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  mngAdminUserList: PaginatedAdminUserModelResponse;
  mngAffiliation: MngAffiliationModel;
  mngAffiliations: PaginatedMngAffiliationModelResponse;
  mngAnnouncement: MngAnnouncementModel;
  mngAnnouncements: Array<MngAnnouncementModel>;
  mngClinic: MngClinicModel;
  mngClinicCalendars: Array<CalendarModel>;
  mngClinicRequestEvents: Array<RequestEventModel>;
  mngClinics: PaginatedMngClinicModelResponse;
  mngGetAdminUser: AdminUserModel;
  mngGetAuthenticatedAdminUser: AdminUserModel;
  mngGetAvailableRegions: MngAvailableRegionsModel;
  mngGetCsvExportItems: Array<CsvExportTypeModel>;
  mngGetDoctorDetails: DoctorModel;
  mngGetFileUrl: Scalars['String']['output'];
  mngGetInstitution: MngInstitutionModel;
  mngGetNonDoctorDetails: NonDoctorModel;
  mngGetPatientDetails: MngPatientModel;
  mngGetRequestDetails: MngRequestModel;
  mngGetUserClaim: MngUserClaimModel;
  mngGetUserDetails: UserModel;
  /** given a patientPortalUserId return existing GsdHsrUserIntegration or create new one and return it */
  mngGsdHsrUserIntegration: MngGsdHsrUserIntegrationModel;
  /** @deprecated Please use mngPaginatedInstitutions */
  mngInstitutionList: PaginatedMngInstitutionModelResponse;
  mngInstitutionStatistics: Array<InvitedDataModel>;
  mngInstitutions: Array<MngInstitutionModel>;
  mngInvite: MngInviteModel;
  mngInvites: PaginatedMngInviteModelResponse;
  mngLookupGsdHsrGenericClinic: MngLookupGsdHsrGenericClinicResultModel;
  mngLookupGsdHsrSsnClinic: MngLookupGsdHsrSsnClinicResultModel;
  mngMedicalRoles: Array<MedicalRoleModel>;
  mngMemorandumList: PaginatedMngAccountingEntryModelResponse;
  mngPaginatedInstitutions: PaginatedMngInstitutionModelResponse;
  mngRequestList: PaginatedMngRequestModelResponse;
  mngUpdateNoticeList: Array<MngUpdateNoticeModel>;
  mngUserClaimList: PaginatedUserClaimModelResponse;
  mngUserList: PaginatedUserModelResponse;
  mngValidateGsdHsrBirthAddress: Scalars['Boolean']['output'];
  mngValidateGsdHsrResidenceAddress: Scalars['Boolean']['output'];
  mpn10MedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  myInfoRequestAcceptance: DoctorInfoRequestAcceptanceModel;
  myPrivacyAcceptances: Array<UserPrivacy>;
  mysecPmMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  networkInstitutions: Array<NetworkInstitution>;
  networkMembers: Array<InstitutionUser>;
  nonCompetitiveSportCertificatePrefillableFormData: NonCompetitiveSportCertificatePrefillableFormDataModel;
  notificationCount: Scalars['Float']['output'];
  notifications: Array<DashboardNotification>;
  oneToOneChat: OneToOneChatModel;
  oneToOneChats: PaginatedOneToOneChatModelResponse;
  oneToOneChatsV2: SearchOneToOneChatModelV2Response;
  orderByRequest: Order;
  patient: PatientModel;
  patientAvailableRequestBookingTypes: Array<ServiceBookingType>;
  patientCalculations: Array<PatientCalculationModel>;
  patientCareTeam: Array<CareTeamMember>;
  patientClinic: ClinicModel;
  patientCommunications: Array<PatientCommunicationUnion>;
  patientCommunicationsForUser: Array<PatientCommunicationUnion>;
  patientFiles: PaginatedPatientFileModelResponse;
  patientInvoicesAndRefundsList: Array<File>;
  patientJoinedAffiliations: PaginatedPatientAffiliationModelResponse;
  /** @deprecated use Query.searchPatients instead */
  patientList: PaginatedPatientModelResponse;
  patientMedicalDataSurveyAnswers: Array<PatientMedicalDataSurveyAnswerModel>;
  patientProfileImage: ProfileImageModel;
  patientRegistrationPrivacyAcceptance: PatientPrivacyAcceptance;
  patientSurveys: PaginatedPatientSurveyListEntryModelResponse;
  patientSurveysUnansweredCount: Scalars['Int']['output'];
  patientVoiceCalls: Array<PatientVoiceCallsGroupModel>;
  paypalSettings: PaypalSettings;
  pendingPrivacies: Array<Privacy>;
  portal: PortalModel;
  /** Gets all the available/defined Document groups on current Portal. The presence of Documents without a group is indicated by virtual group '__none__'. */
  portalDocumentGroups: Array<Scalars['String']['output']>;
  portalDocuments: PaginatedPortalDocumentResponse;
  portalSettings: Portal;
  portalUserProfileImage: ProfileImageModel;
  portalUserStats: PortalUserStatsModel;
  privacies: Array<Privacy>;
  ptnmMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  reminderList: PaginatedReminderModelResponse;
  /** @deprecated Use reminderList with filterBy arguments instead */
  remindersByRequest: Array<ReminderModel>;
  requestBookingTypeFilterSource: Array<ServiceBookingType>;
  requests: PaginatedRequestResponse;
  /** @deprecated use Query.searchPatients instead */
  searchAssociatedPatientsForDoctorOrNonDoctor: Array<InstitutionUserModel>;
  /** Searches Clinics with fuzzy text matching (guest/anonymous) */
  searchClinics: PaginatedClinicModelResponse;
  /** Searches Doctors with fuzzy text matching (guest/anonymous) */
  searchDoctors: PaginatedDoctorModelResponse;
  searchIntramedThreadRecipients: Array<IntramedThreadRecipientUnion>;
  searchOperators: Array<InstitutionUserModelAndEquipeModelUnion>;
  searchPatients: PaginatedPatientModelResponse;
  searchPotentialEquipeMembers: Array<DoctorNonDoctorPortalUserModelUnion>;
  /** Matches the provided search term to existing Doctors' Specializations (guest/anonymous) */
  searchSpecializations: PaginatedSpecializationsResponse;
  /** Matches the provided search term to existing Doctors' spheres of interest (AKA pathologies) (guest/anonymous) */
  searchSpheresOfInterest: PaginatedSphereOfInterestResponse;
  searchUser: Array<User>;
  searchUserToInvite: Array<InstitutionUserModel>;
  service: ServiceModel;
  serviceForRequestCreation: ServiceModel;
  services: Array<ServiceModel>;
  servicesByContext: ServicesByContextModel;
  sokalMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  survey: SurveyModel;
  surveyAnswerHistory: Array<SurveyAnswerHistoryModel>;
  surveyDefaultQuestionValues?: Maybe<Array<DefaultQuestionValueModel>>;
  surveyDoctorHeaderData: SurveyDoctorHeaderDataModel;
  surveyIssues: Array<SurveyIssueModel>;
  surveyVisibilityOnDoctorDashboard: Scalars['Boolean']['output'];
  surveyVisibilityOnPatientDashboard: Scalars['Boolean']['output'];
  surveys: Array<SurveyModel>;
  tnmAsco2021AppendixMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  tnmAsco2021ColonAndRectumMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  tnmAsco2021DuodenumAndVaterAmpullaMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  tnmAsco2021JejunumAndIleumMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  tnmAsco2021PancreasMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  tnmAsco2021StomachMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  tnmMedicalIndexCalculation: Array<PatientCalculationResultRecord>;
  unreadUpdateNoticeList: Array<UpdateNoticeModel>;
  userByPrivacyToken: UserModel;
  userRoles: UserRoles;
  validateCurrentResidenceAddress: Scalars['Boolean']['output'];
  validateFiscalCode: ValidateFiscalCodeResultModel;
  validateGsdHsrBirthAddress: Scalars['Boolean']['output'];
  validateGsdHsrPatientCreation: ValidateGsdHsrPatientCreationModel;
  validateGsdHsrResidenceAddress: Scalars['Boolean']['output'];
  videoVisitURL: Scalars['String']['output'];
};


export type QueryallowedPathologiesArgs = {
  specialization: Specialization;
};


export type QueryallowedSymptomsArgs = {
  specialization: Specialization;
};


export type QueryannouncementListArgs = {
  order: AnnouncementsOrder;
};


export type QueryannouncementsArgs = {
  order: AnnouncementsOrder;
};


export type QueryassistedSearchDoctorsArgs = {
  forPatientUserEmail?: InputMaybe<Scalars['String']['input']>;
  pathology?: InputMaybe<Pathology>;
  specialization?: InputMaybe<Specialization>;
  symptoms?: Array<Symptom>;
};


export type QueryassistedSearchSpecializationsArgs = {
  forPatientUserEmail?: InputMaybe<Scalars['String']['input']>;
};


export type QueryavailableOnDemandServiceDatesArgs = {
  doctorPortalUserId: Scalars['String']['input'];
  endAt: Scalars['DateTime']['input'];
  serviceId: Scalars['String']['input'];
  startAt: Scalars['DateTime']['input'];
};


export type QueryavailableOnDemandServiceTimeSlotsArgs = {
  desiredServiceDate: Scalars['DateTime']['input'];
  doctorPortalUserId: Scalars['String']['input'];
  serviceId: Scalars['String']['input'];
};


export type QueryavailableServiceDatesArgs = {
  doctorPortalUserId?: InputMaybe<Scalars['String']['input']>;
  endAt: Scalars['DateTime']['input'];
  serviceId: Scalars['String']['input'];
  startAt: Scalars['DateTime']['input'];
};


export type QueryavailableServiceTimeSlotsArgs = {
  desiredServiceDate: Scalars['DateTime']['input'];
  doctorPortalUserId?: InputMaybe<Scalars['String']['input']>;
  serviceId: Scalars['String']['input'];
};


export type QuerycalculateRequestPriceArgs = {
  selectedServiceAddonIds?: InputMaybe<Array<Scalars['String']['input']>>;
  service: Scalars['String']['input'];
};


export type QuerycalculateShouldBeAnsweredByArgs = {
  providedByPortalUserId?: InputMaybe<Scalars['String']['input']>;
  recipientInstitutionUser?: InputMaybe<Scalars['String']['input']>;
  selectedServiceAddonIds?: InputMaybe<Array<Scalars['String']['input']>>;
  selectedServiceAddons?: InputMaybe<Array<Scalars['String']['input']>>;
  service?: InputMaybe<Scalars['String']['input']>;
  serviceId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerycalendarsArgs = {
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
};


export type QuerycanSendPatientInviteArgs = {
  patientPortalUserId: Scalars['String']['input'];
};


export type QuerycciMedicalIndexCalculationArgs = {
  cciMedicalIndexParametersModel: CciMedicalIndexParametersModel;
};


export type QuerychatArgs = {
  chatId: Scalars['String']['input'];
};


export type QuerychatFilesArgs = {
  chatId: Scalars['String']['input'];
};


export type QuerychatVoiceCallsArgs = {
  chatId: Scalars['String']['input'];
};


export type QuerychatsArgs = {
  filterBy?: InputMaybe<ChatFilterByArgs>;
  orderBy?: InputMaybe<ChatOrderByArgs>;
  pagination?: InputMaybe<PaginationModel>;
  type?: InputMaybe<ChatType>;
};


export type QuerychatsCountArgs = {
  chatType: ChatType;
  filterBy?: InputMaybe<ChatFilterByArgs>;
};


export type QuerycheckFiscalCodeArgs = {
  fiscalCode: Scalars['String']['input'];
};


export type QuerycheckPatientExistenceStatusArgs = {
  birthAddressExternalGooglePlaceId: Scalars['String']['input'];
  birthDate: Scalars['DateTime']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  lastName: Scalars['String']['input'];
};


export type QuerycheckUserRegistrationStatusArgs = {
  birthAddressExternalGooglePlaceId: Scalars['String']['input'];
  birthDate: Scalars['DateTime']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  institutionId: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  profileType: ProfileType;
};


export type QuerycheckValidationBirthResidenceAddressFromGsdSsoArgs = {
  gsdSsoRequestId: Scalars['String']['input'];
  gsdSsoUserId: Scalars['String']['input'];
};


export type QueryclinicCalendarsArgs = {
  clinicId: Scalars['String']['input'];
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
};


export type QueryclinicChatArgs = {
  clinicId: Scalars['String']['input'];
  patientPortalUserId: Scalars['String']['input'];
};


export type QueryclinicChatsArgs = {
  filterBy?: InputMaybe<ChatFilterByArgs>;
  orderBy?: InputMaybe<ChatOrderByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QueryclinicRequestEventsArgs = {
  clinicId: Scalars['String']['input'];
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
};


export type QueryclosedRequestListArgs = {
  filterBy?: InputMaybe<ClosedRequestListFilters>;
  orderBy?: InputMaybe<ClosedRequestListOrderByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerycompetitiveSportCertificatePrefillableFormDataArgs = {
  patientId: Scalars['String']['input'];
  surveyAnswerId: Scalars['String']['input'];
};


export type QuerycomputeFiscalCodeArgs = {
  birthDate: Scalars['DateTime']['input'];
  externalGooglePlaceId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  lastName: Scalars['String']['input'];
};


export type QueryctnmMedicalIndexCalculationArgs = {
  ctnmMedicalIndexParametersModel: CtnmMedicalIndexParametersModel;
};


export type QuerydipssMedicalIndexCalculationArgs = {
  dipssMedicalIndexParametersModel: DipssMedicalIndexParametersModel;
};


export type QuerydipssPlusMedicalIndexCalculationArgs = {
  dipssPlusMedicalIndexParametersModel: DipssPlusMedicalIndexParametersModel;
};


export type QuerydoctorAffiliationArgs = {
  affiliationId: Scalars['String']['input'];
};


export type QuerydoctorAffiliationsArgs = {
  filterBy?: InputMaybe<DoctorAffiliationListFilterByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerydoctorCanSelfAssignRequestArgs = {
  requestId: Scalars['String']['input'];
};


export type QuerydoctorClinicArgs = {
  clinicId: Scalars['String']['input'];
};


export type QuerydoctorClinicListArgs = {
  filterBy?: InputMaybe<DoctorClinicListFilterByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerydoctorForNewRequestArgs = {
  fullName?: InputMaybe<Scalars['String']['input']>;
  queryString?: InputMaybe<Scalars['String']['input']>;
};


export type QuerydoctorIntegrationServicesArgs = {
  doctorInstitutionUserId: Scalars['String']['input'];
};


export type QuerydoctorRequestEventsArgs = {
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
};


export type QuerydoctorSurveysArgs = {
  includeHiddenInDashboard: Scalars['Boolean']['input'];
  pagination?: InputMaybe<PaginationModel>;
};


export type QueryeltsMedicalIndexCalculationArgs = {
  eltsMedicalIndexParametersModel: EltsMedicalIndexParametersModel;
};


export type QueryequipeArgs = {
  id: Scalars['String']['input'];
};


export type QueryequipesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<OrderByArgs>;
};


export type QueryfileArgs = {
  fileId: Scalars['String']['input'];
};


export type QueryfilesArgs = {
  filter?: InputMaybe<FileListFilterByArgs>;
  patientId: Scalars['String']['input'];
};


export type QueryforgotPasswordArgs = {
  emailOrPhoneNumber: Scalars['String']['input'];
};


export type QueryforwardedIntramedMessagePreviewArgs = {
  messageId: Scalars['String']['input'];
  messageSource: MessageForwardingSource;
};


export type QuerygetAllergiesAnaphylacticShockPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAllergiesHymenopteraPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAllergiesOtherPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisAllogeneicTransplantationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisAutoimmuneDiseasesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisBreastFamilyHistoryPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisDonorPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisGlomerulonephritesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisInfectionsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisIsInTransplantListPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisMelanomaFamilyHistoryPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisNeoplasiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisNetSurgeryFlagPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisNetSurgeryPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisSevereHypertensionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisSurgeryForBreastCancerPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetAnamnesisTraumaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetBreastPatientLabResultsParametersHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetCMLPatientLabResultsParametersHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetCarTPatientLabResultsParametersHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetCurrentClinicalEvaluationGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetDiagnosesAhusDiagnosisDatePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetDiagnosesDiseaseStagePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetDiagnosesGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetDiagnosesNetGepGradingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetDiagnosesNetThoraxGradingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetDiagnosesNetThoraxStagingTnmAsco2021PatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetDoctorDetailsBySlugArgs = {
  doctorSlug: Scalars['String']['input'];
};


export type QuerygetFamilyAnamnesisCardiovascularDiseasePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetFamilyAnamnesisGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetFamilyAnamnesisKidneyFailurePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetFamilyAnamnesisNetGastricUlcersPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetFamilyAnamnesisNetGlucidicMetabolismAlterationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetFamilyAnamnesisNetHypercalcemiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetFamilyAnamnesisNetHypophysisAdenomaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetFamilyAnamnesisNetMultipleEndocrineNeoplasiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetFamilyAnamnesisNetNephrolithiasisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetFamilyAnamnesisNetNeuroendocrineTumorsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetFamilyAnamnesisNetOtherNeoplasiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetFileUrlArgs = {
  fileId: Scalars['String']['input'];
};


export type QuerygetGenericAhusTookChargeDatePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetGooglePlaceDetailsArgs = {
  placeId: Scalars['String']['input'];
};


export type QuerygetItalianCitiesByGsdHsrArgs = {
  date: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
};


export type QuerygetLabResultAdamts13DeliveryDatePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLabResultAdamts13RequestDatePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLabResultGeneticsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLabResultUrineExamPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLabResultsBcrAbl1MutationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLabResultsBcrAbl1PcrQPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLabResultsBcrAbl1TranscriptPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLabResultsCALRPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLabResultsJak2Exon12PatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLabResultsJak2V617FPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLabResultsMPLPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLabResultsNGSPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLabResultsSubclonalPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastAnamnesisAllogeneicTransplantationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastAnamnesisAutoimmuneDiseasesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastAnamnesisBreastFamilyHistoryPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastAnamnesisDonorPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastAnamnesisGlomerulonephritesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastAnamnesisInfectionsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastAnamnesisIsInTransplantListPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastAnamnesisMelanomaFamilyHistoryPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastAnamnesisNeoplasiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastAnamnesisNetSurgeryFlagPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastAnamnesisSevereHypertensionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastAnamnesisSurgeryForBreastCancerPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastAnamnesisTraumaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastCurrentClinicalEvaluationGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastDiagnosesAhusDiagnosisDatePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastDiagnosesDiseaseStagePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastDiagnosesGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastDiagnosesNetGepGradingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastDiagnosesNetThoraxGradingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastDiagnosesNetThoraxStagingTnmAsco2021PatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastFamilyAnamnesisCardiovascularDiseasePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastFamilyAnamnesisGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastFamilyAnamnesisKidneyFailurePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastFamilyAnamnesisNetGastricUlcersPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastFamilyAnamnesisNetGlucidicMetabolismAlterationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastFamilyAnamnesisNetHypercalcemiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastFamilyAnamnesisNetHypophysisAdenomaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastFamilyAnamnesisNetMultipleEndocrineNeoplasiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastFamilyAnamnesisNetNephrolithiasisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastFamilyAnamnesisNetNeuroendocrineTumorsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastFamilyAnamnesisNetOtherNeoplasiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastGenericAhusTookChargeDatePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastLabResultAdamts13DeliveryDatePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastLabResultAdamts13RequestDatePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastLabResultGeneticsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastLabResultUrineExamPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastLabResultsBcrAbl1MutationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastLabResultsBcrAbl1PcrQPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastLabResultsBcrAbl1TranscriptPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastLabResultsCALRPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastLabResultsJak2Exon12PatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastLabResultsJak2V617FPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastLabResultsMPLPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastLabResultsNGSPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastLabResultsSubclonalPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologicalAnamnesisNetGastricUlcersPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologicalAnamnesisNetGlucidicMetabolismAlterationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologicalAnamnesisNetHypercalcemiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologicalAnamnesisNetHypophysisAdenomaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologicalAnamnesisNetMultipleEndocrineNeoplasiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologicalAnamnesisNetNephrolithiasisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologicalAnamnesisNetNeuroendocrineTumorsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologicalAnamnesisNetOtherNeoplasiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsACAPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsAhusCytologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsAhusHystologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsBMAPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsBMBPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsBrafMutationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsBreastHistologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsBreastHistotypePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsBreastMetastasisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsBreastNodesDissectionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsBreastResectionMarginsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsBreastSentinelNodePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsHistologyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsKaryotypePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsMelanomaHistotypePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsMelanomaLymphNodesHistologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsMelanomaMetastasisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsMelanomaNodesDissectionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsMelanomaResectionMarginsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsMelanomaSentinelNodePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsMelanomaTumorHistologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsNetCytologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsNetHistologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsNetLymphNodesHistologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsNetLymphNodesPresencePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsNetMetastasisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPathologyResultsNonBrafMutationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPhysiologicalHistoryDeathDatePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPhysiologicalHistoryFitzpatrickScalePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPhysiologicalHistoryGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPhysiologicalHistoryMenopausePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPhysiologicalHistoryMenstrualCyclePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastPhysiologicalHistorySmokePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastRadiologyECGPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastRadiologyEchoPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastRadiologyEyeExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastRadiologySncLocalizationsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastRiskScoreGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsAbdominalPainPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsAstheniaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsBlurredEyesightPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsComaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsConfusionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsConvulsionsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsDispneaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsEcogPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsEdemasPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsEyeBleedingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsEyePainPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsFeverPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsHeartAttackPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsHematuriaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsHemophthousPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsHemoptysisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsHypertensionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsNauseaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsNetAchePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsNetDiarrheaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsNetFlushingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsNetJaundicePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsNetRetchingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsNetTachycardiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsNetWeightLossPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsOligoanuriaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsPancreatitisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsPulmonaryBleedingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsPulmonaryEdemaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsRetinalVesselsObstructionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsSplenomegalyPalpablePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsStenosisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsStrokePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsTachypneaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastSignsAndSymptomsVisualImpairmentPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyAhusMedicationsStartDatePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyApheresisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyBreastAdjuvantTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyBreastHormoneTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyBreastNeoadjuvantTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyBreastRadiotherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyCarTEligibilityPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyCarTInfusionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyCarTTreatmentArrivePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyCarTTreatmentOrderPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyNetAdjuvantTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyNetNeoadjuvantTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyNetRadioReceptorTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyNetSomatostatinAnalogueTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastTherapyTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastVaccinationsAntimeningococcalVaccinationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastVaccinationsGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetLastVitalsParametersGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetMFPatientLabResultsParametersHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetMastocytosisPatientLabResultsParametersHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetMastocytosisPatientMedicalDataArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetMedicalDataPatientLabResultsParametersHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetMelanomaPatientLabResultsParametersHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetNetPatientLabResultsParametersHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPVPatientLabResultsParametersHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetParticularServiceInformationArgs = {
  doctorSlug?: InputMaybe<Scalars['String']['input']>;
  serviceId: Scalars['String']['input'];
};


export type QuerygetPathologicalAnamnesisNetGastricUlcersPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologicalAnamnesisNetGlucidicMetabolismAlterationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologicalAnamnesisNetHypercalcemiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologicalAnamnesisNetHypophysisAdenomaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologicalAnamnesisNetMultipleEndocrineNeoplasiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologicalAnamnesisNetNephrolithiasisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologicalAnamnesisNetNeuroendocrineTumorsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologicalAnamnesisNetOtherNeoplasiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsACAPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsAhusCytologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsAhusHystologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsBMAPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsBMBPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsBrafMutationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsBreastHistologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsBreastHistotypePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsBreastMetastasisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsBreastNodesDissectionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsBreastResectionMarginsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsBreastSentinelNodePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsCKitMutationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsHistologyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsImmunophenotypingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsKaryotypePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsMelanomaHistotypePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsMelanomaLymphNodesHistologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsMelanomaMetastasisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsMelanomaNodesDissectionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsMelanomaResectionMarginsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsMelanomaSentinelNodePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsMelanomaTumorHistologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsNetCytologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsNetHistologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsNetLymphNodesHistologicalExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsNetLymphNodesPresencePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsNetMetastasisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPathologyResultsNonBrafMutationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPatientLabResultsParametersHistoryArgs = {
  labResultParameters: Array<SubSectionLabParameter>;
  patientId: Scalars['String']['input'];
};


export type QuerygetPatientsAvailableForGsdSsoByAccessTokenArgs = {
  accessToken: Scalars['String']['input'];
};


export type QuerygetPatientsAvailableForGsdSsoByAuthorizationCodeArgs = {
  authorizationCode: Scalars['String']['input'];
};


export type QuerygetPhysiologicalHistoryDeathDatePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPhysiologicalHistoryFitzpatrickScalePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPhysiologicalHistoryGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPhysiologicalHistoryMenopausePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPhysiologicalHistoryMenstrualCyclePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPhysiologicalHistorySmokePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetPlacePredictionsArgs = {
  input: Scalars['String']['input'];
  placeType?: InputMaybe<GooglePlaceType>;
};


export type QuerygetRadiologyAbdomenUltrasoundPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetRadiologyBoneDensitometryPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetRadiologyBoneImagingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetRadiologyECGPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetRadiologyEchoPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetRadiologyEyeExaminationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetRadiologySncLocalizationsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetReminderArgs = {
  reminderId: Scalars['String']['input'];
};


export type QuerygetRiskScoreGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsAbdominalPainPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsAstheniaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsBlurredEyesightPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsComaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsConfusionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsConvulsionsPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsDispneaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsEcogPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsEdemasPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsEyeBleedingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsEyePainPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsFeverPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsGastrointestinalDisordersPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsHeartAttackPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsHematuriaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsHemophthousPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsHemoptysisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsHypertensionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsNauseaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsNetAchePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsNetDiarrheaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsNetFlushingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsNetJaundicePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsNetRetchingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsNetTachycardiaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsNetWeightLossPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsOligoanuriaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsPancreatitisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsPulmonaryBleedingPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsPulmonaryEdemaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsRetinalVesselsObstructionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsSkinInvolvementPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsSplenomegalyPalpablePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsStenosisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsStrokePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsSyncopesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsTachypneaPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSignsAndSymptomsVisualImpairmentPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetSurveyAnswersPDFArgs = {
  surveyIssueRecordId: Scalars['String']['input'];
};


export type QuerygetTherapyAhusMedicationsStartDatePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyApheresisPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyBreastAdjuvantTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyBreastHormoneTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyBreastNeoadjuvantTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyBreastRadiotherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyCarTEligibilityPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyCarTInfusionPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyCarTTreatmentArrivePatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyCarTTreatmentOrderPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyHematologyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyNetAdjuvantTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyNetNeoadjuvantTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyNetRadioReceptorTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyNetSomatostatinAnalogueTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTherapyTherapyPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetTumoursGenericInformationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetUserByPatientInviteArgs = {
  patientInviteId: Scalars['String']['input'];
};


export type QuerygetUserDataByUserClaimTokenArgs = {
  userClaimToken: Scalars['String']['input'];
};


export type QuerygetVaccinationsAntimeningococcalVaccinationPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetVaccinationsGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QuerygetVitalsParametersGenericNotesPatientMedicalDataHistoryArgs = {
  patient: Scalars['String']['input'];
};


export type QueryglobalSearchArgs = {
  filterResultByTypes?: InputMaybe<Array<GlobalSearchHitType>>;
  search: Scalars['String']['input'];
};


export type QueryglobalSearchDetailsArgs = {
  filters: GlobalSearchDetailsFiltersArgs;
  order: GlobalSearchDetailsOrder;
  pagination?: InputMaybe<PaginationModel>;
};


export type QueryguestInviteArgs = {
  inviteId: Scalars['String']['input'];
};


export type Queryicd9DiagnosesArgs = {
  filterBy?: InputMaybe<ICD9DiagnosesFilterBy>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QueryinfoForwardedMessageArgs = {
  infoForwardedMessageId: Scalars['String']['input'];
};


export type QueryinfoThreadsArgs = {
  filterBy?: InputMaybe<InfoThreadsFilterByArgs>;
  orderBy?: InputMaybe<OrderByArgs>;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryinfoThreadsWithMessagesArgs = {
  infoThread?: InputMaybe<Scalars['String']['input']>;
};


export type QueryinstitutionUserProfileImageArgs = {
  institutionUserId: Scalars['String']['input'];
};


export type QueryintramedForwardedMessageArgs = {
  intramedForwardedMessageId: Scalars['String']['input'];
};


export type QueryintramedThreadAttachmentsArgs = {
  intramedThreadId: Scalars['String']['input'];
};


export type QueryintramedThreadDetailsArgs = {
  intramedThreadId: Scalars['String']['input'];
};


export type QueryintramedThreadListArgs = {
  filters: IntramedThreadListFiltersArgs;
  order: IntramedThreadListOrder;
};


export type QueryintramedVideoMeetingURLArgs = {
  threadId: Scalars['String']['input'];
};


export type QueryinviteArgs = {
  inviteId: Scalars['String']['input'];
};


export type QueryinvitesArgs = {
  filterBy?: InputMaybe<InviteListFilterByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QueryipssrMedicalIndexCalculationArgs = {
  ipssrMedicalIndexParametersModel: IpssrMedicalIndexParametersModel;
};


export type QueryisUserEmailUniqueArgs = {
  email: Scalars['String']['input'];
};


export type QueryisUserPhoneNumberUniqueArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type QuerymedicalDataArgs = {
  patientId: Scalars['String']['input'];
};


export type QuerymedicalReportTemplatesArgs = {
  pagination: PaginationModel;
};


export type Querymipss70MedicalIndexCalculationArgs = {
  mipss70MedicalIndexParametersModel: Mipss70MedicalIndexParametersModel;
};


export type QuerymngAdminUserListArgs = {
  filterBy?: InputMaybe<AdminUserListFilterByArgs>;
  orderBy?: InputMaybe<AdminUserListOrderByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerymngAffiliationArgs = {
  affiliationId: Scalars['String']['input'];
};


export type QuerymngAffiliationsArgs = {
  filterBy?: InputMaybe<MngAffiliationListFilterByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerymngAnnouncementArgs = {
  announcementId: Scalars['String']['input'];
};


export type QuerymngClinicArgs = {
  clinicId: Scalars['String']['input'];
};


export type QuerymngClinicCalendarsArgs = {
  clinicId: Scalars['String']['input'];
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
};


export type QuerymngClinicRequestEventsArgs = {
  clinicId: Scalars['String']['input'];
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
};


export type QuerymngClinicsArgs = {
  filterBy?: InputMaybe<MngClinicListFilterByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerymngGetAdminUserArgs = {
  adminUserId: Scalars['String']['input'];
};


export type QuerymngGetDoctorDetailsArgs = {
  userId: Scalars['String']['input'];
};


export type QuerymngGetFileUrlArgs = {
  fileId: Scalars['String']['input'];
};


export type QuerymngGetInstitutionArgs = {
  institutionId: Scalars['String']['input'];
};


export type QuerymngGetNonDoctorDetailsArgs = {
  userId: Scalars['String']['input'];
};


export type QuerymngGetPatientDetailsArgs = {
  userId: Scalars['String']['input'];
};


export type QuerymngGetRequestDetailsArgs = {
  requestId: Scalars['String']['input'];
};


export type QuerymngGetUserClaimArgs = {
  userClaimId: Scalars['String']['input'];
};


export type QuerymngGetUserDetailsArgs = {
  userId: Scalars['String']['input'];
};


export type QuerymngGsdHsrUserIntegrationArgs = {
  patientPortalUserId: Scalars['String']['input'];
};


export type QuerymngInstitutionListArgs = {
  filterBy?: InputMaybe<InstitutionListFilterByArgs>;
  orderBy?: InputMaybe<InstitutionListOrderByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerymngInviteArgs = {
  inviteId: Scalars['String']['input'];
};


export type QuerymngInvitesArgs = {
  filterBy?: InputMaybe<InviteListFilterByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerymngLookupGsdHsrGenericClinicArgs = {
  medicalCode: Scalars['String']['input'];
};


export type QuerymngLookupGsdHsrSsnClinicArgs = {
  bookingCode: Scalars['String']['input'];
  medicalCode: Scalars['String']['input'];
  operatingUnit: Scalars['String']['input'];
};


export type QuerymngMemorandumListArgs = {
  filterBy?: InputMaybe<MngMemorandumListFilters>;
  orderBy?: InputMaybe<MngMemorandumListOrderByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerymngPaginatedInstitutionsArgs = {
  filterBy?: InputMaybe<InstitutionListFilterByArgs>;
  orderBy?: InputMaybe<InstitutionListOrderByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerymngRequestListArgs = {
  filterBy?: InputMaybe<MngRequestListFilters>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerymngUserClaimListArgs = {
  filterBy?: InputMaybe<MngUserClaimListFilterByArgs>;
  orderBy?: InputMaybe<MngUserClaimListOrderByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerymngUserListArgs = {
  filterBy?: InputMaybe<UserListFilterByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerymngValidateGsdHsrBirthAddressArgs = {
  externalGooglePlaceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QuerymngValidateGsdHsrResidenceAddressArgs = {
  externalGooglePlaceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Querympn10MedicalIndexCalculationArgs = {
  mpn10MedicalIndexParametersModel: Mpn10MedicalIndexParametersModel;
};


export type QuerymysecPmMedicalIndexCalculationArgs = {
  mysecPmMedicalIndexParametersModel: MysecPmMedicalIndexParametersModel;
};


export type QuerynetworkInstitutionsArgs = {
  orderBy?: InputMaybe<OrderByArgs>;
  usernameOrInstitution?: InputMaybe<Scalars['String']['input']>;
};


export type QuerynetworkMembersArgs = {
  institutionId: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};


export type QuerynonCompetitiveSportCertificatePrefillableFormDataArgs = {
  patientId: Scalars['String']['input'];
  surveyAnswerId: Scalars['String']['input'];
};


export type QuerynotificationsArgs = {
  filterBy?: InputMaybe<FilterByArgs>;
  orderBy?: InputMaybe<GetNotificationOrderByArgs>;
};


export type QueryoneToOneChatArgs = {
  portalUserId: Scalars['String']['input'];
};


export type QueryoneToOneChatsArgs = {
  filterBy?: InputMaybe<ChatFilterByArgs>;
  orderBy?: InputMaybe<ChatOrderByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QueryoneToOneChatsV2Args = {
  filterBy?: InputMaybe<ChatFilterByArgs>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ChatOrderByArgs>;
  pointInTime?: InputMaybe<Scalars['String']['input']>;
  searchAfter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryorderByRequestArgs = {
  request: Scalars['String']['input'];
};


export type QuerypatientArgs = {
  patient: Scalars['String']['input'];
};


export type QuerypatientCalculationsArgs = {
  patientId: Scalars['String']['input'];
};


export type QuerypatientCareTeamArgs = {
  excludeOtherPortals?: Scalars['Boolean']['input'];
  patientId: Scalars['String']['input'];
};


export type QuerypatientClinicArgs = {
  clinicId: Scalars['String']['input'];
};


export type QuerypatientCommunicationsForUserArgs = {
  patient: Scalars['String']['input'];
};


export type QuerypatientFilesArgs = {
  filterBy?: InputMaybe<PatientFilesFilterByArgs>;
  pagination: PaginationModel;
};


export type QuerypatientInvoicesAndRefundsListArgs = {
  orderBy?: InputMaybe<PatientInvoicesListOrderByArgs>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerypatientJoinedAffiliationsArgs = {
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerypatientListArgs = {
  filterBy?: InputMaybe<PatientListFilterByArgs>;
  orderBy?: InputMaybe<PatientListOrderByArgs>;
  pagination?: InputMaybe<PaginationModel>;
  patientFullNameOrEmail?: InputMaybe<Scalars['String']['input']>;
};


export type QuerypatientMedicalDataSurveyAnswersArgs = {
  patientId: Scalars['String']['input'];
};


export type QuerypatientProfileImageArgs = {
  patientId: Scalars['String']['input'];
};


export type QuerypatientRegistrationPrivacyAcceptanceArgs = {
  birthAddressExternalGooglePlaceId: Scalars['String']['input'];
  birthDate: Scalars['DateTime']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  lastName: Scalars['String']['input'];
};


export type QuerypatientSurveysArgs = {
  includeHiddenInDashboard: Scalars['Boolean']['input'];
  pagination?: InputMaybe<PaginationModel>;
};


export type QuerypatientSurveysUnansweredCountArgs = {
  includeHiddenInDashboard: Scalars['Boolean']['input'];
};


export type QueryportalArgs = {
  portalId: Scalars['String']['input'];
};


export type QueryportalDocumentsArgs = {
  filterBy?: InputMaybe<PortalDocumentListFilterByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QueryportalSettingsArgs = {
  slug: Scalars['String']['input'];
};


export type QueryportalUserProfileImageArgs = {
  portalUserId: Scalars['String']['input'];
};


export type QueryprivaciesArgs = {
  forProfileType: ProfileType;
  portalId: Scalars['String']['input'];
};


export type QueryptnmMedicalIndexCalculationArgs = {
  ptnmMedicalIndexParametersModel: PtnmMedicalIndexParametersModel;
};


export type QueryreminderListArgs = {
  filterBy?: InputMaybe<ReminderListFilterByArgs>;
  orderBy?: InputMaybe<ReminderListOrderByArgs>;
  pagination?: InputMaybe<PaginationModel>;
};


export type QueryremindersByRequestArgs = {
  requestId: Scalars['String']['input'];
};


export type QueryrequestsArgs = {
  filterBy?: InputMaybe<RequestFilterByArgs>;
  orderBy?: InputMaybe<RequestOrderByArgs>;
  pagination: PaginationModel;
};


export type QuerysearchAssociatedPatientsForDoctorOrNonDoctorArgs = {
  fullNameOrEmail: Scalars['String']['input'];
};


export type QuerysearchClinicsArgs = {
  pagination?: InputMaybe<PaginationModel>;
  searchTerm: Scalars['String']['input'];
};


export type QuerysearchDoctorsArgs = {
  pagination?: InputMaybe<PaginationModel>;
  searchTerm: Scalars['String']['input'];
};


export type QuerysearchIntramedThreadRecipientsArgs = {
  doctorNonDoctorPortalUserId?: InputMaybe<Scalars['String']['input']>;
  equipeId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerysearchOperatorsArgs = {
  fullNameOrEmail: Scalars['String']['input'];
};


export type QuerysearchPatientsArgs = {
  filterBy?: InputMaybe<SearchPatientsFilterByArgs>;
  pagination?: InputMaybe<PaginationModel>;
  sortBy?: InputMaybe<SearchPatientsSortByArgs>;
};


export type QuerysearchPotentialEquipeMembersArgs = {
  search: Scalars['String']['input'];
};


export type QuerysearchSpecializationsArgs = {
  pagination?: InputMaybe<PaginationModel>;
  searchTerm: Scalars['String']['input'];
};


export type QuerysearchSpheresOfInterestArgs = {
  pagination?: InputMaybe<PaginationModel>;
  searchTerm: Scalars['String']['input'];
};


export type QuerysearchUserArgs = {
  fullName: Scalars['String']['input'];
  institutionId?: InputMaybe<Scalars['String']['input']>;
  portalId?: InputMaybe<Scalars['String']['input']>;
  profileTypeList?: InputMaybe<Array<ProfileType>>;
};


export type QuerysearchUserToInviteArgs = {
  fullNameOrEmail: Scalars['String']['input'];
};


export type QueryserviceArgs = {
  service: Scalars['String']['input'];
};


export type QueryserviceForRequestCreationArgs = {
  serviceId: Scalars['String']['input'];
};


export type QueryservicesByContextArgs = {
  bookingTypeFilter?: InputMaybe<BookingTypeFilterArgs>;
  doctorSlug: Scalars['String']['input'];
};


export type QuerysokalMedicalIndexCalculationArgs = {
  sokalMedicalIndexParametersModel: SokalMedicalIndexParametersModel;
};


export type QuerysurveyArgs = {
  forRecipientInstitutionUserId?: InputMaybe<Scalars['String']['input']>;
  surveyId: Scalars['String']['input'];
};


export type QuerysurveyAnswerHistoryArgs = {
  recipientPortalUserId: Scalars['String']['input'];
  surveyCode?: InputMaybe<Scalars['String']['input']>;
  surveyId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerysurveyDefaultQuestionValuesArgs = {
  forRecipientInstitutionUserId: Scalars['String']['input'];
  surveyId: Scalars['String']['input'];
};


export type QuerysurveyDoctorHeaderDataArgs = {
  surveyIssueRecordId: Scalars['String']['input'];
};


export type QuerysurveyIssuesArgs = {
  recipientPortalUserId: Scalars['String']['input'];
  surveyId: Scalars['String']['input'];
};


export type QuerysurveyVisibilityOnDoctorDashboardArgs = {
  patientPortalUserId: Scalars['String']['input'];
  surveyId: Scalars['String']['input'];
};


export type QuerysurveyVisibilityOnPatientDashboardArgs = {
  surveyId: Scalars['String']['input'];
};


export type QuerytnmAsco2021AppendixMedicalIndexCalculationArgs = {
  tnmAsco2021AppendixMedicalIndexParametersModel: TnmAsco2021AppendixMedicalIndexParametersModel;
};


export type QuerytnmAsco2021ColonAndRectumMedicalIndexCalculationArgs = {
  tnmAsco2021ColonAndRectumMedicalIndexParametersModel: TnmAsco2021ColonAndRectumMedicalIndexParametersModel;
};


export type QuerytnmAsco2021DuodenumAndVaterAmpullaMedicalIndexCalculationArgs = {
  tnmAsco2021DuodenumAndVaterAmpullaMedicalIndexParametersModel: TnmAsco2021DuodenumAndVaterAmpullaMedicalIndexParametersModel;
};


export type QuerytnmAsco2021JejunumAndIleumMedicalIndexCalculationArgs = {
  tnmAsco2021JejunumAndIleumMedicalIndexParametersModel: TnmAsco2021JejunumAndIleumMedicalIndexParametersModel;
};


export type QuerytnmAsco2021PancreasMedicalIndexCalculationArgs = {
  tnmAsco2021PancreasMedicalIndexParametersModel: TnmAsco2021PancreasMedicalIndexParametersModel;
};


export type QuerytnmAsco2021StomachMedicalIndexCalculationArgs = {
  tnmAsco2021StomachMedicalIndexParametersModel: TnmAsco2021StomachMedicalIndexParametersModel;
};


export type QuerytnmMedicalIndexCalculationArgs = {
  tnmMedicalIndexParametersModel: TnmMedicalIndexParametersModel;
};


export type QueryuserByPrivacyTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryvalidateFiscalCodeArgs = {
  context?: InputMaybe<ValidateFiscalCodeContextArgs>;
  fiscalCode: Scalars['String']['input'];
};


export type QueryvalidateGsdHsrBirthAddressArgs = {
  externalGooglePlaceId: Scalars['String']['input'];
};


export type QueryvalidateGsdHsrResidenceAddressArgs = {
  externalGooglePlaceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QueryvideoVisitURLArgs = {
  request: Scalars['String']['input'];
};

export type QuestionDependencyModel = {
  __typename?: 'QuestionDependencyModel';
  questionCode: Scalars['String']['output'];
  rule: QuestionDependencyRuleModel;
};

export type QuestionDependencyRuleModel = {
  __typename?: 'QuestionDependencyRuleModel';
  isNegation: Scalars['Boolean']['output'];
  operator: SurveyDependecyRuleOperator;
  value?: Maybe<Array<Scalars['String']['output']>>;
};

export type QuestionnaireData = {
  __typename?: 'QuestionnaireData';
  additionalData?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy: Scalars['String']['output'];
  updaterName: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export type QuestionnaireDataArgs = {
  additionalData?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type RadiologyAbdomenUltrasoundData = {
  __typename?: 'RadiologyAbdomenUltrasoundData';
  notes?: Maybe<Scalars['String']['output']>;
};

export type RadiologyAbdomenUltrasoundDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type RadiologyAbdomenUltrasoundHistory = {
  __typename?: 'RadiologyAbdomenUltrasoundHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: RadiologyAbdomenUltrasoundData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type RadiologyBoneDensitometryData = {
  __typename?: 'RadiologyBoneDensitometryData';
  isNormal: Scalars['Boolean']['output'];
  leftHipTScore?: Maybe<Scalars['Float']['output']>;
  lumbarSpineTScore?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  radialTScore?: Maybe<Scalars['Float']['output']>;
  trabecularBoneScore?: Maybe<Scalars['Float']['output']>;
};

export type RadiologyBoneDensitometryDataArgs = {
  isNormal: Scalars['Boolean']['input'];
  leftHipTScore?: InputMaybe<Scalars['Float']['input']>;
  lumbarSpineTScore?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  radialTScore?: InputMaybe<Scalars['Float']['input']>;
  trabecularBoneScore?: InputMaybe<Scalars['Float']['input']>;
};

export type RadiologyBoneDensitometryHistory = {
  __typename?: 'RadiologyBoneDensitometryHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: RadiologyBoneDensitometryData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type RadiologyBoneImagingData = {
  __typename?: 'RadiologyBoneImagingData';
  hasFractures: Scalars['Boolean']['output'];
  hasVertebralFailure: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type RadiologyBoneImagingDataArgs = {
  hasFractures: Scalars['Boolean']['input'];
  hasVertebralFailure: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type RadiologyBoneImagingHistory = {
  __typename?: 'RadiologyBoneImagingHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: RadiologyBoneImagingData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type RadiologyData = {
  __typename?: 'RadiologyData';
  abdomenUltrasound?: Maybe<RadiologyAbdomenUltrasoundData>;
  boneDensitometry?: Maybe<RadiologyBoneDensitometryData>;
  boneImaging?: Maybe<RadiologyBoneImagingData>;
};

export type RadiologyECGData = {
  __typename?: 'RadiologyECGData';
  executionDate: Scalars['DateTime']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  value: ECGResult;
};

export type RadiologyECGDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<Scalars['String']['input']>;
  value: ECGResult;
};

export type RadiologyECGHistory = {
  __typename?: 'RadiologyECGHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: RadiologyECGData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type RadiologyEchoData = {
  __typename?: 'RadiologyEchoData';
  executionDate: Scalars['DateTime']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Int']['output'];
};

export type RadiologyEchoDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Int']['input'];
};

export type RadiologyEchoHistory = {
  __typename?: 'RadiologyEchoHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: RadiologyEchoData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type RadiologyEyeExaminationData = {
  __typename?: 'RadiologyEyeExaminationData';
  isDone: Scalars['Boolean']['output'];
};

export type RadiologyEyeExaminationDataArgs = {
  isDone: Scalars['Boolean']['input'];
};

export type RadiologyEyeExaminationHistory = {
  __typename?: 'RadiologyEyeExaminationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: RadiologyEyeExaminationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type RadiologySncLocalizationsData = {
  __typename?: 'RadiologySncLocalizationsData';
  hasClinicalEvidence?: Maybe<Scalars['Boolean']['output']>;
  hasCytologicalEvidence?: Maybe<Scalars['Boolean']['output']>;
  hasRadiologicalEvidence?: Maybe<Scalars['Boolean']['output']>;
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type RadiologySncLocalizationsDataArgs = {
  hasClinicalEvidence?: InputMaybe<Scalars['Boolean']['input']>;
  hasCytologicalEvidence?: InputMaybe<Scalars['Boolean']['input']>;
  hasRadiologicalEvidence?: InputMaybe<Scalars['Boolean']['input']>;
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type RadiologySncLocalizationsHistory = {
  __typename?: 'RadiologySncLocalizationsHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: RadiologySncLocalizationsData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export enum RecipientType {
  EQUIPE = 'EQUIPE',
  INSTITUTION_USER = 'INSTITUTION_USER'
}

export type Refund = {
  __typename?: 'Refund';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  invoice?: Maybe<Invoice>;
  method: Scalars['String']['output'];
  order: Order;
  payment: Payment;
  reason: Scalars['String']['output'];
  refundedTotal: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum RefundStatus {
  COMPLETED = 'COMPLETED'
}

export type RegistrationIntention = {
  __typename?: 'RegistrationIntention';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  institution: Institution;
  profileType: ProfileType;
  user: User;
};

export type ReminderListFilterByArgs = {
  forPatientPortalUserId?: InputMaybe<Scalars['String']['input']>;
  fromDoctorPortalUserId?: InputMaybe<Scalars['String']['input']>;
};

export type ReminderListOrderByArgs = {
  field: ReminderListOrderByField;
  type: OrderByType;
};

export enum ReminderListOrderByField {
  CREATED_AT = 'CREATED_AT',
  CREATOR = 'CREATOR',
  DATE_START = 'DATE_START',
  RECIPIENT = 'RECIPIENT'
}

export type ReminderModel = {
  __typename?: 'ReminderModel';
  createdAt: Scalars['DateTime']['output'];
  creatorInstitutionUser: InstitutionUserModel;
  dateEnd?: Maybe<Scalars['DateTime']['output']>;
  dateStart: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  emailNotificationTime: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDeletable: Scalars['Boolean']['output'];
  isModifiable: Scalars['Boolean']['output'];
  isSelf: Scalars['Boolean']['output'];
  isVisible: Scalars['Boolean']['output'];
  recipientInstitutionUser: InstitutionUserModel;
  recurrenceType: ReminderRecurrenceType;
  title: Scalars['String']['output'];
};

export type ReminderRecipientArgs = {
  recipientId: Scalars['String']['input'];
  recipientType: RecipientType;
};

export enum ReminderRecurrenceType {
  EVERY_DAY = 'EVERY_DAY',
  EVERY_MONTH = 'EVERY_MONTH',
  EVERY_TWO_WEEK = 'EVERY_TWO_WEEK',
  EVERY_WEEK = 'EVERY_WEEK',
  EVERY_YEAR = 'EVERY_YEAR',
  NEVER = 'NEVER'
}

export type Request = {
  __typename?: 'Request';
  activatedAfter?: Maybe<Scalars['Float']['output']>;
  appliedTaxes: Array<Tax>;
  assignmentStatus: RequestAssignmentStatus;
  attachments: Array<FileModel>;
  bookingProviderExtension: AbstractBookingProviderExtensionModel;
  cachedService: RequestCachedService;
  cancelledByPortalUser?: Maybe<PortalUserModel>;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dueInfo: RequestDueInfoModel;
  id: Scalars['String']['output'];
  isContinuation: Scalars['Boolean']['output'];
  isRefunded: Scalars['Boolean']['output'];
  isStatusFinal: Scalars['Boolean']['output'];
  patientInstitutionUser: InstitutionUser;
  portalExtension: RequestPortalExtensionModel;
  /** the contents are filtered/sanitized */
  question?: Maybe<Scalars['String']['output']>;
  recipients: Array<RequestRecipient>;
  requestEventBookingRequest?: Maybe<RequestEventBookingRequest>;
  requestEvents: Array<RequestEvent>;
  /** The RequestProposal the Request originated from */
  requestProposal?: Maybe<RequestProposalModel>;
  /** @deprecated Will be removed soon, use Request.dueInfo */
  shouldBeAnsweredBy?: Maybe<Scalars['DateTime']['output']>;
  status: RequestStatus;
  /** the contents are filtered/sanitized */
  subject?: Maybe<Scalars['String']['output']>;
  type: ServiceType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum RequestAnswerStatus {
  COMING = 'COMING',
  PRIORITY = 'PRIORITY',
  USUAL = 'USUAL'
}

export enum RequestAssignmentStatus {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED'
}

export type RequestCachedService = {
  __typename?: 'RequestCachedService';
  appliedTaxes: Array<Tax>;
  baseServiceType: ServiceType;
  createdAt: Scalars['DateTime']['output'];
  fullBody: ServiceCacheModel;
  id: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  selectedServiceAddons: Array<ServiceAddonCacheModel>;
  serviceId: Scalars['String']['output'];
  source: ServiceSourceType;
  taxPrice: Scalars['Float']['output'];
};

export type RequestDueInfoModel = {
  __typename?: 'RequestDueInfoModel';
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  dueStatus?: Maybe<RequestDueStatus>;
};

export enum RequestDueStatus {
  /** AKA "expiring" */
  CLOSE_DUE = 'CLOSE_DUE',
  /** AKA "expired" */
  PAST_DUE = 'PAST_DUE'
}

export type RequestEvent = {
  __typename?: 'RequestEvent';
  createdAt: Scalars['DateTime']['output'];
  durationMinutes: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  scheduledAt: Scalars['DateTime']['output'];
};

export type RequestEventBookingRequest = {
  __typename?: 'RequestEventBookingRequest';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  primaryDate: Scalars['DateTime']['output'];
  secondaryDate: Scalars['DateTime']['output'];
};

export type RequestEventModel = {
  __typename?: 'RequestEventModel';
  durationMinutes: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  /** True when the RequestEvent overlaps with another RequestEvent assigned to the same doctor. */
  overlapsDoctorEvents: Scalars['Boolean']['output'];
  patientUser: UserModel;
  scheduledAt: Scalars['DateTime']['output'];
};

export type RequestFilesFilterArgs = {
  isWithoutInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  isWithoutRefund?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequestFilterByArgs = {
  patientPortalUserId?: InputMaybe<Scalars['String']['input']>;
  statuses?: Array<RequestStatus>;
};

export type RequestHistoryEventModel = {
  __typename?: 'RequestHistoryEventModel';
  occurredAt: Scalars['DateTime']['output'];
  request: Request;
  statusAfter: RequestStatus;
  triggeredByPortalUser: PortalUserModel;
  type: RequestHistoryEventType;
};

export enum RequestHistoryEventType {
  APPOINTMENT_ACCEPTED = 'APPOINTMENT_ACCEPTED',
  APPOINTMENT_PROPOSED = 'APPOINTMENT_PROPOSED',
  /** represents the deferred/late assignment of a Request *only* (as opposed to the Request being created-and-assigned) */
  ASSIGNED = 'ASSIGNED',
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  CREATED = 'CREATED',
  PAID = 'PAID'
}

export type RequestInvoiceFilesModel = {
  __typename?: 'RequestInvoiceFilesModel';
  invoice?: Maybe<MngFileModel>;
  receipt?: Maybe<MngFileModel>;
  refund?: Maybe<MngFileModel>;
  refundReceipt?: Maybe<MngFileModel>;
};

export enum RequestListOrderByField {
  CREATED_AT = 'CREATED_AT',
  DOCTOR_FIRST_NAME = 'DOCTOR_FIRST_NAME',
  DOCTOR_LAST_NAME = 'DOCTOR_LAST_NAME',
  PATIENT_FIRST_NAME = 'PATIENT_FIRST_NAME',
  PATIENT_LAST_NAME = 'PATIENT_LAST_NAME'
}

export type RequestOrderByArgs = {
  field: RequestListOrderByField;
  type: OrderByType;
};

export type RequestPatientCommunication = {
  __typename?: 'RequestPatientCommunication';
  createdAt: Scalars['DateTime']['output'];
  request: Request;
  subject?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type RequestPortalExtensionFilterArgs = {
  gsdHsrClinicSubType?: InputMaybe<Array<GsdHsrClinicSubType>>;
};

export type RequestPortalExtensionGsdHsrSsnFulfillmentModel = {
  __typename?: 'RequestPortalExtensionGsdHsrSsnFulfillmentModel';
  couldNotFulfillReason?: Maybe<GsdHsrSsnCouldNotFulfillReason>;
  feedbackStatus: GsdHsrSsnFeedbackStatus;
};

export type RequestPortalExtensionModel = {
  __typename?: 'RequestPortalExtensionModel';
  gsdHsrClinicSubType?: Maybe<GsdHsrClinicSubType>;
  gsdHsrSsnFulfillment?: Maybe<RequestPortalExtensionGsdHsrSsnFulfillmentModel>;
};

export type RequestPriceResponse = {
  __typename?: 'RequestPriceResponse';
  appliedTaxes: Array<Tax>;
  totalPrice: Scalars['Float']['output'];
};

export type RequestProposalModel = {
  __typename?: 'RequestProposalModel';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  forcedVideoVisitDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  selectedServiceAddons: Array<ServiceAddonModel>;
  service: ServiceModel;
};

export type RequestRecipient = {
  __typename?: 'RequestRecipient';
  /** @deprecated This field has no meaning and should never be read or used as an argument for further operations */
  id: Scalars['String']['output'];
  institutionUser?: Maybe<InstitutionUser>;
};

export enum RequestStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PENDING_DOCTOR_APPROVAL = 'PENDING_DOCTOR_APPROVAL',
  PENDING_PATIENT_APPROVAL = 'PENDING_PATIENT_APPROVAL',
  PENDING_PAYMENT = 'PENDING_PAYMENT'
}

export enum RequestStatusAction {
  ACCEPT = 'ACCEPT',
  ACCEPT_FREE = 'ACCEPT_FREE',
  CANCEL = 'CANCEL',
  CANCEL_PAYMENT = 'CANCEL_PAYMENT',
  CLOSE = 'CLOSE',
  DECLINE = 'DECLINE',
  FAIL_PAYMENT = 'FAIL_PAYMENT',
  PAY = 'PAY'
}

export enum RequestStatusCompound {
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  REFUNDED = 'REFUNDED'
}

export type ResidenceAddressModel = {
  __typename?: 'ResidenceAddressModel';
  city: Scalars['String']['output'];
  fullAddress: Scalars['String']['output'];
  googlePlaceId: Scalars['String']['output'];
  province?: Maybe<Scalars['String']['output']>;
};

export type RevisionDoctorConfirmationModel = {
  __typename?: 'RevisionDoctorConfirmationModel';
  confirmedAt: Scalars['DateTime']['output'];
  confirmedBy: PortalUserModel;
  isPresent: Scalars['Boolean']['output'];
};

export type RiskScoreGenericNotesData = {
  __typename?: 'RiskScoreGenericNotesData';
  notes: Scalars['String']['output'];
};

export type RiskScoreGenericNotesDataArgs = {
  notes: Scalars['String']['input'];
};

export type RiskScoreGenericNotesHistory = {
  __typename?: 'RiskScoreGenericNotesHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: RiskScoreGenericNotesData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SaveBirthResidenceAddressForGsdSsoModel = {
  __typename?: 'SaveBirthResidenceAddressForGsdSsoModel';
  birthAddressIsValid?: Maybe<Scalars['Boolean']['output']>;
  residenceAddressIsValid?: Maybe<Scalars['Boolean']['output']>;
};

export type SearchCriteriaArgs = {
  pathology?: InputMaybe<Pathology>;
  specialization?: InputMaybe<Specialization>;
  symptoms?: Array<Symptom>;
};

export type SearchCriteriaModel = {
  __typename?: 'SearchCriteriaModel';
  pathology?: Maybe<Pathology>;
  specialization?: Maybe<Specialization>;
  symptoms: Array<Symptom>;
};

export type SearchOneToOneChatModelV2Response = {
  __typename?: 'SearchOneToOneChatModelV2Response';
  items: Array<OneToOneChatModel>;
  pointInTime?: Maybe<Scalars['String']['output']>;
  searchAfter?: Maybe<Scalars['String']['output']>;
};

export type SearchPatientsFilterByArgs = {
  /** Can be used to narrow Patients to those that can *immediately* be interacted with in the current Portal (or vice versa, to those that would first require the creation of a new PatientPortalUser). */
  hasPatientPortalUserInInvokersPortal?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to only include patients that are/are not cared for by the invoking doctor/non-doctor,  or in other words for which the invoker is/is not member of the patients' care team */
  isCaredForByInvoker?: InputMaybe<Scalars['Boolean']['input']>;
  isFullyRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  /** User-provided keyword/term, will be internally tested against multiple Patient fields (first/last name, email) */
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type SearchPatientsSortByArgs = {
  field: PatientListOrderByField;
  type?: InputMaybe<OrderByType>;
};

export enum Section {
  ALLERGIES = 'ALLERGIES',
  ANAMNESIS = 'ANAMNESIS',
  CURRENT_CLINICAL_EVALUATION = 'CURRENT_CLINICAL_EVALUATION',
  DIAGNOSES = 'DIAGNOSES',
  FAMILY_ANAMNESIS = 'FAMILY_ANAMNESIS',
  GENERIC = 'GENERIC',
  HISTOLOGICAL_EXAMINATION = 'HISTOLOGICAL_EXAMINATION',
  LAB_RESULT = 'LAB_RESULT',
  PATHOLOGY_RESULTS = 'PATHOLOGY_RESULTS',
  PHYSIOLOGICAL_HISTORY = 'PHYSIOLOGICAL_HISTORY',
  RADIOLOGY = 'RADIOLOGY',
  RISK_SCORE = 'RISK_SCORE',
  SIGNS_AND_SYMPTOMS = 'SIGNS_AND_SYMPTOMS',
  THERAPY = 'THERAPY',
  TUMOURS = 'TUMOURS',
  VACCINATIONS = 'VACCINATIONS',
  VITALS_PARAMETERS = 'VITALS_PARAMETERS'
}

export type SelectOptionModel = {
  __typename?: 'SelectOptionModel';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SendDraftDirectMessageModel = {
  __typename?: 'SendDraftDirectMessageModel';
  draftDirectMessage: DraftDirectMessageModel;
  patientInviteId?: Maybe<Scalars['String']['output']>;
};

export type ServiceAddonCacheModel = {
  __typename?: 'ServiceAddonCacheModel';
  price: Scalars['Float']['output'];
  providedWithinHours?: Maybe<Scalars['Float']['output']>;
  type: ServiceAddonType;
};

export type ServiceAddonModel = {
  __typename?: 'ServiceAddonModel';
  id: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  providedWithinDays?: Maybe<Scalars['Int']['output']>;
  type: ServiceAddonType;
};

export enum ServiceAddonType {
  PRIORITY = 'PRIORITY',
  RADIOLOGICAL_IMAGE = 'RADIOLOGICAL_IMAGE'
}

export type ServiceBaseCacheModel = {
  __typename?: 'ServiceBaseCacheModel';
  /** @deprecated Will be removed in favor of ServiceCacheModel.type */
  type: ServiceType;
};

export enum ServiceBookingType {
  AFFILIATION = 'AFFILIATION',
  CLINIC = 'CLINIC',
  DOCTOR = 'DOCTOR'
}

export type ServiceCacheModel = {
  __typename?: 'ServiceCacheModel';
  /** @deprecated Will be removed in favor of ServiceCacheModel.serviceType */
  baseService: ServiceBaseCacheModel;
  description: Scalars['String']['output'];
  durationMinutes?: Maybe<Scalars['Float']['output']>;
  giftType?: Maybe<ServiceGiftType>;
  isGiftService?: Maybe<Scalars['Boolean']['output']>;
  price: Scalars['Float']['output'];
  providedWithinHours?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  type: ServiceType;
};

export type ServiceDateModel = {
  __typename?: 'ServiceDateModel';
  date: Scalars['DateTime']['output'];
};

export enum ServiceGiftType {
  GIFT_FREE_REQUEST = 'GIFT_FREE_REQUEST',
  GIFT_VOICE_CALL = 'GIFT_VOICE_CALL',
  NOT_GIFT = 'NOT_GIFT'
}

export type ServiceInformationModel = {
  __typename?: 'ServiceInformationModel';
  service: ContextAwareServiceModel;
  serviceProvider: ServiceProviderUnionModel;
};

export type ServiceModel = {
  __typename?: 'ServiceModel';
  addons: Array<ServiceAddonModel>;
  allowsOnDemandSchedule?: Maybe<Scalars['Boolean']['output']>;
  baseServiceType: ServiceType;
  bookingProviderExtension: AbstractBookingProviderExtensionModel;
  description: Scalars['String']['output'];
  doctorCanCreateRequestProposal: Scalars['Boolean']['output'];
  durationMinutes?: Maybe<Scalars['Float']['output']>;
  giftType: ServiceGiftType;
  id: Scalars['String']['output'];
  integrationServiceType?: Maybe<IntegrationServiceType>;
  isEnabled: Scalars['Boolean']['output'];
  isGiftService?: Maybe<Scalars['Boolean']['output']>;
  patientBookingRequiresRequestProposal: Scalars['Boolean']['output'];
  portalExtension: ServicePortalExtensionModel;
  price: Scalars['Float']['output'];
  providedWithinDays?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

/** Holds portal/integration-specific extensions to the base/shared schema */
export type ServicePortalExtensionModel = {
  __typename?: 'ServicePortalExtensionModel';
  /** AKA "mnemonico prestazione" */
  onHealthServiceId?: Maybe<Scalars['String']['output']>;
};

export enum ServiceProviderType {
  DOCTOR = 'DOCTOR'
}

export type ServiceProviderUnionModel = ClinicModel | DoctorServiceProviderModel;

export enum ServiceSourceType {
  HSR_SAP = 'HSR_SAP',
  INTERNAL = 'INTERNAL'
}

export enum ServiceType {
  CONSULTATION = 'CONSULTATION',
  VIDEO_VISIT = 'VIDEO_VISIT'
}

export type ServiceUpdateAvailabilityModel = {
  __typename?: 'ServiceUpdateAvailabilityModel';
  id: Scalars['String']['output'];
  isEnabled: Scalars['Boolean']['output'];
};

export type ServicesByContextModel = {
  __typename?: 'ServicesByContextModel';
  acceptsInfoRequest: Scalars['Boolean']['output'];
  extendedServices: Array<ContextAwareServiceModel>;
  serviceProvider: AbstractServiceProviderModel;
};

export enum SignInType {
  MOBILE = 'MOBILE',
  WEB = 'WEB'
}

export type SignInWithGsdSsoModel = {
  __typename?: 'SignInWithGsdSsoModel';
  cognitoAuthModel?: Maybe<CognitoAuthModel>;
  maskedEmail?: Maybe<Scalars['String']['output']>;
  status: SignInWithGsdSsoStatus;
};

export enum SignInWithGsdSsoStatus {
  EMAIL_IS_REQUIRED = 'EMAIL_IS_REQUIRED',
  PENDING_EMAIL_VERIFICATION = 'PENDING_EMAIL_VERIFICATION',
  SUCCESS = 'SUCCESS'
}

export type SignsAndSymptoms = {
  __typename?: 'SignsAndSymptoms';
  gastrointestinalDisorders?: Maybe<SignsAndSymptomsGastrointestinalDisordersData>;
  skinInvolvement?: Maybe<SignsAndSymptomsSkinInvolvementData>;
  syncopes?: Maybe<SignsAndSymptomsSyncopesData>;
};

export type SignsAndSymptomsAbdominalPainData = {
  __typename?: 'SignsAndSymptomsAbdominalPainData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsAbdominalPainDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsAbdominalPainHistory = {
  __typename?: 'SignsAndSymptomsAbdominalPainHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsAbdominalPainData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsAstheniaData = {
  __typename?: 'SignsAndSymptomsAstheniaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsAstheniaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsAstheniaHistory = {
  __typename?: 'SignsAndSymptomsAstheniaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsAstheniaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsBlurredEyesightData = {
  __typename?: 'SignsAndSymptomsBlurredEyesightData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsBlurredEyesightDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsBlurredEyesightHistory = {
  __typename?: 'SignsAndSymptomsBlurredEyesightHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsBlurredEyesightData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsComaData = {
  __typename?: 'SignsAndSymptomsComaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsComaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsComaHistory = {
  __typename?: 'SignsAndSymptomsComaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsComaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsConfusionData = {
  __typename?: 'SignsAndSymptomsConfusionData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsConfusionDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsConfusionHistory = {
  __typename?: 'SignsAndSymptomsConfusionHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsConfusionData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsConvulsionsData = {
  __typename?: 'SignsAndSymptomsConvulsionsData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsConvulsionsDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsConvulsionsHistory = {
  __typename?: 'SignsAndSymptomsConvulsionsHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsConvulsionsData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsDispneaData = {
  __typename?: 'SignsAndSymptomsDispneaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsDispneaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsDispneaHistory = {
  __typename?: 'SignsAndSymptomsDispneaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsDispneaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsEcogData = {
  __typename?: 'SignsAndSymptomsEcogData';
  executionDate: Scalars['DateTime']['output'];
  value: Scalars['Int']['output'];
};

export type SignsAndSymptomsEcogDataArgs = {
  executionDate: Scalars['DateTime']['input'];
  value: Scalars['Int']['input'];
};

export type SignsAndSymptomsEcogHistory = {
  __typename?: 'SignsAndSymptomsEcogHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsEcogData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsEdemasData = {
  __typename?: 'SignsAndSymptomsEdemasData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsEdemasDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsEdemasHistory = {
  __typename?: 'SignsAndSymptomsEdemasHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsEdemasData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsEyeBleedingData = {
  __typename?: 'SignsAndSymptomsEyeBleedingData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsEyeBleedingDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsEyeBleedingHistory = {
  __typename?: 'SignsAndSymptomsEyeBleedingHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsEyeBleedingData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsEyePainData = {
  __typename?: 'SignsAndSymptomsEyePainData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsEyePainDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsEyePainHistory = {
  __typename?: 'SignsAndSymptomsEyePainHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsEyePainData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsFeverData = {
  __typename?: 'SignsAndSymptomsFeverData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsFeverDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsFeverHistory = {
  __typename?: 'SignsAndSymptomsFeverHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsFeverData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsGastrointestinalDisordersData = {
  __typename?: 'SignsAndSymptomsGastrointestinalDisordersData';
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type SignsAndSymptomsGastrointestinalDisordersDataArgs = {
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type SignsAndSymptomsGastrointestinalDisordersHistory = {
  __typename?: 'SignsAndSymptomsGastrointestinalDisordersHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsGastrointestinalDisordersData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsGenericNotesData = {
  __typename?: 'SignsAndSymptomsGenericNotesData';
  notes: Scalars['String']['output'];
};

export type SignsAndSymptomsGenericNotesDataArgs = {
  notes: Scalars['String']['input'];
};

export type SignsAndSymptomsGenericNotesHistory = {
  __typename?: 'SignsAndSymptomsGenericNotesHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsGenericNotesData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsHeartAttackData = {
  __typename?: 'SignsAndSymptomsHeartAttackData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsHeartAttackDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsHeartAttackHistory = {
  __typename?: 'SignsAndSymptomsHeartAttackHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsHeartAttackData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsHematuriaData = {
  __typename?: 'SignsAndSymptomsHematuriaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsHematuriaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsHematuriaHistory = {
  __typename?: 'SignsAndSymptomsHematuriaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsHematuriaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsHemophthousData = {
  __typename?: 'SignsAndSymptomsHemophthousData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsHemophthousDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsHemophthousHistory = {
  __typename?: 'SignsAndSymptomsHemophthousHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsHemophthousData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsHemoptysisData = {
  __typename?: 'SignsAndSymptomsHemoptysisData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsHemoptysisDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsHemoptysisHistory = {
  __typename?: 'SignsAndSymptomsHemoptysisHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsHemoptysisData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsHypertensionData = {
  __typename?: 'SignsAndSymptomsHypertensionData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsHypertensionDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsHypertensionHistory = {
  __typename?: 'SignsAndSymptomsHypertensionHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsHypertensionData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsNauseaData = {
  __typename?: 'SignsAndSymptomsNauseaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsNauseaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsNauseaHistory = {
  __typename?: 'SignsAndSymptomsNauseaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsNauseaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsNetAcheData = {
  __typename?: 'SignsAndSymptomsNetAcheData';
  notes?: Maybe<Scalars['String']['output']>;
  value: YesNo;
};

export type SignsAndSymptomsNetAcheDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: YesNo;
};

export type SignsAndSymptomsNetAcheHistory = {
  __typename?: 'SignsAndSymptomsNetAcheHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsNetAcheData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsNetDiarrheaData = {
  __typename?: 'SignsAndSymptomsNetDiarrheaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: YesNo;
};

export type SignsAndSymptomsNetDiarrheaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: YesNo;
};

export type SignsAndSymptomsNetDiarrheaHistory = {
  __typename?: 'SignsAndSymptomsNetDiarrheaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsNetDiarrheaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsNetFlushingData = {
  __typename?: 'SignsAndSymptomsNetFlushingData';
  notes?: Maybe<Scalars['String']['output']>;
  value: YesNo;
};

export type SignsAndSymptomsNetFlushingDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: YesNo;
};

export type SignsAndSymptomsNetFlushingHistory = {
  __typename?: 'SignsAndSymptomsNetFlushingHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsNetFlushingData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsNetJaundiceData = {
  __typename?: 'SignsAndSymptomsNetJaundiceData';
  notes?: Maybe<Scalars['String']['output']>;
  value: YesNo;
};

export type SignsAndSymptomsNetJaundiceDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: YesNo;
};

export type SignsAndSymptomsNetJaundiceHistory = {
  __typename?: 'SignsAndSymptomsNetJaundiceHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsNetJaundiceData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsNetRetchingData = {
  __typename?: 'SignsAndSymptomsNetRetchingData';
  notes?: Maybe<Scalars['String']['output']>;
  value: YesNo;
};

export type SignsAndSymptomsNetRetchingDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: YesNo;
};

export type SignsAndSymptomsNetRetchingHistory = {
  __typename?: 'SignsAndSymptomsNetRetchingHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsNetRetchingData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsNetTachycardiaData = {
  __typename?: 'SignsAndSymptomsNetTachycardiaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: YesNo;
};

export type SignsAndSymptomsNetTachycardiaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: YesNo;
};

export type SignsAndSymptomsNetTachycardiaHistory = {
  __typename?: 'SignsAndSymptomsNetTachycardiaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsNetTachycardiaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsNetWeightLossData = {
  __typename?: 'SignsAndSymptomsNetWeightLossData';
  notes?: Maybe<Scalars['String']['output']>;
  value: YesNo;
};

export type SignsAndSymptomsNetWeightLossDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: YesNo;
};

export type SignsAndSymptomsNetWeightLossHistory = {
  __typename?: 'SignsAndSymptomsNetWeightLossHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsNetWeightLossData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsOligoanuriaData = {
  __typename?: 'SignsAndSymptomsOligoanuriaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsOligoanuriaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsOligoanuriaHistory = {
  __typename?: 'SignsAndSymptomsOligoanuriaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsOligoanuriaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsPancreatitisData = {
  __typename?: 'SignsAndSymptomsPancreatitisData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsPancreatitisDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsPancreatitisHistory = {
  __typename?: 'SignsAndSymptomsPancreatitisHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsPancreatitisData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsPulmonaryBleedingData = {
  __typename?: 'SignsAndSymptomsPulmonaryBleedingData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsPulmonaryBleedingDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsPulmonaryBleedingHistory = {
  __typename?: 'SignsAndSymptomsPulmonaryBleedingHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsPulmonaryBleedingData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsPulmonaryEdemaData = {
  __typename?: 'SignsAndSymptomsPulmonaryEdemaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsPulmonaryEdemaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsPulmonaryEdemaHistory = {
  __typename?: 'SignsAndSymptomsPulmonaryEdemaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsPulmonaryEdemaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsRetinalVesselsObstructionData = {
  __typename?: 'SignsAndSymptomsRetinalVesselsObstructionData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsRetinalVesselsObstructionDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsRetinalVesselsObstructionHistory = {
  __typename?: 'SignsAndSymptomsRetinalVesselsObstructionHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsRetinalVesselsObstructionData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsSkinInvolvementData = {
  __typename?: 'SignsAndSymptomsSkinInvolvementData';
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type SignsAndSymptomsSkinInvolvementDataArgs = {
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type SignsAndSymptomsSkinInvolvementHistory = {
  __typename?: 'SignsAndSymptomsSkinInvolvementHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsSkinInvolvementData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsSplenomegalyPalpableData = {
  __typename?: 'SignsAndSymptomsSplenomegalyPalpableData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsSplenomegalyPalpableDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsSplenomegalyPalpableHistory = {
  __typename?: 'SignsAndSymptomsSplenomegalyPalpableHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsSplenomegalyPalpableData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsStenosisData = {
  __typename?: 'SignsAndSymptomsStenosisData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsStenosisDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsStenosisHistory = {
  __typename?: 'SignsAndSymptomsStenosisHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsStenosisData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsStrokeData = {
  __typename?: 'SignsAndSymptomsStrokeData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsStrokeDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsStrokeHistory = {
  __typename?: 'SignsAndSymptomsStrokeHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsStrokeData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsSyncopesData = {
  __typename?: 'SignsAndSymptomsSyncopesData';
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type SignsAndSymptomsSyncopesDataArgs = {
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type SignsAndSymptomsSyncopesHistory = {
  __typename?: 'SignsAndSymptomsSyncopesHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsSyncopesData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsTachypneaData = {
  __typename?: 'SignsAndSymptomsTachypneaData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsTachypneaDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsTachypneaHistory = {
  __typename?: 'SignsAndSymptomsTachypneaHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsTachypneaData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SignsAndSymptomsVisualImpairmentData = {
  __typename?: 'SignsAndSymptomsVisualImpairmentData';
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type SignsAndSymptomsVisualImpairmentDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type SignsAndSymptomsVisualImpairmentHistory = {
  __typename?: 'SignsAndSymptomsVisualImpairmentHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: SignsAndSymptomsVisualImpairmentData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type SingleValueSurveyAnswerArgs = {
  code: Scalars['String']['input'];
  otherAnswerValue?: InputMaybe<Array<Scalars['String']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type SingleValueSurveyAnswerModel = {
  __typename?: 'SingleValueSurveyAnswerModel';
  code: Scalars['String']['output'];
  otherAnswerValue?: Maybe<Array<Scalars['String']['output']>>;
  type: SurveyAnswerType;
  /** In case when OtherAnswerOptions.isExclusive === true, this shall be null */
  value?: Maybe<Scalars['String']['output']>;
};

export type SokalMedicalIndexParametersModel = {
  ageInYears: Scalars['Float']['input'];
  myeloblastsPercentage: Scalars['Float']['input'];
  plateletCount: Scalars['Float']['input'];
  spleenSizeCm: Scalars['Float']['input'];
};

export enum Specialization {
  ACUPUNCTURE = 'ACUPUNCTURE',
  ADMINISTRATION = 'ADMINISTRATION',
  ALLERGOLOGY = 'ALLERGOLOGY',
  ANESTHESIA_AND_RESUSCITATION = 'ANESTHESIA_AND_RESUSCITATION',
  ANGIOLOGY = 'ANGIOLOGY',
  AUDIOLOGY = 'AUDIOLOGY',
  BIOCHEMISTRY = 'BIOCHEMISTRY',
  BREAST_SURGERY = 'BREAST_SURGERY',
  BRIEF_INTEGRATED_PSYCHOTHERAPY = 'BRIEF_INTEGRATED_PSYCHOTHERAPY',
  CARDIOLOGY = 'CARDIOLOGY',
  CARDIOVASCULAR_SURGERY = 'CARDIOVASCULAR_SURGERY',
  CARDIOVASCULAR_SYSTEM_DISEASES = 'CARDIOVASCULAR_SYSTEM_DISEASES',
  CHILD_NEUROPSYCHIATRY = 'CHILD_NEUROPSYCHIATRY',
  CLINICAL_IMMUNOLOGY = 'CLINICAL_IMMUNOLOGY',
  CLINICAL_PSYCHOLOGY = 'CLINICAL_PSYCHOLOGY',
  CLINICAL_PSYCHOLOGY_AND_PSYCHOTHERAPY = 'CLINICAL_PSYCHOLOGY_AND_PSYCHOTHERAPY',
  CLINICAL_SPEECH_THERAPY = 'CLINICAL_SPEECH_THERAPY',
  COGNITIVE_PSYCHOTHERAPY = 'COGNITIVE_PSYCHOTHERAPY',
  DERMATOLOGY = 'DERMATOLOGY',
  DERMATOLOGY_AND_VENEREOLOGY = 'DERMATOLOGY_AND_VENEREOLOGY',
  DIABETOLOGY = 'DIABETOLOGY',
  DIGESTIVE_SYSTEM_DISEASES = 'DIGESTIVE_SYSTEM_DISEASES',
  DIGESTIVE_SYSTEM_SURGERY = 'DIGESTIVE_SYSTEM_SURGERY',
  EMERGENCY_SURGERY = 'EMERGENCY_SURGERY',
  ENDOCRINOLOGY = 'ENDOCRINOLOGY',
  FOOD_SCIENCE = 'FOOD_SCIENCE',
  GASTROENTEROLOGICAL_SURGERY = 'GASTROENTEROLOGICAL_SURGERY',
  GASTROENTEROLOGY = 'GASTROENTEROLOGY',
  GENERAL_MEDICINE = 'GENERAL_MEDICINE',
  GENERAL_SURGERY = 'GENERAL_SURGERY',
  GENETICS = 'GENETICS',
  GERIATRICS = 'GERIATRICS',
  GYNECOLOGY = 'GYNECOLOGY',
  GYNECOLOGY_AND_OBSTRETRICS = 'GYNECOLOGY_AND_OBSTRETRICS',
  HEALTH_PROFESSION_TECHNICAL_SCIENCES_ASSISTENTIAL = 'HEALTH_PROFESSION_TECHNICAL_SCIENCES_ASSISTENTIAL',
  HEMATOLOGY = 'HEMATOLOGY',
  HUMAN_NUTRITION_AND_FOOD_SCIENCE = 'HUMAN_NUTRITION_AND_FOOD_SCIENCE',
  HYGIENE_AND_PREVENTIVE_MEDICINE = 'HYGIENE_AND_PREVENTIVE_MEDICINE',
  INFECTIOUS_DISEASES = 'INFECTIOUS_DISEASES',
  INTERNAL_MEDICINE = 'INTERNAL_MEDICINE',
  MAXILLOFACIAL_SURGERY = 'MAXILLOFACIAL_SURGERY',
  NEPHROLOGY = 'NEPHROLOGY',
  NEUROLOGY = 'NEUROLOGY',
  NEUROPHYSIOPATHOLOGY = 'NEUROPHYSIOPATHOLOGY',
  NEUROPSYCHOLOGY = 'NEUROPSYCHOLOGY',
  NEUROSURGERY = 'NEUROSURGERY',
  NUCLEAR_MEDICINE = 'NUCLEAR_MEDICINE',
  NUTRITION = 'NUTRITION',
  OCCUPATIONAL_MEDICINE = 'OCCUPATIONAL_MEDICINE',
  ODONTOSTOMATOLOGICAL_SURGERY = 'ODONTOSTOMATOLOGICAL_SURGERY',
  ODONTOSTOMATOLOGY = 'ODONTOSTOMATOLOGY',
  ONCOLOGY = 'ONCOLOGY',
  OPHTHALMOLOGY = 'OPHTHALMOLOGY',
  ORTHODONTICS = 'ORTHODONTICS',
  ORTHOPEDICS = 'ORTHOPEDICS',
  ORTHOPEDICS_AND_TRAUMATOLOGY = 'ORTHOPEDICS_AND_TRAUMATOLOGY',
  ORTHOPEDIC_SURGERY = 'ORTHOPEDIC_SURGERY',
  OTOLARYNGOLOGY = 'OTOLARYNGOLOGY',
  PATHOLOGICAL_ANATOMY = 'PATHOLOGICAL_ANATOMY',
  PEDIATRICS = 'PEDIATRICS',
  PEDIATRIC_SURGERY = 'PEDIATRIC_SURGERY',
  PHYSIATRY = 'PHYSIATRY',
  PHYSICAL_AND_REHABILITATIVE_MEDICINE = 'PHYSICAL_AND_REHABILITATIVE_MEDICINE',
  PHYSIOTHERAPY = 'PHYSIOTHERAPY',
  PLASTIC_SURGERY = 'PLASTIC_SURGERY',
  PNEUMOLOGY = 'PNEUMOLOGY',
  PSYCHIATRY = 'PSYCHIATRY',
  PSYCHOTHERAPY = 'PSYCHOTHERAPY',
  RADIOLOGY = 'RADIOLOGY',
  RADIOTHERAPY = 'RADIOTHERAPY',
  RHEUMATOLOGY = 'RHEUMATOLOGY',
  SPORTS_MEDICINE = 'SPORTS_MEDICINE',
  SURGERY = 'SURGERY',
  THORACIC_SURGERY = 'THORACIC_SURGERY',
  TROPICAL_MEDICINE = 'TROPICAL_MEDICINE',
  URGENCY_EMERGENCY_MEDICINE = 'URGENCY_EMERGENCY_MEDICINE',
  UROLOGY = 'UROLOGY',
  VASCULAR_SURGERY = 'VASCULAR_SURGERY',
  VULNOLOGY = 'VULNOLOGY'
}

export enum SpecializationCategory {
  CLINICAL = 'CLINICAL',
  SURGICAL = 'SURGICAL'
}

export type SsnClinicDataArgs = {
  defaultPatientWritePolicy: AllowDenyPolicyType;
  description: Scalars['String']['input'];
  isEnabled: Scalars['Boolean']['input'];
  memberPortalUserIds: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  patientWriteButtonVisibilityPolicy: AllowDenyPolicyType;
  slug?: InputMaybe<Scalars['String']['input']>;
  specializations: Array<Specialization>;
};

export type StringArgs = {
  evaluatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  value: Scalars['String']['input'];
};

export type StringContainer = {
  __typename?: 'StringContainer';
  evaluatedAt?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy: Scalars['String']['output'];
  updaterName: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum SubSection {
  ABDOMEN_ULTRASOUND = 'ABDOMEN_ULTRASOUND',
  ABDOMINAL_PAIN = 'ABDOMINAL_PAIN',
  ACA = 'ACA',
  ADAMTS13_DELIVERY_DATE = 'ADAMTS13_DELIVERY_DATE',
  ADAMTS13_REQUEST_DATE = 'ADAMTS13_REQUEST_DATE',
  AHUS_CYTOLOGICAL_EXAMINATION = 'AHUS_CYTOLOGICAL_EXAMINATION',
  AHUS_DIAGNOSIS_DATE = 'AHUS_DIAGNOSIS_DATE',
  AHUS_HYSTOLOGICAL_EXAMINATION = 'AHUS_HYSTOLOGICAL_EXAMINATION',
  AHUS_MEDICATIONS_START_DATE = 'AHUS_MEDICATIONS_START_DATE',
  AHUS_TOOK_CHARGE_DATE = 'AHUS_TOOK_CHARGE_DATE',
  ALLOGENEIC_TRANSPLANTATION = 'ALLOGENEIC_TRANSPLANTATION',
  ANAPHYLACTIC_SHOCK = 'ANAPHYLACTIC_SHOCK',
  ANTIMENINGOCOCCAL_VACCINATION = 'ANTIMENINGOCOCCAL_VACCINATION',
  APHERESIS = 'APHERESIS',
  ASTHENIA = 'ASTHENIA',
  AUTOIMMUNE_DISEASES = 'AUTOIMMUNE_DISEASES',
  BCR_ABL1_MUTATION = 'BCR_ABL1_MUTATION',
  BCR_ABL1_PCR_Q = 'BCR_ABL1_PCR_Q',
  BCR_ABL1_TRANSCRIPT = 'BCR_ABL1_TRANSCRIPT',
  BLURRED_EYESIGHT = 'BLURRED_EYESIGHT',
  BMA = 'BMA',
  BMB = 'BMB',
  BONE_DENSITOMETRY = 'BONE_DENSITOMETRY',
  BONE_IMAGING = 'BONE_IMAGING',
  BRAF_MUTATION = 'BRAF_MUTATION',
  BREAST_ADJUVANT_THERAPY = 'BREAST_ADJUVANT_THERAPY',
  BREAST_FAMILY_HISTORY = 'BREAST_FAMILY_HISTORY',
  BREAST_HISTOLOGICAL_EXAMINATION = 'BREAST_HISTOLOGICAL_EXAMINATION',
  BREAST_HISTOTYPE = 'BREAST_HISTOTYPE',
  BREAST_HORMONE_THERAPY = 'BREAST_HORMONE_THERAPY',
  BREAST_METASTASIS = 'BREAST_METASTASIS',
  BREAST_NEOADJUVANT_THERAPY = 'BREAST_NEOADJUVANT_THERAPY',
  BREAST_NODES_DISSECTION = 'BREAST_NODES_DISSECTION',
  BREAST_RADIOTHERAPY = 'BREAST_RADIOTHERAPY',
  BREAST_RESECTION_MARGINS = 'BREAST_RESECTION_MARGINS',
  BREAST_SENTINEL_NODE = 'BREAST_SENTINEL_NODE',
  CALR = 'CALR',
  CARDIOVASCULAR_DISEASE = 'CARDIOVASCULAR_DISEASE',
  CAR_T_ELIGIBILITY = 'CAR_T_ELIGIBILITY',
  CAR_T_INFUSION = 'CAR_T_INFUSION',
  CAR_T_TREATMENT_ARRIVE = 'CAR_T_TREATMENT_ARRIVE',
  CAR_T_TREATMENT_ORDER = 'CAR_T_TREATMENT_ORDER',
  COMA = 'COMA',
  CONFUSION = 'CONFUSION',
  CONVULSIONS = 'CONVULSIONS',
  C_KIT_MUTATION = 'C_KIT_MUTATION',
  DEATH_DATE = 'DEATH_DATE',
  DISEASE_STAGE = 'DISEASE_STAGE',
  DISPNEA = 'DISPNEA',
  DONOR = 'DONOR',
  ECG = 'ECG',
  ECHO = 'ECHO',
  ECOG = 'ECOG',
  EDEMAS = 'EDEMAS',
  EYE_BLEEDING = 'EYE_BLEEDING',
  EYE_EXAMINATION = 'EYE_EXAMINATION',
  EYE_PAIN = 'EYE_PAIN',
  FEVER = 'FEVER',
  FITZPATRICK_SCALE = 'FITZPATRICK_SCALE',
  GASTROINTESTINAL_DISORDERS = 'GASTROINTESTINAL_DISORDERS',
  GENERIC_INFORMATION = 'GENERIC_INFORMATION',
  GENERIC_NOTES = 'GENERIC_NOTES',
  GENETICS = 'GENETICS',
  GLOMERULONEPHRITES = 'GLOMERULONEPHRITES',
  HEART_ATTACK = 'HEART_ATTACK',
  HEMATOLOGY = 'HEMATOLOGY',
  HEMATURIA = 'HEMATURIA',
  HEMOPHTHOUS = 'HEMOPHTHOUS',
  HEMOPTYSIS = 'HEMOPTYSIS',
  HISTOLOGY = 'HISTOLOGY',
  HYMENOPTERA = 'HYMENOPTERA',
  HYPERTENSION = 'HYPERTENSION',
  IMMUNOPHENOTYPING = 'IMMUNOPHENOTYPING',
  INFECTIONS = 'INFECTIONS',
  IS_IN_TRANSPLANT_LIST = 'IS_IN_TRANSPLANT_LIST',
  JAK2_EXON12 = 'JAK2_EXON12',
  JAK2_V617F = 'JAK2_V617F',
  KARYOTYPE = 'KARYOTYPE',
  KIDNEY_FAILURE = 'KIDNEY_FAILURE',
  MELANOMA_FAMILY_HISTORY = 'MELANOMA_FAMILY_HISTORY',
  MELANOMA_HISTOTYPE = 'MELANOMA_HISTOTYPE',
  MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION = 'MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION',
  MELANOMA_METASTASIS = 'MELANOMA_METASTASIS',
  MELANOMA_NODES_DISSECTION = 'MELANOMA_NODES_DISSECTION',
  MELANOMA_RESECTION_MARGINS = 'MELANOMA_RESECTION_MARGINS',
  MELANOMA_SENTINEL_NODE = 'MELANOMA_SENTINEL_NODE',
  MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION = 'MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION',
  MENOPAUSE = 'MENOPAUSE',
  MENSTRUAL_CYCLE = 'MENSTRUAL_CYCLE',
  MPL = 'MPL',
  NAUSEA = 'NAUSEA',
  NEOPLASIA = 'NEOPLASIA',
  NET_ACHE = 'NET_ACHE',
  NET_ADJUVANT_THERAPY = 'NET_ADJUVANT_THERAPY',
  NET_CYTOLOGICAL_EXAMINATION = 'NET_CYTOLOGICAL_EXAMINATION',
  NET_DIARRHEA = 'NET_DIARRHEA',
  NET_FLUSHING = 'NET_FLUSHING',
  NET_GASTRIC_ULCERS = 'NET_GASTRIC_ULCERS',
  NET_GEP_GRADING = 'NET_GEP_GRADING',
  NET_GLUCIDIC_METABOLISM_ALTERATION = 'NET_GLUCIDIC_METABOLISM_ALTERATION',
  NET_HISTOLOGICAL_EXAMINATION = 'NET_HISTOLOGICAL_EXAMINATION',
  NET_HYPERCALCEMIA = 'NET_HYPERCALCEMIA',
  NET_HYPOPHYSIS_ADENOMA = 'NET_HYPOPHYSIS_ADENOMA',
  NET_JAUNDICE = 'NET_JAUNDICE',
  NET_LYMPH_NODES_HISTOLOGICAL_EXAMINATION = 'NET_LYMPH_NODES_HISTOLOGICAL_EXAMINATION',
  NET_LYMPH_NODES_PRESENCE = 'NET_LYMPH_NODES_PRESENCE',
  NET_METASTASIS = 'NET_METASTASIS',
  NET_MULTIPLE_ENDOCRINE_NEOPLASIA = 'NET_MULTIPLE_ENDOCRINE_NEOPLASIA',
  NET_NEOADJUVANT_THERAPY = 'NET_NEOADJUVANT_THERAPY',
  NET_NEPHROLITHIASIS = 'NET_NEPHROLITHIASIS',
  NET_NEUROENDOCRINE_TUMORS = 'NET_NEUROENDOCRINE_TUMORS',
  NET_OTHER_NEOPLASIA = 'NET_OTHER_NEOPLASIA',
  NET_RADIO_RECEPTOR_THERAPY = 'NET_RADIO_RECEPTOR_THERAPY',
  NET_RETCHING = 'NET_RETCHING',
  NET_SOMATOSTATIN_ANALOGUE_THERAPY = 'NET_SOMATOSTATIN_ANALOGUE_THERAPY',
  NET_SURGERY = 'NET_SURGERY',
  NET_SURGERY_FLAG = 'NET_SURGERY_FLAG',
  NET_TACHYCARDIA = 'NET_TACHYCARDIA',
  NET_THORAX_GRADING = 'NET_THORAX_GRADING',
  NET_THORAX_STAGING_TNM_ASCO_2021 = 'NET_THORAX_STAGING_TNM_ASCO_2021',
  NET_WEIGHT_LOSS = 'NET_WEIGHT_LOSS',
  NGS = 'NGS',
  NON_BRAF_MUTATION = 'NON_BRAF_MUTATION',
  OLIGOANURIA = 'OLIGOANURIA',
  OTHER = 'OTHER',
  PANCREATITIS = 'PANCREATITIS',
  PULMONARY_BLEEDING = 'PULMONARY_BLEEDING',
  PULMONARY_EDEMA = 'PULMONARY_EDEMA',
  RETINAL_VESSELS_OBSTRUCTION = 'RETINAL_VESSELS_OBSTRUCTION',
  SEVERE_HYPERTENSION = 'SEVERE_HYPERTENSION',
  SKIN_INVOLVEMENT = 'SKIN_INVOLVEMENT',
  SMOKE = 'SMOKE',
  SNC_LOCALIZATIONS = 'SNC_LOCALIZATIONS',
  SPLENOMEGALY_PALPABLE = 'SPLENOMEGALY_PALPABLE',
  STENOSIS = 'STENOSIS',
  STROKE = 'STROKE',
  SUBCLONAL = 'SUBCLONAL',
  SURGERY_FOR_BREAST_CANCER = 'SURGERY_FOR_BREAST_CANCER',
  SYNCOPES = 'SYNCOPES',
  TACHYPNEA = 'TACHYPNEA',
  THERAPY = 'THERAPY',
  TRAUMA = 'TRAUMA',
  URINE_EXAM = 'URINE_EXAM',
  VISUAL_IMPAIRMENT = 'VISUAL_IMPAIRMENT'
}

export enum SubSectionLabParameter {
  PARAMETER_ACTH = 'PARAMETER_ACTH',
  PARAMETER_ADAMTS13 = 'PARAMETER_ADAMTS13',
  PARAMETER_ALBUMIN = 'PARAMETER_ALBUMIN',
  PARAMETER_ALBUMIN_PERCENT = 'PARAMETER_ALBUMIN_PERCENT',
  PARAMETER_ALP = 'PARAMETER_ALP',
  PARAMETER_ALPHA_FETOPROTEIN = 'PARAMETER_ALPHA_FETOPROTEIN',
  PARAMETER_ALT = 'PARAMETER_ALT',
  PARAMETER_AMYLASE = 'PARAMETER_AMYLASE',
  PARAMETER_ANTIBODIES_ANTI_THYROID_PEROXIDASE = 'PARAMETER_ANTIBODIES_ANTI_THYROID_PEROXIDASE',
  PARAMETER_ANTIBODIES_THYROGLOBULIN = 'PARAMETER_ANTIBODIES_THYROGLOBULIN',
  PARAMETER_ANTIBODIES_TSH_RECEPTOR = 'PARAMETER_ANTIBODIES_TSH_RECEPTOR',
  PARAMETER_APTT = 'PARAMETER_APTT',
  PARAMETER_APTT_RATIO = 'PARAMETER_APTT_RATIO',
  PARAMETER_AST = 'PARAMETER_AST',
  PARAMETER_ATM = 'PARAMETER_ATM',
  PARAMETER_BASOPHILS = 'PARAMETER_BASOPHILS',
  PARAMETER_BASOPHILS_ABSOLUTE = 'PARAMETER_BASOPHILS_ABSOLUTE',
  PARAMETER_BILIRUBIN_DIRECT = 'PARAMETER_BILIRUBIN_DIRECT',
  PARAMETER_BILIRUBIN_TOTAL = 'PARAMETER_BILIRUBIN_TOTAL',
  PARAMETER_BRCA1 = 'PARAMETER_BRCA1',
  PARAMETER_BRCA2 = 'PARAMETER_BRCA2',
  PARAMETER_CALCITONIN = 'PARAMETER_CALCITONIN',
  PARAMETER_CA_15_3 = 'PARAMETER_CA_15_3',
  PARAMETER_CA_19_9 = 'PARAMETER_CA_19_9',
  PARAMETER_CA_125 = 'PARAMETER_CA_125',
  PARAMETER_CEA = 'PARAMETER_CEA',
  PARAMETER_CHOLESTEROL_HDL = 'PARAMETER_CHOLESTEROL_HDL',
  PARAMETER_CHOLESTEROL_LDL = 'PARAMETER_CHOLESTEROL_LDL',
  PARAMETER_CHOLESTEROL_TOTAL = 'PARAMETER_CHOLESTEROL_TOTAL',
  PARAMETER_CHROMOGRANIN_A = 'PARAMETER_CHROMOGRANIN_A',
  PARAMETER_CREATININE = 'PARAMETER_CREATININE',
  PARAMETER_C_TERMINAL_TELOPEPTIDE = 'PARAMETER_C_TERMINAL_TELOPEPTIDE',
  PARAMETER_DIRECT_COOMBS_TEST = 'PARAMETER_DIRECT_COOMBS_TEST',
  PARAMETER_EGFR = 'PARAMETER_EGFR',
  PARAMETER_EOSINOPHILS = 'PARAMETER_EOSINOPHILS',
  PARAMETER_EOSINOPHILS_ABSOLUTE = 'PARAMETER_EOSINOPHILS_ABSOLUTE',
  PARAMETER_ERYTHROPOIETIN = 'PARAMETER_ERYTHROPOIETIN',
  PARAMETER_ESTRADIOL = 'PARAMETER_ESTRADIOL',
  PARAMETER_FERRITIN = 'PARAMETER_FERRITIN',
  PARAMETER_FOLATE = 'PARAMETER_FOLATE',
  PARAMETER_FSH = 'PARAMETER_FSH',
  PARAMETER_FT3 = 'PARAMETER_FT3',
  PARAMETER_FT4 = 'PARAMETER_FT4',
  PARAMETER_GASTRIN = 'PARAMETER_GASTRIN',
  PARAMETER_GGT = 'PARAMETER_GGT',
  PARAMETER_GH = 'PARAMETER_GH',
  PARAMETER_GLUCOSE = 'PARAMETER_GLUCOSE',
  PARAMETER_HAPTOGLOBIN = 'PARAMETER_HAPTOGLOBIN',
  PARAMETER_HB = 'PARAMETER_HB',
  PARAMETER_HBA1C = 'PARAMETER_HBA1C',
  PARAMETER_HBA1C_PERCENT = 'PARAMETER_HBA1C_PERCENT',
  PARAMETER_HBS_AG = 'PARAMETER_HBS_AG',
  PARAMETER_HCT = 'PARAMETER_HCT',
  PARAMETER_HCV = 'PARAMETER_HCV',
  PARAMETER_HIV = 'PARAMETER_HIV',
  PARAMETER_IGF_1 = 'PARAMETER_IGF_1',
  PARAMETER_INDIRECT_BILIRUBIN = 'PARAMETER_INDIRECT_BILIRUBIN',
  PARAMETER_INORGANIC_PHOSPHATE = 'PARAMETER_INORGANIC_PHOSPHATE',
  PARAMETER_INR = 'PARAMETER_INR',
  PARAMETER_INSULIN = 'PARAMETER_INSULIN',
  PARAMETER_IONIZED_CALCIUM = 'PARAMETER_IONIZED_CALCIUM',
  PARAMETER_IRON = 'PARAMETER_IRON',
  PARAMETER_LDH = 'PARAMETER_LDH',
  PARAMETER_LH = 'PARAMETER_LH',
  PARAMETER_LINFO = 'PARAMETER_LINFO',
  PARAMETER_LIPASE = 'PARAMETER_LIPASE',
  PARAMETER_LYMPHOCYTES = 'PARAMETER_LYMPHOCYTES',
  PARAMETER_MCH = 'PARAMETER_MCH',
  PARAMETER_MCV = 'PARAMETER_MCV',
  PARAMETER_MONOCYTES = 'PARAMETER_MONOCYTES',
  PARAMETER_MONOCYTES_ABSOLUTE = 'PARAMETER_MONOCYTES_ABSOLUTE',
  PARAMETER_NEURON_SPECIFIC_ENOLASE = 'PARAMETER_NEURON_SPECIFIC_ENOLASE',
  PARAMETER_NEUTROPHILS = 'PARAMETER_NEUTROPHILS',
  PARAMETER_NEUTROPHILS_ABSOLUTE = 'PARAMETER_NEUTROPHILS_ABSOLUTE',
  PARAMETER_PALB2 = 'PARAMETER_PALB2',
  PARAMETER_PARATHYROID_HORMONE = 'PARAMETER_PARATHYROID_HORMONE',
  PARAMETER_PCR = 'PARAMETER_PCR',
  PARAMETER_PLASMATIC_CORTISOL = 'PARAMETER_PLASMATIC_CORTISOL',
  PARAMETER_PLT = 'PARAMETER_PLT',
  PARAMETER_POTASSIUM = 'PARAMETER_POTASSIUM',
  PARAMETER_PROGESTERONE = 'PARAMETER_PROGESTERONE',
  PARAMETER_PROLACTIN = 'PARAMETER_PROLACTIN',
  PARAMETER_PT = 'PARAMETER_PT',
  PARAMETER_RAD51 = 'PARAMETER_RAD51',
  PARAMETER_RBC = 'PARAMETER_RBC',
  PARAMETER_RDW = 'PARAMETER_RDW',
  PARAMETER_RDW_SD = 'PARAMETER_RDW_SD',
  PARAMETER_RETICULOCYTES = 'PARAMETER_RETICULOCYTES',
  PARAMETER_SCHISTOCYTES = 'PARAMETER_SCHISTOCYTES',
  PARAMETER_SERUM_PROTEIN_ELECTROPHORESIS = 'PARAMETER_SERUM_PROTEIN_ELECTROPHORESIS',
  PARAMETER_SHIGA_TOXIN = 'PARAMETER_SHIGA_TOXIN',
  PARAMETER_SODIUM = 'PARAMETER_SODIUM',
  PARAMETER_S_100 = 'PARAMETER_S_100',
  PARAMETER_TOTAL_CALCIUM = 'PARAMETER_TOTAL_CALCIUM',
  PARAMETER_TRANSFERRIN = 'PARAMETER_TRANSFERRIN',
  PARAMETER_TRIGLYCERIDES = 'PARAMETER_TRIGLYCERIDES',
  PARAMETER_TRYPTASE = 'PARAMETER_TRYPTASE',
  PARAMETER_TSH = 'PARAMETER_TSH',
  PARAMETER_UREA = 'PARAMETER_UREA',
  PARAMETER_URIC_ACID = 'PARAMETER_URIC_ACID',
  PARAMETER_URINARY_CALCIUM = 'PARAMETER_URINARY_CALCIUM',
  PARAMETER_URINARY_FREE_CORTISOL = 'PARAMETER_URINARY_FREE_CORTISOL',
  PARAMETER_URINARY_PHOSPHATE = 'PARAMETER_URINARY_PHOSPHATE',
  PARAMETER_VES = 'PARAMETER_VES',
  PARAMETER_VITAMIN_25_OH_D = 'PARAMETER_VITAMIN_25_OH_D',
  PARAMETER_VITAMIN_B12 = 'PARAMETER_VITAMIN_B12',
  PARAMETER_WBC = 'PARAMETER_WBC'
}

export type SubmitAssistedDoctorSearchFormArgs = {
  appliedSearchCriteria: SearchCriteriaArgs;
  desiredSearchCriteria: SearchCriteriaArgs;
  doctorPortalUserId: Scalars['String']['input'];
  /** An array of FileId(s). The referenced Files will be added to the Patient's Clinical Folder (as PatientFile(s)) if they are not part of it already, and then attached to the message. */
  patientFileAttachmentsIds?: Array<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chatItemUpserted: ChatItemModel;
  chatPinnedToHomeChanged: ChatPinnedToHomeChangedModel;
  chatSearchStoreUpdated: ChatModelUnion;
  draftDirectMessageConfirmed: DraftDirectMessageConfirmedModel;
  globalUserStatsUpdated: Array<PortalUserStatsModel>;
  identityVerificationInitialized: IdentityVerificationInitializedModel;
  identityVerificationRequestCreated: IdentityVerificationRequestModel;
  identityVerificationRequestProcessed: IdentityVerificationRequestProcessingResultModel;
  intramedThreadAttachmentsUpdated: IntramedThreadAttachmentsModel;
  intramedThreadListUpdated: IntramedThreadModel;
  intramedThreadMessageUpdated: IntramedThreadMessageUnion;
  intramedThreadUpdated: IntramedThreadModel;
  pdfHeaderExtracted: PdfHeaderExtractionResultModel;
  pdfParametersExtracted: FileModel;
  pendingApplicationRequestExecuted: BasicResourceReferenceModelUnion;
  portalUserStatsUpdated: PortalUserStatsModel;
};


export type SubscriptionchatItemUpsertedArgs = {
  chatId: Scalars['String']['input'];
  context: SubscriptionContextModel;
};


export type SubscriptionchatPinnedToHomeChangedArgs = {
  chatId: Scalars['String']['input'];
  context: SubscriptionContextModel;
};


export type SubscriptionchatSearchStoreUpdatedArgs = {
  context: SubscriptionContextModel;
};


export type SubscriptiondraftDirectMessageConfirmedArgs = {
  draftDirectMessageId: Scalars['String']['input'];
};


export type SubscriptionglobalUserStatsUpdatedArgs = {
  context: SubscriptionContextModel;
};


export type SubscriptionidentityVerificationInitializedArgs = {
  context: SubscriptionContextModel;
};


export type SubscriptionidentityVerificationRequestCreatedArgs = {
  context: SubscriptionContextModel;
};


export type SubscriptionidentityVerificationRequestProcessedArgs = {
  context: SubscriptionContextModel;
  identityVerificationRequestId: Scalars['String']['input'];
};


export type SubscriptionintramedThreadAttachmentsUpdatedArgs = {
  context: SubscriptionContextModel;
  intramedThreadId: Scalars['String']['input'];
};


export type SubscriptionintramedThreadListUpdatedArgs = {
  context: SubscriptionContextModel;
};


export type SubscriptionintramedThreadMessageUpdatedArgs = {
  context: SubscriptionContextModel;
  intramedThreadId: Scalars['String']['input'];
};


export type SubscriptionintramedThreadUpdatedArgs = {
  context: SubscriptionContextModel;
  intramedThreadId: Scalars['String']['input'];
};


export type SubscriptionpdfHeaderExtractedArgs = {
  context: SubscriptionContextModel;
};


export type SubscriptionpdfParametersExtractedArgs = {
  context: SubscriptionContextModel;
};


export type SubscriptionpendingApplicationRequestExecutedArgs = {
  pendingApplicationRequestId: Scalars['String']['input'];
};


export type SubscriptionportalUserStatsUpdatedArgs = {
  context: SubscriptionContextModel;
};

export type SubscriptionContextModel = {
  institutionUserId: Scalars['String']['input'];
  portalId: Scalars['String']['input'];
};

export type SupportRequest = {
  __typename?: 'SupportRequest';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  institutionUser?: Maybe<InstitutionUser>;
  lastName: Scalars['String']['output'];
  message: Scalars['String']['output'];
  number: Scalars['Float']['output'];
  phoneNumber: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SurveyAnswerDataArgs = {
  multiValueAnswers: Array<MultiValueSurveyAnswerArgs>;
  singleValueAnswers: Array<SingleValueSurveyAnswerArgs>;
};

export type SurveyAnswerDataModel = {
  __typename?: 'SurveyAnswerDataModel';
  answerValues: Array<SurveyAnswerValueTypeUnion>;
};

export type SurveyAnswerHistoryModel = {
  __typename?: 'SurveyAnswerHistoryModel';
  issueDate: Scalars['DateTime']['output'];
  issuer: PortalUserModel;
  recordId: Scalars['String']['output'];
  surveyAnswer?: Maybe<SurveyAnswerModel>;
};

export type SurveyAnswerModel = {
  __typename?: 'SurveyAnswerModel';
  answerData: SurveyAnswerDataModel;
  answerDate: Scalars['DateTime']['output'];
  answeredBy: PortalUserModel;
  id: Scalars['String']['output'];
};

export enum SurveyAnswerType {
  MULTI = 'MULTI',
  SINGLE = 'SINGLE'
}

export type SurveyAnswerValueTypeUnion = MultiValueSurveyAnswerModel | SingleValueSurveyAnswerModel;

export type SurveyComputedModel = {
  __typename?: 'SurveyComputedModel';
  answerCssClasses?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  contentAfter?: Maybe<Scalars['String']['output']>;
  contentBefore?: Maybe<Scalars['String']['output']>;
  fieldType: SurveyQuestionFieldType;
  fillOutCssClasses?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: SurveyPartType;
};

/** The maximum depth of the structure is 5, meaning that rootSection may have at most 4 recursively nested Sections. */
export type SurveyDefinitionModel = {
  __typename?: 'SurveyDefinitionModel';
  rootSection: SurveySectionModel;
};

export enum SurveyDependecyRuleOperator {
  HAS = 'HAS',
  IN = 'IN',
  IS = 'IS'
}

export type SurveyDoctorHeaderDataModel = {
  __typename?: 'SurveyDoctorHeaderDataModel';
  doctor: PortalUserModel;
  institution: InstitutionModel;
  surveyIssueRecord: SurveyIssueRecordModel;
};

export type SurveyIssueModel = {
  __typename?: 'SurveyIssueModel';
  createdAt: Scalars['DateTime']['output'];
  dateEnd?: Maybe<Scalars['DateTime']['output']>;
  dateStart: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isDeletable: Scalars['Boolean']['output'];
  issuer: PortalUserModel;
  recipient: PortalUserModel;
  recurrenceType: SurveyIssueRecurrenceType;
  survey: SurveyModel;
};

export type SurveyIssueRecordModel = {
  __typename?: 'SurveyIssueRecordModel';
  dueOn: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
};

export enum SurveyIssueRecurrenceType {
  EVERY_DAY = 'EVERY_DAY',
  EVERY_MONTH = 'EVERY_MONTH',
  EVERY_TWO_WEEK = 'EVERY_TWO_WEEK',
  EVERY_WEEK = 'EVERY_WEEK',
  EVERY_YEAR = 'EVERY_YEAR',
  NEVER = 'NEVER'
}

export type SurveyModel = {
  __typename?: 'SurveyModel';
  code: Scalars['String']['output'];
  definition: SurveyDefinitionModel;
  id: Scalars['String']['output'];
  issuerDescription?: Maybe<Scalars['String']['output']>;
  patientCanAnswer: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type SurveyPartModelTypeUnion = SurveyComputedModel | SurveyQuestionModel | SurveySectionModel;

export enum SurveyPartType {
  COMPUTED = 'COMPUTED',
  QUESTION = 'QUESTION',
  SECTION = 'SECTION'
}

export enum SurveyQuestionFieldType {
  MULTISELECT = 'MULTISELECT',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  TEXT_LIST = 'TEXT_LIST',
  YES_NO = 'YES_NO'
}

export enum SurveyQuestionIntent {
  INIT_FROM_PREVIOUS_ANSWER = 'INIT_FROM_PREVIOUS_ANSWER',
  PATIENT_AGE = 'PATIENT_AGE'
}

export type SurveyQuestionModel = {
  __typename?: 'SurveyQuestionModel';
  answerCssClasses?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  contentAfter?: Maybe<Scalars['String']['output']>;
  contentBefore?: Maybe<Scalars['String']['output']>;
  defaultValue?: Maybe<DefaultQuestionValueModel>;
  dependsOn?: Maybe<QuestionDependencyModel>;
  fieldType: SurveyQuestionFieldType;
  fillOutCssClasses?: Maybe<Scalars['String']['output']>;
  intent?: Maybe<SurveyQuestionIntent>;
  isRequired: Scalars['Boolean']['output'];
  options?: Maybe<Array<SelectOptionModel>>;
  otherAnswer?: Maybe<OtherAnswerOptionsModel>;
  title: Scalars['String']['output'];
  type: SurveyPartType;
  validation?: Maybe<ValidationOptionsModel>;
};

export type SurveySectionModel = {
  __typename?: 'SurveySectionModel';
  answerCssClasses?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  contentAfter?: Maybe<Scalars['String']['output']>;
  contentBefore?: Maybe<Scalars['String']['output']>;
  fillOutCssClasses?: Maybe<Scalars['String']['output']>;
  parts: Array<SurveyPartModelTypeUnion>;
  title: Scalars['String']['output'];
  type: SurveyPartType;
};

export enum Symptom {
  ABDOMINAL_BLOATING = 'ABDOMINAL_BLOATING',
  ABDOMINAL_CRAMPS = 'ABDOMINAL_CRAMPS',
  ABDOMINAL_DISTENSION = 'ABDOMINAL_DISTENSION',
  ABDOMINAL_DISTENSION_OR_METEORISM = 'ABDOMINAL_DISTENSION_OR_METEORISM',
  ABDOMINAL_OR_PELVIC_MASS = 'ABDOMINAL_OR_PELVIC_MASS',
  ABDOMINAL_PAIN = 'ABDOMINAL_PAIN',
  ABDOMINAL_PAIN_VARIANT_2 = 'ABDOMINAL_PAIN_VARIANT_2',
  ABDOMINAL_PALPITATIONS = 'ABDOMINAL_PALPITATIONS',
  ABDOMINAL_PULSE = 'ABDOMINAL_PULSE',
  ABDOMINOPLASTY_TUMMY_TUCK_SURGERY = 'ABDOMINOPLASTY_TUMMY_TUCK_SURGERY',
  ABNORMALITIES_IN_THE_GROWTH_OF_PERMANENT_TEETH = 'ABNORMALITIES_IN_THE_GROWTH_OF_PERMANENT_TEETH',
  ABNORMAL_BLEEDING = 'ABNORMAL_BLEEDING',
  ABNORMAL_BLEEDING_AFTER_SURGERY_OR_INJURY = 'ABNORMAL_BLEEDING_AFTER_SURGERY_OR_INJURY',
  ABNORMAL_HAIR_GROWTH_HIRSUTISM = 'ABNORMAL_HAIR_GROWTH_HIRSUTISM',
  ABNORMAL_MAMMARY_SECRETION = 'ABNORMAL_MAMMARY_SECRETION',
  ABNORMAL_VAGINAL_BLEEDING = 'ABNORMAL_VAGINAL_BLEEDING',
  ABNORMAL_VAGINAL_BLEEDING_IN_ADOLESCENTS = 'ABNORMAL_VAGINAL_BLEEDING_IN_ADOLESCENTS',
  ABNORMAL_VAGINAL_SECRETION = 'ABNORMAL_VAGINAL_SECRETION',
  ABSTRACT_REASONING_PROBLEMS = 'ABSTRACT_REASONING_PROBLEMS',
  ACID_REFLUX_OR_HEARTBURN = 'ACID_REFLUX_OR_HEARTBURN',
  ACID_REGURGITATION_GASTROESOPHAGEAL_REFLUX = 'ACID_REGURGITATION_GASTROESOPHAGEAL_REFLUX',
  ACNE_OR_CHANGES_IN_THE_SKIN = 'ACNE_OR_CHANGES_IN_THE_SKIN',
  ACQUIRED_DEFORMITIES = 'ACQUIRED_DEFORMITIES',
  ACUTE_ABDOMINAL_PAIN = 'ACUTE_ABDOMINAL_PAIN',
  ACUTE_CHEST_PAIN_ASSOCIATED_WITH_HEART_PROBLEMS = 'ACUTE_CHEST_PAIN_ASSOCIATED_WITH_HEART_PROBLEMS',
  ACUTE_LYMPHOBLASTIC_LEUKEMIA = 'ACUTE_LYMPHOBLASTIC_LEUKEMIA',
  ACUTE_MYELOBLASTIC_LEUKEMIA = 'ACUTE_MYELOBLASTIC_LEUKEMIA',
  ACUTE_NEPHRITIC_SYNDROME = 'ACUTE_NEPHRITIC_SYNDROME',
  ACUTE_RENAL_FAILURE_SYNDROME = 'ACUTE_RENAL_FAILURE_SYNDROME',
  ACUTE_STRESS_DISORDER = 'ACUTE_STRESS_DISORDER',
  ADAPTATION_DISORDER = 'ADAPTATION_DISORDER',
  ADDICTIONS_ALCOHOL_DRUGS_GAMBLING_ETC = 'ADDICTIONS_ALCOHOL_DRUGS_GAMBLING_ETC',
  ADVERSE_OR_ALLERGIC_REACTIONS_TO_BANDAGES_OR_TREATMENTS = 'ADVERSE_OR_ALLERGIC_REACTIONS_TO_BANDAGES_OR_TREATMENTS',
  AGE_RELATED_HEARING_DISORDERS_PRESBYCUSIS = 'AGE_RELATED_HEARING_DISORDERS_PRESBYCUSIS',
  AGGRESSIVE_BEHAVIORS = 'AGGRESSIVE_BEHAVIORS',
  AGGRESSIVE_CHILD = 'AGGRESSIVE_CHILD',
  AGITATION = 'AGITATION',
  AGITATION_OR_RESTLESSNESS = 'AGITATION_OR_RESTLESSNESS',
  AGORAPHOBIC_PANIC_DISORDER = 'AGORAPHOBIC_PANIC_DISORDER',
  AGRAPHIA_LOSS_OF_THE_ABILITY_TO_WRITE = 'AGRAPHIA_LOSS_OF_THE_ABILITY_TO_WRITE',
  AIRWAY_COMPRESSION = 'AIRWAY_COMPRESSION',
  ALCOHOLISM_ALCOHOL_ADDICTION = 'ALCOHOLISM_ALCOHOL_ADDICTION',
  ALLERGIC_SYMPTOMS_OR_ALLERGIC_REACTIONS = 'ALLERGIC_SYMPTOMS_OR_ALLERGIC_REACTIONS',
  ALLERGY = 'ALLERGY',
  ALOPECIA_HAIR_LOSS = 'ALOPECIA_HAIR_LOSS',
  ALPORT_SYNDROME = 'ALPORT_SYNDROME',
  ALTERATIONS_IN_CELLULAR_IMMUNITY_SUCH_AS_CELLULAR_IMMUNODEFICIENCY_SYNDROME = 'ALTERATIONS_IN_CELLULAR_IMMUNITY_SUCH_AS_CELLULAR_IMMUNODEFICIENCY_SYNDROME',
  ALTERATIONS_IN_EYESIGHT_OR_VISION = 'ALTERATIONS_IN_EYESIGHT_OR_VISION',
  ALTERATIONS_IN_HEAD_SHAPE = 'ALTERATIONS_IN_HEAD_SHAPE',
  ALTERATIONS_IN_HEART_RATE_OR_BLOOD_PRESSURE = 'ALTERATIONS_IN_HEART_RATE_OR_BLOOD_PRESSURE',
  ALTERATIONS_IN_LANGUAGE_AND_THE_ABILITY_TO_COMMUNICATE = 'ALTERATIONS_IN_LANGUAGE_AND_THE_ABILITY_TO_COMMUNICATE',
  ALTERATIONS_IN_MUSCLE_TONE = 'ALTERATIONS_IN_MUSCLE_TONE',
  ALTERATIONS_IN_NUMERICAL_COGNITION_AND_COMPUTATION = 'ALTERATIONS_IN_NUMERICAL_COGNITION_AND_COMPUTATION',
  ALTERATIONS_IN_PERCEPTION = 'ALTERATIONS_IN_PERCEPTION',
  ALTERATIONS_IN_POSTURE = 'ALTERATIONS_IN_POSTURE',
  ALTERATIONS_IN_SENSE_OF_SMELL_OR_TASTE = 'ALTERATIONS_IN_SENSE_OF_SMELL_OR_TASTE',
  ALTERATIONS_IN_SOCIAL_COGNITION = 'ALTERATIONS_IN_SOCIAL_COGNITION',
  ALTERATIONS_IN_THE_MENSTRUAL_CYCLE = 'ALTERATIONS_IN_THE_MENSTRUAL_CYCLE',
  ALTERATIONS_IN_THE_SENSE_OF_SMELL_OR_TASTE = 'ALTERATIONS_IN_THE_SENSE_OF_SMELL_OR_TASTE',
  ALTERATIONS_IN_THE_SHAPE_OF_THE_CHEST = 'ALTERATIONS_IN_THE_SHAPE_OF_THE_CHEST',
  ALTERATIONS_OF_THE_SALIVARY_OR_LACRIMAL_GLANDS_AS_IN_SJ_GREN_S_SYNDROME = 'ALTERATIONS_OF_THE_SALIVARY_OR_LACRIMAL_GLANDS_AS_IN_SJ_GREN_S_SYNDROME',
  ALTERED_BLOOD_VALUES = 'ALTERED_BLOOD_VALUES',
  ALVEOLAR_HYPOVENTILATION_SYNDROME_DYSPNEA_DAYTIME_SLEEPINESS = 'ALVEOLAR_HYPOVENTILATION_SYNDROME_DYSPNEA_DAYTIME_SLEEPINESS',
  ALZHEIMER_S_DISEASE = 'ALZHEIMER_S_DISEASE',
  AMENORRHEA_LACK_OF_MENSTRUATION = 'AMENORRHEA_LACK_OF_MENSTRUATION',
  ANAL_ITCHING = 'ANAL_ITCHING',
  ANAL_ITCHING_OR_IRRITATION = 'ANAL_ITCHING_OR_IRRITATION',
  ANEMIA = 'ANEMIA',
  ANEURYSM_FORMATION_SWELLING_OR_DILATATION_OF_A_BLOOD_VESSEL = 'ANEURYSM_FORMATION_SWELLING_OR_DILATATION_OF_A_BLOOD_VESSEL',
  ANGINA_PECTORIS = 'ANGINA_PECTORIS',
  ANGINA_PECTORIS_CHEST_PAIN = 'ANGINA_PECTORIS_CHEST_PAIN',
  ANOMIA_DIFFICULTY_FINDING_WORDS = 'ANOMIA_DIFFICULTY_FINDING_WORDS',
  ANTERIOR_HIP_PAIN = 'ANTERIOR_HIP_PAIN',
  ANTERIOR_SHOULDER_PAIN = 'ANTERIOR_SHOULDER_PAIN',
  ANURIA_ABSENCE_OF_URINE_PRODUCTION = 'ANURIA_ABSENCE_OF_URINE_PRODUCTION',
  ANXIETY = 'ANXIETY',
  ANXIETY_OR_DEPRESSIVE_SYMPTOMS = 'ANXIETY_OR_DEPRESSIVE_SYMPTOMS',
  ANXIETY_OR_NERVOUSNESS = 'ANXIETY_OR_NERVOUSNESS',
  ANXIETY_WORRY = 'ANXIETY_WORRY',
  APHASIA_DIFFICULTY_IN_SPOKEN_AND_WRITTEN_LANGUAGE = 'APHASIA_DIFFICULTY_IN_SPOKEN_AND_WRITTEN_LANGUAGE',
  APHONIA_TOTAL_OR_PARTIAL_LOSS_OF_VOICE = 'APHONIA_TOTAL_OR_PARTIAL_LOSS_OF_VOICE',
  APLASTIC_ANEMIA = 'APLASTIC_ANEMIA',
  APNEA_SLEEP_DISORDER = 'APNEA_SLEEP_DISORDER',
  APPEARANCE_OF_HAIR = 'APPEARANCE_OF_HAIR',
  ARM_PAIN_ASSOCIATED_WITH_VASCULAR_PROBLEMS = 'ARM_PAIN_ASSOCIATED_WITH_VASCULAR_PROBLEMS',
  ARTERIAL_EMBOLISM = 'ARTERIAL_EMBOLISM',
  ARTHRITIS = 'ARTHRITIS',
  ASBESTOSIS_COUGH_DYSPNEA_RESPIRATORY_FAILURE = 'ASBESTOSIS_COUGH_DYSPNEA_RESPIRATORY_FAILURE',
  ASSESSMENT_AND_MANAGEMENT_OF_PREEXISTING_MEDICAL_CONDITIONS_IN_ATHLETES = 'ASSESSMENT_AND_MANAGEMENT_OF_PREEXISTING_MEDICAL_CONDITIONS_IN_ATHLETES',
  ASSESSMENT_AND_MANAGEMENT_OF_RISKS_RELATED_TO_THE_ENVIRONMENT_IN_WHICH_THE_SPORTS_ACTIVITY_TAKES_PLACE_SUCH_AS_TEMPERATURE_ALTITUDE_ETC = 'ASSESSMENT_AND_MANAGEMENT_OF_RISKS_RELATED_TO_THE_ENVIRONMENT_IN_WHICH_THE_SPORTS_ACTIVITY_TAKES_PLACE_SUCH_AS_TEMPERATURE_ALTITUDE_ETC',
  ASSESSMENT_AND_MANAGEMENT_OF_RISK_FACTORS_FOR_SPORTS_RELATED_INJURIES_OR_DISEASES = 'ASSESSMENT_AND_MANAGEMENT_OF_RISK_FACTORS_FOR_SPORTS_RELATED_INJURIES_OR_DISEASES',
  ASSESSMENT_AND_MANAGEMENT_OF_THE_RISK_OF_COMPLICATIONS = 'ASSESSMENT_AND_MANAGEMENT_OF_THE_RISK_OF_COMPLICATIONS',
  ASSESSMENT_OF_BRAIN_FUNCTION = 'ASSESSMENT_OF_BRAIN_FUNCTION',
  ASSESSMENT_OF_CARDIAC_FUNCTION = 'ASSESSMENT_OF_CARDIAC_FUNCTION',
  ASSESSMENT_OF_CHANGES_IN_BONE_STRUCTURE_SUCH_AS_OSTEOPOROSIS = 'ASSESSMENT_OF_CHANGES_IN_BONE_STRUCTURE_SUCH_AS_OSTEOPOROSIS',
  ASSESSMENT_OF_CIRCULATION_AND_TISSUE_PERFUSION = 'ASSESSMENT_OF_CIRCULATION_AND_TISSUE_PERFUSION',
  ASSESSMENT_OF_CONGENITAL_OR_ACQUIRED_DISORDERS = 'ASSESSMENT_OF_CONGENITAL_OR_ACQUIRED_DISORDERS',
  ASSESSMENT_OF_DEPTH_AND_SIZE_OF_ULCERS = 'ASSESSMENT_OF_DEPTH_AND_SIZE_OF_ULCERS',
  ASSESSMENT_OF_PHYSICAL_CONDITION_FOR_SPORTING_ACTIVITY = 'ASSESSMENT_OF_PHYSICAL_CONDITION_FOR_SPORTING_ACTIVITY',
  ASSESSMENT_OF_PHYSICAL_FITNESS_FOR_SPORTING_ACTIVITY_IN_INDIVIDUALS_WITH_COMPLEX_MEDICAL_CONDITIONS = 'ASSESSMENT_OF_PHYSICAL_FITNESS_FOR_SPORTING_ACTIVITY_IN_INDIVIDUALS_WITH_COMPLEX_MEDICAL_CONDITIONS',
  ASSESSMENT_OF_PULMONARY_FUNCTION = 'ASSESSMENT_OF_PULMONARY_FUNCTION',
  ASSESSMENT_OF_RENAL_FUNCTION = 'ASSESSMENT_OF_RENAL_FUNCTION',
  ASSESSMENT_OF_SALIVARY_GLAND_FUNCTION = 'ASSESSMENT_OF_SALIVARY_GLAND_FUNCTION',
  ASSESSMENT_OF_THE_FUNCTION_OF_THE_LYMPHATIC_SYSTEM = 'ASSESSMENT_OF_THE_FUNCTION_OF_THE_LYMPHATIC_SYSTEM',
  ASTHMA_COUGH_DYSPNEA_WHEEZING_CHEST_OPPRESSION = 'ASTHMA_COUGH_DYSPNEA_WHEEZING_CHEST_OPPRESSION',
  ASYMMETRICAL_BREASTS = 'ASYMMETRICAL_BREASTS',
  ATAXIA = 'ATAXIA',
  ATAXIA_DIFFICULTY_IN_COORDINATING_MOVEMENTS = 'ATAXIA_DIFFICULTY_IN_COORDINATING_MOVEMENTS',
  ATHLETIC_PERFORMANCE_MONITORING_AND_TRAINING_SCHEDULING = 'ATHLETIC_PERFORMANCE_MONITORING_AND_TRAINING_SCHEDULING',
  ATTENTION_AND_CONCENTRATION_PROBLEMS = 'ATTENTION_AND_CONCENTRATION_PROBLEMS',
  ATTENTION_DEFICIT_AND_IMPULSIVITY_DISORDERS_ADHD = 'ATTENTION_DEFICIT_AND_IMPULSIVITY_DISORDERS_ADHD',
  ATTENTION_DEFICIT_HYPERACTIVITY_DISORDER_ADHD = 'ATTENTION_DEFICIT_HYPERACTIVITY_DISORDER_ADHD',
  ATTENTION_DEFICIT_HYPERACTIVITY_DISORDER_ADHD_VARIANT_2 = 'ATTENTION_DEFICIT_HYPERACTIVITY_DISORDER_ADHD_VARIANT_2',
  ATTENTION_DEFICIT_OR_HYPERACTIVITY_DISORDER = 'ATTENTION_DEFICIT_OR_HYPERACTIVITY_DISORDER',
  AUTISM_AND_AUTISM_SPECTRUM_DISORDERS_ASD = 'AUTISM_AND_AUTISM_SPECTRUM_DISORDERS_ASD',
  AUTISM_SPECTRUM_DISORDERS_ASD = 'AUTISM_SPECTRUM_DISORDERS_ASD',
  AUTISM_SPECTRUM_DISORDER_ASD = 'AUTISM_SPECTRUM_DISORDER_ASD',
  AUTOIMMUNE_DISEASES_SUCH_AS_RHEUMATOID_ARTHRITIS_SYSTEMIC_LUPUS_ERYTHEMATOSUS = 'AUTOIMMUNE_DISEASES_SUCH_AS_RHEUMATOID_ARTHRITIS_SYSTEMIC_LUPUS_ERYTHEMATOSUS',
  AUTOIMMUNE_KIDNEY_DISEASE_SUCH_AS_GLOMERULONEPHRITIS = 'AUTOIMMUNE_KIDNEY_DISEASE_SUCH_AS_GLOMERULONEPHRITIS',
  A_LOT_OF_PEEING = 'A_LOT_OF_PEEING',
  BACK_HAND_PAIN = 'BACK_HAND_PAIN',
  BACK_OR_NECK_PAIN = 'BACK_OR_NECK_PAIN',
  BACK_PAIN = 'BACK_PAIN',
  BACK_PAIN_ASSOCIATED_WITH_FEVER_OR_KIDNEY_PAIN = 'BACK_PAIN_ASSOCIATED_WITH_FEVER_OR_KIDNEY_PAIN',
  BACK_PAIN_DURING_MOVEMENT = 'BACK_PAIN_DURING_MOVEMENT',
  BACK_PAIN_VARIANT_2 = 'BACK_PAIN_VARIANT_2',
  BACK_PAIN_VARIANT_3 = 'BACK_PAIN_VARIANT_3',
  BACK_PAIN_WITH_LOSS_OF_CONTROL_OF_URINE_OR_STOOL = 'BACK_PAIN_WITH_LOSS_OF_CONTROL_OF_URINE_OR_STOOL',
  BACTERIAL_VAGINOSIS = 'BACTERIAL_VAGINOSIS',
  BAD_PERFORMANCE_BAD_PERFORMANCE = 'BAD_PERFORMANCE_BAD_PERFORMANCE',
  BALANCE_AND_COORDINATION_PROBLEMS = 'BALANCE_AND_COORDINATION_PROBLEMS',
  BALANCE_DISORDERS_DIZZINESS_LABYRINTHITIS = 'BALANCE_DISORDERS_DIZZINESS_LABYRINTHITIS',
  BALANCE_DISORDERS_OR_DIZZINESS = 'BALANCE_DISORDERS_OR_DIZZINESS',
  BALANCE_PROBLEMS = 'BALANCE_PROBLEMS',
  BARRETT_S_ESOPHAGUS_PRECANCEROUS = 'BARRETT_S_ESOPHAGUS_PRECANCEROUS',
  BARTTER_S_SYNDROME = 'BARTTER_S_SYNDROME',
  BED_SORE_BEDDING = 'BED_SORE_BEDDING',
  BEHAVIORAL_OR_MENTAL_HEALTH_PROBLEMS = 'BEHAVIORAL_OR_MENTAL_HEALTH_PROBLEMS',
  BEHAVIOR_DISORDERS_IN_REM_SLEEP = 'BEHAVIOR_DISORDERS_IN_REM_SLEEP',
  BEH_ET_S_SYNDROME_COUGH_DYSPNEA_HEMOPTYSIS = 'BEH_ET_S_SYNDROME_COUGH_DYSPNEA_HEMOPTYSIS',
  BELLYACHE = 'BELLYACHE',
  BENIGN_TUMORS_FOR_GROWTH_CONTROL = 'BENIGN_TUMORS_FOR_GROWTH_CONTROL',
  BEREAVEMENT_STRESS_DISORDER = 'BEREAVEMENT_STRESS_DISORDER',
  BIG_BULGING_EYES = 'BIG_BULGING_EYES',
  BIG_HANDS = 'BIG_HANDS',
  BIG_HEAD_INCREASED_HEAD_CIRCUMFERENCE = 'BIG_HEAD_INCREASED_HEAD_CIRCUMFERENCE',
  BILIARY_TRACT_DISORDERS_SUCH_AS_PRIMARY_SCLEROSING_CHOLANGITIS = 'BILIARY_TRACT_DISORDERS_SUCH_AS_PRIMARY_SCLEROSING_CHOLANGITIS',
  BIPOLAR_MOOD_DISORDER = 'BIPOLAR_MOOD_DISORDER',
  BLACK_OR_WHITE_SPOTS_ON_THE_SKIN_BLACKHEADS = 'BLACK_OR_WHITE_SPOTS_ON_THE_SKIN_BLACKHEADS',
  BLADDER_OR_BOWEL_CONTROL_PROBLEMS = 'BLADDER_OR_BOWEL_CONTROL_PROBLEMS',
  BLADDER_TUMORS = 'BLADDER_TUMORS',
  BLEEDING_DISORDERS = 'BLEEDING_DISORDERS',
  BLEEDING_OF_THE_GUMS = 'BLEEDING_OF_THE_GUMS',
  BLEEDING_OF_VARIOUS_KINDS = 'BLEEDING_OF_VARIOUS_KINDS',
  BLEEDING_WITH_MENSTRUATION = 'BLEEDING_WITH_MENSTRUATION',
  BLINDNESS_NOT_SEEING = 'BLINDNESS_NOT_SEEING',
  BLISTERS_OR_SKIN_BLISTERS = 'BLISTERS_OR_SKIN_BLISTERS',
  BLOATING_OR_SWELLING_IN_THE_ABDOMINAL_AREA = 'BLOATING_OR_SWELLING_IN_THE_ABDOMINAL_AREA',
  BLOOD_AND_BONE_MARROW_DISEASES = 'BLOOD_AND_BONE_MARROW_DISEASES',
  BLOOD_EXPECTORATION = 'BLOOD_EXPECTORATION',
  BLOOD_IN_STOOL = 'BLOOD_IN_STOOL',
  BLOOD_IN_STOOL_BLOOD_WHEN_I_GO_TO_THE_TOILET = 'BLOOD_IN_STOOL_BLOOD_WHEN_I_GO_TO_THE_TOILET',
  BLOOD_IN_THE_COUGH = 'BLOOD_IN_THE_COUGH',
  BLOOD_IN_THE_URINE_HEMATURIA = 'BLOOD_IN_THE_URINE_HEMATURIA',
  BLOOD_IN_URINE = 'BLOOD_IN_URINE',
  BLOOD_IN_WOUNDS_OR_ULCERS = 'BLOOD_IN_WOUNDS_OR_ULCERS',
  BLOOD_PRESSURE_OR_HEART_RATE_PROBLEMS = 'BLOOD_PRESSURE_OR_HEART_RATE_PROBLEMS',
  BLOOD_PRESSURE_PROBLEMS_OR_PALPITATIONS = 'BLOOD_PRESSURE_PROBLEMS_OR_PALPITATIONS',
  BLOOD_VESSEL_NARROWING_NEPHROPATHY = 'BLOOD_VESSEL_NARROWING_NEPHROPATHY',
  BLURRED_OR_FUZZY_VISION = 'BLURRED_OR_FUZZY_VISION',
  BLURRED_VISION = 'BLURRED_VISION',
  BLURRED_VISION_OR_DIFFICULTY_ADAPTING_TO_DARKNESS = 'BLURRED_VISION_OR_DIFFICULTY_ADAPTING_TO_DARKNESS',
  BLURRED_VISION_OR_VISUAL_DISTURBANCES = 'BLURRED_VISION_OR_VISUAL_DISTURBANCES',
  BODY_DYSMORPHIC_DISORDER_BDD = 'BODY_DYSMORPHIC_DISORDER_BDD',
  BODY_PROSTHESIS_CORRECTION_SURGERY = 'BODY_PROSTHESIS_CORRECTION_SURGERY',
  BODY_RESHAPING_SURGERY = 'BODY_RESHAPING_SURGERY',
  BONE_FRACTURES_OR_INJURIES = 'BONE_FRACTURES_OR_INJURIES',
  BONE_GROWTH_DISORDERS_E_G_SCOLIOSIS = 'BONE_GROWTH_DISORDERS_E_G_SCOLIOSIS',
  BONE_OR_JOINT_DEFORMITY = 'BONE_OR_JOINT_DEFORMITY',
  BONE_OR_JOINT_PAIN = 'BONE_OR_JOINT_PAIN',
  BONE_OR_MUSCULOSKELETAL_SYSTEM_PROBLEMS = 'BONE_OR_MUSCULOSKELETAL_SYSTEM_PROBLEMS',
  BONE_OR_SOFT_PART_TUMORS = 'BONE_OR_SOFT_PART_TUMORS',
  BONE_PAIN = 'BONE_PAIN',
  BONE_PAIN_VARIANT_2 = 'BONE_PAIN_VARIANT_2',
  BONE_TUMORS = 'BONE_TUMORS',
  BOWEL_IRREGULARITY = 'BOWEL_IRREGULARITY',
  BOWEL_MOVEMENTS = 'BOWEL_MOVEMENTS',
  BRAIN_PERFUSION_STUDIES = 'BRAIN_PERFUSION_STUDIES',
  BREAST_ASYMMETRY = 'BREAST_ASYMMETRY',
  BREAST_AUGMENTATION_SURGERY_ADDITIVE_MASTOPLASTY = 'BREAST_AUGMENTATION_SURGERY_ADDITIVE_MASTOPLASTY',
  BREAST_CANCER_EVALUATION_AND_COUNSELING = 'BREAST_CANCER_EVALUATION_AND_COUNSELING',
  BREAST_DEFECTS_OR_MALFORMATIONS = 'BREAST_DEFECTS_OR_MALFORMATIONS',
  BREAST_IMPLANT_CORRECTION_SURGERY = 'BREAST_IMPLANT_CORRECTION_SURGERY',
  BREAST_ITCHING = 'BREAST_ITCHING',
  BREAST_KNOT_OR_MASS = 'BREAST_KNOT_OR_MASS',
  BREAST_LESION_WOUND_CYST = 'BREAST_LESION_WOUND_CYST',
  BREAST_PAIN = 'BREAST_PAIN',
  BREAST_PAIN_OR_TENDERNESS = 'BREAST_PAIN_OR_TENDERNESS',
  BREAST_REDUCTION_SURGERY_REDUCTION_MAMMAPLASTY = 'BREAST_REDUCTION_SURGERY_REDUCTION_MAMMAPLASTY',
  BREAST_TUMORS = 'BREAST_TUMORS',
  BREATHING_DISORDERS_DURING_PHONATION = 'BREATHING_DISORDERS_DURING_PHONATION',
  BRIEF_PSYCHOTIC_DISORDER = 'BRIEF_PSYCHOTIC_DISORDER',
  BROKEN_KNEE_LIGAMENT = 'BROKEN_KNEE_LIGAMENT',
  BRONCHIECTASIS_CHRONIC_COUGH_SPUTUM_PRODUCTION = 'BRONCHIECTASIS_CHRONIC_COUGH_SPUTUM_PRODUCTION',
  BRUXISM_TEETH_GRINDING = 'BRUXISM_TEETH_GRINDING',
  BUDS_CONGENITAL_ANOMALIES_OF_THE_FACE = 'BUDS_CONGENITAL_ANOMALIES_OF_THE_FACE',
  BULGES = 'BULGES',
  BURNING_OR_ITCHING_SENSATION_IN_THE_ANUS = 'BURNING_OR_ITCHING_SENSATION_IN_THE_ANUS',
  BURNING_SENSATION_IN_THE_EXTREMITIES = 'BURNING_SENSATION_IN_THE_EXTREMITIES',
  BURNING_SENSATION_OR_PULSATING_PAIN = 'BURNING_SENSATION_OR_PULSATING_PAIN',
  CALCIUM_DEPLETED_KIDNEY_DISEASE_NEPHROPATHY = 'CALCIUM_DEPLETED_KIDNEY_DISEASE_NEPHROPATHY',
  CALCULATION_DISORDERS_DYSARITHMETRIA = 'CALCULATION_DISORDERS_DYSARITHMETRIA',
  CALF_MUSCLE_PAIN = 'CALF_MUSCLE_PAIN',
  CANCER_IN_VARIOUS_ORGANS_AND_TISSUES = 'CANCER_IN_VARIOUS_ORGANS_AND_TISSUES',
  CANCER_MALIGNANT_TUMORS = 'CANCER_MALIGNANT_TUMORS',
  CARDIAC_ARRHYTHMIAS = 'CARDIAC_ARRHYTHMIAS',
  CARDIAC_OR_RESPIRATORY_PROBLEMS_DURING_PHYSICAL_ACTIVITY = 'CARDIAC_OR_RESPIRATORY_PROBLEMS_DURING_PHYSICAL_ACTIVITY',
  CARDIAC_SYMPTOMS_SUCH_AS_CHEST_PAIN = 'CARDIAC_SYMPTOMS_SUCH_AS_CHEST_PAIN',
  CARDIOLOGICAL_OR_HEART_HEALTH_PROBLEMS = 'CARDIOLOGICAL_OR_HEART_HEALTH_PROBLEMS',
  CARDIOPALMO_HEART_IN_THE_THROAT = 'CARDIOPALMO_HEART_IN_THE_THROAT',
  CARDIOVASCULAR_OR_CIRCULATORY_HEALTH_PROBLEMS = 'CARDIOVASCULAR_OR_CIRCULATORY_HEALTH_PROBLEMS',
  CARDIOVASCULAR_PROBLEMS_HYPERTENSION_HEART_FAILURE = 'CARDIOVASCULAR_PROBLEMS_HYPERTENSION_HEART_FAILURE',
  CARPAL_TUNNEL_SYNDROME = 'CARPAL_TUNNEL_SYNDROME',
  CELIAC_DISEASE = 'CELIAC_DISEASE',
  CENTRAL_NERVOUS_SYSTEM_DISORDERS = 'CENTRAL_NERVOUS_SYSTEM_DISORDERS',
  CEREBRAL_VENOUS_THROMBOSIS = 'CEREBRAL_VENOUS_THROMBOSIS',
  CHANGED_VOICE = 'CHANGED_VOICE',
  CHANGES_IN_APPETITE = 'CHANGES_IN_APPETITE',
  CHANGES_IN_APPETITE_OR_ENERGY_LEVELS = 'CHANGES_IN_APPETITE_OR_ENERGY_LEVELS',
  CHANGES_IN_AUTONOMIC_FUNCTIONS_BLOOD_PRESSURE_HEART_RATE = 'CHANGES_IN_AUTONOMIC_FUNCTIONS_BLOOD_PRESSURE_HEART_RATE',
  CHANGES_IN_BEHAVIOR = 'CHANGES_IN_BEHAVIOR',
  CHANGES_IN_BLOOD_COMPOSITION_SUCH_AS_ANEMIA_OR_PLATELETOPENIA = 'CHANGES_IN_BLOOD_COMPOSITION_SUCH_AS_ANEMIA_OR_PLATELETOPENIA',
  CHANGES_IN_BLOOD_PRESSURE = 'CHANGES_IN_BLOOD_PRESSURE',
  CHANGES_IN_BLOOD_PRESSURE_OR_HEART_RATE = 'CHANGES_IN_BLOOD_PRESSURE_OR_HEART_RATE',
  CHANGES_IN_BLOOD_SUGAR_LEVELS_HYPERGLYCEMIA_OR_HYPOGLYCEMIA = 'CHANGES_IN_BLOOD_SUGAR_LEVELS_HYPERGLYCEMIA_OR_HYPOGLYCEMIA',
  CHANGES_IN_BODY_TEMPERATURE_OR_SWEATING = 'CHANGES_IN_BODY_TEMPERATURE_OR_SWEATING',
  CHANGES_IN_BOWEL_HABITS = 'CHANGES_IN_BOWEL_HABITS',
  CHANGES_IN_EYE_HEAD_COORDINATION = 'CHANGES_IN_EYE_HEAD_COORDINATION',
  CHANGES_IN_HEART_RATE_OR_BLOOD_PRESSURE = 'CHANGES_IN_HEART_RATE_OR_BLOOD_PRESSURE',
  CHANGES_IN_LIBIDO = 'CHANGES_IN_LIBIDO',
  CHANGES_IN_LIBIDO_VARIANT_2 = 'CHANGES_IN_LIBIDO_VARIANT_2',
  CHANGES_IN_LOCAL_SKIN_TEMPERATURE = 'CHANGES_IN_LOCAL_SKIN_TEMPERATURE',
  CHANGES_IN_MENSTRUAL_CYCLE_OR_AMENORRHEA_IN_WOMEN = 'CHANGES_IN_MENSTRUAL_CYCLE_OR_AMENORRHEA_IN_WOMEN',
  CHANGES_IN_MOLES_OR_FRECKLES = 'CHANGES_IN_MOLES_OR_FRECKLES',
  CHANGES_IN_MOLES_OR_SOFT_TISSUE = 'CHANGES_IN_MOLES_OR_SOFT_TISSUE',
  CHANGES_IN_MOOD_OR_BEHAVIOR = 'CHANGES_IN_MOOD_OR_BEHAVIOR',
  CHANGES_IN_MOOD_OR_BEHAVIOR_VARIANT_2 = 'CHANGES_IN_MOOD_OR_BEHAVIOR_VARIANT_2',
  CHANGES_IN_MOOD_OR_BEHAVIOR_VARIANT_3 = 'CHANGES_IN_MOOD_OR_BEHAVIOR_VARIANT_3',
  CHANGES_IN_MOOD_OR_CONCENTRATION = 'CHANGES_IN_MOOD_OR_CONCENTRATION',
  CHANGES_IN_NAIL_PIGMENTATION = 'CHANGES_IN_NAIL_PIGMENTATION',
  CHANGES_IN_PERCEPTION_OF_PAIN_OR_DISCOMFORT = 'CHANGES_IN_PERCEPTION_OF_PAIN_OR_DISCOMFORT',
  CHANGES_IN_PERCEPTION_OF_THE_SURROUNDING_WORLD = 'CHANGES_IN_PERCEPTION_OF_THE_SURROUNDING_WORLD',
  CHANGES_IN_PERSONALITY_AND_BEHAVIOR = 'CHANGES_IN_PERSONALITY_AND_BEHAVIOR',
  CHANGES_IN_PERSONALITY_OR_BEHAVIOR = 'CHANGES_IN_PERSONALITY_OR_BEHAVIOR',
  CHANGES_IN_POSTURE_OR_WALKING = 'CHANGES_IN_POSTURE_OR_WALKING',
  CHANGES_IN_RESPONSE_TO_AND_CONTROL_OF_EMOTIONS = 'CHANGES_IN_RESPONSE_TO_AND_CONTROL_OF_EMOTIONS',
  CHANGES_IN_RESPONSE_TO_SENSORY_STIMULI = 'CHANGES_IN_RESPONSE_TO_SENSORY_STIMULI',
  CHANGES_IN_SELF_REGULATION_AND_SELF_CONTROL = 'CHANGES_IN_SELF_REGULATION_AND_SELF_CONTROL',
  CHANGES_IN_SENSE_OF_SMELL_OR_TASTE = 'CHANGES_IN_SENSE_OF_SMELL_OR_TASTE',
  CHANGES_IN_SENSITIVITY_TO_LIGHT_OR_SOUND = 'CHANGES_IN_SENSITIVITY_TO_LIGHT_OR_SOUND',
  CHANGES_IN_SENSITIVITY_TO_MOVEMENT_OR_TOUCH = 'CHANGES_IN_SENSITIVITY_TO_MOVEMENT_OR_TOUCH',
  CHANGES_IN_SENSITIVITY_TO_PAIN_OR_TEMPERATURE = 'CHANGES_IN_SENSITIVITY_TO_PAIN_OR_TEMPERATURE',
  CHANGES_IN_SKIN_OR_EXTREMITY_COLOR_SUCH_AS_PALLOR_OR_CYANOSIS = 'CHANGES_IN_SKIN_OR_EXTREMITY_COLOR_SUCH_AS_PALLOR_OR_CYANOSIS',
  CHANGES_IN_SKIN_PIGMENTATION = 'CHANGES_IN_SKIN_PIGMENTATION',
  CHANGES_IN_SKIN_STRUCTURE_THICKENING_LICHENIFICATION = 'CHANGES_IN_SKIN_STRUCTURE_THICKENING_LICHENIFICATION',
  CHANGES_IN_SKIN_TEMPERATURE_OR_COLOR = 'CHANGES_IN_SKIN_TEMPERATURE_OR_COLOR',
  CHANGES_IN_SKIN_TEMPERATURE_OR_COLOR_TO_THE_LEGS = 'CHANGES_IN_SKIN_TEMPERATURE_OR_COLOR_TO_THE_LEGS',
  CHANGES_IN_THE_ABILITY_TO_RECOGNIZE_OBJECTS_OR_FACES = 'CHANGES_IN_THE_ABILITY_TO_RECOGNIZE_OBJECTS_OR_FACES',
  CHANGES_IN_THE_ADRENAL_GLANDS_OR_CORTICOSURRENALS = 'CHANGES_IN_THE_ADRENAL_GLANDS_OR_CORTICOSURRENALS',
  CHANGES_IN_THE_COLOR_OR_TEXTURE_OF_THE_BREAST_SKIN = 'CHANGES_IN_THE_COLOR_OR_TEXTURE_OF_THE_BREAST_SKIN',
  CHANGES_IN_THE_COLOR_SHAPE_OR_SIZE_OF_THE_TESTICLES = 'CHANGES_IN_THE_COLOR_SHAPE_OR_SIZE_OF_THE_TESTICLES',
  CHANGES_IN_THE_FUNCTION_OF_SENSORY_ORGANS_SUCH_AS_SIGHT_OR_HEARING = 'CHANGES_IN_THE_FUNCTION_OF_SENSORY_ORGANS_SUCH_AS_SIGHT_OR_HEARING',
  CHANGES_IN_THE_NAILS_DEFORMITIES_STREAKS_CHANGES_IN_COLOR = 'CHANGES_IN_THE_NAILS_DEFORMITIES_STREAKS_CHANGES_IN_COLOR',
  CHANGES_IN_THE_NAILS_OR_SKIN = 'CHANGES_IN_THE_NAILS_OR_SKIN',
  CHANGES_IN_THE_PERCEPTION_OF_TASTES_OR_SMELLS = 'CHANGES_IN_THE_PERCEPTION_OF_TASTES_OR_SMELLS',
  CHANGES_IN_THE_PERCEPTION_OF_TEMPERATURE_OR_TOUCH = 'CHANGES_IN_THE_PERCEPTION_OF_TEMPERATURE_OR_TOUCH',
  CHANGES_IN_THE_PERCEPTION_OF_TIME = 'CHANGES_IN_THE_PERCEPTION_OF_TIME',
  CHANGES_IN_THE_PUPIL_SIZE_SHAPE = 'CHANGES_IN_THE_PUPIL_SIZE_SHAPE',
  CHANGES_IN_THE_SEX_GLANDS_OVARIES_OR_TESTES = 'CHANGES_IN_THE_SEX_GLANDS_OVARIES_OR_TESTES',
  CHANGES_IN_THE_SKIN_OR_HAIR = 'CHANGES_IN_THE_SKIN_OR_HAIR',
  CHANGES_IN_THE_VOICE_OR_VOCAL_CORDS = 'CHANGES_IN_THE_VOICE_OR_VOCAL_CORDS',
  CHANGES_IN_URINARY_HABITS_OR_FREQUENCY_IN_THE_ELDERLY = 'CHANGES_IN_URINARY_HABITS_OR_FREQUENCY_IN_THE_ELDERLY',
  CHANGES_IN_URINE_OR_STOOL_COLOR = 'CHANGES_IN_URINE_OR_STOOL_COLOR',
  CHANGES_IN_VISION_OR_HEARING = 'CHANGES_IN_VISION_OR_HEARING',
  CHANGES_IN_VISION_OR_VISUAL_DISTURBANCES = 'CHANGES_IN_VISION_OR_VISUAL_DISTURBANCES',
  CHANGES_IN_VOICE = 'CHANGES_IN_VOICE',
  CHANGE_IN_APPEARANCE_OF_STOOL = 'CHANGE_IN_APPEARANCE_OF_STOOL',
  CHANGE_IN_COLOR_OF_URINE_PEE = 'CHANGE_IN_COLOR_OF_URINE_PEE',
  CHANGE_IN_ROUTINE_STRESS_DISORDER = 'CHANGE_IN_ROUTINE_STRESS_DISORDER',
  CHANGE_OF_FACE = 'CHANGE_OF_FACE',
  CHANGE_OF_VOICE = 'CHANGE_OF_VOICE',
  CHEST_OR_ABDOMINAL_PAIN_ASSOCIATED_WITH_VASCULAR_DISORDERS = 'CHEST_OR_ABDOMINAL_PAIN_ASSOCIATED_WITH_VASCULAR_DISORDERS',
  CHEST_PAIN = 'CHEST_PAIN',
  CHEST_PAIN_IF_RELATED_TO_DIGESTIVE_PROBLEMS = 'CHEST_PAIN_IF_RELATED_TO_DIGESTIVE_PROBLEMS',
  CHEST_PAIN_OR_TENDERNESS = 'CHEST_PAIN_OR_TENDERNESS',
  CHEST_PAIN_RADIATING_TO_THE_LEFT_ARM = 'CHEST_PAIN_RADIATING_TO_THE_LEFT_ARM',
  CHEST_PAIN_RADIATING_TO_THE_LEFT_ARM_VARIANT_2 = 'CHEST_PAIN_RADIATING_TO_THE_LEFT_ARM_VARIANT_2',
  CHEST_PAIN_VARIANT_2 = 'CHEST_PAIN_VARIANT_2',
  CHEST_PAIN_VARIANT_3 = 'CHEST_PAIN_VARIANT_3',
  CHEST_PAIN_VARIANT_4 = 'CHEST_PAIN_VARIANT_4',
  CHEST_WEIGHT = 'CHEST_WEIGHT',
  CHEWING_OR_SWALLOWING_PROBLEMS = 'CHEWING_OR_SWALLOWING_PROBLEMS',
  CHEWING_PAIN = 'CHEWING_PAIN',
  CHILDHOOD_AND_ADOLESCENT_DISORDERS_ADHD_AUTISM_ETC = 'CHILDHOOD_AND_ADOLESCENT_DISORDERS_ADHD_AUTISM_ETC',
  CHILD_DOES_NOT_EAT = 'CHILD_DOES_NOT_EAT',
  CHILD_DOES_NOT_SOCIALIZE = 'CHILD_DOES_NOT_SOCIALIZE',
  CHILD_DOES_NOT_SPEAK = 'CHILD_DOES_NOT_SPEAK',
  CHILD_DOES_NOT_UNDERSTAND = 'CHILD_DOES_NOT_UNDERSTAND',
  CHILD_REMAINS_PLAY_ONLY = 'CHILD_REMAINS_PLAY_ONLY',
  CHIN_RESHAPING_SURGERY_MENTOPLASTY = 'CHIN_RESHAPING_SURGERY_MENTOPLASTY',
  CHOLECYST_PROBLEMS_SUCH_AS_GALLSTONES = 'CHOLECYST_PROBLEMS_SUCH_AS_GALLSTONES',
  CHRONIC_BRONCHITIS_PERSISTENT_COUGH_SPUTUM_PRODUCTION = 'CHRONIC_BRONCHITIS_PERSISTENT_COUGH_SPUTUM_PRODUCTION',
  CHRONIC_COUGH = 'CHRONIC_COUGH',
  CHRONIC_COUGH_ASSOCIATED_WITH_GASTROINTESTINAL_SYSTEM_PROBLEMS = 'CHRONIC_COUGH_ASSOCIATED_WITH_GASTROINTESTINAL_SYSTEM_PROBLEMS',
  CHRONIC_COUGH_OR_BLOOD_IN_PHLEGM = 'CHRONIC_COUGH_OR_BLOOD_IN_PHLEGM',
  CHRONIC_FATIGUE = 'CHRONIC_FATIGUE',
  CHRONIC_FATIGUE_SYNDROME = 'CHRONIC_FATIGUE_SYNDROME',
  CHRONIC_FATIGUE_SYNDROME_CFS = 'CHRONIC_FATIGUE_SYNDROME_CFS',
  CHRONIC_FATIGUE_VARIANT_2 = 'CHRONIC_FATIGUE_VARIANT_2',
  CHRONIC_GASTROESOPHAGEAL_REFLUX_DISEASE = 'CHRONIC_GASTROESOPHAGEAL_REFLUX_DISEASE',
  CHRONIC_HEAD_NECK_OR_SHOULDER_PAIN_RELATED_TO_CRANIOFACIAL_STRUCTURE = 'CHRONIC_HEAD_NECK_OR_SHOULDER_PAIN_RELATED_TO_CRANIOFACIAL_STRUCTURE',
  CHRONIC_INFLAMMATION_OF_THE_BREAST_MASTITIS = 'CHRONIC_INFLAMMATION_OF_THE_BREAST_MASTITIS',
  CHRONIC_LYMPHOCYTIC_LEUKEMIA = 'CHRONIC_LYMPHOCYTIC_LEUKEMIA',
  CHRONIC_MUSCLE_OR_JOINT_PAIN = 'CHRONIC_MUSCLE_OR_JOINT_PAIN',
  CHRONIC_MYELOID_LEUKEMIA = 'CHRONIC_MYELOID_LEUKEMIA',
  CHRONIC_NEPHRITIC_SYNDROME = 'CHRONIC_NEPHRITIC_SYNDROME',
  CHRONIC_OR_PERSISTENT_DIARRHEA = 'CHRONIC_OR_PERSISTENT_DIARRHEA',
  CHRONIC_OR_RECURRENT_SKIN_LESIONS_SUCH_AS_SKIN_ULCERS = 'CHRONIC_OR_RECURRENT_SKIN_LESIONS_SUCH_AS_SKIN_ULCERS',
  CHRONIC_PAIN = 'CHRONIC_PAIN',
  CHRONIC_PAIN_ASSOCIATED_WITH_MUSCULOSKELETAL_DISORDERS = 'CHRONIC_PAIN_ASSOCIATED_WITH_MUSCULOSKELETAL_DISORDERS',
  CHRONIC_PAIN_ASSOCIATED_WITH_NEUROLOGICAL_CONDITIONS = 'CHRONIC_PAIN_ASSOCIATED_WITH_NEUROLOGICAL_CONDITIONS',
  CHRONIC_PAIN_ASSOCIATED_WITH_ORTHOPEDIC_CONDITIONS = 'CHRONIC_PAIN_ASSOCIATED_WITH_ORTHOPEDIC_CONDITIONS',
  CHRONIC_PAIN_OR_ARTHRALGIA = 'CHRONIC_PAIN_OR_ARTHRALGIA',
  CHRONIC_PAIN_PROBLEMS_RELATED_TO_MUSCULAR_OR_NEUROLOGICAL_DISORDERS = 'CHRONIC_PAIN_PROBLEMS_RELATED_TO_MUSCULAR_OR_NEUROLOGICAL_DISORDERS',
  CHRONIC_PELVIC_PAIN = 'CHRONIC_PELVIC_PAIN',
  CHRONIC_RENAL_FAILURE_SYNDROME = 'CHRONIC_RENAL_FAILURE_SYNDROME',
  CHRONIC_SWELLING_OR_EDEMA = 'CHRONIC_SWELLING_OR_EDEMA',
  CHRONIC_VENOUS_INSUFFICIENCY_SUCH_AS_VARICES = 'CHRONIC_VENOUS_INSUFFICIENCY_SUCH_AS_VARICES',
  CHRONIC_WOUND_RELATED_PAIN_OR_DISCOMFORT = 'CHRONIC_WOUND_RELATED_PAIN_OR_DISCOMFORT',
  CHURG_STRAUSS_SYNDROME_COUGH_DYSPNEA_LUNG_INFILTRATES = 'CHURG_STRAUSS_SYNDROME_COUGH_DYSPNEA_LUNG_INFILTRATES',
  CIRCULATION_DISORDERS_IN_THE_HANDS_OR_FEET = 'CIRCULATION_DISORDERS_IN_THE_HANDS_OR_FEET',
  CLIMACTERIC_SYMPTOMS_CALORINI_NIGHT_SWEATS = 'CLIMACTERIC_SYMPTOMS_CALORINI_NIGHT_SWEATS',
  CLOTTING_OR_BLEEDING_DISORDERS = 'CLOTTING_OR_BLEEDING_DISORDERS',
  COLDS = 'COLDS',
  COLD_HANDS_COLD_EXTREMITIES = 'COLD_HANDS_COLD_EXTREMITIES',
  COLD_INTOLERANCE = 'COLD_INTOLERANCE',
  COLD_SORES_OR_SHINGLES = 'COLD_SORES_OR_SHINGLES',
  COLON_PROBLEMS_SUCH_AS_ULCERATIVE_COLITIS_OR_CROHN_S_DISEASE = 'COLON_PROBLEMS_SUCH_AS_ULCERATIVE_COLITIS_OR_CROHN_S_DISEASE',
  COMMUNICATION_AND_LANGUAGE_PROBLEMS_IN_CHILDREN = 'COMMUNICATION_AND_LANGUAGE_PROBLEMS_IN_CHILDREN',
  COMMUNICATION_DISORDERS_IN_DOWN_SYNDROME_AND_OTHER_GENETIC_CONDITIONS = 'COMMUNICATION_DISORDERS_IN_DOWN_SYNDROME_AND_OTHER_GENETIC_CONDITIONS',
  COMMUNICATION_DISORDERS_IN_PATIENTS_WITH_AUTISM_SPECTRUM_DISORDERS_ASD = 'COMMUNICATION_DISORDERS_IN_PATIENTS_WITH_AUTISM_SPECTRUM_DISORDERS_ASD',
  COMMUNICATION_DISORDERS_IN_PATIENTS_WITH_AUTISM_SPECTRUM_DISORDERS_ASD_VARIANT_2 = 'COMMUNICATION_DISORDERS_IN_PATIENTS_WITH_AUTISM_SPECTRUM_DISORDERS_ASD_VARIANT_2',
  COMMUNICATION_OR_LANGUAGE_PROBLEMS = 'COMMUNICATION_OR_LANGUAGE_PROBLEMS',
  COMMUNICATION_PROBLEMS_IN_PATIENTS_WITH_COGNITIVE_DISORDERS_SUCH_AS_DEMENTIA = 'COMMUNICATION_PROBLEMS_IN_PATIENTS_WITH_COGNITIVE_DISORDERS_SUCH_AS_DEMENTIA',
  COMMUNICATION_PROBLEMS_IN_PATIENTS_WITH_SENSORY_DISABILITIES_SUCH_AS_DEAFNESS_OR_HEARING_LOSS = 'COMMUNICATION_PROBLEMS_IN_PATIENTS_WITH_SENSORY_DISABILITIES_SUCH_AS_DEAFNESS_OR_HEARING_LOSS',
  COMPLICATED_PULMONARY_INFECTIONS = 'COMPLICATED_PULMONARY_INFECTIONS',
  COMPLICATIONS_FROM_CHRONIC_MUSCULAR_OR_NEUROLOGICAL_DISEASES = 'COMPLICATIONS_FROM_CHRONIC_MUSCULAR_OR_NEUROLOGICAL_DISEASES',
  COMPLICATIONS_FROM_ORTHOPEDIC_OR_RHEUMATOLOGIC_DISEASES = 'COMPLICATIONS_FROM_ORTHOPEDIC_OR_RHEUMATOLOGIC_DISEASES',
  COMPLICATIONS_FROM_PREVIOUS_SURGERY_OR_INVASIVE_PROCEDURES = 'COMPLICATIONS_FROM_PREVIOUS_SURGERY_OR_INVASIVE_PROCEDURES',
  COMPLICATIONS_FROM_TRAUMA_OR_INJURY = 'COMPLICATIONS_FROM_TRAUMA_OR_INJURY',
  COMPLICATIONS_OF_PREVIOUS_VASCULAR_SURGERY = 'COMPLICATIONS_OF_PREVIOUS_VASCULAR_SURGERY',
  COMPLICATIONS_RELATED_TO_THE_USE_OF_SUBSTANCES_OR_DOPANTS = 'COMPLICATIONS_RELATED_TO_THE_USE_OF_SUBSTANCES_OR_DOPANTS',
  COMPRESSIVE_SYMPTOMS_CAUSED_BY_TUMORS_IN_CRITICAL_REGIONS = 'COMPRESSIVE_SYMPTOMS_CAUSED_BY_TUMORS_IN_CRITICAL_REGIONS',
  COMPROMISES_IN_AUTONOMIC_NERVOUS_SYSTEM_FUNCTION_SUCH_AS_PROBLEMS_CONTROLLING_BLOOD_PRESSURE_OR_HEART_RATE = 'COMPROMISES_IN_AUTONOMIC_NERVOUS_SYSTEM_FUNCTION_SUCH_AS_PROBLEMS_CONTROLLING_BLOOD_PRESSURE_OR_HEART_RATE',
  COMPROMISES_IN_AWARENESS_OF_ONE_S_BODY_AND_ABILITIES = 'COMPROMISES_IN_AWARENESS_OF_ONE_S_BODY_AND_ABILITIES',
  COMPROMISES_IN_SENSORY_FUNCTIONS = 'COMPROMISES_IN_SENSORY_FUNCTIONS',
  COMPROMISES_IN_SPATIOTEMPORAL_ORIENTATION = 'COMPROMISES_IN_SPATIOTEMPORAL_ORIENTATION',
  COMPROMISES_IN_THE_FUNCTION_OF_SPECIFIC_ORGANS = 'COMPROMISES_IN_THE_FUNCTION_OF_SPECIFIC_ORGANS',
  COMPROMISES_IN_THE_FUNCTION_OR_MOVEMENT_OF_THE_LOWER_OR_UPPER_LIMBS = 'COMPROMISES_IN_THE_FUNCTION_OR_MOVEMENT_OF_THE_LOWER_OR_UPPER_LIMBS',
  COMPROMISES_IN_UNDERSTANDING_METAPHORS_AND_FIGURATIVE_LANGUAGE = 'COMPROMISES_IN_UNDERSTANDING_METAPHORS_AND_FIGURATIVE_LANGUAGE',
  COMPULSIVE_HOARDING_DISORDER_HOARDING_DISORDER = 'COMPULSIVE_HOARDING_DISORDER_HOARDING_DISORDER',
  COMPULSORY_BEHAVIORS = 'COMPULSORY_BEHAVIORS',
  CONFLICT_OF_VALUES_STRESS_DISORDER = 'CONFLICT_OF_VALUES_STRESS_DISORDER',
  CONFUSION = 'CONFUSION',
  CONFUSION_DISORIENTATION = 'CONFUSION_DISORIENTATION',
  CONFUSION_OR_COGNITIVE_IMPAIRMENT = 'CONFUSION_OR_COGNITIVE_IMPAIRMENT',
  CONGENITAL_DEFORMITIES = 'CONGENITAL_DEFORMITIES',
  CONGENITAL_MALFORMATIONS_SUCH_AS_FISTULAS_BETWEEN_THE_ESOPHAGUS_AND_TRACHEA = 'CONGENITAL_MALFORMATIONS_SUCH_AS_FISTULAS_BETWEEN_THE_ESOPHAGUS_AND_TRACHEA',
  CONGESTIVE_HEART_FAILURE = 'CONGESTIVE_HEART_FAILURE',
  CONJUNCTIVITIS = 'CONJUNCTIVITIS',
  CONJUNCTIVITIS_RED_EYES = 'CONJUNCTIVITIS_RED_EYES',
  CONSTANT_FATIGUE = 'CONSTANT_FATIGUE',
  CONSTANT_FEELING_OF_HUNGER = 'CONSTANT_FEELING_OF_HUNGER',
  CONSTIPATION = 'CONSTIPATION',
  CONSTIPATION_VARIANT_2 = 'CONSTIPATION_VARIANT_2',
  CONSTIPATION_VARIANT_3 = 'CONSTIPATION_VARIANT_3',
  CONSULTATIONS_FOR_BREAST_RECONSTRUCTION_SURGERY = 'CONSULTATIONS_FOR_BREAST_RECONSTRUCTION_SURGERY',
  CONSULTING_ON_ERGONOMICS_AND_BIOMECHANICS_IN_SPORTING_ACTIVITY = 'CONSULTING_ON_ERGONOMICS_AND_BIOMECHANICS_IN_SPORTING_ACTIVITY',
  CONTRAST_NEPHROPATHY = 'CONTRAST_NEPHROPATHY',
  CONTROL_OF_CHRONIC_CONDITIONS_SUCH_AS_CYSTIC_FIBROSIS = 'CONTROL_OF_CHRONIC_CONDITIONS_SUCH_AS_CYSTIC_FIBROSIS',
  CONTROL_OF_DIAGNOSTIC_OR_THERAPEUTIC_PROCEDURES = 'CONTROL_OF_DIAGNOSTIC_OR_THERAPEUTIC_PROCEDURES',
  CONTROL_OF_DISEASES_OF_THE_REPRODUCTIVE_SYSTEM = 'CONTROL_OF_DISEASES_OF_THE_REPRODUCTIVE_SYSTEM',
  CONTROL_OF_INFLAMMATORY_CONDITIONS_SUCH_AS_RHEUMATOID_ARTHRITIS = 'CONTROL_OF_INFLAMMATORY_CONDITIONS_SUCH_AS_RHEUMATOID_ARTHRITIS',
  CONTROL_OF_NEUROLOGICAL_CONDITIONS_SUCH_AS_MULTIPLE_SCLEROSIS = 'CONTROL_OF_NEUROLOGICAL_CONDITIONS_SUCH_AS_MULTIPLE_SCLEROSIS',
  CONTROL_OF_PREVIOUS_SURGERIES = 'CONTROL_OF_PREVIOUS_SURGERIES',
  CONTROL_OF_VASCULAR_DISEASES_SUCH_AS_THROMBOSIS = 'CONTROL_OF_VASCULAR_DISEASES_SUCH_AS_THROMBOSIS',
  CONVULSIONS = 'CONVULSIONS',
  COORDINATION_DISORDERS = 'COORDINATION_DISORDERS',
  COPD_COUGH_DYSPNEA_SPUTUM_PRODUCTION = 'COPD_COUGH_DYSPNEA_SPUTUM_PRODUCTION',
  CORRECTION_OF_BURN_SCARS = 'CORRECTION_OF_BURN_SCARS',
  CORRECTION_OF_CONGENITAL_DEFECTS_OF_THE_ABDOMEN = 'CORRECTION_OF_CONGENITAL_DEFECTS_OF_THE_ABDOMEN',
  CORRECTION_OF_CONGENITAL_DEFORMITIES_OF_THE_TRUNK = 'CORRECTION_OF_CONGENITAL_DEFORMITIES_OF_THE_TRUNK',
  CORRECTION_OF_CONGENITAL_MUSCLE_DEFORMITIES = 'CORRECTION_OF_CONGENITAL_MUSCLE_DEFORMITIES',
  CORRECTION_OF_FACIAL_FRACTURES = 'CORRECTION_OF_FACIAL_FRACTURES',
  CORRECTION_OF_FEMALE_GENITAL_DEFECTS = 'CORRECTION_OF_FEMALE_GENITAL_DEFECTS',
  CORRECTION_OF_GENITAL_MALFORMATIONS = 'CORRECTION_OF_GENITAL_MALFORMATIONS',
  CORRECTION_OF_MALE_GENITAL_DEFECTS = 'CORRECTION_OF_MALE_GENITAL_DEFECTS',
  CORRECTION_OF_SCARS_FROM_PREVIOUS_SURGERIES = 'CORRECTION_OF_SCARS_FROM_PREVIOUS_SURGERIES',
  CORRECTION_OF_SOFT_TISSUE_MALFORMATIONS_OF_THE_FACE = 'CORRECTION_OF_SOFT_TISSUE_MALFORMATIONS_OF_THE_FACE',
  COSMETIC_SURGERY_E_G_FACE_LIFT_RHINOPLASTY = 'COSMETIC_SURGERY_E_G_FACE_LIFT_RHINOPLASTY',
  COUGH = 'COUGH',
  COUGHING_WITH_BLOOD = 'COUGHING_WITH_BLOOD',
  COUNSELING_ON_THE_SAFE_USE_OF_DRUGS_AND_SUPPLEMENTS_IN_SPORTS = 'COUNSELING_ON_THE_SAFE_USE_OF_DRUGS_AND_SUPPLEMENTS_IN_SPORTS',
  CRAMPS_MUSCLE_PAIN = 'CRAMPS_MUSCLE_PAIN',
  CRANIOFACIAL_MALFORMATIONS = 'CRANIOFACIAL_MALFORMATIONS',
  CROOKED_CANINE_INCISORS_MOLAR_TEETH = 'CROOKED_CANINE_INCISORS_MOLAR_TEETH',
  CROOKED_EYES = 'CROOKED_EYES',
  CUTANEOUS_VASCULITIS_INFLAMMATION_OF_CUTANEOUS_BLOOD_VESSELS = 'CUTANEOUS_VASCULITIS_INFLAMMATION_OF_CUTANEOUS_BLOOD_VESSELS',
  CYANOSIS_BLUISH_DISCOLORATION_OF_THE_SKIN = 'CYANOSIS_BLUISH_DISCOLORATION_OF_THE_SKIN',
  CYANOSIS_BLUISH_DISCOLORATION_OF_THE_SKIN_OR_MUCOUS_MEMBRANES = 'CYANOSIS_BLUISH_DISCOLORATION_OF_THE_SKIN_OR_MUCOUS_MEMBRANES',
  CYSTIC_FIBROSIS_CHRONIC_COUGH_DYSPNEA_RESPIRATORY_INFECTIONS = 'CYSTIC_FIBROSIS_CHRONIC_COUGH_DYSPNEA_RESPIRATORY_INFECTIONS',
  CYSTS = 'CYSTS',
  CYSTS_OR_TUMORS_OF_THE_JAW_OR_FACE = 'CYSTS_OR_TUMORS_OF_THE_JAW_OR_FACE',
  CYSTS_OR_TUMORS_OF_THE_NECK_OR_HEAD = 'CYSTS_OR_TUMORS_OF_THE_NECK_OR_HEAD',
  DARK_FECES_BLACK_FECES = 'DARK_FECES_BLACK_FECES',
  DARK_SPOTS_OR_HYPERPIGMENTATION = 'DARK_SPOTS_OR_HYPERPIGMENTATION',
  DARK_URINE = 'DARK_URINE',
  DAYTIME_SLEEPINESS = 'DAYTIME_SLEEPINESS',
  DEAD_FABRICS = 'DEAD_FABRICS',
  DEAFNESS_HEARING_LOSS = 'DEAFNESS_HEARING_LOSS',
  DECLINE_IN_ATHLETIC_PERFORMANCE_OR_LACK_OF_PROGRESS = 'DECLINE_IN_ATHLETIC_PERFORMANCE_OR_LACK_OF_PROGRESS',
  DECREASED_APPETITE = 'DECREASED_APPETITE',
  DECREASED_BLOOD_PRESSURE = 'DECREASED_BLOOD_PRESSURE',
  DECREASED_SEXUAL_DESIRE = 'DECREASED_SEXUAL_DESIRE',
  DECREASED_SKIN_SENSITIVITY = 'DECREASED_SKIN_SENSITIVITY',
  DECREASE_OR_ABSENCE_OF_ARTERIAL_PULSES_IN_THE_EXTREMITIES = 'DECREASE_OR_ABSENCE_OF_ARTERIAL_PULSES_IN_THE_EXTREMITIES',
  DEEP_VEIN_THROMBOSIS_DVT = 'DEEP_VEIN_THROMBOSIS_DVT',
  DEFECTS_MALFORMATIONS_OF_THE_NOSE = 'DEFECTS_MALFORMATIONS_OF_THE_NOSE',
  DEFORMITIES_OR_STRUCTURAL_PROBLEMS_AFFECTING_PERFORMANCE = 'DEFORMITIES_OR_STRUCTURAL_PROBLEMS_AFFECTING_PERFORMANCE',
  DELAYED_OR_IMPAIRED_LANGUAGE_DEVELOPMENT_IN_CHILDREN = 'DELAYED_OR_IMPAIRED_LANGUAGE_DEVELOPMENT_IN_CHILDREN',
  DELAY_IN_LANGUAGE_OR_COMMUNICATION_DEVELOPMENT = 'DELAY_IN_LANGUAGE_OR_COMMUNICATION_DEVELOPMENT',
  DELAY_IN_PUBERTY_OR_SEXUAL_DEVELOPMENT = 'DELAY_IN_PUBERTY_OR_SEXUAL_DEVELOPMENT',
  DELAY_IN_REACHING_THE_STAGES_OF_LANGUAGE_DEVELOPMENT_IN_CHILDREN = 'DELAY_IN_REACHING_THE_STAGES_OF_LANGUAGE_DEVELOPMENT_IN_CHILDREN',
  DELAY_IN_SPEECH_OR_LANGUAGE_DEVELOPMENT = 'DELAY_IN_SPEECH_OR_LANGUAGE_DEVELOPMENT',
  DEMYELINATING_DISEASES = 'DEMYELINATING_DISEASES',
  DENTAL_CARIES = 'DENTAL_CARIES',
  DENTAL_INFECTIONS = 'DENTAL_INFECTIONS',
  DENTAL_MALOCCLUSION = 'DENTAL_MALOCCLUSION',
  DENTAL_MALOCCLUSION_CROOKED_OR_MISALIGNED_TEETH = 'DENTAL_MALOCCLUSION_CROOKED_OR_MISALIGNED_TEETH',
  DENTAL_MOBILITY = 'DENTAL_MOBILITY',
  DENTAL_OCCLUSION_PROBLEMS_ABNORMAL_BITE = 'DENTAL_OCCLUSION_PROBLEMS_ABNORMAL_BITE',
  DENTAL_OR_FACIAL_BONE_FRACTURES = 'DENTAL_OR_FACIAL_BONE_FRACTURES',
  DENTAL_OR_GUM_PAIN = 'DENTAL_OR_GUM_PAIN',
  DENTAL_PROBLEMS_OR_DRY_MOUTH = 'DENTAL_PROBLEMS_OR_DRY_MOUTH',
  DENTAL_PROBLEMS_PERIODONTITIS_CARIES = 'DENTAL_PROBLEMS_PERIODONTITIS_CARIES',
  DENTAL_SENSITIVITY = 'DENTAL_SENSITIVITY',
  DENTURES_OR_DENTAL_IMPLANTS = 'DENTURES_OR_DENTAL_IMPLANTS',
  DEPRESSION = 'DEPRESSION',
  DEPRESSION_OR_MOOD_CHANGES = 'DEPRESSION_OR_MOOD_CHANGES',
  DEPRESSION_OR_PERSISTENT_SADNESS = 'DEPRESSION_OR_PERSISTENT_SADNESS',
  DEPRESSION_OR_SOCIAL_ISOLATION = 'DEPRESSION_OR_SOCIAL_ISOLATION',
  DERMATITIS_OR_SKIN_IRRITATION_ASSOCIATED_WITH_ULCERS = 'DERMATITIS_OR_SKIN_IRRITATION_ASSOCIATED_WITH_ULCERS',
  DERMATOLOGICAL_OR_SKIN_HEALTH_PROBLEMS = 'DERMATOLOGICAL_OR_SKIN_HEALTH_PROBLEMS',
  DERMATOLOGICAL_OR_SKIN_PROBLEMS = 'DERMATOLOGICAL_OR_SKIN_PROBLEMS',
  DERMATOLOGICAL_PROBLEMS_DRY_SKIN_SKIN_LESIONS = 'DERMATOLOGICAL_PROBLEMS_DRY_SKIN_SKIN_LESIONS',
  DERMATOLOGICAL_PROBLEMS_PSORIASIS_DERMATITIS = 'DERMATOLOGICAL_PROBLEMS_PSORIASIS_DERMATITIS',
  DETECTION_OF_INFECTION_AND_INFLAMMATION = 'DETECTION_OF_INFECTION_AND_INFLAMMATION',
  DETECTION_OF_METASTATIC_BONE_LESIONS = 'DETECTION_OF_METASTATIC_BONE_LESIONS',
  DETERIORATION_OF_VISION = 'DETERIORATION_OF_VISION',
  DIABETES_MELLITUS = 'DIABETES_MELLITUS',
  DIABETIC_NEPHROPATHY = 'DIABETIC_NEPHROPATHY',
  DIABETIC_NEUROPATHY = 'DIABETIC_NEUROPATHY',
  DIAPHRAGM_DISORDERS = 'DIAPHRAGM_DISORDERS',
  DIARRHEA = 'DIARRHEA',
  DIET_RELATED_DERMATOLOGICAL_DISORDERS = 'DIET_RELATED_DERMATOLOGICAL_DISORDERS',
  DIET_RELATED_ENDOCRINE_DISORDERS = 'DIET_RELATED_ENDOCRINE_DISORDERS',
  DIET_RELATED_MUSCULOSKELETAL_DISORDERS = 'DIET_RELATED_MUSCULOSKELETAL_DISORDERS',
  DIET_RELATED_SLEEP_DISORDERS = 'DIET_RELATED_SLEEP_DISORDERS',
  DIFFICULTIES_IN_ACQUIRING_GRAMMAR = 'DIFFICULTIES_IN_ACQUIRING_GRAMMAR',
  DIFFICULTIES_IN_BODY_PERCEPTION_AND_BODY_IMAGE = 'DIFFICULTIES_IN_BODY_PERCEPTION_AND_BODY_IMAGE',
  DIFFICULTIES_IN_EMOTIONAL_SELF_REGULATION = 'DIFFICULTIES_IN_EMOTIONAL_SELF_REGULATION',
  DIFFICULTIES_IN_FEEDING = 'DIFFICULTIES_IN_FEEDING',
  DIFFICULTIES_IN_GROWTH_OR_DEVELOPMENT = 'DIFFICULTIES_IN_GROWTH_OR_DEVELOPMENT',
  DIFFICULTIES_IN_HAND_EYE_COORDINATION = 'DIFFICULTIES_IN_HAND_EYE_COORDINATION',
  DIFFICULTIES_IN_MANAGING_ACTIVITIES_OF_DAILY_LIVING_ADL = 'DIFFICULTIES_IN_MANAGING_ACTIVITIES_OF_DAILY_LIVING_ADL',
  DIFFICULTIES_IN_MANAGING_EMOTIONS = 'DIFFICULTIES_IN_MANAGING_EMOTIONS',
  DIFFICULTIES_IN_MANAGING_INSTRUMENTAL_ACTIVITIES_OF_DAILY_LIVING_IADL = 'DIFFICULTIES_IN_MANAGING_INSTRUMENTAL_ACTIVITIES_OF_DAILY_LIVING_IADL',
  DIFFICULTIES_IN_MOBILITY_RELATED_TO_INJURY_OR_AMPUTATION = 'DIFFICULTIES_IN_MOBILITY_RELATED_TO_INJURY_OR_AMPUTATION',
  DIFFICULTIES_IN_PLANNING_AND_ORGANIZATION = 'DIFFICULTIES_IN_PLANNING_AND_ORGANIZATION',
  DIFFICULTIES_IN_RECOGNITION_OF_FACES_AND_OBJECTS = 'DIFFICULTIES_IN_RECOGNITION_OF_FACES_AND_OBJECTS',
  DIFFICULTIES_IN_RECOVERY_AFTER_INJURY_OR_SURGERY = 'DIFFICULTIES_IN_RECOVERY_AFTER_INJURY_OR_SURGERY',
  DIFFICULTIES_IN_SOCIALIZATION = 'DIFFICULTIES_IN_SOCIALIZATION',
  DIFFICULTIES_IN_THE_USE_OF_GESTURES_OR_BODY_LANGUAGE = 'DIFFICULTIES_IN_THE_USE_OF_GESTURES_OR_BODY_LANGUAGE',
  DIFFICULTIES_IN_VERBAL_AND_NONVERBAL_COMMUNICATION = 'DIFFICULTIES_IN_VERBAL_AND_NONVERBAL_COMMUNICATION',
  DIFFICULTIES_IN_VERBAL_AND_NONVERBAL_REASONING = 'DIFFICULTIES_IN_VERBAL_AND_NONVERBAL_REASONING',
  DIFFICULTIES_IN_WALKING_OR_MOTOR_DEVELOPMENT = 'DIFFICULTIES_IN_WALKING_OR_MOTOR_DEVELOPMENT',
  DIFFICULTIES_IN_WOUND_HEALING = 'DIFFICULTIES_IN_WOUND_HEALING',
  DIFFICULTY_ARTICULATING_SOUNDS_DUE_TO_ANATOMICAL_PROBLEMS_SUCH_AS_CLEFT_LIP_AND_PALATE = 'DIFFICULTY_ARTICULATING_SOUNDS_DUE_TO_ANATOMICAL_PROBLEMS_SUCH_AS_CLEFT_LIP_AND_PALATE',
  DIFFICULTY_ARTICULATING_WORDS_SUCH_AS_SIGMATISM = 'DIFFICULTY_ARTICULATING_WORDS_SUCH_AS_SIGMATISM',
  DIFFICULTY_BREATHING_AT_NIGHT = 'DIFFICULTY_BREATHING_AT_NIGHT',
  DIFFICULTY_BREATHING_AT_NIGHT_VARIANT_2 = 'DIFFICULTY_BREATHING_AT_NIGHT_VARIANT_2',
  DIFFICULTY_BREATHING_THROUGH_THE_NOSE = 'DIFFICULTY_BREATHING_THROUGH_THE_NOSE',
  DIFFICULTY_CHEWING_OR_OPENING_THE_MOUTH = 'DIFFICULTY_CHEWING_OR_OPENING_THE_MOUTH',
  DIFFICULTY_CONCENTRATING_OR_LEARNING = 'DIFFICULTY_CONCENTRATING_OR_LEARNING',
  DIFFICULTY_CONCENTRATING_OR_LEARNING_VARIANT_2 = 'DIFFICULTY_CONCENTRATING_OR_LEARNING_VARIANT_2',
  DIFFICULTY_DIGESTING_CAN_T_DIGEST = 'DIFFICULTY_DIGESTING_CAN_T_DIGEST',
  DIFFICULTY_GOING_TO_THE_BATHROOM = 'DIFFICULTY_GOING_TO_THE_BATHROOM',
  DIFFICULTY_HEARING_DEAFNESS = 'DIFFICULTY_HEARING_DEAFNESS',
  DIFFICULTY_INITIATING_URINATION_URINARY_RETENTION = 'DIFFICULTY_INITIATING_URINATION_URINARY_RETENTION',
  DIFFICULTY_IN_ADAPTING_TO_NEW_SITUATIONS = 'DIFFICULTY_IN_ADAPTING_TO_NEW_SITUATIONS',
  DIFFICULTY_IN_AUDITORY_DISCRIMINATION_OF_SPEECH_SOUNDS = 'DIFFICULTY_IN_AUDITORY_DISCRIMINATION_OF_SPEECH_SOUNDS',
  DIFFICULTY_IN_BREATHING = 'DIFFICULTY_IN_BREATHING',
  DIFFICULTY_IN_BREATHING_AND_PHONATION_AFTER_SURGERY_OR_INJURY_TO_THE_LARYNX = 'DIFFICULTY_IN_BREATHING_AND_PHONATION_AFTER_SURGERY_OR_INJURY_TO_THE_LARYNX',
  DIFFICULTY_IN_CONCENTRATION = 'DIFFICULTY_IN_CONCENTRATION',
  DIFFICULTY_IN_CONCENTRATION_AND_ATTENTION = 'DIFFICULTY_IN_CONCENTRATION_AND_ATTENTION',
  DIFFICULTY_IN_CONCENTRATION_OR_MEMORY = 'DIFFICULTY_IN_CONCENTRATION_OR_MEMORY',
  DIFFICULTY_IN_FEEDING_OR_SWALLOWING = 'DIFFICULTY_IN_FEEDING_OR_SWALLOWING',
  DIFFICULTY_IN_FINE_MOTOR_COORDINATION_AND_PERFORMING_DAILY_ACTIVITIES = 'DIFFICULTY_IN_FINE_MOTOR_COORDINATION_AND_PERFORMING_DAILY_ACTIVITIES',
  DIFFICULTY_IN_FOCUSING = 'DIFFICULTY_IN_FOCUSING',
  DIFFICULTY_IN_MOTOR_COORDINATION = 'DIFFICULTY_IN_MOTOR_COORDINATION',
  DIFFICULTY_IN_PRODUCING_SPEECH_SOUNDS_DUE_TO_PARALYSIS_OR_NEUROLOGICAL_INJURY = 'DIFFICULTY_IN_PRODUCING_SPEECH_SOUNDS_DUE_TO_PARALYSIS_OR_NEUROLOGICAL_INJURY',
  DIFFICULTY_IN_READING_DYSLEXIA = 'DIFFICULTY_IN_READING_DYSLEXIA',
  DIFFICULTY_IN_REGULATING_BODY_TEMPERATURE = 'DIFFICULTY_IN_REGULATING_BODY_TEMPERATURE',
  DIFFICULTY_IN_SEEING_UP_CLOSE = 'DIFFICULTY_IN_SEEING_UP_CLOSE',
  DIFFICULTY_IN_SPEAKING = 'DIFFICULTY_IN_SPEAKING',
  DIFFICULTY_IN_SPEECH_OR_ELOCUTION = 'DIFFICULTY_IN_SPEECH_OR_ELOCUTION',
  DIFFICULTY_IN_SPOKEN_WORD_OR_COMPREHENSION_OF_LANGUAGE = 'DIFFICULTY_IN_SPOKEN_WORD_OR_COMPREHENSION_OF_LANGUAGE',
  DIFFICULTY_IN_SWALLOWING = 'DIFFICULTY_IN_SWALLOWING',
  DIFFICULTY_IN_SWALLOWING_DYSPHAGIA = 'DIFFICULTY_IN_SWALLOWING_DYSPHAGIA',
  DIFFICULTY_IN_THE_ABILITY_TO_PLAN_FOR_THE_FUTURE = 'DIFFICULTY_IN_THE_ABILITY_TO_PLAN_FOR_THE_FUTURE',
  DIFFICULTY_IN_UNDERSTANDING = 'DIFFICULTY_IN_UNDERSTANDING',
  DIFFICULTY_IN_UNDERSTANDING_LANGUAGE = 'DIFFICULTY_IN_UNDERSTANDING_LANGUAGE',
  DIFFICULTY_IN_UNDERSTANDING_THE_EMOTIONS_OF_OTHERS = 'DIFFICULTY_IN_UNDERSTANDING_THE_EMOTIONS_OF_OTHERS',
  DIFFICULTY_IN_URINATION_OR_EVACUATION = 'DIFFICULTY_IN_URINATION_OR_EVACUATION',
  DIFFICULTY_IN_WALKING = 'DIFFICULTY_IN_WALKING',
  DIFFICULTY_IN_WALKING_OR_BALANCE = 'DIFFICULTY_IN_WALKING_OR_BALANCE',
  DIFFICULTY_IN_WALKING_OR_GAIT = 'DIFFICULTY_IN_WALKING_OR_GAIT',
  DIFFICULTY_IN_WRITING_DYSORTHOGRAPHY = 'DIFFICULTY_IN_WRITING_DYSORTHOGRAPHY',
  DIFFICULTY_MOVING_A_LIMB = 'DIFFICULTY_MOVING_A_LIMB',
  DIFFICULTY_OF_MOVEMENT = 'DIFFICULTY_OF_MOVEMENT',
  DIFFICULTY_OF_VISION = 'DIFFICULTY_OF_VISION',
  DIFFICULTY_OR_PAIN_IN_SWALLOWING = 'DIFFICULTY_OR_PAIN_IN_SWALLOWING',
  DIFFICULTY_SEEING_AT_NIGHT = 'DIFFICULTY_SEEING_AT_NIGHT',
  DIFFICULTY_SEEING_FROM_A_DISTANCE = 'DIFFICULTY_SEEING_FROM_A_DISTANCE',
  DIFFICULTY_SWALLOWING = 'DIFFICULTY_SWALLOWING',
  DIFFICULTY_SWALLOWING_DYSPHAGIA = 'DIFFICULTY_SWALLOWING_DYSPHAGIA',
  DIFFICULTY_SWALLOWING_LIQUIDS_OR_SOLID_FOODS = 'DIFFICULTY_SWALLOWING_LIQUIDS_OR_SOLID_FOODS',
  DIFFICULTY_SWALLOWING_SOLID_FOOD = 'DIFFICULTY_SWALLOWING_SOLID_FOOD',
  DIFFICULTY_SWALLOWING_VARIANT_2 = 'DIFFICULTY_SWALLOWING_VARIANT_2',
  DIFFICULTY_UNDERSTANDING_PEOPLE_WHEN_THEY_SPEAK = 'DIFFICULTY_UNDERSTANDING_PEOPLE_WHEN_THEY_SPEAK',
  DIFFICULTY_WALKING_OR_SUPPORTING_BODY_WEIGHT = 'DIFFICULTY_WALKING_OR_SUPPORTING_BODY_WEIGHT',
  DIFFUSE_BONE_PAIN = 'DIFFUSE_BONE_PAIN',
  DIGESTION_PROBLEMS_OR_DIFFICULTY_EATING = 'DIGESTION_PROBLEMS_OR_DIFFICULTY_EATING',
  DIGESTIVE_DISORDERS = 'DIGESTIVE_DISORDERS',
  DIRTY_WOUND = 'DIRTY_WOUND',
  DISEASES_OF_THE_BONE_SYSTEM_INCLUDING_OSTEOPOROSIS = 'DISEASES_OF_THE_BONE_SYSTEM_INCLUDING_OSTEOPOROSIS',
  DISEASES_OF_THE_DIAPHRAGM = 'DISEASES_OF_THE_DIAPHRAGM',
  DISEASES_OF_THE_ENDOMETRIUM_SUCH_AS_ENDOMETRIOSIS = 'DISEASES_OF_THE_ENDOMETRIUM_SUCH_AS_ENDOMETRIOSIS',
  DISEASES_OF_THE_GASTROINTESTINAL_SYSTEM = 'DISEASES_OF_THE_GASTROINTESTINAL_SYSTEM',
  DISEASES_OF_THE_PULMONARY_SYSTEM = 'DISEASES_OF_THE_PULMONARY_SYSTEM',
  DISEASES_OF_THE_RECTUM_AND_ANUS_SUCH_AS_HEMORRHOIDS_OR_ANAL_FISSURES = 'DISEASES_OF_THE_RECTUM_AND_ANUS_SUCH_AS_HEMORRHOIDS_OR_ANAL_FISSURES',
  DISORDERS_IN_THE_PERCEPTION_OF_TIME = 'DISORDERS_IN_THE_PERCEPTION_OF_TIME',
  DISORDERS_OF_BLADDER_OR_BOWEL_FUNCTION_RELATED_TO_NEUROLOGICAL_OR_MUSCULAR_CONDITIONS = 'DISORDERS_OF_BLADDER_OR_BOWEL_FUNCTION_RELATED_TO_NEUROLOGICAL_OR_MUSCULAR_CONDITIONS',
  DISORDERS_OF_COAGULATION_OR_THROMBOSIS = 'DISORDERS_OF_COAGULATION_OR_THROMBOSIS',
  DISORDERS_OF_COLOR_PERCEPTION = 'DISORDERS_OF_COLOR_PERCEPTION',
  DISORDERS_OF_FEEDING_AND_FLUID_INTAKE = 'DISORDERS_OF_FEEDING_AND_FLUID_INTAKE',
  DISORDERS_OF_FEEDING_OR_FLUID_INTAKE = 'DISORDERS_OF_FEEDING_OR_FLUID_INTAKE',
  DISORDERS_OF_RESPIRATORY_FUNCTION = 'DISORDERS_OF_RESPIRATORY_FUNCTION',
  DISORDERS_OF_SENSORY_PERCEPTION_HYPERSENSITIVITY_OR_HYPOSENSITIVITY = 'DISORDERS_OF_SENSORY_PERCEPTION_HYPERSENSITIVITY_OR_HYPOSENSITIVITY',
  DISORDERS_OF_THE_AUTONOMIC_NERVOUS_SYSTEM = 'DISORDERS_OF_THE_AUTONOMIC_NERVOUS_SYSTEM',
  DISORDERS_OF_THE_CARDIOVASCULAR_SYSTEM = 'DISORDERS_OF_THE_CARDIOVASCULAR_SYSTEM',
  DISORDERS_OF_THE_GASTROINTESTINAL_TRACT_OR_DIGESTIVE_SYSTEM = 'DISORDERS_OF_THE_GASTROINTESTINAL_TRACT_OR_DIGESTIVE_SYSTEM',
  DISORDERS_OF_THE_MALE_GENITAL_SYSTEM_IN_BOYS_AT_PUBERTAL_AGE = 'DISORDERS_OF_THE_MALE_GENITAL_SYSTEM_IN_BOYS_AT_PUBERTAL_AGE',
  DISORDERS_OF_THE_RESPIRATORY_SYSTEM_OR_RESPIRATORY_SYSTEM = 'DISORDERS_OF_THE_RESPIRATORY_SYSTEM_OR_RESPIRATORY_SYSTEM',
  DISORDERS_OF_THE_TONGUE_OR_PALATE = 'DISORDERS_OF_THE_TONGUE_OR_PALATE',
  DISORDERS_OF_THE_VASCULAR_SYSTEM = 'DISORDERS_OF_THE_VASCULAR_SYSTEM',
  DISORDERS_OF_THE_VISUAL_APPARATUS = 'DISORDERS_OF_THE_VISUAL_APPARATUS',
  DISORDERS_OF_THE_VULVA = 'DISORDERS_OF_THE_VULVA',
  DISORDERS_OF_VESTIBULAR_FUNCTION_DIZZINESS_AND_IMBALANCE = 'DISORDERS_OF_VESTIBULAR_FUNCTION_DIZZINESS_AND_IMBALANCE',
  DISSOCIATIVE_IDENTITY_DISORDER_DID = 'DISSOCIATIVE_IDENTITY_DISORDER_DID',
  DISTURBANCES_IN_THE_SHAPE_OR_SIZE_OF_THE_EYES = 'DISTURBANCES_IN_THE_SHAPE_OR_SIZE_OF_THE_EYES',
  DIZZINESS = 'DIZZINESS',
  DIZZINESS_OR_BALANCE_PROBLEMS = 'DIZZINESS_OR_BALANCE_PROBLEMS',
  DIZZINESS_OR_FEELING_DIZZY = 'DIZZINESS_OR_FEELING_DIZZY',
  DON_T_HEAR_DON_T_FEEL = 'DON_T_HEAR_DON_T_FEEL',
  DON_T_UNDERSTAND_DON_T_UNDERSTAND_DON_T_UNDERSTAND = 'DON_T_UNDERSTAND_DON_T_UNDERSTAND_DON_T_UNDERSTAND',
  DORSAL_FOOT_PAIN = 'DORSAL_FOOT_PAIN',
  DOUBLE_VISION_DIPLOPIA = 'DOUBLE_VISION_DIPLOPIA',
  DRUG_ADDICTION = 'DRUG_ADDICTION',
  DRUG_RELATED_NEPHROPATHY = 'DRUG_RELATED_NEPHROPATHY',
  DRYNESS_OR_BURNING_OF_THE_EYES = 'DRYNESS_OR_BURNING_OF_THE_EYES',
  DRY_MOUTH_OR_ITCHING_AROUND_THE_GENITALS = 'DRY_MOUTH_OR_ITCHING_AROUND_THE_GENITALS',
  DRY_OR_ITCHY_SKIN = 'DRY_OR_ITCHY_SKIN',
  DYSARTHRIA_DIFFICULTY_IN_PRONOUNCING_SOUNDS = 'DYSARTHRIA_DIFFICULTY_IN_PRONOUNCING_SOUNDS',
  DYSFUNCTION_OF_THE_TEMPOROMANDIBULAR_JOINT_TMJ = 'DYSFUNCTION_OF_THE_TEMPOROMANDIBULAR_JOINT_TMJ',
  DYSFUNCTION_OF_VERBAL_AND_NONVERBAL_LEARNING_AND_MEMORY_SKILLS = 'DYSFUNCTION_OF_VERBAL_AND_NONVERBAL_LEARNING_AND_MEMORY_SKILLS',
  DYSLIPIDEMIA_ABNORMAL_LEVELS_OF_FAT_IN_THE_BLOOD = 'DYSLIPIDEMIA_ABNORMAL_LEVELS_OF_FAT_IN_THE_BLOOD',
  DYSMENORRHEA_PAINFUL_MENSTRUATION = 'DYSMENORRHEA_PAINFUL_MENSTRUATION',
  DYSPHONIA_VOICE_ALTERATION = 'DYSPHONIA_VOICE_ALTERATION',
  DYSPHONIA_VOICE_ALTERATIONS = 'DYSPHONIA_VOICE_ALTERATIONS',
  DYSPNEA_DIFFICULTY_BREATHING = 'DYSPNEA_DIFFICULTY_BREATHING',
  DYSPNEA_DIFFICULTY_IN_BREATHING = 'DYSPNEA_DIFFICULTY_IN_BREATHING',
  DYSTONIA = 'DYSTONIA',
  EAR_PAIN = 'EAR_PAIN',
  EAR_PAIN_VARIANT_2 = 'EAR_PAIN_VARIANT_2',
  EAR_PAIN_VARIANT_3 = 'EAR_PAIN_VARIANT_3',
  EAR_PAIN_WITH_DISCHARGE = 'EAR_PAIN_WITH_DISCHARGE',
  EAR_RESHAPING_SURGERY_OTOPLASTY = 'EAR_RESHAPING_SURGERY_OTOPLASTY',
  EASY_APPEARANCE_OF_BRUISING_OR_ECCHYMOSIS = 'EASY_APPEARANCE_OF_BRUISING_OR_ECCHYMOSIS',
  EASY_BONE_FRACTURES = 'EASY_BONE_FRACTURES',
  EATING_DISORDERS = 'EATING_DISORDERS',
  EATING_DISORDERS_ANOREXIA_BULIMIA_BINGE_EATING = 'EATING_DISORDERS_ANOREXIA_BULIMIA_BINGE_EATING',
  EATING_DISORDERS_ANOREXIA_BULIMIA_BINGE_EATING_VARIANT_2 = 'EATING_DISORDERS_ANOREXIA_BULIMIA_BINGE_EATING_VARIANT_2',
  EATING_DISORDERS_ANOREXIA_BULIMIA_PICA = 'EATING_DISORDERS_ANOREXIA_BULIMIA_PICA',
  EATING_DISORDERS_E_G_ANOREXIA_OR_BULIMIA = 'EATING_DISORDERS_E_G_ANOREXIA_OR_BULIMIA',
  EATING_DISORDERS_E_G_FOOD_SELECTIVITY = 'EATING_DISORDERS_E_G_FOOD_SELECTIVITY',
  EATING_DISORDERS_IN_CHILDREN_AND_ADOLESCENTS = 'EATING_DISORDERS_IN_CHILDREN_AND_ADOLESCENTS',
  EATING_DISORDERS_OR_BODY_IMAGE_DISORDERS = 'EATING_DISORDERS_OR_BODY_IMAGE_DISORDERS',
  EATING_DISORDER_ANOREXIA_BULIMIA = 'EATING_DISORDER_ANOREXIA_BULIMIA',
  EATING_DISORDER_EDNOS = 'EATING_DISORDER_EDNOS',
  ECCHYMOSIS = 'ECCHYMOSIS',
  ECHOLALIA_MECHANICAL_REPETITION_OF_WORDS_OR_PHRASES = 'ECHOLALIA_MECHANICAL_REPETITION_OF_WORDS_OR_PHRASES',
  EDEMA = 'EDEMA',
  EDEMA_SWELLING = 'EDEMA_SWELLING',
  EFFORT_TO_GO_TO_THE_BATHROOM = 'EFFORT_TO_GO_TO_THE_BATHROOM',
  ELBOW_PAIN = 'ELBOW_PAIN',
  ELBOW_PAIN_OR_DIFFICULTY_BENDING_IT = 'ELBOW_PAIN_OR_DIFFICULTY_BENDING_IT',
  ELBOW_SWELLING = 'ELBOW_SWELLING',
  ELECTROLYTE_ALTERATIONS = 'ELECTROLYTE_ALTERATIONS',
  EMOTIONAL_PROBLEMS_SUCH_AS_ANXIETY_AND_DEPRESSION = 'EMOTIONAL_PROBLEMS_SUCH_AS_ANXIETY_AND_DEPRESSION',
  EMPHYSEMA_DYSPNEA_CHRONIC_COUGH_RESPIRATORY_FAILURE = 'EMPHYSEMA_DYSPNEA_CHRONIC_COUGH_RESPIRATORY_FAILURE',
  ENCEPHALITIS = 'ENCEPHALITIS',
  ENDOCRINE_OR_HORMONAL_DISORDERS = 'ENDOCRINE_OR_HORMONAL_DISORDERS',
  ENDOCRINE_OR_HORMONAL_HEALTH_PROBLEMS = 'ENDOCRINE_OR_HORMONAL_HEALTH_PROBLEMS',
  ENDOCRINE_PROBLEMS_DIABETES_THYROID_DYSFUNCTION = 'ENDOCRINE_PROBLEMS_DIABETES_THYROID_DYSFUNCTION',
  ENDOCRINE_PROBLEMS_THYROID_DYSFUNCTION_DIABETES_MELLITUS = 'ENDOCRINE_PROBLEMS_THYROID_DYSFUNCTION_DIABETES_MELLITUS',
  ENDOCRINE_SYMPTOMS_SUCH_AS_CHANGES_IN_THE_MENSTRUAL_CYCLE_OR_CHANGES_IN_HORMONE_PRODUCTION = 'ENDOCRINE_SYMPTOMS_SUCH_AS_CHANGES_IN_THE_MENSTRUAL_CYCLE_OR_CHANGES_IN_HORMONE_PRODUCTION',
  ENDOCRINE_TUMORS_SUCH_AS_THYROID_TUMORS = 'ENDOCRINE_TUMORS_SUCH_AS_THYROID_TUMORS',
  ENDOMETRIAL_CANCERS = 'ENDOMETRIAL_CANCERS',
  ENDOMETRIAL_HYPERPLASIA = 'ENDOMETRIAL_HYPERPLASIA',
  ENDOMETRIOSIS = 'ENDOMETRIOSIS',
  ENLARGED_GANGLIA = 'ENLARGED_GANGLIA',
  ENLARGEMENT_OF_THE_PROSTATE_BENIGN_PROSTATIC_HYPERPLASIA = 'ENLARGEMENT_OF_THE_PROSTATE_BENIGN_PROSTATIC_HYPERPLASIA',
  ENLARGEMENT_OR_PROTRUSION_OF_THE_EYEBALL = 'ENLARGEMENT_OR_PROTRUSION_OF_THE_EYEBALL',
  ENT_HEALTH_PROBLEMS = 'ENT_HEALTH_PROBLEMS',
  ENT_OR_AUDITORY_HEALTH_PROBLEMS = 'ENT_OR_AUDITORY_HEALTH_PROBLEMS',
  ENURESIS_FAILURE_TO_CONTROL_THE_BLADDER_DURING_SLEEP = 'ENURESIS_FAILURE_TO_CONTROL_THE_BLADDER_DURING_SLEEP',
  EOSINOPHILIA_HIGH_NUMBER_OF_EOSINOPHILS = 'EOSINOPHILIA_HIGH_NUMBER_OF_EOSINOPHILS',
  EPIGASTRIC_PAIN = 'EPIGASTRIC_PAIN',
  EPIGASTRIC_PAIN_IN_THE_UPPER_PART_OF_THE_ABDOMEN = 'EPIGASTRIC_PAIN_IN_THE_UPPER_PART_OF_THE_ABDOMEN',
  EPILEPSY = 'EPILEPSY',
  EPILEPTIC_SEIZURE = 'EPILEPTIC_SEIZURE',
  EPILEPTIC_SEIZURES = 'EPILEPTIC_SEIZURES',
  EPISTAXIS_NOSEBLEED = 'EPISTAXIS_NOSEBLEED',
  ERECTILE_DYSFUNCTION_IMPOTENCE = 'ERECTILE_DYSFUNCTION_IMPOTENCE',
  ERECTILE_DYSFUNCTION_IN_MEN = 'ERECTILE_DYSFUNCTION_IN_MEN',
  ERYTHEMA_NODO_OR_OTHER_SKIN_MANIFESTATIONS_ASSOCIATED_WITH_AUTOIMMUNE_DISEASES = 'ERYTHEMA_NODO_OR_OTHER_SKIN_MANIFESTATIONS_ASSOCIATED_WITH_AUTOIMMUNE_DISEASES',
  ERYTHEMA_REDNESS_AROUND_ULCERS = 'ERYTHEMA_REDNESS_AROUND_ULCERS',
  ESOPHAGEAL_MOTILITY_DISORDERS_SUCH_AS_ACHALASIA = 'ESOPHAGEAL_MOTILITY_DISORDERS_SUCH_AS_ACHALASIA',
  ESPUTO_WITH_BLOOD = 'ESPUTO_WITH_BLOOD',
  ESSENTIAL_THROMBOCYTHEMIA = 'ESSENTIAL_THROMBOCYTHEMIA',
  ESSENTIAL_TREMOR = 'ESSENTIAL_TREMOR',
  EVALUATION_OF_CEREBRAL_VASCULAR_DISEASE = 'EVALUATION_OF_CEREBRAL_VASCULAR_DISEASE',
  EVALUATION_OF_CONGENITAL_OR_DEVELOPMENTAL_DISORDERS = 'EVALUATION_OF_CONGENITAL_OR_DEVELOPMENTAL_DISORDERS',
  EVALUATION_OF_IMPLANTED_PROSTHESES_OR_DEVICES = 'EVALUATION_OF_IMPLANTED_PROSTHESES_OR_DEVICES',
  EVALUATION_OF_SPORTS_INJURIES_OR_PHYSICAL_TRAUMA = 'EVALUATION_OF_SPORTS_INJURIES_OR_PHYSICAL_TRAUMA',
  EXCESSIVE_ANXIETY_OR_FEAR = 'EXCESSIVE_ANXIETY_OR_FEAR',
  EXCESSIVE_BLEEDING_DURING_MENSTRUATION = 'EXCESSIVE_BLEEDING_DURING_MENSTRUATION',
  EXCESSIVE_FATIGUE_OR_WEAKNESS = 'EXCESSIVE_FATIGUE_OR_WEAKNESS',
  EXCESSIVE_GAS_AND_FLATULENCE = 'EXCESSIVE_GAS_AND_FLATULENCE',
  EXCESSIVE_NIGHT_SWEATING = 'EXCESSIVE_NIGHT_SWEATING',
  EXCESSIVE_OR_INSUFFICIENT_TEARS = 'EXCESSIVE_OR_INSUFFICIENT_TEARS',
  EXCESSIVE_PREOCCUPATION_WITH_PARTICULAR_OBJECTS_OR_TOPICS = 'EXCESSIVE_PREOCCUPATION_WITH_PARTICULAR_OBJECTS_OR_TOPICS',
  EXCESSIVE_SHYNESS_OR_SOCIAL_ANXIETY = 'EXCESSIVE_SHYNESS_OR_SOCIAL_ANXIETY',
  EXCESSIVE_SWEATING = 'EXCESSIVE_SWEATING',
  EXCESSIVE_THIRST = 'EXCESSIVE_THIRST',
  EXCESSIVE_THIRST_VARIANT_2 = 'EXCESSIVE_THIRST_VARIANT_2',
  EXCESSIVE_TIREDNESS_OR_FATIGUE = 'EXCESSIVE_TIREDNESS_OR_FATIGUE',
  EXECUTIVE_DYSFUNCTION_ABILITY_TO_PLAN_INHIBIT_COGNITIVE_FLEXIBILITY = 'EXECUTIVE_DYSFUNCTION_ABILITY_TO_PLAN_INHIBIT_COGNITIVE_FLEXIBILITY',
  EXERCISE_INTOLERANCE = 'EXERCISE_INTOLERANCE',
  EXPECTORATION_OF_BLOOD_HEMOPTYSIS = 'EXPECTORATION_OF_BLOOD_HEMOPTYSIS',
  EYELID_RECONSTRUCTION = 'EYELID_RECONSTRUCTION',
  EYE_MOVEMENT_PROBLEMS_OR_NYSTAGMUS = 'EYE_MOVEMENT_PROBLEMS_OR_NYSTAGMUS',
  EYE_PAIN = 'EYE_PAIN',
  EYE_PROBLEMS_CATARACTS_GLAUCOMA = 'EYE_PROBLEMS_CATARACTS_GLAUCOMA',
  EYE_REDNESS_OR_IRRITATION = 'EYE_REDNESS_OR_IRRITATION',
  FACIAL_ASYMMETRIES = 'FACIAL_ASYMMETRIES',
  FACIAL_ASYMMETRY = 'FACIAL_ASYMMETRY',
  FACIAL_COSMETIC_SURGERY = 'FACIAL_COSMETIC_SURGERY',
  FACIAL_MASSES_OR_TUMORS = 'FACIAL_MASSES_OR_TUMORS',
  FACIAL_OR_MAXILLARY_FRACTURES = 'FACIAL_OR_MAXILLARY_FRACTURES',
  FACIAL_PAIN = 'FACIAL_PAIN',
  FACIAL_PAIN_PROBLEMS_SUCH_AS_TRIGEMINAL_NEURALGIA = 'FACIAL_PAIN_PROBLEMS_SUCH_AS_TRIGEMINAL_NEURALGIA',
  FACIAL_TRAUMA = 'FACIAL_TRAUMA',
  FACULTY_GO_TO_THE_BATHROOM = 'FACULTY_GO_TO_THE_BATHROOM',
  FAINTING_SPELLS_OR_LOSS_OF_CONSCIOUSNESS = 'FAINTING_SPELLS_OR_LOSS_OF_CONSCIOUSNESS',
  FALLS = 'FALLS',
  FAMILY_CONFLICT_STRESS_DISORDER = 'FAMILY_CONFLICT_STRESS_DISORDER',
  FANCONI_SYNDROME = 'FANCONI_SYNDROME',
  FARMER_S_LUNG_COUGH_DYSPNEA_FEVER = 'FARMER_S_LUNG_COUGH_DYSPNEA_FEVER',
  FATIGUE = 'FATIGUE',
  FATIGUE_AND_EXCESSIVE_EXHAUSTION = 'FATIGUE_AND_EXCESSIVE_EXHAUSTION',
  FATIGUE_AND_GENERAL_WEAKNESS = 'FATIGUE_AND_GENERAL_WEAKNESS',
  FATIGUE_AND_WEAKNESS = 'FATIGUE_AND_WEAKNESS',
  FATIGUE_VARIANT_2 = 'FATIGUE_VARIANT_2',
  FATIGUE_VARIANT_3 = 'FATIGUE_VARIANT_3',
  FECAL_INCONTINENCE = 'FECAL_INCONTINENCE',
  FECAL_INCONTINENCE_RARE_BUT_MAY_REQUIRE_UROLOGIC_CONSULTATION = 'FECAL_INCONTINENCE_RARE_BUT_MAY_REQUIRE_UROLOGIC_CONSULTATION',
  FEELING_OF_HEAVINESS_IN_THE_LEGS = 'FEELING_OF_HEAVINESS_IN_THE_LEGS',
  FEELING_OF_PELVIC_HEAVINESS = 'FEELING_OF_PELVIC_HEAVINESS',
  FEELING_OF_WEIGHT_IN_THE_ABDOMEN = 'FEELING_OF_WEIGHT_IN_THE_ABDOMEN',
  FEELING_OF_WEIGHT_IN_THE_CHEST = 'FEELING_OF_WEIGHT_IN_THE_CHEST',
  FEMALE_GENITAL_PROSTHESIS_CORRECTION_SURGERY = 'FEMALE_GENITAL_PROSTHESIS_CORRECTION_SURGERY',
  FEVER = 'FEVER',
  FEVER_OF_UNKNOWN_ORIGIN = 'FEVER_OF_UNKNOWN_ORIGIN',
  FEVER_UNEXPLAINED_BY_OTHER_CAUSES = 'FEVER_UNEXPLAINED_BY_OTHER_CAUSES',
  FINE_MOTOR_COORDINATION_PROBLEMS_SUCH_AS_WRITING_OR_USING_TOOLS = 'FINE_MOTOR_COORDINATION_PROBLEMS_SUCH_AS_WRITING_OR_USING_TOOLS',
  FLAKING_OR_PEELING_SKIN = 'FLAKING_OR_PEELING_SKIN',
  FLATULENCE = 'FLATULENCE',
  FLUENCY_DISORDERS_SUCH_AS_STUTTERING = 'FLUENCY_DISORDERS_SUCH_AS_STUTTERING',
  FLUID_IN_THE_ABDOMEN = 'FLUID_IN_THE_ABDOMEN',
  FLU_SYMPTOMS = 'FLU_SYMPTOMS',
  FOOD_ALLERGIES = 'FOOD_ALLERGIES',
  FOOD_INTOLERANCES = 'FOOD_INTOLERANCES',
  FOOD_INTOLERANCES_OR_FOOD_ALLERGIES = 'FOOD_INTOLERANCES_OR_FOOD_ALLERGIES',
  FOOD_PROBLEMS = 'FOOD_PROBLEMS',
  FOOD_RELATED_KIDNEY_OR_LIVER_FAILURE = 'FOOD_RELATED_KIDNEY_OR_LIVER_FAILURE',
  FOOT_AND_MOUTH_DISEASE = 'FOOT_AND_MOUTH_DISEASE',
  FOOT_DEFORMITIES_SUCH_AS_HALLUX_VALGUS = 'FOOT_DEFORMITIES_SUCH_AS_HALLUX_VALGUS',
  FOOT_JOINT_PAIN = 'FOOT_JOINT_PAIN',
  FOOT_OR_ANKLE_PAIN = 'FOOT_OR_ANKLE_PAIN',
  FOREIGN_BODIES = 'FOREIGN_BODIES',
  FOREIGN_BODY_SENSATION_IN_THE_EYE = 'FOREIGN_BODY_SENSATION_IN_THE_EYE',
  FORMATION_OF_GRANULATION_TISSUE_TISSUE_RENEWAL = 'FORMATION_OF_GRANULATION_TISSUE_TISSUE_RENEWAL',
  FORMATION_OF_KIDNEY_STONES_URINARY_LITHIASIS = 'FORMATION_OF_KIDNEY_STONES_URINARY_LITHIASIS',
  FREQUENT_FALLS = 'FREQUENT_FALLS',
  FREQUENT_INFECTIONS = 'FREQUENT_INFECTIONS',
  FREQUENT_NASAL_BLEEDING = 'FREQUENT_NASAL_BLEEDING',
  FREQUENT_NOCTURNAL_URINATION_NOCTURIA = 'FREQUENT_NOCTURNAL_URINATION_NOCTURIA',
  FREQUENT_OR_SEVERE_INFECTIONS = 'FREQUENT_OR_SEVERE_INFECTIONS',
  FREQUENT_PEE = 'FREQUENT_PEE',
  FREQUENT_SNEEZING = 'FREQUENT_SNEEZING',
  FREQUENT_URINATION = 'FREQUENT_URINATION',
  FREQUENT_URINATION_OR_URINARY_INCONTINENCE = 'FREQUENT_URINATION_OR_URINARY_INCONTINENCE',
  FREQUENT_URINATION_POLYURIA = 'FREQUENT_URINATION_POLYURIA',
  FRUSTRATION_TOLERANCE_PROBLEMS = 'FRUSTRATION_TOLERANCE_PROBLEMS',
  FUNCTIONAL_NEUROLOGICAL_DISORDER_CONVERSION = 'FUNCTIONAL_NEUROLOGICAL_DISORDER_CONVERSION',
  FURUNCULOSIS = 'FURUNCULOSIS',
  GAINED_OR_LOST_WEIGHT = 'GAINED_OR_LOST_WEIGHT',
  GAIT_PROBLEMS_RELATED_TO_MUSCULAR_OR_NEUROLOGICAL_DISORDERS = 'GAIT_PROBLEMS_RELATED_TO_MUSCULAR_OR_NEUROLOGICAL_DISORDERS',
  GALACTORRHEA_MILK_PRODUCTION_FROM_MAMMARY_GLANDS_UNRELATED_TO_LACTATION = 'GALACTORRHEA_MILK_PRODUCTION_FROM_MAMMARY_GLANDS_UNRELATED_TO_LACTATION',
  GAMBLING_ADDICTION = 'GAMBLING_ADDICTION',
  GANGRENE_OR_NECROSIS_OF_TISSUES_DUE_TO_VASCULAR_PROBLEMS = 'GANGRENE_OR_NECROSIS_OF_TISSUES_DUE_TO_VASCULAR_PROBLEMS',
  GASTRIC_PAIN = 'GASTRIC_PAIN',
  GASTROENTEROLOGICAL_DISORDERS_SUCH_AS_GASTROESOPHAGEAL_REFLUX_GASTRITIS = 'GASTROENTEROLOGICAL_DISORDERS_SUCH_AS_GASTROESOPHAGEAL_REFLUX_GASTRITIS',
  GASTROENTEROLOGICAL_OR_DIGESTIVE_HEALTH_PROBLEMS = 'GASTROENTEROLOGICAL_OR_DIGESTIVE_HEALTH_PROBLEMS',
  GASTROENTEROLOGICAL_OR_DIGESTIVE_PROBLEMS = 'GASTROENTEROLOGICAL_OR_DIGESTIVE_PROBLEMS',
  GASTROENTEROLOGICAL_PROBLEMS_DISEASES_OF_THE_LIVER_PANCREAS = 'GASTROENTEROLOGICAL_PROBLEMS_DISEASES_OF_THE_LIVER_PANCREAS',
  GASTROESOPHAGEAL_REFLUX = 'GASTROESOPHAGEAL_REFLUX',
  GASTROINTESTINAL_BLEEDING = 'GASTROINTESTINAL_BLEEDING',
  GASTROINTESTINAL_BLEEDING_VARIANT_2 = 'GASTROINTESTINAL_BLEEDING_VARIANT_2',
  GASTROINTESTINAL_DISORDERS = 'GASTROINTESTINAL_DISORDERS',
  GASTROINTESTINAL_DISORDERS_SUCH_AS_OBSTRUCTIONS = 'GASTROINTESTINAL_DISORDERS_SUCH_AS_OBSTRUCTIONS',
  GASTROINTESTINAL_OR_DIGESTIVE_HEALTH_PROBLEMS = 'GASTROINTESTINAL_OR_DIGESTIVE_HEALTH_PROBLEMS',
  GASTROINTESTINAL_OR_LIVER_HEALTH_PROBLEMS = 'GASTROINTESTINAL_OR_LIVER_HEALTH_PROBLEMS',
  GASTROINTESTINAL_PROBLEMS_CONSTIPATION_DIARRHEA_ACID_REFLUX = 'GASTROINTESTINAL_PROBLEMS_CONSTIPATION_DIARRHEA_ACID_REFLUX',
  GASTROINTESTINAL_PROBLEMS_DIVERTICULAR_DISEASE_PEPTIC_ULCER = 'GASTROINTESTINAL_PROBLEMS_DIVERTICULAR_DISEASE_PEPTIC_ULCER',
  GASTROINTESTINAL_PROBLEMS_DURING_PHYSICAL_ACTIVITY = 'GASTROINTESTINAL_PROBLEMS_DURING_PHYSICAL_ACTIVITY',
  GASTROINTESTINAL_SYMPTOMS_SUCH_AS_ABDOMINAL_PAIN_CRAMPING = 'GASTROINTESTINAL_SYMPTOMS_SUCH_AS_ABDOMINAL_PAIN_CRAMPING',
  GASTROINTESTINAL_SYMPTOMS_SUCH_AS_ACID_REFLUX_OR_ULCERS = 'GASTROINTESTINAL_SYMPTOMS_SUCH_AS_ACID_REFLUX_OR_ULCERS',
  GASTROINTESTINAL_SYMPTOMS_SUCH_AS_NAUSEA_VOMITING_ABDOMINAL_PAIN = 'GASTROINTESTINAL_SYMPTOMS_SUCH_AS_NAUSEA_VOMITING_ABDOMINAL_PAIN',
  GASTRO_INTESTINAL_PROBLEMS_SUCH_AS_GASTROESOPHAGEAL_REFLUX_GASTRITIS = 'GASTRO_INTESTINAL_PROBLEMS_SUCH_AS_GASTROESOPHAGEAL_REFLUX_GASTRITIS',
  GENERALIZED_ANXIETY_DISORDER_GAD = 'GENERALIZED_ANXIETY_DISORDER_GAD',
  GENERALIZED_ANXIETY_DISORDER_GAD_VARIANT_2 = 'GENERALIZED_ANXIETY_DISORDER_GAD_VARIANT_2',
  GENERAL_MALAISE = 'GENERAL_MALAISE',
  GENITAL_ITCHING = 'GENITAL_ITCHING',
  GENITAL_ULCERATIONS = 'GENITAL_ULCERATIONS',
  GERIATRIC_HEALTH_PROBLEMS_OR_IN_THE_ELDERLY = 'GERIATRIC_HEALTH_PROBLEMS_OR_IN_THE_ELDERLY',
  GITELMAN_S_SYNDROME = 'GITELMAN_S_SYNDROME',
  GLARE_OR_LIGHT_SENSITIVITY = 'GLARE_OR_LIGHT_SENSITIVITY',
  GLOMERULONEPHRITIS = 'GLOMERULONEPHRITIS',
  GOITER_ENLARGED_THYROID_GLAND = 'GOITER_ENLARGED_THYROID_GLAND',
  GOODPASTURE_SYNDROME = 'GOODPASTURE_SYNDROME',
  GOODPASTURE_S_SYNDROME_COUGH_DYSPNEA_HEMOPTYSIS = 'GOODPASTURE_S_SYNDROME_COUGH_DYSPNEA_HEMOPTYSIS',
  GREASED_FATTENING = 'GREASED_FATTENING',
  GROIN_PAIN = 'GROIN_PAIN',
  GROWTH_DISORDERS_IN_CHILDREN = 'GROWTH_DISORDERS_IN_CHILDREN',
  GROWTH_DISORDERS_IN_CHILDREN_AND_ADOLESCENTS = 'GROWTH_DISORDERS_IN_CHILDREN_AND_ADOLESCENTS',
  GROWTH_RETARDATION_IN_CHILDREN = 'GROWTH_RETARDATION_IN_CHILDREN',
  GUILLAIN_BARR_DISEASE = 'GUILLAIN_BARR_DISEASE',
  GURGLING_BOWEL_NOISES = 'GURGLING_BOWEL_NOISES',
  GYNECOLOGICAL_CANCERS = 'GYNECOLOGICAL_CANCERS',
  HAIR_LOSS_ALOPECIA = 'HAIR_LOSS_ALOPECIA',
  HAMMAN_RICH_SYNDROME_COUGH_DYSPNEA_RESPIRATORY_FAILURE = 'HAMMAN_RICH_SYNDROME_COUGH_DYSPNEA_RESPIRATORY_FAILURE',
  HANDWRITING_DISORDERS_DYSGRAPHIA = 'HANDWRITING_DISORDERS_DYSGRAPHIA',
  HAND_JOINT_PAIN = 'HAND_JOINT_PAIN',
  HEADACHE = 'HEADACHE',
  HEADACHES_ASSOCIATED_WITH_NASAL_OR_EAR_PROBLEMS = 'HEADACHES_ASSOCIATED_WITH_NASAL_OR_EAR_PROBLEMS',
  HEADACHES_ASSOCIATED_WITH_VISION = 'HEADACHES_ASSOCIATED_WITH_VISION',
  HEADACHE_ASSOCIATED_WITH_CARDIAC_SYMPTOMS = 'HEADACHE_ASSOCIATED_WITH_CARDIAC_SYMPTOMS',
  HEADACHE_CLUSTER = 'HEADACHE_CLUSTER',
  HEADACHE_HEADACHE = 'HEADACHE_HEADACHE',
  HEADACHE_VARIANT_2 = 'HEADACHE_VARIANT_2',
  HEAD_AND_NECK_SYMPTOMS = 'HEAD_AND_NECK_SYMPTOMS',
  HEAD_OR_BRAIN_INJURY = 'HEAD_OR_BRAIN_INJURY',
  HEARING_LOSS_HEARING_IMPAIRMENT = 'HEARING_LOSS_HEARING_IMPAIRMENT',
  HEARING_LOSS_OR_DIFFICULTY_IN_HEARING = 'HEARING_LOSS_OR_DIFFICULTY_IN_HEARING',
  HEARING_PROBLEMS = 'HEARING_PROBLEMS',
  HEARING_PROBLEMS_HEARING_LOSS_TINNITUS = 'HEARING_PROBLEMS_HEARING_LOSS_TINNITUS',
  HEARING_PROBLEMS_OR_DEAFNESS = 'HEARING_PROBLEMS_OR_DEAFNESS',
  HEARING_PROBLEMS_OR_DIZZINESS = 'HEARING_PROBLEMS_OR_DIZZINESS',
  HEARING_REHABILITATION_EXERCISES = 'HEARING_REHABILITATION_EXERCISES',
  HEARING_VOICES = 'HEARING_VOICES',
  HEARTBURN = 'HEARTBURN',
  HEARTBURN_OR_ACID_REFLUX = 'HEARTBURN_OR_ACID_REFLUX',
  HEARTBURN_OR_STOMACH_ACIDITY = 'HEARTBURN_OR_STOMACH_ACIDITY',
  HEART_DISEASE_AND_CARDIAC_DYSFUNCTION = 'HEART_DISEASE_AND_CARDIAC_DYSFUNCTION',
  HEART_FAILURE = 'HEART_FAILURE',
  HEART_PALPITATIONS = 'HEART_PALPITATIONS',
  HEART_PALPITATIONS_OR_ARRHYTHMIAS = 'HEART_PALPITATIONS_OR_ARRHYTHMIAS',
  HEAT_INTOLERANCE = 'HEAT_INTOLERANCE',
  HEEL_PAIN = 'HEEL_PAIN',
  HEMATOLOGICAL_DISORDERS_HEMOLYTIC_ANEMIA_THROMBOCYTOPENIA = 'HEMATOLOGICAL_DISORDERS_HEMOLYTIC_ANEMIA_THROMBOCYTOPENIA',
  HEMATOLOGICAL_OR_BLOOD_DISORDERS = 'HEMATOLOGICAL_OR_BLOOD_DISORDERS',
  HEMATOLOGICAL_OR_BLOOD_PROBLEMS = 'HEMATOLOGICAL_OR_BLOOD_PROBLEMS',
  HEMATOLOGICAL_PROBLEMS_ANEMIA_THROMBOSIS = 'HEMATOLOGICAL_PROBLEMS_ANEMIA_THROMBOSIS',
  HEMATURIA_BLOOD_IN_THE_URINE = 'HEMATURIA_BLOOD_IN_THE_URINE',
  HEMOPTYSIS_SPITTING_WITH_BLOOD = 'HEMOPTYSIS_SPITTING_WITH_BLOOD',
  HEMORRHAGES = 'HEMORRHAGES',
  HEMORRHAGIC_FEVER = 'HEMORRHAGIC_FEVER',
  HEMORRHOIDS = 'HEMORRHOIDS',
  HEMORRHOIDS_OR_VARICOSE_VEINS_IN_THE_ANUS = 'HEMORRHOIDS_OR_VARICOSE_VEINS_IN_THE_ANUS',
  HEPATOMEGALY_ENLARGED_LIVER = 'HEPATOMEGALY_ENLARGED_LIVER',
  HIGH_BLOOD_PRESSURE_OR_HYPERTENSION = 'HIGH_BLOOD_PRESSURE_OR_HYPERTENSION',
  HIGH_FEVER = 'HIGH_FEVER',
  HIP_PAIN_DURING_MOVEMENT = 'HIP_PAIN_DURING_MOVEMENT',
  HIP_SWELLING = 'HIP_SWELLING',
  HIP_SYMPTOMS = 'HIP_SYMPTOMS',
  HISSING_BREATH = 'HISSING_BREATH',
  HOARSENESS = 'HOARSENESS',
  HORMONAL_DISORDERS = 'HORMONAL_DISORDERS',
  HOT_OR_INFLAMED_SKIN = 'HOT_OR_INFLAMED_SKIN',
  HUNTINGTON_S_DISEASE = 'HUNTINGTON_S_DISEASE',
  HYGIENE_AND_CARE_OF_THE_SURROUNDING_SKIN = 'HYGIENE_AND_CARE_OF_THE_SURROUNDING_SKIN',
  HYPERACTIVITY = 'HYPERACTIVITY',
  HYPERCALCEMIA = 'HYPERCALCEMIA',
  HYPERCOAGULABILITY_INCREASED_RISK_OF_THROMBOSIS = 'HYPERCOAGULABILITY_INCREASED_RISK_OF_THROMBOSIS',
  HYPERGLYCEMIA_HIGH_BLOOD_SUGAR_LEVELS = 'HYPERGLYCEMIA_HIGH_BLOOD_SUGAR_LEVELS',
  HYPERHIDROSIS_EXCESSIVE_SWEATING_OF_THE_HANDS = 'HYPERHIDROSIS_EXCESSIVE_SWEATING_OF_THE_HANDS',
  HYPERHIDROSIS_EXCESSIVE_SWEATING_OF_THE_HANDS_OR_ARMPITS = 'HYPERHIDROSIS_EXCESSIVE_SWEATING_OF_THE_HANDS_OR_ARMPITS',
  HYPERLIPIDEMIA_HIGH_LEVELS_OF_FAT_IN_THE_BLOOD = 'HYPERLIPIDEMIA_HIGH_LEVELS_OF_FAT_IN_THE_BLOOD',
  HYPERLIPIDEMIA_HIGH_LEVELS_OF_FAT_IN_THE_BLOOD_VARIANT_2 = 'HYPERLIPIDEMIA_HIGH_LEVELS_OF_FAT_IN_THE_BLOOD_VARIANT_2',
  HYPERPHOSPHOREMIA_HIGH_LEVELS_OF_PHOSPHORUS_IN_THE_BLOOD = 'HYPERPHOSPHOREMIA_HIGH_LEVELS_OF_PHOSPHORUS_IN_THE_BLOOD',
  HYPERREFLEXIA_OR_HYPOREFLEXIA = 'HYPERREFLEXIA_OR_HYPOREFLEXIA',
  HYPERTENSION = 'HYPERTENSION',
  HYPERTENSION_OR_HYPOTENSION = 'HYPERTENSION_OR_HYPOTENSION',
  HYPERTENSIVE_NEPHROPATHY = 'HYPERTENSIVE_NEPHROPATHY',
  HYPERURICEMIA_HIGH_LEVELS_OF_URIC_ACID_IN_THE_BLOOD = 'HYPERURICEMIA_HIGH_LEVELS_OF_URIC_ACID_IN_THE_BLOOD',
  HYPERVENTILATION_SYNDROME_EXCESSIVE_BREATHING = 'HYPERVENTILATION_SYNDROME_EXCESSIVE_BREATHING',
  HYPOCALCEMIA = 'HYPOCALCEMIA',
  HYPOGLYCEMIA_LOW_BLOOD_SUGAR_LEVELS = 'HYPOGLYCEMIA_LOW_BLOOD_SUGAR_LEVELS',
  HYPOMENORRHEA_SCANTY_MENSTRUAL_FLOW = 'HYPOMENORRHEA_SCANTY_MENSTRUAL_FLOW',
  HYPOURICEMIA_LOW_LEVELS_OF_URIC_ACID_IN_THE_BLOOD = 'HYPOURICEMIA_LOW_LEVELS_OF_URIC_ACID_IN_THE_BLOOD',
  HYPOXURIA_LOW_PHOSPHATE_CONTENT_IN_URINE = 'HYPOXURIA_LOW_PHOSPHATE_CONTENT_IN_URINE',
  IDENTITY_AND_SELF_CONSCIOUSNESS_DISORDERS = 'IDENTITY_AND_SELF_CONSCIOUSNESS_DISORDERS',
  IGA_NEPHROPATHY_BERGER_S_DISEASE = 'IGA_NEPHROPATHY_BERGER_S_DISEASE',
  IMMUNE_PROBLEMS_IMMUNODEFICIENCY_AUTOIMMUNE_DISEASES = 'IMMUNE_PROBLEMS_IMMUNODEFICIENCY_AUTOIMMUNE_DISEASES',
  IMMUNE_SYSTEM_DISORDERS_OR_ALLERGIES = 'IMMUNE_SYSTEM_DISORDERS_OR_ALLERGIES',
  IMPULSE_CONTROL_DISORDERS_SUCH_AS_KLEPTOMANIA = 'IMPULSE_CONTROL_DISORDERS_SUCH_AS_KLEPTOMANIA',
  IMPULSIVITY = 'IMPULSIVITY',
  INABILITY_TO_SIT_STILL = 'INABILITY_TO_SIT_STILL',
  INAPPETENCE_LOSS_OF_APPETITE = 'INAPPETENCE_LOSS_OF_APPETITE',
  INCONSOLABLE_CRYING_OR_EMOTIONAL_IRREGULARITIES = 'INCONSOLABLE_CRYING_OR_EMOTIONAL_IRREGULARITIES',
  INCONTINENT_VEINS = 'INCONTINENT_VEINS',
  INCREASED_ABDOMINAL_VOLUME = 'INCREASED_ABDOMINAL_VOLUME',
  INCREASED_APPETITE = 'INCREASED_APPETITE',
  INCREASED_BLOOD_PRESSURE = 'INCREASED_BLOOD_PRESSURE',
  INCREASED_BLOOD_SUGAR_LEVELS_HYPERGLYCEMIA = 'INCREASED_BLOOD_SUGAR_LEVELS_HYPERGLYCEMIA',
  INCREASED_BODY_HAIR_HIRSUTISM = 'INCREASED_BODY_HAIR_HIRSUTISM',
  INCREASED_FRAGILITY = 'INCREASED_FRAGILITY',
  INCREASED_HEART_RATE = 'INCREASED_HEART_RATE',
  INCREASED_INFECTIONS_IMMUNOSUPPRESSION = 'INCREASED_INFECTIONS_IMMUNOSUPPRESSION',
  INCREASED_NIGHT_SWEATING = 'INCREASED_NIGHT_SWEATING',
  INCREASED_OR_DECREASED_SENSITIVITY_TO_PAIN = 'INCREASED_OR_DECREASED_SENSITIVITY_TO_PAIN',
  INCREASED_OR_DECREASED_SEXUAL_DESIRE = 'INCREASED_OR_DECREASED_SEXUAL_DESIRE',
  INCREASED_SEXUAL_APPETITE = 'INCREASED_SEXUAL_APPETITE',
  INCREASED_SEXUAL_DESIRE = 'INCREASED_SEXUAL_DESIRE',
  INCREASED_SIZE_OF_HANDS_OR_FEET = 'INCREASED_SIZE_OF_HANDS_OR_FEET',
  INCREASED_SWEATING = 'INCREASED_SWEATING',
  INCREASED_THIRST_AND_HUNGER = 'INCREASED_THIRST_AND_HUNGER',
  INCREASED_URINARY_FREQUENCY = 'INCREASED_URINARY_FREQUENCY',
  INCREASED_URINARY_FREQUENCY_AT_NIGHT = 'INCREASED_URINARY_FREQUENCY_AT_NIGHT',
  INCREASE_IN_MUSCLE_MASS_OR_DECREASE_IN_STRENGTH = 'INCREASE_IN_MUSCLE_MASS_OR_DECREASE_IN_STRENGTH',
  INCREASE_OR_DECREASE_IN_WEIGHT = 'INCREASE_OR_DECREASE_IN_WEIGHT',
  INFECTIONS_IN_THE_EYE = 'INFECTIONS_IN_THE_EYE',
  INFECTIONS_OF_THE_NAILS_OR_NAIL_SYSTEM = 'INFECTIONS_OF_THE_NAILS_OR_NAIL_SYSTEM',
  INFECTIONS_OR_ABSCESSES_IN_THE_OROFACIAL_REGION = 'INFECTIONS_OR_ABSCESSES_IN_THE_OROFACIAL_REGION',
  INFERTILITY = 'INFERTILITY',
  INFERTILITY_OR_DIFFICULTY_CONCEIVING = 'INFERTILITY_OR_DIFFICULTY_CONCEIVING',
  INFLAMMATION_OF_THE_GUMS_GINGIVITIS = 'INFLAMMATION_OF_THE_GUMS_GINGIVITIS',
  INFLAMMATION_OR_INFECTION_OF_THE_URINARY_TRACT = 'INFLAMMATION_OR_INFECTION_OF_THE_URINARY_TRACT',
  INFLAMMATORY_AND_AUTOIMMUNE_DISEASES = 'INFLAMMATORY_AND_AUTOIMMUNE_DISEASES',
  INFLAMMATORY_AND_INFECTIOUS_RESPONSE_OF_WOUNDS = 'INFLAMMATORY_AND_INFECTIOUS_RESPONSE_OF_WOUNDS',
  INFLAMMATORY_BOWEL_DISEASES_IBD = 'INFLAMMATORY_BOWEL_DISEASES_IBD',
  INFLAMMATORY_BOWEL_DISEASE_SUCH_AS_CROHN_S_DISEASE = 'INFLAMMATORY_BOWEL_DISEASE_SUCH_AS_CROHN_S_DISEASE',
  INJURIES_OR_WOUNDS_TO_THE_SOFT_TISSUES_OF_THE_MOUTH = 'INJURIES_OR_WOUNDS_TO_THE_SOFT_TISSUES_OF_THE_MOUTH',
  INSOMNIA = 'INSOMNIA',
  INSOMNIA_SLEEP_DISORDER = 'INSOMNIA_SLEEP_DISORDER',
  INSTEP_PAIN = 'INSTEP_PAIN',
  INSUFFICIENT_WEIGHT_GAIN = 'INSUFFICIENT_WEIGHT_GAIN',
  INTENSE_ITCHING_IN_THE_GENITAL_AREAS = 'INTENSE_ITCHING_IN_THE_GENITAL_AREAS',
  INTENSE_OR_UNUSUAL_REACTIONS_TO_ENVIRONMENTAL_STIMULI = 'INTENSE_OR_UNUSUAL_REACTIONS_TO_ENVIRONMENTAL_STIMULI',
  INTERACTION_PROBLEMS_WITH_PEERS = 'INTERACTION_PROBLEMS_WITH_PEERS',
  INTERMENSTRUAL_BLEEDING = 'INTERMENSTRUAL_BLEEDING',
  INTERPERSONAL_CONFLICT_STRESS_DISORDER = 'INTERPERSONAL_CONFLICT_STRESS_DISORDER',
  INTERRUPTIONS_OR_INTERMITTENT_URINARY_FLOW = 'INTERRUPTIONS_OR_INTERMITTENT_URINARY_FLOW',
  INTERSTITIAL_LUNG_DISEASES_SUCH_AS_IDIOPATHIC_PULMONARY_FIBROSIS = 'INTERSTITIAL_LUNG_DISEASES_SUCH_AS_IDIOPATHIC_PULMONARY_FIBROSIS',
  INTERSTITIAL_LUNG_DISEASE_DRY_COUGH_DYSPNEA_FATIGUE = 'INTERSTITIAL_LUNG_DISEASE_DRY_COUGH_DYSPNEA_FATIGUE',
  INTESTINAL_GAS = 'INTESTINAL_GAS',
  INTESTINAL_PARASITES = 'INTESTINAL_PARASITES',
  INVOLUNTARY_MOVEMENTS_DYSKINESIAS = 'INVOLUNTARY_MOVEMENTS_DYSKINESIAS',
  INVOLUNTARY_TREMORS_OR_SHAKING = 'INVOLUNTARY_TREMORS_OR_SHAKING',
  INVOLUNTARY_URINATION_URINARY_INCONTINENCE = 'INVOLUNTARY_URINATION_URINARY_INCONTINENCE',
  IRON_DEFICIENCY_ANEMIA = 'IRON_DEFICIENCY_ANEMIA',
  IRREGULARITY_OF_MENSTRUAL_CYCLE_OR_AMENORRHEA = 'IRREGULARITY_OF_MENSTRUAL_CYCLE_OR_AMENORRHEA',
  IRREGULARITY_OF_MENSTRUAL_CYCLE_OR_AMENORRHEA_IN_WOMEN = 'IRREGULARITY_OF_MENSTRUAL_CYCLE_OR_AMENORRHEA_IN_WOMEN',
  IRREGULAR_CYCLES_WITH_SPOTTING = 'IRREGULAR_CYCLES_WITH_SPOTTING',
  IRREGULAR_MENSTRUAL_PERIODS_IN_WOMEN = 'IRREGULAR_MENSTRUAL_PERIODS_IN_WOMEN',
  IRRITABILITY = 'IRRITABILITY',
  IRRITABILITY_OR_FRUSTRATION = 'IRRITABILITY_OR_FRUSTRATION',
  ITCHING = 'ITCHING',
  ITCHING_ITCHY_SKIN = 'ITCHING_ITCHY_SKIN',
  ITCHY_EYES = 'ITCHY_EYES',
  ITCHY_SKIN = 'ITCHY_SKIN',
  ITCHY_SKIN_OR_RECURRENT_SKIN_INFECTIONS = 'ITCHY_SKIN_OR_RECURRENT_SKIN_INFECTIONS',
  ITCHY_SKIN_VARIANT_2 = 'ITCHY_SKIN_VARIANT_2',
  I_CAN_T_RECOGNIZE_THE_VOICES_AND_WORDS = 'I_CAN_T_RECOGNIZE_THE_VOICES_AND_WORDS',
  I_CAN_T_SLEEP = 'I_CAN_T_SLEEP',
  I_CAN_T_SPEAK = 'I_CAN_T_SPEAK',
  I_SWELL = 'I_SWELL',
  JAUNDICE = 'JAUNDICE',
  JAUNDICE_YELLOW_SKIN_OR_EYES = 'JAUNDICE_YELLOW_SKIN_OR_EYES',
  JAW_PAIN = 'JAW_PAIN',
  JOB_CHANGE_STRESS_DISORDER = 'JOB_CHANGE_STRESS_DISORDER',
  JOINT_DEFORMITIES = 'JOINT_DEFORMITIES',
  JOINT_INJURIES_SUCH_AS_SPRAINS_OR_DISLOCATIONS = 'JOINT_INJURIES_SUCH_AS_SPRAINS_OR_DISLOCATIONS',
  JOINT_INSTABILITY = 'JOINT_INSTABILITY',
  JOINT_OR_MUSCLE_PAIN = 'JOINT_OR_MUSCLE_PAIN',
  JOINT_OR_MUSCLE_PAIN_ASSOCIATED_WITH_INFLAMMATORY_CONDITIONS = 'JOINT_OR_MUSCLE_PAIN_ASSOCIATED_WITH_INFLAMMATORY_CONDITIONS',
  JOINT_PAIN = 'JOINT_PAIN',
  JOINT_PAIN_AS_IN_OSTEOARTHRITIS = 'JOINT_PAIN_AS_IN_OSTEOARTHRITIS',
  JOINT_PAIN_VARIANT_2 = 'JOINT_PAIN_VARIANT_2',
  JOINT_PAIN_VARIANT_3 = 'JOINT_PAIN_VARIANT_3',
  JOINT_PAIN_VARIANT_4 = 'JOINT_PAIN_VARIANT_4',
  JOINT_PAIN_WITH_SWELLING_AND_LIMITATION_OF_MOVEMENT = 'JOINT_PAIN_WITH_SWELLING_AND_LIMITATION_OF_MOVEMENT',
  JOINT_PROSTHESIS_CORRECTION_SURGERY = 'JOINT_PROSTHESIS_CORRECTION_SURGERY',
  JOINT_STIFFNESS = 'JOINT_STIFFNESS',
  JOINT_SWELLING = 'JOINT_SWELLING',
  JOINT_SWELLING_OR_EDEMA = 'JOINT_SWELLING_OR_EDEMA',
  JOINT_SWELLING_VARIANT_2 = 'JOINT_SWELLING_VARIANT_2',
  KIDNEY_DISEASE_AND_IMPAIRED_KIDNEY_FUNCTION = 'KIDNEY_DISEASE_AND_IMPAIRED_KIDNEY_FUNCTION',
  KIDNEY_DISEASE_NEPHROPATHY_TO_IMMUNE_COMPLEX_DEPOSITS = 'KIDNEY_DISEASE_NEPHROPATHY_TO_IMMUNE_COMPLEX_DEPOSITS',
  KIDNEY_DISORDERS = 'KIDNEY_DISORDERS',
  KIDNEY_DISORDERS_SUCH_AS_STONES_OR_CYSTS = 'KIDNEY_DISORDERS_SUCH_AS_STONES_OR_CYSTS',
  KIDNEY_PAIN = 'KIDNEY_PAIN',
  KIDNEY_PROBLEMS_RENAL_FAILURE_CONGESTIVE_HEART_FAILURE = 'KIDNEY_PROBLEMS_RENAL_FAILURE_CONGESTIVE_HEART_FAILURE',
  KIDNEY_STONES = 'KIDNEY_STONES',
  KNEE_PAIN = 'KNEE_PAIN',
  KNEE_PAIN_DURING_MOVEMENT = 'KNEE_PAIN_DURING_MOVEMENT',
  KNEE_PAIN_OR_SWELLING = 'KNEE_PAIN_OR_SWELLING',
  KNEE_PAIN_VARIANT_2 = 'KNEE_PAIN_VARIANT_2',
  LACK_OF_APPETITE = 'LACK_OF_APPETITE',
  LACK_OF_BREATH = 'LACK_OF_BREATH',
  LACK_OF_COORDINATION_BETWEEN_THE_EYES = 'LACK_OF_COORDINATION_BETWEEN_THE_EYES',
  LACK_OF_LOSS_OF_SENSE_OF_DEPTH = 'LACK_OF_LOSS_OF_SENSE_OF_DEPTH',
  LACK_OF_MOTOR_COORDINATION = 'LACK_OF_MOTOR_COORDINATION',
  LACTOSE_INTOLERANCE = 'LACTOSE_INTOLERANCE',
  LANGUAGE_AND_COMMUNICATION_DEFICITS = 'LANGUAGE_AND_COMMUNICATION_DEFICITS',
  LANGUAGE_DELAY = 'LANGUAGE_DELAY',
  LARGE_INTESTINE_PROBLEMS_SUCH_AS_IRRITABLE_BOWEL_SYNDROME = 'LARGE_INTESTINE_PROBLEMS_SUCH_AS_IRRITABLE_BOWEL_SYNDROME',
  LATERAL_ANKLE_PAIN = 'LATERAL_ANKLE_PAIN',
  LATERAL_ELBOW_PAIN = 'LATERAL_ELBOW_PAIN',
  LATERAL_FOOT_PAIN = 'LATERAL_FOOT_PAIN',
  LATERAL_HIP_PAIN = 'LATERAL_HIP_PAIN',
  LATERAL_KNEE_PAIN = 'LATERAL_KNEE_PAIN',
  LAZY_EYE = 'LAZY_EYE',
  LEAKAGE_OR_BLEEDING_FROM_THE_BREAST = 'LEAKAGE_OR_BLEEDING_FROM_THE_BREAST',
  LEARNING_DIFFICULTIES = 'LEARNING_DIFFICULTIES',
  LEARNING_PROBLEMS = 'LEARNING_PROBLEMS',
  LEFT_UPPER_ABDOMINAL_PAIN = 'LEFT_UPPER_ABDOMINAL_PAIN',
  LEGIONNAIRE_S_DISEASE_FEVER_COUGH_DYSPNEA = 'LEGIONNAIRE_S_DISEASE_FEVER_COUGH_DYSPNEA',
  LEG_DISCOMFORT_HEAVINESS = 'LEG_DISCOMFORT_HEAVINESS',
  LEG_MUSCLE_CRAMPS = 'LEG_MUSCLE_CRAMPS',
  LEG_OR_ANKLE_EDEMA = 'LEG_OR_ANKLE_EDEMA',
  LEG_PAIN_WHEN_WALKING_CLAUDICATION_INTERMITTENS = 'LEG_PAIN_WHEN_WALKING_CLAUDICATION_INTERMITTENS',
  LEG_PAIN_WITH_SWELLING_AND_REDNESS = 'LEG_PAIN_WITH_SWELLING_AND_REDNESS',
  LEG_SENSATION = 'LEG_SENSATION',
  LEG_SWELLING = 'LEG_SWELLING',
  LEG_SWELLING_VARIANT_2 = 'LEG_SWELLING_VARIANT_2',
  LEG_VEINS_VISIBLE = 'LEG_VEINS_VISIBLE',
  LEUCORRHEA_ABNORMAL_VAGINAL_FLOW = 'LEUCORRHEA_ABNORMAL_VAGINAL_FLOW',
  LEUKEMIA = 'LEUKEMIA',
  LEUKOCYTOSIS_ELEVATED_WHITE_BLOOD_CELL_COUNT = 'LEUKOCYTOSIS_ELEVATED_WHITE_BLOOD_CELL_COUNT',
  LEUKOPENIA_LOW_WHITE_BLOOD_CELL_COUNT = 'LEUKOPENIA_LOW_WHITE_BLOOD_CELL_COUNT',
  LIDDLE_S_SYNDROME = 'LIDDLE_S_SYNDROME',
  LIGAMENTOUS_INJURIES_OR_SPRAINS = 'LIGAMENTOUS_INJURIES_OR_SPRAINS',
  LIGHT_SPOTS_OR_HYPOPIGMENTATION = 'LIGHT_SPOTS_OR_HYPOPIGMENTATION',
  LIMB_MALFORMATIONS = 'LIMB_MALFORMATIONS',
  LIMB_REDUCTION_SURGERY = 'LIMB_REDUCTION_SURGERY',
  LIMITATIONS_IN_JOINT_MOBILITY = 'LIMITATIONS_IN_JOINT_MOBILITY',
  LIMITATIONS_IN_JOINT_MOVEMENTS = 'LIMITATIONS_IN_JOINT_MOVEMENTS',
  LIMITATIONS_IN_MOBILITY = 'LIMITATIONS_IN_MOBILITY',
  LIMITATION_OF_MOVEMENT_OF_THE_COLUMN = 'LIMITATION_OF_MOVEMENT_OF_THE_COLUMN',
  LIPOSUCTION_AND_LIPOFILLING = 'LIPOSUCTION_AND_LIPOFILLING',
  LIPS_LEPORINE_LABIOPALATOSCHISIS = 'LIPS_LEPORINE_LABIOPALATOSCHISIS',
  LITTLE_PEE = 'LITTLE_PEE',
  LIVER_DISEASES = 'LIVER_DISEASES',
  LIVER_OR_GALLBLADDER_PROBLEMS = 'LIVER_OR_GALLBLADDER_PROBLEMS',
  LIVER_PAIN = 'LIVER_PAIN',
  LIVER_PROBLEMS_SUCH_AS_JAUNDICE_OR_ENLARGED_LIVER = 'LIVER_PROBLEMS_SUCH_AS_JAUNDICE_OR_ENLARGED_LIVER',
  LIVER_TUMORS = 'LIVER_TUMORS',
  LOCALIZED_OR_WIDESPREAD_PAIN = 'LOCALIZED_OR_WIDESPREAD_PAIN',
  LOCALIZED_PAIN_OR_TENDERNESS = 'LOCALIZED_PAIN_OR_TENDERNESS',
  LOCALIZED_SWELLING_OR_EDEMA = 'LOCALIZED_SWELLING_OR_EDEMA',
  LOSS_OF_BALANCE = 'LOSS_OF_BALANCE',
  LOSS_OF_BONE_TISSUE = 'LOSS_OF_BONE_TISSUE',
  LOSS_OF_CONSCIOUSNESS = 'LOSS_OF_CONSCIOUSNESS',
  LOSS_OF_COORDINATION = 'LOSS_OF_COORDINATION',
  LOSS_OF_COORDINATION_VARIANT_2 = 'LOSS_OF_COORDINATION_VARIANT_2',
  LOSS_OF_MUSCLE_TISSUE = 'LOSS_OF_MUSCLE_TISSUE',
  LOSS_OF_MUSCLE_VOLUME_OR_TONE = 'LOSS_OF_MUSCLE_VOLUME_OR_TONE',
  LOSS_OF_SENSATION_IN_THE_EXTREMITIES = 'LOSS_OF_SENSATION_IN_THE_EXTREMITIES',
  LOSS_OF_SKIN_AREAS = 'LOSS_OF_SKIN_AREAS',
  LOSS_OF_SKIN_TISSUE = 'LOSS_OF_SKIN_TISSUE',
  LOSS_OF_STRENGTH = 'LOSS_OF_STRENGTH',
  LOSS_OF_SUBCUTANEOUS_TISSUE = 'LOSS_OF_SUBCUTANEOUS_TISSUE',
  LOSS_OF_VISION = 'LOSS_OF_VISION',
  LOSS_OF_VISUAL_FIELD = 'LOSS_OF_VISUAL_FIELD',
  LOU_GEHRIG_S_DISEASE_ALS = 'LOU_GEHRIG_S_DISEASE_ALS',
  LOWER_ABDOMINAL_PAIN = 'LOWER_ABDOMINAL_PAIN',
  LOWER_ABDOMINAL_PAIN_VARIANT_2 = 'LOWER_ABDOMINAL_PAIN_VARIANT_2',
  LOWER_AIRWAY_DISEASES_SUCH_AS_ACUTE_BRONCHITIS = 'LOWER_AIRWAY_DISEASES_SUCH_AS_ACUTE_BRONCHITIS',
  LOWER_BACK_OR_CHEST_PAIN = 'LOWER_BACK_OR_CHEST_PAIN',
  LOWER_BACK_OR_HIP_PAIN = 'LOWER_BACK_OR_HIP_PAIN',
  LOWER_BACK_OR_NECK_PAIN_RELATED_TO_SPORTS_ACTIVITY = 'LOWER_BACK_OR_NECK_PAIN_RELATED_TO_SPORTS_ACTIVITY',
  LOWER_BACK_PAIN = 'LOWER_BACK_PAIN',
  LOWER_LIMB_ALIGNMENT_PROBLEMS = 'LOWER_LIMB_ALIGNMENT_PROBLEMS',
  LOWER_SHOULDER_PAIN = 'LOWER_SHOULDER_PAIN',
  LOW_BLOOD_PRESSURE_OR_HYPOTENSION = 'LOW_BLOOD_PRESSURE_OR_HYPOTENSION',
  LOW_BLOOD_SUGAR_LEVELS_HYPOGLYCEMIA = 'LOW_BLOOD_SUGAR_LEVELS_HYPOGLYCEMIA',
  LUNG_GROWTH_DEFICIENCY_IN_CHILDREN = 'LUNG_GROWTH_DEFICIENCY_IN_CHILDREN',
  LUNG_GROWTH_DEFICIENCY_SYMPTOMS_IN_CHILDREN = 'LUNG_GROWTH_DEFICIENCY_SYMPTOMS_IN_CHILDREN',
  LUNG_INJURIES_OR_TUMORS = 'LUNG_INJURIES_OR_TUMORS',
  LUNG_OR_AIRWAY_DISORDERS_SUCH_AS_PNEUMONIAS_OR_BRONCHITIS = 'LUNG_OR_AIRWAY_DISORDERS_SUCH_AS_PNEUMONIAS_OR_BRONCHITIS',
  LYMPHADENOPATHY_ENLARGEMENT_OF_LYMPH_NODES = 'LYMPHADENOPATHY_ENLARGEMENT_OF_LYMPH_NODES',
  LYMPHOCYTOSIS_HIGH_NUMBER_OF_LYMPHOCYTES = 'LYMPHOCYTOSIS_HIGH_NUMBER_OF_LYMPHOCYTES',
  LYMPHOMA = 'LYMPHOMA',
  LYMPHOPENIA_LOW_NUMBER_OF_LYMPHOCYTES = 'LYMPHOPENIA_LOW_NUMBER_OF_LYMPHOCYTES',
  L_FFLER_S_SYNDROME_COUGH_DYSPNEA_LUNG_INFILTRATES = 'L_FFLER_S_SYNDROME_COUGH_DYSPNEA_LUNG_INFILTRATES',
  MAJOR_DEPRESSIVE_DISORDER_MDD = 'MAJOR_DEPRESSIVE_DISORDER_MDD',
  MALE_OR_FEMALE_INFERTILITY = 'MALE_OR_FEMALE_INFERTILITY',
  MALFORMATIONS_OF_THE_EAR = 'MALFORMATIONS_OF_THE_EAR',
  MALFORMATIONS_OF_THE_EAR_OR_HEAD_AND_NECK = 'MALFORMATIONS_OF_THE_EAR_OR_HEAD_AND_NECK',
  MALFORMATIONS_OF_THE_OUTER_OR_MIDDLE_EAR = 'MALFORMATIONS_OF_THE_OUTER_OR_MIDDLE_EAR',
  MALNUTRITION = 'MALNUTRITION',
  MALNUTRITION_OR_GROWTH_RETARDATION_IN_CHILDREN = 'MALNUTRITION_OR_GROWTH_RETARDATION_IN_CHILDREN',
  MANAGEMENT_OF_POSTOPERATIVE_REHABILITATION_AND_TRAINING_PROGRAMS = 'MANAGEMENT_OF_POSTOPERATIVE_REHABILITATION_AND_TRAINING_PROGRAMS',
  MARITAL_CONFLICT_STRESS_DISORDER = 'MARITAL_CONFLICT_STRESS_DISORDER',
  MASTITIS_INFLAMMATION_OF_THE_BREAST = 'MASTITIS_INFLAMMATION_OF_THE_BREAST',
  MEDIAL_ANKLE_PAIN = 'MEDIAL_ANKLE_PAIN',
  MEDIAL_ELBOW_PAIN = 'MEDIAL_ELBOW_PAIN',
  MEDIAL_FOOT_PAIN = 'MEDIAL_FOOT_PAIN',
  MEDIAL_HIP_PAIN = 'MEDIAL_HIP_PAIN',
  MEDIAL_KNEE_PAIN = 'MEDIAL_KNEE_PAIN',
  MEDIASTINAL_TUMORS = 'MEDIASTINAL_TUMORS',
  MEDIASTINAL_TUMORS_IN_THE_AREA_BETWEEN_THE_LUNGS = 'MEDIASTINAL_TUMORS_IN_THE_AREA_BETWEEN_THE_LUNGS',
  MEMORY_DISORDERS = 'MEMORY_DISORDERS',
  MEMORY_PROBLEMS = 'MEMORY_PROBLEMS',
  MEMORY_PROBLEMS_OR_DIFFICULTY_CONCENTRATING = 'MEMORY_PROBLEMS_OR_DIFFICULTY_CONCENTRATING',
  MENORRHAGIA_HEAVY_MENSTRUATION = 'MENORRHAGIA_HEAVY_MENSTRUATION',
  MENSTRUAL_IRREGULARITIES = 'MENSTRUAL_IRREGULARITIES',
  MENSTRUAL_IRREGULARITY = 'MENSTRUAL_IRREGULARITY',
  MENTAL_CONFUSION = 'MENTAL_CONFUSION',
  MENTAL_HEALTH_OR_PSYCHIATRIC_PROBLEMS = 'MENTAL_HEALTH_OR_PSYCHIATRIC_PROBLEMS',
  MENTAL_HEALTH_PROBLEMS_COGNITIVE_DISORDERS_DEMENTIA = 'MENTAL_HEALTH_PROBLEMS_COGNITIVE_DISORDERS_DEMENTIA',
  MESOTHELIOMA_DYSPNEA_COUGH_CHEST_PAIN = 'MESOTHELIOMA_DYSPNEA_COUGH_CHEST_PAIN',
  METABOLIC_DISORDERS = 'METABOLIC_DISORDERS',
  METABOLIC_OR_ENDOCRINE_SYSTEM_DISORDERS = 'METABOLIC_OR_ENDOCRINE_SYSTEM_DISORDERS',
  METASTASIS_CANCER_THAT_HAS_SPREAD_FROM_OTHER_PARTS_OF_THE_BODY = 'METASTASIS_CANCER_THAT_HAS_SPREAD_FROM_OTHER_PARTS_OF_THE_BODY',
  METRORRHAGIA_ABNORMAL_UTERINE_BLEEDING = 'METRORRHAGIA_ABNORMAL_UTERINE_BLEEDING',
  MIGRAINE = 'MIGRAINE',
  MIGRENA = 'MIGRENA',
  MISSING_OR_EDENTULOUS_TEETH = 'MISSING_OR_EDENTULOUS_TEETH',
  MOBILITY_PROBLEMS_AIDS_WHEELCHAIRS = 'MOBILITY_PROBLEMS_AIDS_WHEELCHAIRS',
  MOLECULAR_IMAGING_STUDIES = 'MOLECULAR_IMAGING_STUDIES',
  MONITORING_AND_MANAGEMENT_OF_SPORT_RELATED_PSYCHOLOGICAL_CONDITIONS = 'MONITORING_AND_MANAGEMENT_OF_SPORT_RELATED_PSYCHOLOGICAL_CONDITIONS',
  MONITORING_OF_AUTOIMMUNE_DISEASES_SUCH_AS_LUPUS = 'MONITORING_OF_AUTOIMMUNE_DISEASES_SUCH_AS_LUPUS',
  MONITORING_OF_DEGENERATIVE_DISEASES_SUCH_AS_OSTEOARTHRITIS = 'MONITORING_OF_DEGENERATIVE_DISEASES_SUCH_AS_OSTEOARTHRITIS',
  MONITORING_OF_ONCOLOGICAL_CONDITIONS_SUCH_AS_METASTASIS = 'MONITORING_OF_ONCOLOGICAL_CONDITIONS_SUCH_AS_METASTASIS',
  MONITORING_OF_SPINAL_DISEASES_SUCH_AS_ANKYLOSING_SPONDYLITIS = 'MONITORING_OF_SPINAL_DISEASES_SUCH_AS_ANKYLOSING_SPONDYLITIS',
  MONITORING_THE_PROGRESS_OF_THE_HEALING_PROCESS = 'MONITORING_THE_PROGRESS_OF_THE_HEALING_PROCESS',
  MONOCYTOSIS_HIGH_NUMBER_OF_MONOCYTES = 'MONOCYTOSIS_HIGH_NUMBER_OF_MONOCYTES',
  MOOD_CHANGES = 'MOOD_CHANGES',
  MOOD_CHANGES_VARIANT_2 = 'MOOD_CHANGES_VARIANT_2',
  MOOD_DISORDERS = 'MOOD_DISORDERS',
  MOOD_DISORDERS_E_G_DEPRESSION_OR_BIPOLAR_DISORDER = 'MOOD_DISORDERS_E_G_DEPRESSION_OR_BIPOLAR_DISORDER',
  MOOD_DISORDERS_SUCH_AS_BIPOLAR_DISORDER = 'MOOD_DISORDERS_SUCH_AS_BIPOLAR_DISORDER',
  MOOD_PROBLEMS_ANXIETY_DEPRESSION_IRRITABILITY = 'MOOD_PROBLEMS_ANXIETY_DEPRESSION_IRRITABILITY',
  MOOD_SWINGS = 'MOOD_SWINGS',
  MOTOR_AGITATION = 'MOTOR_AGITATION',
  MOTOR_AND_FINE_MOTOR_DISABILITIES = 'MOTOR_AND_FINE_MOTOR_DISABILITIES',
  MOTOR_DISORDERS_E_G_COORDINATION_DIFFICULTIES = 'MOTOR_DISORDERS_E_G_COORDINATION_DIFFICULTIES',
  MULTIPLE_CHEMICAL_SENSITIVITY_SYNDROME_MCS = 'MULTIPLE_CHEMICAL_SENSITIVITY_SYNDROME_MCS',
  MULTIPLE_MYELOMA = 'MULTIPLE_MYELOMA',
  MULTIPLE_SCLEROSIS = 'MULTIPLE_SCLEROSIS',
  MULTI_ORGAN_FAILURE = 'MULTI_ORGAN_FAILURE',
  MUSCLE_AND_JOINT_PAIN = 'MUSCLE_AND_JOINT_PAIN',
  MUSCLE_AND_SKELETAL_PROBLEMS_ARTHRITIS_OSTEOPOROSIS = 'MUSCLE_AND_SKELETAL_PROBLEMS_ARTHRITIS_OSTEOPOROSIS',
  MUSCLE_INJURIES_SUCH_AS_STRAINS_OR_TEARS = 'MUSCLE_INJURIES_SUCH_AS_STRAINS_OR_TEARS',
  MUSCLE_OR_JOINT_PAIN = 'MUSCLE_OR_JOINT_PAIN',
  MUSCLE_OR_JOINT_PAIN_VARIANT_2 = 'MUSCLE_OR_JOINT_PAIN_VARIANT_2',
  MUSCLE_OR_TENDON_SYMPTOMS_SUCH_AS_INJURY_OR_INFLAMMATION = 'MUSCLE_OR_TENDON_SYMPTOMS_SUCH_AS_INJURY_OR_INFLAMMATION',
  MUSCLE_PAIN = 'MUSCLE_PAIN',
  MUSCLE_PAIN_IN_THE_QUADRICEPS = 'MUSCLE_PAIN_IN_THE_QUADRICEPS',
  MUSCLE_PAIN_OR_CONTRACTURES = 'MUSCLE_PAIN_OR_CONTRACTURES',
  MUSCLE_PAIN_OR_CONTRACTURES_IN_THE_SHOULDERS = 'MUSCLE_PAIN_OR_CONTRACTURES_IN_THE_SHOULDERS',
  MUSCLE_PAIN_VARIANT_2 = 'MUSCLE_PAIN_VARIANT_2',
  MUSCLE_SPASMS = 'MUSCLE_SPASMS',
  MUSCLE_STIFFNESS = 'MUSCLE_STIFFNESS',
  MUSCLE_WEAKNESS = 'MUSCLE_WEAKNESS',
  MUSCULOSKELETAL_PAIN = 'MUSCULOSKELETAL_PAIN',
  MYELITIS = 'MYELITIS',
  MYELODYSPLASIA_MYELODYSPLASTIC_SYNDROME = 'MYELODYSPLASIA_MYELODYSPLASTIC_SYNDROME',
  MYELOFIBROSIS_BONE_MARROW_FIBROSIS = 'MYELOFIBROSIS_BONE_MARROW_FIBROSIS',
  NASAL_BLEEDING = 'NASAL_BLEEDING',
  NASAL_BREATHING_PROBLEMS_OR_DIFFICULTY_IN_BREATHING = 'NASAL_BREATHING_PROBLEMS_OR_DIFFICULTY_IN_BREATHING',
  NASAL_CONGESTION = 'NASAL_CONGESTION',
  NASAL_CONGESTION_OR_STUFFY_NOSE = 'NASAL_CONGESTION_OR_STUFFY_NOSE',
  NASAL_OR_OCULAR_SECRETION = 'NASAL_OR_OCULAR_SECRETION',
  NASAL_SECRETION_RHINITIS = 'NASAL_SECRETION_RHINITIS',
  NASAL_VOICE = 'NASAL_VOICE',
  NAUSEA = 'NAUSEA',
  NAUSEA_AND_VOMITING = 'NAUSEA_AND_VOMITING',
  NECK_OR_BACK_PAIN = 'NECK_OR_BACK_PAIN',
  NECK_OR_JAW_PAIN_ASSOCIATED_WITH_CARDIAC_SYMPTOMS = 'NECK_OR_JAW_PAIN_ASSOCIATED_WITH_CARDIAC_SYMPTOMS',
  NECK_PAIN = 'NECK_PAIN',
  NECK_PAIN_VARIANT_2 = 'NECK_PAIN_VARIANT_2',
  NECK_PAIN_WITH_STIFFNESS_AND_LIMITATION_OF_MOVEMENT = 'NECK_PAIN_WITH_STIFFNESS_AND_LIMITATION_OF_MOVEMENT',
  NECK_STIFFNESS = 'NECK_STIFFNESS',
  NEED_TO_DO_SOMETHING = 'NEED_TO_DO_SOMETHING',
  NEED_TO_GO_TO_THE_BATHROOM_FREQUENTLY = 'NEED_TO_GO_TO_THE_BATHROOM_FREQUENTLY',
  NEPHRITIC_SYNDROME = 'NEPHRITIC_SYNDROME',
  NEPHROPATHY_FROM_KIDNEY_DISEASE_TO_CRYSTAL_DEPOSITS = 'NEPHROPATHY_FROM_KIDNEY_DISEASE_TO_CRYSTAL_DEPOSITS',
  NEPHROTIC_SYNDROME = 'NEPHROTIC_SYNDROME',
  NEUROENDOCRINE_TUMORS = 'NEUROENDOCRINE_TUMORS',
  NEUROGENIC_BLADDER_DYSFUNCTION = 'NEUROGENIC_BLADDER_DYSFUNCTION',
  NEUROLOGICAL_DISORDERS = 'NEUROLOGICAL_DISORDERS',
  NEUROLOGICAL_DISORDERS_SUCH_AS_SEIZURES = 'NEUROLOGICAL_DISORDERS_SUCH_AS_SEIZURES',
  NEUROLOGICAL_OR_NERVOUS_SYSTEM_DISORDERS = 'NEUROLOGICAL_OR_NERVOUS_SYSTEM_DISORDERS',
  NEUROLOGICAL_PROBLEMS_ALZHEIMER_S_STROKE = 'NEUROLOGICAL_PROBLEMS_ALZHEIMER_S_STROKE',
  NEUROLOGICAL_PROBLEMS_STROKE_PARKINSON_S_NEUROPATHY = 'NEUROLOGICAL_PROBLEMS_STROKE_PARKINSON_S_NEUROPATHY',
  NEUROLOGICAL_SYMPTOMS_ASSOCIATED_WITH_PHYSICAL_ACTIVITY = 'NEUROLOGICAL_SYMPTOMS_ASSOCIATED_WITH_PHYSICAL_ACTIVITY',
  NEUROLOGICAL_SYMPTOMS_CAUSED_BY_BRAIN_TUMORS = 'NEUROLOGICAL_SYMPTOMS_CAUSED_BY_BRAIN_TUMORS',
  NEUROLOGICAL_SYMPTOMS_RELATED_TO_VASCULAR_PROBLEMS_IN_THE_BRAIN = 'NEUROLOGICAL_SYMPTOMS_RELATED_TO_VASCULAR_PROBLEMS_IN_THE_BRAIN',
  NEUROLOGICAL_SYMPTOMS_SUCH_AS_MIGRAINES_OR_DIZZINESS = 'NEUROLOGICAL_SYMPTOMS_SUCH_AS_MIGRAINES_OR_DIZZINESS',
  NEUROLOGICAL_SYMPTOMS_SUCH_AS_MOVEMENT_DISORDERS_DEMENTIA = 'NEUROLOGICAL_SYMPTOMS_SUCH_AS_MOVEMENT_DISORDERS_DEMENTIA',
  NEUROLOGICAL_SYMPTOMS_SUCH_AS_PERSISTENT_HEADACHE_MOTOR_OR_SENSORY_DEFICITS = 'NEUROLOGICAL_SYMPTOMS_SUCH_AS_PERSISTENT_HEADACHE_MOTOR_OR_SENSORY_DEFICITS',
  NEUTROPENIA_LOW_NEUTROPHIL_COUNT = 'NEUTROPENIA_LOW_NEUTROPHIL_COUNT',
  NIGHTMARE_SLEEP_DISORDER = 'NIGHTMARE_SLEEP_DISORDER',
  NIGHTTIME_SKIN_ITCHING = 'NIGHTTIME_SKIN_ITCHING',
  NIGHT_COUGH = 'NIGHT_COUGH',
  NIGHT_VISION_DISORDERS = 'NIGHT_VISION_DISORDERS',
  NIPPLE_IRRITATION_OR_INFLAMMATION = 'NIPPLE_IRRITATION_OR_INFLAMMATION',
  NOCTURNAL_HEMATURIA = 'NOCTURNAL_HEMATURIA',
  NOCTURNAL_NASAL_BLEEDING = 'NOCTURNAL_NASAL_BLEEDING',
  NOCTURNAL_POLYDIPSIA = 'NOCTURNAL_POLYDIPSIA',
  NODULES_OR_LUMPS_IN_THE_SKIN = 'NODULES_OR_LUMPS_IN_THE_SKIN',
  NODULES_OR_MASSES_IN_THE_BREAST = 'NODULES_OR_MASSES_IN_THE_BREAST',
  NODULES_OR_PALPABLE_MASSES = 'NODULES_OR_PALPABLE_MASSES',
  NOSE_RESHAPING_SURGERY_RHINOPLASTY = 'NOSE_RESHAPING_SURGERY_RHINOPLASTY',
  NOT_BEING_ABLE_TO_CLOSE_MY_MOUTH = 'NOT_BEING_ABLE_TO_CLOSE_MY_MOUTH',
  NOT_BEING_ABLE_TO_OPEN_YOUR_MOUTH = 'NOT_BEING_ABLE_TO_OPEN_YOUR_MOUTH',
  NUMBNESS_OR_TINGLING_IN_A_PART_OF_THE_BODY = 'NUMBNESS_OR_TINGLING_IN_A_PART_OF_THE_BODY',
  NUMBNESS_OR_WEAKNESS_IN_THE_ARMS_OR_LEGS = 'NUMBNESS_OR_WEAKNESS_IN_THE_ARMS_OR_LEGS',
  NUTRITION_OR_DIETARY_PROBLEMS = 'NUTRITION_OR_DIETARY_PROBLEMS',
  NUTRITION_RELATED_BREATHING_DISORDERS = 'NUTRITION_RELATED_BREATHING_DISORDERS',
  NUTRITION_RELATED_CARDIOVASCULAR_DISORDERS = 'NUTRITION_RELATED_CARDIOVASCULAR_DISORDERS',
  NUTRITION_RELATED_COAGULATION_DISORDERS = 'NUTRITION_RELATED_COAGULATION_DISORDERS',
  NUTRITION_RELATED_IMMUNE_SYSTEM_DISORDERS = 'NUTRITION_RELATED_IMMUNE_SYSTEM_DISORDERS',
  NUTRITION_RELATED_NEUROLOGICAL_DISORDERS = 'NUTRITION_RELATED_NEUROLOGICAL_DISORDERS',
  OBESITY = 'OBESITY',
  OBSERVATION_OF_FOREIGN_BODIES = 'OBSERVATION_OF_FOREIGN_BODIES',
  OBSESSIVE_COMPULSIVE_DISORDER_OCD = 'OBSESSIVE_COMPULSIVE_DISORDER_OCD',
  OBSESSIVE_COMPULSIVE_DISORDER_OCD_VARIANT_2 = 'OBSESSIVE_COMPULSIVE_DISORDER_OCD_VARIANT_2',
  OBSTRUCTIVE_SLEEP_APNEA_OR_SNORING = 'OBSTRUCTIVE_SLEEP_APNEA_OR_SNORING',
  ODOR_OF_URINE_PEE = 'ODOR_OF_URINE_PEE',
  OILY_OR_EXCESSIVELY_SEBACEOUS_SKIN = 'OILY_OR_EXCESSIVELY_SEBACEOUS_SKIN',
  OLIGOMENORRHEA_MENSTRUAL_CYCLES_THAT_ARE_TOO_INFREQUENT = 'OLIGOMENORRHEA_MENSTRUAL_CYCLES_THAT_ARE_TOO_INFREQUENT',
  OLIGURIA_INSUFFICIENT_URINE_PRODUCTION = 'OLIGURIA_INSUFFICIENT_URINE_PRODUCTION',
  ONCOLOGICAL_PROBLEMS_CANCER_BENIGN_TUMORS = 'ONCOLOGICAL_PROBLEMS_CANCER_BENIGN_TUMORS',
  ONCOLOGICAL_PROBLEMS_TUMORS_CANCER = 'ONCOLOGICAL_PROBLEMS_TUMORS_CANCER',
  OPTIMIZATION_OF_NUTRITION_AND_HYDRATION_FOR_SPORTS_ACTIVITY = 'OPTIMIZATION_OF_NUTRITION_AND_HYDRATION_FOR_SPORTS_ACTIVITY',
  ORAL_CANKER_SORE_OR_LESIONS = 'ORAL_CANKER_SORE_OR_LESIONS',
  ORAL_CYSTS_OR_TUMORS = 'ORAL_CYSTS_OR_TUMORS',
  ORAL_HYGIENE_PROBLEMS = 'ORAL_HYGIENE_PROBLEMS',
  ORAL_LESIONS_OR_ULCERATIONS = 'ORAL_LESIONS_OR_ULCERATIONS',
  ORAL_ULCERS = 'ORAL_ULCERS',
  ORTHOGNATHIC_SURGERY_FOR_CORRECTION_OF_MALOCCLUSIONS = 'ORTHOGNATHIC_SURGERY_FOR_CORRECTION_OF_MALOCCLUSIONS',
  ORTHOPEDIC_OR_MUSCULOSKELETAL_PROBLEMS = 'ORTHOPEDIC_OR_MUSCULOSKELETAL_PROBLEMS',
  OSTEOARTICULAR_INFECTIONS = 'OSTEOARTICULAR_INFECTIONS',
  OSTEOPOROSIS = 'OSTEOPOROSIS',
  OSTEOPOROSIS_OR_BONE_FRAGILITY = 'OSTEOPOROSIS_OR_BONE_FRAGILITY',
  OTITIS_INFLAMMATION_OF_THE_EAR = 'OTITIS_INFLAMMATION_OF_THE_EAR',
  OTOSCLEROSIS_STIFFNESS_OF_THE_EAR_BONES = 'OTOSCLEROSIS_STIFFNESS_OF_THE_EAR_BONES',
  OVARIAN_CYSTS = 'OVARIAN_CYSTS',
  OVARIAN_PAIN = 'OVARIAN_PAIN',
  OVARIAN_PAIN_IN_WOMEN = 'OVARIAN_PAIN_IN_WOMEN',
  PAINFUL_BLISTERS_OR_SORES = 'PAINFUL_BLISTERS_OR_SORES',
  PAINFUL_EJACULATION = 'PAINFUL_EJACULATION',
  PAINFUL_OR_DIFFICULT_URINATION = 'PAINFUL_OR_DIFFICULT_URINATION',
  PAINFUL_UDDERS = 'PAINFUL_UDDERS',
  PAINFUL_URINE = 'PAINFUL_URINE',
  PAIN_ACHING_FEET = 'PAIN_ACHING_FEET',
  PAIN_AND_SWELLING_IN_THE_WRIST_OR_HANDS = 'PAIN_AND_SWELLING_IN_THE_WRIST_OR_HANDS',
  PAIN_AND_SWELLING_OF_THE_VEINS_THROMBOPHLEBITIS = 'PAIN_AND_SWELLING_OF_THE_VEINS_THROMBOPHLEBITIS',
  PAIN_ASSOCIATED_WITH_CANCER = 'PAIN_ASSOCIATED_WITH_CANCER',
  PAIN_CHEST_PAIN = 'PAIN_CHEST_PAIN',
  PAIN_DURING_DEFECATION = 'PAIN_DURING_DEFECATION',
  PAIN_DURING_OVULATION = 'PAIN_DURING_OVULATION',
  PAIN_DURING_PHYSICAL_ACTIVITY = 'PAIN_DURING_PHYSICAL_ACTIVITY',
  PAIN_DURING_PREGNANCY = 'PAIN_DURING_PREGNANCY',
  PAIN_DURING_SEXUAL_INTERCOURSE = 'PAIN_DURING_SEXUAL_INTERCOURSE',
  PAIN_DURING_SEXUAL_INTERCOURSE_DYSPAREUNIA = 'PAIN_DURING_SEXUAL_INTERCOURSE_DYSPAREUNIA',
  PAIN_DURING_URINATION_IF_RELATED_TO_DIGESTIVE_PROBLEMS = 'PAIN_DURING_URINATION_IF_RELATED_TO_DIGESTIVE_PROBLEMS',
  PAIN_FROM_CARPAL_TUNNEL_SYNDROME = 'PAIN_FROM_CARPAL_TUNNEL_SYNDROME',
  PAIN_FROM_POOR_POSTURE = 'PAIN_FROM_POOR_POSTURE',
  PAIN_FROM_RHEUMATIC_OR_MUSCULOSKELETAL_CONDITIONS = 'PAIN_FROM_RHEUMATIC_OR_MUSCULOSKELETAL_CONDITIONS',
  PAIN_FROM_SPORTS_INJURIES = 'PAIN_FROM_SPORTS_INJURIES',
  PAIN_FROM_TEMPOROMANDIBULAR_DYSFUNCTION_TMD = 'PAIN_FROM_TEMPOROMANDIBULAR_DYSFUNCTION_TMD',
  PAIN_FROM_TENSION_HEADACHE_OR_MIGRAINE_HEADACHE = 'PAIN_FROM_TENSION_HEADACHE_OR_MIGRAINE_HEADACHE',
  PAIN_IN_THE_ABDOMEN_OR_BACK_AREA_ASSOCIATED_WITH_AORTIC_PROBLEMS = 'PAIN_IN_THE_ABDOMEN_OR_BACK_AREA_ASSOCIATED_WITH_AORTIC_PROBLEMS',
  PAIN_IN_THE_ANKLES = 'PAIN_IN_THE_ANKLES',
  PAIN_IN_THE_FINGERS_OF_THE_HANDS = 'PAIN_IN_THE_FINGERS_OF_THE_HANDS',
  PAIN_IN_THE_HIPS = 'PAIN_IN_THE_HIPS',
  PAIN_IN_THE_KIDNEYS_OR_LOW_BACK = 'PAIN_IN_THE_KIDNEYS_OR_LOW_BACK',
  PAIN_IN_THE_LEFT_ARM_OR_SHOULDER = 'PAIN_IN_THE_LEFT_ARM_OR_SHOULDER',
  PAIN_IN_THE_LEFT_LOWER_ABDOMEN = 'PAIN_IN_THE_LEFT_LOWER_ABDOMEN',
  PAIN_IN_THE_LEFT_LOWER_QUADRANT_INDICATIVE_OF_PROBLEMS_IN_THE_COLON = 'PAIN_IN_THE_LEFT_LOWER_QUADRANT_INDICATIVE_OF_PROBLEMS_IN_THE_COLON',
  PAIN_IN_THE_LEFT_UPPER_QUADRANT_INDICATIVE_OF_PROBLEMS_IN_THE_SPLEEN_OR_COLON = 'PAIN_IN_THE_LEFT_UPPER_QUADRANT_INDICATIVE_OF_PROBLEMS_IN_THE_SPLEEN_OR_COLON',
  PAIN_IN_THE_LOWER_ABDOMEN = 'PAIN_IN_THE_LOWER_ABDOMEN',
  PAIN_IN_THE_LOWER_BACK_OR_KIDNEY_REGION = 'PAIN_IN_THE_LOWER_BACK_OR_KIDNEY_REGION',
  PAIN_IN_THE_LUMBAR_OR_SACRAL_REGION = 'PAIN_IN_THE_LUMBAR_OR_SACRAL_REGION',
  PAIN_IN_THE_NECK_OF_THE_FEMUR = 'PAIN_IN_THE_NECK_OF_THE_FEMUR',
  PAIN_IN_THE_NECK_OF_THE_SCAPULA = 'PAIN_IN_THE_NECK_OF_THE_SCAPULA',
  PAIN_IN_THE_PELVIC_OR_GENITAL_AREA = 'PAIN_IN_THE_PELVIC_OR_GENITAL_AREA',
  PAIN_IN_THE_POSTERIOR_SHOULDER = 'PAIN_IN_THE_POSTERIOR_SHOULDER',
  PAIN_IN_THE_RIBS_OR_CHEST = 'PAIN_IN_THE_RIBS_OR_CHEST',
  PAIN_IN_THE_RIGHT_ARM_OR_SHOULDER = 'PAIN_IN_THE_RIGHT_ARM_OR_SHOULDER',
  PAIN_IN_THE_RIGHT_LOWER_QUADRANT_INDICATIVE_OF_APPENDIX_OR_GALLBLADDER_PROBLEMS = 'PAIN_IN_THE_RIGHT_LOWER_QUADRANT_INDICATIVE_OF_APPENDIX_OR_GALLBLADDER_PROBLEMS',
  PAIN_IN_THE_RIGHT_SIDE_UNDER_THE_RIBS = 'PAIN_IN_THE_RIGHT_SIDE_UNDER_THE_RIBS',
  PAIN_IN_THE_RIGHT_UPPER_ABDOMEN = 'PAIN_IN_THE_RIGHT_UPPER_ABDOMEN',
  PAIN_IN_THE_RIGHT_UPPER_QUADRANT_INDICATIVE_OF_PROBLEMS_IN_THE_LIVER_OR_GALLBLADDER = 'PAIN_IN_THE_RIGHT_UPPER_QUADRANT_INDICATIVE_OF_PROBLEMS_IN_THE_LIVER_OR_GALLBLADDER',
  PAIN_IN_THE_SPINE = 'PAIN_IN_THE_SPINE',
  PAIN_IN_THE_TEETH_OR_GUMS = 'PAIN_IN_THE_TEETH_OR_GUMS',
  PAIN_IN_THE_TEMPOROMANDIBULAR_JOINT_TMJ = 'PAIN_IN_THE_TEMPOROMANDIBULAR_JOINT_TMJ',
  PAIN_IN_THE_TESTICLES_OR_PENIS = 'PAIN_IN_THE_TESTICLES_OR_PENIS',
  PAIN_IN_THE_TOES = 'PAIN_IN_THE_TOES',
  PAIN_IN_THE_UPPER_THIGH_OR_GROIN = 'PAIN_IN_THE_UPPER_THIGH_OR_GROIN',
  PAIN_IN_THE_WRISTS = 'PAIN_IN_THE_WRISTS',
  PAIN_IN_THE_WRIST_OF_THE_FOOT = 'PAIN_IN_THE_WRIST_OF_THE_FOOT',
  PAIN_IN_THE_WRIST_OF_THE_HAND = 'PAIN_IN_THE_WRIST_OF_THE_HAND',
  PAIN_JOINT_PAIN = 'PAIN_JOINT_PAIN',
  PAIN_OR_BURNING_DURING_URINATION = 'PAIN_OR_BURNING_DURING_URINATION',
  PAIN_OR_DIFFICULTY_DURING_URINATION = 'PAIN_OR_DIFFICULTY_DURING_URINATION',
  PAIN_OR_DISABILITY_RELATED_TO_HAND_OR_WRIST_CONDITIONS = 'PAIN_OR_DISABILITY_RELATED_TO_HAND_OR_WRIST_CONDITIONS',
  PAIN_OR_DISABILITY_RELATED_TO_MUSCLE_OR_TENDON_CONDITIONS_SUCH_AS_TENDINITIS = 'PAIN_OR_DISABILITY_RELATED_TO_MUSCLE_OR_TENDON_CONDITIONS_SUCH_AS_TENDINITIS',
  PAIN_OR_DISABILITY_RELATED_TO_SHOULDER_OR_ARM_DISEASE = 'PAIN_OR_DISABILITY_RELATED_TO_SHOULDER_OR_ARM_DISEASE',
  PAIN_OR_DISCOMFORT_IN_THE_EYES = 'PAIN_OR_DISCOMFORT_IN_THE_EYES',
  PAIN_OR_DISCOMFORT_IN_THE_LEGS = 'PAIN_OR_DISCOMFORT_IN_THE_LEGS',
  PAIN_OR_DISCOMFORT_IN_THE_THROAT = 'PAIN_OR_DISCOMFORT_IN_THE_THROAT',
  PAIN_OR_DISORDERS_ASSOCIATED_WITH_THE_PERIPHERAL_NERVOUS_SYSTEM = 'PAIN_OR_DISORDERS_ASSOCIATED_WITH_THE_PERIPHERAL_NERVOUS_SYSTEM',
  PAIN_OR_DYSFUNCTION_CAUSED_BY_SPINAL_CORD_OR_SPINAL_CORD_INJURY = 'PAIN_OR_DYSFUNCTION_CAUSED_BY_SPINAL_CORD_OR_SPINAL_CORD_INJURY',
  PAIN_OR_DYSFUNCTION_PROBLEMS_RELATED_TO_HIP_OR_KNEE_DISEASE = 'PAIN_OR_DYSFUNCTION_PROBLEMS_RELATED_TO_HIP_OR_KNEE_DISEASE',
  PAIN_OR_FUNCTIONAL_LIMITATIONS_RELATED_TO_HIP_OR_KNEE_DISEASE = 'PAIN_OR_FUNCTIONAL_LIMITATIONS_RELATED_TO_HIP_OR_KNEE_DISEASE',
  PAIN_OR_HEART_FAILURE = 'PAIN_OR_HEART_FAILURE',
  PAIN_OR_INJURY_IN_THE_ELBOW_OR_FOREARM = 'PAIN_OR_INJURY_IN_THE_ELBOW_OR_FOREARM',
  PAIN_OR_INJURY_IN_THE_HANDS_OR_WRISTS = 'PAIN_OR_INJURY_IN_THE_HANDS_OR_WRISTS',
  PAIN_OR_INJURY_IN_THE_WRISTS = 'PAIN_OR_INJURY_IN_THE_WRISTS',
  PAIN_OR_PROBLEMS_CAUSED_BY_HERNIATED_DISCS = 'PAIN_OR_PROBLEMS_CAUSED_BY_HERNIATED_DISCS',
  PAIN_OR_PROBLEMS_WITH_FINGERS = 'PAIN_OR_PROBLEMS_WITH_FINGERS',
  PAIN_OR_STIFFNESS_IN_THE_NECK = 'PAIN_OR_STIFFNESS_IN_THE_NECK',
  PAIN_OR_STIFFNESS_IN_THE_NECK_OR_BACK = 'PAIN_OR_STIFFNESS_IN_THE_NECK_OR_BACK',
  PAIN_OR_SWELLING_IN_THE_JOINTS = 'PAIN_OR_SWELLING_IN_THE_JOINTS',
  PAIN_OR_SWELLING_IN_THE_LEGS = 'PAIN_OR_SWELLING_IN_THE_LEGS',
  PAIN_OR_SWELLING_IN_THE_NECK = 'PAIN_OR_SWELLING_IN_THE_NECK',
  PAIN_PAIN_IN_HANDS = 'PAIN_PAIN_IN_HANDS',
  PALMAR_HAND_PAIN = 'PALMAR_HAND_PAIN',
  PALPITATIONS = 'PALPITATIONS',
  PALPITATIONS_OR_IRREGULAR_HEARTBEAT = 'PALPITATIONS_OR_IRREGULAR_HEARTBEAT',
  PANCREATIC_PROBLEMS_SUCH_AS_PANCREATITIS = 'PANCREATIC_PROBLEMS_SUCH_AS_PANCREATITIS',
  PANIC_ATTACKS = 'PANIC_ATTACKS',
  PANIC_DISORDER = 'PANIC_DISORDER',
  PARALYSIS = 'PARALYSIS',
  PARALYSIS_DIFFICULTY_MOVING = 'PARALYSIS_DIFFICULTY_MOVING',
  PARASITOSIS = 'PARASITOSIS',
  PARKINSON_S_DISEASE = 'PARKINSON_S_DISEASE',
  PAROXYSMAL_NOCTURNAL_DYSPNEA = 'PAROXYSMAL_NOCTURNAL_DYSPNEA',
  PATHOLOGICAL_GAMBLING_DISORDER = 'PATHOLOGICAL_GAMBLING_DISORDER',
  PATHOLOGIES_OF_THE_LARYNX_LARYNGITIS_PAPILLOMAS = 'PATHOLOGIES_OF_THE_LARYNX_LARYNGITIS_PAPILLOMAS',
  PEDIATRIC_CANCERS = 'PEDIATRIC_CANCERS',
  PELVIC_FLOOR_FUNCTION_DISORDERS_URINARY_INCONTINENCE = 'PELVIC_FLOOR_FUNCTION_DISORDERS_URINARY_INCONTINENCE',
  PELVIC_FLOOR_SYMPTOMS = 'PELVIC_FLOOR_SYMPTOMS',
  PELVIC_INFLAMMATORY_DISEASES_PIDS = 'PELVIC_INFLAMMATORY_DISEASES_PIDS',
  PELVIC_PAIN = 'PELVIC_PAIN',
  PELVIC_PAIN_VARIANT_2 = 'PELVIC_PAIN_VARIANT_2',
  PENILE_PROSTHESIS_CORRECTION_SURGERY = 'PENILE_PROSTHESIS_CORRECTION_SURGERY',
  PENILE_TUMORS = 'PENILE_TUMORS',
  PERCEPTION_OF_FLASHING_LIGHTS_OR_SPARKS = 'PERCEPTION_OF_FLASHING_LIGHTS_OR_SPARKS',
  PERIODONTITIS_INFLAMMATION_OF_THE_SUPPORTING_STRUCTURES_OF_THE_TEETH = 'PERIODONTITIS_INFLAMMATION_OF_THE_SUPPORTING_STRUCTURES_OF_THE_TEETH',
  PERIPHERAL_NEUROPATHY = 'PERIPHERAL_NEUROPATHY',
  PERSECUTION_MANIA = 'PERSECUTION_MANIA',
  PERSISTENT_BACK_PAIN = 'PERSISTENT_BACK_PAIN',
  PERSISTENT_CONSTIPATION = 'PERSISTENT_CONSTIPATION',
  PERSISTENT_COUGH = 'PERSISTENT_COUGH',
  PERSISTENT_FATIGUE = 'PERSISTENT_FATIGUE',
  PERSISTENT_FATIGUE_IF_RELATED_TO_DIGESTIVE_DISORDERS = 'PERSISTENT_FATIGUE_IF_RELATED_TO_DIGESTIVE_DISORDERS',
  PERSISTENT_HEADACHES_OR_CHANGES_IN_VISION = 'PERSISTENT_HEADACHES_OR_CHANGES_IN_VISION',
  PERSISTENT_OR_CHRONIC_COUGH = 'PERSISTENT_OR_CHRONIC_COUGH',
  PERSISTENT_OR_ENLARGED_LYMPHADENOPATHY = 'PERSISTENT_OR_ENLARGED_LYMPHADENOPATHY',
  PERSISTENT_OR_SEVERE_HEADACHE = 'PERSISTENT_OR_SEVERE_HEADACHE',
  PERSISTENT_PAIN_AT_REST_IN_THE_LEGS = 'PERSISTENT_PAIN_AT_REST_IN_THE_LEGS',
  PERSISTENT_SORE_THROAT = 'PERSISTENT_SORE_THROAT',
  PERSONALITY_DISORDERS_BORDERLINE_NARCISSISTIC_ANTISOCIAL = 'PERSONALITY_DISORDERS_BORDERLINE_NARCISSISTIC_ANTISOCIAL',
  PERSONALITY_DISORDER_BORDERLINE_ANTISOCIAL_ETC = 'PERSONALITY_DISORDER_BORDERLINE_ANTISOCIAL_ETC',
  PETECHIAE_SMALL_SPOTS_OF_BLOOD_ON_THE_SKIN = 'PETECHIAE_SMALL_SPOTS_OF_BLOOD_ON_THE_SKIN',
  PHARYNGITIS = 'PHARYNGITIS',
  PHARYNGODYNIA_THROAT_PAIN = 'PHARYNGODYNIA_THROAT_PAIN',
  PHOBIAS_AND_IRRATIONAL_FEARS = 'PHOBIAS_AND_IRRATIONAL_FEARS',
  PHOBIAS_AND_OBSESSIVE_COMPULSIVE_DISORDERS_OCD = 'PHOBIAS_AND_OBSESSIVE_COMPULSIVE_DISORDERS_OCD',
  PHONOLOGY_DISORDERS_DIFFICULTIES_WITH_SPEECH_SOUNDS = 'PHONOLOGY_DISORDERS_DIFFICULTIES_WITH_SPEECH_SOUNDS',
  PHYSICAL_ILLNESS_STRESS_DISORDER = 'PHYSICAL_ILLNESS_STRESS_DISORDER',
  PICKWICK_S_SYNDROME_OBESITY_DAYTIME_SLEEPINESS_DYSPNEA = 'PICKWICK_S_SYNDROME_OBESITY_DAYTIME_SLEEPINESS_DYSPNEA',
  PLANTAR_FOOT_PAIN = 'PLANTAR_FOOT_PAIN',
  PLEURITIC_PAIN = 'PLEURITIC_PAIN',
  PLEURITIS_CHEST_PAIN_DYSPNEA_DRY_COUGH = 'PLEURITIS_CHEST_PAIN_DYSPNEA_DRY_COUGH',
  PLUGGED_EAR_OR_FEELING_OF_PRESSURE = 'PLUGGED_EAR_OR_FEELING_OF_PRESSURE',
  PLUGGED_NOSE = 'PLUGGED_NOSE',
  PNEUMOCONIOSIS_COUGH_DYSPNEA_SPUTUM_PRODUCTION = 'PNEUMOCONIOSIS_COUGH_DYSPNEA_SPUTUM_PRODUCTION',
  PNEUMONIA_FEVER_COUGH_DYSPNEA_CHEST_PAIN = 'PNEUMONIA_FEVER_COUGH_DYSPNEA_CHEST_PAIN',
  PNEUMOTHORAX_CHEST_PAIN_DYSPNEA_RAPID_BREATHING = 'PNEUMOTHORAX_CHEST_PAIN_DYSPNEA_RAPID_BREATHING',
  POLYCYSTIC_OVARY_SYNDROME_PCOS = 'POLYCYSTIC_OVARY_SYNDROME_PCOS',
  POLYDIPSIA_EXCESSIVE_THIRST = 'POLYDIPSIA_EXCESSIVE_THIRST',
  POLYMENORRHEA_TOO_FREQUENT_MENSTRUAL_CYCLES = 'POLYMENORRHEA_TOO_FREQUENT_MENSTRUAL_CYCLES',
  POLYPHAGIA_EXCESSIVE_APPETITE = 'POLYPHAGIA_EXCESSIVE_APPETITE',
  POLYPHAGIA_INCREASED_HUNGER = 'POLYPHAGIA_INCREASED_HUNGER',
  POLYPHARMACY_USE_OF_MANY_DRUGS = 'POLYPHARMACY_USE_OF_MANY_DRUGS',
  POLYURIA_EXCESSIVE_URINE_PRODUCTION = 'POLYURIA_EXCESSIVE_URINE_PRODUCTION',
  POLYURIA_FREQUENT_URINATION = 'POLYURIA_FREQUENT_URINATION',
  POSTERIOR_HIP_PAIN = 'POSTERIOR_HIP_PAIN',
  POSTMENOPAUSAL_BLEEDING = 'POSTMENOPAUSAL_BLEEDING',
  POSTNATAL_STRESS_DISORDER = 'POSTNATAL_STRESS_DISORDER',
  POSTURE_PROBLEMS = 'POSTURE_PROBLEMS',
  POST_MASTECTOMY_BREAST_RECONSTRUCTION = 'POST_MASTECTOMY_BREAST_RECONSTRUCTION',
  POST_TRAINING_OR_POST_RACE_RECOVERY_MANAGEMENT = 'POST_TRAINING_OR_POST_RACE_RECOVERY_MANAGEMENT',
  POST_TRAUMATIC_STRESS_DISORDER_PTSD = 'POST_TRAUMATIC_STRESS_DISORDER_PTSD',
  POST_TRAUMATIC_STRESS_DISORDER_PTSD_VARIANT_2 = 'POST_TRAUMATIC_STRESS_DISORDER_PTSD_VARIANT_2',
  PRAGMATIC_DIFFICULTIES_IN_SOCIAL_AND_COMMUNICATION_SKILLS = 'PRAGMATIC_DIFFICULTIES_IN_SOCIAL_AND_COMMUNICATION_SKILLS',
  PREMATURE_EJACULATION = 'PREMATURE_EJACULATION',
  PREMATURE_OR_DELAYED_EJACULATION = 'PREMATURE_OR_DELAYED_EJACULATION',
  PREMATURE_RELEASE_SYNDROME_OF_THE_COSTOCLAVICULAR_LIGAMENT_PAGET_SCHROETTER_SYNDROME = 'PREMATURE_RELEASE_SYNDROME_OF_THE_COSTOCLAVICULAR_LIGAMENT_PAGET_SCHROETTER_SYNDROME',
  PREMENSTRUAL_SYNDROME_PMS = 'PREMENSTRUAL_SYNDROME_PMS',
  PRESENCE_OF_ABDOMINAL_MASSES_OR_TUMORS = 'PRESENCE_OF_ABDOMINAL_MASSES_OR_TUMORS',
  PRESENCE_OF_BLOOD_IN_THE_STOOL_MELENA_OR_HEMATOCHEZIA = 'PRESENCE_OF_BLOOD_IN_THE_STOOL_MELENA_OR_HEMATOCHEZIA',
  PRESENCE_OF_FOREIGN_BODY_IN_THE_ESOPHAGUS = 'PRESENCE_OF_FOREIGN_BODY_IN_THE_ESOPHAGUS',
  PRESENCE_OF_NASAL_POLYPS_OR_OTHER_MASSES = 'PRESENCE_OF_NASAL_POLYPS_OR_OTHER_MASSES',
  PRESENCE_OF_PULMONARY_MASS_OR_NODULES = 'PRESENCE_OF_PULMONARY_MASS_OR_NODULES',
  PRESENCE_OF_SPOTS_OR_FLOATERS_IN_THE_VISUAL_FIELD = 'PRESENCE_OF_SPOTS_OR_FLOATERS_IN_THE_VISUAL_FIELD',
  PRESSURE_ULCERS_PRESSURE_SORES = 'PRESSURE_ULCERS_PRESSURE_SORES',
  PREVENTION_OF_ULCER_OR_WOUND_RECURRENCE = 'PREVENTION_OF_ULCER_OR_WOUND_RECURRENCE',
  PROBLEMS_ADAPTING_TO_CONTACT_LENSES_OR_GLASSES = 'PROBLEMS_ADAPTING_TO_CONTACT_LENSES_OR_GLASSES',
  PROBLEMS_AFTER_EATING = 'PROBLEMS_AFTER_EATING',
  PROBLEMS_ASSOCIATED_WITH_HYDROCEPHALUS_OR_ABNORMAL_ACCUMULATION_OF_CEREBROSPINAL_FLUID = 'PROBLEMS_ASSOCIATED_WITH_HYDROCEPHALUS_OR_ABNORMAL_ACCUMULATION_OF_CEREBROSPINAL_FLUID',
  PROBLEMS_HEAL = 'PROBLEMS_HEAL',
  PROBLEMS_IN_DECISION_MAKING_ABILITY_AND_RISK_ASSESSMENT = 'PROBLEMS_IN_DECISION_MAKING_ABILITY_AND_RISK_ASSESSMENT',
  PROBLEMS_IN_HORMONE_BALANCE_REGULATION = 'PROBLEMS_IN_HORMONE_BALANCE_REGULATION',
  PROBLEMS_IN_NONVERBAL_COMMUNICATION = 'PROBLEMS_IN_NONVERBAL_COMMUNICATION',
  PROBLEMS_IN_PERSONAL_ORIENTATION_AND_IDENTITY = 'PROBLEMS_IN_PERSONAL_ORIENTATION_AND_IDENTITY',
  PROBLEMS_IN_RELATIONSHIPS_WITH_FOOD = 'PROBLEMS_IN_RELATIONSHIPS_WITH_FOOD',
  PROBLEMS_IN_RELATIONSHIPS_WITH_OTHER_PEOPLE = 'PROBLEMS_IN_RELATIONSHIPS_WITH_OTHER_PEOPLE',
  PROBLEMS_IN_SENSORY_PERCEPTION = 'PROBLEMS_IN_SENSORY_PERCEPTION',
  PROBLEMS_IN_SLEEP_WAKE_RHYTHM_REGULATION = 'PROBLEMS_IN_SLEEP_WAKE_RHYTHM_REGULATION',
  PROBLEMS_IN_SPATIAL_ORGANIZATION_AND_NAVIGATION = 'PROBLEMS_IN_SPATIAL_ORGANIZATION_AND_NAVIGATION',
  PROBLEMS_IN_SWALLOWING_DYSPHAGIA = 'PROBLEMS_IN_SWALLOWING_DYSPHAGIA',
  PROBLEMS_IN_THE_ABILITY_TO_LEARN_NEW_INFORMATION = 'PROBLEMS_IN_THE_ABILITY_TO_LEARN_NEW_INFORMATION',
  PROBLEMS_IN_THE_FUNCTION_OF_THE_AUTONOMIC_NERVOUS_SYSTEM = 'PROBLEMS_IN_THE_FUNCTION_OF_THE_AUTONOMIC_NERVOUS_SYSTEM',
  PROBLEMS_IN_THE_MODULATION_OF_EMOTION = 'PROBLEMS_IN_THE_MODULATION_OF_EMOTION',
  PROBLEMS_IN_THE_PERCEPTION_OF_SPATIALITY = 'PROBLEMS_IN_THE_PERCEPTION_OF_SPATIALITY',
  PROBLEMS_OF_ADAPTATION_AND_QUALITY_OF_LIFE = 'PROBLEMS_OF_ADAPTATION_AND_QUALITY_OF_LIFE',
  PROBLEMS_OF_ADAPTATION_TO_PROSTHESES_OR_ORTHOPEDIC_AIDS = 'PROBLEMS_OF_ADAPTATION_TO_PROSTHESES_OR_ORTHOPEDIC_AIDS',
  PROBLEMS_OF_HEARING_OR_OTOLARYNGOLOGY = 'PROBLEMS_OF_HEARING_OR_OTOLARYNGOLOGY',
  PROBLEMS_OF_PAIN_OR_DYSFUNCTION_RELATED_TO_FOOT_OR_ANKLE_DISEASE = 'PROBLEMS_OF_PAIN_OR_DYSFUNCTION_RELATED_TO_FOOT_OR_ANKLE_DISEASE',
  PROBLEMS_OF_PAIN_OR_DYSFUNCTION_RELATED_TO_LOWER_LIMB_DISEASE = 'PROBLEMS_OF_PAIN_OR_DYSFUNCTION_RELATED_TO_LOWER_LIMB_DISEASE',
  PROBLEMS_OF_PAIN_OR_DYSFUNCTION_RELATED_TO_SPINAL_DISEASES = 'PROBLEMS_OF_PAIN_OR_DYSFUNCTION_RELATED_TO_SPINAL_DISEASES',
  PROBLEMS_OF_PAIN_OR_DYSFUNCTION_RELATED_TO_UPPER_LIMB_DISORDERS = 'PROBLEMS_OF_PAIN_OR_DYSFUNCTION_RELATED_TO_UPPER_LIMB_DISORDERS',
  PROBLEMS_OF_PAIN_OR_NERVE_COMPRESSION_IN_THE_NECK_OR_BACK = 'PROBLEMS_OF_PAIN_OR_NERVE_COMPRESSION_IN_THE_NECK_OR_BACK',
  PROBLEMS_OF_SYSTEMIC_DISEASES_RELATED_TO_THE_MOUTH_E_G_DIABETES = 'PROBLEMS_OF_SYSTEMIC_DISEASES_RELATED_TO_THE_MOUTH_E_G_DIABETES',
  PROBLEMS_RELATED_TO_FOOT_OR_ANKLE_DISORDERS = 'PROBLEMS_RELATED_TO_FOOT_OR_ANKLE_DISORDERS',
  PROBLEMS_RELATED_TO_INJURY_OR_SURGERY = 'PROBLEMS_RELATED_TO_INJURY_OR_SURGERY',
  PROBLEMS_RELATED_TO_NEUROLOGICAL_DISEASES_SUCH_AS_MULTIPLE_SCLEROSIS = 'PROBLEMS_RELATED_TO_NEUROLOGICAL_DISEASES_SUCH_AS_MULTIPLE_SCLEROSIS',
  PROBLEMS_RELATED_TO_SPINAL_CORD_INJURY = 'PROBLEMS_RELATED_TO_SPINAL_CORD_INJURY',
  PROBLEMS_WITH_BREATHING_OR_DYSPNEA = 'PROBLEMS_WITH_BREATHING_OR_DYSPNEA',
  PROBLEMS_WITH_CONCENTRATION_OR_ATTENTION = 'PROBLEMS_WITH_CONCENTRATION_OR_ATTENTION',
  PROBLEMS_WITH_CONCENTRATION_OR_MEMORY = 'PROBLEMS_WITH_CONCENTRATION_OR_MEMORY',
  PROBLEMS_WITH_COORDINATION_OR_BALANCE = 'PROBLEMS_WITH_COORDINATION_OR_BALANCE',
  PROBLEMS_WITH_COORDINATION_OR_MOVEMENT = 'PROBLEMS_WITH_COORDINATION_OR_MOVEMENT',
  PROBLEMS_WITH_DENTURES_DENTURES_OR_IMPLANTS = 'PROBLEMS_WITH_DENTURES_DENTURES_OR_IMPLANTS',
  PROBLEMS_WITH_GROWTH_OR_PHYSICAL_DEVELOPMENT = 'PROBLEMS_WITH_GROWTH_OR_PHYSICAL_DEVELOPMENT',
  PROBLEMS_WITH_NUTRITION_OR_DYSPHAGIA = 'PROBLEMS_WITH_NUTRITION_OR_DYSPHAGIA',
  PROBLEMS_WITH_PLUGGED_EARS_OR_RINGING_EARS = 'PROBLEMS_WITH_PLUGGED_EARS_OR_RINGING_EARS',
  PROBLEMS_WITH_POSTURE_OR_BODY_ALIGNMENT = 'PROBLEMS_WITH_POSTURE_OR_BODY_ALIGNMENT',
  PROBLEMS_WITH_SENSITIVITY_TO_MOVEMENT_OR_CHANGE_OF_POSITION = 'PROBLEMS_WITH_SENSITIVITY_TO_MOVEMENT_OR_CHANGE_OF_POSITION',
  PROBLEMS_WITH_STABILITY_OR_STRENGTH_IN_THE_HANDS = 'PROBLEMS_WITH_STABILITY_OR_STRENGTH_IN_THE_HANDS',
  PROBLEMS_WITH_STOMATOLOGY_OR_DENTITION = 'PROBLEMS_WITH_STOMATOLOGY_OR_DENTITION',
  PROBLEMS_WITH_VISION_OR_BLURRED_VISION = 'PROBLEMS_WITH_VISION_OR_BLURRED_VISION',
  PROLAPSES_OF_THE_PELVIC_ORGANS = 'PROLAPSES_OF_THE_PELVIC_ORGANS',
  PROMOTION_OF_GRANULATION_TISSUE_GROWTH = 'PROMOTION_OF_GRANULATION_TISSUE_GROWTH',
  PRONUNCIATION_BLESA = 'PRONUNCIATION_BLESA',
  PROSTATE_CANCERS = 'PROSTATE_CANCERS',
  PROTEINURIA_PRESENCE_OF_PROTEIN_IN_THE_URINE = 'PROTEINURIA_PRESENCE_OF_PROTEIN_IN_THE_URINE',
  PSYCHIATRIC_OR_BEHAVIORAL_DISORDERS = 'PSYCHIATRIC_OR_BEHAVIORAL_DISORDERS',
  PSYCHIATRIC_OR_BEHAVIORAL_HEALTH_PROBLEMS = 'PSYCHIATRIC_OR_BEHAVIORAL_HEALTH_PROBLEMS',
  PSYCHIATRIC_PROBLEMS_DEPRESSION_MOOD_DISORDERS = 'PSYCHIATRIC_PROBLEMS_DEPRESSION_MOOD_DISORDERS',
  PSYCHOLOGICAL_DISORDERS_RELATED_TO_NUTRITION = 'PSYCHOLOGICAL_DISORDERS_RELATED_TO_NUTRITION',
  PSYCHOLOGICAL_OR_EMOTIONAL_SYMPTOMS_SUCH_AS_ANXIETY_DEPRESSION_OR_STRESS = 'PSYCHOLOGICAL_OR_EMOTIONAL_SYMPTOMS_SUCH_AS_ANXIETY_DEPRESSION_OR_STRESS',
  PSYCHOLOGICAL_PROBLEMS_ANXIETY_STRESS_MOOD_DISORDERS = 'PSYCHOLOGICAL_PROBLEMS_ANXIETY_STRESS_MOOD_DISORDERS',
  PSYCHOSIS_SUCH_AS_DELUSIONAL_DISORDER = 'PSYCHOSIS_SUCH_AS_DELUSIONAL_DISORDER',
  PULMONARY_EMBOLISM = 'PULMONARY_EMBOLISM',
  PULMONARY_EMBOLISM_DYSPNEA_CHEST_PAIN_COUGH = 'PULMONARY_EMBOLISM_DYSPNEA_CHEST_PAIN_COUGH',
  PULMONARY_THROMBOEMBOLISM_DYSPNEA_CHEST_PAIN_COUGH = 'PULMONARY_THROMBOEMBOLISM_DYSPNEA_CHEST_PAIN_COUGH',
  PULMONITIS_COUGH_FEVER_DYSPNEA_SPUTUM_PRODUCTION = 'PULMONITIS_COUGH_FEVER_DYSPNEA_SPUTUM_PRODUCTION',
  PURULENT_WOUND_PUS = 'PURULENT_WOUND_PUS',
  PUS_OR_PURULENT_MATERIAL = 'PUS_OR_PURULENT_MATERIAL',
  RADIOMETABOLIC_THERAPY_FOR_CERTAIN_CONDITIONS = 'RADIOMETABOLIC_THERAPY_FOR_CERTAIN_CONDITIONS',
  RADIUS_NECK_PAIN = 'RADIUS_NECK_PAIN',
  READING_DISORDERS_DYSLEXIA = 'READING_DISORDERS_DYSLEXIA',
  RECONSTRUCTION_OF_AMPUTATED_LIMBS = 'RECONSTRUCTION_OF_AMPUTATED_LIMBS',
  RECONSTRUCTION_OF_CRANIOFACIAL_DEFECTS = 'RECONSTRUCTION_OF_CRANIOFACIAL_DEFECTS',
  RECONSTRUCTION_OF_DAMAGED_JOINTS = 'RECONSTRUCTION_OF_DAMAGED_JOINTS',
  RECONSTRUCTION_OF_DEFECTS_IN_THE_UPPER_ABDOMEN = 'RECONSTRUCTION_OF_DEFECTS_IN_THE_UPPER_ABDOMEN',
  RECONSTRUCTION_OF_DEFECTS_OR_DEFORMITIES_OF_THE_UPPER_LIMBS = 'RECONSTRUCTION_OF_DEFECTS_OR_DEFORMITIES_OF_THE_UPPER_LIMBS',
  RECONSTRUCTION_OF_EYELID_DEFECTS = 'RECONSTRUCTION_OF_EYELID_DEFECTS',
  RECONSTRUCTION_OF_JOINT_INJURIES = 'RECONSTRUCTION_OF_JOINT_INJURIES',
  RECONSTRUCTION_OF_MAXILLARY_JOINTS = 'RECONSTRUCTION_OF_MAXILLARY_JOINTS',
  RECONSTRUCTION_OF_MUSCLE_DEFECTS = 'RECONSTRUCTION_OF_MUSCLE_DEFECTS',
  RECONSTRUCTION_OF_NOSE_DEFECTS = 'RECONSTRUCTION_OF_NOSE_DEFECTS',
  RECONSTRUCTION_OF_PERIPHERAL_NERVOUS_SYSTEM_LESIONS = 'RECONSTRUCTION_OF_PERIPHERAL_NERVOUS_SYSTEM_LESIONS',
  RECONSTRUCTION_OF_SKIN_LESIONS_OF_THE_NECK = 'RECONSTRUCTION_OF_SKIN_LESIONS_OF_THE_NECK',
  RECONSTRUCTION_OF_SOFT_TISSUE_INJURIES_OF_THE_TRUNK = 'RECONSTRUCTION_OF_SOFT_TISSUE_INJURIES_OF_THE_TRUNK',
  RECONSTRUCTION_OF_THE_ABDOMEN_AFTER_SURGERY = 'RECONSTRUCTION_OF_THE_ABDOMEN_AFTER_SURGERY',
  RECONSTRUCTION_OF_TRUNK_INJURIES = 'RECONSTRUCTION_OF_TRUNK_INJURIES',
  RECONSTRUCTION_OF_VASCULAR_LESIONS = 'RECONSTRUCTION_OF_VASCULAR_LESIONS',
  RECONSTRUCTION_OF_VASCULAR_LESIONS_OF_THE_BODY = 'RECONSTRUCTION_OF_VASCULAR_LESIONS_OF_THE_BODY',
  RECONSTRUCTION_OF_VASCULAR_LESIONS_OF_THE_TRUNK = 'RECONSTRUCTION_OF_VASCULAR_LESIONS_OF_THE_TRUNK',
  RECONSTRUCTIVE_SURGERY_AFTER_ONCOLOGICAL_INJURY_OR_SURGERY = 'RECONSTRUCTIVE_SURGERY_AFTER_ONCOLOGICAL_INJURY_OR_SURGERY',
  RECTAL_BLEEDING = 'RECTAL_BLEEDING',
  RECTAL_BLEEDING_VARIANT_2 = 'RECTAL_BLEEDING_VARIANT_2',
  RECURRENT_INFECTIONS_OF_THE_EARS_THROAT_OR_SINUSES = 'RECURRENT_INFECTIONS_OF_THE_EARS_THROAT_OR_SINUSES',
  RECURRENT_LUNG_INFECTIONS = 'RECURRENT_LUNG_INFECTIONS',
  RECURRENT_MISCARRIAGE = 'RECURRENT_MISCARRIAGE',
  RECURRENT_NOSEBLEEDS_NOSEBLEEDS = 'RECURRENT_NOSEBLEEDS_NOSEBLEEDS',
  RECURRENT_OR_PERSISTENT_FEVER = 'RECURRENT_OR_PERSISTENT_FEVER',
  RECURRENT_OR_SEVERE_INFECTIONS_ESPECIALLY_WHEN_THERE_IS_NO_APPARENT_CAUSE = 'RECURRENT_OR_SEVERE_INFECTIONS_ESPECIALLY_WHEN_THERE_IS_NO_APPARENT_CAUSE',
  RECURRENT_URINARY_INFECTIONS = 'RECURRENT_URINARY_INFECTIONS',
  REDDENING_OF_THE_SKIN = 'REDDENING_OF_THE_SKIN',
  REDUCED_SPEECH_COMPREHENSION_AND_PRODUCTION_IN_PATIENTS_WITH_BRAIN_DAMAGE = 'REDUCED_SPEECH_COMPREHENSION_AND_PRODUCTION_IN_PATIENTS_WITH_BRAIN_DAMAGE',
  REDUCED_VISUAL_ACUITY = 'REDUCED_VISUAL_ACUITY',
  RED_OR_PURPLISH_DOTS_ON_THE_SKIN_PETECHIAE_OR_ECCHYMOSIS = 'RED_OR_PURPLISH_DOTS_ON_THE_SKIN_PETECHIAE_OR_ECCHYMOSIS',
  REHABILITATION_AFTER_INJURY_OR_SURGERY = 'REHABILITATION_AFTER_INJURY_OR_SURGERY',
  REMOVAL_OF_BENIGN_SKIN_LESIONS = 'REMOVAL_OF_BENIGN_SKIN_LESIONS',
  REMOVAL_OF_IMPACTED_WISDOM_TEETH = 'REMOVAL_OF_IMPACTED_WISDOM_TEETH',
  RENAL_CYSTS = 'RENAL_CYSTS',
  RENAL_DISEASE_NEPHROPATHY_TO_FUNDAMENTAL_SUBSTANCE_DEPOSITS = 'RENAL_DISEASE_NEPHROPATHY_TO_FUNDAMENTAL_SUBSTANCE_DEPOSITS',
  RENAL_DISEASE_NEPHROPATHY_TO_MONOCLONAL_DEPOSITS = 'RENAL_DISEASE_NEPHROPATHY_TO_MONOCLONAL_DEPOSITS',
  RENAL_DISEASE_NEPHROPATHY_WITH_AMYLOID_DEPOSITS = 'RENAL_DISEASE_NEPHROPATHY_WITH_AMYLOID_DEPOSITS',
  RENAL_DISEASE_NEPHROPATHY_WITH_FIBRIL_DEPOSITS = 'RENAL_DISEASE_NEPHROPATHY_WITH_FIBRIL_DEPOSITS',
  RENAL_FAILURE = 'RENAL_FAILURE',
  RENAL_OR_URINARY_DISORDERS = 'RENAL_OR_URINARY_DISORDERS',
  RENAL_OR_URINARY_PROBLEMS_KIDNEY_FAILURE_URINARY_INFECTIONS = 'RENAL_OR_URINARY_PROBLEMS_KIDNEY_FAILURE_URINARY_INFECTIONS',
  REPAIR_OF_PERIPHERAL_NERVE_INJURIES = 'REPAIR_OF_PERIPHERAL_NERVE_INJURIES',
  REPEATED_BEHAVIORS = 'REPEATED_BEHAVIORS',
  REPEATED_DISCHARGES = 'REPEATED_DISCHARGES',
  REPRODUCTIVE_OR_GYNECOLOGICAL_DISORDERS_IN_GIRLS_OF_PUBERTAL_AGE = 'REPRODUCTIVE_OR_GYNECOLOGICAL_DISORDERS_IN_GIRLS_OF_PUBERTAL_AGE',
  RESPIRATORY_AND_THORACIC_PROBLEMS = 'RESPIRATORY_AND_THORACIC_PROBLEMS',
  RESPIRATORY_DIFFICULTIES = 'RESPIRATORY_DIFFICULTIES',
  RESPIRATORY_DIFFICULTY = 'RESPIRATORY_DIFFICULTY',
  RESPIRATORY_DIFFICULTY_ASSOCIATED_WITH_HEART_FAILURE = 'RESPIRATORY_DIFFICULTY_ASSOCIATED_WITH_HEART_FAILURE',
  RESPIRATORY_OR_PULMONARY_HEALTH_PROBLEMS = 'RESPIRATORY_OR_PULMONARY_HEALTH_PROBLEMS',
  RESPIRATORY_PROBLEMS_ASTHMA_CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE = 'RESPIRATORY_PROBLEMS_ASTHMA_CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
  RESPIRATORY_PROBLEMS_DYSPNEA_CHRONIC_COUGH = 'RESPIRATORY_PROBLEMS_DYSPNEA_CHRONIC_COUGH',
  RESPIRATORY_PROBLEMS_OR_DYSPNEA = 'RESPIRATORY_PROBLEMS_OR_DYSPNEA',
  RESPIRATORY_SYMPTOMS_SUCH_AS_PERSISTENT_COUGH = 'RESPIRATORY_SYMPTOMS_SUCH_AS_PERSISTENT_COUGH',
  RESPIRATORY_SYMPTOMS_SUCH_AS_PERSISTENT_COUGH_DYSPNEA = 'RESPIRATORY_SYMPTOMS_SUCH_AS_PERSISTENT_COUGH_DYSPNEA',
  RESPONSE_TO_BREAST_CANCER_TREATMENTS = 'RESPONSE_TO_BREAST_CANCER_TREATMENTS',
  RESPONSE_TO_PREVIOUS_BREAST_SURGERY = 'RESPONSE_TO_PREVIOUS_BREAST_SURGERY',
  RESTLESS_LEG_SYNDROME = 'RESTLESS_LEG_SYNDROME',
  RETROSTERNAL_PAIN_BEHIND_THE_STERNUM = 'RETROSTERNAL_PAIN_BEHIND_THE_STERNUM',
  REVIEW_AND_CORRECTION_OF_BREAST_SURGICAL_SCARS = 'REVIEW_AND_CORRECTION_OF_BREAST_SURGICAL_SCARS',
  RHEUMATOLOGICAL_PROBLEMS_ARTHRITIS_GOUT = 'RHEUMATOLOGICAL_PROBLEMS_ARTHRITIS_GOUT',
  RHINITIS = 'RHINITIS',
  RIB_PAIN = 'RIB_PAIN',
  RIB_PAIN_VARIANT_2 = 'RIB_PAIN_VARIANT_2',
  RIB_PAIN_VARIANT_3 = 'RIB_PAIN_VARIANT_3',
  RIGHT_FLANK_PAIN_IF_RELATED_TO_LIVER_OR_GALLBLADDER_PROBLEMS = 'RIGHT_FLANK_PAIN_IF_RELATED_TO_LIVER_OR_GALLBLADDER_PROBLEMS',
  RITUALISTIC_OR_REPETITIVE_BEHAVIORS = 'RITUALISTIC_OR_REPETITIVE_BEHAVIORS',
  ROLE_CONFLICT_STRESS_DISORDER = 'ROLE_CONFLICT_STRESS_DISORDER',
  ROUGH_RASPY_VOICE = 'ROUGH_RASPY_VOICE',
  SALIVARY_GLAND_DISEASES = 'SALIVARY_GLAND_DISEASES',
  SAPHENOUS_VEIN_PROBLEMS_SAPHENOUS = 'SAPHENOUS_VEIN_PROBLEMS_SAPHENOUS',
  SARCOIDOSIS_COUGH_DYSPNEA_FATIGUE = 'SARCOIDOSIS_COUGH_DYSPNEA_FATIGUE',
  SCALY_OR_CRACKED_SKIN = 'SCALY_OR_CRACKED_SKIN',
  SCARS = 'SCARS',
  SCHIZOPHRENIA = 'SCHIZOPHRENIA',
  SEARCHING_FOR_COMPLICATIONS_FROM_SURGERY = 'SEARCHING_FOR_COMPLICATIONS_FROM_SURGERY',
  SEARCHING_FOR_INFECTIONS_OR_ABSCESSES = 'SEARCHING_FOR_INFECTIONS_OR_ABSCESSES',
  SEARCHING_FOR_POSTOPERATIVE_COMPLICATIONS_OR_SYMPTOMS = 'SEARCHING_FOR_POSTOPERATIVE_COMPLICATIONS_OR_SYMPTOMS',
  SEARCH_FOR_INTERNAL_BLEEDING_OR_BLEEDING = 'SEARCH_FOR_INTERNAL_BLEEDING_OR_BLEEDING',
  SEARCH_FOR_OVERLOAD_INJURIES_OR_HEAD_TRAUMA = 'SEARCH_FOR_OVERLOAD_INJURIES_OR_HEAD_TRAUMA',
  SEARCH_FOR_OVERLOAD_OR_REPETITIVE_STRAIN_INJURIES = 'SEARCH_FOR_OVERLOAD_OR_REPETITIVE_STRAIN_INJURIES',
  SEARCH_FOR_VASCULAR_ABNORMALITIES_OR_ANEURYSMS = 'SEARCH_FOR_VASCULAR_ABNORMALITIES_OR_ANEURYSMS',
  SECRETIONS_OR_EXUDATE_FROM_ULCERS = 'SECRETIONS_OR_EXUDATE_FROM_ULCERS',
  SECRETIONS_OR_FLUID_FROM_THE_NIPPLE = 'SECRETIONS_OR_FLUID_FROM_THE_NIPPLE',
  SEIZURES_OR_SEIZURES = 'SEIZURES_OR_SEIZURES',
  SEIZURES_SEIZURES = 'SEIZURES_SEIZURES',
  SELF_INJURIOUS_BEHAVIORS = 'SELF_INJURIOUS_BEHAVIORS',
  SELF_INJURIOUS_OR_SUICIDAL_BEHAVIOR = 'SELF_INJURIOUS_OR_SUICIDAL_BEHAVIOR',
  SENSATION_OF_INCOMPLETE_BLADDER_FILLING = 'SENSATION_OF_INCOMPLETE_BLADDER_FILLING',
  SENSATION_OF_WARMTH_IN_THE_CHEST = 'SENSATION_OF_WARMTH_IN_THE_CHEST',
  SENSE_OF_INADEQUACY_FAILURE_DISSATISFACTION = 'SENSE_OF_INADEQUACY_FAILURE_DISSATISFACTION',
  SENSE_OF_TIGHTNESS_IN_THE_CHEST = 'SENSE_OF_TIGHTNESS_IN_THE_CHEST',
  SENSE_OF_WEIGHT_HEAVINESS_ON_CHEST = 'SENSE_OF_WEIGHT_HEAVINESS_ON_CHEST',
  SENSITIVE_OR_REACTIVE_SKIN = 'SENSITIVE_OR_REACTIVE_SKIN',
  SENSITIVITY_DISORDERS = 'SENSITIVITY_DISORDERS',
  SENSITIVITY_TO_LOCALIZED_PRESSURES = 'SENSITIVITY_TO_LOCALIZED_PRESSURES',
  SENSORY_INTEGRATION_ISSUES = 'SENSORY_INTEGRATION_ISSUES',
  SENTINEL_LYMPH_NODE_SEARCH_DURING_BREAST_CANCER_REMOVAL = 'SENTINEL_LYMPH_NODE_SEARCH_DURING_BREAST_CANCER_REMOVAL',
  SEPARATION_ANXIETY_DISORDER_SAD = 'SEPARATION_ANXIETY_DISORDER_SAD',
  SEVERE_ALLERGIC_REACTIONS_ANAPHYLAXIS = 'SEVERE_ALLERGIC_REACTIONS_ANAPHYLAXIS',
  SEVERE_CARDIAC_SYMPTOMS = 'SEVERE_CARDIAC_SYMPTOMS',
  SEVERE_GASTROINTESTINAL_SYMPTOMS = 'SEVERE_GASTROINTESTINAL_SYMPTOMS',
  SEVERE_NEUROLOGICAL_DISORDERS = 'SEVERE_NEUROLOGICAL_DISORDERS',
  SEVERE_RESPIRATORY_SYMPTOMS = 'SEVERE_RESPIRATORY_SYMPTOMS',
  SEVERE_SKIN_BURNS = 'SEVERE_SKIN_BURNS',
  SEVERE_SKIN_INJURIES = 'SEVERE_SKIN_INJURIES',
  SEXUALLY_TRANSMITTED_DISEASES_STDS = 'SEXUALLY_TRANSMITTED_DISEASES_STDS',
  SEXUALLY_TRANSMITTED_INFECTIONS_SUCH_AS_GONORRHEA_OR_CHLAMYDIA = 'SEXUALLY_TRANSMITTED_INFECTIONS_SUCH_AS_GONORRHEA_OR_CHLAMYDIA',
  SEXUAL_DISORDERS_ERECTILE_DYSFUNCTION_ETC = 'SEXUAL_DISORDERS_ERECTILE_DYSFUNCTION_ETC',
  SEXUAL_INTERCOURSE_PROBLEMS = 'SEXUAL_INTERCOURSE_PROBLEMS',
  SEXUAL_OR_REPRODUCTIVE_HEALTH_PROBLEMS = 'SEXUAL_OR_REPRODUCTIVE_HEALTH_PROBLEMS',
  SEXUAL_PROBLEMS_OR_ERECTILE_DYSFUNCTION = 'SEXUAL_PROBLEMS_OR_ERECTILE_DYSFUNCTION',
  SEXUAL_PROBLEMS_OR_ERECTILE_DYSFUNCTION_IN_MEN = 'SEXUAL_PROBLEMS_OR_ERECTILE_DYSFUNCTION_IN_MEN',
  SEXUAL_PROBLEMS_OR_SEXUAL_DYSFUNCTION = 'SEXUAL_PROBLEMS_OR_SEXUAL_DYSFUNCTION',
  SHARP_PAIN_IN_THE_UPPER_ABDOMEN_OR_CHEST = 'SHARP_PAIN_IN_THE_UPPER_ABDOMEN_OR_CHEST',
  SHIVERS = 'SHIVERS',
  SHOCKS_OR_TREMORS = 'SHOCKS_OR_TREMORS',
  SHORTNESS_OF_BREATH = 'SHORTNESS_OF_BREATH',
  SHOULDER_PAIN = 'SHOULDER_PAIN',
  SHOULDER_PAIN_VARIANT_2 = 'SHOULDER_PAIN_VARIANT_2',
  SHOULDER_PAIN_WITH_LIMITATION_OF_MOVEMENT = 'SHOULDER_PAIN_WITH_LIMITATION_OF_MOVEMENT',
  SHOULDER_SWELLING = 'SHOULDER_SWELLING',
  SHOULDER_SYMPTOMS = 'SHOULDER_SYMPTOMS',
  SIBILANCE_WHISTLE_LIKE_SOUNDS_DURING_BREATHING = 'SIBILANCE_WHISTLE_LIKE_SOUNDS_DURING_BREATHING',
  SICKNESS_AFTER_EATING = 'SICKNESS_AFTER_EATING',
  SILICOSIS_COUGH_DYSPNEA_SPUTUM_PRODUCTION = 'SILICOSIS_COUGH_DYSPNEA_SPUTUM_PRODUCTION',
  SINUS_DISTORTIONS = 'SINUS_DISTORTIONS',
  SKIN_CANCERS = 'SKIN_CANCERS',
  SKIN_CANCERS_MELANOMA_AND_NON_MELANOMA = 'SKIN_CANCERS_MELANOMA_AND_NON_MELANOMA',
  SKIN_CHANGES_SUCH_AS_CHANGES_IN_MOLES_OR_SKIN_LESIONS = 'SKIN_CHANGES_SUCH_AS_CHANGES_IN_MOLES_OR_SKIN_LESIONS',
  SKIN_DEFORMITY_CORRECTION_SURGERY = 'SKIN_DEFORMITY_CORRECTION_SURGERY',
  SKIN_DISORDERS_RELATED_TO_DIGESTIVE_PROBLEMS = 'SKIN_DISORDERS_RELATED_TO_DIGESTIVE_PROBLEMS',
  SKIN_DRYNESS_AND_DESQUAMATION = 'SKIN_DRYNESS_AND_DESQUAMATION',
  SKIN_HEMORRHAGES = 'SKIN_HEMORRHAGES',
  SKIN_INJURIES = 'SKIN_INJURIES',
  SKIN_NODULES = 'SKIN_NODULES',
  SKIN_OR_DERMATOLOGICAL_PROBLEMS = 'SKIN_OR_DERMATOLOGICAL_PROBLEMS',
  SKIN_OR_MUSCLE_TISSUE_TRANSPLANTS = 'SKIN_OR_MUSCLE_TISSUE_TRANSPLANTS',
  SKIN_OR_NAIL_DISORDERS_ASSOCIATED_WITH_SPORTS_ACTIVITY = 'SKIN_OR_NAIL_DISORDERS_ASSOCIATED_WITH_SPORTS_ACTIVITY',
  SKIN_PALENESS = 'SKIN_PALENESS',
  SKIN_PALENESS_VARIANT_2 = 'SKIN_PALENESS_VARIANT_2',
  SKIN_RASH = 'SKIN_RASH',
  SKIN_RASH_RASH = 'SKIN_RASH_RASH',
  SKIN_RASH_VARIANT_2 = 'SKIN_RASH_VARIANT_2',
  SKIN_REDNESS = 'SKIN_REDNESS',
  SKIN_SPOTS_MASSES_OR_NODULES = 'SKIN_SPOTS_MASSES_OR_NODULES',
  SKIN_TUMORS = 'SKIN_TUMORS',
  SKIN_ULCERATIONS_OR_LESIONS = 'SKIN_ULCERATIONS_OR_LESIONS',
  SKIN_ULCERS_OPEN_SORES_OR_LESIONS_ON_THE_SKIN = 'SKIN_ULCERS_OPEN_SORES_OR_LESIONS_ON_THE_SKIN',
  SLEEP_APNEA = 'SLEEP_APNEA',
  SLEEP_APNEA_DAYTIME_SLEEPINESS_SNORING_PAUSES_IN_BREATHING_DURING_SLEEP = 'SLEEP_APNEA_DAYTIME_SLEEPINESS_SNORING_PAUSES_IN_BREATHING_DURING_SLEEP',
  SLEEP_APNEA_DIFFICULTY_BREATHING_AT_NIGHT = 'SLEEP_APNEA_DIFFICULTY_BREATHING_AT_NIGHT',
  SLEEP_DISORDERS = 'SLEEP_DISORDERS',
  SLEEP_DISORDERS_E_G_PARASOMNIAS = 'SLEEP_DISORDERS_E_G_PARASOMNIAS',
  SLEEP_DISORDERS_INSOMNIA_HYPERSOMNIA = 'SLEEP_DISORDERS_INSOMNIA_HYPERSOMNIA',
  SLEEP_DISORDERS_INSOMNIA_OR_EXCESSIVE_DAYTIME_SLEEPINESS = 'SLEEP_DISORDERS_INSOMNIA_OR_EXCESSIVE_DAYTIME_SLEEPINESS',
  SLEEP_DISORDERS_INSOMNIA_SLEEP_APNEA = 'SLEEP_DISORDERS_INSOMNIA_SLEEP_APNEA',
  SLEEP_DISORDERS_RELATED_TO_MUSCULOSKELETAL_OR_NEUROLOGICAL_CONDITIONS = 'SLEEP_DISORDERS_RELATED_TO_MUSCULOSKELETAL_OR_NEUROLOGICAL_CONDITIONS',
  SLEEP_DISORDERS_VARIANT_2 = 'SLEEP_DISORDERS_VARIANT_2',
  SLEEP_DISTURBANCE_FROM_NIGHT_TERRORS = 'SLEEP_DISTURBANCE_FROM_NIGHT_TERRORS',
  SLEEP_PROBLEMS_INSOMNIA_DAYTIME_SLEEPINESS = 'SLEEP_PROBLEMS_INSOMNIA_DAYTIME_SLEEPINESS',
  SLEEP_PROBLEMS_INSOMNIA_DIFFICULTY_SLEEPING = 'SLEEP_PROBLEMS_INSOMNIA_DIFFICULTY_SLEEPING',
  SLEEP_PROBLEMS_INSOMNIA_EXCESSIVE_SLEEPINESS = 'SLEEP_PROBLEMS_INSOMNIA_EXCESSIVE_SLEEPINESS',
  SLEEP_PROBLEMS_INSOMNIA_OR_EXCESSIVE_SLEEPINESS = 'SLEEP_PROBLEMS_INSOMNIA_OR_EXCESSIVE_SLEEPINESS',
  SLIMMING_SLIMMING = 'SLIMMING_SLIMMING',
  SMALL_INTESTINE_PROBLEMS_SUCH_AS_SHORT_BOWEL_SYNDROME = 'SMALL_INTESTINE_PROBLEMS_SUCH_AS_SHORT_BOWEL_SYNDROME',
  SNEEZE_SNEEZE = 'SNEEZE_SNEEZE',
  SNORING = 'SNORING',
  SOCIAL_ANXIETY_DISORDER_SOCIAL_PHOBIA = 'SOCIAL_ANXIETY_DISORDER_SOCIAL_PHOBIA',
  SOCIAL_CONFLICT_STRESS_DISORDER = 'SOCIAL_CONFLICT_STRESS_DISORDER',
  SOCIAL_WITHDRAWAL_OR_ISOLATION = 'SOCIAL_WITHDRAWAL_OR_ISOLATION',
  SOFT_TISSUE_INJURIES = 'SOFT_TISSUE_INJURIES',
  SOMATIZATION_DISORDER = 'SOMATIZATION_DISORDER',
  SOMATOFORM_DISORDERS_SUCH_AS_HYPOCHONDRIA = 'SOMATOFORM_DISORDERS_SUCH_AS_HYPOCHONDRIA',
  SORE_THROAT = 'SORE_THROAT',
  SOUNDS_RINGING_WHISTLING_NOISES_IN_THE_EARS = 'SOUNDS_RINGING_WHISTLING_NOISES_IN_THE_EARS',
  SPASMS = 'SPASMS',
  SPASMS_OF_THE_ARTERIES_CLAUDICATION_INTERMITTENS = 'SPASMS_OF_THE_ARTERIES_CLAUDICATION_INTERMITTENS',
  SPASTICITY = 'SPASTICITY',
  SPECIFIC_PHOBIAS = 'SPECIFIC_PHOBIAS',
  SPECIFIC_SYMPTOMS_RELATED_TO_SPECIFIC_TUMOR_TYPES_SUCH_AS_CHRONIC_COUGH_IN_LUNG_CANCER = 'SPECIFIC_SYMPTOMS_RELATED_TO_SPECIFIC_TUMOR_TYPES_SUCH_AS_CHRONIC_COUGH_IN_LUNG_CANCER',
  SPEECH_DISORDERS_APHASIA_DYSARTHRIA = 'SPEECH_DISORDERS_APHASIA_DYSARTHRIA',
  SPEECH_DISORDERS_IN_PEOPLE_WITH_CEREBRAL_PALSY_OR_OTHER_NEUROLOGICAL_CONDITIONS = 'SPEECH_DISORDERS_IN_PEOPLE_WITH_CEREBRAL_PALSY_OR_OTHER_NEUROLOGICAL_CONDITIONS',
  SPEECH_PROBLEMS_ASSOCIATED_WITH_NEUROLOGICAL_CONDITIONS_SUCH_AS_STROKE_BRAIN_INJURY = 'SPEECH_PROBLEMS_ASSOCIATED_WITH_NEUROLOGICAL_CONDITIONS_SUCH_AS_STROKE_BRAIN_INJURY',
  SPEECH_PROBLEMS_OR_DIFFICULTY_IN_SPOKEN_WORD = 'SPEECH_PROBLEMS_OR_DIFFICULTY_IN_SPOKEN_WORD',
  SPINAL_COLUMN_SYMPTOMS = 'SPINAL_COLUMN_SYMPTOMS',
  SPINAL_DISORDERS_SUCH_AS_HERNIATED_DISCS = 'SPINAL_DISORDERS_SUCH_AS_HERNIATED_DISCS',
  SPINAL_DISORDERS_SUCH_AS_SCIATICA = 'SPINAL_DISORDERS_SUCH_AS_SCIATICA',
  SPINAL_SYMPTOMS_SUCH_AS_SPINAL_CORD_COMPRESSION = 'SPINAL_SYMPTOMS_SUCH_AS_SPINAL_CORD_COMPRESSION',
  SPLEEN_PAIN = 'SPLEEN_PAIN',
  SPLENOMEGALY_ENLARGED_SPLEEN = 'SPLENOMEGALY_ENLARGED_SPLEEN',
  SPORTS_ACTIVITY_RELATED_SLEEP_DISORDERS = 'SPORTS_ACTIVITY_RELATED_SLEEP_DISORDERS',
  SPORTS_INJURY = 'SPORTS_INJURY',
  SPOTTING_VAGINAL_BLEEDING_BETWEEN_MENSTRUATION = 'SPOTTING_VAGINAL_BLEEDING_BETWEEN_MENSTRUATION',
  SPROUTING_SPOTS_ON_THE_BODY = 'SPROUTING_SPOTS_ON_THE_BODY',
  STENOSIS_OF_THE_ESOPHAGUS = 'STENOSIS_OF_THE_ESOPHAGUS',
  STENOSIS_OR_OCCLUSION_OF_PULMONARY_ARTERIES = 'STENOSIS_OR_OCCLUSION_OF_PULMONARY_ARTERIES',
  STEREOTYPIES_REPETITIVE_MOVEMENTS = 'STEREOTYPIES_REPETITIVE_MOVEMENTS',
  STIFFENING_OR_THINNING_OF_THE_ARTERIES_ATHEROSCLEROSIS = 'STIFFENING_OR_THINNING_OF_THE_ARTERIES_ATHEROSCLEROSIS',
  STIFFNESS_IN_THE_MORNING = 'STIFFNESS_IN_THE_MORNING',
  STOMACHACHE = 'STOMACHACHE',
  STOMACH_PROBLEMS_SUCH_AS_STOMACH_ULCERS = 'STOMACH_PROBLEMS_SUCH_AS_STOMACH_ULCERS',
  STOOL_WITH_BLOOD_OR_MELENA = 'STOOL_WITH_BLOOD_OR_MELENA',
  STRABISMUS_DEVIATED_EYES = 'STRABISMUS_DEVIATED_EYES',
  STREAKS_RED_STRIPES_ON_THE_BODY = 'STREAKS_RED_STRIPES_ON_THE_BODY',
  STUTTERING = 'STUTTERING',
  SUBCUTANEOUS_NODULES = 'SUBCUTANEOUS_NODULES',
  SUBSTANCE_ADDICTION = 'SUBSTANCE_ADDICTION',
  SUDDEN_CHANGES_IN_MOOD = 'SUDDEN_CHANGES_IN_MOOD',
  SUDDEN_JOY_OR_SADNESS = 'SUDDEN_JOY_OR_SADNESS',
  SUICIDAL_THOUGHTS = 'SUICIDAL_THOUGHTS',
  SUN_SENSITIVE_SKIN_OR_PHOTOSENSITIVITY = 'SUN_SENSITIVE_SKIN_OR_PHOTOSENSITIVITY',
  SURGERY_FOR_CORRECTION_OF_CRANIOFACIAL_DEFORMITIES = 'SURGERY_FOR_CORRECTION_OF_CRANIOFACIAL_DEFORMITIES',
  SURGERY_FOR_CORRECTION_OF_VASCULAR_MALFORMATIONS = 'SURGERY_FOR_CORRECTION_OF_VASCULAR_MALFORMATIONS',
  SURGERY_FOR_REMOVAL_OF_CUTANEOUS_NEOPLASMS = 'SURGERY_FOR_REMOVAL_OF_CUTANEOUS_NEOPLASMS',
  SURGERY_FOR_SLEEP_APNEA_AND_OTHER_NOCTURNAL_RESPIRATORY_DISORDERS = 'SURGERY_FOR_SLEEP_APNEA_AND_OTHER_NOCTURNAL_RESPIRATORY_DISORDERS',
  SURGERY_TO_CORRECT_BODY_DEFECTS_OR_DEFORMITIES = 'SURGERY_TO_CORRECT_BODY_DEFECTS_OR_DEFORMITIES',
  SUSPECTED_MASS_OR_BREAST_CANCER = 'SUSPECTED_MASS_OR_BREAST_CANCER',
  SUSPECTED_MASS_OR_TUMOR_IN_THE_GENITAL_OR_UROLOGICAL_AREAS = 'SUSPECTED_MASS_OR_TUMOR_IN_THE_GENITAL_OR_UROLOGICAL_AREAS',
  SUSPICION_OF_EXTRAUTERINE_PREGNANCY = 'SUSPICION_OF_EXTRAUTERINE_PREGNANCY',
  SUSPICION_OF_PREGNANCY = 'SUSPICION_OF_PREGNANCY',
  SWALLOWING_DISORDERS = 'SWALLOWING_DISORDERS',
  SWALLOWING_DISORDERS_DYSPHAGIA = 'SWALLOWING_DISORDERS_DYSPHAGIA',
  SWEATY_NIGHT_AWAKENINGS = 'SWEATY_NIGHT_AWAKENINGS',
  SWELLING = 'SWELLING',
  SWELLING_OF_BODY_PARTS = 'SWELLING_OF_BODY_PARTS',
  SWELLING_OF_THE_ANKLES = 'SWELLING_OF_THE_ANKLES',
  SWELLING_OF_THE_FACE_LIPS_OR_TONGUE = 'SWELLING_OF_THE_FACE_LIPS_OR_TONGUE',
  SWELLING_OF_THE_FACE_OR_JAWS = 'SWELLING_OF_THE_FACE_OR_JAWS',
  SWELLING_OF_THE_FINGERS = 'SWELLING_OF_THE_FINGERS',
  SWELLING_OF_THE_KNEES = 'SWELLING_OF_THE_KNEES',
  SWELLING_OF_THE_LEGS_OR_ANKLES = 'SWELLING_OF_THE_LEGS_OR_ANKLES',
  SWELLING_OF_THE_LYMPH_GLANDS = 'SWELLING_OF_THE_LYMPH_GLANDS',
  SWELLING_OF_THE_VEINS_IN_THE_NECK = 'SWELLING_OF_THE_VEINS_IN_THE_NECK',
  SWELLING_OR_EDEMA_IN_THE_LEGS_OR_ANKLES = 'SWELLING_OR_EDEMA_IN_THE_LEGS_OR_ANKLES',
  SWELLING_OR_PAIN_IN_THE_TESTICLES = 'SWELLING_OR_PAIN_IN_THE_TESTICLES',
  SWOLLEN_LEGS = 'SWOLLEN_LEGS',
  SWOLLEN_NECK_VEINS = 'SWOLLEN_NECK_VEINS',
  SWOLLEN_VEINS = 'SWOLLEN_VEINS',
  SYMPTOMS_AFTER_TRAVEL_ABROAD = 'SYMPTOMS_AFTER_TRAVEL_ABROAD',
  SYMPTOMS_ASSOCIATED_WITH_AGE_RELATED_NEUROCOGNITIVE_DISORDERS_SUCH_AS_ALZHEIMER_S_DISEASE = 'SYMPTOMS_ASSOCIATED_WITH_AGE_RELATED_NEUROCOGNITIVE_DISORDERS_SUCH_AS_ALZHEIMER_S_DISEASE',
  SYMPTOMS_ASSOCIATED_WITH_ALLERGIC_OR_IMMUNOLOGICAL_CONDITIONS = 'SYMPTOMS_ASSOCIATED_WITH_ALLERGIC_OR_IMMUNOLOGICAL_CONDITIONS',
  SYMPTOMS_ASSOCIATED_WITH_ALLERGIES_OR_ASTHMA = 'SYMPTOMS_ASSOCIATED_WITH_ALLERGIES_OR_ASTHMA',
  SYMPTOMS_ASSOCIATED_WITH_BENIGN_MAMMAR_PROBLEMS = 'SYMPTOMS_ASSOCIATED_WITH_BENIGN_MAMMAR_PROBLEMS',
  SYMPTOMS_ASSOCIATED_WITH_BRAIN_INJURY = 'SYMPTOMS_ASSOCIATED_WITH_BRAIN_INJURY',
  SYMPTOMS_ASSOCIATED_WITH_CHRONIC_PEDIATRIC_CONDITIONS_SUCH_AS_DIABETES = 'SYMPTOMS_ASSOCIATED_WITH_CHRONIC_PEDIATRIC_CONDITIONS_SUCH_AS_DIABETES',
  SYMPTOMS_ASSOCIATED_WITH_DENTAL_CONDITIONS_OR_TEETH = 'SYMPTOMS_ASSOCIATED_WITH_DENTAL_CONDITIONS_OR_TEETH',
  SYMPTOMS_ASSOCIATED_WITH_DISEASES_OF_THE_IMMUNE_SYSTEM = 'SYMPTOMS_ASSOCIATED_WITH_DISEASES_OF_THE_IMMUNE_SYSTEM',
  SYMPTOMS_ASSOCIATED_WITH_DISEASES_OF_THE_MUSCULOSKELETAL_SYSTEM = 'SYMPTOMS_ASSOCIATED_WITH_DISEASES_OF_THE_MUSCULOSKELETAL_SYSTEM',
  SYMPTOMS_ASSOCIATED_WITH_DISEASES_OF_THE_PERIPHERAL_NERVOUS_SYSTEM = 'SYMPTOMS_ASSOCIATED_WITH_DISEASES_OF_THE_PERIPHERAL_NERVOUS_SYSTEM',
  SYMPTOMS_ASSOCIATED_WITH_DISORDERS_OF_CALCIUM_METABOLISM = 'SYMPTOMS_ASSOCIATED_WITH_DISORDERS_OF_CALCIUM_METABOLISM',
  SYMPTOMS_ASSOCIATED_WITH_ENDOCRINE_OR_HORMONAL_DISEASES = 'SYMPTOMS_ASSOCIATED_WITH_ENDOCRINE_OR_HORMONAL_DISEASES',
  SYMPTOMS_ASSOCIATED_WITH_GASTROINTESTINAL_TUMORS_SUCH_AS_DIGESTIVE_DISORDERS_OR_GASTROINTESTINAL_BLEEDING = 'SYMPTOMS_ASSOCIATED_WITH_GASTROINTESTINAL_TUMORS_SUCH_AS_DIGESTIVE_DISORDERS_OR_GASTROINTESTINAL_BLEEDING',
  SYMPTOMS_ASSOCIATED_WITH_GENETIC_OR_HEREDITARY_CONDITIONS = 'SYMPTOMS_ASSOCIATED_WITH_GENETIC_OR_HEREDITARY_CONDITIONS',
  SYMPTOMS_ASSOCIATED_WITH_GERIATRIC_CONDITIONS_OR_IN_THE_ELDERLY = 'SYMPTOMS_ASSOCIATED_WITH_GERIATRIC_CONDITIONS_OR_IN_THE_ELDERLY',
  SYMPTOMS_ASSOCIATED_WITH_GYNECOLOGIC_OR_UROLOGIC_CONDITIONS = 'SYMPTOMS_ASSOCIATED_WITH_GYNECOLOGIC_OR_UROLOGIC_CONDITIONS',
  SYMPTOMS_ASSOCIATED_WITH_HEAD_OR_NECK_INJURY = 'SYMPTOMS_ASSOCIATED_WITH_HEAD_OR_NECK_INJURY',
  SYMPTOMS_ASSOCIATED_WITH_HEAD_TRAUMA = 'SYMPTOMS_ASSOCIATED_WITH_HEAD_TRAUMA',
  SYMPTOMS_ASSOCIATED_WITH_HEMATOLOGIC_OR_BLOOD_CONDITIONS = 'SYMPTOMS_ASSOCIATED_WITH_HEMATOLOGIC_OR_BLOOD_CONDITIONS',
  SYMPTOMS_ASSOCIATED_WITH_HEREDITARY_OR_GENETIC_MUSCLE_DISEASE = 'SYMPTOMS_ASSOCIATED_WITH_HEREDITARY_OR_GENETIC_MUSCLE_DISEASE',
  SYMPTOMS_ASSOCIATED_WITH_IMMUNE_SYSTEM_CONDITIONS = 'SYMPTOMS_ASSOCIATED_WITH_IMMUNE_SYSTEM_CONDITIONS',
  SYMPTOMS_ASSOCIATED_WITH_INFECTIONS_OR_TUMORS_OF_THE_SPINE = 'SYMPTOMS_ASSOCIATED_WITH_INFECTIONS_OR_TUMORS_OF_THE_SPINE',
  SYMPTOMS_ASSOCIATED_WITH_INFECTIOUS_DISEASES_SUCH_AS_CHICKENPOX_OR_SCARLET_FEVER = 'SYMPTOMS_ASSOCIATED_WITH_INFECTIOUS_DISEASES_SUCH_AS_CHICKENPOX_OR_SCARLET_FEVER',
  SYMPTOMS_ASSOCIATED_WITH_INFLAMMATORY_BOWEL_DISEASE_SUCH_AS_COLITIS = 'SYMPTOMS_ASSOCIATED_WITH_INFLAMMATORY_BOWEL_DISEASE_SUCH_AS_COLITIS',
  SYMPTOMS_ASSOCIATED_WITH_KIDNEY_OR_URINARY_DISEASE = 'SYMPTOMS_ASSOCIATED_WITH_KIDNEY_OR_URINARY_DISEASE',
  SYMPTOMS_ASSOCIATED_WITH_LIVER_OR_GALLBLADDER_DISEASE = 'SYMPTOMS_ASSOCIATED_WITH_LIVER_OR_GALLBLADDER_DISEASE',
  SYMPTOMS_ASSOCIATED_WITH_LUNG_OR_PLEURAL_DISEASE = 'SYMPTOMS_ASSOCIATED_WITH_LUNG_OR_PLEURAL_DISEASE',
  SYMPTOMS_ASSOCIATED_WITH_METASTASIS_SUCH_AS_PATHOLOGICAL_FRACTURES_OR_SYMPTOMS_SPECIFIC_TO_THE_ORGAN_INVOLVED = 'SYMPTOMS_ASSOCIATED_WITH_METASTASIS_SUCH_AS_PATHOLOGICAL_FRACTURES_OR_SYMPTOMS_SPECIFIC_TO_THE_ORGAN_INVOLVED',
  SYMPTOMS_ASSOCIATED_WITH_MUSCULOSKELETAL_ONCOLOGICAL_DISEASES = 'SYMPTOMS_ASSOCIATED_WITH_MUSCULOSKELETAL_ONCOLOGICAL_DISEASES',
  SYMPTOMS_ASSOCIATED_WITH_MUSCULOSKELETAL_OR_NEUROLOGICAL_ONCOLOGICAL_DISEASES = 'SYMPTOMS_ASSOCIATED_WITH_MUSCULOSKELETAL_OR_NEUROLOGICAL_ONCOLOGICAL_DISEASES',
  SYMPTOMS_ASSOCIATED_WITH_NERVOUS_SYSTEM_DISORDERS = 'SYMPTOMS_ASSOCIATED_WITH_NERVOUS_SYSTEM_DISORDERS',
  SYMPTOMS_ASSOCIATED_WITH_NEUROLOGICAL_AND_NEURODEGENERATIVE_DISORDERS = 'SYMPTOMS_ASSOCIATED_WITH_NEUROLOGICAL_AND_NEURODEGENERATIVE_DISORDERS',
  SYMPTOMS_ASSOCIATED_WITH_NEUROLOGICAL_DISEASES_WITH_VISUAL_INVOLVEMENT = 'SYMPTOMS_ASSOCIATED_WITH_NEUROLOGICAL_DISEASES_WITH_VISUAL_INVOLVEMENT',
  SYMPTOMS_ASSOCIATED_WITH_NEUROLOGICAL_OR_BRAIN_CONDITIONS = 'SYMPTOMS_ASSOCIATED_WITH_NEUROLOGICAL_OR_BRAIN_CONDITIONS',
  SYMPTOMS_ASSOCIATED_WITH_NEUROLOGICAL_OR_NERVOUS_SYSTEM_CONDITIONS = 'SYMPTOMS_ASSOCIATED_WITH_NEUROLOGICAL_OR_NERVOUS_SYSTEM_CONDITIONS',
  SYMPTOMS_ASSOCIATED_WITH_NEUROMUSCULAR_OR_NEURODEGENERATIVE_DISEASES = 'SYMPTOMS_ASSOCIATED_WITH_NEUROMUSCULAR_OR_NEURODEGENERATIVE_DISEASES',
  SYMPTOMS_ASSOCIATED_WITH_ONCOLOGICAL_DISEASES_OR_CANCER = 'SYMPTOMS_ASSOCIATED_WITH_ONCOLOGICAL_DISEASES_OR_CANCER',
  SYMPTOMS_ASSOCIATED_WITH_OPHTHALMOLOGIC_OR_EYE_CONDITIONS = 'SYMPTOMS_ASSOCIATED_WITH_OPHTHALMOLOGIC_OR_EYE_CONDITIONS',
  SYMPTOMS_ASSOCIATED_WITH_OPHTHALMOLOGIC_OR_OCULAR_CONDITIONS = 'SYMPTOMS_ASSOCIATED_WITH_OPHTHALMOLOGIC_OR_OCULAR_CONDITIONS',
  SYMPTOMS_ASSOCIATED_WITH_ORAL_SURGERY_SUCH_AS_WISDOM_TEETH_EXTRACTION = 'SYMPTOMS_ASSOCIATED_WITH_ORAL_SURGERY_SUCH_AS_WISDOM_TEETH_EXTRACTION',
  SYMPTOMS_ASSOCIATED_WITH_PARANEOPLASTIC_SYNDROMES = 'SYMPTOMS_ASSOCIATED_WITH_PARANEOPLASTIC_SYNDROMES',
  SYMPTOMS_ASSOCIATED_WITH_PEDIATRIC_INFECTIOUS_DISEASES_SUCH_AS_CHICKENPOX_OR_SCARLET_FEVER = 'SYMPTOMS_ASSOCIATED_WITH_PEDIATRIC_INFECTIOUS_DISEASES_SUCH_AS_CHICKENPOX_OR_SCARLET_FEVER',
  SYMPTOMS_ASSOCIATED_WITH_PEDIATRIC_OR_DEVELOPMENTAL_DISEASE = 'SYMPTOMS_ASSOCIATED_WITH_PEDIATRIC_OR_DEVELOPMENTAL_DISEASE',
  SYMPTOMS_ASSOCIATED_WITH_PSYCHIATRIC_DISORDERS = 'SYMPTOMS_ASSOCIATED_WITH_PSYCHIATRIC_DISORDERS',
  SYMPTOMS_ASSOCIATED_WITH_RHEUMATIC_OR_AUTOIMMUNE_DISEASES = 'SYMPTOMS_ASSOCIATED_WITH_RHEUMATIC_OR_AUTOIMMUNE_DISEASES',
  SYMPTOMS_ASSOCIATED_WITH_RHEUMATOLOGIC_OR_MUSCULOSKELETAL_CONDITIONS = 'SYMPTOMS_ASSOCIATED_WITH_RHEUMATOLOGIC_OR_MUSCULOSKELETAL_CONDITIONS',
  SYMPTOMS_ASSOCIATED_WITH_RHEUMATOLOGIC_OR_MUSCULOSKELETAL_DISORDERS = 'SYMPTOMS_ASSOCIATED_WITH_RHEUMATOLOGIC_OR_MUSCULOSKELETAL_DISORDERS',
  SYMPTOMS_ASSOCIATED_WITH_SEXUALLY_TRANSMITTED_DISEASES_SUCH_AS_GENITAL_HERPES = 'SYMPTOMS_ASSOCIATED_WITH_SEXUALLY_TRANSMITTED_DISEASES_SUCH_AS_GENITAL_HERPES',
  SYMPTOMS_ASSOCIATED_WITH_SPECIFIC_NEUROLOGICAL_SYNDROMES_SUCH_AS_MULTIPLE_SCLEROSIS_PARKINSON_S_DISEASE_ALZHEIMER_S_DISEASE_ETC = 'SYMPTOMS_ASSOCIATED_WITH_SPECIFIC_NEUROLOGICAL_SYNDROMES_SUCH_AS_MULTIPLE_SCLEROSIS_PARKINSON_S_DISEASE_ALZHEIMER_S_DISEASE_ETC',
  SYMPTOMS_ASSOCIATED_WITH_SPECIFIC_NEUROPSYCHOLOGICAL_SYNDROMES = 'SYMPTOMS_ASSOCIATED_WITH_SPECIFIC_NEUROPSYCHOLOGICAL_SYNDROMES',
  SYMPTOMS_ASSOCIATED_WITH_SPECIFIC_PEDIATRIC_CONDITIONS = 'SYMPTOMS_ASSOCIATED_WITH_SPECIFIC_PEDIATRIC_CONDITIONS',
  SYMPTOMS_ASSOCIATED_WITH_TRAUMATIC_BRAIN_INJURY = 'SYMPTOMS_ASSOCIATED_WITH_TRAUMATIC_BRAIN_INJURY',
  SYMPTOMS_ASSOCIATED_WITH_TRAUMATIC_ORTHOPEDIC_CONDITIONS_FRACTURES_DISLOCATIONS = 'SYMPTOMS_ASSOCIATED_WITH_TRAUMATIC_ORTHOPEDIC_CONDITIONS_FRACTURES_DISLOCATIONS',
  SYMPTOMS_ASSOCIATED_WITH_UROLOGIC_OR_NEPHROLOGIC_CONDITIONS = 'SYMPTOMS_ASSOCIATED_WITH_UROLOGIC_OR_NEPHROLOGIC_CONDITIONS',
  SYMPTOMS_AT_CARDIOVASCULAR_SYSTEM = 'SYMPTOMS_AT_CARDIOVASCULAR_SYSTEM',
  SYMPTOMS_A_SCAR_TISSUE_AND_POSTOPERATIVE = 'SYMPTOMS_A_SCAR_TISSUE_AND_POSTOPERATIVE',
  SYMPTOMS_FROM_DISEASES_OF_THE_DIGESTIVE_SYSTEM_SUCH_AS_PELVIC_PAIN = 'SYMPTOMS_FROM_DISEASES_OF_THE_DIGESTIVE_SYSTEM_SUCH_AS_PELVIC_PAIN',
  SYMPTOMS_FROM_NEUROLOGICAL_DISEASES_SUCH_AS_STROKE = 'SYMPTOMS_FROM_NEUROLOGICAL_DISEASES_SUCH_AS_STROKE',
  SYMPTOMS_FROM_ORTHOPEDIC_DISEASES_SUCH_AS_OSTEOARTHRITIS = 'SYMPTOMS_FROM_ORTHOPEDIC_DISEASES_SUCH_AS_OSTEOARTHRITIS',
  SYMPTOMS_IN_ANKLES = 'SYMPTOMS_IN_ANKLES',
  SYMPTOMS_IN_ARMS = 'SYMPTOMS_IN_ARMS',
  SYMPTOMS_IN_BASIN = 'SYMPTOMS_IN_BASIN',
  SYMPTOMS_IN_CENTRAL_NERVOUS_SYSTEM = 'SYMPTOMS_IN_CENTRAL_NERVOUS_SYSTEM',
  SYMPTOMS_IN_ELBOWS = 'SYMPTOMS_IN_ELBOWS',
  SYMPTOMS_IN_FEET = 'SYMPTOMS_IN_FEET',
  SYMPTOMS_IN_JOINTS = 'SYMPTOMS_IN_JOINTS',
  SYMPTOMS_IN_KNEES = 'SYMPTOMS_IN_KNEES',
  SYMPTOMS_IN_LIGAMENTS = 'SYMPTOMS_IN_LIGAMENTS',
  SYMPTOMS_IN_LYMPHATIC_SYSTEM = 'SYMPTOMS_IN_LYMPHATIC_SYSTEM',
  SYMPTOMS_IN_MUSCLES = 'SYMPTOMS_IN_MUSCLES',
  SYMPTOMS_IN_PERIPHERAL_NERVOUS_SYSTEM = 'SYMPTOMS_IN_PERIPHERAL_NERVOUS_SYSTEM',
  SYMPTOMS_IN_RESPIRATORY_SYSTEM = 'SYMPTOMS_IN_RESPIRATORY_SYSTEM',
  SYMPTOMS_IN_THIGHS = 'SYMPTOMS_IN_THIGHS',
  SYMPTOMS_IN_WRISTS_AND_HANDS = 'SYMPTOMS_IN_WRISTS_AND_HANDS',
  SYMPTOMS_OF_ANEMIA = 'SYMPTOMS_OF_ANEMIA',
  SYMPTOMS_OF_ARTERIOVENOUS_MALFORMATIONS_OR_CEREBRAL_ANEURYSMS = 'SYMPTOMS_OF_ARTERIOVENOUS_MALFORMATIONS_OR_CEREBRAL_ANEURYSMS',
  SYMPTOMS_OF_BRAIN_TUMORS_SUCH_AS_CHANGES_IN_CONSCIOUSNESS_OR_MOOD = 'SYMPTOMS_OF_BRAIN_TUMORS_SUCH_AS_CHANGES_IN_CONSCIOUSNESS_OR_MOOD',
  SYMPTOMS_OF_CARDIOPULMONARY_DISEASE = 'SYMPTOMS_OF_CARDIOPULMONARY_DISEASE',
  SYMPTOMS_OF_CENTRAL_OR_PERIPHERAL_NEUROLOGICAL_PROBLEMS = 'SYMPTOMS_OF_CENTRAL_OR_PERIPHERAL_NEUROLOGICAL_PROBLEMS',
  SYMPTOMS_OF_CEREBRAL_ARTERY_DISEASE_SUCH_AS_STROKE_OR_CEREBRAL_ISCHEMIA = 'SYMPTOMS_OF_CEREBRAL_ARTERY_DISEASE_SUCH_AS_STROKE_OR_CEREBRAL_ISCHEMIA',
  SYMPTOMS_OF_CEREBROVASCULAR_DISORDERS_STROKE_HEMORRHAGE = 'SYMPTOMS_OF_CEREBROVASCULAR_DISORDERS_STROKE_HEMORRHAGE',
  SYMPTOMS_OF_CHRONIC_COLD_OR_ALLERGIES = 'SYMPTOMS_OF_CHRONIC_COLD_OR_ALLERGIES',
  SYMPTOMS_OF_CONGESTIVE_HEART_FAILURE = 'SYMPTOMS_OF_CONGESTIVE_HEART_FAILURE',
  SYMPTOMS_OF_CONJUNCTIVITIS_REDNESS_DISCHARGE = 'SYMPTOMS_OF_CONJUNCTIVITIS_REDNESS_DISCHARGE',
  SYMPTOMS_OF_CONNECTIVE_TISSUE_DISEASES_SUCH_AS_RAYNAUD_S_PHENOMENON = 'SYMPTOMS_OF_CONNECTIVE_TISSUE_DISEASES_SUCH_AS_RAYNAUD_S_PHENOMENON',
  SYMPTOMS_OF_CRANIAL_SYNDROMES_SUCH_AS_CARPAL_TUNNEL_SYNDROME = 'SYMPTOMS_OF_CRANIAL_SYNDROMES_SUCH_AS_CARPAL_TUNNEL_SYNDROME',
  SYMPTOMS_OF_DEGENERATIVE_DISEASES_OF_THE_NERVOUS_SYSTEM_SUCH_AS_PARKINSON_S_DISEASE = 'SYMPTOMS_OF_DEGENERATIVE_DISEASES_OF_THE_NERVOUS_SYSTEM_SUCH_AS_PARKINSON_S_DISEASE',
  SYMPTOMS_OF_DIAPHRAGMATIC_HERNIA = 'SYMPTOMS_OF_DIAPHRAGMATIC_HERNIA',
  SYMPTOMS_OF_DISEASES_OF_THE_ANUS_AND_RECTUM_SUCH_AS_HEMORRHOIDS_OR_FISSURES = 'SYMPTOMS_OF_DISEASES_OF_THE_ANUS_AND_RECTUM_SUCH_AS_HEMORRHOIDS_OR_FISSURES',
  SYMPTOMS_OF_DISEASES_OF_THE_LARGE_INTESTINE_SUCH_AS_COLITIS_OR_DIVERTICULITIS = 'SYMPTOMS_OF_DISEASES_OF_THE_LARGE_INTESTINE_SUCH_AS_COLITIS_OR_DIVERTICULITIS',
  SYMPTOMS_OF_DISEASES_OF_THE_SMALL_INTESTINE_SUCH_AS_MALABSORPTION = 'SYMPTOMS_OF_DISEASES_OF_THE_SMALL_INTESTINE_SUCH_AS_MALABSORPTION',
  SYMPTOMS_OF_DISORDERS_OF_INNATE_IMMUNITY_SUCH_AS_CROHN_S_DISEASE = 'SYMPTOMS_OF_DISORDERS_OF_INNATE_IMMUNITY_SUCH_AS_CROHN_S_DISEASE',
  SYMPTOMS_OF_EAR_NOSE_OR_THROAT_DISEASE_RELATED_TO_INFECTIONS_ALLERGIES_OR_CHRONIC_CONDITIONS = 'SYMPTOMS_OF_EAR_NOSE_OR_THROAT_DISEASE_RELATED_TO_INFECTIONS_ALLERGIES_OR_CHRONIC_CONDITIONS',
  SYMPTOMS_OF_ESOPHAGEAL_DISEASES_SUCH_AS_GASTROESOPHAGEAL_REFLUX_OR_ESOPHAGITIS = 'SYMPTOMS_OF_ESOPHAGEAL_DISEASES_SUCH_AS_GASTROESOPHAGEAL_REFLUX_OR_ESOPHAGITIS',
  SYMPTOMS_OF_EXERCISE_INTOLERANCE = 'SYMPTOMS_OF_EXERCISE_INTOLERANCE',
  SYMPTOMS_OF_FATIGUE = 'SYMPTOMS_OF_FATIGUE',
  SYMPTOMS_OF_GALLBLADDER_DISEASE_SUCH_AS_BILIARY_COLIC = 'SYMPTOMS_OF_GALLBLADDER_DISEASE_SUCH_AS_BILIARY_COLIC',
  SYMPTOMS_OF_HEAD_INJURY_SUCH_AS_SKULL_FRACTURES_OR_HEMATOMAS = 'SYMPTOMS_OF_HEAD_INJURY_SUCH_AS_SKULL_FRACTURES_OR_HEMATOMAS',
  SYMPTOMS_OF_INFECTION_OR_INFLAMMATION_OF_THE_CENTRAL_NERVOUS_SYSTEM = 'SYMPTOMS_OF_INFECTION_OR_INFLAMMATION_OF_THE_CENTRAL_NERVOUS_SYSTEM',
  SYMPTOMS_OF_INTERSTITIAL_CYSTITIS_INFLAMMATION_OF_THE_BLADDER_WALL = 'SYMPTOMS_OF_INTERSTITIAL_CYSTITIS_INFLAMMATION_OF_THE_BLADDER_WALL',
  SYMPTOMS_OF_KIDNEY_DISEASE_SUCH_AS_POLYCYSTIC_KIDNEY = 'SYMPTOMS_OF_KIDNEY_DISEASE_SUCH_AS_POLYCYSTIC_KIDNEY',
  SYMPTOMS_OF_LIVER_DISEASE_SUCH_AS_JAUNDICE_OR_ASCITES = 'SYMPTOMS_OF_LIVER_DISEASE_SUCH_AS_JAUNDICE_OR_ASCITES',
  SYMPTOMS_OF_MUSCLE_OR_TENDON_DISORDERS_SUCH_AS_TENDINITIS_OR_MYALGIA = 'SYMPTOMS_OF_MUSCLE_OR_TENDON_DISORDERS_SUCH_AS_TENDINITIS_OR_MYALGIA',
  SYMPTOMS_OF_MUSCULAR_OR_MYOFASCIAL_CONDITIONS_SUCH_AS_FIBROMYALGIA = 'SYMPTOMS_OF_MUSCULAR_OR_MYOFASCIAL_CONDITIONS_SUCH_AS_FIBROMYALGIA',
  SYMPTOMS_OF_NEURODEGENERATIVE_DISEASES_SUCH_AS_ALZHEIMER_S = 'SYMPTOMS_OF_NEURODEGENERATIVE_DISEASES_SUCH_AS_ALZHEIMER_S',
  SYMPTOMS_OF_NEURODEGENERATIVE_DISORDERS = 'SYMPTOMS_OF_NEURODEGENERATIVE_DISORDERS',
  SYMPTOMS_OF_PANCREATIC_DISEASES_SUCH_AS_PANCREATITIS = 'SYMPTOMS_OF_PANCREATIC_DISEASES_SUCH_AS_PANCREATITIS',
  SYMPTOMS_OF_PERIPHERAL_ARTERIAL_OCCLUSION_SUCH_AS_PAIN_OR_COLDNESS_IN_THE_EXTREMITIES = 'SYMPTOMS_OF_PERIPHERAL_ARTERIAL_OCCLUSION_SUCH_AS_PAIN_OR_COLDNESS_IN_THE_EXTREMITIES',
  SYMPTOMS_OF_PERIPHERAL_NERVE_COMPRESSION = 'SYMPTOMS_OF_PERIPHERAL_NERVE_COMPRESSION',
  SYMPTOMS_OF_PERIPHERAL_NERVOUS_SYSTEM_DISEASES = 'SYMPTOMS_OF_PERIPHERAL_NERVOUS_SYSTEM_DISEASES',
  SYMPTOMS_OF_PREMENSTRUAL_SYNDROME_PMS = 'SYMPTOMS_OF_PREMENSTRUAL_SYNDROME_PMS',
  SYMPTOMS_OF_PROSTATITIS_INFLAMMATION_OF_THE_PROSTATE = 'SYMPTOMS_OF_PROSTATITIS_INFLAMMATION_OF_THE_PROSTATE',
  SYMPTOMS_OF_PULMONARY_ARTERY_STENOSIS_OR_OCCLUSION = 'SYMPTOMS_OF_PULMONARY_ARTERY_STENOSIS_OR_OCCLUSION',
  SYMPTOMS_OF_RECURRENT_TONSILLITIS_OR_ADENOIDITIS = 'SYMPTOMS_OF_RECURRENT_TONSILLITIS_OR_ADENOIDITIS',
  SYMPTOMS_OF_RENAL_ARTERY_DISEASE_SUCH_AS_RENAL_FAILURE = 'SYMPTOMS_OF_RENAL_ARTERY_DISEASE_SUCH_AS_RENAL_FAILURE',
  SYMPTOMS_OF_RENAL_ARTERY_DISEASE_SUCH_AS_RENAL_HYPERTENSION = 'SYMPTOMS_OF_RENAL_ARTERY_DISEASE_SUCH_AS_RENAL_HYPERTENSION',
  SYMPTOMS_OF_SARCOIDOSIS_SUCH_AS_MULTIPLE_GRANULOMAS = 'SYMPTOMS_OF_SARCOIDOSIS_SUCH_AS_MULTIPLE_GRANULOMAS',
  SYMPTOMS_OF_SEVERE_OR_COMPLEX_ALLERGIES_SUCH_AS_ALLERGIC_CONTACT_DERMATITIS = 'SYMPTOMS_OF_SEVERE_OR_COMPLEX_ALLERGIES_SUCH_AS_ALLERGIC_CONTACT_DERMATITIS',
  SYMPTOMS_OF_SINUSITIS_SUCH_AS_FACIAL_PAIN_OR_PRESSURE = 'SYMPTOMS_OF_SINUSITIS_SUCH_AS_FACIAL_PAIN_OR_PRESSURE',
  SYMPTOMS_OF_SPINAL_CORD_COMPRESSION = 'SYMPTOMS_OF_SPINAL_CORD_COMPRESSION',
  SYMPTOMS_OF_SPINAL_CORD_INJURY_SUCH_AS_PARAPLEGIA_OR_QUADRIPLEGIA = 'SYMPTOMS_OF_SPINAL_CORD_INJURY_SUCH_AS_PARAPLEGIA_OR_QUADRIPLEGIA',
  SYMPTOMS_OF_SPINAL_INJURY_OR_SPINAL_PATHOLOGY = 'SYMPTOMS_OF_SPINAL_INJURY_OR_SPINAL_PATHOLOGY',
  SYMPTOMS_OF_SPLEEN_DISEASE_SUCH_AS_SPLENOMEGALY = 'SYMPTOMS_OF_SPLEEN_DISEASE_SUCH_AS_SPLENOMEGALY',
  SYMPTOMS_OF_STOMACH_DISEASES_SUCH_AS_ULCERS_OR_GASTRITIS = 'SYMPTOMS_OF_STOMACH_DISEASES_SUCH_AS_ULCERS_OR_GASTRITIS',
  SYMPTOMS_OF_SYSTEMIC_DISEASES_WITH_OCULAR_MANIFESTATIONS = 'SYMPTOMS_OF_SYSTEMIC_DISEASES_WITH_OCULAR_MANIFESTATIONS',
  SYMPTOMS_OF_TRAUMATIC_BRAIN_INJURY = 'SYMPTOMS_OF_TRAUMATIC_BRAIN_INJURY',
  SYMPTOMS_OF_UNDERLYING_DISEASES_SUCH_AS_DIABETES_OR_VASCULAR_DISEASE = 'SYMPTOMS_OF_UNDERLYING_DISEASES_SUCH_AS_DIABETES_OR_VASCULAR_DISEASE',
  SYMPTOMS_OF_UROLOGICAL_TUMORS_SUCH_AS_BLADDER_OR_KIDNEY_CANCER = 'SYMPTOMS_OF_UROLOGICAL_TUMORS_SUCH_AS_BLADDER_OR_KIDNEY_CANCER',
  SYMPTOMS_OF_VASCULITIS_SUCH_AS_PETECHIAE_PURPURA = 'SYMPTOMS_OF_VASCULITIS_SUCH_AS_PETECHIAE_PURPURA',
  SYMPTOMS_OF_VISCERAL_ARTERY_DISEASE_SUCH_AS_ABDOMINAL_ANGINA = 'SYMPTOMS_OF_VISCERAL_ARTERY_DISEASE_SUCH_AS_ABDOMINAL_ANGINA',
  SYMPTOMS_OF_VISCERAL_ARTERY_DISEASE_SUCH_AS_DIGESTIVE_PROBLEMS = 'SYMPTOMS_OF_VISCERAL_ARTERY_DISEASE_SUCH_AS_DIGESTIVE_PROBLEMS',
  SYMPTOMS_RELATED_TO_BLADDER_DYSFUNCTION_SUCH_AS_OVERACTIVE_BLADDER_SYNDROME = 'SYMPTOMS_RELATED_TO_BLADDER_DYSFUNCTION_SUCH_AS_OVERACTIVE_BLADDER_SYNDROME',
  SYMPTOMS_RELATED_TO_CANCER_THERAPIES_SUCH_AS_NAUSEA_FROM_CHEMOTHERAPY_HAIR_LOSS = 'SYMPTOMS_RELATED_TO_CANCER_THERAPIES_SUCH_AS_NAUSEA_FROM_CHEMOTHERAPY_HAIR_LOSS',
  SYMPTOMS_RELATED_TO_CONGENITAL_OR_ACQUIRED_HEART_CONDITIONS = 'SYMPTOMS_RELATED_TO_CONGENITAL_OR_ACQUIRED_HEART_CONDITIONS',
  SYMPTOMS_RELATED_TO_DEGENERATIVE_NEUROLOGICAL_DISEASES_SUCH_AS_PARKINSON_S_DISEASE = 'SYMPTOMS_RELATED_TO_DEGENERATIVE_NEUROLOGICAL_DISEASES_SUCH_AS_PARKINSON_S_DISEASE',
  SYMPTOMS_RELATED_TO_GYNECOLOGICAL_CANCERS_SUCH_AS_CHANGES_IN_MENSTRUAL_CYCLE_OR_PELVIC_PAIN = 'SYMPTOMS_RELATED_TO_GYNECOLOGICAL_CANCERS_SUCH_AS_CHANGES_IN_MENSTRUAL_CYCLE_OR_PELVIC_PAIN',
  SYMPTOMS_RELATED_TO_NEUROLOGICAL_DISEASES_SUCH_AS_STROKE_MULTIPLE_SCLEROSIS = 'SYMPTOMS_RELATED_TO_NEUROLOGICAL_DISEASES_SUCH_AS_STROKE_MULTIPLE_SCLEROSIS',
  SYMPTOMS_RELATED_TO_PEDIATRIC_NEUROLOGICAL_DISORDERS = 'SYMPTOMS_RELATED_TO_PEDIATRIC_NEUROLOGICAL_DISORDERS',
  SYMPTOMS_RELATED_TO_PEDIATRIC_ORTHOPEDIC_CONDITIONS = 'SYMPTOMS_RELATED_TO_PEDIATRIC_ORTHOPEDIC_CONDITIONS',
  SYMPTOMS_RELATED_TO_PEDIATRIC_UROLOGIC_CONDITIONS_SUCH_AS_GENITAL_HYGIENE_OR_NOCTURNAL_ENURESIS = 'SYMPTOMS_RELATED_TO_PEDIATRIC_UROLOGIC_CONDITIONS_SUCH_AS_GENITAL_HYGIENE_OR_NOCTURNAL_ENURESIS',
  SYMPTOMS_RELATED_TO_THE_LYMPHATIC_SYSTEM_SUCH_AS_ENLARGED_LYMPH_NODES = 'SYMPTOMS_RELATED_TO_THE_LYMPHATIC_SYSTEM_SUCH_AS_ENLARGED_LYMPH_NODES',
  SYMPTOMS_RELATED_TO_TOOTH_ERUPTION_IN_CHILDREN_TEETHING = 'SYMPTOMS_RELATED_TO_TOOTH_ERUPTION_IN_CHILDREN_TEETHING',
  SYMPTOMS_RELATED_TO_TRAUMATIC_ORTHOPEDIC_CONDITIONS_FRACTURES_DISLOCATIONS = 'SYMPTOMS_RELATED_TO_TRAUMATIC_ORTHOPEDIC_CONDITIONS_FRACTURES_DISLOCATIONS',
  SYMPTOMS_RELATED_TO_TUMORS_OF_THE_HEMOLYMPHOPOIETIC_SYSTEM_SUCH_AS_LYMPHOMAS = 'SYMPTOMS_RELATED_TO_TUMORS_OF_THE_HEMOLYMPHOPOIETIC_SYSTEM_SUCH_AS_LYMPHOMAS',
  SYMPTOMS_TO_MUSCULOSKELETAL_SYSTEM_IN_GENERAL = 'SYMPTOMS_TO_MUSCULOSKELETAL_SYSTEM_IN_GENERAL',
  SYNDROME_OF_INAPPROPRIATE_RELEASE_OF_ADH_SIADH = 'SYNDROME_OF_INAPPROPRIATE_RELEASE_OF_ADH_SIADH',
  SYSTEMIC_LUPUS_ERYTHEMATOSUS_NEPHROPATHY = 'SYSTEMIC_LUPUS_ERYTHEMATOSUS_NEPHROPATHY',
  TEARY_EYES_WATERY_EYES = 'TEARY_EYES_WATERY_EYES',
  TEAR_CRAMP = 'TEAR_CRAMP',
  TEETHING_PROBLEMS_OR_MALOCCLUSION = 'TEETHING_PROBLEMS_OR_MALOCCLUSION',
  TENDINITIS_OR_INFLAMMATION_OF_TENDONS = 'TENDINITIS_OR_INFLAMMATION_OF_TENDONS',
  TENDON_PAIN_TENDINOPATHIES = 'TENDON_PAIN_TENDINOPATHIES',
  TENDON_SYMPTOMS = 'TENDON_SYMPTOMS',
  TESTICULAR_PAIN = 'TESTICULAR_PAIN',
  TESTICULAR_PAIN_VARIANT_2 = 'TESTICULAR_PAIN_VARIANT_2',
  TESTICULAR_PROSTHESIS_CORRECTION_SURGERY = 'TESTICULAR_PROSTHESIS_CORRECTION_SURGERY',
  THINKING_OF_BEING_MONITORED_OR_SPIED_ON = 'THINKING_OF_BEING_MONITORED_OR_SPIED_ON',
  THIN_SKIN_OR_SKIN_FRAGILITY = 'THIN_SKIN_OR_SKIN_FRAGILITY',
  THORACIC_AND_PULMONARY_TUMORS = 'THORACIC_AND_PULMONARY_TUMORS',
  THORACIC_TRAUMA = 'THORACIC_TRAUMA',
  THROAT_PAIN = 'THROAT_PAIN',
  THROAT_PAIN_VARIANT_2 = 'THROAT_PAIN_VARIANT_2',
  THROMBOCYTOPATHY_OR_ABNORMAL_BLEEDING = 'THROMBOCYTOPATHY_OR_ABNORMAL_BLEEDING',
  THROMBOCYTOPENIA_LOW_PLATELET_COUNT = 'THROMBOCYTOPENIA_LOW_PLATELET_COUNT',
  THROMBOCYTOSIS_HIGH_PLATELET_COUNT = 'THROMBOCYTOSIS_HIGH_PLATELET_COUNT',
  THYROID_DISORDERS = 'THYROID_DISORDERS',
  THYROID_DISORDERS_VARIANT_2 = 'THYROID_DISORDERS_VARIANT_2',
  THYROID_OR_HORMONE_DISORDERS = 'THYROID_OR_HORMONE_DISORDERS',
  THYROID_PROBLEMS_HYPERTHYROIDISM_OR_HYPOTHYROIDISM = 'THYROID_PROBLEMS_HYPERTHYROIDISM_OR_HYPOTHYROIDISM',
  THYROID_SCINTIGRAPHY_STUDIES = 'THYROID_SCINTIGRAPHY_STUDIES',
  THYROID_SYMPTOMS_SUCH_AS_NODULES_OR_GOITER = 'THYROID_SYMPTOMS_SUCH_AS_NODULES_OR_GOITER',
  TIC_DISORDER = 'TIC_DISORDER',
  TINGLING_IN_THE_HANDS_OR_FEET = 'TINGLING_IN_THE_HANDS_OR_FEET',
  TINGLING_OR_NUMBNESS = 'TINGLING_OR_NUMBNESS',
  TINGLING_OR_NUMBNESS_IN_THE_HANDS_OR_FEET = 'TINGLING_OR_NUMBNESS_IN_THE_HANDS_OR_FEET',
  TINGLING_OR_NUMBNESS_IN_THE_LEGS = 'TINGLING_OR_NUMBNESS_IN_THE_LEGS',
  TINNITUS_RINGING_OR_BUZZING_IN_THE_EARS = 'TINNITUS_RINGING_OR_BUZZING_IN_THE_EARS',
  TINNITUS_RINGING_WHISTLING_OR_OTHER_SOUNDS_IN_THE_EARS = 'TINNITUS_RINGING_WHISTLING_OR_OTHER_SOUNDS_IN_THE_EARS',
  TIREDNESS_AND_FATIGUE = 'TIREDNESS_AND_FATIGUE',
  TISSUE_NECROSIS = 'TISSUE_NECROSIS',
  TOOTHACHE = 'TOOTHACHE',
  TOOTHACHE_OBVIOUS_PAIN = 'TOOTHACHE_OBVIOUS_PAIN',
  TOTAL_OR_PARTIAL_LOSS_OF_VISION = 'TOTAL_OR_PARTIAL_LOSS_OF_VISION',
  TOURETTE_SYNDROME = 'TOURETTE_SYNDROME',
  TRAUMATIC_INJURIES_TO_THE_MOUTH_OR_JAW = 'TRAUMATIC_INJURIES_TO_THE_MOUTH_OR_JAW',
  TREATMENT_OF_CHEMICAL_BURNS_OF_THE_BODY = 'TREATMENT_OF_CHEMICAL_BURNS_OF_THE_BODY',
  TREATMENT_OF_CHEMICAL_OR_ELECTRICAL_BURNS = 'TREATMENT_OF_CHEMICAL_OR_ELECTRICAL_BURNS',
  TREATMENT_OF_COMPLICATIONS_FROM_COSMETIC_PROCEDURES = 'TREATMENT_OF_COMPLICATIONS_FROM_COSMETIC_PROCEDURES',
  TREATMENT_OF_COMPLICATIONS_FROM_COSMETIC_SURGERY = 'TREATMENT_OF_COMPLICATIONS_FROM_COSMETIC_SURGERY',
  TREATMENT_OF_COMPLICATIONS_FROM_LIPOSUCTION = 'TREATMENT_OF_COMPLICATIONS_FROM_LIPOSUCTION',
  TREATMENT_OF_COMPLICATIONS_FROM_PREVIOUS_ORTHOPEDIC_SURGERY = 'TREATMENT_OF_COMPLICATIONS_FROM_PREVIOUS_ORTHOPEDIC_SURGERY',
  TREATMENT_OF_COMPLICATIONS_FROM_PREVIOUS_RECONSTRUCTIVE_SURGERY = 'TREATMENT_OF_COMPLICATIONS_FROM_PREVIOUS_RECONSTRUCTIVE_SURGERY',
  TREATMENT_OF_CONGENITAL_OR_ACQUIRED_CRANIOFACIAL_ANOMALIES = 'TREATMENT_OF_CONGENITAL_OR_ACQUIRED_CRANIOFACIAL_ANOMALIES',
  TREATMENT_OF_EXPOSED_BONE_FRACTURES = 'TREATMENT_OF_EXPOSED_BONE_FRACTURES',
  TREATMENT_OF_HYPERTROPHIC_OR_KELOID_SCARS = 'TREATMENT_OF_HYPERTROPHIC_OR_KELOID_SCARS',
  TREATMENT_OF_INFECTION_AND_NECROTIC_TISSUE = 'TREATMENT_OF_INFECTION_AND_NECROTIC_TISSUE',
  TREATMENT_OF_RADIATION_BURNS = 'TREATMENT_OF_RADIATION_BURNS',
  TREATMENT_OF_SKIN_DEFECTS_FROM_TRAUMATIC_INJURIES = 'TREATMENT_OF_SKIN_DEFECTS_FROM_TRAUMATIC_INJURIES',
  TREATMENT_OF_SOFT_TISSUE_INJURIES_OF_THE_HEAD_AND_NECK = 'TREATMENT_OF_SOFT_TISSUE_INJURIES_OF_THE_HEAD_AND_NECK',
  TREATMENT_OF_TMJ_DISORDERS = 'TREATMENT_OF_TMJ_DISORDERS',
  TREATMENT_OF_TRAUMATIC_EYE_INJURIES = 'TREATMENT_OF_TRAUMATIC_EYE_INJURIES',
  TREATMENT_OF_WRINKLES_OR_SKIN_AGING = 'TREATMENT_OF_WRINKLES_OR_SKIN_AGING',
  TREATMENT_WITH_MANY_DRUGS = 'TREATMENT_WITH_MANY_DRUGS',
  TREMORS = 'TREMORS',
  TREMORS_OR_SHAKING = 'TREMORS_OR_SHAKING',
  TUBERCULOSIS_PERSISTENT_COUGH_FEVER_NIGHT_SWEATS = 'TUBERCULOSIS_PERSISTENT_COUGH_FEVER_NIGHT_SWEATS',
  TUBERCULOUS_PLEURISY_CHEST_PAIN_COUGH_DYSPNEA = 'TUBERCULOUS_PLEURISY_CHEST_PAIN_COUGH_DYSPNEA',
  TUMORS_OF_ADIPOSE_TISSUE = 'TUMORS_OF_ADIPOSE_TISSUE',
  TUMORS_OF_BONE_AND_CONNECTIVE_TISSUE = 'TUMORS_OF_BONE_AND_CONNECTIVE_TISSUE',
  TUMORS_OF_MUSCLE_TISSUE = 'TUMORS_OF_MUSCLE_TISSUE',
  TUMORS_OF_NERVOUS_TISSUE = 'TUMORS_OF_NERVOUS_TISSUE',
  TUMORS_OF_THE_CENTRAL_NERVOUS_SYSTEM = 'TUMORS_OF_THE_CENTRAL_NERVOUS_SYSTEM',
  TUMORS_OF_THE_CERVIX = 'TUMORS_OF_THE_CERVIX',
  TUMORS_OF_THE_COLON_AND_RECTUM = 'TUMORS_OF_THE_COLON_AND_RECTUM',
  TUMORS_OF_THE_ENCEPHALON = 'TUMORS_OF_THE_ENCEPHALON',
  TUMORS_OF_THE_ESOPHAGUS = 'TUMORS_OF_THE_ESOPHAGUS',
  TUMORS_OF_THE_GASTROINTESTINAL_TRACT = 'TUMORS_OF_THE_GASTROINTESTINAL_TRACT',
  TUMORS_OF_THE_HEAD_AND_NECK = 'TUMORS_OF_THE_HEAD_AND_NECK',
  TUMORS_OF_THE_KIDNEY = 'TUMORS_OF_THE_KIDNEY',
  TUMORS_OF_THE_LARYNX = 'TUMORS_OF_THE_LARYNX',
  TUMORS_OF_THE_LYMPHATIC_SYSTEM = 'TUMORS_OF_THE_LYMPHATIC_SYSTEM',
  TUMORS_OF_THE_MOUTH = 'TUMORS_OF_THE_MOUTH',
  TUMORS_OF_THE_MUSCULOSKELETAL_SYSTEM = 'TUMORS_OF_THE_MUSCULOSKELETAL_SYSTEM',
  TUMORS_OF_THE_OVARY = 'TUMORS_OF_THE_OVARY',
  TUMORS_OF_THE_PANCREAS = 'TUMORS_OF_THE_PANCREAS',
  TUMORS_OF_THE_PHARYNX = 'TUMORS_OF_THE_PHARYNX',
  TUMORS_OF_THE_RESPIRATORY_SYSTEM = 'TUMORS_OF_THE_RESPIRATORY_SYSTEM',
  TUMORS_OF_THE_SPINAL_CORD = 'TUMORS_OF_THE_SPINAL_CORD',
  TUMORS_OF_THE_STOMACH = 'TUMORS_OF_THE_STOMACH',
  TUMORS_OF_THE_TESTIS = 'TUMORS_OF_THE_TESTIS',
  TUMORS_OF_THE_THROAT = 'TUMORS_OF_THE_THROAT',
  TUMORS_OF_THE_URETHRA = 'TUMORS_OF_THE_URETHRA',
  TUMORS_OF_THE_UROGENITAL_SYSTEM = 'TUMORS_OF_THE_UROGENITAL_SYSTEM',
  TUMORS_OF_THE_UTERUS = 'TUMORS_OF_THE_UTERUS',
  TUMORS_OF_THE_VAGINA = 'TUMORS_OF_THE_VAGINA',
  TUMORS_OF_THE_VULVA = 'TUMORS_OF_THE_VULVA',
  TUMORS_OF_VASCULAR_TISSUE = 'TUMORS_OF_VASCULAR_TISSUE',
  TUMOR_OR_PALPABLE_MASS = 'TUMOR_OR_PALPABLE_MASS',
  UGLY_DISFIGURING_SCAR = 'UGLY_DISFIGURING_SCAR',
  ULCERS_OR_LESIONS_THAT_DO_NOT_HEAL_ON_THE_LEGS_OR_FEET = 'ULCERS_OR_LESIONS_THAT_DO_NOT_HEAL_ON_THE_LEGS_OR_FEET',
  ULCERS_OR_SKIN_LESIONS_THAT_DO_NOT_HEAL = 'ULCERS_OR_SKIN_LESIONS_THAT_DO_NOT_HEAL',
  ULCERS_OR_SKIN_LESIONS_THAT_DO_NOT_HEAL_ADEQUATELY = 'ULCERS_OR_SKIN_LESIONS_THAT_DO_NOT_HEAL_ADEQUATELY',
  ULNAR_NECK_PAIN = 'ULNAR_NECK_PAIN',
  UNCONTROLLED_ASTHMA = 'UNCONTROLLED_ASTHMA',
  UNDERWEIGHT = 'UNDERWEIGHT',
  UNEXPLAINED_WEIGHT_LOSS = 'UNEXPLAINED_WEIGHT_LOSS',
  UNINTENTIONAL_APPETITE_REDUCTION_OR_WEIGHT_LOSS = 'UNINTENTIONAL_APPETITE_REDUCTION_OR_WEIGHT_LOSS',
  UNINTENTIONAL_WEIGHT_LOSS = 'UNINTENTIONAL_WEIGHT_LOSS',
  UNINTENTIONAL_WEIGHT_LOSS_VARIANT_2 = 'UNINTENTIONAL_WEIGHT_LOSS_VARIANT_2',
  UNPLEASANT_ODORS_FROM_ULCERS_OR_WOUNDS = 'UNPLEASANT_ODORS_FROM_ULCERS_OR_WOUNDS',
  UNSTABLE_BLOOD_PRESSURE = 'UNSTABLE_BLOOD_PRESSURE',
  UPPER_ABDOMINAL_PAIN = 'UPPER_ABDOMINAL_PAIN',
  UPPER_AIRWAY_DISEASES_SUCH_AS_RHINITIS_OR_SINUSITIS = 'UPPER_AIRWAY_DISEASES_SUCH_AS_RHINITIS_OR_SINUSITIS',
  UPPER_SHOULDER_PAIN = 'UPPER_SHOULDER_PAIN',
  URGENT_URINATION_SUDDEN_AND_UNCONTROLLABLE_NEED_TO_URINATE = 'URGENT_URINATION_SUDDEN_AND_UNCONTROLLABLE_NEED_TO_URINATE',
  URINARY_BLEEDING = 'URINARY_BLEEDING',
  URINARY_INCONTINENCE = 'URINARY_INCONTINENCE',
  URINARY_RETENTION = 'URINARY_RETENTION',
  URINARY_SYMPTOMS_FREQUENCY_URGENCY_BURNING = 'URINARY_SYMPTOMS_FREQUENCY_URGENCY_BURNING',
  URINARY_SYMPTOMS_SUCH_AS_BLOOD_IN_URINE_OR_CHANGES_IN_URINARY_FLOW = 'URINARY_SYMPTOMS_SUCH_AS_BLOOD_IN_URINE_OR_CHANGES_IN_URINARY_FLOW',
  URINARY_TRACT_DISORDERS_SUCH_AS_HEMATURIA = 'URINARY_TRACT_DISORDERS_SUCH_AS_HEMATURIA',
  URINARY_TRACT_DISORDERS_SUCH_AS_RENAL_COLIC = 'URINARY_TRACT_DISORDERS_SUCH_AS_RENAL_COLIC',
  URINARY_TRACT_OR_BLADDER_DISORDERS = 'URINARY_TRACT_OR_BLADDER_DISORDERS',
  URINARY_TRACT_PAIN = 'URINARY_TRACT_PAIN',
  UROLOGICAL_OR_NEPHROLOGICAL_DISORDERS = 'UROLOGICAL_OR_NEPHROLOGICAL_DISORDERS',
  UROLOGICAL_TUMOR_RELATED_SYMPTOMS_SUCH_AS_DIFFICULTY_IN_URINATION_OR_BLOOD_IN_URINE = 'UROLOGICAL_TUMOR_RELATED_SYMPTOMS_SUCH_AS_DIFFICULTY_IN_URINATION_OR_BLOOD_IN_URINE',
  URTICARIA_RED_DOTS_OR_BUMPS_ON_THE_SKIN = 'URTICARIA_RED_DOTS_OR_BUMPS_ON_THE_SKIN',
  UTERINE_BLEEDING_DISORDERS = 'UTERINE_BLEEDING_DISORDERS',
  UTERINE_FIBROIDS = 'UTERINE_FIBROIDS',
  VAGINAL_CANDIDIASIS = 'VAGINAL_CANDIDIASIS',
  VAGINAL_ITCHING = 'VAGINAL_ITCHING',
  VAGINAL_ITCHING_OR_BURNING = 'VAGINAL_ITCHING_OR_BURNING',
  VARICES = 'VARICES',
  VARICES_DILATED_OR_TORTUOUS_VEINS = 'VARICES_DILATED_OR_TORTUOUS_VEINS',
  VARICOCELE_DILATION_OF_THE_VEINS_OF_THE_TESTICLE = 'VARICOCELE_DILATION_OF_THE_VEINS_OF_THE_TESTICLE',
  VASCULAR_PROBLEMS_ATHEROSCLEROSIS_VARICOSE_VEINS = 'VASCULAR_PROBLEMS_ATHEROSCLEROSIS_VARICOSE_VEINS',
  VASCULAR_SYMPTOMS_SUCH_AS_ANEURYSMS = 'VASCULAR_SYMPTOMS_SUCH_AS_ANEURYSMS',
  VASCULAR_WEAKNESS_AS_IN_CAPILLARY_FRAGILITY = 'VASCULAR_WEAKNESS_AS_IN_CAPILLARY_FRAGILITY',
  VENOUS_OR_ARTERIAL_ULCERS = 'VENOUS_OR_ARTERIAL_ULCERS',
  VESTIBULAR_SYSTEM_SYMPTOMS = 'VESTIBULAR_SYSTEM_SYMPTOMS',
  VICE_OF_THE_GAME = 'VICE_OF_THE_GAME',
  VICE_OR_ADDICTION = 'VICE_OR_ADDICTION',
  VISIBLE_VEINS = 'VISIBLE_VEINS',
  VISION_DISORDERS_ASSOCIATED_WITH_HEREDITARY_CONDITIONS = 'VISION_DISORDERS_ASSOCIATED_WITH_HEREDITARY_CONDITIONS',
  VISION_OR_EYE_PROBLEMS = 'VISION_OR_EYE_PROBLEMS',
  VISION_PROBLEMS = 'VISION_PROBLEMS',
  VISUAL_DISORDERS = 'VISUAL_DISORDERS',
  VISUAL_IMPAIRMENT_CAUSED_BY_INJURY_OR_TRAUMA = 'VISUAL_IMPAIRMENT_CAUSED_BY_INJURY_OR_TRAUMA',
  VISUAL_OR_OPHTHALMIC_DISORDERS = 'VISUAL_OR_OPHTHALMIC_DISORDERS',
  VISUAL_PROBLEMS_CATARACTS_MACULAR_DEGENERATION = 'VISUAL_PROBLEMS_CATARACTS_MACULAR_DEGENERATION',
  VISUAL_SPATIAL_ABILITY_DISORDERS = 'VISUAL_SPATIAL_ABILITY_DISORDERS',
  VITAMIN_OR_MINERAL_DEFICIENCIES = 'VITAMIN_OR_MINERAL_DEFICIENCIES',
  VOCAL_CORD_NODULES = 'VOCAL_CORD_NODULES',
  VOCAL_CORD_NODULES_OR_OTHER_VOCAL_PROBLEMS = 'VOCAL_CORD_NODULES_OR_OTHER_VOCAL_PROBLEMS',
  VOCAL_CORD_POLYPS = 'VOCAL_CORD_POLYPS',
  VOCAL_MODULATION_AND_INTONATION_DISORDERS = 'VOCAL_MODULATION_AND_INTONATION_DISORDERS',
  VOICE_CHANGE = 'VOICE_CHANGE',
  VOICE_DISORDERS_ASSOCIATED_WITH_EXCESSIVE_OR_INCORRECT_USE_OF_VOICE = 'VOICE_DISORDERS_ASSOCIATED_WITH_EXCESSIVE_OR_INCORRECT_USE_OF_VOICE',
  VOICE_PROBLEMS_HOARSENESS_WEAK_OR_LOST_VOICE = 'VOICE_PROBLEMS_HOARSENESS_WEAK_OR_LOST_VOICE',
  VOICE_PROBLEMS_RELATED_TO_MISUSE_OF_THE_PHONATORY_APPARATUS = 'VOICE_PROBLEMS_RELATED_TO_MISUSE_OF_THE_PHONATORY_APPARATUS',
  VOMITING = 'VOMITING',
  VULVAR_IRRITATION = 'VULVAR_IRRITATION',
  VULVAR_OR_CERVICAL_INJURIES = 'VULVAR_OR_CERVICAL_INJURIES',
  WARTS_OR_SKIN_GROWTHS = 'WARTS_OR_SKIN_GROWTHS',
  WATER_RETENTION = 'WATER_RETENTION',
  WEAKNESS_OF_THE_EYE_MUSCLES = 'WEAKNESS_OF_THE_EYE_MUSCLES',
  WEAKNESS_OF_URINARY_FLOW = 'WEAKNESS_OF_URINARY_FLOW',
  WEAKNESS_OR_NUMBNESS_IN_THE_LIMBS = 'WEAKNESS_OR_NUMBNESS_IN_THE_LIMBS',
  WEAKNESS_OR_PARALYSIS_OF_A_LIMB = 'WEAKNESS_OR_PARALYSIS_OF_A_LIMB',
  WEAK_URINATION_OR_EXCESSIVE_EFFORT_TO_URINATE = 'WEAK_URINATION_OR_EXCESSIVE_EFFORT_TO_URINATE',
  WEIGHT_GAIN = 'WEIGHT_GAIN',
  WEIGHT_GAIN_GAINING_WEIGHT_FATTENING_UP = 'WEIGHT_GAIN_GAINING_WEIGHT_FATTENING_UP',
  WEIGHT_LOSS = 'WEIGHT_LOSS',
  WEIGHT_LOSS_OR_GAIN = 'WEIGHT_LOSS_OR_GAIN',
  WEIGHT_LOSS_SLIMMING_SLIMMED_DOWN = 'WEIGHT_LOSS_SLIMMING_SLIMMED_DOWN',
  WHEEZING_OR_DIFFICULTY_BREATHING = 'WHEEZING_OR_DIFFICULTY_BREATHING',
  WHEEZING_OR_LABORED_BREATHING = 'WHEEZING_OR_LABORED_BREATHING',
  WHISTLES = 'WHISTLES',
  WHISTLE_HISS_NOISE_IN_THE_EAR = 'WHISTLE_HISS_NOISE_IN_THE_EAR',
  WIDESPREAD_MUSCLE_PAIN = 'WIDESPREAD_MUSCLE_PAIN',
  WINTER_MOOD_DISORDERS_SUCH_AS_SEASONAL_DEPRESSION = 'WINTER_MOOD_DISORDERS_SUCH_AS_SEASONAL_DEPRESSION',
  WORRY_SADNESS = 'WORRY_SADNESS',
  WOUNDS_THAT_HEAL_SLOWLY = 'WOUNDS_THAT_HEAL_SLOWLY',
  WOUNDS_THAT_WON_T_CLOSE = 'WOUNDS_THAT_WON_T_CLOSE',
  WOUND_INFECTIONS = 'WOUND_INFECTIONS',
  WOUND_RELATED_PAIN_MANAGEMENT = 'WOUND_RELATED_PAIN_MANAGEMENT',
  WOUND_STINKING_SMELLY = 'WOUND_STINKING_SMELLY',
  WOUND_THAT_WON_T_CLOSE = 'WOUND_THAT_WON_T_CLOSE',
  WRINKLED_OR_UNEVENLY_TEXTURED_SKIN = 'WRINKLED_OR_UNEVENLY_TEXTURED_SKIN',
  WRIST_SWELLING = 'WRIST_SWELLING',
  YELLOWED_SKIN_OR_EYES_JAUNDICE = 'YELLOWED_SKIN_OR_EYES_JAUNDICE',
  ZENKER_S_DIVERTICULUM_A_TYPE_OF_ESOPHAGEAL_HERNIA = 'ZENKER_S_DIVERTICULUM_A_TYPE_OF_ESOPHAGEAL_HERNIA'
}

export type Tax = {
  __typename?: 'Tax';
  name: Scalars['String']['output'];
  taxValue: Scalars['Float']['output'];
  translationCode: Scalars['String']['output'];
};

export type TherapyAhusMedicationsStartDateData = {
  __typename?: 'TherapyAhusMedicationsStartDateData';
  value: Scalars['DateTime']['output'];
};

export type TherapyAhusMedicationsStartDateDataArgs = {
  value: Scalars['DateTime']['input'];
};

export type TherapyAhusMedicationsStartDateHistory = {
  __typename?: 'TherapyAhusMedicationsStartDateHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyAhusMedicationsStartDateData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyApheresisData = {
  __typename?: 'TherapyApheresisData';
  executionDate?: Maybe<Scalars['DateTime']['output']>;
  freezingExecutionDate?: Maybe<Scalars['DateTime']['output']>;
  isExecuted: Scalars['Boolean']['output'];
};

export type TherapyApheresisDataArgs = {
  executionDate?: InputMaybe<Scalars['DateTime']['input']>;
  freezingExecutionDate?: InputMaybe<Scalars['DateTime']['input']>;
  isExecuted: Scalars['Boolean']['input'];
};

export type TherapyApheresisHistory = {
  __typename?: 'TherapyApheresisHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyApheresisData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyBreastAdjuvantTherapyData = {
  __typename?: 'TherapyBreastAdjuvantTherapyData';
  isInProgress: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type TherapyBreastAdjuvantTherapyDataArgs = {
  isInProgress: Scalars['Boolean']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type TherapyBreastAdjuvantTherapyHistory = {
  __typename?: 'TherapyBreastAdjuvantTherapyHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyBreastAdjuvantTherapyData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyBreastHormoneTherapyData = {
  __typename?: 'TherapyBreastHormoneTherapyData';
  isInProgress: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type TherapyBreastHormoneTherapyDataArgs = {
  isInProgress: Scalars['Boolean']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type TherapyBreastHormoneTherapyHistory = {
  __typename?: 'TherapyBreastHormoneTherapyHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyBreastHormoneTherapyData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyBreastNeoadjuvantTherapyData = {
  __typename?: 'TherapyBreastNeoadjuvantTherapyData';
  isInProgress: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type TherapyBreastNeoadjuvantTherapyDataArgs = {
  isInProgress: Scalars['Boolean']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type TherapyBreastNeoadjuvantTherapyHistory = {
  __typename?: 'TherapyBreastNeoadjuvantTherapyHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyBreastNeoadjuvantTherapyData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyBreastRadiotherapyData = {
  __typename?: 'TherapyBreastRadiotherapyData';
  isInProgress: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type TherapyBreastRadiotherapyDataArgs = {
  isInProgress: Scalars['Boolean']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type TherapyBreastRadiotherapyHistory = {
  __typename?: 'TherapyBreastRadiotherapyHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyBreastRadiotherapyData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyCarTEligibilityData = {
  __typename?: 'TherapyCarTEligibilityData';
  idealDateOfTreatment?: Maybe<Scalars['DateTime']['output']>;
  isExecuted: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type TherapyCarTEligibilityDataArgs = {
  idealDateOfTreatment?: InputMaybe<Scalars['DateTime']['input']>;
  isExecuted: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type TherapyCarTEligibilityHistory = {
  __typename?: 'TherapyCarTEligibilityHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyCarTEligibilityData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyCarTInfusionData = {
  __typename?: 'TherapyCarTInfusionData';
  executionDate?: Maybe<Scalars['DateTime']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  value: CarTInfusionStatus;
};

export type TherapyCarTInfusionDataArgs = {
  executionDate?: InputMaybe<Scalars['DateTime']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  value: CarTInfusionStatus;
};

export type TherapyCarTInfusionHistory = {
  __typename?: 'TherapyCarTInfusionHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyCarTInfusionData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyCarTTreatmentArriveData = {
  __typename?: 'TherapyCarTTreatmentArriveData';
  date: Scalars['DateTime']['output'];
};

export type TherapyCarTTreatmentArriveDataArgs = {
  date: Scalars['DateTime']['input'];
};

export type TherapyCarTTreatmentArriveHistory = {
  __typename?: 'TherapyCarTTreatmentArriveHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyCarTTreatmentArriveData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyCarTTreatmentOrderData = {
  __typename?: 'TherapyCarTTreatmentOrderData';
  date: Scalars['DateTime']['output'];
};

export type TherapyCarTTreatmentOrderDataArgs = {
  date: Scalars['DateTime']['input'];
};

export type TherapyCarTTreatmentOrderHistory = {
  __typename?: 'TherapyCarTTreatmentOrderHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyCarTTreatmentOrderData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyHematologyData = {
  __typename?: 'TherapyHematologyData';
  createdIn: TherapyHematologySection;
  dose: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  medicine: Scalars['String']['output'];
  molecularResponseType?: Maybe<Scalars['String']['output']>;
  objectId: Scalars['String']['output'];
  reductionReason?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  suspensionReason?: Maybe<Scalars['String']['output']>;
};

export type TherapyHematologyDataArgs = {
  createdIn: TherapyHematologySection;
  dose: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  medicine: Scalars['String']['input'];
  molecularResponseType?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['String']['input'];
  reductionReason?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  suspensionReason?: InputMaybe<Scalars['String']['input']>;
};

export type TherapyHematologyHistory = {
  __typename?: 'TherapyHematologyHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyHematologyData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyHematologyHistoryModel = {
  __typename?: 'TherapyHematologyHistoryModel';
  rows: Array<TherapyHematologyRow>;
};

export type TherapyHematologyRow = {
  __typename?: 'TherapyHematologyRow';
  history: Array<TherapyHematologyHistory>;
  objectId: Scalars['String']['output'];
};

export enum TherapyHematologySection {
  BREAST = 'BREAST',
  CAR_T = 'CAR_T',
  CML = 'CML',
  MELANOMA = 'MELANOMA',
  MF = 'MF',
  NET = 'NET',
  PV = 'PV'
}

export type TherapyNetAdjuvantTherapyData = {
  __typename?: 'TherapyNetAdjuvantTherapyData';
  notes?: Maybe<Scalars['String']['output']>;
  value: YesNo;
};

export type TherapyNetAdjuvantTherapyDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: YesNo;
};

export type TherapyNetAdjuvantTherapyHistory = {
  __typename?: 'TherapyNetAdjuvantTherapyHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyNetAdjuvantTherapyData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyNetNeoadjuvantTherapyData = {
  __typename?: 'TherapyNetNeoadjuvantTherapyData';
  notes?: Maybe<Scalars['String']['output']>;
  value: YesNo;
};

export type TherapyNetNeoadjuvantTherapyDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: YesNo;
};

export type TherapyNetNeoadjuvantTherapyHistory = {
  __typename?: 'TherapyNetNeoadjuvantTherapyHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyNetNeoadjuvantTherapyData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyNetRadioReceptorTherapyData = {
  __typename?: 'TherapyNetRadioReceptorTherapyData';
  notes?: Maybe<Scalars['String']['output']>;
  value: YesNo;
};

export type TherapyNetRadioReceptorTherapyDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: YesNo;
};

export type TherapyNetRadioReceptorTherapyHistory = {
  __typename?: 'TherapyNetRadioReceptorTherapyHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyNetRadioReceptorTherapyData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyNetSomatostatinAnalogueTherapyData = {
  __typename?: 'TherapyNetSomatostatinAnalogueTherapyData';
  notes?: Maybe<Scalars['String']['output']>;
  value: YesNo;
};

export type TherapyNetSomatostatinAnalogueTherapyDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  value: YesNo;
};

export type TherapyNetSomatostatinAnalogueTherapyHistory = {
  __typename?: 'TherapyNetSomatostatinAnalogueTherapyHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyNetSomatostatinAnalogueTherapyData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type TherapyTherapyData = {
  __typename?: 'TherapyTherapyData';
  dose: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  medicine: Scalars['String']['output'];
  reductionReason?: Maybe<Scalars['String']['output']>;
  responseType?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  suspensionReason?: Maybe<Scalars['String']['output']>;
};

export type TherapyTherapyDataArgs = {
  dose: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  medicine: Scalars['String']['input'];
  reductionReason?: InputMaybe<Scalars['String']['input']>;
  responseType?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  suspensionReason?: InputMaybe<Scalars['String']['input']>;
};

export type TherapyTherapyHistory = {
  __typename?: 'TherapyTherapyHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TherapyTherapyData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export enum ThoraxGrading {
  ATYPICAL_CARCINOID = 'ATYPICAL_CARCINOID',
  GX = 'GX',
  LARGE_CELL_AND_SMALL_CELLL_NEUROENDOCRINE_CARCINOMA = 'LARGE_CELL_AND_SMALL_CELLL_NEUROENDOCRINE_CARCINOMA',
  TYPICAL_CARCINOID = 'TYPICAL_CARCINOID'
}

export enum ThoraxStagingTnmAsco2021 {
  STAGE_0 = 'STAGE_0',
  STAGE_IA = 'STAGE_IA',
  STAGE_IB = 'STAGE_IB',
  STAGE_IIA = 'STAGE_IIA',
  STAGE_IIB = 'STAGE_IIB',
  STAGE_III = 'STAGE_III',
  STAGE_IVA = 'STAGE_IVA',
  STAGE_IVB = 'STAGE_IVB'
}

export type TimeIntervalInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeSlotModel = {
  __typename?: 'TimeSlotModel';
  durationMinutes: Scalars['Float']['output'];
  startTime: Scalars['DateTime']['output'];
};

export type TnmAsco2021AppendixMedicalIndexParametersModel = {
  mParameter: TnmAsco2021GeneralMedicalIndexMParameter;
  nParameter: TnmAsco2021GeneralMedicalIndexNParameter;
  tParameter: TnmAsco2021AppendixMedicalIndexTParameter;
};

export enum TnmAsco2021AppendixMedicalIndexTParameter {
  T0 = 'T0',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  TX = 'TX'
}

export type TnmAsco2021ColonAndRectumMedicalIndexParametersModel = {
  mParameter: TnmAsco2021GeneralMedicalIndexMParameter;
  nParameter: TnmAsco2021GeneralMedicalIndexNParameter;
  tParameter: TnmAsco2021ColonAndRectumMedicalIndexTParameter;
};

export enum TnmAsco2021ColonAndRectumMedicalIndexTParameter {
  T0 = 'T0',
  T1A = 'T1A',
  T1B = 'T1B',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  TX = 'TX'
}

export type TnmAsco2021DuodenumAndVaterAmpullaMedicalIndexParametersModel = {
  mParameter: TnmAsco2021GeneralMedicalIndexMParameter;
  nParameter: TnmAsco2021GeneralMedicalIndexNParameter;
  tParameter: TnmAsco2021DuodenumAndVaterAmpullaMedicalIndexTParameter;
};

export enum TnmAsco2021DuodenumAndVaterAmpullaMedicalIndexTParameter {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  TX = 'TX'
}

export enum TnmAsco2021GeneralMedicalIndexMParameter {
  M0 = 'M0',
  M1A = 'M1A',
  M1B = 'M1B',
  M1C = 'M1C'
}

export enum TnmAsco2021GeneralMedicalIndexNParameter {
  N0 = 'N0',
  N1 = 'N1',
  NX = 'NX'
}

export enum TnmAsco2021JejunumAndIleumMedicalIndexNParameter {
  N0 = 'N0',
  N1 = 'N1',
  N2 = 'N2',
  NX = 'NX'
}

export type TnmAsco2021JejunumAndIleumMedicalIndexParametersModel = {
  mParameter: TnmAsco2021GeneralMedicalIndexMParameter;
  nParameter: TnmAsco2021JejunumAndIleumMedicalIndexNParameter;
  tParameter: TnmAsco2021JejunumAndIleumMedicalIndexTParameter;
};

export enum TnmAsco2021JejunumAndIleumMedicalIndexTParameter {
  T0 = 'T0',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  TX = 'TX'
}

export type TnmAsco2021PancreasMedicalIndexParametersModel = {
  mParameter: TnmAsco2021GeneralMedicalIndexMParameter;
  nParameter: TnmAsco2021GeneralMedicalIndexNParameter;
  tParameter: TnmAsco2021PancreasMedicalIndexTParameter;
};

export enum TnmAsco2021PancreasMedicalIndexTParameter {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  TX = 'TX'
}

export type TnmAsco2021StomachMedicalIndexParametersModel = {
  mParameter: TnmAsco2021GeneralMedicalIndexMParameter;
  nParameter: TnmAsco2021GeneralMedicalIndexNParameter;
  tParameter: TnmAsco2021StomachMedicalIndexTParameter;
};

export enum TnmAsco2021StomachMedicalIndexTParameter {
  T0 = 'T0',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  TX = 'TX'
}

export enum TnmMedicalIndexMParameter {
  M0 = 'M0',
  M1 = 'M1'
}

export enum TnmMedicalIndexNParameter {
  N0 = 'N0',
  N1 = 'N1',
  N1MI = 'N1MI',
  N2 = 'N2',
  N3 = 'N3'
}

export type TnmMedicalIndexParametersModel = {
  mParameter: TnmMedicalIndexMParameter;
  nParameter: TnmMedicalIndexNParameter;
  tParameter: TnmMedicalIndexTParameter;
};

export enum TnmMedicalIndexTParameter {
  T0 = 'T0',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  TIS = 'TIS'
}

export type Tumours = {
  __typename?: 'Tumours';
  genericInformation?: Maybe<TumoursGenericInformationData>;
};

export type TumoursGenericInformationData = {
  __typename?: 'TumoursGenericInformationData';
  isPresent: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

export type TumoursGenericInformationDataArgs = {
  isPresent: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type TumoursGenericInformationHistory = {
  __typename?: 'TumoursGenericInformationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: TumoursGenericInformationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type UpdateNoticeModel = {
  __typename?: 'UpdateNoticeModel';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type User = {
  __typename?: 'User';
  birthAddress: BirthAddressModel;
  birthDate: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  currentResidenceAddress?: Maybe<ResidenceAddressModel>;
  email?: Maybe<Scalars['String']['output']>;
  emailNotificationPeriod: EmailNotificationPeriod;
  firstName: Scalars['String']['output'];
  fiscalCode?: Maybe<Scalars['String']['output']>;
  gender: Gender;
  id: Scalars['String']['output'];
  isEmailVerified: Scalars['Boolean']['output'];
  isFullyRegistered: Scalars['Boolean']['output'];
  isOtpActive: Scalars['Boolean']['output'];
  isPhoneNumberVerified: Scalars['Boolean']['output'];
  lastAuthenticatedAt: Scalars['DateTime']['output'];
  lastName: Scalars['String']['output'];
  otpUpdatedAt: Scalars['DateTime']['output'];
  patient?: Maybe<Patient>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  portalProfileList: Array<InstitutionUser>;
  profileList: Array<InstitutionUser>;
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type UserportalProfileListArgs = {
  institutionId?: InputMaybe<Scalars['String']['input']>;
  profileTypes?: InputMaybe<Array<ProfileType>>;
};

export enum UserClaimListOrderByField {
  CREATED_AT = 'CREATED_AT',
  FIRST_NAME_AND_LAST_NAME = 'FIRST_NAME_AND_LAST_NAME',
  STATUS = 'STATUS'
}

export enum UserClaimStatus {
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type UserCreatedByModel = {
  __typename?: 'UserCreatedByModel';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type UserDataByUserClaimTokenResponseModel = {
  __typename?: 'UserDataByUserClaimTokenResponseModel';
  user: UserModel;
  userClaim: AppUserClaimModel;
};

export type UserEmailChangeRequestModel = {
  __typename?: 'UserEmailChangeRequestModel';
  confirmedAt?: Maybe<Scalars['DateTime']['output']>;
  newEmail: Scalars['String']['output'];
  requestedAt: Scalars['DateTime']['output'];
};

export type UserListFilterByArgs = {
  roles?: InputMaybe<Array<ProfileType>>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
};

export type UserModel = {
  __typename?: 'UserModel';
  birthAddress: BirthAddressModel;
  birthDate: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<UserCreatedByModel>;
  currentResidenceAddress?: Maybe<ResidenceAddressModel>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fiscalCode?: Maybe<Scalars['String']['output']>;
  gender: Gender;
  id: Scalars['String']['output'];
  isEmailVerified: Scalars['Boolean']['output'];
  isFullyRegistered: Scalars['Boolean']['output'];
  isOtpActive: Scalars['Boolean']['output'];
  isPhoneNumberVerified: Scalars['Boolean']['output'];
  /** NOTE: Field is portal dependant, use with a proper header */
  isPresentOnThisPortal: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  patient?: Maybe<PatientModel>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profileList: Array<InstitutionUserModel>;
};

export type UserPhoneNumberChangeRequestModel = {
  __typename?: 'UserPhoneNumberChangeRequestModel';
  confirmedAt?: Maybe<Scalars['DateTime']['output']>;
  newPhoneNumber: Scalars['String']['output'];
  requestedAt: Scalars['DateTime']['output'];
};

export type UserPrivacy = {
  __typename?: 'UserPrivacy';
  id: Scalars['String']['output'];
  isAccepted: Scalars['Boolean']['output'];
  privacy: Privacy;
};

export type UserPrivacyModel = {
  __typename?: 'UserPrivacyModel';
  id: Scalars['String']['output'];
  isAccepted: Scalars['Boolean']['output'];
  privacy: PrivacyModel;
};

export type UserRegistrationModel = {
  __typename?: 'UserRegistrationModel';
  cognitoAuthModel: CognitoAuthModel;
  user: User;
};

export enum UserRegistrationStatusCheckDecision {
  CURRENT_REGISTRATION_FLOW_IS_NOT_SUPPORTED = 'CURRENT_REGISTRATION_FLOW_IS_NOT_SUPPORTED',
  PROCEED_REGISTRATION = 'PROCEED_REGISTRATION',
  PROCEED_TO_LOGIN = 'PROCEED_TO_LOGIN',
  PROCEED_TO_LOGIN_VIA_REGISTRATION = 'PROCEED_TO_LOGIN_VIA_REGISTRATION',
  PROCEED_TO_PASSWORD_CONFIRMATION_AND_EMAIL_VERIFICATION = 'PROCEED_TO_PASSWORD_CONFIRMATION_AND_EMAIL_VERIFICATION',
  PROCEED_TO_PASSWORD_CONFIRMATION_AND_EMAIL_VERIFICATION_VIA_REGISTRATION = 'PROCEED_TO_PASSWORD_CONFIRMATION_AND_EMAIL_VERIFICATION_VIA_REGISTRATION',
  PROCEED_TO_PATIENT_CREATED_BY_DOCTOR_LOGIN_AND_EMAIL_VERIFICATION = 'PROCEED_TO_PATIENT_CREATED_BY_DOCTOR_LOGIN_AND_EMAIL_VERIFICATION',
  PROCEED_TO_USER_CLAIM_CREATION = 'PROCEED_TO_USER_CLAIM_CREATION'
}

export type UserRoles = {
  __typename?: 'UserRoles';
  portalUsers: Array<PortalUserModel>;
  profiles: Array<InstitutionUser>;
  registrationIntentions: Array<RegistrationIntention>;
};

export enum UserTitle {
  CARE_MANAGER = 'CARE_MANAGER',
  DR = 'DR',
  MR = 'MR',
  NO_VALUE = 'NO_VALUE',
  PROF = 'PROF'
}

export type UserVerificationModel = {
  __typename?: 'UserVerificationModel';
  userVerificationId: Scalars['String']['output'];
};

export type VaccinationsAntimeningococcalVaccinationData = {
  __typename?: 'VaccinationsAntimeningococcalVaccinationData';
  executionDate?: Maybe<Scalars['DateTime']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type VaccinationsAntimeningococcalVaccinationDataArgs = {
  executionDate?: InputMaybe<Scalars['DateTime']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};

export type VaccinationsAntimeningococcalVaccinationHistory = {
  __typename?: 'VaccinationsAntimeningococcalVaccinationHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: VaccinationsAntimeningococcalVaccinationData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type VaccinationsGenericNotesData = {
  __typename?: 'VaccinationsGenericNotesData';
  notes: Scalars['String']['output'];
};

export type VaccinationsGenericNotesDataArgs = {
  notes: Scalars['String']['input'];
};

export type VaccinationsGenericNotesHistory = {
  __typename?: 'VaccinationsGenericNotesHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: VaccinationsGenericNotesData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export type ValidateFiscalCodeContextArgs = {
  birthDate: Scalars['DateTime']['input'];
  externalGooglePlaceId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  lastName: Scalars['String']['input'];
};

export type ValidateFiscalCodeContextualValidationResultModel = {
  __typename?: 'ValidateFiscalCodeContextualValidationResultModel';
  computedDefault: Scalars['String']['output'];
  correlatesToBirthDate: Scalars['Boolean']['output'];
  correlatesToGender: Scalars['Boolean']['output'];
  /** If true, the submitted fiscal code matches one of the fiscal code variations used in case of real world collisions between different people (known as "omocodie"). */
  matchesComputedAlternatives: Scalars['Boolean']['output'];
  matchesComputedDefault: Scalars['Boolean']['output'];
};

export type ValidateFiscalCodeIntrinsicValidationResultModel = {
  __typename?: 'ValidateFiscalCodeIntrinsicValidationResultModel';
  isValid: Scalars['Boolean']['output'];
};

export type ValidateFiscalCodeResultModel = {
  __typename?: 'ValidateFiscalCodeResultModel';
  /**
   * Result of the validation of the provided fiscal code alongside the provided context,
   * i.e. of the validation of the correlation between the fiscal code and the context.
   * Will be null when either:
   * - the intrinsic validation fails
   * - context is not provided
   * - the computation fails
   */
  contextualValidationResult?: Maybe<ValidateFiscalCodeContextualValidationResultModel>;
  /** Result of the intrinsic/standalone validation of the provided fiscal code, i.e. ignoring the provided context. */
  intrinsicValidationResult: ValidateFiscalCodeIntrinsicValidationResultModel;
};

export type ValidateGsdHsrPatientCreationModel = {
  __typename?: 'ValidateGsdHsrPatientCreationModel';
  fiscalCodeRequired: Scalars['Boolean']['output'];
  gsdHsrBirthAddressRequired: Scalars['Boolean']['output'];
  gsdHsrResidenceAddressRequired: Scalars['Boolean']['output'];
  identityDocumentRequired: Scalars['Boolean']['output'];
};

export type ValidationOptionsModel = {
  __typename?: 'ValidationOptionsModel';
  isNumeric?: Maybe<Scalars['Boolean']['output']>;
  maxLength?: Maybe<Scalars['Int']['output']>;
  minLength?: Maybe<Scalars['Int']['output']>;
};

export enum VideoVisitAvailability {
  ALLOWS_ON_DEMAND_SCHEDULE = 'ALLOWS_ON_DEMAND_SCHEDULE',
  AVAILABLE_WITHIN_7_DAYS = 'AVAILABLE_WITHIN_7_DAYS'
}

export type VideovisitModel = {
  __typename?: 'VideovisitModel';
  allowsOnDemandSchedule: Scalars['Boolean']['output'];
  durationMinutes: Scalars['Int']['output'];
};

export type VitalParameterArgs = {
  basalMetabolism?: InputMaybe<Scalars['Float']['input']>;
  bmi?: InputMaybe<Scalars['Float']['input']>;
  bodySurfaceArea?: InputMaybe<Scalars['Float']['input']>;
  bpDiastolic?: InputMaybe<Scalars['Float']['input']>;
  bpSystolic?: InputMaybe<Scalars['Float']['input']>;
  breathing?: InputMaybe<Scalars['Float']['input']>;
  evaluatedAt: Scalars['DateTime']['input'];
  heartRate?: InputMaybe<Scalars['Float']['input']>;
  heartRateType?: InputMaybe<HeartRateType>;
  height?: InputMaybe<Scalars['Float']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type VitalsParametersGenericNotesData = {
  __typename?: 'VitalsParametersGenericNotesData';
  notes: Scalars['String']['output'];
};

export type VitalsParametersGenericNotesDataArgs = {
  notes: Scalars['String']['input'];
};

export type VitalsParametersGenericNotesHistory = {
  __typename?: 'VitalsParametersGenericNotesHistory';
  createdAt: Scalars['DateTime']['output'];
  creator: InstitutionUser;
  data: VitalsParametersGenericNotesData;
  id: Scalars['String']['output'];
  patient: Patient;
  section: Section;
  subSection: SubSection;
};

export enum VoiceCallContextType {
  CLINIC = 'CLINIC',
  ONE_TO_ONE = 'ONE_TO_ONE'
}

export type VoiceCallDetailsModel = {
  __typename?: 'VoiceCallDetailsModel';
  caller: PortalUserModel;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  contextType: VoiceCallContextType;
  id: Scalars['String']['output'];
  startedAt: Scalars['DateTime']['output'];
  status: VoiceCallStatus;
};

export enum VoiceCallStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_ANSWERED = 'NOT_ANSWERED',
  REJECTED = 'REJECTED',
  STARTED = 'STARTED'
}

export type VoiceCallTokenIssueResultModel = {
  __typename?: 'VoiceCallTokenIssueResultModel';
  twilioJwtToken: Scalars['String']['output'];
};

export type VoiceCallsPatientCommunication = {
  __typename?: 'VoiceCallsPatientCommunication';
  type: Scalars['String']['output'];
  voiceCalls: Array<VoiceCallDetailsModel>;
};

export enum YesNo {
  NO = 'NO',
  YES = 'YES'
}
